import React from "react";
import {
  clickHereDraggerStyles,
  headerDraggerStyles,
  subtitleDraggerStyles,
} from "./styles";
import Colors from "../../../config/Colors";
import { CustomIcons } from "../../../assets";

type DaggerCardProps = {
  description?: string;
};
const DaggerCard = ({
  description = "File should be PNG,JPG or GIF",
}: DaggerCardProps) => {
  return (
    <div className="flex flex-col items-center content-center w-full h-full gap-3">
      <CustomIcons.UploadIcon
        width={46}
        height={46}
        svgColor={Colors.appMainColor}
      />
      <div className="flex gap-1 justify-content-center">
        <div className="text-center" style={headerDraggerStyles}>
          Drop your icon here or to upload
        </div>
        <div style={clickHereDraggerStyles}>click here</div>
        <div className="text-center" style={headerDraggerStyles}>
          to upload
        </div>
      </div>

      <div style={subtitleDraggerStyles}>{description}</div>
    </div>
  );
};

export default DaggerCard;

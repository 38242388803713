import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import CPFs from "@/services/CPFs";
import { queries } from "../services/queries";

export function useGetCPFTableData(
  page: number,
  pageSize: number
): UseQueryResult<ICPFResponse> {
  return useQuery({
    ...queries.cpfResponder.getCPFResponders(page, pageSize),
    refetchOnWindowFocus: false,
  });
}

export function usePostCPFResponder() {
  const queryClient = useQueryClient();

  return useMutation(CPFs.createNewCPFResponder, {
    meta: {
      conflict: "Conflicts found whilst making changes",
      badRequest: "Please make sure all fields are entered correctly.",
      notFound: "CPF could not be found. Please try again",
    },
  });
}
export function useGetCPFResponderDetails(
  id: number
): UseQueryResult<ICPFResponderDetails> {
  return useQuery({
    ...queries.cpfResponder.getCPFResponderDetails(id),
    refetchOnWindowFocus: false,
  });
}

export function useUpdateCPFResponder() {
  const queryClient = useQueryClient();

  return useMutation(CPFs.updateCPFResponder, {
    onSuccess: (data, ctx) => {
      queryClient.invalidateQueries(
        queries.cpfResponder.getCPFResponderDetails(ctx.id as number).queryKey
      );
    },

    meta: {
      conflict: "Conflicts found whilst making changes",
      badRequest: "Please make sure all fields are entered correctly.",
      notFound: "CPF could not be found. Please try again",
    },
  });
}

export function useBulkCreateNewCPFResponders() {
  const queryClient = useQueryClient();

  return useMutation(CPFs.bulkCreateNewCPFResponders, {
    meta: {
      conflict: "Conflicts found whilst making changes",
      badRequest: "Please make sure all fields are entered correctly.",
      notFound: "CPF could not be found. Please try again",
    },
  });
}

import React, { useContext, useState } from "react";
import {
  Button,
  Divider,
  Dropdown,
  MenuProps,
  message,
  Modal,
  Select,
  Spin,
} from "antd";
import HeaderComponent from "../../components/Header/Header";
import { useInfiniteQuery } from "@tanstack/react-query";
import Communities from "../../services/Communities";
import InfiniteScroll from "react-infinite-scroller";
import CommunityReportedPost from "../../components/Cards/CommunityCard/CommunityReportedPost";
import { CustomIcons } from "../../assets";
import Colors from "../../config/Colors";
import CommunityFeedCard from "../../components/Cards/CommunityCard/CommunityFeedCard";
import { SubHeading } from "../Home/styles";
import CommunityPostTabs from "../../components/Tabs/Community/Feed/CommunityPostTabs";
import AuthContext from "../../contexts/AuthContext";

const SaferCityCommunityFeed = () => {
  const [openCommunityPostModal, setOpenCommunityPostModal] = useState(false);
  const user = useContext(AuthContext);
  const [filter, setFilter] = useState<string>();

  const limit = 10;

  let orderBy = "recent";

  const {
    data: QueryDataFeed,
    fetchNextPage: fetchNextPageFeed,
    hasNextPage: hasNextPageFeed,
    isLoading: isLoadingFeed,
    isFetching: isFetchingFeed,
    isError: isErrorFeed,
    error: errorFeed,
    refetch: refetchFeed,
  } = useInfiniteQuery(
    ["getSaferCityPosts"], // unique key
    ({ pageParam = 0 }) =>
      Communities.getSaferCityPosts(limit, pageParam, orderBy), // query function
    {
      getNextPageParam: (lastPage: ICommunityFeedResponse) =>
        lastPage.hasMore ? lastPage.skip / 10 + 1 : undefined, // Use the length of the last page as the next page parameter
    }
  );
  const loadFuncFeed = () => {
    if (hasNextPageFeed) {
      fetchNextPageFeed();
    } else {
      message.info("That's all, nothing more 🤐");
    }
  };
  const RefreshFeed = () => {
    refetchFeed();
  };
  const handleChange = (value: string) => {
    orderBy = value;
    refetchFeed();
  };
  const renderNothing = () => {
    return <Divider plain>That&apos;s all, nothing more 🤐</Divider>;
  };

  const flattenedFeed = QueryDataFeed?.pages.flatMap((page) => page.feed) || [];
  return (
    <div className="flex w-full h-full bg-backgroundColor">
      <div className="w-full h-full max-h-screen overflow-y-auto disable-scrollbars">
        <HeaderComponent title="Community feed" />
        <div className="flex w-full h-full px-4 justify-center">
          <Spin tip="" spinning={isLoadingFeed} delay={500}>
            <button
              aria-label="Create a post"
              className="flex flex-row items-center flex-1 w-full h-12 gap-2 px-3 py-1 shadow-5 surface-card border-round"
              onClick={() => setOpenCommunityPostModal(true)}
              type="button"
            >
              <img
                src={require("../../assets/images/SaferCity.png")}
                height={50}
                width={40}
              />
              <div className="flex items-center justify-start w-full pl-3 rounded-omd h-9 bg-[#F5F5F5]">
                Create a post
              </div>
            </button>
            <div className="flex w-full mt-3 justify-end">
              <Select
                defaultValue="recent"
                placeholder="Operator's type"
                size="large"
                onChange={handleChange}
                style={{ width: 120 }}
              >
                <Select.Option value="recent">Recent</Select.Option>
                <Select.Option value="old">Old</Select.Option>
              </Select>
            </div>
            <div
              style={{ maxHeight: "95vh" }}
              className="flex-grow  my-2 overflow-y-auto disable-scrollbars"
            >
              <InfiniteScroll
                pageStart={0}
                loadMore={loadFuncFeed}
                hasMore={
                  QueryDataFeed?.pages[QueryDataFeed.pages.length - 1]
                    .hasMore || false
                }
                loader={
                  <div className="flex justify-center pb-2" key={0}>
                    <CustomIcons.EOSloading
                      width={60}
                      height={60}
                      svgColor={Colors.appMainColor}
                    />
                  </div>
                }
                useWindow={false}
              >
                {flattenedFeed.map((item, index) => (
                  <div className="px-1 py-2" key={item?.id}>
                    <CommunityFeedCard card={item} key={item?.id} />
                  </div>
                ))}
              </InfiniteScroll>
            </div>
            {/* {showNothing && <>{renderNothing()}</>} */}
          </Spin>
        </div>
      </div>
      <Modal
        centered
        open={openCommunityPostModal}
        onOk={() => setOpenCommunityPostModal(false)}
        onCancel={() => setOpenCommunityPostModal(false)}
        width={550}
        closeIcon={
          <CustomIcons.CloseIcon
            width={30}
            height={30}
            viewBox={null}
            svgColor="#1C274C"
          />
        }
        footer={null}
      >
        <div className="flex w-full items-center">
          <img
            src={require("../../assets/images/SaferCity.png")}
            height={50}
            width={40}
            alt="Safer City Badge"
          />
          <SubHeading className="ml-2 pt-3">Community Post</SubHeading>
        </div>

        <CommunityPostTabs
          communityId={null}
          userId={user.user?.id}
          setOpenCommunityPostModal={() => {
            setOpenCommunityPostModal(false);
          }}
          refreshPage={() => RefreshFeed()}
        />
      </Modal>
    </div>
  );
};

export default SaferCityCommunityFeed;

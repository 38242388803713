/* eslint-disable max-len */
import React from "react";
import Colors from "../../config/Colors";

export function UploadIcon({
  height, // number type by default
  width,
  viewBox = "0 0 46 46",
  svgColor = `${Colors.appMainColor}`,
}: CustomSVGProp) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox={viewBox || "0 0 46 46"}
      fill="none"
    >
      <path
        d="M42.9875 3.3375H17.3125C17.3125 1.7125 16.0125 0.25 14.3875 0.25H3.0125C1.55 0.25 0.25 1.55 0.25 3.3375V12.4375V42.825C0.25 44.45 1.55 45.9125 3.175 45.9125H42.9875C44.6125 45.9125 45.9125 44.6125 45.9125 42.825V12.4375V6.2625C45.75 4.6375 44.45 3.3375 42.9875 3.3375ZM5.9375 6.2625H40.0625C41.6875 6.2625 42.9875 7.5625 42.9875 9.35H3.175C3.175 7.725 4.3125 6.2625 5.9375 6.2625ZM28.2 27.0625C27.55 27.7125 26.7375 27.7125 26.0875 27.0625L24.3 25.1125V33.5625C24.3 34.375 23.65 35.025 22.8375 35.025C22.025 35.025 21.375 34.375 21.375 33.5625V25.275L19.5875 27.0625C18.9375 27.7125 18.125 27.7125 17.475 27.0625C16.825 26.4125 16.825 25.4375 17.475 24.7875L21.7 20.4L21.8625 20.2375C22.025 20.075 22.35 19.9125 22.5125 19.9125H22.675C23 19.9125 23.325 20.075 23.65 20.4L27.875 24.7875C28.85 25.4375 28.85 26.4125 28.2 27.0625Z"
        fill={svgColor || Colors.appMainColor}
      />
    </svg>
  );
}
export default UploadIcon;

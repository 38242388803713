/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
import { API, API_2, APILocal, APIStaging } from "../config/axios";

class ResponderCompany {
  getOverViewCompanies = async (
    pageNumber: number,
    pageSize: number,
    name: string | null = null
  ) => {
    let queryParams = `pageNumber=${pageNumber}&pageSize=${pageSize}`;
    if (name) {
      queryParams += `&name=${encodeURIComponent(name)}`;
    }
    const { data } = await API.get<PaginatedCompaniesResponse>(
      `Admin_ResponderCompany?${queryParams}`
    );
    return data;
  };

  getAllResponderCompanies = async () => {
    const { data } = await API.get<IBasicResponderCompanyDto[]>(
      "Admin_ResponderCompany/AllCompanies"
    );
    return data;
  };

  async updateCompany(company: IEditResponderCompany) {
    try {
      const response = await API_2.put<APIResult<IEditResponderCompany>>(
        `/Admin_ResponderCompany`,
        company
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async createCompany(company: IEditResponderCompany) {
    try {
      const response = await API_2.post<APIResult<IEditResponderCompany>>(
        `/Admin_ResponderCompany`,
        company
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}

export default new ResponderCompany();

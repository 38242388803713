/* eslint-disable comma-dangle */
/* eslint-disable function-paren-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable  implicit-arrow-linebreak */
import { CSSProperties } from "react";
import styled from "styled-components";
import Colors from "../config/Colors";

export const WINDOW_WIDTH = window.innerWidth;
export const WINDOW_HEIGHT = window.innerHeight;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: WINDOW_WIDTH;
  height: WINDOW_HEIGHT;
  background: ${Colors.backgroundColor};
`;

export const BodyContainer = styled.div`
  justify-content: space-between;
  overflow-y: auto;
  width: 90%;
  max-height: 100vh;
`;

export const ActionBodyText = styled.p`
  font-style: normal;
  font-size: 18;
  color: ${Colors.appMainColor};
  text-decoration: underline;
  justify-content: center;
  align-items: center;
`;

/* eslint-disable comma-dangle */
/* eslint-disable function-paren-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable  implicit-arrow-linebreak */
import styled, { CSSProperties } from "styled-components";
import Colors from "../../config/Colors";

export const WINDOW_WIDTH = window.innerWidth;
export const WINDOW_HEIGHT = window.innerHeight;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: WINDOW_WIDTH;
  height: WINDOW_HEIGHT;
  color: black;
  background: #fbfbfb;
`;

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BodyContainer = styled.div`
  justify-content: space-between;
  p {
    font-weight: bold;
  }
  overflow-y: auto;
  width: 90%;
  max-height: 100vh;
`;

export const AddBtnStyle = {
  backgroundColor: `${Colors.subtleBlue}`,
  padding: "1rem",
  border: "none",
  width: "158px",
  height: "114px",
};

export const InnerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;

  .card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(23.75rem, 1fr));
    grid-gap: 20px;
    width: 100%;
    padding: 20px;
    margin-top: 20px;
    border: 0px solid #fff;
  }
`;

export const SubmitButton = styled.button`
  width: 120px;
  height: 40px;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SmallBlueButton = styled(SubmitButton)`
  background-color: ${Colors.appMainColor};
  color: white;
  margin-right: 10px;
  width: 1px;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const modelTitle: CSSProperties = {
  color: `${Colors.mainTextColor}`,
  fontWeight: 700,
  fontSize: 24,
  fontStyle: "normal",
};

export const successTitle: CSSProperties = {
  color: `${Colors.mainTextColor}`,
  fontWeight: 700,
  fontSize: 22,
  fontStyle: "normal",
};
export const successSubtitle: CSSProperties = {
  color: `${Colors.subtleTextColor}`,
  fontWeight: 400,
  fontSize: 14,
  fontStyle: "normal",
};

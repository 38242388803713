import { message, Tabs, TabsProps } from "antd";
import React from "react";
import NewReferralcode from "./NewReferralcode";
import EditReferralCode from "./EditReferralCode";
import {
  useGetAffiliationCodeByCode,
  usePostAffiliationCode,
  useUpdateAffiliationCode,
} from "@/hooks/useCommunityCodes";
import { set } from "lodash";

type ReferralCodeModelProps = {
  primaryCategories: IAffiliationPrimaryCategory[];
  secondaryCategories: IAffiliationSecondaryCategory[];
  tertiarycategories: IAffiliationTertiaryCategory[];
  onModalClose: () => void;
  refetch: () => void;
};

const ReferralCodeFormIndex = ({
  onModalClose,
  refetch,
  primaryCategories,
  secondaryCategories,
  tertiarycategories,
}: ReferralCodeModelProps) => {
  const postAffiliationCode = usePostAffiliationCode();
  const updateAffiliationCode = useUpdateAffiliationCode();
  const [selectedCode, setSelectedCode] = React.useState<string | null>(null);

  const { data } = useGetAffiliationCodeByCode(selectedCode as string);

  const handleAddAffiliationCode = async (
    code: IAffiliationCodeCreateHandler
  ) => {
    try {
      await postAffiliationCode.mutateAsync(code);
      message.success("Referral Code has been successfully saved");
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateAffiliationCode = async (code: IUpdateAffiliationCode) => {
    try {
      if (code) {
        await updateAffiliationCode.mutateAsync(code);
        message.success("Referral Code has been successfully updated");
        refetch();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Add New Referral Code",
      children: (
        <NewReferralcode
          onFormFinish={async (body: IAffiliationCodeCreateHandler) => {
            handleAddAffiliationCode(body);
          }}
          onModelClose={() => {
            postAffiliationCode.reset();
            onModalClose();
          }}
          isLoading={postAffiliationCode.isLoading}
          isSuccess={postAffiliationCode.isSuccess}
          primaryCategories={primaryCategories as IAffiliationPrimaryCategory[]}
          secondaryCategories={
            secondaryCategories as IAffiliationSecondaryCategory[]
          }
          tertiarycategories={
            tertiarycategories as IAffiliationTertiaryCategory[]
          }
        />
      ),
    },
    {
      key: "2",
      label: "Edit existing Referral Code",
      children: (
        <EditReferralCode
          onFormFinish={(body: IUpdateAffiliationCode) =>
            handleUpdateAffiliationCode(body)
          }
          onModelClose={() => {
            updateAffiliationCode.reset();
            onModalClose();
          }}
          isLoading={updateAffiliationCode.isLoading}
          isSuccess={updateAffiliationCode.isSuccess}
          primaryCategories={primaryCategories as IAffiliationPrimaryCategory[]}
          secondaryCategories={
            secondaryCategories as IAffiliationSecondaryCategory[]
          }
          tertiarycategories={
            tertiarycategories as IAffiliationTertiaryCategory[]
          }
        />
      ),
    },
  ];

  return (
    <div className="flex w-full h-full">
      <div className="w-full h-full px-3">
        <Tabs defaultActiveKey="1" items={items} />
      </div>
    </div>
  );
};

export default ReferralCodeFormIndex;

import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useRef, useState } from "react";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { TweenOneGroup } from "rc-tween-one";
import type { InputRef, UploadFile, UploadProps } from "antd";
import { Button, Form, Input, Tag, theme, message, Upload } from "antd";
import { RcFile } from "antd/es/upload";
import mime from "mime";
import { CustomIcons } from "../../../../assets";
import Colors from "../../../../config/Colors";
import { uploadUrl } from "@/config/urls";
import { values } from "lodash";

type NewCommunityPost = {
  communityPost?: ICommunityPost;
  onFormFinish: (communityPost: ICommunityPost) => void;
};

const Post = ({ communityPost, onFormFinish }: NewCommunityPost) => {
  const { token } = theme.useToken();
  const [tags, setTags] = useState(["SaferCity"]);
  const [mediaArr, setMediaArr] = useState<ICommunityMedia[]>([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef<InputRef>(null);
  const [imageUrl, setImageUrl] = useState("");
  const [fileListImage, setFileListImage] = useState<UploadFile[]>([]);
  const [fileListVideo, setFileListVideo] = useState<UploadFile[]>([]);

  const [form] = Form.useForm();

  const onFinish = async (formValues: ICommunityPost) => {
    const values: ICommunityPost = {
      ...formValues,
      tags: JSON.stringify(tags),
      mediaPath: JSON.stringify(mediaArr),
    };
    onFormFinish(values);
  };

  const beforeImageUpload = (file: RcFile) => {
    const isJpegOrPngOrGif = ["image/jpeg", "image/png", "image/gif"].includes(
      file.type
    );
    const isLt2M = file.size / 1024 / 1024 < 16;
    if (!isJpegOrPngOrGif) {
      message.error("You can only upload JPG/PNG/GIF file!");
      return false; // This prevents the file from being added to the list
    }
    if (!isLt2M) {
      message.error("Image must smaller than 15MB!");
      return false; // This prevents the file from being added to the list
    }
    return isJpegOrPngOrGif && isLt2M;
  };

  const beforeVideoUpload = (file: RcFile) => {
    const isMP4 = ["video/mp4", "video/mov"].includes(file.type);
    const isLt2M = file.size / 1024 / 1024 < 101;
    if (!isMP4) {
      message.error("You can only upload videos!");
      return false; // This prevents the file from being added to the list
    }
    if (!isLt2M) {
      message.error("Video must smaller than 100MB!");
      return false; // This prevents the file from being added to the list
    }
    return isMP4 && isLt2M;
  };

  const propsImage: UploadProps = {
    name: "file",
    multiple: false,
    action: uploadUrl,
    onChange(info) {
      setFileListImage([...info.fileList]); // Update the local fileList state

      const { status } = info?.file;

      if (status === "done") {
        const fileUrl = info.file.response.path;
        const fileTypeAndFormat = mime.getType(fileUrl || "");

        let fileType: string = "";
        let fileFormat: string = "";

        if (fileTypeAndFormat) {
          const splitTypeAndFormat = fileTypeAndFormat.split("/");
          if (splitTypeAndFormat.length > 1) {
            fileType = splitTypeAndFormat[0];
            fileFormat = splitTypeAndFormat[1];
          }
        }

        const newMedia: ICommunityMedia = {
          url: fileUrl || "",
          fileType,
          fileFormat,
        };

        setMediaArr([...mediaArr, newMedia]);

        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`); // Changed from fileName to name
      }
    },
    onDrop(e) {
      // console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const propsVideo: UploadProps = {
    name: "file",
    multiple: false,
    action: uploadUrl,
    onChange(info) {
      setFileListVideo([...info.fileList]); // Update the local fileList state

      const { status } = info.file;

      if (status === "done") {
        const fileUrl = info.file.response.path;
        const fileTypeAndFormat = mime.getType(fileUrl || "");

        let fileType: string = "";
        let fileFormat: string = "";

        if (fileTypeAndFormat) {
          const splitTypeAndFormat = fileTypeAndFormat.split("/");
          if (splitTypeAndFormat.length > 1) {
            fileType = splitTypeAndFormat[0];
            fileFormat = splitTypeAndFormat[1];
          }
        }

        const newMedia: ICommunityMedia = {
          url: fileUrl || "",
          fileType,
          fileFormat,
        };

        setMediaArr([...mediaArr, newMedia]);

        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);

        // Changed from fileName to name
      }
    },
    onDrop(e) {
      // console.log("Dropped files", e.dataTransfer.files);
    },
  };
  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    console.log(newTags);
    setTags(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue) {
      setTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue("");
  };

  const forMap = (tag: string) => {
    const tagElem = (
      <Tag
        closable
        onClose={(e) => {
          e.preventDefault();
          handleClose(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span key={tag} style={{ display: "inline-block" }}>
        {tagElem}
      </span>
    );
  };

  const tagChild = tags.map(forMap);

  const tagPlusStyle: React.CSSProperties = {
    background: token.colorBgContainer,
    borderStyle: "dashed",
  };

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);
  useEffect(() => {
    if (communityPost) {
      form.setFieldsValue(communityPost);
      if (communityPost) {
        setImageUrl(communityPost.mediaPath);
      }
    } else {
      form.resetFields();
    }
  }, [communityPost, form]);
  return (
    <div className="flex flex-col w-full">
      <Form
        form={form}
        layout="vertical"
        className="w-full flex flex-col"
        onFinish={onFinish}
      >
        <div className="w-full mb-1">
          <Form.Item
            name="title"
            label="Title"
            rules={[
              { required: true, message: "Please enter title to be posted" },
            ]}
          >
            <Input className="w-full" />
          </Form.Item>
        </div>
        <div className="w-full mb-1">
          <Form.Item
            name="content"
            label="What do you want to post about ?"
            rules={[
              { required: true, message: "Please enter content to be posted" },
            ]}
          >
            <TextArea className="w-full" rows={6} showCount maxLength={2000} />
          </Form.Item>
        </div>
        <div className="w-full mb-1">
          <Form.Item name="tags" label="Add tags">
            <div className="mb-4">
              <TweenOneGroup
                enter={{
                  scale: 0.8,
                  opacity: 0,
                  type: "from",
                  duration: 100,
                }}
                onEnd={(e) => {
                  if (e.type === "appear" || e.type === "enter") {
                    (e.target as any).style = "display: inline-block";
                  }
                }}
                leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                appear={false}
              >
                {tagChild}
              </TweenOneGroup>
            </div>
            {inputVisible ? (
              <Input
                ref={inputRef}
                type="text"
                size="small"
                className="mb-4"
                style={{ width: 78 }}
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleInputConfirm}
                onPressEnter={handleInputConfirm}
              />
            ) : (
              <Tag
                className="w-1/5 mb-4"
                onClick={showInput}
                style={tagPlusStyle}
              >
                <PlusOutlined /> New Tag
              </Tag>
            )}
          </Form.Item>
        </div>

        <div className="flex flex-col w-full items-start">
          <Upload
            {...propsImage}
            beforeUpload={beforeImageUpload}
            maxCount={2}
            accept=".jpg,.jpeg,.png"
            fileList={fileListImage}
          >
            <button aria-label="Picture Icon" type="button" className="mb-2">
              <CustomIcons.PictureIcon
                height={25}
                width={25}
                svgColor={Colors.appMainColor}
              />
            </button>
          </Upload>
          <Upload
            {...propsVideo}
            beforeUpload={beforeVideoUpload}
            maxCount={1}
            accept=".mp4"
            fileList={fileListVideo}
          >
            <button aria-label="Video Icon" type="button">
              <CustomIcons.VideoIcon
                height={25}
                width={25}
                svgColor={Colors.appMainColor}
              />
            </button>
          </Upload>
        </div>

        <div className="w-full mb-1 flex justify-end">
          <Form.Item className="pt-2 mb-1">
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="bg-[#1757a2] shadow-none"
            >
              Next
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default Post;

import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import Colors from "../../../config/Colors";

ChartJS.register(ArcElement, Tooltip, Legend);

interface IDashboardDoughnutProps {
  packages: IDashboardSubscriptionType;
}

export function DashboardDoughnut({ packages }: IDashboardDoughnutProps) {
  const data = {
    labels: [
      "Basic plan",
      "Family plan (2)",
      "Family plan (4)",
      "Family plan (6)",
      "Premium",
      "Premium Family plan (2)",
      "Premium Family plan (4)",
      "Premium Family plan (6)",
    ],
    datasets: [
      {
        label: "# of subscribers",
        data: [
          packages.Basic,
          packages.FamilyOf2,
          packages.FamilyOf4,
          packages.FamilyOf6,
          packages.Premium,
          packages.PremiumFamilyOf2,
          packages.PremiumFamilyOf4,
          packages.PremiumFamilyOf6,
        ],
        backgroundColor: [
          `${Colors.appMainColor}`,
          "#D6E8F7",
          `${Colors.secondaryColor}`,
        ],
        borderColor: [
          `${Colors.appMainColor}`,
          "#D6E8F7",
          `${Colors.secondaryColor}`,
        ],
        borderWidth: 1,
        hoverBorderWidth: 20,
      },
    ],
  };
  return (
    <div
      style={{ background: "#FFFFFF" }}
      className="px-3 shadow-5 surface-card border-round"
    >
      <div className="flex flex-row w-12 justify-content-between align-items-center">
        <div
          className="pt-3"
          style={{
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "18px",
            lineHeight: "27px",
            color: `${Colors.mainTextColor}`,
          }}
        >
          Subscriptions packages
        </div>
      </div>
      <div className="pb-2">
        <Doughnut
          data={data}
          options={{
            responsive: true,
            aspectRatio: 2,
            maintainAspectRatio: true,
            plugins: {
              legend: {
                display: true,
                position: "right" as const,
                align: "center",
                labels: {
                  usePointStyle: true,
                  padding: 30,
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
}

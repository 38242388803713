import { Button, Form, Input, Select, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import mime from "mime";
import Upload, { RcFile, UploadFile, UploadProps } from "antd/es/upload";
import Colors from "../../config/Colors";
import { CustomIcons } from "../../assets";
import { uploadUrl } from "@/config/urls";

type NewCauroselProp = {
  newCarouselValues?: IAddCarouselData;
  onFormFinish: (carousel: IAddCarouselData) => Promise<void>;
  onModalClose: () => void;
  isLoading: boolean;
  isSuccess: boolean;
};

const NewCarouselForm = ({
  newCarouselValues,
  onFormFinish,
  onModalClose,
  isLoading,
  isSuccess,
}: NewCauroselProp) => {
  const [form] = Form.useForm();
  const [fileListImage, setFileListImage] = useState<UploadFile[]>([]);
  const [mediaUrl, setMediaUrl] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const onFinish = async (formValues: IAddCarouselData) => {
    const values: IAddCarouselData = formValues as IAddCarouselData;
    values.icon = mediaUrl;
    await onFormFinish(values);
  };

  const handleCloseForm = () => {
    form.resetFields();
    setMediaUrl("");
    setFileListImage([]);
    onModalClose();
  };

  const handleChangeActionType = (value: string) => {
    const actionType = value;
    console.log(actionType);
  };

  const handleChangeCallToAction = (value: string) => {
    const callToAction = value;
    console.log(callToAction);
  };
  const beforeImageUpload = (file: RcFile) => {
    const isJpegOrPngOrGif = ["image/jpeg", "image/png", "image/gif"].includes(
      file.type
    );
    const isLt2M = file.size / 1024 / 1024 < 16;
    if (!isJpegOrPngOrGif) {
      message.error("You can only upload JPG/PNG/GIF file!");
      return false; // This prevents the file from being added to the list
    }
    if (!isLt2M) {
      message.error("Image must smaller than 16MB!");
      return false; // This prevents the file from being added to the list
    }
    return isJpegOrPngOrGif && isLt2M;
  };

  const propsImage: UploadProps = {
    name: "file",
    multiple: false,
    action: uploadUrl,
    onChange(info) {
      setFileListImage([...info.fileList]); // Update the local fileList state
      const { status } = info?.file;
      if (status === "uploading") {
        setIsUploading(true);
      } else if (status === "done") {
        setIsUploading(false);
        const fileUrl = info.file.response.path;
        setMediaUrl(fileUrl);
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        setIsUploading(false);
        message.error(`${info.file.name} file upload failed.`); // Changed from fileName to name
      }
    },
    onDrop(e: any) {
      //   console.log("Dropped files", e.dataTransfer.files);
    },
  };

  useEffect(() => {
    if (isSuccess) {
      handleCloseForm();
    }
  }, [isSuccess]);

  return (
    <Form
      form={form}
      name="carouselSlides"
      onFinish={onFinish}
      layout="vertical"
      style={{ width: "100%" }}
    >
      <Form.Item
        name="title"
        label="Title"
        rules={[
          {
            required: true,
            message: "Please input the carousel title",
          },
        ]}
        className="mb-1"
      >
        <Input size="large" />
      </Form.Item>

      <Form.Item
        name="content"
        label="Content"
        rules={[
          {
            required: true,
            message: "Please input content to be added to carousel",
          },
        ]}
      >
        <TextArea className="w-full" rows={4} showCount maxLength={170} />
      </Form.Item>

      <Form.Item
        label="Select a image"
        name="icon"
        rules={[
          {
            required: true,
            message: "Please select an image",
          },
          () => ({
            validator(_, value) {
              if (!mediaUrl) {
                return Promise.reject(new Error("Please upload an image!."));
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <Upload
          {...propsImage}
          beforeUpload={beforeImageUpload}
          maxCount={1}
          accept=".jpg,.jpeg,.png"
          fileList={fileListImage}
        >
          <button aria-label="Picture Icon" type="button" className="mb-2">
            <CustomIcons.PictureIcon
              height={25}
              width={25}
              svgColor={Colors.appMainColor}
            />
          </button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="actionType"
        label="Select action type"
        rules={[
          {
            required: true,
            message: "Please select action type",
          },
        ]}
      >
        <Select
          placeholder="Action type"
          size="large"
          onChange={handleChangeActionType}
          className="w-full"
        >
          <Select.Option value="CommunityFeed">Community Feed</Select.Option>
          <Select.Option value="Panic">Panic</Select.Option>
          <Select.Option value="Subscription">Subscription</Select.Option>
          <Select.Option value="TrackMe">Track Me</Select.Option>
          <Select.Option value="CrimeStats">Crime Stats</Select.Option>
          <Select.Option value="ReportCrime">Report Crime</Select.Option>
          <Select.Option value="Profile">Profile</Select.Option>
          <Select.Option value="PhysicalButton">Physical Button</Select.Option>
          <Select.Option value="Communities">Communities</Select.Option>
          <Select.Option value="Emergencies">Emergencies</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="callToAction"
        label="Select call to action"
        rules={[
          {
            required: true,
            message: "Please select call to action",
          },
        ]}
      >
        <Select
          placeholder="Call To Action"
          size="large"
          onChange={handleChangeCallToAction}
          className="w-full"
        >
          <Select.Option value="Enter Panic Info">
            Enter Panic Info
          </Select.Option>
          <Select.Option value="Edit Panic Info">Edit Panic Info</Select.Option>
          <Select.Option value="Subscribe">Subscribe</Select.Option>
          <Select.Option value="Edit Profile">Edit Profile</Select.Option>
          <Select.Option value="Upgrade Subscription">
            Upgrade Subscription
          </Select.Option>
          <Select.Option value="View New Group">View New Group</Select.Option>
          <Select.Option value="Invite Members To Plan">
            Invite Members To Plan
          </Select.Option>
          <Select.Option value="Invite Members To Group">
            Invite Members To Group
          </Select.Option>
          <Select.Option value="Join Family Group">
            Join Family Group
          </Select.Option>
          <Select.Option value="Join Family Subscription">
            Join Family Subscription
          </Select.Option>
          <Select.Option value="Report A Crime">Report A Crime</Select.Option>
          <Select.Option value="View Latest Crime Stats">
            View Latest Crime Stats
          </Select.Option>
          <Select.Option value="View The Feed">View The Feed</Select.Option>
          <Select.Option value="View New Post">View New Post</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="hidden"
        label="Status"
        rules={[
          {
            required: true,
            message: "Please select carousel status",
          },
        ]}
      >
        <Select placeholder="Select Status" size="large" className="w-full">
          <Select.Option value={"Active"}>Active</Select.Option>
          <Select.Option value={"InActive"}>InActive</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <div className="flex w-full gap-2 col-12 justify-content-end align-content-center">
          <Button
            type="primary"
            ghost
            onClick={handleCloseForm}
            size="large"
            className="w-3"
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="w-3 bg-[#1757a2] shadow-none"
            loading={isLoading}
            disabled={isUploading}
          >
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default NewCarouselForm;

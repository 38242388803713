import React, { useState } from "react";
import { Button, DatePicker, Form, Input, Modal, Select } from "antd";
import { NavLink } from "react-router-dom";
import { useHistory, Link } from "react-router-dom";
import { BodyContainer, Container } from "../../styles/ContainerStyles";
import HeaderComponent from "../../components/Header/Header";
import { tableName } from "../../styles/TableStyles";
import { CustomIcons } from "../../assets";
import UploadDocument from "../../components/Forms/UploadDocument";
import { modelTitle } from "../../styles/ModalStyles";

const AddUsers = () => {
  const [openUploadDocumentModal, setOpenUploadDocumentModal] = useState(false);
  const navigate = useHistory();
  return (
    <div className="h-full w-full">
      <Container className="h-full w-full">
        <BodyContainer className="h-full w-full">
          <HeaderComponent title="Users" />
          <div className="flex py-3 px-3 justify-content-between align-content-center">
            <div className="flex flex-row align-items-center" style={tableName}>
              Add Users
            </div>
            <div className="flex flex-row gap-1 justify-items-end">
              <Button
                type="primary"
                ghost
                size="large"
                onClick={() => setOpenUploadDocumentModal(true)}
              >
                Upload Excel Document
              </Button>
            </div>
          </div>

          <div className="h-full justify-center align-items-center">
            <Form
              // form={form}
              // name="newUser"
              // onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
              layout="vertical"
              style={{ width: "100%" }}
              className="grid mt-2 pl-3"
            >
              <div className="col-12 md:col-6">
                <Form.Item
                  name="firstName"
                  label="First Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input the user's first name!",
                    },
                  ]}
                  className="mb-1"
                >
                  <Input size="large" placeholder="Amanda" />
                </Form.Item>
              </div>

              <div className="col-12 md:col-6">
                <Form.Item
                  name="lastName"
                  label="Last Name"
                  rules={[
                    {
                      required: true,
                      message: "Please input the user's last name!",
                    },
                  ]}
                  className="mb-1"
                >
                  <Input size="large" placeholder="Neli" />
                </Form.Item>
              </div>

              <div className="col-12 md:col-6">
                <Form.Item
                  name="emailAddress"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: "Please input the user's email!",
                    },
                    { type: "email", message: "Please input a valid email!" },
                  ]}
                  className="mb-1"
                >
                  <Input size="large" placeholder="Amando@gmail.com" />
                </Form.Item>
              </div>

              <div className="col-12 md:col-6">
                <Form.Item
                  name="phoneNumber"
                  label="Phone Number"
                  rules={[
                    {
                      required: true,
                      message: "Please enter user's phone number!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        // const phoneNumberPattern = /^(\+27|0)[6-8][0-9]{8}$/;
                        const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;
                        if (phoneNumberPattern.test(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Please enter a valid phoneNumber!")
                        );
                      },
                    }),
                  ]}
                  className="mb-1"
                >
                  <Input size="large" placeholder="+2763256392" />
                </Form.Item>
              </div>

              <div className="col-12 md:col-6">
                <Form.Item
                  name="dateOfBirth"
                  label="Date of birth"
                  rules={[
                    {
                      required: true,
                      message: "Please enter user's date of birth!",
                    },
                  ]}
                  className="mb-3"
                >
                  <DatePicker size="large" style={{ width: "100%" }} />
                </Form.Item>
              </div>

              <div className="col-12 md:col-6">
                <Form.Item
                  name="gender"
                  label="Gender"
                  rules={[
                    {
                      required: true,
                      message: "Please select the user's gender!",
                    },
                  ]}
                  className="mb-3"
                >
                  <Select placeholder="Select user's gender" size="large">
                    <Select.Option value="Male">Male</Select.Option>
                    <Select.Option value="Female">Female</Select.Option>
                    <Select.Option value="Other">Other</Select.Option>
                  </Select>
                </Form.Item>
              </div>

              <Form.Item className="w-12 mb-1">
                <div className="flex w-full gap-2 col-12 justify-between justify-content-end align-content-center">
                  <Button
                    type="primary"
                    ghost
                    size="large"
                    className="w-3"
                    onClick={() => {
                      navigate.goBack();
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="primary"
                    //   htmlType="submit"
                    //   loading={isLoading}
                    size="large"
                    className="w-3 bg-[#1757a2] shadow-none"
                  >
                    Save
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </BodyContainer>
        <Modal
          centered
          open={openUploadDocumentModal}
          onOk={() => setOpenUploadDocumentModal(false)}
          onCancel={() => setOpenUploadDocumentModal(false)}
          width={550}
          closeIcon={
            <CustomIcons.CloseIcon
              width={30}
              height={30}
              viewBox={null}
              svgColor="#1C274C"
            />
          }
          footer={null}
        >
          <div
            className="flex w-12 py-3 justify-content-center"
            style={modelTitle}
          >
            Upload your files
          </div>
          <div className="ant-upload-text flex mb-4 justify-content-center">
            File should be Excel
          </div>
          <UploadDocument
            actionURL="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            onComplete={(done) => {}}
          />
        </Modal>
      </Container>
    </div>
  );
};

export default AddUsers;

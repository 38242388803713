import React from "react";
import Colors from "../../config/Colors";

export function PictureIcon({
  height, // number type by default
  width,
  viewBox = "0 0 20 20",
  svgColor,
}: CustomSVGProp) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox || "0 0 20 20"}
    >
      <path
        fill={svgColor}
        d="M19.09 2a.9.9 0 0 1 .91.889V17.11a.9.9 0 0 1-.91.889H.91A.9.9 0 0 1 0 17.11V2.89A.9.9 0 0 1 .91 2h18.18ZM5.416 8.417l-4.06 4.042v4.217H18.64v-1.433l-3.2-3.12l-2.777 2.333c-.166.117-.326.168-.48.155a.792.792 0 0 1-.439-.189L5.415 8.417Zm13.22-5.086H1.362v7.23L4.968 6.97a.718.718 0 0 1 .44-.156c.155 0 .291.047.41.14l6.431 6.088l2.805-2.35a.704.704 0 0 1 .421-.146a.69.69 0 0 1 .418.145l2.742 2.665V3.33ZM15.273 5.23c.753 0 1.363.597 1.363 1.333s-.61 1.333-1.363 1.333c-.754 0-1.364-.597-1.364-1.333s.61-1.333 1.364-1.333Z"
      />
    </svg>
  );
}
export default PictureIcon;

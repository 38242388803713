import React from "react";
import Colors from "../../config/Colors";

export function CalendarCancelIcon({
  height, // number type by default
  width,
  viewBox = "0 0 24 24",
  svgColor,
}: CustomSVGProp) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox || "0 0 24 24"}
    >
      <path
        fill={svgColor || "#000000"}
        d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.1 0-2 .9-2 2v14a2 2 0 0 0 2 2h14c1.11 0 2-.89 2-2V5a2 2 0 0 0-2-2m0 16H5V9h14zM5 7V5h14v2zm3.23 9.41l1.06 1.06l2.44-2.44l2.44 2.44l1.06-1.06l-2.44-2.44l2.44-2.44l-1.06-1.06l-2.44 2.44l-2.44-2.44l-1.06 1.06l2.44 2.44z"
      />
    </svg>
  );
}
export default CalendarCancelIcon;

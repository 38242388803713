/* eslint-disable max-len */
/* eslint-disable no-console */
import { Layout } from "antd";
import { observer } from "mobx-react-lite";
import React, { useState, useContext, useEffect, useRef } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import notification from "antd/es/notification";
import { MessageOutlined } from "@ant-design/icons";
import { useQueryClient, useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { Layouts, UserType, OnlineStatus } from "../util/enums";
import { AuthContext } from "../contexts/AuthContext";
import { homePages } from "../pages";
import Siders from "../components/Siders/Siders";
import { APP_LOCAL_URL_HOST, APP_URL_HOST } from "../config/urls";
import { NotificationSound } from "../assets";
import {
  useDirectMessageByCase,
  useDirectMessageByIds,
} from "../hooks/useDirectMessage";
import { useFetchUser } from "../hooks/useUsers";
import {
  useOperatorEmergencyCases,
  useUpdateOperatorOnlineStatus,
} from "../hooks/useOperators";
import { queries } from "../services/queries";
import { Users } from "../services";
import { useGetCommunityAdminCases } from "../hooks/useCommunities";
import { decodeMessage } from "../util/helpers";

const Home = observer(() => {
  const navigate = useHistory();
  const {
    hasRoutePermission,
    user,
    isAdmin,
    isOperator,
    isOperatorAdmin,
    isCommunityAdmin,
    isLoggedIn,
    getUserType,
    getEmergencyId,
    hasRole,
    setUser,
    logout,
  } = useContext(AuthContext);
  const queryClient = useQueryClient();

  // Update Currently Logged In User
  const { data: updatedUserData } = useFetchUser(user?.id);

  // Slider Here
  const [collapsed, setCollapsed] = useState(false);

  // Real-Time Messaging Here
  const [currentUserId, setCurrentUserId] = useState<string | null | number>(0);
  const [currentCaseId, setCurrentCaseId] = useState<string | number | null>(0);
  const [currentCommunityCaseId, setCurrentCommunityCaseId] = useState<
    string | number | null
  >(null);
  const [currentReceiverId, setCurrentReceiverId] = useState<
    string | number | null
  >(0);
  const [queriesEnabled, setQueriesEnabled] = useState(false);

  const updateOnlineStatusMutation = useUpdateOperatorOnlineStatus();

  // Fetches Messages by Emergency Case Id and stores it in Cache
  const loadMessageByCase = useDirectMessageByCase(currentCaseId);

  // Fetches Messages By CurrentUser and Partner/Sender of the new message and Stores it in Cache
  const loadMssageByIds = useDirectMessageByIds(
    currentUserId,
    currentReceiverId,
    queriesEnabled
  );

  // Fetches Emergency Cases By Current Operator, allows us to refetch the emergency cases details
  const { refetch } = useOperatorEmergencyCases(
    currentUserId,
    isOperator || isOperatorAdmin
  );

  // CommunityAdmin
  const { refetch: refetchCommunityAdmin } = useGetCommunityAdminCases(
    currentUserId,
    isCommunityAdmin
  );

  const buildHubConnection = (userId: string | number): HubConnection => {
    return new HubConnectionBuilder()
      .withUrl(`${APP_URL_HOST}/chatHub?userId=${userId}`)
      .withAutomaticReconnect()
      .build();
  };

  const handleStatus = (status: OnlineStatus, operatorId: number) => {
    try {
      updateOnlineStatusMutation.mutateAsync({
        status,
        operatorId,
      });
    } catch (error) {}
  };

  useEffect(() => {
    let messageConnection: HubConnection | null = null;
    const startMessageConnection = async (userId: string | number) => {
      messageConnection = buildHubConnection(userId);

      messageConnection.on("DirectMessage", (message: any, ...args) => {
        const receivedMessage = JSON.parse(message) as IDirectMessage;
        // this.unreadMessages.push(receivedMessage);
        notification.info({
          message: "New Message",
          icon: React.createElement(MessageOutlined),
          description: decodeMessage(receivedMessage.messageText),
          className: "message-notification",
        });
        const audio = new Audio(NotificationSound);
        audio.play().catch(() => {
          audio.muted = true;
          audio.play().catch(() => {});
        });
        setCurrentReceiverId(receivedMessage.senderId);
        receivedMessage.dateAdded = DateTime.utc().toString();
        if (receivedMessage.emergencyCaseId) {
          setCurrentCaseId(receivedMessage.emergencyCaseId);
          // loadMessageByCase.refetch();
          queryClient.setQueryData(
            queries.directMessage.conversationByCase(
              receivedMessage.emergencyCaseId
            ).queryKey,
            (oldDirectMessagesData: IDirectMessage[] = []) => {
              return [...oldDirectMessagesData, receivedMessage];
            }
          );
        }
        setQueriesEnabled(true);
        refetch();
      });

      messageConnection.on("TransferredCase", (message: any, ...args) => {
        const receivedMessage = JSON.parse(message) as ITransferCase;
        // this.unreadMessages.push(receivedMessage);
        notification.info({
          message: "New Transfer Case",
          icon: React.createElement(MessageOutlined),
          description: "A case has been transferred to you",
          className: "message-notification",
        });
        const audio = new Audio(NotificationSound);
        audio.play().catch(() => {
          audio.muted = true;
          audio.play().catch(() => {});
        });
        setCurrentReceiverId(receivedMessage.userID);
        receivedMessage.dateAdded = DateTime.utc().toString();
        if (receivedMessage.id) {
          setCurrentCaseId(receivedMessage.id);
          // loadMessageByCase.refetch();

          queryClient.invalidateQueries(
            queries.directMessage.conversationByCase(receivedMessage.id)
          );
        }
        setQueriesEnabled(true);
        refetch();
      });

      messageConnection.on("CommunityMessage", (message: any, ...args) => {
        const receivedMessage = JSON.parse(message) as ICommunityMessage;
        notification.info({
          message: "New Message",
          icon: React.createElement(MessageOutlined),
          description: decodeMessage(receivedMessage.messageText),
          className: "message-notification",
        });
        const audio = new Audio(NotificationSound);
        audio.play().catch(() => {
          audio.muted = true;
          audio.play().catch(() => {});
        });
        if (receivedMessage.communityCaseId) {
          setCurrentCommunityCaseId(receivedMessage.communityCaseId);
          // loadMessageByCase.refetch();
          queryClient.setQueryData(
            queries.communityMessage.GetCommunityCaseMessages(
              receivedMessage.communityCaseId
            ).queryKey,
            (oldDirectMessagesData: ICommunityMessage[] = []) => {
              return [...oldDirectMessagesData, receivedMessage];
            }
          );
        }
        refetchCommunityAdmin();
      });
      messageConnection.on("NewCase", (...args) => {
        notification.info({
          message: "New Case",
          icon: React.createElement(MessageOutlined),
          description: "You have a new case",
          className: "message-notification",
        });
        const audio = new Audio(NotificationSound);
        audio.play().catch(() => {
          audio.muted = true;
          audio.play().catch(() => {});
        });
        refetchCommunityAdmin();
      });

      messageConnection.onclose((error) => {
        console.error("SignalR connection closed: ", error);
      });

      try {
        await messageConnection.start();
      } catch (error) {
        console.log(error);
      }
    };
    if (user) {
      setCurrentUserId(user.id);
      startMessageConnection(user.id);
      if (isOperator || (isOperatorAdmin && getEmergencyId())) {
        handleStatus(OnlineStatus.Online, user.id);
      }
    }

    // cleanup function
    return () => {
      messageConnection?.stop();

      if (isOperator || (isOperatorAdmin && getEmergencyId())) {
        handleStatus(OnlineStatus.Offline, user?.id || 0);
      }
    };
  }, [user, hasRoutePermission, logout]);

  useEffect(() => {
    if (updatedUserData) {
      // Check if the user has any of the privileged roles
      const hasPrivilegedRole = updatedUserData.userRoles.some((role) =>
        [
          "SuperAdmin",
          "EmergencyAdmin",
          "CommunityAdmin",
          "EmergencyOperator",
        ].includes(role.role.name)
      );

      if (!hasPrivilegedRole) {
        logout();
        if (isOperator || (isOperatorAdmin && getEmergencyId())) {
          handleStatus(OnlineStatus.Offline, updatedUserData.id || 0);
        }
      } else {
        setUser(updatedUserData);
      }
    }
  }, [
    updatedUserData,
    logout,
    setUser,
    isOperator,
    isOperatorAdmin,
    handleStatus,
  ]);

  const getRoutes = (defaultRoutes: any[]) => {
    return defaultRoutes
      .map((prop, index) => {
        if (
          prop.layout === Layouts.Home &&
          hasRoutePermission(prop.platformRights)
        ) {
          return (
            <Route
              key={index}
              path={prop.layout + prop.path}
              exact={prop.exact}
              component={prop.component}
            />
          );
        }
        return null;
      })
      .filter(Boolean);
  };
  useEffect(() => {
    const handleBeforeUnload = () => {
      if (user) {
        if ((isOperator || isOperatorAdmin) && getEmergencyId()) {
          handleStatus(OnlineStatus.Offline, user?.id || 0);
        }
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [user]);

  return (
    <Layout className="w-screen max-h-screen">
      <Siders collapsed={collapsed} routes={homePages} />
      <Layout.Content className="max-h-screen overflow-y-auto max-w-11/12 disable-scrollbars">
        <Switch>{getRoutes(homePages)}</Switch>
      </Layout.Content>
    </Layout>
  );
});

export default Home;

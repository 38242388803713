import { Button, Checkbox, Form, Input, Select, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { CustomIcons } from "../../assets";
import { useGetCarouselDataById } from "../../hooks/useCarousel";
import { tr } from "@faker-js/faker";

type editCarousalProp = {
  id: number | null | string;
  onFormFinish: (carousel: IEditCarouselData) => void;
  onModalClose: () => void;
  isLoading: boolean;
  isSuccess: boolean;
};

const EditCarousel = ({ id, onFormFinish, onModalClose }: editCarousalProp) => {
  let checked: boolean;
  const { data, isLoading, isFetching, remove } = useGetCarouselDataById(id);

  const [form] = Form.useForm();
  console.log(data);

  const handleCloseForm = () => {
    onModalClose();
  };

  const onFinish = async (formValues: IEditCarouselData) => {
    const values: IEditCarouselData = formValues as IEditCarouselData;
    values.id = id as number;
    await onFormFinish(values);
    console.log(values);
  };

  const handleStatusType = (value: string) => {
    const actionType = value;
    console.log(actionType);
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    } else {
      form.resetFields();
    }
  }, [data]);

  return (
    <>
      {isLoading ? (
        <div>
          <CustomIcons.EOSThreeDotsLoading
            width={30}
            height={30}
            viewBox={null}
            svgColor="#1C274C"
          />
        </div>
      ) : (
        <Form
          form={form}
          name="carouselSlides"
          onFinish={onFinish}
          layout="vertical"
          style={{ width: "100%" }}
          initialValues={data}
        >
          <Form.Item
            name="title"
            label="Title"
            rules={[
              {
                required: true,
                message: "Please input the carousel title",
              },
            ]}
            className="mb-1"
          >
            <Input size="large" value={data?.title} />
          </Form.Item>

          <Form.Item
            name="content"
            label="Content"
            rules={[
              {
                required: true,
                message: "Please input content to be added to carousel",
              },
            ]}
          >
            <TextArea className="w-full" rows={4} showCount maxLength={250} />
          </Form.Item>

          <Form.Item
            name={"status"}
            label="Status"
            rules={[
              {
                required: true,
                message: "Please select carousel status",
              },
            ]}
          >
            <Select
              placeholder="Select Status"
              size="large"
              onChange={handleStatusType}
              className="w-full"
              defaultValue={data?.status as string}
            >
              <Select.Option value={"Active"}>Active</Select.Option>
              <Select.Option value={"InActive"}>InActive</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <div className="flex w-full gap-2 col-12 justify-content-end align-content-center">
              <Button
                type="primary"
                ghost
                onClick={handleCloseForm}
                size="large"
                className="w-3"
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="w-3 bg-[#1757a2] shadow-none"
              >
                Save
              </Button>
            </div>
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default EditCarousel;

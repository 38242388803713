import { Button, Modal, Spin, Tag, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import moment from "moment";

import { FilterMatchMode, FilterOperator } from "primereact/api";

import { Calendar, CalendarChangeEvent } from "primereact/calendar";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import debounce from "lodash/debounce";
import { toast } from "sonner";
import { tableBody, tableHeader } from "../../styles/TableStyles";
import CustomInputField from "../../components/widgets/Inputs/CustomInputField";
import {
  useUsersWithAffiliaition,
  usePostAffiliationCode,
  useAllAffiliationCodes,
  useGetPaginatedAffiliationCode,
  useUpdateAffiliationCode,
  useGetPrimaryCategory,
  useGetTertiaryCategory,
} from "../../hooks/useCommunityCodes";
import { CommunityCode as ReferralCodeService } from "../../services";
import { CustomIcons } from "../../assets";
import {
  modelTitle,
  successSubtitle,
  successTitle,
} from "../../styles/ModalStyles";
import NewReferralcode from "../../components/Forms/ReferralCodeForms/NewReferralcode";
import ReferralCodeFormIndex from "@/components/Forms/ReferralCodeForms/ReferralCodeFormIndex";
import { useGetSecondaryCategory } from "../../hooks/useCommunityCodes";

const ReferralCode = () => {
  // @ts-ignore
  const dt = useRef<DataTable>(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [filtersState, setFiltersState] = useState<AffiliationFilters>({
    code: { values: [""], matchModes: ["contains"] },
    status: { values: [""], matchModes: ["contains"] },
    subscriptionPlan: { values: [""], matchModes: ["contains"] },
    primaryCategory: { values: [""], matchModes: ["equals"] },
    secondaryCategory: { values: [""], matchModes: ["equals"] },
    tertiaryCategory: { values: [""], matchModes: ["equals"] },
  });
  const {
    isLoading,
    isFetching,
    data: affiliationCodeData,
    refetch,
  } = useGetPaginatedAffiliationCode(first, rows, filtersState);

  const { data: primaryCategories } = useGetPrimaryCategory();
  const { data: secondaryCategories } = useGetSecondaryCategory();
  const { data: tertiaryCategories } = useGetTertiaryCategory();

  const allReferralCodes = useAllAffiliationCodes();
  const postAffiliationCode = usePostAffiliationCode();
  const updateAffiliationCode = useUpdateAffiliationCode();
  const [users, setUsers] = useState<IAffiliationUser[]>([]);
  const [filters, setFilters] = useState<DataTableFilterMeta | undefined>(
    undefined
  );
  const [openDownloadButtonsModal, setOpenDownloadButtonsModal] =
    useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [codes] = useState<string[]>([]);
  const [statuses] = useState<string[]>([
    "Subscribed",
    "Unsubscribed",
    "Non-Renewing",
  ]);

  const cols = [
    { field: "firstUsed", header: "First Used" },
    { field: "firstName", header: "First Name" },
    { field: "lastName", header: "Last Name" },
    { field: "code", header: "Community Code" },
    { field: "primaryCategory", header: "Primary Category" },
    { field: "secondaryCategory", header: "Secondary Category" },
    { field: "tertiaryCategory", header: "Tertiary Category" },
    { field: "subscriptionPlan", header: "Subscription Plan" },
    { field: "status", header: "Subscription status" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const onFilter = (event: { filters: ITableFilters }) => {
    console.log(event);

    const newFilters: { [key: string]: IFilterParam } = {};
    Object.keys(event.filters).forEach((key) => {
      const filter: ITableFilter = event.filters[key];

      // Check if the filter has 'constraints' (for complex filters)
      if (filter.constraints) {
        newFilters[key] = {
          values: filter.constraints.map(
            (constraint) => constraint.value || ""
          ),
          matchModes: filter.constraints.map(
            (constraint) => constraint.matchMode
          ),
        };
      } else {
        // Simple filter
        newFilters[key] = {
          values: [filter.value || ""],
          matchModes: [filter.matchMode || ""],
        };
      }
    });

    setFiltersState(newFilters);
    setFirst(0); // Reset to the first page
  };

  const onPage = (event: any) => {
    console.log(event);
    setFirst(event.first);
    setRows(event.rows);
  };

  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const [globalFilterSearch, setGlobalFilterSearch] = useState<string>("");

  const debounceSetGlobalFilterValue = debounce((value) => {
    setGlobalFilterValue(value);
  }, 500);

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      code: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      status: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      subscriptionPlan: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      primaryCategory: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      secondaryCategory: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      tertiaryCategory: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
    });
    setGlobalFilterValue("");
  };
  const onGlobalFilterChange = (e: string) => {
    const _filters = { ...filters };
    // @ts-ignore
    // _filters.global.value = e;

    // setFilters(_filters);
    // Use the debounce function
    debounceSetGlobalFilterValue(e);
    setGlobalFilterSearch(e);
    setFirst(0);
  };

  const exportPdf = async () => {
    toast.promise(ReferralCodeService.downloadPdfReferralCodes(filtersState), {
      loading: "Exporting...",
      success: () => {
        return "Exported successfully";
      },
      error: "Error exporting Referral Code table, please try again later.",
    });
  };

  const exportCSV = async (selectionOnly: boolean) => {
    toast.promise(
      ReferralCodeService.downloadExcelReferralCodes(filtersState),
      {
        loading: "Exporting...",
        success: () => {
          return "Exported successfully";
        },
        error: "Error exporting Referral Code table, please try again later.",
      }
    );
  };

  useEffect(() => {
    if (allReferralCodes.data) {
      codes.length = 0;

      codes.push(...allReferralCodes.data.map((d) => d.code));
    }
  }, [allReferralCodes.data]);

  useEffect(() => {
    const currentFilers = { ...filtersState };
    if (globalFilterValue) {
      currentFilers.code = {
        values: [globalFilterValue],
        matchModes: ["contains"],
      };
    } else {
      delete currentFilers.code;
    }

    setFiltersState(currentFilers);
    //refetchFilteredUsers();
  }, [globalFilterValue]);

  useEffect(() => {
    initFilters();
  }, []);

  const getSeverity = (status: string) => {
    switch (status) {
      case "Subscribed":
        return "success";
      case "Unsubscribed":
        return "error";
      case "Non-Renewing":
        return "warning";
      default:
        return "processing";
    }
  };

  const dateBodyTemplate = (rowData: IAffiliationUser) => {
    return moment(rowData.firstUsed).format("YYYY/MM/DD");
  };

  const statusBodyTemplate = (rowData: IAffiliationUser) => {
    return <Tag color={getSeverity(rowData.status)}>{rowData.status}</Tag>;
  };

  const statusItemTemplate = (option: string) => {
    return <Tag color={getSeverity(option)}>{option}</Tag>;
  };

  const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e: CalendarChangeEvent) => {
          options.filterCallback(e.value, options.index);
        }}
        dateFormat="yy/mm/dd"
        placeholder="yyyy/mm/dd"
        mask="9999/99/99"
      />
    );
  };

  const statusFilterTemplate = (
    options: ColumnFilterElementTemplateOptions
  ) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e: DropdownChangeEvent) =>
          options.filterCallback(e.value, options.index)
        }
        itemTemplate={statusItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
      />
    );
  };

  const referralFilterTemplate = (
    options: ColumnFilterElementTemplateOptions
  ) => {
    return (
      <Dropdown
        value={options.value}
        options={codes}
        onChange={(e: DropdownChangeEvent) =>
          options.filterCallback(e.value, options.index)
        }
        itemTemplate={statusItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
      />
    );
  };

  const handleClose = async () => {
    setOpenAddModal(false);
    postAffiliationCode.reset();
  };

  return (
    <div className="flex w-full h-full bg-backgroundColor">
      <div className="w-full h-full max-h-screen overflow-y-auto disable-scrollbars">
        <Spin tip="" spinning={isLoading || isFetching} delay={500}>
          <div className="w-full h-full px-3 card Users">
            <div className="flex flex-row justify-end w-full gap-1 mb-2 align-items-center">
              <Button
                type="primary"
                ghost
                size="large"
                className="w-3 ml-2"
                onClick={() => setOpenAddModal(!openAddModal)}
              >
                Manage Referral code
              </Button>
              <Button
                icon={<DownloadOutlined />}
                onClick={() => setOpenDownloadButtonsModal(true)}
                loading={isExporting}
                type="primary"
                ghost
                size="large"
                className="w-1"
              />
              <CustomInputField
                value={globalFilterSearch}
                onChange={(e: string) => onGlobalFilterChange(e)}
                onPressEnter={() => {}}
                inputProps={{
                  size: "large",
                  placeholder: "Search",
                  allowClear: true,
                  className: "ml-2 w-2",
                  prefix: <SearchOutlined />,
                }}
                theme={CustomInputField}
                mergeThemes
              />
            </div>
          </div>
          <DataTable
            value={affiliationCodeData?.userCodes || []}
            ref={dt}
            first={first}
            totalRecords={affiliationCodeData?.totalRecords || 0}
            paginator
            rows={rows}
            onPage={onPage}
            lazy
            rowsPerPageOptions={[10, 25, 50, 100]}
            dataKey="id"
            filters={filters}
            onFilter={onFilter}
            globalFilterFields={[
              "code",
              "subscriptionPlan",
              "status",
              "primaryCategory",
              "secondaryCategory",
              "tertiaryCategory",
            ]}
            emptyMessage="No users found."
            rowHover
            scrollable
            scrollHeight="40rem"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Users"
            stateStorage="session"
            stateKey="dt-state-referral-code-table"
            className="mx-3"
          >
            <Column
              field="firstUsed"
              header="First Used"
              headerStyle={tableHeader}
              dataType="date"
              body={dateBodyTemplate}
              bodyStyle={tableBody}
              style={{ background: "#FBFBFB" }}
            />
            <Column
              field="firstName"
              header="First Name"
              headerStyle={tableHeader}
              style={{ background: "#FBFBFB" }}
              bodyStyle={tableBody}
            />
            <Column
              field="lastName"
              header="Last name"
              headerStyle={tableHeader}
              style={{ background: "#FBFBFB" }}
              bodyStyle={tableBody}
            />
            <Column
              field="code"
              header="Referral Code"
              headerStyle={tableHeader}
              style={{ background: "#FBFBFB" }}
              bodyStyle={tableBody}
              filter
              filterElement={referralFilterTemplate}
              filterMatchModeOptions={[
                { label: "Contains", value: "contains" },
              ]}
            />
            <Column
              field="primaryCategory"
              header="Primary Category"
              headerStyle={tableHeader}
              style={{ background: "#FBFBFB" }}
              bodyStyle={tableBody}
              filter
              filterPlaceholder="Search by Primary Category"
              filterMenuStyle={{ width: "14rem" }}
              filterMatchModeOptions={[{ label: "Equals", value: "equals" }]}
            />
            <Column
              field="secondaryCategory"
              header="Secondary Category"
              headerStyle={tableHeader}
              style={{ background: "#FBFBFB" }}
              bodyStyle={tableBody}
              filter
              filterPlaceholder="Search by Secondary Category"
              filterMenuStyle={{ width: "14rem" }}
              filterMatchModeOptions={[{ label: "Equals", value: "equals" }]}
            />
            <Column
              field="tertiaryCategory"
              header="Tertiary Category"
              headerStyle={tableHeader}
              style={{ background: "#FBFBFB" }}
              bodyStyle={tableBody}
              filter
              filterPlaceholder="Search by Tertiary Category"
              filterMenuStyle={{ width: "14rem" }}
              filterMatchModeOptions={[{ label: "Equals", value: "equals" }]}
            />
            <Column
              field="subscriptionPlan"
              header="Subscription Plan"
              headerStyle={tableHeader}
              style={{ background: "#FBFBFB" }}
              filter
              filterPlaceholder="Search by plan"
              filterMenuStyle={{ width: "14rem" }}
              filterMatchModeOptions={[{ label: "Equals", value: "equals" }]}
            />
            <Column
              field="status"
              header="Subscription status"
              headerStyle={tableHeader}
              style={{ background: "#FBFBFB" }}
              body={statusBodyTemplate}
              filter
              filterElement={statusFilterTemplate}
              filterMatchModeOptions={[{ label: "Equals", value: "equals" }]}
            />
          </DataTable>
        </Spin>
      </div>
      <Modal
        centered
        open={openAddModal}
        onOk={() => setOpenAddModal(false)}
        onCancel={handleClose}
        width={550}
        closeIcon={
          <CustomIcons.CloseIcon
            width={30}
            height={30}
            viewBox={null}
            svgColor="#1C274C"
          />
        }
        footer={null}
      >
        <div className="w-full pt-2 mx-2">
          {postAffiliationCode.isSuccess ? (
            <div className="flex w-full h-full gap-2 flex-column align-items-center justify-content-center">
              <div className="flex w-full justify-content-center">
                <CustomIcons.SuccessIcon
                  width={100}
                  height={100}
                  viewBox={null}
                  svgColor="#179717"
                />
              </div>
              <div style={successTitle}> Successful</div>
              <div style={successSubtitle} className="pb-4">
                {" "}
                You have successfully added a new Referral Code
              </div>
              <Button
                type="primary"
                ghost
                onClick={handleClose}
                size="large"
                className="w-4"
              >
                close
              </Button>
            </div>
          ) : (
            <>
              <div
                className="flex w-12 py-3 justify-content-start"
                style={modelTitle}
              >
                Manage Referral code
              </div>
              <ReferralCodeFormIndex
                primaryCategories={primaryCategories || []}
                secondaryCategories={secondaryCategories || []}
                tertiarycategories={tertiaryCategories || []}
                onModalClose={handleClose}
                refetch={refetch}
              />
            </>
          )}
        </div>
      </Modal>
      <Modal
        centered
        open={openDownloadButtonsModal}
        onOk={() => setOpenDownloadButtonsModal(false)}
        onCancel={() => setOpenDownloadButtonsModal(false)}
        width={550}
        closeIcon={
          <CustomIcons.CloseIcon
            width={30}
            height={30}
            viewBox={null}
            svgColor="#1C274C"
          />
        }
        footer={null}
      >
        <div
          className="flex w-12 py-3 justify-content-start"
          style={modelTitle}
        >
          Download As
        </div>
        <Button
          className="flex justify-center w-full px-2 mb-1 text-white bg-successColor"
          onClick={() => {
            exportCSV(false);
          }}
        >
          CSV
        </Button>
        <Button
          className="flex justify-center w-full px-2 mb-1 text-white bg-errorColor"
          onClick={() => exportPdf()}
        >
          PDF
        </Button>
      </Modal>
    </div>
  );
};

export default ReferralCode;

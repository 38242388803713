import React from "react";
import { Modal, Button, message } from "antd";
import {
  successTitle,
  successSubtitle,
  modelTitle,
} from "../../styles/ModalStyles";
import { CustomIcons } from "../../assets/index";
import NewCommunityForm from "../Forms/NewCommunity";
import { useUpdateCommunity } from "../../hooks/useCommunities";
import { copyObjects } from "../../util/copyObjects";

interface EditCommunityModalProp {
  values?: ICommunity;
  openEditModal: boolean;
  setOpenEditModal: (open: boolean) => void;
}

const EditCommunityModal = ({
  values,
  openEditModal,
  setOpenEditModal,
}: EditCommunityModalProp) => {
  const updateCommunity = useUpdateCommunity();
  const [messageApi, contextHolder] = message.useMessage();
  const messageKey = "createEditCommunityModal";
  const handleClose = async () => {
    updateCommunity.reset();
    setOpenEditModal(false);
  };
  const handleEditCommunity = async (community: IAddCommunity) => {
    if (values) {
      try {
        messageApi.open({
          key: messageKey,
          type: "loading",
          content: "updating Community..",
          duration: 0,
        });
        const editedCommunity = await copyObjects(values, community);

        await updateCommunity.mutateAsync(editedCommunity);
        messageApi.open({
          key: messageKey,
          type: "success",
          content: "Updated Community..",
          duration: 0,
        });
        setTimeout(() => {
          messageApi.destroy(messageKey);
        }, 2000);
      } catch (error) {
        // close Message
        messageApi.destroy(messageKey);
      }
    }
  };

  return (
    <Modal
      centered
      open={openEditModal}
      onOk={() => setOpenEditModal(false)}
      onCancel={() => handleClose()}
      width={550}
      closeIcon={
        <CustomIcons.CloseIcon
          width={30}
          height={30}
          viewBox={null}
          svgColor="#1C274C"
        />
      }
      footer={null}
    >
      {contextHolder}
      <div className="w-full pt-2 mx-2">
        {updateCommunity.isSuccess ? (
          <div className="flex w-full h-full gap-2 flex-column align-items-center justify-content-center">
            <div className="flex w-full justify-content-center">
              <CustomIcons.SuccessIcon
                width={100}
                height={100}
                viewBox={null}
                svgColor="#179717"
              />
            </div>
            <div style={successTitle}> Successful</div>
            <div style={successSubtitle} className="pb-4">
              {" "}
              You have successfully edited a community
            </div>
            <Button
              type="primary"
              ghost
              onClick={handleClose}
              size="large"
              className="w-4"
            >
              close
            </Button>
          </div>
        ) : (
          <>
            <div
              className="flex w-12 py-3 justify-content-start"
              style={modelTitle}
            >
              Edit community
            </div>

            <NewCommunityForm
              communityValues={values || undefined}
              onFormFinish={(community: IAddCommunity) =>
                handleEditCommunity(community)
              }
              onModelClose={() => {
                handleClose();
              }}
              isLoading={updateCommunity.isLoading}
              isSuccess={updateCommunity.isSuccess}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default EditCommunityModal;

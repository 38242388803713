// import styled from 'styled-components';
import { CSSProperties } from "react";
import Colors from "../../../config/Colors";

export const cardStyles = {
  backgroundColor: `${Colors.subtleBlue}`,
  padding: "1rem",
  border: "none",
  justifyContent: "flex-start",
  // borderRadius: '0.5rem',
};

export const imageStyles = {
  paddingBottom: "0.5rem",
};

export const titleStyles: CSSProperties = {
  color: Colors.appMainColor,
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 600,
  paddingLeft: "1rem",
  paddingRight: "1rem",
};

export const contentStyles = {
  display: "flex",
  alignItems: "center",
};

export const subheaderStyles: CSSProperties = {
  display: "flex",
  alignItems: "flex-start",
};

export const iconStyles = {
  width: "2rem",
  marginRight: "0.5rem",
};

export const subtitleStyles: CSSProperties = {
  color: Colors.mainTextColor,
  fontSize: "13px",
  fontWeight: "normal",
  textAlign: "left",
};

export const btnStyles = {
  display: "flex",
  color: Colors.appMainColor,
  alignSelf: "flex-start",
  width: "100%",
  borderWidth: "0",
  backgroundColor: `${Colors.subtleBlue}`,
};

export const btnTextStyles = {
  color: Colors.mainTextColor,
  fontSize: "18px",
  fontStyle: "normal",
  fontWeight: 600,
};

export const eventStyles = {
  marginLeft: "auto",
  color: Colors.mainTextColor,
};

import MultipleSelector, { Option } from "@/components/ui/multiple-selector";
import { Button, Form, Input, Select } from "antd";
import React, { useEffect } from "react";

type AddReferralCodesProp = {
  primaryCategories: IAffiliationPrimaryCategory[];
  secondaryCategories: IAffiliationSecondaryCategory[];
  tertiarycategories: IAffiliationTertiaryCategory[];
  onFormFinish: (code: IAffiliationCodeCreateHandler) => void;
  onModelClose: () => void;
  isLoading: boolean;
  isSuccess: boolean;
};

const NewReferralcode = ({
  onFormFinish,
  onModelClose,
  isLoading,
  isSuccess,
  primaryCategories,
  secondaryCategories,
  tertiarycategories,
}: AddReferralCodesProp) => {
  const [form] = Form.useForm();

  const primaryOptions: Option[] = primaryCategories?.map((c) => ({
    label: c.name,
    value: c.name,
  }));

  const secondaryOptions: Option[] = secondaryCategories?.map((c) => ({
    label: c.name,
    value: c.name,
  }));

  const tertiaryOptions: Option[] = tertiarycategories?.map((c) => ({
    label: c.name,
    value: c.name,
  }));

  const onFinish = async (formValues: any) => {
    const values: IAffiliationCodeCreateHandler = {
      affiliationCode: formValues.affiliationCode,
      primaryCategory: formValues.primaryCategory[0].value,
      secondaryCategory: formValues.secondaryCategory[0].value,
      tertiaryCategory:
        formValues.tertiaryCategory[0].value !== undefined
          ? formValues.tertiaryCategory[0].value
          : null,
    };

    onFormFinish(values);
  };

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
    }
  }, [isSuccess]);

  const handleClose = () => {
    form.resetFields();
    onModelClose();
  };

  return (
    <Form
      name="newAffiliation"
      layout="vertical"
      form={form}
      onFinish={onFinish}
      className="grid"
    >
      <div className="col-12 md:col-6">
        <Form.Item
          name="affiliationCode"
          label="Add New Code"
          rules={[{ required: true, message: "Please enter referral code!" }]}
          className="mb-1"
        >
          <Input size="large" placeholder="Enter Code" className="mb-2" />
        </Form.Item>
      </div>

      <div className="col-12 md:col-6">
        <Form.Item
          name="primaryCategory"
          label="Primary Code"
          rules={[{ required: true, message: "Please enter referral code!" }]}
          className="mb-1"
        >
          <MultipleSelector
            defaultOptions={primaryOptions}
            hidePlaceholderWhenSelected
            placeholder="Select a province or enter a new province"
            creatable
            maxSelected={1}
          />
        </Form.Item>
      </div>

      <div className="col-12 md:col-6">
        <Form.Item
          name="secondaryCategory"
          label="Secondary Code"
          rules={[{ required: true, message: "Please enter referral code!" }]}
          className="mb-1"
        >
          <MultipleSelector
            defaultOptions={secondaryOptions}
            hidePlaceholderWhenSelected
            placeholder="Select a province or enter a new province"
            creatable
            maxSelected={1}
          />
        </Form.Item>
      </div>

      <div className="col-12 md:col-6">
        <Form.Item
          name="tertiaryCategory"
          label="Tertiary Code"
          className="mb-1"
        >
          <MultipleSelector
            defaultOptions={tertiaryOptions}
            hidePlaceholderWhenSelected
            placeholder="Select a province or enter a new province"
            creatable
            maxSelected={1}
          />
        </Form.Item>
      </div>
      <Form.Item className="w-12 mb-1">
        <div className="flex w-full gap-2 col-12 justify-content-end align-content-center">
          <Button
            type="primary"
            ghost
            onClick={handleClose}
            size="large"
            className="w-3"
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            size="large"
            className="w-3 bg-[#1757a2] shadow-none"
          >
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default NewReferralcode;

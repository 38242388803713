import { Avatar, Button, Form, Input, message, Select, Upload } from "antd";
import Dragger from "antd/es/upload/Dragger";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import React, { useEffect, useState } from "react";
import { CustomIcons } from "../../assets";
import {
  clickHereDraggerStyles,
  headerDraggerStyles,
  subtitleDraggerStyles,
} from "./styles";
import Colors from "../../config/Colors";
import { useAddCompanyMembers } from "../../hooks/useCompanies";
import {
  useAllAffiliationCodes,
  useAllCommunityCodes,
  useUnusedAllCommunityCodes,
  useUnusedAllReferralCode,
} from "@/hooks/useCommunityCodes";
import { uploadUrl } from "@/config/urls";

type NewCompanyProp = {
  newCompanyValues?: IAddCompany;
  onFormFinish: (company: IAddCompany) => Promise<void>;
  onModalClose: () => void;
  isLoading: boolean;
  isSuccess: boolean;
};

const NewCompanyForm = ({
  newCompanyValues,
  onFormFinish,
  onModalClose,
  isLoading,
  isSuccess,
}: NewCompanyProp) => {
  const [form] = Form.useForm();

  const [imageUrl, setImageUrl] = useState("");
  const communityCodes = useUnusedAllCommunityCodes();
  const referralCodes = useUnusedAllReferralCode();
  const [uploading, setUploading] = useState<boolean>();

  const handleReset = () => {
    form.resetFields();
    setImageUrl("");
  };

  const onFinish = async (formValues: IAddCompany) => {
    const values: IAddCompany = formValues as IAddCompany;
    await onFormFinish(values);
  };
  const handleClose = () => {
    handleReset();
    onModalClose();
  };
  const filterOption = (
    input: string,
    option?: { id?: number; label: string; value: string } | any
  ) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase());

  const beforeUpload = (file: RcFile, fileList: UploadFile[]) => {
    const isValidFile =
      file.type === "image/jpeg" ||
      "image/png" ||
      "image/svg+xml" ||
      "image/gif";
    if (!isValidFile) {
      message.error("You can only upload JPG/PNG/SVG/GIF file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error("Image must smaller than 10MB!");
    }
    return isValidFile && isLt2M;
  };

  const props: UploadProps = {
    name: "file",
    multiple: false,
    action: uploadUrl,
    onChange(info) {
      const { status } = info.file;
      if (status === "uploading") {
        setUploading(true);
      }
      if (status === "done") {
        form.setFieldsValue({ companyImage: info.file.response.path });
        setImageUrl(info.file.response.path);
        message.success(`${info.file.name} file uploaded successfully.`);
        setUploading(false);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
        setUploading(true);
      }
    },
    onDrop(e) {
      //console.log("Dropped files", e.dataTransfer.files);
    },
  };

  useEffect(() => {
    if (newCompanyValues) {
      form.setFieldsValue(newCompanyValues);
      if (newCompanyValues) {
        setImageUrl(newCompanyValues.companyImage);
      }
    } else {
      form.resetFields();
    }
  }, [newCompanyValues, form]);

  useEffect(() => {
    if (isSuccess) {
      handleReset();
    }
  }, [isSuccess]);

  return (
    <Form
      form={form}
      name="addCompany"
      layout="vertical"
      onFinish={onFinish}
      initialValues={newCompanyValues}
    >
      <div className="col-12">
        <Form.Item
          name="companyName"
          label="Name of Company"
          rules={[{ required: true, message: "Please enter company name!" }]}
          className="mb-1"
        >
          <Input size="large" placeholder="ABC Ltd" />
        </Form.Item>
      </div>
      <div className="col-12">
        <Form.Item
          name="pricePerMonth"
          label="Price per month"
          rules={[
            { required: true, message: "Please enter price per month" },
            {
              pattern: /^\d{1,3}(?:\s?\d{3})*(?:\.\d{1,2})?$/,
              message: "Invalid price format",
            },
          ]}
          className="mb-1"
        >
          <Input size="large" placeholder="10000.99" />
        </Form.Item>
      </div>
      <div className="col-12">
        <Form.Item
          name="communityCodeId"
          label="Community Code"
          className="mb-1"
        >
          <Select
            showSearch
            placeholder="Search to select Community Code"
            size="large"
            filterOption={filterOption}
            loading={communityCodes.isLoading}
          >
            {communityCodes.data?.map((item: IAllCommunityCode) => (
              <Select.Option
                key={`key-community-code-${item.id}`}
                value={item.id}
              >
                {item.code}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <div className="col-12">
        <Form.Item name="referralCodeId" label="Referral Code" className="mb-1">
          <Select
            showSearch
            placeholder="Search to select Referral Code"
            size="large"
            filterOption={filterOption}
            loading={referralCodes.isLoading}
          >
            {referralCodes.data?.map((item: IAllAffiliationCode) => (
              <Select.Option
                key={`key-referral-code-${item.id}`}
                value={item.id}
              >
                {item.code}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>
      <Form.Item
        name="companyImage"
        label="Icon"
        rules={[
          { required: true, message: "Please upload company icon!" }, // Custom validator to check if a valid certificate file has been uploaded
          () => ({
            validator(_) {
              if (!imageUrl) {
                return Promise.reject(
                  new Error("Please upload a valid image.")
                );
              }
              return Promise.resolve();
            },
          }),
        ]}
        className="mb-5 col-12"
      >
        <div className="flex w-full gap-6 flx-row justify-content-start">
          {imageUrl.length > 0 ? (
            <Avatar
              shape="square"
              size={64}
              alt="emergency icon"
              src={imageUrl}
            />
          ) : (
            <Avatar shape="square" size={64} alt="emergency icon" />
          )}
          <Dragger
            {...props}
            beforeUpload={beforeUpload}
            className="w-full"
            style={{ background: "transparent" }}
            accept=".svg,.png,.jpg,.gif"
          >
            <div className="flex flex-col items-center content-center w-full h-full gap-3">
              <CustomIcons.UploadIcon
                width={46}
                height={46}
                svgColor={Colors.appMainColor}
              />
              <div className="flex gap-1 justify-content-center">
                <div className="text-center" style={headerDraggerStyles}>
                  Drop your icon here or to upload
                </div>
                <div style={clickHereDraggerStyles}>click here</div>
                <div className="text-center" style={headerDraggerStyles}>
                  to upload
                </div>
              </div>

              <div style={subtitleDraggerStyles}>
                File should be SVG, PNG,JPG or GIF (max 800x400px){" "}
              </div>
            </div>
          </Dragger>
        </div>
      </Form.Item>
      <Form.Item className="w-12 pt-2 mb-1">
        <div className="flex w-full gap-2 col-12 justify-content-end align-content-center">
          <Button
            type="primary"
            ghost
            onClick={handleClose}
            size="large"
            className="w-3"
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            size="large"
            className="w-3 bg-[#1757a2] shadow-none"
            disabled={uploading as boolean}
          >
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default NewCompanyForm;

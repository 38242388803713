import React, { MouseEvent, useRef } from "react";
import type { InteractionItem } from "chart.js";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  BarController,
} from "chart.js";
import {
  Chart,
  getDatasetAtEvent,
  getElementAtEvent,
  getElementsAtEvent,
} from "react-chartjs-2";
import { da, faker } from "@faker-js/faker";
import { Select } from "antd";
import Colors from "../../../config/Colors";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  BarController
);

interface IResponderChartProps {
  analytics?: IResponderOperatorOnliineTime;
  companyName?: string;
}

export default function ResponderOnlineTimeChart({
  analytics,
  companyName,
}: IResponderChartProps) {
  const company = [
    analytics?.monday ?? 0,
    analytics?.tuesday ?? 0,
    analytics?.wednesday ?? 0,
    analytics?.thursday ?? 0,
    analytics?.friday ?? 0,
    analytics?.saturday ?? 0,
    analytics?.sunday ?? 0,
  ];
  const options = {
    plugins: {
      legend: {
        position: "top" as const,
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
    },
  };

  const labels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const data = {
    labels,
    datasets: [
      {
        label: companyName,
        backgroundColor: `#1757A2`,
        data: company,
      },
    ],
  };

  const { Option } = Select;
  const printDatasetAtEvent = (dataset: InteractionItem[]) => {
    if (!dataset.length) return;

    const datasetIndex = dataset[0].datasetIndex;

    console.log(data.datasets[datasetIndex].label);
  };

  const printElementAtEvent = (element: InteractionItem[]) => {
    if (!element.length) return;

    const { datasetIndex, index } = element[0];

    console.log(data.labels[index], data.datasets[datasetIndex].data[index]);
  };

  const printElementsAtEvent = (elements: InteractionItem[]) => {
    if (!elements.length) return;
  };

  const chartRef = useRef<ChartJS>(null);

  const onClick = (event: MouseEvent<HTMLCanvasElement>) => {
    const { current: chart } = chartRef;

    if (!chart) {
      return;
    }

    printDatasetAtEvent(getDatasetAtEvent(chart, event));
    printElementAtEvent(getElementAtEvent(chart, event));
    printElementsAtEvent(getElementsAtEvent(chart, event));
  };

  const onSortChange = (value: any) => {
    console.log(`selected ${value}`);
  };

  return (
    <div
      style={{ background: "#FFFFFF" }}
      className="shadow-5 surface-card border-round px-3"
    >
      <div className="w-12 flex flex-row justify-content-between align-items-center">
        <div
          className="pt-3"
          style={{
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "18px",
            lineHeight: "27px",
            color: `${Colors.mainTextColor}`,
          }}
        >
          Average Online time
        </div>
      </div>
      <div className="pb-2">
        <Chart
          ref={chartRef}
          type="bar"
          onClick={onClick}
          options={options}
          data={data}
        />
      </div>
    </div>
  );
}

/* eslint-disable no-plusplus */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable  spaced-comment */
/* eslint-disable arrow-body-style */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-fragments */
/* eslint-disable  prefer-destructuring */

import React, { useState, useEffect, useReducer } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import { Container, BodyContainer, SearchBar } from "./styles";
import SideMenu from "../../components/Menu/Menu";
import { BaseUrl, LocalHost, apiUrl } from "../../config/urls";
import "./styles.css";
import { BlueButton } from "../../components/widgets/Buttons/BlueButtton";
import SkeletonDashboard from "../../components/widgets/skeletons/SkeletonDashboard";
import AllEmergencyOperators from "./components/AllEmergencyOperators";
import HeaderComponent from "../../components/Header/Header";
import GeneralOperators from "./components/GeneralOperators";
import { useEmergencies } from "../../hooks/useEmergencies";
import {
  useGetAllEmergenciesOperators,
  useOperators,
} from "../../hooks/useOperators";

function generateRandomPercentage(): string {
  const percentage = Math.floor(Math.random() * 100);
  return `${percentage}%`;
}

function generateRandomRating(): number {
  const rating = Math.floor(Math.random() * 5 * 10) / 10; // Generates a random number between 0 and 5 with one decimal place
  return rating;
}

function generateRandomCallouts(): number {
  const callouts = Math.floor(Math.random() * 100) + 1; // Generates a random number between 1 and 10
  return callouts;
}

const blueCommunity: IBlueCommunity[] = [];

for (let i = 1; i <= 10; i++) {
  const record: IBlueCommunity = {
    id: i,
    firstName: "John",
    lastName: "Doe",
    callouts: generateRandomCallouts(),
    attendedCallouts: generateRandomPercentage(),
    unattendedCallouts: generateRandomPercentage(),
    resolvedCases: generateRandomPercentage(),
    unresolvedCases: generateRandomPercentage(),
    rating: generateRandomRating(),
  };

  blueCommunity.push(record);
}

const EmergencyOperators: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState("1");

  const { isLoading, data: emergencies } = useEmergencies();
  const { isLoading: loadingOperator, data: operators } =
    useGetAllEmergenciesOperators();

  const onTabChange = (key: string) => {
    setActiveIndex(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `All`,
      children: (
        <>
          {loadingOperator ? (
            <SkeletonDashboard
              height={900}
              className="flex flex-col items-center content-center w-full h-screen"
            />
          ) : (
            <AllEmergencyOperators allUsers={operators ? operators : []} />
          )}
        </>
      ),
    },
  ];

  if (emergencies && Array.isArray(emergencies)) {
    emergencies.forEach((emergency: IEmergency, index) => {
      const key = `key-${emergency.id}`;

      items.push({
        key,
        label: emergency.emergencyType,
        children: (
          <div key={key}>
            <GeneralOperators
              emergencyId={emergency.id}
              emergencyName={emergency.emergencyType}
              key={key}
            />
          </div>
        ),
      });
    });
  }

  return (
    <div className="w-full h-full">
      <Container className="w-full h-full">
        <BodyContainer className="w-full">
          <HeaderComponent title="Operators" />
          <div className="px-3">
            <Tabs
              defaultActiveKey={activeIndex}
              items={items}
              onChange={onTabChange}
              animated
            />
          </div>
        </BodyContainer>
      </Container>
    </div>
  );
};

export default EmergencyOperators;

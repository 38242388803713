/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import OtpInput from "react-otp-input";
import { Button, Alert, Space } from "antd";
import Images from "../../assets";
import PrimaryProgressBar from "../../components/widgets/Spinner/ProgressBar";
import "./OTPInput.css";
import TextUnderlineButton from "../../components/widgets/Buttons/TextUnderlineButton";
import Colors from "../../config/Colors";
import { AuthContext } from "../../contexts/AuthContext";
import { Auth } from "../../services";
import { redirectToDashboard } from "../../util/helpers";

const OTP: React.FC = () => {
  const navigate = useHistory();
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [otp, setOtp] = useState("");

  const {
    user,
    setUser,
    setToken,
    tempUser,
    setTempUser,
    tempToken,
    setTempToken,
    tempRefreshToken,
    setTempRefreshToken,
    setRefreshToken,
  } = useContext(AuthContext);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await Auth.verifyOtp(tempUser?.phoneNumber || "", otp);
      if (response.isSuccess) {
        setUser(tempUser);
        setToken(tempToken);
        setRefreshToken(tempRefreshToken);
        redirectToDashboard(tempUser, navigate);
        setTempToken(null);
        setTempUser(null);
        setTempRefreshToken(null);
      } else {
        setShowAlert(true);
        setAlertMessage("Invalid OTP");
      }
    } catch (error) {
      console.log(error);
      setShowAlert(true);
      setAlertMessage("An error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-screen h-screen Account-Login-Container sm:overflow-auto md:overflow-hidden">
      <div className="flex flex-col items-center justify-start w-full h-screen md:h-full md:flex-row ">
        <img
          src={Images.backgroundImage}
          alt="safer city logo"
          className="hidden w-12 h-full md:flex md:w-2/4 md:min-h-full"
          style={{
            objectFit: "cover",
          }}
        />
        <div className="flex flex-col items-center justify-center w-full h-full px-2 ali md:w-2/4 ">
          <div className="flex flex-col items-start">
            <Button
              type="text"
              className="absolute hidden text-base text-left md:block align-self-start md:top-0 md:left-50 "
              style={{
                color: "#8692A6",
              }}
              onClick={() => navigate.goBack()}
            >
              <div className="flex items-center justify-start gap-1">
                <LeftOutlined />
                Back
              </div>
            </Button>
          </div>
          <div className="flex justify-center w-full py-4 md:py-2">
            {showAlert && (
              <Alert message={alertMessage} type="error" closable />
            )}
            {loading && <PrimaryProgressBar />}
          </div>
          <Space direction="vertical" size={30} style={{ display: "flex" }}>
            <div className="">
              <h2
                className="flex pt-4 text-2xl font-bold align-items-start justify-content-start md:pt-0"
                style={{
                  color: `${Colors.appMainColor}`,
                }}
              >
                Check phone number for OTP
              </h2>
              <div
                className="flex items-start justify-start w-full pt-4 font-normal text-medium md:pt-0"
                style={{
                  color: "#9B9B9B",
                }}
              >
                Please enter the six digit pin sent to your mobile number.
              </div>
            </div>
            <div className="flex items-center justify-start h-full gap-5 flex-column">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                containerStyle="otpContainer"
                inputStyle="otpInput"
                renderSeparator={<span> </span>}
                renderInput={(props) => <input {...props} />}
              />
              <Button
                type="primary"
                htmlType="button"
                loading={loading}
                className="w-5"
                style={{
                  width: "100%",
                  backgroundColor: `${Colors.appMainColor}`,
                  color: "white",
                  opacity: otp.length < 6 ? 0.5 : 1,
                }}
                disabled={otp.length < 6}
                onClick={handleSubmit}
              >
                Continue
              </Button>
            </div>
          </Space>

          {/* <div
            className="flex items-start justify-start w-7/12 pt-6 pb-3 pl-3 text-base font-normal line-height-2"
            style={{ color: `${Colors.mainTextColor}` }}
          >
            Didn't get the code?
          </div>
          <TextUnderlineButton
            title="Resend"
            color={Colors.appMainColor}
            onclick={() => {
              console.log("Resend otp");
            }}
            className="flex items-start justify-start w-7/12 text-base font-normal line-height-2"
          />
          <div style={{ margin: "10rem" }} /> */}
        </div>
      </div>
    </div>
  );
};

export default OTP;

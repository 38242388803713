/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-console */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState, useContext } from "react";
import { toast } from "sonner";
import { Modal, Drawer, Divider, message, Button } from "antd";
import { DateTime } from "luxon";
import {
  Container,
  MessagingDrawer,
  Header,
  ChatName,
  ChatNameStatus,
  ChatEmailStatus,
  ReceiverChatMessage,
  ReceiverText,
  ReceiverTextTime,
  ChatMessages,
  ChatMessagesContainer,
  SenderChatMessage,
  SuccessStatusText,
  SuccessStatusDescriptionText,
  SenderText,
  ChatSendBox,
  ChatsContainer,
  ChatUserInformation,
  ConversationStartDate,
} from "./Styles";
import MessagePanel from "../../components/MessagePanel/MessagePanel";
import { BlueButton } from "../../components/widgets/Buttons/BlueButtton";
import UserAvatar from "../../components/widgets/Avatars/UserAvatar";
import "./Messaging.css";
import TextUnderlineButton from "../../components/widgets/Buttons/TextUnderlineButton";
import { CustomIcons } from "../../assets";
import CustomOutlinedButton from "../../components/widgets/Buttons/CustomOutlinedButton";
import CustomInputField from "../../components/widgets/Inputs/CustomInputField";
import Colors from "../../config/Colors";
import {
  useEmergencyCaseOperatorNote,
  useOperatorEmergencyCases,
  useOperatorResolve,
  useTransferCase,
  useResumeCase,
  usePausedCase,
  usePostOperatorNote,
  useTransferableOperators,
  useTransferByOperator,
} from "../../hooks/useOperators";
import {
  useDirectMessageByCase,
  useDirectMessageByIds,
  usePostDirectMessage,
} from "../../hooks/useDirectMessage";
import { copyObjects } from "../../util/copyObjects";
import { DirectMessage } from "../../services";
import AuthContext from "../../contexts/AuthContext";
import { modelTitle } from "../Users/styles";
import EditUserForm from "../../components/Forms/EditUser";
import OperatorNoteForm from "../../components/Forms/OperatorNote";
import { decodeMessage, encodeMessage } from "../../util/helpers";
import TransferableOperators from "../../components/Table/TransferableOperator";

interface UserData {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  profilePicture: string;
}
const Messaging: React.FC = () => {
  const { user, getEmergencyId } = useContext(AuthContext);
  const [newMessage, setNewMessage] = useState("");
  const [canBulkEdit, setCanBulkEdit] = useState(false);
  const [canPausedCase, setCanPausedCase] = useState<boolean | null>(null);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [operatorUserId, setCurrentOperatorUserId] = useState<number>(
    user && user.id ? user.id : 0
  );
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [openOperatorNoteModel, setOpenOperatorNoteModel] = useState(false);
  const [transferModal, setTransferModal] = useState(false);
  const [modelMessage, setModelMessage] = useState(
    "The user has been transferred to another therapist"
  );
  const [currentEmergencyCaseId, setCurrentEmergencyCaseId] = useState<
    string | number | null
  >(0);
  const [currentReceiverId, setCurrentReceiverId] = useState<
    string | number | null
  >(0);
  const [currentEmergencyId, setCurrentEmergencyId] = useState<
    string | number | null
  >(getEmergencyId());
  const [queriesEnabled, setQueriesEnabled] = useState(false);

  // Hooks
  // Get all the operator emergency cases
  const {
    isLoading,
    data: EmergencyCases,
    refetch,
  } = useOperatorEmergencyCases(operatorUserId, true);

  // Gets all the messages linked to a specific case
  const loadMessageByCase = useDirectMessageByCase(currentEmergencyCaseId);

  // Gets all the messages linked with userId and OperatorId
  const loadMessageByIds = useDirectMessageByIds(
    operatorUserId,
    currentReceiverId,
    queriesEnabled
  );
  // Gets Operator Note by Emergency Case Id
  const emergencyCaseNote = useEmergencyCaseOperatorNote(
    currentEmergencyCaseId
  );

  // Gets all transferable operators
  const transferableOperators = useTransferableOperators(
    currentEmergencyId,
    operatorUserId
  );

  // mutation hooks
  const postMessage = usePostDirectMessage();

  const markAsResolved = useOperatorResolve();

  const transferredCase = useTransferCase();

  const resumeCase = useResumeCase();

  const pauseCase = usePausedCase();

  const postOperatorNote = usePostOperatorNote();

  const transferCaseByOperator = useTransferByOperator();

  // Handles Who is currently logged in and gets Operator's Id
  useEffect(() => {
    if (user) {
      setCurrentOperatorUserId(user.id);
    }
  }, []);

  const handleUnreadMessages = async (unreadMessages: IDirectMessage[]) => {
    await DirectMessage.updateMessageList(unreadMessages);
    setCanBulkEdit(false);
  };

  // Handles Bulk Messages Mark has read
  useEffect(() => {
    if (loadMessageByCase?.data && canBulkEdit) {
      // const receivedMessage = JSON.parse(message) as IDirectMessage;

      // Find unread messages
      const unreadMessages = loadMessageByCase.data.filter(
        (unReadMessage: IDirectMessage) =>
          unReadMessage.readStatus === 0 &&
          unReadMessage.receiverId === operatorUserId
      );

      // Create a new array with updated readStatus
      const updatedMessages = unreadMessages.map((message: IDirectMessage) => ({
        ...message,
        readStatus: 1,
      }));
      handleUnreadMessages(updatedMessages);
    }
  }, [loadMessageByCase?.data, canBulkEdit]);

  // When the user clicks on a case
  const handleUserSelect = async (selectedCase: IOperatorEmergencyCase) => {
    setCurrentEmergencyCaseId(selectedCase.emergencyCase.id);
    setCurrentUser(selectedCase.user);
    setCanPausedCase(selectedCase.emergencyCase.resolvedStatus !== 2);
    setCanBulkEdit(true);
  };

  function handleInputChange(event: string) {
    setNewMessage(event);
  }

  async function handleSendClick() {
    const storeMessage = newMessage;
    await setNewMessage("");
    const messageData: IDirectMessage = copyObjects(
      {},
      {
        id: 0,
        messageText: encodeMessage(newMessage),
        senderId: operatorUserId as number,
        receiverId: currentUser?.id as number,
        readStatus: 0,
        dateAdded: DateTime.utc().toString(),
        emergencyCaseId: currentEmergencyCaseId as number,
      }
    );

    try {
      await postMessage.mutateAsync(messageData);
      // setCanBulkEdit(true);
      // await loadMessageByCase.refetch();
    } catch (error) {
      setNewMessage(storeMessage);
      console.error(error);
      await loadMessageByCase.refetch();
    }
  }

  async function handleTransferClick() {
    const hide = message.loading("transferring Case...");
    if (currentEmergencyCaseId) {
      try {
        await transferredCase.mutateAsync(currentEmergencyCaseId);
        refetch();

        setModelMessage("The case has been transferred");
        message.success("Case has been transferred successfully");
      } catch (error) {
        console.log(error);
      }
    }
    hide();
  }

  async function handleTransferByOperatorClick(
    emergencyCaseId: string | number,
    operatorId: string | number
  ) {
    if (emergencyCaseId && operatorId) {
      try {
        await transferCaseByOperator.mutateAsync({
          emergencyCaseId,
          operatorId,
        });
        refetch();
        transferableOperators.refetch();
      } catch (error) {
        console.log(error);
      }
    }
  }

  async function handleTransferByOperatorClickPromise(
    emergencyCaseId: string | number,
    operatorId: string | number
  ): Promise<IEmergencyCase> {
    if (emergencyCaseId && operatorId) {
      const response = await transferCaseByOperator.mutateAsync({
        emergencyCaseId,
        operatorId,
      });
      setCurrentUser(null);
      refetch();
      transferableOperators.refetch();
      return response; // Returning for success scenario, you can adjust this based on your needs
    }
    throw new Error("Invalid emergencyCaseId or operatorId");
  }

  async function handleResolveClick() {
    const hide = message.loading("Marking Case has resolved...");
    if (currentEmergencyCaseId) {
      try {
        await markAsResolved.mutateAsync(currentEmergencyCaseId);
        refetch();
        setModelMessage("The case has been resolved");
        message.success("Case has been resolved successfully");
      } catch (error) {
        console.log(error);
      }
    }
    hide();
  }

  async function handlePausedClick() {
    const hide = message.loading("Marking Case has paused...");
    if (currentEmergencyCaseId) {
      try {
        await pauseCase.mutateAsync(currentEmergencyCaseId);
        refetch();
        message.success("Case has been paused successfully");
        setCanPausedCase(false);
      } catch (error) {
        console.log(error);
      }
    }
    hide();
  }

  async function handleResumedClick() {
    const hide = message.loading("Marking Case has resumed...");
    if (currentEmergencyCaseId) {
      try {
        await resumeCase.mutateAsync(currentEmergencyCaseId);
        refetch();
        message.success("Case has been resumed successfully");
        setCanPausedCase(true);
      } catch (error) {
        console.log(error);
      }
    }
    hide();
  }

  async function handlePostOperatorNoteClick(note: string) {
    const hide = message.loading("Saving note...");
    if (currentEmergencyCaseId && note) {
      try {
        const operatorNoteData: IOperatorNote = {
          EmergencyCaseId: currentEmergencyCaseId,
          OperatorNote: note,
        };

        await postOperatorNote.mutateAsync(operatorNoteData);
        refetch();
        message.success("Note has been saved successfully");
      } catch (error) {
        console.log(error);
      }
    }
    hide();
  }

  // Helper function to check if a message is the first of its date.
  const isFirstMessageOfTheDay = (
    currentMessage: any,
    index: number,
    messageList: IDirectMessage[]
  ) => {
    if (index === 0) return true; // If it's the first message in the list, return true.

    const previousMessageDate = DateTime.fromISO(
      messageList[index - 1].dateAdded,
      { zone: "utc" }
    )
      .toLocal()
      .startOf("day");
    const currentMessageDate = DateTime.fromISO(currentMessage.dateAdded, {
      zone: "utc",
    })
      .toLocal()
      .startOf("day");

    // If the current message and the previous one are not from the same day, return true.
    return !currentMessageDate.hasSame(previousMessageDate, "day");
  };

  const customChatInputTheme = {
    token: {
      colorPrimaryHover: `${Colors.appMainColor}`,
      colorBgContainer: "#FFFFFF",
      lineWidth: 1,
      colorBorder: " #9B9B9B",
      borderRadius: 12,
    },
  };

  function resetMutations() {
    markAsResolved.reset();
    transferredCase.reset();
  }

  function closeModal() {
    setCurrentUser(null);
    setCanPausedCase(null);
    setCanBulkEdit(false);
    resetMutations();
  }

  return (
    <div className="w-full h-screen">
      <Container className="w-full h-screen">
        <ChatsContainer className="w-full">
          {isLoading ? (
            <div className="flex flex-row w-12 justify-content-center align-items-center">
              <CustomIcons.EOSThreeDotsLoading
                width={100}
                height={100}
                viewBox={null}
                svgColor={Colors.appMainColor}
              />
            </div>
          ) : (
            <>
              {EmergencyCases && (
                <>
                  <MessagePanel
                    onUserSelect={handleUserSelect}
                    cases={EmergencyCases}
                  />
                  {loadMessageByCase.data && currentUser ? (
                    <>
                      <ChatMessagesContainer>
                        <Header className="w-full">
                          <div className="flex flex-row gap-1 align-items-center">
                            <UserAvatar user={currentUser} />
                            <div className="flex gap-1 flex-column">
                              <ChatName>
                                {currentUser?.firstName} {currentUser?.lastName}
                              </ChatName>
                              {/* <ChatNameStatus>Online</ChatNameStatus> */}
                            </div>
                          </div>
                          <div className="flex flex-row gap-3 align-items-center">
                            <Button
                              type="primary"
                              size="large"
                              className="w-6 bg-[#99C7EB] shadow-none hover:bg-blue-300"
                              onClick={() => {
                                setOpenDrawer(!openDrawer);
                              }}
                            >
                              View profile
                            </Button>
                            <Button
                              type="primary"
                              size="large"
                              className="w-5 bg-[#1757a2] shadow-none"
                              onClick={() => {
                                emergencyCaseNote.remove();
                                setOpenOperatorNoteModel(true);
                              }}
                            >
                              Note
                            </Button>

                            {canPausedCase !== null && (
                              <Button
                                type="primary"
                                size="large"
                                loading={
                                  pauseCase.isLoading || resumeCase.isLoading
                                }
                                className={`w-7 ${
                                  canPausedCase
                                    ? "bg-yellow-500"
                                    : "bg-green-500"
                                } shadow-none hover:${
                                  canPausedCase
                                    ? "bg-yellow-600"
                                    : "bg-green-600"
                                }`}
                                onClick={() => {
                                  if (canPausedCase) {
                                    handlePausedClick();
                                  } else {
                                    handleResumedClick();
                                  }
                                }}
                              >
                                {canPausedCase ? "Pause Case" : "Resume Case"}
                              </Button>
                            )}
                          </div>
                        </Header>
                        <ChatMessages className="gap-5 overflow-auto">
                          {loadMessageByCase.data?.map(
                            (message: IDirectMessage, index) => (
                              <div className="chats-wrapper" key={index}>
                                {isFirstMessageOfTheDay(
                                  message,
                                  index,
                                  loadMessageByCase.data
                                ) && (
                                  <div className="px-4 date-divider">
                                    <Divider plain>
                                      <ConversationStartDate>
                                        {DateTime.fromISO(message.dateAdded, {
                                          zone: "utc",
                                        })
                                          .toLocal()
                                          .startOf("day")
                                          .hasSame(
                                            DateTime.local().startOf("day"),
                                            "day"
                                          )
                                          ? "Today"
                                          : DateTime.fromISO(
                                              message.dateAdded
                                            ).toFormat("MMMM d, yyyy")}
                                      </ConversationStartDate>
                                    </Divider>
                                  </div>
                                )}
                                {Number(message.senderId) == currentUser.id ? (
                                  <div className="py-1 Receiver-wrapper">
                                    <ReceiverChatMessage className="gap-3 mx-6">
                                      <ReceiverText className="w-12 px-4">
                                        {decodeMessage(message.messageText)}
                                      </ReceiverText>
                                      <ReceiverTextTime className="w-12 px-4">
                                        {DateTime.fromISO(message.dateAdded, {
                                          zone: "utc",
                                        })
                                          .toLocal()
                                          .toFormat("HH:mm")}
                                      </ReceiverTextTime>
                                    </ReceiverChatMessage>
                                  </div>
                                ) : (
                                  <div className="flex flex-row py-1 Sender-wrapper justify-content-end">
                                    <SenderChatMessage>
                                      <SenderText>
                                        {decodeMessage(message.messageText)}
                                      </SenderText>
                                    </SenderChatMessage>
                                  </div>
                                )}
                              </div>
                            )
                          )}
                          {!loadMessageByCase.isLoading &&
                            loadMessageByCase.data?.length === 0 && (
                              <div className="flex w-full gap-3 flex-column align-items-center justify-content-center">
                                <p
                                  style={{
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    fontSize: "2rem",
                                    color: `${Colors.mainTextColor}`,
                                    textAlign: "center",
                                    lineHeight: "3rem",
                                  }}
                                >
                                  You currently have no messages
                                </p>
                                <p
                                  style={{
                                    fontWeight: "600",
                                    fontStyle: "normal",
                                    fontSize: "1.2rem",
                                    color: `${Colors.subtleTextColor}`,
                                    textAlign: "center",
                                  }}
                                >
                                  No one has reached out for assistance.
                                </p>
                                <div className="flex w-full align-items-center justify-content-center">
                                  <CustomIcons.MessageIcon
                                    width={325}
                                    height={241}
                                    viewBox={null}
                                    svgColor=""
                                  />
                                </div>
                              </div>
                            )}
                          {postMessage.isLoading && (
                            <div className="flex flex-row w-12 align-items-end justify-content-center">
                              <CustomIcons.EOSloading
                                width={30}
                                height={30}
                                viewBox={null}
                                svgColor={Colors.appMainColor}
                              />
                            </div>
                          )}
                          {loadMessageByCase.isFetching && (
                            <div className="flex flex-row w-12 align-items-center justify-content-center">
                              <CustomIcons.EOSloading
                                width={100}
                                height={100}
                                viewBox={null}
                                svgColor={Colors.appMainColor}
                              />
                            </div>
                          )}
                        </ChatMessages>
                        <ChatSendBox>
                          <CustomInputField
                            value={newMessage}
                            onChange={(e: string) => handleInputChange(e)}
                            onPressEnter={() => handleSendClick()}
                            inputProps={{
                              size: "large",
                              placeholder: "Type your message here...",
                              allowClear: false,
                              className: "ml-2 w-11",
                              prefix: <span />,
                            }}
                            theme={customChatInputTheme}
                            mergeThemes
                          />
                          <div className="flex flex-row gap-3 pl-3 align-items-center">
                            <BlueButton
                              type="button"
                              style={{ height: "2.3rem", width: "12rem" }}
                              onClick={handleSendClick}
                            >
                              Send
                            </BlueButton>

                            <CustomOutlinedButton
                              title="Transfer user"
                              onClick={() => {
                                setTransferModal(true);
                              }}
                              color={Colors.appMainColor}
                              borderColor={Colors.appMainColor}
                              className="w-6 border-round-lgl"
                            />

                            <TextUnderlineButton
                              title="Resolved"
                              color={Colors.appMainColor}
                              onclick={() => {
                                handleResolveClick();
                              }}
                              className="w-6 pl-2"
                            />
                          </div>
                        </ChatSendBox>
                      </ChatMessagesContainer>
                    </>
                  ) : (
                    <div className="flex w-full flex-column align-items-center justify-content-center">
                      <p
                        style={{
                          fontStyle: "normal",
                          fontWeight: "600",
                          fontSize: "2rem",
                          color: `${Colors.mainTextColor}`,
                          textAlign: "center",
                          lineHeight: "3rem",
                        }}
                      >
                        Please select a message
                      </p>
                      <div className="flex w-full align-items-center justify-content-center">
                        <CustomIcons.MessageIcon
                          width={325}
                          height={241}
                          viewBox={null}
                          svgColor=""
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}

          <div className="Messaging">
            <Drawer
              title="Profile"
              placement="right"
              size="default"
              onClose={() => {
                setOpenDrawer(false);
              }}
              open={openDrawer}
            >
              {currentUser && (
                <div className="flex gap-2 profile-contents flex-column justify-content-start">
                  <UserAvatar user={currentUser} showBadge={false} />
                  {/* <ChatNameStatus>Active</ChatNameStatus> */}
                  <ChatEmailStatus>{currentUser.emailAddress}</ChatEmailStatus>
                  <ChatUserInformation>
                    {currentUser.phoneNumber}
                  </ChatUserInformation>
                  <Divider className="m-1" />
                  {/* <TextUnderlineButton
                    title="Make emergency request"
                    color={Colors.appMainColor}
                    className="flex p-1"
                    onclick={() => {
                      setOpenDrawer(!openDrawer);
                    }}
                  /> */}
                  <TextUnderlineButton
                    title="Transfer user"
                    color={Colors.appMainColor}
                    className="flex p-1"
                    onclick={() => {
                      setOpenDrawer(!openDrawer);
                      setTransferModal(true);
                    }}
                  />
                  <TextUnderlineButton
                    title="Resolve chat"
                    color={Colors.appMainColor}
                    className="flex p-1"
                    onclick={() => {
                      setOpenDrawer(!openDrawer);
                      handleResolveClick();
                    }}
                  />
                </div>
              )}
            </Drawer>
          </div>
        </ChatsContainer>
      </Container>

      <Modal
        centered
        open={
          openModel || markAsResolved.isSuccess || transferredCase.isSuccess
        }
        onOk={() => setOpenModel(false)}
        onCancel={() => {
          resetMutations();
          setOpenModel(false);
        }}
        width={550}
        closeIcon={
          <CustomIcons.CloseIcon
            width={30}
            height={30}
            viewBox={null}
            svgColor="#1C274C"
          />
        }
        footer={null}
      >
        <div className="flex w-full h-full gap-2 flex-column align-items-center justify-content-center">
          <div className="flex w-full justify-content-center">
            <CustomIcons.SuccessIcon
              width={100}
              height={100}
              viewBox={null}
              svgColor="#179717"
            />
          </div>
          <div className="flex justify-content-center align-content-center">
            <SuccessStatusText>Successful</SuccessStatusText>
          </div>
          <SuccessStatusDescriptionText>
            {modelMessage}
          </SuccessStatusDescriptionText>
          <Button
            type="primary"
            ghost
            onClick={closeModal}
            size="large"
            className="w-4"
          >
            close
          </Button>
        </div>
      </Modal>
      <Modal
        centered
        open={openOperatorNoteModel}
        onOk={() => setOpenOperatorNoteModel(false)}
        onCancel={() => {
          setOpenOperatorNoteModel(false);
          postOperatorNote.reset();
        }}
        width={550}
        closeIcon={
          <CustomIcons.CloseIcon
            width={30}
            height={30}
            viewBox={null}
            svgColor="#1C274C"
          />
        }
        footer={null}
      >
        <>
          <div
            className="flex w-12 py-3 justify-content-start"
            style={modelTitle}
          >
            Add Note
          </div>
          {emergencyCaseNote.isLoading ? (
            <div className="w-full">
              <CustomIcons.EOSThreeDotsLoading
                width={30}
                height={30}
                viewBox={null}
                svgColor="#1C274C"
              />
            </div>
          ) : (
            <OperatorNoteForm
              isSuccess={postOperatorNote.isSuccess}
              isLoading={postOperatorNote.isLoading}
              note={emergencyCaseNote.data}
              onFormFinish={(value: string) => {
                handlePostOperatorNoteClick(value);
              }}
              onModelClose={() => {
                setOpenOperatorNoteModel(false);
              }}
            />
          )}
        </>
      </Modal>

      <Modal
        centered
        open={transferModal}
        onOk={() => setTransferModal(false)}
        onCancel={() => {
          setTransferModal(false);
        }}
        width={1000}
        closeIcon={
          <CustomIcons.CloseIcon
            width={30}
            height={30}
            viewBox={null}
            svgColor="#1C274C"
          />
        }
        footer={null}
      >
        <>
          <div
            className="flex w-12 py-3 justify-content-start"
            style={modelTitle}
          >
            Operators
          </div>
          {emergencyCaseNote.isLoading ? (
            <div className="w-full">
              <CustomIcons.EOSThreeDotsLoading
                width={30}
                height={30}
                viewBox={null}
                svgColor="#1C274C"
              />
            </div>
          ) : (
            <TransferableOperators
              data={transferableOperators.data ?? []}
              isLoading={transferableOperators.isLoading}
              onTransfer={async (operatorId: number) => {
                if (currentEmergencyCaseId) {
                  const toastId = toast.loading("Transferring...");
                  const transferPromise = handleTransferByOperatorClickPromise(
                    currentEmergencyCaseId,
                    operatorId
                  );
                  transferPromise
                    .then(() => {
                      toast.success("Successfully transferred!", {
                        id: toastId,
                      });
                      setTransferModal(false);
                    })
                    .catch((err) => {
                      toast.dismiss(toastId);
                      console.log(err); // Optional: Log error to console or handle it further
                    });
                } else {
                  toast.error("Please select a case");
                }
              }}
            />
          )}
        </>
      </Modal>
    </div>
  );
};
export default Messaging;

/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-unused-vars */
import {
  Button,
  DatePicker,
  Form,
  FormInstance,
  Input,
  message,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { en, faker } from "@faker-js/faker";
import { copyObjects } from "../../util/copyObjects";
import { useGetCompanies } from "../../hooks/useCompanies";
import { UserType } from "../../util/enums";
import PhoneNumberPrefixSelector from "../widgets/Inputs/PhoneNumberPrefixSelector";

type NewUserFormProp = {
  onFormFinish: (user: IAddNewUser) => Promise<void>;
  onModelClose: () => void;
  isLoading: boolean;
  isSuccess: boolean;
};

const AddNewUser = ({
  onFormFinish,
  onModelClose,
  isLoading,
  isSuccess,
}: NewUserFormProp) => {
  const [form] = Form.useForm();
  const [optionCompany, setOptionCompany] = useState<string>();
  const [subId, setSubId] = useState<number>(0);
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();

  const handleDateChange = (dates: any, dateString: [string, string]) => {
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
  };

  const handleChangeCompany = (value: string) => {
    setOptionCompany(value);
  };

  const handleSubChange = (value: number) => {
    setSubId(value);
  };

  const { RangePicker } = DatePicker;

  const onFinish = async (formValues: any) => {
    const values: IAddNewUser = {
      user: {
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        dateOfBirth: formValues.dateOfBirth,
        gender: formValues.gender,
        userCode: faker.string.alphanumeric(4),
        password: faker.internet.password(),
        emailAddress: formValues.emailAddress,
        phoneNumber: formValues.prefix + formValues.phoneNumber,
        companyId: formValues.companyId,
      },
      subTypeId: subId as number,
      validFrom: startDate as string,
      validTo: endDate as string,
    };
    await onFormFinish(values);
  };

  const { data } = useGetCompanies();

  const handleClose = () => {
    form.resetFields();
    setOptionCompany("");
    setSubId(0);
    onModelClose();
  };

  const onFinishFailed = (errorInfo: any) => {
    message.error("Could not create user, please try again.");
  };

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
    }
  }, [isSuccess]);

  return (
    <Form
      form={form}
      name="newUser"
      onFinish={onFinish}
      layout="vertical"
      style={{ width: "100%" }}
      className="grid"
      initialValues={{
        prefix: "+27", // Set the default value for the prefix here
      }}
    >
      <div className="col-12 md:col-6">
        <Form.Item
          name="firstName"
          label="First Name"
          rules={[
            { required: true, message: "Please input the user's first name!" },
          ]}
          className="mb-1"
        >
          <Input size="large" placeholder="Amanda" />
        </Form.Item>
      </div>

      <div className="col-12 md:col-6">
        <Form.Item
          name="lastName"
          label="Last Name"
          rules={[
            { required: true, message: "Please input the user's last name!" },
          ]}
          className="mb-1"
        >
          <Input size="large" placeholder="Neli" />
        </Form.Item>
      </div>

      <div className="col-12 md:col-6">
        <Form.Item
          name="emailAddress"
          label="Email"
          rules={[
            { required: true, message: "Please input the user's email!" },
            { type: "email", message: "Please input a valid email!" },
          ]}
          className="mb-1"
        >
          <Input size="large" placeholder="Amando@gmail.com" />
        </Form.Item>
      </div>

      <div className="col-12 md:col-6">
        <Form.Item
          name="phoneNumber"
          label="Phone Number"
          rules={[
            {
              required: true,
              message: "Please enter user's phone number!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                // Assuming the country code (+27) is automatically included and not part of the input
                const phoneNumberPattern = /^[6-8][0-9]{8}$/;
                const fullPhoneNumber = getFieldValue("prefix") + value;
                // Validate the full phone number including the country code
                // ^\+27[6-8][0-9]{8}$
                // ^(\+27|0)[6-8][0-9]{8}$ // if you want to use 0 as the country code
                if (/^(\+27)[6-8][0-9]{8}$/.test(fullPhoneNumber)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    "Please enter a valid South African phone number without a leading 0 after the country code!"
                  )
                );
              },
            }),
          ]}
          className="mb-1"
        >
          <Input
            size="large"
            addonBefore={PhoneNumberPrefixSelector()}
            placeholder="63256392"
          />
        </Form.Item>
      </div>

      <div className="col-12 md:col-6">
        <Form.Item
          name="dateOfBirth"
          label="Date of birth"
          rules={[
            { required: true, message: "Please enter user's date of birth!" },
          ]}
          className="mb-3"
        >
          <DatePicker size="large" style={{ width: "100%" }} />
        </Form.Item>
      </div>

      <div className="col-12 md:col-6">
        <Form.Item
          name="gender"
          label="Gender"
          rules={[
            { required: true, message: "Please select the user's gender!" },
          ]}
          className="mb-3"
        >
          <Select placeholder="Select user's gender" size="large">
            <Select.Option value="Male">Male</Select.Option>
            <Select.Option value="Female">Female</Select.Option>
            <Select.Option value="Other">Other</Select.Option>
          </Select>
        </Form.Item>
      </div>

      <div className="col-12 md:col-6">
        <Form.Item name="companyId" label="Company" className="mb-3">
          <Select
            placeholder="Select user company"
            size="large"
            onChange={handleChangeCompany}
          >
            {data?.map((item: IActiveCompanies) => (
              <Select.Option
                key={`key-companies-${item.companyId}`}
                value={item.companyId}
              >
                {item.companyName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>

      <div className="col-12 md:col-6">
        <Form.Item
          name={"subTypeId"}
          label="Select Subscription"
          className="mb-1"
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                const companyId = getFieldValue("companyId");

                if (companyId && !value) {
                  return Promise.reject(
                    new Error("Please select subscription!")
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Select
            onChange={handleSubChange}
            placeholder="Subscription Plan"
            size="large"
            options={[
              { value: 2, label: "Basic Plan" },
              { value: 8, label: "Family of Two" },
              { value: 3, label: "Family of Four" },
              { value: 4, label: "Family of Six" },
              { value: 5, label: "Premium" },
              { value: 9, label: "Family of Two Premium " },
              {
                value: 6,
                label: "Family of Four Premium",
              },
              {
                value: 7,
                label: "Family of Six Premium",
              },
            ]}
          />
        </Form.Item>
      </div>

      {subId > 0 && (
        <div className="col-12 md:col-6">
          <Form.Item
            name="contractPeroid"
            label="Select Contract Period"
            className="mb-2"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const subscriptionId = getFieldValue("subTypeId");

                  if (subscriptionId && !value) {
                    return Promise.reject(
                      new Error("Please select contract period!")
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <RangePicker onChange={handleDateChange} size="large" />
          </Form.Item>
        </div>
      )}

      <Form.Item className="w-12 mb-1">
        <div className="flex w-full gap-2 col-12 justify-content-end align-content-center">
          <Button
            type="primary"
            ghost
            onClick={handleClose}
            size="large"
            className="w-3"
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            size="large"
            className="w-3 bg-[#1757a2] shadow-none"
          >
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default AddNewUser;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { useHistory } from "react-router-dom";
import { CustomIcons } from "../../../assets";

const BackButton = () => {
  const navigate = useHistory();
  return (
    <div
      className="ml-2"
      onClick={() => {
        navigate.goBack();
      }}
    >
      <CustomIcons.BackIcon height={32} width={32} svgColor="#495057" />
    </div>
  );
};

export default BackButton;

/* eslint-disable no-underscore-dangle */
import {
  InfiniteData,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { queries } from "../services/queries";
import CommunityMessage from "../services/CommunityMessages";
import { isErrorMessage } from "../util/helpers";
import { DirectMessage } from "../services";

export function useGetCommunityCaseMessages(
  communityCaseId: number | string | null | undefined
): UseQueryResult<ICommunityMessage[]> {
  return useQuery({
    ...queries.communityMessage.GetCommunityCaseMessages(communityCaseId),
    enabled: !!communityCaseId,
    refetchInterval: 30 * 1000, // refetch every 30 seconds
    refetchIntervalInBackground: true,
  });
}

export function usePostCommunityMessage() {
  const queryClient = useQueryClient();

  return useMutation(CommunityMessage.postMessage, {
    onMutate: async (dm) => {
      // get previous caseMessage data
      const previousCommunityCaseMessagesData = queryClient.getQueryData(
        queries.communityMessage.GetCommunityCaseMessages(dm.communityCaseId)
          .queryKey
      );

      // optimistic update
      queryClient.setQueryData(
        queries.communityMessage.GetCommunityCaseMessages(dm.communityCaseId)
          .queryKey,
        (oldDirectMessagesData: ICommunityMessage[] = []) => {
          return [...oldDirectMessagesData, dm];
        }
      );

      return { previousCommunityCaseMessagesData };
    },
    onError: (error: AxiosError, dm, context) => {
      // rollback to the previous value if mutation fails
      if (context) {
        queryClient.setQueryData(
          queries.communityMessage.GetCommunityCaseMessages(dm.communityCaseId)
            .queryKey,
          context.previousCommunityCaseMessagesData
        );
      }
      const errorMessage = error.response?.data;

      if (typeof errorMessage === "string") {
        toast.error(errorMessage);
      } else if (isErrorMessage(errorMessage)) {
        const errorTitle = errorMessage.title;
        const errorKeys = Object.keys(errorMessage.errors || {});
        if (errorKeys.length > 0 && errorMessage.errors) {
          const errorFieldMessage = `${errorKeys[0]}: ${
            errorMessage.errors[errorKeys[0]][0]
          }`;
          toast.error(errorFieldMessage);
        } else {
          toast.error(errorTitle);
        }
      } else {
        // If errorMessage is not a string and not an IErrorMessage, show a generic error message
        toast.error("Something went wrong");
      }
    },
    onSuccess: (dm, variables) => {
      queryClient.invalidateQueries({
        queryKey: queries.communityMessage.GetCommunityCaseMessages(
          variables.communityCaseId
        ).queryKey,
      });
      // Invalidate Message Panel Here
      queryClient.invalidateQueries({
        queryKey: queries.communities.GetCommunityAdminCases(variables.senderId)
          .queryKey,
      });
    },
    meta: {
      useError: true,
    },
  });
}

export function useResolveCommunityCase() {
  const queryClient = useQueryClient();

  return useMutation(CommunityMessage.resolveCase, {
    onError: (error: AxiosError, dm, context) => {
      const errorMessage = error.response?.data;

      if (typeof errorMessage === "string") {
        toast.error(errorMessage);
      } else if (isErrorMessage(errorMessage)) {
        const errorTitle = errorMessage.title;
        const errorKeys = Object.keys(errorMessage.errors || {});
        if (errorKeys.length > 0 && errorMessage.errors) {
          const errorFieldMessage = `${errorKeys[0]}: ${
            errorMessage.errors[errorKeys[0]][0]
          }`;
          toast.error(errorFieldMessage);
        } else {
          toast.error(errorTitle);
        }
      } else {
        // If errorMessage is not a string and not an IErrorMessage, show a generic error message
        toast.error("Something went wrong");
      }
    },
    onSuccess: (caseData) => {
      // Invalidate Message Panel Here
      queryClient.invalidateQueries({
        queryKey: queries.communities.GetCommunityAdminCases(
          caseData.data.communityAdminId
        ).queryKey,
      });
    },
    meta: {
      useError: true,
    },
  });
}

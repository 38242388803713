/* eslint-disable comma-dangle */
/* eslint-disable function-paren-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable  implicit-arrow-linebreak */
import styled from "styled-components";
import { Select } from "antd";
import Colors from "../../config/Colors";

export const Title = styled.div`
  font-size: 33px;
  font-weight: normal;
  text-align: left;
  align-self: flex-start;
  font-weight: 700;
  line-height: 51px;
  display: flex;
  color: ${Colors.mainTextColor};
`;

export const UserPreview = styled.div`
  text-align: left;
  align-items: "flex-start";
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: ${Colors.subtleBlue};
  }
`;

export const UserName = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: ${Colors.mainTextColor};
`;

export const UserPreviewMessage = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: ${Colors.subtleTextColor};
`;

export const SortSelect = styled(Select)`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none;
    border-radius: 44px;
    padding: 5px 5px;
    background: transparent;
    color: ${Colors.appMainColor};
    font-weight: 400;
    font-size: 14px;
  }

  .ant-select-selection-placeholder {
    color: ${Colors.appMainColor};
  }

  #container .ant-select-selector {
    padding-top: 8px;
    padding-left: 25px;
    padding-right: 40px;
    height: 50px;
  }

  .ant-select-arrow {
    font-size: 10px;
    color: ${Colors.appMainColor};
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    padding-right: 0px !important;
  }

  .ant-select-selection-item {
    color: ${Colors.appMainColor};
  }
`;

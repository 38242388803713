import React from "react";
import confetti from "canvas-confetti";

type LikeButtonsProps = {
  active: boolean;
  amount: number;
  onLike: () => void;
};

const LikeContent = ({ src, amount }: { src: string; amount: number }) => (
  <div className="flex items-center justify-center gap-2">
    <img
      width={25}
      height={25}
      src={src}
      aria-label="Click here to like or unlike"
      alt="Like here"
    />
    <p className="px-0 mb-0 text-lg text-mainTextColor">{amount}</p>
  </div>
);

export default function LikeButton({
  active,
  amount,
  onLike,
}: LikeButtonsProps) {
  const handleLike = () => {
    if (!active) {
      // Trigger confetti only on like
      confetti({
        angle: 90,
        spread: 100,
        startVelocity: 40,
        particleCount: 60,
      });
    }
    onLike();
  };
  return (
    <button type="button" onClick={handleLike} className="pb-2">
      <LikeContent
        src={
          active
            ? "https://api.iconify.design/solar:like-bold.svg?color=%230C4E9D"
            : "https://api.iconify.design/solar:like-outline.svg?color=%23888888"
        }
        amount={amount}
      />
    </button>
  );
}

import { createQueryKeys } from "@lukemorales/query-key-factory";
import CPFResponder from "../CPFs";

export const cpfResponder = createQueryKeys("cpfResponder", {
  getCPFResponders: (page: number, pageSize: number) => ({
    queryKey: [{ page, pageSize }],
    queryFn: () => CPFResponder.getCPFResponders(page, pageSize),
  }),
  getCPFResponderDetails: (id: number) => ({
    queryKey: [id],
    queryFn: () => CPFResponder.getCPFResponderDetails(id),
  }),
});

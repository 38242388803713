import React, { useContext, useState } from "react";
import {
  HandHelping,
  Ambulance,
  Siren,
  ChevronRight,
  File,
} from "lucide-react";
import { Upload, Modal, Button as AButton } from "antd";
import { UploadFile, RcFile, UploadProps } from "antd/es/upload/interface";
import { toast } from "sonner";

import { useModal } from "@/hooks/useModalStore";
import { CustomIcons } from "@/assets/index";
import { baseUploadProps } from "@/util/helpers";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import DaggerCard from "@/components/Cards/DaggerCard/DaggerCard";
import { usePanicTypes } from "@/hooks/usePanicTypes";
import Colors from "@/config/Colors";
import AuthContext from "@/contexts/AuthContext";
import { useUploadResponder } from "@/hooks/useResponder";

const { Dragger } = Upload;

function ResponderBulkUpload() {
  const { user } = useContext(AuthContext);
  const { isOpen, onClose, type, data } = useModal();
  const { data: panicTypes, isLoading: fetchingPanicTypes } = usePanicTypes();
  const uploadResponderMutation = useUploadResponder();
  const isModalOpen = isOpen && type === "responderBulkUpload";
  const { activeResponderCompanyId } = data;
  const [selectedPanicType, setSelectedPanicType] =
    useState<IPanicTypes | null>(null); // Change any to the type of panicType when it is known
  const [templateUrl, setTemplateUrl] = useState<string | null>(null);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [excelFile, setExcelFile] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  // Helper Functions
  const handleReset = () => {
    setFileList([]);
    setExcelFile(null);
    setTemplateUrl(null);
    setSelectedPanicType(null);
  };

  const beforeUpload = (file: RcFile) => {
    const isExcel = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ].includes(file.type);
    const isLt15M = file.size / 1024 / 1024 < 15;
    if (!isExcel) {
      toast.error("You can only upload excel file!");
      return false; // This prevents the file from being added to the list
    }
    if (!isLt15M) {
      toast.error("file must smaller than 15MB!");
      return false; // This prevents the file from being added to the list
    }
    return isExcel && isLt15M;
  };

  const excelUploadProps: UploadProps = {
    ...baseUploadProps,
    beforeUpload,
    onChange(info) {
      let newFileList = [...info.fileList];

      // Filter out if you want only the last file:
      newFileList = newFileList.slice(-1);

      setFileList(newFileList); // Update your state here
      const { status } = info.file;
      if (status === "uploading") {
        setIsUploading(true);
      } else if (status === "done") {
        setIsUploading(false);
        setExcelFile(info.file.response.path); // Update certificate URL state
        toast.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        setIsUploading(false);
        toast.error(`${info.file.name} file upload failed.`);
      }
    },
    fileList,
    maxCount: 1,
    accept: ".xlsx",
  };

  // Modal Functions
  const handleClose = async () => {
    handleReset();
    onClose();
  };

  // UI functions
  const isSelected = (panicTypeLabel: string) =>
    selectedPanicType?.name === panicTypeLabel;

  const renderIcons = (typeSelection: string, isSelectedValue: boolean) => {
    const iconColor = isSelectedValue ? "#FFFFFF" : "#000000"; // White for selected, black for unselected
    switch (typeSelection.toLowerCase()) {
      case "cpf":
        return <HandHelping size={24} color={iconColor} />;
      case "medical":
        return <Ambulance size={24} color={iconColor} />;
      default:
        return <Siren size={24} color={iconColor} />;
    }
  };

  const handlePanicTypeSelection = (panicTypeLabel: string) => {
    const panicType = panicTypes?.find(
      (panic) => panic.name === panicTypeLabel
    );

    if (panicType) {
      setSelectedPanicType(panicType);
    }
  };

  const handleNext = () => {
    // Set the template url based on the panic type selected
    setTemplateUrl(selectedPanicType?.templateUrl || "");
  };

  // Async Functions
  const handleSave = async () => {
    // Check if the fileList is empty or the excel file is empty
    // If it is empty, show an error message
    if (!fileList.length || !excelFile) {
      toast.error("Please upload a file");
      return;
    }
    // Send the excel file to the backend
    const payload: IResponderUploadRequest = {
      companyId: activeResponderCompanyId || 0,
      typeOfPanic: selectedPanicType?.name || "",
      url: excelFile,
      uploadBy: user?.id || 0,
    };
    try {
      const response = await uploadResponderMutation.mutateAsync(payload);
      if (response) {
        toast.success(
          "File uploaded successfully, you will shortly receive an email notification regarding the status of the upload.",
          {
            duration: 5000,
          }
        );
        handleClose();
      }
    } catch (error) {}
  };

  if (fetchingPanicTypes)
    return (
      <div className="flex items-center justify-center w-full h-full bg-backgroundColor">
        <CustomIcons.EOSThreeDotsLoading
          width={90}
          height={90}
          svgColor={Colors.appMainColor}
        />
      </div>
    );

  return (
    <Modal
      centered
      open={isModalOpen}
      onCancel={() => handleClose()}
      width={600}
      closeIcon={
        <CustomIcons.CloseIcon
          width={30}
          height={30}
          viewBox={null}
          svgColor="#1C274C"
        />
      }
      footer={null}
    >
      <div className="flex flex-col w-full h-full gap-1 min-h-96">
        <span className="text-xl font-normal text-center text-mainTextColor">
          Create Bulk Responders
        </span>
        <span className="text-sm font-medium text-center text-subtleTextColor">
          You can create multiple responders at once by uploading a spreadsheet
        </span>
        {/* Render Options Here */}
        {!templateUrl && (
          <div
            className={`flex flex-col w-full h-full${
              selectedPanicType
                ? "animate-backOutLeft animate-duration-[3s]"
                : ""
            }`}
          >
            <span className="my-4 text-lg font-bold text-center text-mainTextColor">
              Select Panic Type
            </span>
            <div className="flex flex-col items-center justify-center h-full">
              <div className="grid items-center w-full grid-cols-1 gap-2 md:grid-cols-3 justify-evenly">
                {panicTypes?.map((panicType) => {
                  // check if the panicTypes contains medical or security or cpf if not return null
                  if (!["Medical", "Security"].includes(panicType.name)) {
                    return null;
                  }
                  return (
                    <Button
                      variant="ghost"
                      className={cn(
                        "flex flex-col items-center h-[120px] w-[150px] justify-center gap-1 p-1 bg-white rounded-lg shadow-md hover:bg-gray-100",
                        isSelected(panicType.name) &&
                          "bg-appMainColor hover:bg-appMainColor"
                      )}
                      key={panicType.id}
                      onClick={() => handlePanicTypeSelection(panicType.name)}
                    >
                      <div className="flex items-center justify-center w-12 h-12 rounded-full">
                        {renderIcons(
                          panicType.name,
                          isSelected(panicType.name)
                        )}
                      </div>
                      <span
                        className={cn(
                          "mt-2 text-lg font-bold text-center text-mainTextColor",
                          isSelected(panicType.name) ? "text-[#FFFFFF]" : ""
                        )}
                      >
                        {panicType.name}
                      </span>
                    </Button>
                  );
                })}
              </div>
            </div>
            {/* Next Button to be rendered here once you the panic type is selected */}
            {selectedPanicType && (
              <div className="flex items-end justify-end px-4">
                <Button
                  variant="outline"
                  size="icon"
                  className="justify-center w-2 h-10 mt-4"
                  onClick={handleNext}
                >
                  Next
                  <ChevronRight className="w-2 h-4 ml-1" />
                </Button>
              </div>
            )}
          </div>
        )}
        {/* Render Upload Screen Here With Dagger, Template url based on the panic selected */}
        {templateUrl && (
          <div className="w-full h-full animate-distance-full animate-fadeInRight animate-duration-[0.31s]">
            <div className="flex flex-col items-center justify-center gap-1 my-4">
              <span className="text-sm text-mainTextColor">
                1. Download spreadsheet
              </span>
              <Button variant="link" className="text-appMainColor" asChild>
                <a
                  href={templateUrl}
                  download={`${selectedPanicType?.name}_template.xlsx`}
                >
                  <File className="w-4 h-4 mr-1" />
                  Download Template
                </a>
              </Button>
              <span className="text-sm text-mainTextColor">2. Upload</span>
              <span className="text-xs text-mainTextColor">
                Fill and Submit the excel document
              </span>
            </div>
            <Dragger
              {...excelUploadProps}
              className="w-full"
              style={{ background: "transparent" }}
            >
              <DaggerCard description="File should be .xlsx" />
            </Dragger>
            <div className="flex w-full gap-2 my-4 col-12 justify-content-end align-content-center">
              <AButton
                type="primary"
                ghost
                onClick={handleClose}
                size="large"
                className="w-3"
              >
                Cancel
              </AButton>
              <AButton
                type="primary"
                htmlType="button"
                size="large"
                className="w-3 bg-[#1757a2] shadow-none"
                disabled={isUploading} // Disable the button while uploading
                onClick={handleSave}
                loading={uploadResponderMutation.isLoading}
              >
                Save
              </AButton>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default ResponderBulkUpload;

import React, { useState, useRef, useEffect } from "react";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import {
  SearchOutlined,
  DownloadOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Tag, Button, Modal } from "antd";
import { Calendar, CalendarChangeEvent } from "primereact/calendar";
import moment from "moment";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { MultiSelect } from "primereact/multiselect";
import { BodyContainer, Container } from "../../styles/ContainerStyles";
import HeaderComponent from "../../components/Header/Header";
import {
  tableBody,
  tableHeader,
  tableName,
  tableBodyRight,
} from "../../styles/TableStyles";
import CustomInputField from "../../components/widgets/Inputs/CustomInputField";
import Colors from "../../config/Colors";
import { useSalesIQAll } from "../../hooks/useSalesIQ";
import Spin from "../../components/widgets/Spinner/Spin";
import { useAllCommunityCodes } from "../../hooks/useCommunityCodes";
import { modelTitle, successTitle, successSubtitle } from "../Users/styles";
import { CustomIcons } from "../../assets";
import SalesIQModal from "../../components/Modals/SalesIQModal";

const SalesIQ = () => {
  // Refs
  // @ts-ignore
  const dt = useRef<DataTable>(null);

  // Hooks
  const { data: salesIQData, isLoading, isFetching } = useSalesIQAll();
  const allCommunityCodes = useAllCommunityCodes();

  // States
  const [salesIQTable, setSalesIQTable] = useState<ISalesIQTable[]>([]);
  const [selectedUser, setSelectedUser] = useState<number | null>(null);
  const [openDownloadButtonsModal, setOpenDownloadButtonsModal] =
    useState(false);
  const [viewMoreModal, setViewMoreModal] = useState(false);
  const [filters, setFilters] = useState<DataTableFilterMeta | undefined>(
    undefined
  );
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");

  // Filters
  const [statuses] = useState<string[]>([
    "Subscribed",
    "Unsubscribed",
    "Non-Renewing",
  ]);
  const [subscriptionFrom] = useState<string[]>(["Android", "IOS", "WEB"]);
  const [subTypes] = useState<string[]>([
    "Basic Plan",
    "Family of 2",
    "Family of 4",
    "Family of 6",
    "Premium",
    "Family of 2 Premium",
    "Family of 4 Premium",
    "Family of 6 Premium",
    "Pending cancellation",
  ]);
  const [salesTypes] = useState<string[]>(["Repeating Sale", "New Sale"]);
  const [communityCodes] = useState<string[]>([]);
  const [buttonSuppliers] = useState<string[]>([]);
  const [amounts] = useState<string[]>([
    "R35.99",
    "R69.99",
    "R70.00",
    "R120.00",
    "R135.00",
    "R180.00",
    "255.00",
    "R380.00",
    "Contractual",
  ]);

  // Functions

  const onGlobalFilterChangeV2 = (e: string) => {
    const _filters = { ...filters };
    // @ts-ignore
    _filters.global.value = e;

    setFilters(_filters);
    setGlobalFilterValue(e);
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      firstName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      lastName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      email: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      phoneNumber: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      accountCreated: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      subscribedDate: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      expiringDate: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      cancelDate: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      subscriptionStatus: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      sales: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      companyId: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      companyName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      subscriptionAmount: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      subscriptionFrom: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      communityCode: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      physicalButton: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      subscriptionType: { value: null, matchMode: FilterMatchMode.IN },
    });
    setGlobalFilterValue("");
  };

  const getSeverity = (status: string) => {
    switch (status) {
      case "Subscribed":
        return "success";
      case "Unsubscribed":
        return "error";
      case "Non-Renewing":
        return "warning";
      default:
        return "processing";
    }
  };

  const getSystemColor = (system: string) => {
    switch (system) {
      case "IOS":
        return "#5ac8fa";
      case "Android":
        return "#71D98C";
      case "WEB":
        return "#F2C347";
      default:
        return "#B6CB51";
    }
  };

  const getSubTypeColor = (type: string) => {
    switch (type.toLowerCase()) {
      case "basic plan":
        return "#2B569D";
      case "family of 2":
        return "#4FD1C5"; // Teal blue
      case "family of 4":
        return "#38BDF8";
      case "family of 6":
        return "#1D4ED8";
      case "premium":
        return "#7C3AED"; // Royal purple
      case "family of 2 premium":
        return "#805AD5"; // Deep violet
      case "family of 4 premium":
        return "#6D28D9"; // Periwinkle blue
      case "family of 6 premium":
        return "#4C1D95"; // Dark indigo
      case "-":
        return "";
      default:
        return "#FDA29B";
    }
  };

  const getAmountColor = (amount: string) => {
    switch (amount.toLowerCase()) {
      case "r35,99":
      case "r35.99":
        return "#2B569D";
      case "r69,99":
      case "r69.99":
        return "#4FD1C5"; // Teal blue
      case "r70,00":
      case "r70.00":
        return "#7C3AED"; // Royal purple
      case "r120,00":
      case "r120.00":
        return "#38BDF8";
      case "r135,00":
      case "r135.00":
        return "#2B569D";
      case "r180,00":
      case "r180.00":
        return "#1D4ED8";
      case "r255,00":
      case "r255.00":
        return "#6D28D9"; // Periwinkle blue
      case "r380,00":
      case "r380.00":
        return "#4C1D95"; // Dark indigo
      case "contractual":
        return "#37f434";
      default:
        return "#FDA29B";
    }
  };

  const getSalesColor = (sale: string) => {
    switch (sale.toLowerCase()) {
      case "repeating sales":
      case "repeating sale":
        return "success";
      case "new sale":
      case "new sales":
        return "processing";
      default:
        return "error";
    }
  };

  const statusItemTemplate = (option: string) => {
    return <Tag color={getSeverity(option)}>{option}</Tag>;
  };

  const systemItemTemplate = (option: string) => {
    return <Tag color={getSystemColor(option)}>{option}</Tag>;
  };

  const subTypeBodyTemplate = (rowData: ISalesIQUser) => {
    return (
      <Tag color={getSubTypeColor(rowData.subscriptionType)}>
        {rowData.subscriptionType}
      </Tag>
    );
  };

  const amountBodyTemplate = (rowData: ISalesIQUser) => {
    return (
      <Tag color={getAmountColor(rowData.subscriptionAmount)}>
        {rowData.subscriptionAmount}
      </Tag>
    );
  };

  const subTypeItemTemplate = (option: string) => {
    return (
      <div className="flex gap-2 align-items-center">
        <Tag color={getSubTypeColor(option)}>{option}</Tag>
      </div>
    );
  };
  const salesItemTemplate = (option: string) => {
    return <Tag color={getSalesColor(option)}>{option}</Tag>;
  };
  const amountItemTemplate = (option: string) => {
    return (
      <div className="flex gap-2 align-items-center">
        <Tag color={getAmountColor(option)}>{option}</Tag>
      </div>
    );
  };

  const statusBodyTemplate = (rowData: ISalesIQUser) => {
    return (
      <Tag color={getSeverity(rowData.subscriptionStatus)}>
        {rowData.subscriptionStatus}
      </Tag>
    );
  };

  const accountCreatedBodyTemplate = (rowData: ISalesIQTable) => {
    return moment(rowData.accountCreated).format("DD/MM/YYYY");
  };

  const subscriptionDateBodyTemplate = (rowData: ISalesIQTable) => {
    return moment(rowData.subscribedDate).format("DD/MM/YYYY");
  };
  const expiringDateBodyTemplate = (rowData: ISalesIQTable) => {
    return moment(rowData.expiringDate).format("DD/MM/YYYY");
  };

  const subscriptionCancelDateBodyTemplate = (rowData: ISalesIQTable) => {
    if (!rowData.cancelDate) return "-";
    if (rowData.subscriptionStatus === "subscribed") return "-";
    return moment(rowData.cancelDate).format("DD/MM/YYYY");
  };

  const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e: CalendarChangeEvent) => {
          options.filterCallback(e.value, options.index);
        }}
        dateFormat="yy/mm/dd"
        placeholder="yyyy/mm/dd"
        mask="9999/99/99"
      />
    );
  };

  const statusFilterTemplate = (
    options: ColumnFilterElementTemplateOptions
  ) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e: DropdownChangeEvent) =>
          options.filterCallback(e.value, options.index)
        }
        itemTemplate={statusItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
      />
    );
  };

  const referralFilterTemplate = (
    options: ColumnFilterElementTemplateOptions
  ) => {
    return (
      <Dropdown
        value={options.value}
        options={communityCodes}
        onChange={(e: DropdownChangeEvent) =>
          options.filterCallback(e.value, options.index)
        }
        itemTemplate={statusItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
      />
    );
  };

  const salesFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Dropdown
        value={options.value}
        options={salesTypes}
        onChange={(e: DropdownChangeEvent) =>
          options.filterCallback(e.value, options.index)
        }
        itemTemplate={salesItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
      />
    );
  };

  const subscriptionTypeFilterTemplate = (
    options: ColumnFilterElementTemplateOptions
  ) => {
    return (
      <MultiSelect
        value={options.value}
        options={subTypes}
        onChange={(e: DropdownChangeEvent) =>
          options.filterCallback(e.value, options.index)
        }
        itemTemplate={subTypeItemTemplate}
        placeholder="Select Any"
        className="p-column-filter"
        showClear
      />
    );
  };

  const amountFilterTemplate = (
    options: ColumnFilterElementTemplateOptions
  ) => {
    return (
      <MultiSelect
        value={options.value}
        options={amounts}
        onChange={(e: DropdownChangeEvent) =>
          options.filterCallback(e.value, options.index)
        }
        itemTemplate={amountItemTemplate}
        placeholder="Select Any"
        className="p-column-filter"
        showClear
      />
    );
  };

  const systemFilterTemplate = (
    options: ColumnFilterElementTemplateOptions
  ) => {
    return (
      <Dropdown
        value={options.value}
        options={subscriptionFrom}
        onChange={(e: DropdownChangeEvent) =>
          options.filterCallback(e.value, options.index)
        }
        itemTemplate={systemItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
      />
    );
  };

  const buttonSuppliersFilterTemplate = (
    options: ColumnFilterElementTemplateOptions
  ) => {
    return (
      <Dropdown
        value={options.value}
        options={buttonSuppliers}
        onChange={(e: DropdownChangeEvent) =>
          options.filterCallback(e.value, options.index)
        }
        itemTemplate={statusItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
      />
    );
  };

  const parseDate = (dateString: string) => {
    const parts = dateString.split("/");
    // Assuming the format is dd/mm/yyyy
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // month is 0-indexed in JavaScript Date
    const year = parseInt(parts[2], 10);
    return new Date(year, month, day);
  };

  // UseEffects

  const getSalesIQ = (data: ISalesIQTable[]) => {
    if (Array.isArray(data)) {
      return data.map((d) => {
        d.accountCreated = parseCorrectly(d.accountCreated);
        d.subscribedDate = parseCorrectly(d.subscribedDate);
        d.expiringDate = parseCorrectly(d.expiringDate);
        if (d.cancelDate) d.cancelDate = parseCorrectly(d.cancelDate);
        return d;
      });
    }
    return [];
  };

  const parseCorrectly = (dateString: string | Date) => {
    // Check if dateString is already a Date object
    if (dateString instanceof Date) {
      return dateString;
    }

    // Check for ISO 8601 format (e.g., 2023-04-24T07:16:39.3133333)
    const iso8601Regex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d+/;
    if (iso8601Regex.test(dateString)) {
      return new Date(dateString);
    }

    // Otherwise, parse from dd/mm/yyyy format
    const parts = dateString.split("/");
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // month is 0-indexed in JavaScript Date
    const year = parseInt(parts[2], 10);
    return new Date(year, month, day);
  };

  useEffect(() => {
    if (salesIQData) {
      setSalesIQTable(getSalesIQ(salesIQData));
    }

    initFilters();
  }, [salesIQData]);

  useEffect(() => {
    if (allCommunityCodes.data) {
      communityCodes.length = 0;

      communityCodes.push(...allCommunityCodes.data.map((d) => d.code));
    }
  }, [allCommunityCodes.data]);

  // Export Download
  const exportCSV = (selectionOnly: boolean) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const cols = [
    { field: "accountCreated", header: "Account Created" },
    { field: "firstName", header: "First Name" },
    { field: "lastName", header: "Last Name" },
    { field: "email", header: "Email" },
    { field: "phoneNumber", header: "Phone Number" },
    { field: "communityCode", header: "Community Code" },
    { field: "sales", header: "Sales" },
    { field: "companyId", header: "Company Id" },
    { field: "companyName", header: "Company" },
    { field: "subscribedDate", header: "Subscribed Date" },
    { field: "expiringDate", header: "Expiring Date" },
    { field: "cancelDate", header: "Cancelled Date" },
    { field: "subscriptionType", header: "Subscription Type" },
    { field: "subscriptionAmount", header: "Subscription Amount (Inc Vat)" },
    { field: "subscriptionStatus", header: "Subscription Status" },
    { field: "subscriptionFrom", header: "Subscription From" },
    { field: "physicalButton", header: "Physical Button" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const exportPdf = () => {
    if (salesIQTable) {
      const doc = new jsPDF("l");
      const rowInputData = salesIQTable.map((user) => ({
        accountCreated: new Date(user.accountCreated).toLocaleDateString(),
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        communityCode: user.CommunityCode,
        sales: user.sales,
        companyId: user.companyId,
        companyName: user.companyName,
        subscribedDate: new Date(user.subscribedDate).toLocaleDateString(),
        expiringDate: new Date(user.expiringDate).toLocaleDateString(),
        cancelDate:
          user.subscriptionStatus !== "subscribed" && user.cancelDate
            ? new Date(user.cancelDate).toLocaleDateString()
            : "",
        subscriptionType: user.subscriptionType,
        subscriptionAmount: user.subscriptionAmount,
        subscriptionStatus: user.subscriptionStatus,
        subscribedFrom: user.subscriptionFrom,
        physicalButton: user.physicalButton,
      }));
      autoTable(doc, { columns: exportColumns, body: rowInputData });
      doc.save("sales_IQ.pdf");
    }
  };

  const customInputTheme = {
    token: {
      colorPrimaryHover: `${Colors.appMainColor}`,
    },
  };

  const actionBodyTemplate = (rowData: ISalesIQTable) => {
    return (
      <div style={{ minWidth: "50px" }}>
        <Button
          type="text"
          icon={<EyeOutlined />}
          style={{ color: `${Colors.appMainColor}` }}
          onClick={() => {
            setSelectedUser(rowData.userId);
            setViewMoreModal(true);
          }}
        />
      </div>
    );
  };

  const companyIdBodyTemplate = (rowData: ISalesIQTable) => {
    return rowData.companyId || "-";
  };

  const companyNameBodyTemplate = (rowData: ISalesIQTable) => {
    return rowData.companyName || "-";
  };

  return (
    <div className="w-full h-full">
      <Container className="w-full h-full">
        <BodyContainer className="w-full h-full">
          <HeaderComponent title="Sales IQ" />
          <Spin tip="" spinning={isLoading || isFetching} delay={500}>
            <div className="flex flex-row justify-end w-full gap-2 px-4 mt-2 mb-2">
              <Button
                icon={<DownloadOutlined />}
                onClick={() => setOpenDownloadButtonsModal(true)}
                type="primary"
                ghost
                size="large"
                className="w-1"
              />
              <CustomInputField
                value={globalFilterValue}
                onChange={(e: string) => onGlobalFilterChangeV2(e)}
                onPressEnter={() => {}}
                theme={customInputTheme}
                inputProps={{
                  size: "large",
                  placeholder: "Search",
                  allowClear: true,
                  className: "ml-2 w-3",
                  prefix: <SearchOutlined />,
                }}
                mergeThemes
              />
            </div>
            <div className="w-full h-full px-4">
              <DataTable
                ref={dt}
                paginator
                rows={10}
                value={salesIQTable}
                rowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
                dataKey="id"
                emptyMessage="No Sales found."
                rowHover
                scrollable
                scrollHeight="40rem"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} sales"
                stateStorage="session"
                stateKey="dt-state-sales-iq-table"
                className="mx-0"
                filters={filters}
                globalFilterFields={[
                  "accountCreated",
                  "firstName",
                  "lastName",
                  "email",
                  "phoneNumber",
                  "sales",
                  "companyId",
                  "companyName",
                  "subscribedDate",
                  "communityCode",
                  "subscriptionType",
                  "subscriptionAmount",
                  "subscriptionStatus",
                  "subscriptionFrom",
                  "physicalButton",
                ]}
              >
                <Column
                  field="accountCreated"
                  header="Account Created"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB", minWidth: "15rem" }}
                  bodyStyle={tableBody}
                  filter
                  dataType="date"
                  filterField="accountCreated"
                  filterElement={dateFilterTemplate}
                  body={accountCreatedBodyTemplate}
                />
                <Column
                  field="firstName"
                  header="First Name"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB", minWidth: "10rem" }}
                  bodyStyle={tableBody}
                  filter
                />
                <Column
                  field="lastName"
                  header="Last Name"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB", minWidth: "10rem" }}
                  bodyStyle={tableBody}
                  filter
                />

                <Column
                  field="email"
                  header="Email"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                  filter
                />
                <Column
                  field="phoneNumber"
                  header="Phone Number"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB", minWidth: "15rem" }}
                  bodyStyle={tableBody}
                  filter
                />
                <Column
                  field="communityCode"
                  header="Community Code"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB", minWidth: "15rem" }}
                  bodyStyle={tableBody}
                  filter
                  showFilterMatchModes={false}
                  filterElement={referralFilterTemplate}
                />
                <Column
                  field="sales"
                  header="Sales"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB", minWidth: "10rem" }}
                  bodyStyle={tableBody}
                  filter
                  showFilterMatchModes={false}
                  filterElement={salesFilterTemplate}
                />
                <Column
                  field="companyId"
                  header="Company Id"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB", minWidth: "10rem" }}
                  bodyStyle={tableBody}
                  body={companyIdBodyTemplate}
                  filter
                />
                <Column
                  field="companyName"
                  header="Company"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB", minWidth: "10rem" }}
                  bodyStyle={tableBody}
                  body={companyNameBodyTemplate}
                  filter
                />
                <Column
                  field="subscribedDate"
                  header="Subscription Date"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                  filter
                  dataType="date"
                  filterField="subscribedDate"
                  filterElement={dateFilterTemplate}
                  body={subscriptionDateBodyTemplate}
                />
                <Column
                  field="expiringDate"
                  header="Expiring Date"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                  filter
                  dataType="date"
                  filterElement={dateFilterTemplate}
                  body={expiringDateBodyTemplate}
                />
                {/* <Column
                  field="nextBillingDate"
                  header="Next Billing Date"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                  filter
                /> */}
                <Column
                  field="cancelDate"
                  header="Cancelled Subscription"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                  filter
                  dataType="date"
                  filterElement={dateFilterTemplate}
                  body={subscriptionCancelDateBodyTemplate}
                />
                <Column
                  field="subscriptionType"
                  header="Subscription Type"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB", minWidth: "15rem" }}
                  bodyStyle={tableBody}
                  body={subTypeBodyTemplate}
                  filter
                  showFilterMatchModes={false}
                  filterElement={subscriptionTypeFilterTemplate}
                />
                <Column
                  field="subscriptionAmount"
                  header="Subscription Amount (Inc Vat)"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB", minWidth: "15rem" }}
                  bodyStyle={tableBodyRight}
                  body={amountBodyTemplate}
                  filter
                  showFilterMatchModes={false}
                  filterElement={amountFilterTemplate}
                />
                <Column
                  field="subscriptionStatus"
                  header="Subscription Status"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB", minWidth: "15rem" }}
                  bodyStyle={tableBody}
                  body={statusBodyTemplate}
                  filter
                  showFilterMatchModes={false}
                  filterElement={statusFilterTemplate}
                />
                <Column
                  field="subscriptionFrom"
                  header="Subscription From"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB", minWidth: "15rem" }}
                  bodyStyle={tableBody}
                  filter
                  showFilterMatchModes={false}
                  filterElement={systemFilterTemplate}
                />
                <Column
                  field="physicalButton"
                  header="Physical Button"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB", minWidth: "15rem" }}
                  bodyStyle={tableBody}
                  filter
                />
                <Column
                  headerStyle={tableHeader}
                  exportable={false}
                  body={actionBodyTemplate}
                  bodyStyle={tableBody}
                  style={{ background: "#FBFBFB" }}
                />
              </DataTable>
            </div>
          </Spin>

          <Modal
            centered
            open={viewMoreModal}
            onOk={() => {
              setSelectedUser(null);
              setViewMoreModal(false);
            }}
            onCancel={() => {
              setSelectedUser(null);
              setViewMoreModal(false);
            }}
            width={550}
            closeIcon={
              <CustomIcons.CloseIcon
                width={30}
                height={30}
                viewBox={null}
                svgColor="#1C274C"
              />
            }
            footer={null}
          >
            <div className="w-full pt-2 mx-2">
              <>
                <div className="flex items-center justify-start w-full gap-3 py-3">
                  <div
                    className="flex w-12 justify-content-start"
                    style={modelTitle}
                  >
                    Detailed View
                  </div>
                </div>
                <SalesIQModal userId={selectedUser} />
              </>
            </div>
          </Modal>

          <Modal
            centered
            open={openDownloadButtonsModal}
            onOk={() => setOpenDownloadButtonsModal(false)}
            onCancel={() => setOpenDownloadButtonsModal(false)}
            width={550}
            closeIcon={
              <CustomIcons.CloseIcon
                width={30}
                height={30}
                viewBox={null}
                svgColor="#1C274C"
              />
            }
            footer={null}
          >
            <div
              className="flex w-12 py-3 justify-content-start"
              style={modelTitle}
            >
              Download As
            </div>
            <Button
              className="flex justify-center w-full px-2 mb-1 text-white bg-successColor"
              onClick={() => {
                exportCSV(false);
              }}
            >
              CSV
            </Button>
            <Button
              className="flex justify-center w-full px-2 mb-1 text-white bg-errorColor"
              onClick={() => exportPdf()}
            >
              PDF
            </Button>
          </Modal>
        </BodyContainer>
      </Container>
    </div>
  );
};

export default SalesIQ;

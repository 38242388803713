import React, { ReactNode } from "react";
import { Modal } from "antd";
import { CustomIcons } from "@/assets/index";
import { modelTitle } from "@/styles/ModalStyles";

type SharedModalHeaderProps = {
  title: string;
  width: number;
  open: boolean;
  onClose: () => void;
  children: ReactNode;
};
function SharedModalHeader({
  title,
  width = 550,
  open,
  onClose,
  children,
}: SharedModalHeaderProps) {
  return (
    <Modal
      centered
      open={open}
      onCancel={onClose}
      width={width}
      closeIcon={
        <CustomIcons.CloseIcon
          width={30}
          height={30}
          viewBox={null}
          svgColor="#1C274C"
        />
      }
      footer={null}
    >
      <div className="flex w-12 py-3 justify-content-start" style={modelTitle}>
        {title}
      </div>
      {children}
    </Modal>
  );
}

export default SharedModalHeader;

import React from "react";
import { Paginator } from "primereact/paginator";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

interface CustomPaginatorProps {
  totalRecords: number;
  rows: number;
  currentPage: number;
  onPageChange: (e: {
    first: number;
    rows: number;
    page: number;
    pageCount: number;
  }) => void;
}

const CustomPaginator: React.FC<CustomPaginatorProps> = ({
  totalRecords,
  rows,
  currentPage,
  onPageChange,
}) => {
  const customTemplate = {
    layout:
      "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
    FirstPageLink: (options: any) => (
      <button
        type="button"
        className="p-paginator-first p-paginator-element p-link"
        onClick={options.onClick}
        disabled={options.disabled}
        aria-label="First Page"
      >
        <LeftOutlined
          style={{ color: options.disabled ? "#d3d3d3" : "black" }}
        />
      </button>
    ),
    PrevPageLink: (options: any) => (
      <button
        type="button"
        className="p-paginator-prev p-paginator-element p-link"
        onClick={options.onClick}
        disabled={options.disabled}
        aria-label="Previous Page"
      >
        <LeftOutlined
          style={{ color: options.disabled ? "#d3d3d3" : "black" }}
        />
      </button>
    ),
    NextPageLink: (options: any) => (
      <button
        type="button"
        className="p-paginator-next p-paginator-element p-link bg-saferCity-primary"
        onClick={options.onClick}
        disabled={options.disabled}
        aria-label="Next Page"
      >
        <RightOutlined
          style={{ color: options.disabled ? "#d3d3d3" : "black" }}
        />
      </button>
    ),
    LastPageLink: (options: any) => (
      <button
        type="button"
        className="p-paginator-last p-paginator-element p-link"
        onClick={options.onClick}
        disabled={options.disabled}
        aria-label="Last Page"
      >
        <RightOutlined
          style={{ color: options.disabled ? "#d3d3d3" : "black" }}
        />
      </button>
    ),
    CurrentPageReport: (options: any) => (
      <span className="p-paginator-current" aria-live="polite">
        Showing {options.first} to {options.last} of {options.totalRecords}{" "}
        Users
      </span>
    ),
  };

  return (
    <Paginator
      first={currentPage * rows}
      rows={rows}
      totalRecords={totalRecords}
      onPageChange={onPageChange}
      template={customTemplate}
    />
  );
};

export default CustomPaginator;

/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-vars */
import { Button, Form, Input, message, InputNumber } from "antd";

import React, { useEffect, useRef, useState } from "react";
import { copyObjects } from "../../util/copyObjects";
import Colors from "../../config/Colors";
import {
  useGetOperatorMaxConCurrentCases,
  useUpdateOperatorMaxConcurrentCases,
} from "../../hooks/useOperators";
import { CustomIcons } from "../../assets";

type OperatorMaxCasesFormProp = {
  userId: string | number | null;
  onModelClose: () => void;
};

const OperatorMaxCasesForm = ({
  userId,
  onModelClose,
}: OperatorMaxCasesFormProp) => {
  const [form] = Form.useForm();
  const maxConcurrentCasesData = useGetOperatorMaxConCurrentCases(userId);
  const putOperatorMaxConcurrentCases = useUpdateOperatorMaxConcurrentCases();
  const onFinish = async (formValues: any) => {
    const values: string = formValues.maxConcurrentCases;

    if (userId && values) {
      const hide = message.loading("updating settings...");
      try {
        await putOperatorMaxConcurrentCases.mutateAsync({
          userId,
          maxCases: values,
        });
        maxConcurrentCasesData.refetch();
        message.success("Setting has been updated successfully");
      } catch (error) {
        console.log(error);
      }
      hide();
    }
  };

  // New reset function
  const resetFormAndState = () => {
    form.resetFields();
  };

  const handleClose = () => {
    resetFormAndState();
    onModelClose();
  };

  const onFinishFailed = (errorInfo: any) => {
    message.error("Could not update max concurrent cases, please try again.");
  };

  useEffect(() => {
    if (maxConcurrentCasesData.data) {
      console.log(
        "The data we get from endpoint",
        maxConcurrentCasesData.data.maxCases
      );
      form.setFieldsValue({
        maxConcurrentCases: maxConcurrentCasesData.data.maxCases,
      });
    } else {
      resetFormAndState();
    }
  }, [maxConcurrentCasesData.data, form]);

  useEffect(() => {
    if (userId) {
      maxConcurrentCasesData.refetch();
    }
  }, [userId]);

  return (
    <>
      {maxConcurrentCasesData.isLoading ? (
        <div className="w-full">
          <CustomIcons.EOSThreeDotsLoading
            width={30}
            height={30}
            viewBox={null}
            svgColor="#1C274C"
          />
        </div>
      ) : (
        <Form
          form={form}
          name="operatorMaxConcurrentCases"
          onFinish={onFinish}
          layout="vertical"
          style={{ width: "100%" }}
          className="grid"
          initialValues={{
            maxConcurrentCases: maxConcurrentCasesData.data?.maxCases,
          }}
        >
          <div className="col-12">
            <Form.Item
              name="maxConcurrentCases"
              label="Max concurrent cases"
              rules={[
                { required: true, message: "Please enter value!" },
                {
                  validator: async (_, value) => {
                    if (Number.isNaN(value)) {
                      return Promise.reject(
                        new Error("The input must be a number")
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              className="mb-1"
            >
              <InputNumber
                placeholder="Enter number here"
                size="large"
                className="w-12"
                min={1}
                max={50}
              />
            </Form.Item>
          </div>

          <Form.Item className="w-12 pt-2 mb-1">
            <div className="flex w-full gap-2 col-12 justify-content-end align-content-center">
              <Button
                type="primary"
                ghost
                onClick={handleClose}
                size="large"
                className="w-3"
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={putOperatorMaxConcurrentCases.isLoading}
                size="large"
                className="w-3 bg-[#1757a2] shadow-none"
              >
                Save
              </Button>
            </div>
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default OperatorMaxCasesForm;

import { createQueryKeys } from "@lukemorales/query-key-factory";
import communityMessage from "../CommunityMessages";

export const communityMessages = createQueryKeys("communityMessage", {
  GetCommunityCaseMessages: (
    communityCaseId: number | string | null | undefined
  ) => ({
    queryKey: [{ communityCaseId }],
    queryFn: () => communityMessage.getCommunityCaseMessage(communityCaseId),
  }),
});

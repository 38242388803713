/* eslint-disable comma-dangle */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-alert */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable  implicit-arrow-linebreak */
import React, { useEffect, useInsertionEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { message } from "antd";
import { Container, InformationContainer, BodyContainer } from "./styles";
import { CustomIcons } from "../../assets/index";
import SideMenu from "../../components/Menu/Menu";
import HeaderComponent from "../../components/Header/Header";
import Colors from "../../config/Colors";
import { DashboardCard } from "../../components/Cards/DashboardCard";
import DashboardChart from "../../components/Charts/Dashboard/DashboardChart";
import { DashboardDoughnut } from "../../components/Charts/Dashboard/DashboardDoughnut";
import { useMainDashboard } from "../../hooks/useDashboard";

const Home = observer(() => {
  const { isLoading, data: DashboardData, isFetching } = useMainDashboard();
  interface MetricComponentProps {
    metric: IDashboardMetrics;
  }

  function MetricComponent(metric: MetricComponentProps) {
    return (
      <>
        {Object.entries(metric.metric).map(([key, value]) => (
          <div key={key}>
            <DashboardCard
              title={key}
              number={value.count}
              percentage={value.percentage}
              trend={value.percentage >= 0}
            />
          </div>
        ))}
      </>
    );
  }

  return (
    <div className="w-full h-full">
      <Container className="w-full h-full">
        <BodyContainer className="w-full disable-scrollbars">
          <HeaderComponent title="Home" />
          {isLoading ? (
            <div className="flex flex-column justify-content-center align-items-center h-full">
              <CustomIcons.EOSThreeDotsLoading
                width={80}
                height={80}
                viewBox={null}
                svgColor={Colors.appMainColor}
              />
            </div>
          ) : (
            <InformationContainer>
              <div className="w-12 h-12 py-1">
                {DashboardData !== null && DashboardData !== undefined && (
                  <>
                    <div className="py-1">
                      <div className="flex flex-row gap-3 overflow-x-scroll disable-scrollbars py-3 px-2">
                        <MetricComponent metric={DashboardData?.metric} />
                      </div>
                    </div>
                    <div className="grid px-2 animate-fadeInUp animate-faster">
                      <div className="col-12 md:col-6 animate-bounce animate-repeat-2">
                        <div className="w-12 h-full">
                          <DashboardChart users={DashboardData?.users} />
                        </div>
                      </div>
                      <div className="col-12 md:col-6 animate-bounce animate-repeat-2">
                        <div className="w-12">
                          <DashboardDoughnut
                            packages={DashboardData?.subscriptionType}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </InformationContainer>
          )}
        </BodyContainer>
      </Container>
    </div>
  );
});

export default Home;

/* eslint-disable default-case */
import { observer } from "mobx-react-lite";
import React, { useState, useEffect, useRef } from "react";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import { Button, Modal, Tag } from "antd";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { Calendar, CalendarChangeEvent } from "primereact/calendar";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import debounce from "lodash/debounce";
import moment from "moment";
import { toast } from "sonner";
import { useHistory } from "react-router-dom";
import {
  BodyContainer,
  Container,
  tableBody,
  tableHeader,
  tableName,
  modelTitle,
  successTitle,
  successSubtitle,
} from "./styles";
import HeaderComponent from "../../components/Header/Header";
import Spin from "../../components/widgets/Spinner/Spin";
import { toIUser, toUser } from "../../util/copyObjects";
import Colors from "../../config/Colors";
import CustomInputField from "../../components/widgets/Inputs/CustomInputField";
import { CustomIcons } from "../../assets";
import "./Users.css";
import NewUserForm from "../../components/Forms/NewUser";
import EditUserForm from "../../components/Forms/EditUser";
import {
  useAddUser,
  useEditUser,
  useGetFilteredUsers,
  useUsers,
} from "../../hooks/useUsers";
import { Users as UserService } from "../../services";
import UploadDocument from "../../components/Forms/UploadDocument";
import { apiUrl } from "../../config/urls";
import { ActionBodyText } from "../../styles/ContainerStyles";
import AddNewUser from "../../components/Forms/AddNewUser";

const Users = observer(() => {
  // @ts-ignore
  const dt = useRef<DataTable>(null);
  const [isExporting, setIsExporting] = useState(false);
  //const { isLoading, data, refetch, isFetching } = useUsers();
  const addUser = useAddUser();
  const editUser = useEditUser();
  const [users, setUsers] = useState<IUser[]>([]);
  const [currentUser, setCurrentUser] = useState<IUser | null>(null);
  const [openAddUserModal, setOpenAddUserModal] = useState(false);
  const [openEditUserModal, setOpenEditUserModal] = useState(false);
  const [openUploadDocumentModal, setOpenUploadDocumentModal] = useState(false);
  const [filters, setFilters] = useState<DataTableFilterMeta | undefined>(
    undefined
  );

  const [globalFilterSearch, setGlobalFilterSearch] = useState<string>("");
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  // Debounce function
  const debounceSetGlobalFilterValue = debounce((value) => {
    setGlobalFilterValue(value);
  }, 500); // 500ms delay
  const [statuses] = useState<string[]>([
    "Subscribed",
    "Unsubscribed",
    "Non-Renewing",
  ]);
  const [turnoverStatus] = useState<string[]>(["Logged In", "Pending"]);
  const [from] = useState<string[]>(["Paystack", "InApp", "Portal"]);
  const [openDownloadButtonsModal, setOpenDownloadButtonsModal] =
    useState(false);

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [filtersState, setFiltersState] = useState<Filters>({
    status: { values: [""], matchModes: ["contains"] },
    subscriptionFrom: { values: [""], matchModes: ["contains"] },
    global: { values: [""], matchModes: ["contains"] },
    createdAt: { values: [""], matchModes: ["dateis"] },
    auraId: { values: [""], matchModes: ["contains"] },
    companyId: { values: [""], matchModes: ["contains"] },
    companyName: { values: [""], matchModes: ["contains"] },
    username: { values: [""], matchModes: ["contains"] },
  });

  // Fetch users with pagination and filters
  const {
    isLoading: isLoadingUsers,
    isFetching: isFetchingUsers,
    data: filteredUsers,
    refetch: refetchFilteredUsers,
  } = useGetFilteredUsers(first, rows, filtersState);

  // Handling DataTable's onPage event
  const onPage = (event: any) => {
    console.log(event);
    setFirst(event.first);
    setRows(event.rows);
  };

  const getTurnoverStatus = (status: string) => {
    switch (status) {
      case "Logged In":
        return "success";
      case "Pending":
        return "warning";
      default:
        return "success";
    }
  };

  // Handling DataTable's onFilter event
  const onFilter = (event: { filters: ITableFilters }) => {
    console.log(event);

    const newFilters: { [key: string]: IFilterParam } = {};
    Object.keys(event.filters).forEach((key) => {
      const filter: ITableFilter = event.filters[key];

      // Check if the filter has 'constraints' (for complex filters)
      if (filter.constraints) {
        newFilters[key] = {
          values: filter.constraints.map(
            (constraint) => constraint.value || ""
          ),
          matchModes: filter.constraints.map(
            (constraint) => constraint.matchMode
          ),
        };
      } else {
        // Simple filter
        newFilters[key] = {
          values: [filter.value || ""],
          matchModes: [filter.matchMode || ""],
        };
      }
    });

    setFiltersState(newFilters);
    setFirst(0); // Reset to the first page
  };

  // useEffect(() => {
  //   refetchFilteredUsers(); // Refetch users when filters or pagination change
  // }, [first, rows, filtersState]);

  useEffect(() => {
    const currentFilers = { ...filtersState };
    if (globalFilterValue) {
      currentFilers.username = {
        values: [globalFilterValue],
        matchModes: ["contains"],
      };
    } else {
      delete currentFilers.username;
    }

    setFiltersState(currentFilers);
    //refetchFilteredUsers();
  }, [globalFilterValue]);

  const navigate = useHistory();

  const navigateTo = (id: number | string) => {
    navigate.push(`/home/user-profile/${id}`);
  };

  const getSeverity = (status: string) => {
    switch (status) {
      case "Subscribed":
        return "success";
      case "Unsubscribed":
        return "error";
      case "Non-Renewing":
        return "warning";
      case "Referral code":
        return "warning";
      default:
        return "processing";
    }
  };

  const exportPdf = async () => {
    toast.promise(UserService.downloadPdfUsers(filtersState), {
      loading: "Exporting...",
      success: () => {
        return "Exported successfully";
      },
      error: "Error exporting users table, please try again later.",
    });
  };

  const exportCSV = async (selectionOnly: boolean) => {
    toast.promise(UserService.downloadExcelUsers(filtersState), {
      loading: "Exporting...",
      success: () => {
        return "Exported successfully";
      },
      error: "Error exporting users table, please try again later.",
    });
  };

  const onGlobalFilterChangeV2 = (e: string) => {
    const _filters = { ...filters };
    // @ts-ignore
    // _filters.global.value = e;

    // setFilters(_filters);
    // Use the debounce function
    debounceSetGlobalFilterValue(e);
    setGlobalFilterSearch(e);
    setFirst(0);
  };

  const initFilters = () => {
    setFilters({
      // global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      date: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      status: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      companyId: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      subscriptionFrom: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      subscriptionPlan: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      turnover: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
    });
    setGlobalFilterValue("");
  };

  const clearFilter = () => {
    initFilters();
  };

  const dateBodyTemplate = (rowData: IGetUsers) => {
    // return formatDate(rowData.date);
    return moment(rowData.createdAt).format("YYYY/MM/DD");
  };

  const auraIdBodyTemplate = (rowData: IGetUsers) => {
    return rowData.auraId || "-";
  };

  const suburbBodyTemplate = (rowData: IGetUsers) => {
    return rowData.suburb || "-";
  };

  const communityCodeBodyTemplate = (rowData: IGetUsers) => {
    if ((rowData.communityCode?.length ?? 0) < 1) return "-";

    return rowData.communityCode;
  };

  const referralCodeBodyTemplate = (rowData: IGetUsers) => {
    if ((rowData.referralCode?.length ?? 0) < 1) return "-";

    return rowData.referralCode;
  };

  const companyIdBodyTemplate = (rowData: IGetUsers) => {
    return rowData.companyId || "-";
  };

  const companyNameBodyTemplate = (rowData: IGetUsers) => {
    return rowData.company || "-";
  };

  const turnoverBodyTemplate = (rowData: IUser) => {
    return (
      <Tag color={getTurnoverStatus(rowData.turnover)}>{rowData.turnover}</Tag>
    );
  };

  const turnoverItemTemplate = (option: string) => {
    return <Tag color={getTurnoverStatus(option)}>{option}</Tag>;
  };

  const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e: CalendarChangeEvent) => {
          options.filterCallback(e.value, options.index);
        }}
        dateFormat="yy/mm/dd"
        placeholder="yyyy/mm/dd"
        mask="9999/99/99"
      />
    );
  };

  const statusItemTemplate = (option: string) => {
    return <Tag color={getSeverity(option)}>{option}</Tag>;
  };

  const statusBodyTemplate = (rowData: IUser) => {
    return <Tag color={getSeverity(rowData.status)}>{rowData.status}</Tag>;
  };

  const statusFilterTemplate = (
    options: ColumnFilterElementTemplateOptions
  ) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e: DropdownChangeEvent) =>
          options.filterCallback(e.value, options.index)
        }
        itemTemplate={statusItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
      />
    );
  };

  const subFromItemTemplate = (option: string) => {
    return <Tag>{option}</Tag>;
  };
  const subFromBodyItemTemplate = (option: string) => {
    return <Tag>{option}</Tag>;
  };

  const subFromFilterTemplate = (
    options: ColumnFilterElementTemplateOptions
  ) => {
    return (
      <Dropdown
        value={options.value}
        options={from}
        onChange={(e: DropdownChangeEvent) =>
          options.filterCallback(e.value, options.index)
        }
        itemTemplate={subFromItemTemplate}
        placeholder="Select"
        className="p-column-filter"
        showClear
      />
    );
  };

  const turnoverFilterTemplate = (
    options: ColumnFilterElementTemplateOptions
  ) => {
    return (
      <Dropdown
        value={options.value}
        options={turnoverStatus}
        onChange={(e: DropdownChangeEvent) =>
          options.filterCallback(e.value, options.index)
        }
        itemTemplate={turnoverItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
      />
    );
  };

  const actionBodyTemplate = (rowData: IUser) => {
    return (
      <div style={{ minWidth: "50px" }}>
        <Button
          type="text"
          icon={
            <CustomIcons.PencilIcon
              width={20}
              height={20}
              viewBox={null}
              svgColor={Colors.appMainColor}
            />
          }
          style={{ color: `${Colors.appMainColor}` }}
          onClick={() => {
            // setCurrentUser(rowData);
            // setOpenEditUserModal(true);
            navigateTo(rowData.id);
          }}
        />
      </div>
    );
  };

  const customInputTheme = {
    token: {
      colorPrimaryHover: `${Colors.appMainColor}`,
    },
  };

  const getUsers = (userData: IUser[]) => {
    if (Array.isArray(userData)) {
      return [...(userData || [])].map((d) => {
        const userInstance = toUser(d);
        return toIUser(userInstance);
      });
    }
    return [];
  };
  useEffect(() => {
    initFilters();
  }, []);

  const handleAddUser = async (user: IAddNewUser) => {
    try {
      await addUser.mutateAsync(user);
      await refetchFilteredUsers();
      //if (data) setUsers(getUsers(data));
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = async () => {
    setOpenAddUserModal(false);
    addUser.reset();
  };

  const viewMoreBodyTemplate = (rowData: IUser) => {
    const userid = rowData.id;

    return (
      <ActionBodyText onClick={() => navigateTo(userid)}>
        View More
      </ActionBodyText>
    );
  };
  return (
    <div className="w-full h-full">
      <Container className="w-full h-full">
        <BodyContainer className="w-full">
          <HeaderComponent title="Users" />
          <Spin tip="" spinning={isLoadingUsers || isFetchingUsers} delay={500}>
            <div className="w-full h-full px-3 card Users">
              <div className="flex flex-row justify-between w-full gap-1 mb-2 align-items-center">
                <div
                  className="flex flex-row align-items-center"
                  style={tableName}
                >
                  Manage users
                </div>
                <div className="flex flex-row gap-1 justify-content-center align-items-center">
                  <Button
                    type="primary"
                    ghost
                    onClick={() => setOpenAddUserModal(true)}
                    size="large"
                  >
                    +Add new user
                  </Button>
                  <Button
                    icon={<DownloadOutlined />}
                    onClick={() => setOpenDownloadButtonsModal(true)}
                    loading={isExporting}
                    type="primary"
                    ghost
                    size="large"
                    className="w-5"
                  />
                  <CustomInputField
                    value={globalFilterSearch}
                    onChange={(e: string) => onGlobalFilterChangeV2(e)}
                    onPressEnter={() => {}}
                    inputProps={{
                      size: "large",
                      placeholder: "Search",
                      allowClear: true,
                      className: "ml-2 w-11",
                      prefix: <SearchOutlined />,
                    }}
                    theme={customInputTheme}
                    mergeThemes
                  />
                </div>
              </div>
              <DataTable
                ref={dt}
                first={first}
                value={filteredUsers?.users || []}
                totalRecords={filteredUsers?.totalRecords || 0}
                paginator
                rows={rows}
                rowsPerPageOptions={[10, 25, 50, 100]}
                dataKey="id"
                filters={filters}
                lazy
                onPage={onPage}
                onFilter={onFilter}
                emptyMessage="No users found."
                rowHover
                scrollable
                scrollHeight="40rem"
                size="large"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Users"
                stateStorage="session"
                stateKey="dt-state-users-table"
              >
                <Column
                  field="createdAt"
                  header="CreatedAt"
                  headerStyle={tableHeader}
                  filterField="date"
                  dataType="date"
                  body={dateBodyTemplate}
                  bodyStyle={tableBody}
                  filter
                  filterElement={dateFilterTemplate}
                  style={{ background: "#FBFBFB", minWidth: "12rem" }}
                />
                <Column
                  field="username"
                  header="Username"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                  filterPlaceholder="Search by name"
                />
                <Column
                  field="email"
                  header="Email"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                  filterPlaceholder="search by email"
                />
                <Column
                  field="phoneNumber"
                  header="Phone Number"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                  filterPlaceholder="search by phone number"
                />
                <Column
                  field="auraId"
                  header="Aura Id"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB", minWidth: "12rem" }}
                  bodyStyle={tableBody}
                  filterPlaceholder="search by aura id"
                  body={auraIdBodyTemplate}
                />
                <Column
                  field="auraCustomer.suburb"
                  header="Suburb"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                  filterPlaceholder="search by suburb"
                  body={suburbBodyTemplate}
                />
                <Column
                  field="communityCode"
                  header="Community Code"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                  body={communityCodeBodyTemplate}
                />

                <Column
                  field="referralCode"
                  header="Referral Code"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                  body={referralCodeBodyTemplate}
                />
                <Column
                  field="subscriptionPlan"
                  header="Subscription Plan"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB", minWidth: "14rem" }}
                  bodyStyle={tableBody}
                  filter
                  filterPlaceholder="Search by plan"
                  filterMatchModeOptions={[
                    { label: "Equals", value: "equals" },
                    { label: "Not Equals", value: "not equals" },
                  ]}
                />
                <Column
                  field="status"
                  header="Status"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  body={statusBodyTemplate}
                  filter
                  filterElement={statusFilterTemplate}
                  filterMenuStyle={{ width: "16rem" }}
                  filterMatchModeOptions={[
                    { label: "Equals", value: "equals" },
                    { label: "Not Equals", value: "not equals" },
                  ]}
                />

                <Column
                  field="subscriptionFrom"
                  header="Subscription From"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB", minWidth: "15rem" }}
                  bodyStyle={tableBody}
                  filter
                  filterElement={subFromFilterTemplate}
                  filterMenuStyle={{ width: "11rem" }}
                  filterMatchModeOptions={[
                    { label: "Equals", value: "equals" },
                    { label: "Not Equals", value: "not equals" },
                  ]}
                />
                <Column
                  field="companyName"
                  header="Company"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  body={companyNameBodyTemplate}
                />
                <Column
                  field="companyId"
                  header="Company ID"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB", minWidth: "11rem" }}
                  body={companyIdBodyTemplate}
                  filter
                  dataType="numeric"
                  filterMatchModeOptions={[
                    { label: "Equals", value: "equals" },
                    { label: "Not Equals", value: "not equals" },
                  ]}
                />
                <Column
                  field="turnover"
                  header="Turnover"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                  body={turnoverBodyTemplate}
                  filter
                  filterElement={turnoverFilterTemplate}
                />
                <Column
                  headerStyle={tableHeader}
                  exportable={false}
                  body={actionBodyTemplate}
                  bodyStyle={tableBody}
                  style={{ background: "#FBFBFB" }}
                />
              </DataTable>
            </div>
          </Spin>
        </BodyContainer>
        <Modal
          centered
          open={openAddUserModal}
          onOk={() => setOpenAddUserModal(false)}
          onCancel={() => setOpenAddUserModal(false)}
          width={550}
          closeIcon={
            <CustomIcons.CloseIcon
              width={30}
              height={30}
              viewBox={null}
              svgColor="#1C274C"
            />
          }
          footer={null}
        >
          <div className="w-full pt-2 mx-2">
            {addUser.isSuccess ? (
              <div className="flex w-full h-full gap-2 flex-column align-items-center justify-content-center">
                <div className="flex w-full justify-content-center">
                  <CustomIcons.SuccessIcon
                    width={100}
                    height={100}
                    viewBox={null}
                    svgColor="#179717"
                  />
                </div>
                <div style={successTitle}> Successful</div>
                <div style={successSubtitle} className="pb-4">
                  {" "}
                  You have successfully added a new user
                </div>
                <Button
                  type="primary"
                  ghost
                  onClick={handleClose}
                  size="large"
                  className="w-4"
                >
                  close
                </Button>
              </div>
            ) : (
              <>
                <div className="flex items-center justify-start w-full gap-3 py-3">
                  <div
                    className="flex w-12 justify-content-start"
                    style={modelTitle}
                  >
                    +Add new user
                  </div>
                  <div className="pt-3">
                    <Button
                      type="primary"
                      ghost
                      size="large"
                      onClick={() => {
                        setOpenUploadDocumentModal(true);
                        setOpenAddUserModal(false);
                      }}
                    >
                      Upload Excel Document
                    </Button>
                  </div>
                </div>

                <AddNewUser
                  onFormFinish={async (user: IAddNewUser) =>
                    await handleAddUser(user)
                  }
                  onModelClose={() => setOpenAddUserModal(false)}
                  isLoading={addUser.isLoading}
                  isSuccess={addUser.isSuccess}
                />
              </>
            )}
          </div>
        </Modal>

        <Modal
          centered
          open={openUploadDocumentModal}
          onOk={() => setOpenUploadDocumentModal(false)}
          onCancel={() => setOpenUploadDocumentModal(false)}
          footer={null}
        >
          <div
            className="flex w-12 py-3 justify-content-center"
            style={modelTitle}
          >
            Upload your files
          </div>
          <div className="flex mb-4 ant-upload-text justify-content-center">
            File should be Excel
          </div>
          <UploadDocument
            actionURL={`${apiUrl}/Admin_UserAuth/uploadExcel`}
            onComplete={(done: boolean) => {
              if (done) {
                setOpenUploadDocumentModal(false);
              }
            }}
          />
        </Modal>
        <Modal
          centered
          open={openDownloadButtonsModal}
          onOk={() => setOpenDownloadButtonsModal(false)}
          onCancel={() => setOpenDownloadButtonsModal(false)}
          width={550}
          closeIcon={
            <CustomIcons.CloseIcon
              width={30}
              height={30}
              viewBox={null}
              svgColor="#1C274C"
            />
          }
          footer={null}
        >
          <div
            className="flex w-12 py-3 justify-content-start"
            style={modelTitle}
          >
            Download As
          </div>
          <Button
            className="flex justify-center w-full px-2 mb-1 text-white bg-successColor"
            onClick={async () => {
              exportCSV(false);
            }}
          >
            CSV
          </Button>
          <Button
            className="flex justify-center w-full px-2 mb-1 text-white bg-errorColor"
            onClick={() => exportPdf()}
          >
            PDF
          </Button>
        </Modal>
      </Container>
    </div>
  );
});

export default Users;

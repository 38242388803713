/* eslint-disable no-underscore-dangle */
import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { queries } from "../services/queries";
import { DirectMessage } from "../services";

export function useDirectMessage() {
  return useQuery({
    ...queries.directMessage.all,
    queryFn: () => DirectMessage.getAll(),
    staleTime: 60 * 1000, // 1 Min
  });
}

export function useDirectMessageByCase(id: string | number | null) {
  return useQuery({
    ...queries.directMessage.conversationByCase(id || 0),
    enabled: !!id && Number(id) > 0,
  });
}

export function useDirectMessageByIds(
  userId: string | number | null,
  receiverId: string | number | null,
  enabled: boolean
) {
  return useQuery({
    ...queries.directMessage.conversationByIds(userId || 0, receiverId || 0),
    enabled:
      enabled &&
      !!userId &&
      !!receiverId &&
      Number(receiverId) > 0 &&
      Number(userId) > 0,
  });
}

export function usePostDirectMessage() {
  const queryClient = useQueryClient();

  return useMutation(DirectMessage.postMessage, {
    onMutate: async (dm) => {
      // get previous caseMessage data
      const previousDirectMessageCaseData = queryClient.getQueryData(
        queries.directMessage.conversationByCase(dm.emergencyCaseId).queryKey
      );
      // get previous IDs
      const previousDirectMessageIdsData = queryClient.getQueryData(
        queries.directMessage.conversationByIds(dm.receiverId, dm.senderId)
          .queryKey
      );

      // optimistic update
      queryClient.setQueryData(
        queries.directMessage.conversationByCase(dm.emergencyCaseId).queryKey,
        (oldDirectMessagesData: IDirectMessage[] = []) => {
          return [...oldDirectMessagesData, dm];
        }
      );

      queryClient.setQueryData(
        queries.directMessage.conversationByIds(dm.senderId, dm.receiverId)
          .queryKey,
        (oldDirectMessagesData: IDirectMessage[] = []) => {
          return [...oldDirectMessagesData, dm];
        }
      );

      return { previousDirectMessageCaseData, previousDirectMessageIdsData };
    },
    onError: (err: AxiosError, dm, context) => {
      // rollback to the previous value if mutation fails
      if (context) {
        queryClient.setQueryData(
          queries.directMessage.conversationByCase(dm.emergencyCaseId).queryKey,
          context.previousDirectMessageCaseData &&
            context.previousDirectMessageIdsData
        );
      }
    },
    onSuccess: (dm) => {
      queryClient.invalidateQueries({
        queryKey: queries.directMessage.conversationByCase(
          dm.data.emergencyCaseId
        ).queryKey,
      });
      queryClient.invalidateQueries({
        queryKey: queries.directMessage.conversationByIds(
          dm.data.receiverId,
          dm.data.senderId
        ).queryKey,
      });

      // Invalidate Message Panel Here
      queryClient.invalidateQueries({
        queryKey: queries.operators.emergencyCases(dm.data.senderId).queryKey,
      });
    },
    meta: {
      conflict: "Found whilst creating posting message, please try again.",
      badRequest: "Please make sure all fields are entered correctly.",
    },
  });
}

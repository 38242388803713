/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { DeleteTwoTone } from "@ant-design/icons";

interface DeleteButtonProps {
  onPress: () => void;
}

const DeleteButton: React.FC<DeleteButtonProps> = ({ onPress }) => {
  return (
    <div
      className="ml-2 cursor-pointer flex justify-center items-center w-10 h-10 rounded-full hover:ring-2 hover:ring-red-500"
      onClick={onPress}
    >
      <DeleteTwoTone />
    </div>
  );
};

export default DeleteButton;

/* eslint-disable lines-between-class-members */
/* eslint-disable no-var */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
import { API, APILocal, APIStaging } from "../config/axios";

class Operators {
  addOperator = async (body: IAddPractitioner) => {
    return API.post("operator", body);
  };

  getById = async (id: string | number): Promise<IGeneralCommunity> => {
    const response = await API.get<IGeneralCommunity>(`UserAuth/${id}`);
    return response.data;
  };

  getAll = async (): Promise<IGeneralCommunity[]> => {
    const url = `Emergency`;
    const response = await API.get<IGeneralCommunity[]>(url);
    return response.data;
  };

  getOperatorsList = async (
    filters: Filters | null,
    page: number | string | null | any = null
  ): Promise<IGeneralCommunity[]> => {
    const url = `Emergency`;
    const params: { [key: string]: any } = {};

    if (filters) {
      params.filters = filters;
    }

    if (page) {
      params.page = page;
    }
    const response = await API.get<IGeneralCommunity[]>(url, { params });
    return response.data;
  };

  getSearchOperators = async (
    filters: Filters | null,
    page: number | string | null | any = null,
    limit: number | null = null,
    search: string | null
  ): Promise<IGeneralCommunity[]> => {
    const url = `Emergency`;
    const params: { [key: string]: any } = {};

    if (filters) {
      params.filters = filters;
    }
    if (search) {
      params.search = search;
    }
    if (page) {
      params.page = page;
    }

    if (limit) {
      params.limit = limit;
    }
    const response = await API.get<IGeneralCommunity[]>(url, { params });
    return response.data;
  };

  getAllByEmergency = async (
    emergencyId: string | number | null
  ): Promise<IGeneralCommunity[]> => {
    if (emergencyId) {
      const url = `Emergency/operators/${emergencyId}`;
      const response = await API.get<IGeneralCommunity[]>(url);
      return response.data;
    }
    return Promise.reject<IGeneralCommunity[]>("emergencyId is not provided");
  };

  getAllEmergencyOperators = async (): Promise<IGeneralCommunity[]> => {
    const url = `Admin_Operator/getAllOperators`;
    const response = await API.get<IGeneralCommunity[]>(url);
    return response.data;
  };

  getOperatorsByEmergencyList = async (
    emergencyId: string | number | null,
    filters: Filters | null,
    page?: number | string | null | any
  ): Promise<IGeneralCommunity[]> => {
    if (emergencyId) {
      const url = `Emergency/operators/${emergencyId}`;
      const params: { [key: string]: any } = {};

      if (filters) {
        params.filters = filters;
      }

      if (page) {
        params.page = page;
      }
      const response = await API.get<IGeneralCommunity[]>(url, { params });
      return response.data;
    }

    return Promise.reject<IGeneralCommunity[]>("emergencyId is not provided");
  };

  getByEmergencySearchOperators = async (
    emergencyId: string | number | null,
    search?: string | null,
    filters?: Filters | null,
    page?: number | string | null | any,
    limit?: number | null
  ): Promise<IGeneralCommunity[]> => {
    if (emergencyId) {
      const url = `Emergency/operators/${emergencyId}`;
      const params: { [key: string]: any } = {};

      if (filters) {
        params.filters = filters;
      }
      if (search) {
        params.search = search;
      }
      if (page) {
        params.page = page;
      }

      if (limit) {
        params.limit = limit;
      }
      const response = await API.get<IGeneralCommunity[]>(url, { params });
      return response.data;
    }
    return Promise.reject<IGeneralCommunity[]>("emergencyId is not provided");
  };

  updateOperator = async (
    body: IGeneralCommunity
  ): Promise<IGeneralCommunity> => {
    return API.post(`operator/change/${body.userId}`, body.user);
  };

  async getEmergencyCases(
    userId: string | number | null
  ): Promise<IOperatorEmergencyCase[]> {
    if (userId) {
      const response = await API.get(`operator/cases/${userId}`);
      return response.data;
    }
    return Promise.reject<IOperatorEmergencyCase[]>("userId is not provided");
  }

  resolveCase = async (caseId: string | number): Promise<IEmergencyCase> => {
    return API.get(`operator/ResolveCase?id=${caseId}`);
  };

  async getOperatorsHistory(
    operatorId: string | number | null | undefined
  ): Promise<IOperatorHistory[]> {
    if (operatorId) {
      const response = await API.get(
        `Admin_operator/OperatorHistoryData?operatorId=${operatorId}`
      );
      return response.data;
    }
    return Promise.reject<IOperatorHistory[]>("operatorId is not provided");
  }

  transferCase = async (caseId: string | number): Promise<ITransferCase> => {
    return API.post(`operator/TransferCase?id=${caseId}`);
  };

  async getOperatorAnalytics(
    emergencyId: number | null
  ): Promise<IOperatorAnalytics> {
    let params = "";
    if (emergencyId) {
      params = `?EmergencyId=${emergencyId}`;
    }
    const response = await API.get(`Admin_Operator/getAnalytics${params}`);
    return response.data;
  }

  async getSelectedOperatorAnalytics(
    operatorId: string | undefined,
    startDate: Date | string | undefined | null,
    endDate: Date | string | undefined | null
  ): Promise<ISelectedOperatorAnalytics> {
    let params = "";
    if (operatorId) {
      params = `?operatorId=${operatorId}`;
    }
    if (startDate && endDate) {
      params += `&startDate=${startDate}&endDate=${endDate}`;
    }
    const response = await API.get(
      `Admin_Operator/OperatorAnalyticsData${params}`
    );
    return response.data;
  }

  async getEmergencyCaseOperatorsNote(
    emergencyCaseId: string | number | null
  ): Promise<string> {
    if (emergencyCaseId) {
      const response = await API.get(
        `Operator/getEmergencyCaseOperatorNote?emergencyCaseId=${emergencyCaseId}`
      );
      return response.data;
    }
    return Promise.reject<string>("emergency case id is not provided");
  }

  postOperatorNote = async (
    operatorNote: IOperatorNote
  ): Promise<IEmergencyCase> => {
    return API.post(`operator/AddOperatorNote`, operatorNote);
  };

  pauseEmergencyCase = async (
    caseId: string | number
  ): Promise<IEmergencyCase> => {
    return API.post(`operator/PauseCase?id=${caseId}`);
  };

  resumeEmergencyCase = async (
    caseId: string | number
  ): Promise<IEmergencyCase> => {
    return API.post(`operator/ResumeCase?id=${caseId}`);
  };

  updateOperatorMaxConcurrentCases = async (
    body: IOperatorMaxConcurrentCases
  ): Promise<IEmergencyPractitioner> => {
    return API.put(
      `operator/UpdateMaxConcurrentCases?userId=${body.userId}&maxConcurrentCases=${body.maxCases}`
    );
  };

  getOperatorMaxConcurrentCases = async (
    userId: number | string | null
  ): Promise<IOperatorMaxConcurrentCasesValue> => {
    const response = await API.get(
      `operator/GetMaxConcurrentCases?userId=${userId}`
    );
    return response.data;
  };

  getTransferableOperators = async (
    emergencyId: number | string | null,
    operatorId: number | string | null
  ): Promise<ITransferableOperators[]> => {
    const response = await API.get(
      `Admin_operator/TransferableOperators?emergencyId=${emergencyId}&operatorId=${operatorId}`
    );
    return response.data;
  };

  transferCaseByOperator = async (
    body: ITransferCaseByOperator
  ): Promise<IEmergencyCase> => {
    return API.post(
      `operator/TransferCaseByOperator?emergencyCaseId=${body.emergencyCaseId}&operatorId=${body.operatorId}`
    );
  };

  UpdateOperatorOnlineStatus = async (
    body: IUpdateOperatorStatus
  ): Promise<IEmergencyPractitioner> => {
    return API.put(
      `Admin_Operator/UpdateOperatorStatus?OperatorId=${body.operatorId}&status=${body.status}`
    );
  };
}

export default new Operators();

import React from "react";
import { Button } from "antd";
import { successTitle, successSubtitle } from "../../../styles/ModalStyles";
import { CustomIcons } from "../../../assets/index";

interface SuccessMessageProps {
  subtitle: string;
  onClose: () => void;
}

const SuccessMessage: React.FC<SuccessMessageProps> = ({
  subtitle,
  onClose,
}) => {
  return (
    <div className="flex w-full h-full gap-2 flex-column align-items-center justify-content-center">
      <div className="flex w-full justify-content-center">
        <CustomIcons.SuccessIcon
          width={100}
          height={100}
          viewBox={null}
          svgColor="#179717"
        />
      </div>
      <div style={successTitle}>Successful</div>
      <div style={successSubtitle} className="pb-4">
        {subtitle}
      </div>
      <Button
        type="primary"
        ghost
        onClick={onClose}
        size="large"
        className="w-4"
      >
        close
      </Button>
    </div>
  );
};

export default SuccessMessage;

/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
import { API, APILocal } from "../config/axios";

class Dashboard {
  async getDashboardData() {
    const response = await API.get("/dashboard");
    try {
      if (response.data === 0) return { isSuccess: false };
      return { isSuccess: true, data: response.data };
    } catch (error) {
      return { isSuccess: false };
    }
  }

  async getDashboard(): Promise<IDashboard> {
    const response = await API.get("/dashboard");
    return response.data;
  }

  async getDetailDashboard(
    userId: string | number | null
  ): Promise<IOperatorDashboard> {
    const response = await API.get(`/dashboard/metrics/${userId}`);
    return response.data;
  }
}

export default new Dashboard();

/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-vars */
import { Avatar, Button, Form, Input, message, Upload } from "antd";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import React, { useEffect, useRef, useState } from "react";
import { copyObjects } from "../../util/copyObjects";
import Colors from "../../config/Colors";
import {
  clickHereDraggerStyles,
  headerDraggerStyles,
  ImageBox,
  subtitleDraggerStyles,
} from "./styles";
import { CustomIcons } from "../../assets";
import DaggerCard from "../Cards/DaggerCard/DaggerCard";
import { uploadUrl } from "@/config/urls";

const MAX_LENGTH = 200;
const { Dragger } = Upload;

type NewEmergencyFormProp = {
  emergencyValues?: any;
  onFormFinish: (emergency: IEmergency) => void;
  onModelClose: () => void;
  isLoading: boolean;
  isSuccess: boolean;
};

const NewEmergencyForm = ({
  emergencyValues,
  onFormFinish,
  onModelClose,
  isLoading,
  isSuccess,
}: NewEmergencyFormProp) => {
  const [form] = Form.useForm();
  const [charactersLeft, setCharactersLeft] = useState(MAX_LENGTH);
  const [imageUrl, setImageUrl] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const onFinish = async (formValues: any) => {
    const values: IEmergency = formValues as IEmergency;

    await onFormFinish(values);
  };

  const handleClose = () => {
    form.resetFields();
    setCharactersLeft(MAX_LENGTH);
    onModelClose();
  };

  const beforeUpload = (file: RcFile) => {
    const isJpegOrPngOrGif = ["image/jpeg", "image/png", "image/gif"].includes(
      file.type
    );
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isJpegOrPngOrGif) {
      message.error("You can only upload JPG/PNG/GIF file!");
      return false; // This prevents the file from being added to the list
    }
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
      return false; // This prevents the file from being added to the list
    }
    return isJpegOrPngOrGif && isLt2M;
  };

  const props: UploadProps = {
    name: "file",
    multiple: false,
    action: uploadUrl,
    onChange(info) {
      setFileList([...info.fileList]); // Update the local fileList state
      const { status } = info.file;
      if (status === "done") {
        form.setFieldsValue({ iconPath: info.file.response.path });
        setImageUrl(info.file.response.path);
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      //console.log("Dropped files", e.dataTransfer.files);
    },
  };

  useEffect(() => {
    if (emergencyValues) {
      form.setFieldsValue(emergencyValues);
      if (emergencyValues.iconPath) {
        setImageUrl(emergencyValues.iconPath);
      }
      if (emergencyValues.emergencyDiscription) {
        setCharactersLeft(
          MAX_LENGTH - emergencyValues.emergencyDiscription.length
        );
      }
    } else {
      form.resetFields();
    }
  }, [emergencyValues, form]);

  useEffect(() => {
    if (isSuccess) {
      setCharactersLeft(MAX_LENGTH);
      form.resetFields();
    }
  }, [isSuccess]);

  return (
    <Form
      form={form}
      name="newEmergency"
      onFinish={onFinish}
      // onFinishFailed={onFinishFailed}
      layout="vertical"
      style={{ width: "100%" }}
      className="grid"
      initialValues={emergencyValues}
    >
      <div className="col-12">
        <Form.Item
          name="emergencyType"
          label="Name of emergency"
          rules={[{ required: true, message: "Please enter emergency name!" }]}
          className="mb-1"
        >
          <Input size="large" placeholder="Armed response" />
        </Form.Item>
      </div>
      <div className="col-12">
        <Form.Item
          name="emergencyDiscription"
          label="Description"
          rules={[
            { required: true, message: "Please enter emergency description!" },
            {
              max: MAX_LENGTH,
              message: `Description cannot be longer than ${MAX_LENGTH} characters`,
            },
          ]}
          className="mb-1"
        >
          <>
            <Input.TextArea
              value={form.getFieldValue("emergencyDiscription")}
              placeholder="In a life threatening situation we can send out an armed responded"
              maxLength={MAX_LENGTH}
              onChange={(e) => {
                form.setFieldsValue({ emergencyDiscription: e.target.value });
                setCharactersLeft(MAX_LENGTH - e.target.value.length);
              }}
              rows={3}
            />
            {charactersLeft <= 100 && (
              <div
                className="py-1"
                style={{
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  color: Colors.subtleTextColor,
                }}
              >
                {charactersLeft} characters left
              </div>
            )}
          </>
        </Form.Item>
      </div>
      <Form.Item
        name="iconPath"
        label="Icon"
        rules={[{ required: true, message: "Please enter emergency icon!" }]}
        className="mb-5 col-12"
      >
        <div className="flex w-full gap-6 flx-row justify-content-start">
          {imageUrl.length > 0 ? (
            <Avatar
              shape="square"
              size={64}
              alt="emergency icon"
              src={imageUrl}
            />
          ) : (
            <Avatar shape="square" size={64} alt="emergency icon" />
          )}
          <Dragger
            {...props}
            beforeUpload={beforeUpload}
            className="w-full"
            style={{ background: "transparent" }}
            maxCount={1}
            fileList={fileList} // Use the local fileList state
            accept=".jpg,.jpeg,.png" // This allows only .jpg, .jpeg, and .png files
          >
            <DaggerCard />
          </Dragger>
        </div>
      </Form.Item>
      <Form.Item className="w-12 pt-2 mb-1">
        <div className="flex w-full gap-2 col-12 justify-content-end align-content-center">
          <Button
            type="primary"
            ghost
            onClick={handleClose}
            size="large"
            className="w-3"
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            size="large"
            className="w-3 bg-[#1757a2] shadow-none"
          >
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default NewEmergencyForm;

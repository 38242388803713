import React, { useContext, useEffect, useState } from "react";
import { Button, Input, Radio, Spin } from "antd";
import { CustomIcons } from "@/assets";
import Colors from "@/config/Colors";
import {
  GoogleMap,
  GoogleMapApiLoader,
  Marker,
} from "react-google-map-wrapper";
import { useParams } from "react-router-dom";
import { useResponderAnalyticsCallOutDetails } from "@/hooks/useResponderAnalytics";
import moment from "moment";
import { Skeleton } from "primereact/skeleton";
import { toast } from "sonner";
import ResponderReportQuestions from "@/components/General/ResponderReportQuestions";
import AuthContext from "@/contexts/AuthContext";
import { useGetControlRoomOperatorChats } from "@/hooks/useControlRoomOperators";

type AnalyticsParams = {
  id: string;
};

const ResponderReport = () => {
  const { TextArea } = Input;
  const { id } = useParams<AnalyticsParams>();
  const { user } = useContext(AuthContext);

  const {
    data: callOutDetails,
    isLoading,
    isFetching,
    isError,
  } = useResponderAnalyticsCallOutDetails(id);

  if (isError) {
    toast.error("Failed to fetch call-out details. Please try again later.");
  }

  const postOperatorchats = useGetControlRoomOperatorChats();
  const handleRequestChatHistory = (params: IControlRoomOperatorChats) => {
    try {
      postOperatorchats.mutateAsync(params);
      toast.success(
        "Chat history requested successfully. Check  your emails for the chat history."
      );
    } catch (error) {
      console.error(error);
    }
  };

  const chatParams: IControlRoomOperatorChats = {
    userId: user?.id as number,
    panicEventId: callOutDetails?.callOutId as number,
  };

  const responderFullName = `${callOutDetails?.responderFirstname} ${callOutDetails?.responderLastName}`;
  const userFullName = `${callOutDetails?.userFirstname} ${callOutDetails?.userLastName}`;
  return (
    <div className="flex w-full h-full bg-backgroundColor">
      <div className="w-full h-full max-h-screen overflow-y-auto disable-scrollbars">
        <div className="flex h-full w-full px-1">
          <div className="flex w-1/2 h-full pr-1">
            <div className="w-full h-full">
              {isLoading || isFetching ? (
                <Skeleton width="100%" height="100%" />
              ) : (
                <GoogleMapApiLoader
                  apiKey={
                    process.env.REACT_APP_GOOGLE_MAPS_API_KEY
                      ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY
                      : ""
                  }
                >
                  <GoogleMap
                    className="h-full w-full"
                    zoom={4.5}
                    initialCenter={{
                      lat: -26.204103,
                      lng: 28.047304,
                    }}
                    center={{
                      lat: -26.204103,
                      lng: 28.047304,
                    }}
                  >
                    {callOutDetails && (
                      <Marker
                        lat={callOutDetails.latitude as number}
                        lng={callOutDetails.longitude as number}
                      />
                    )}
                  </GoogleMap>
                </GoogleMapApiLoader>
              )}
            </div>
          </div>
          <div className="flex w-1/2 h-full pl-1 pt-2 pb-3">
            <div
              className="flex flex-col w-full h-full "
              style={{
                overflowY: "auto",
                height: "100%",
                scrollbarWidth: "none" /* For Firefox */,
                msOverflowStyle: "none" /* For Internet Explorer and Edge */,
              }}
            >
              <div className="flex w-full flex-row mb-3">
                {isLoading || isFetching ? (
                  <div className="flex w-full gap-2 flex-col">
                    <Skeleton height="2rem" width="90%" />
                    <Skeleton height="2rem" width="50%" />
                  </div>
                ) : (
                  <>
                    <div className="text-2xl font-bold">Call-out ID: </div>
                    <div className="text-2xl">
                      {callOutDetails?.callOutId ?? "N/A"}
                    </div>
                  </>
                )}
              </div>
              <div className="flex w-full flex-row mb-3">
                {isLoading || isFetching ? (
                  <>
                    <Skeleton height="2rem" width="90%" />
                  </>
                ) : (
                  <>
                    <CustomIcons.CalendarCancelIcon
                      height={18}
                      width={18}
                      svgColor={Colors.appMainColor}
                    />
                    <div className="text-sm font-bold">Date: </div>
                    <div className="text-sm">
                      {callOutDetails?.date != null
                        ? moment(callOutDetails?.date).format("YYYY/MM/DD")
                        : "N/A"}
                    </div>
                  </>
                )}
              </div>
              <div className="flex w-full text-xl font-bold mb-3">
                {isLoading || isFetching ? (
                  <>
                    <Skeleton height="2rem" width="90%" />
                  </>
                ) : (
                  <>Call-Out Details</>
                )}
              </div>
              <div className="flex w-full flex-row">
                {isLoading || isFetching ? (
                  <>
                    <Skeleton height="2rem" width="90%" />
                  </>
                ) : (
                  <>
                    <CustomIcons.PersonIcon
                      height={18}
                      width={18}
                      svgColor={Colors.appMainColor}
                    />
                    <div className="text-sm font-bold text-subtleTextColor">
                      Responder
                    </div>
                  </>
                )}
              </div>
              <div className="flex text-sm mb-3">
                {isLoading || isFetching ? (
                  <div className="flex w-full mt-3">
                    <Skeleton height="2rem" width="50%" />
                  </div>
                ) : (
                  <>{responderFullName ?? "N/A"}</>
                )}
              </div>
              {isLoading || isFetching ? (
                <div className="flex w-full mt-3">
                  <Skeleton height="2rem" width="90%" />
                </div>
              ) : (
                <>
                  <div className="flex w-full flex-row mb-3">
                    <div className="text-sm font-bold">Repsonse Time: </div>
                    <div className="text-sm">
                      {callOutDetails?.responseTime ?? "N/A"}
                    </div>
                  </div>
                </>
              )}

              {isLoading || isFetching ? (
                <div className="flex w-full mt-3">
                  <Skeleton height="2rem" width="90%" />
                </div>
              ) : (
                <>
                  <div className="flex w-full flex-row mb-3">
                    <div className="text-sm font-bold">ETA: </div>
                    <div className="text-sm">
                      {callOutDetails?.eta ?? "N/A"}
                    </div>
                  </div>
                </>
              )}

              {isLoading || isFetching ? (
                <div className="flex w-full mt-3">
                  <Skeleton height="2rem" width="90%" />
                </div>
              ) : (
                <>
                  <div className="flex w-full flex-row mb-3">
                    <div className="text-sm font-bold">Time on scene: </div>
                    <div className="text-sm">
                      {moment(callOutDetails?.date).format("HH:mm") ?? "N/A"}
                    </div>
                  </div>
                </>
              )}

              {isLoading || isFetching ? (
                <>
                  <Skeleton height="2rem" width="90%" />
                </>
              ) : (
                <>
                  <div className="flex w-full flex-row">
                    <CustomIcons.PersonIcon
                      height={18}
                      width={18}
                      svgColor={Colors.appMainColor}
                    />
                    <div className="text-sm font-bold text-subtleTextColor">
                      Username
                    </div>
                  </div>
                </>
              )}
              {isLoading || isFetching ? (
                <div className="flex w-full mt-3">
                  <Skeleton height="2rem" width="50%" />
                </div>
              ) : (
                <>
                  <div className="flex text-sm mb-3">
                    {userFullName ?? "N/A"}
                  </div>
                </>
              )}

              {isLoading || isFetching ? (
                <>
                  <Skeleton height="2rem" width="90%" />
                </>
              ) : (
                <>
                  <div className="flex w-full flex-row mb-3">
                    <div className="text-sm font-bold">Call-out Type: </div>
                    <div className="text-sm">
                      {callOutDetails?.callOutType ?? "N/A"}
                    </div>
                  </div>
                </>
              )}

              {isLoading || isFetching ? (
                <div className="flex w-full mt-3">
                  <Skeleton height="2rem" width="90%" />
                </div>
              ) : (
                <>
                  <div className="flex w-full text-xl font-bold mb-3">
                    Call-in Report
                  </div>
                </>
              )}

              {isLoading || isFetching ? (
                <div className="flex w-full mt-3">
                  <Skeleton height="2rem" width="90%" />
                </div>
              ) : (
                <>
                  {callOutDetails?.phoneAnswered != null && (
                    <div className="flex w-full text-sm">
                      Did the user answer the phone?
                    </div>
                  )}
                </>
              )}

              {isLoading || isFetching ? (
                <div className="flex w-full mt-3">
                  <Skeleton height="2rem" width="50%" />
                </div>
              ) : (
                <>
                  {callOutDetails?.phoneAnswered != null && (
                    <div className="flex w-full mb-3 flex-col gap-1">
                      <Radio.Group
                        className="flex w-full justify-start"
                        value={callOutDetails?.phoneAnswered ? 1 : 2}
                      >
                        <Radio value={1}>Yes</Radio>
                        <Radio value={2}>No</Radio>
                      </Radio.Group>
                    </div>
                  )}
                </>
              )}
              <ResponderReportQuestions
                isFetchingProp={isFetching}
                isLoadingProp={isLoading}
                question={`Were there any fatalities?`}
                answer={callOutDetails?.casualties}
                answerInfo={callOutDetails?.casualtiesInfo}
              />

              <ResponderReportQuestions
                isFetchingProp={isFetching}
                isLoadingProp={isLoading}
                question={`Were the suspects armed?`}
                answer={callOutDetails?.suspectArmed}
                answerInfo={callOutDetails?.suspectArmedInfo}
              />

              <ResponderReportQuestions
                isFetchingProp={isFetching}
                isLoadingProp={isLoading}
                question={`Were there any vehicles involved?`}
                answer={callOutDetails?.vehiclesInvolved}
                answerInfo={callOutDetails?.vehiclesInvolvedInfo}
              />

              <ResponderReportQuestions
                isFetchingProp={isFetching}
                isLoadingProp={isLoading}
                question={`Were there any police on scene?`}
                answer={callOutDetails?.policeOnScene}
                answerInfo={callOutDetails?.policeOnSceneInfo}
              />

              <ResponderReportQuestions
                isFetchingProp={isFetching}
                isLoadingProp={isLoading}
                question={`Were there any other responders on scene?`}
                answer={callOutDetails?.otherRespondersOnScene}
                answerInfo={callOutDetails?.otherRespondersOnSceneInfo}
              />

              <ResponderReportQuestions
                isFetchingProp={isFetching}
                isLoadingProp={isLoading}
                question={`Were any medical responders required?`}
                answer={callOutDetails?.medical}
                answerInfo={callOutDetails?.medicalInfo}
              />

              <ResponderReportQuestions
                isFetchingProp={isFetching}
                isLoadingProp={isLoading}
                question={`Were there any arrests made?`}
                answer={callOutDetails?.arrestsMade}
                answerInfo={callOutDetails?.arrestsMadeInfo}
              />

              <ResponderReportQuestions
                isFetchingProp={isFetching}
                isLoadingProp={isLoading}
                question={`Other ?`}
                answer={callOutDetails?.other}
                answerInfo={callOutDetails?.otherInfo}
              />

              {isLoading || isFetching ? (
                <div className="flex w-full mt-3">
                  <Skeleton height="7rem" width="90%" />
                </div>
              ) : (
                <>
                  <div className="flex w-full text-lg">Comment</div>
                  <div className="flex w-full">
                    <TextArea rows={4} value={callOutDetails?.comments} />
                  </div>
                </>
              )}

              <div className="flex justify-end mt-2">
                <Button
                  type="primary"
                  size="large"
                  className="w-4 bg-[#1757a2] shadow-none"
                  onClick={async () => handleRequestChatHistory(chatParams)}
                >
                  Request Chat History
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponderReport;

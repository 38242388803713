/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useContext, useCallback } from "react";
import {
  Switch,
  Route,
  BrowserRouter,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import { observer } from "mobx-react";
import AccountLogin from "./pages/AccountLogin/AccountLogin";
import Layouts from "./layouts/index";
import ForgotPassword from "./pages/ResetPassword/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import OTP from "./pages/OTP/OTP";

import Home from "./layouts/Home";
import { AuthContext } from "./contexts/AuthContext.tsx";

import Users from "./services/Users";

const CustomRoute = observer(
  ({ isPrivate = false, authenticationHandler = () => false, ...rest }) => {
    const { user, isLoggedIn, getUserType } = useContext(AuthContext);
    const history = useHistory();

    useEffect(() => {
      let isMounted = true;
      if (isLoggedIn === false && isMounted) {
        history.push("/");
      }
      return () => {
        isMounted = false;
      };
    }, [isLoggedIn, history]);
    return <Route {...rest} render={() => rest.children} />;
  }
);

const PrivateRoute = ({ Component, ...rest }) => {
  const {
    user,
    isLoggedIn,
    isAdmin,
    isOperator,
    isCommunityAdmin,
    isOperatorAdmin,
    setUser,
  } = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();

  const handleNotLoggedIn = useCallback(() => {
    const returnUrl = btoa(`${location.pathname}${location.search}`);
    history.push(`/?returnUrl=${returnUrl}`);
  }, [history, location.pathname, location.search]);

  useEffect(() => {
    let isMounted = true;
    if (isLoggedIn) {
      // fetch latest details of the user here
    }
    return () => {
      isMounted = false;
    };
  }, [
    user,
    handleNotLoggedIn,
    isAdmin,
    isOperator,
    setUser,
    isOperatorAdmin,
    isCommunityAdmin,
  ]);

  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

const Router = () => {
  const LoginView = <Layouts.PlainLayout component={AccountLogin} />;
  const ForgotPasswordView = <Layouts.PlainLayout component={ForgotPassword} />;
  const ResetPasswordView = <Layouts.PlainLayout component={ResetPassword} />;
  const OTPView = <Layouts.PlainLayout component={OTP} />;
  return (
    <BrowserRouter>
      <Switch>
        <CustomRoute path="/" exact>
          {LoginView}
        </CustomRoute>
        <Route path="/forgot_password" exact>
          {ForgotPasswordView}
        </Route>
        <Route path="/reset" exact>
          {ResetPasswordView}
        </Route>
        <Route path="/otp" exact>
          {OTPView}
        </Route>

        <PrivateRoute path={Layouts.Home} component={(props) => <Home />} />
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;

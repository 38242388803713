/* eslint-disable no-useless-catch */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */

import { DateTime } from "luxon";
import { API, APILocal, APIStaging } from "../config/axios";
import { toast } from "sonner";

class Companies {
  addCompany = async (body: IAddCompany) => {
    try {
      const response = await API.post("Admin_Companies/AddCompany", body);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  getPaginatedCompanyCardsData = async (
    pageNumber: string | number | null,
    pageSize: number | string | null = 6,
    name: string | null
  ): Promise<ICompanyCardResponse> => {
    const url = `Admin_Companies?pageNumber=${pageNumber}&pageSize=${pageSize}&name=${name}`;
    const response = await API.get<ICompanyCardResponse>(url);
    return response.data;
  };

  getCompanyCardsData = async (): Promise<ICompanyCard[]> => {
    const response = await API.get("Admin_Companies/getCompanyCardsData");
    return response.data;
  };

  getEditCompanyData = async (
    companyId: string | number
  ): Promise<IEditCompany> => {
    const response = await API.get<IEditCompany>(
      `Admin_Companies/getEditCompanyData?companyId=${companyId}`
    );
    return response.data;
  };

  deactivateCompany = async (companyId: string | undefined) => {
    try {
      const response = await API.post(
        `Admin_Companies/DeactivateCompany?companyId=${companyId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  ReactivateCompany = async (companyId: string | null) => {
    try {
      const response = await API.post(
        `Admin_Companies/Re-ActivateCompany?companyId=${companyId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  AddCompanyMembers = async (companyMembers: ICompanyMembers) => {
    try {
      const response = await API.post(
        `Admin_Companies/uploadCompanyExcel`,
        companyMembers
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  editCompany = async (editedCompany: IEditCompany): Promise<IEditCompany> => {
    try {
      const response = await API.post(
        "Admin_Companies/EditCompany",
        editedCompany
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  cancelCompanyImmediately = async (companyId: string) => {
    try {
      const response = await API.post(
        `Admin_Companies/ImmediateCancelCompanySubscription?companyId=${companyId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  scheduleCancelCompany = async (companyId: string, cancelDate: string) => {
    try {
      const response = await API.post(
        `Admin_Companies/ScheduleCancelCompanySubscription?companyId=${companyId}&cancelDateStr=${cancelDate}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  // getCompanyMembersData = async (
  //   id: string | number
  // ): Promise<ICompanyUsers[]> => {
  //   const response = await API.get(
  //     `Admin_Companies/ Admin_Companies/CompanyMembers/${id}`
  //   );
  //   return response.data;
  // };

  getCompanyMembersData = async (
    id: string | number,
    pageNumber: string | number | null,
    pageSize: number | string | null = 6,
    userName?: string | null,
    subscriptionName?: string | null,
    status?: number | null,
    turnover?: boolean | null
  ): Promise<IPaginatedCompanyMembersResponse> => {
    // Base URL
    let url = `Admin_Companies/CompanyMembers?companyId=${id}`;
  
    // Add parameters only if they are defined
    if (pageNumber !== null && pageNumber !== undefined) {
      url += `&pageNumber=${pageNumber}`;
    }
    if (pageSize !== null && pageSize !== undefined) {
      url += `&pageSize=${pageSize}`;
    }
    if (userName) {
      url += `&userName=${userName}`;
    }
    if (subscriptionName) {
      url += `&subscriptionName=${subscriptionName}`;
    }
    if (status !== null && status !== undefined) {
      url += `&status=${status}`;
    }
    if (turnover !== null && turnover !== undefined) {
      url += `&turnover=${turnover}`;
    }

    const response = await API.get<IPaginatedCompanyMembersResponse>(url);
    return response.data;
  };
  

  getCompanyPaymentData = async (
    id: string | number
  ): Promise<ICompanyPayments[]> => {
    const response = await API.get(`Admin_Companies/GetPayments/${id}`);
    return response.data;
  };

  companyRenewal = async (body: ICompanyUserRenewal) => {
    try {
      const response = await API.post(
        "Admin_Companies/RenewSubscription",
        body
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  AddCompanyPayment = async (companyPayment: ICompanyUploadPayments) => {
    try {
      const response = await API.post(
        `Admin_Companies/AddCompanyPayment`,
        companyPayment
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  getCompanies = async (): Promise<IActiveCompanies[]> => {
    const response = await API.get("Admin_Companies/GetCompanies");
    return response.data;
  };

  // remnove a member from company
  removeCompanyMember = async (userId: string): Promise<string> => {
    try {
      const response = await API.delete(
        `Admin_Companies/companyMember/${userId}`
      );

      return response?.data;
    } catch (error) {
      toast.error("Failed to remove company member. Please try again.");
      throw error;
    }
  };
}

export default new Companies();

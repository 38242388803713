import { createQueryKeys } from "@lukemorales/query-key-factory";
import Carousel from "../Carousel";

export const carousel = createQueryKeys("carousel", {
  getCarouselData: null,
  GetCarousalDataById: (id: number | null | string) => ({
    queryKey: [{ id }],
    queryFn: () => Carousel.getSelectedCarousel(id),
  }),
});

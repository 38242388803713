import React from "react";
import { Button, message } from "antd";
import { successTitle, successSubtitle } from "../../pages/Users/styles";
import { CustomIcons } from "../../assets";
import NewUserForm from "../Forms/NewUser";
import { Dispatcher } from "../../util/helpers";
import { usePostCommunityAdmin } from "../../hooks/useAdmin";
import { copyObjects } from "../../util/copyObjects";

interface NewCommunityAdminsProp {
  communityId: number | null;
  setCloseDrawer: Dispatcher<boolean>;
  refetchAdmins: () => void;
}

const NewCommunityAdmin = ({
  communityId,
  setCloseDrawer,
  refetchAdmins,
}: NewCommunityAdminsProp) => {
  const addCommunityAdmin = usePostCommunityAdmin();
  const [messageApi, contextHolder] = message.useMessage();
  const messageKey = "createCommunityAdmin";

  const destroyMessage = () => {
    messageApi.destroy(messageKey);
  };

  const handleAddUser = async (user: IUser) => {
    if (communityId) {
      try {
        messageApi.open({
          key: messageKey,
          type: "loading",
          content: "Creating Community admin..",
          duration: 0,
        });

        const communityAdmin: ICommunityAdmin = copyObjects(user, {
          CommunityId: communityId || 0,
        });
        await addCommunityAdmin.mutateAsync(communityAdmin);
        refetchAdmins();
        messageApi.open({
          key: messageKey,
          type: "success",
          content: "Created Community admin..",
          duration: 0,
        });
        setTimeout(() => {
          destroyMessage();
        }, 2000);
      } catch (error) {
        destroyMessage();
        console.error(error);
      }
    } else {
      message.error("Community not found");
    }
  };

  const handleClose = async () => {
    setCloseDrawer(false);
    addCommunityAdmin.reset();
  };
  return (
    <div>
      {contextHolder}
      {addCommunityAdmin.isSuccess ? (
        <div className="flex w-full h-full gap-2 flex-column align-items-center justify-content-center">
          <div className="flex w-full justify-content-center">
            <CustomIcons.SuccessIcon
              width={100}
              height={100}
              viewBox={null}
              svgColor="#179717"
            />
          </div>
          <div style={successTitle}> Successful</div>
          <div style={successSubtitle} className="pb-4">
            {" "}
            You have successfully added a new admin
          </div>
          <Button
            type="primary"
            ghost
            onClick={handleClose}
            size="large"
            className="w-4"
          >
            close
          </Button>
        </div>
      ) : (
        <>
          <NewUserForm
            onFormFinish={(user: IUser) => handleAddUser(user)}
            onModelClose={() => {
              handleClose();
            }}
            isLoading={addCommunityAdmin.isLoading}
            isSuccess={addCommunityAdmin.isSuccess}
          />
        </>
      )}
    </div>
  );
};

export default NewCommunityAdmin;

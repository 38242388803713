import { createQueryKeys } from "@lukemorales/query-key-factory";
import Responder from "../Responder";

export const responders = createQueryKeys("responder", {
  all: null,
  GetRespondersByCompanyId: (
    pageNumber: number,
    pageSize: number,
    companyId?: number
  ) => ({
    queryKey: [{ pageNumber, pageSize, companyId }],
    queryFn: () =>
      Responder.getRespondersByCompanyId(pageNumber, pageSize, companyId),
  }),
});

/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { LeftOutlined } from "@ant-design/icons";
import { Form, Input, Button, Alert, Space } from "antd";
import Images from "../../assets";
import PrimaryProgressBar from "../../components/widgets/Spinner/ProgressBar";
import Colors from "../../config/Colors";

const ForgotPassword: React.FC = () => {
  const navigate = useHistory();
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const handleSubmit = async (values: any) => {
    setLoading(true);
    navigate.push("/otp");
  };

  return (
    <div className="w-screen h-screen Account-Login-Container sm:overflow-auto md:overflow-hidden">
      <div className="flex flex-col items-center justify-start w-full h-screen md:h-full md:flex-row ">
        <img
          src={Images.backgroundImage}
          alt="safer city logo"
          className="hidden w-12 h-full md:flex md:w-2/4 md:min-h-full"
          style={{
            objectFit: "cover",
          }}
        />
        <div className="flex flex-col items-center justify-center w-full h-full px-2 md:w-2/4 ">
          <div className="flex flex-column align-items-start">
            <Button
              type="text"
              className="absolute hidden text-base text-left md:block align-self-start md:top-0 md:left-50 "
              style={{
                color: "#8692A6",
              }}
              onClick={() => navigate.goBack()}
            >
              <div className="flex items-center justify-start gap-1">
                <LeftOutlined />
                Back
              </div>
            </Button>
          </div>
          <div className="flex justify-center w-full py-4 md:py-8">
            {showAlert && (
              <Alert message={alertMessage} type="error" closable />
            )}
            {loading && <PrimaryProgressBar />}
          </div>
          <Space
            direction="vertical"
            size={50}
            style={{ display: "flex", width: "100%" }}
          >
            <div className="w-full px-8 mx-8">
              <h2
                className="flex items-start justify-start pt-4 text-2xl font-bold md:pt-0"
                style={{
                  color: `${Colors.appMainColor}`,
                }}
              >
                Forgot password
              </h2>
              <div
                className="flex items-start justify-start pt-4 font-normal text-medium md:pt-0"
                style={{
                  color: `${Colors.subtleTextColor}`,
                }}
              >
                {/* Enter your email bellow to reset your password */}
                Please Reset Your Password using the mobile app.
              </div>
            </div>
            {/* <div className="flex items-center justify-center h-full">
              <Form
                name="loginForm"
                initialValues={{ remember: true }}
                onFinish={handleSubmit}
                className="w-2/3"
              >
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Please enter your email!" },
                    { type: "email", message: "Please enter a valid email!" },
                  ]}
                >
                  <Input
                    size="large"
                    style={{
                      borderBottom: `1px solid ${Colors.appMainColor}`,
                      borderLeft: "none",
                      borderRight: "none",
                      borderTop: "none",
                      borderRadius: 0,
                      outline: "none",
                    }}
                    bordered={false}
                    placeholder="Email"
                  />
                </Form.Item>
                <div style={{ margin: "4rem" }} />
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    className="w-8"
                    style={{
                      width: "100%",
                      backgroundColor: `${Colors.appMainColor}`,
                    }}
                  >
                    Continue
                  </Button>
                </Form.Item>
              </Form>
            </div> */}
            <div style={{ margin: "5rem" }} />
          </Space>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;

import React from "react";
import { Button, Spin } from "antd";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SearchOutlined } from "@ant-design/icons";
import {
  ActionBodyText,
  BodyContainer,
  Container,
} from "../../styles/ContainerStyles";
import HeaderComponent from "../../components/Header/Header";
import { tableBody, tableHeader, tableName } from "../../styles/TableStyles";
import CustomInputField from "../../components/widgets/Inputs/CustomInputField";
import Colors from "../../config/Colors";

const DeactivatedCompanies = () => {
  const customInputTheme = {
    token: {
      colorPrimaryHover: `${Colors.appMainColor}`,
    },
  };

  const ReactivateBodyTemplate = () => {
    return <ActionBodyText>Activate</ActionBodyText>;
  };
  return (
    <div className="w-full h-full">
      <Container className="w-full h-full">
        <BodyContainer className="w-full">
          <HeaderComponent title="Deactivated Companies" />
          <Spin tip="" spinning={false} delay={500}>
            <div className="w-full h-full px-3 card Users">
              <div className="flex flex-row justify-between w-full gap-1 mb-2 align-items-center">
                <div className="flex flex-row gap-1 justify-end mb-2 w-full">
                  <CustomInputField
                    // value={globalFilterValue}
                    // onChange={(e: string) => onGlobalFilterChangeV2(e)}
                    onPressEnter={() => {}}
                    theme={customInputTheme}
                    inputProps={{
                      size: "large",
                      placeholder: "Search",
                      allowClear: true,
                      className: "ml-2 w-3",
                      prefix: <SearchOutlined />,
                    }}
                    mergeThemes
                    value={""}
                    onChange={() => {}}
                  />
                </div>
              </div>
              <DataTable
                // ref={dt}
                //value={users}
                paginator
                rows={10}
                rowsPerPageOptions={[10, 25, 50, 100]}
                dataKey="id"
                //filters={filters}
                globalFilterFields={[]}
                emptyMessage="No companies found."
                rowHover
                scrollable
                scrollHeight="40rem"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Users"
                stateStorage="session"
                stateKey="dt-state-deactivated-companies-table"
              >
                <Column
                  field="date"
                  header="Account created"
                  headerStyle={tableHeader}
                  filterField="date"
                  dataType="date"
                  // body={dateBodyTemplate}
                  bodyStyle={tableBody}
                  // filter
                  // filterElement={dateFilterTemplate}
                  style={{ background: "#FBFBFB" }}
                />
                <Column
                  // field="fullName"
                  header="Company ID"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                  //   filterPlaceholder="Search by name"
                />
                <Column
                  // field="auraId"
                  header="Company Name"
                  headerStyle={tableHeader}
                  filterPlaceholder="Search by aura id"
                  style={{ background: "#FBFBFB", textAlign: "center" }}
                  // body={auraIdBodyTemplate}
                  bodyStyle={tableBody}
                />
                <Column
                  // field="subscriptionPlan"
                  header="Price per Month"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                  //   filter
                  //   filterPlaceholder="Search by plan"
                />
              </DataTable>
            </div>
          </Spin>
        </BodyContainer>
      </Container>
    </div>
  );
};

export default DeactivatedCompanies;

import axios, { AxiosInstance } from "axios";
import Cookies from "js-cookie";
import { apiUrl, LocalHost, StagingUrl } from "./urls";

const setupInterceptors = (axiosInstance: AxiosInstance): void => {
  axiosInstance.interceptors.request.use((config) => {
    const token = Cookies.get("token"); // Retrieve the token from cookies
    if (token && config.headers) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  axiosInstance.interceptors.response.use(
    (response) => {
      if (response.data.status === "error") {
        // Construct an error object to simulate a more typical error scenario
        const error = new Error(response.data.message);
        throw error;
      }
      return response;
    }, // Directly return successful responses
    (error) => {
      if (axios.isAxiosError(error)) {
        let baseMessage = "Something went wrong, please try again."; // Default message
        const serverMessage = error.response?.data?.message
          ? `- ${error.response?.data.message}`
          : "";
        if (error.config?.method === "get") {
          switch (error.response?.status) {
            case 400:
              baseMessage =
                "The request could not be understood by the server, please check your input.";
              break;
            case 401:
              baseMessage =
                "You're not authorized to access this resource, please log in and try again.";
              break;
            case 404:
              baseMessage =
                "The requested resource couldn't be found, please check the URL or try a different one.";
              break;
            case 500:
              baseMessage =
                "We're experiencing some technical issues, our team is working on it. Please try again later.";
              break;
            default:
              break;
          }
          // Append serverMessage if available
          error.message = `${baseMessage} ${serverMessage}`.trim();
        } else if (error.config?.method !== "get") {
          if (error.response) {
            // Handle HTTP errors
            if (error.response.data === "string") {
              baseMessage = error.response.data;
            } else if (
              error.response.status === 400 &&
              error.response.data &&
              "errors" in error.response.data
            ) {
              const { errors } = error.response.data;
              const combinedErrorMessage = Object.keys(errors)
                .map((key) => `${key}: ${errors[key].join(", ")}`)
                .join(". ");
              baseMessage = combinedErrorMessage || "Validation error occurred";
            } else if (error.code === "ECONNABORTED") {
              baseMessage = "Request timed out";
            } else {
              baseMessage = error.response.data?.message || "An error occurred";
            }
          } else if (error.request) {
            baseMessage = "No response received";
          } else {
            baseMessage = "An error occurred";
          }
          error.message = `${baseMessage}`.trim();
        }
      }
      return Promise.reject(error);
    }
  );
};

export const API = axios.create({
  baseURL: apiUrl,
});

export const API_2 = axios.create({
  baseURL: apiUrl,
});

export const APILocal = axios.create({
  baseURL: LocalHost,
});

export const APIStaging = axios.create({
  baseURL: StagingUrl,
});

// Set up the interceptor for API
API.interceptors.request.use((config) => {
  const token = Cookies.get("token"); // Retrieve the token from cookie
  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Set up the interceptor for API_2
setupInterceptors(API_2);

// Set up the interceptor for APILocal
APILocal.interceptors.request.use((config) => {
  const token = Cookies.get("token"); // Retrieve the token from cookie
  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Set up the interceptor for APIStaging
APIStaging.interceptors.request.use((config) => {
  const token = Cookies.get("token"); // Retrieve the token from cookie
  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const axiosInterceptor = async ({ authKey = null }: any) => {
  const authHeader = authKey ? `Bearer ${authKey}` : null;

  await API.interceptors.request.use((config) => {
    config.headers.Authorization = authHeader;
    return config;
  });
};

import React, { useRef, useState } from "react";
import { Button, DatePicker, Modal, Space, message } from "antd";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";
import type { DatePickerProps } from "antd";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {
  btnStyles,
  cardStyles,
  contentStyles,
  imageStyles,
  subheaderStyles,
  titleStyles,
} from "./styles";
import { CustomIcons } from "../../../assets";
import Colors from "../../../config/Colors";
import NewCompanyUsers from "../../Forms/NewCompanyUsersForm";
import { modelTitle } from "../../../styles/ModalStyles";
import EditCompany from "../../Forms/EditCompany";
import {
  useAddCompanyMembers,
  useCompanyRenewal,
  useEditCompany,
  useScheduleCompanyCancel,
  useCancelCompanyImmediately,
} from "../../../hooks/useCompanies";
import GenericDialog from "../../Dialog/GenericDialog";
import RenewCompanySubscriptions from "../../Forms/RenewCompanySubscriptions";

interface cardProps {
  id: number;
  title: string;
  corporateAgreement: number;
  basicPlan: number;
  familyofTwo: number;
  familyofFour: number;
  familyofSix: number;
  pBasicPlan: number;
  pFamilyofTwo: number;
  pFamilyofFour: number;
  pFamilyofSix: number;
  referralCode?: string;
  communityCode?: string;
  turnover: number;
  total: number;
  imgSrc: string;
}

const CompaniesCard = ({
  id,
  title,
  corporateAgreement,
  basicPlan,
  familyofTwo,
  familyofFour,
  familyofSix,
  pBasicPlan,
  pFamilyofTwo,
  pFamilyofFour,
  pFamilyofSix,
  referralCode,
  communityCode,
  turnover,
  total,
  imgSrc,
}: cardProps) => {
  let cancelDate;
  const editCompanyhook = useEditCompany();
  const navigate = useHistory();
  const cancelImmediately = useCancelCompanyImmediately();
  const sheduleCancel = useScheduleCompanyCancel();
  const renewcompanyUsers = useCompanyRenewal();
  const [openImmediateCancelModal, setOpenImmediateCancelModal] =
    useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openRenewModal, setOpenRenewModal] = useState(false);
  const addCompnayMembers = useAddCompanyMembers();
  const menuRight = useRef<Menu>(null);
  const [openNewCompanyUsersModal, setOpenNewCompanyUsersModal] =
    useState(false);
  const [openEditCompanyModal, setOpenEditCompanyModal] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const messageKey = "cancelCompanySubImmediately";
  const messageKeyShedule = "sheduleCancelCompanySub";

  const handleEditCompany = async (company: IEditCompany) => {
    try {
      await editCompanyhook.mutateAsync(company);
      message.success("Changes Saved Successfully.");
    } catch (error) {
      console.log(error);
    }
  };

  const navigateToMembers = (companyId: number | string) => {
    navigate.push(`/home/company-members/${companyId}`);
  };

  const navigateToPayment = (companyId: number | string) => {
    navigate.push(`/home/proof-of-payment/${companyId}`);
  };

  let scheduleVars: IScheduleCancelParams;

  const onChangeDate: DatePickerProps["onChange"] = (date) => {
    cancelDate = moment(date?.toString()).format("YYYY/MM/DD");
    scheduleVars = {
      companyId: id.toString(),
      cancelDate,
    };
  };

  const handleCompanyRenewal = async (companyRenewal: ICompanyUserRenewal) => {
    try {
      companyRenewal.companyId = id;
      await renewcompanyUsers.mutateAsync(companyRenewal);
      message.success(
        "Subscription renewal will take up to 30 minutes to complete"
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleScheduleCancelCompany = async () => {
    try {
      messageApi.open({
        key: messageKeyShedule,
        type: "loading",
        content: "Sheduling Cancel..",
        duration: 0,
      });
      setOpenCancelModal(false);
      await sheduleCancel.mutateAsync(scheduleVars);
      messageApi.open({
        key: messageKeyShedule,
        type: "success",
        content: "Subsciption Cancel Sheduled..",
        duration: 0,
      });
      setTimeout(() => {
        messageApi.destroy(messageKeyShedule);
      }, 2000);
    } catch (error) {
      // close Message
      messageApi.destroy(messageKeyShedule);
    }
  };

  const handleImmediateCancelCompany = async () => {
    try {
      messageApi.open({
        key: messageKey,
        type: "loading",
        content: "Cancelling Subscription..",
        duration: 0,
      });
      setOpenImmediateCancelModal(false);
      await cancelImmediately.mutateAsync(id.toString());
      messageApi.open({
        key: messageKey,
        type: "success",
        content: "Subsciption Canceelled..",
        duration: 0,
      });
      setTimeout(() => {
        messageApi.destroy(messageKey);
      }, 2000);
    } catch (error) {
      // close Message
      messageApi.destroy(messageKey);
    }
  };

  const items: MenuItem[] = [
    {
      label: "Renew Subscriptions",
      items: [
        {
          label: "Renew for all users",
          icon: (
            <div className="mr-2">
              <CustomIcons.RefreshIcon
                height={26}
                width={26}
                svgColor={Colors.appMainColor}
              />
            </div>
          ),
          command: () => {
            setOpenRenewModal(true);
          },
        },
      ],
    },
    {
      label: "Edit",
      items: [
        {
          label: "Edit Company",
          icon: (
            <div className="mr-2">
              <CustomIcons.PencilIcon
                height={22}
                width={22}
                svgColor={Colors.appMainColor}
              />
            </div>
          ),
          command: () => {
            setOpenEditCompanyModal(true);
          },
        },
      ],
    },
    {
      label: "Payment",
      items: [
        {
          label: "View Payment history",
          icon: (
            <div className="mr-2">
              <CustomIcons.ReceiptIcon
                height={26}
                width={26}
                svgColor={Colors.appMainColor}
              />
            </div>
          ),
          command: () => {
            navigateToPayment(id);
          },
        },
      ],
    },
    {
      label: "Cancel Subscription",
      items: [
        {
          label: "Cancel Immediately",
          icon: (
            <div className="mr-2">
              <CustomIcons.CrossIcon
                height={23}
                width={23}
                svgColor={Colors.appMainColor}
              />
            </div>
          ),
          command: () => {
            setOpenImmediateCancelModal(true);
          },
        },
        {
          label: "Cancel on Next Billing Date",
          icon: (
            <div className="mr-2">
              <CustomIcons.CalendarCancelIcon
                height={23}
                width={23}
                svgColor={Colors.appMainColor}
              />
            </div>
          ),
          command: () => {
            setOpenCancelModal(true);
          },
        },
      ],
    },
  ];

  const handleAddCompanyMembers = async (
    newCompanyMembers: ICompanyMembers
  ) => {
    try {
      newCompanyMembers.id = id;
      await addCompnayMembers.mutateAsync(newCompanyMembers);
      message.success(
        "Company Users will take 30 mins to be added successfully."
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = async () => {
    setOpenEditCompanyModal(false);
    setOpenNewCompanyUsersModal(false);
    setOpenCancelModal(false);
    setOpenImmediateCancelModal(false);
    setOpenRenewModal(false);

    editCompanyhook.reset();
    addCompnayMembers.reset();
  };

  const editCompany: IEditCompany = {
    companyId: id,
    companyName: title,
    companyImage: imgSrc,
    communityCode,
    referralCode,
  };

  const newCompanyMembers: ICompanyMembers = {
    id,
  };

  return (
    <>
      {contextHolder}
      <div style={cardStyles}>
        <div className="flex flex-row w-full gap-1 justify-content-between align-items-center">
          <div style={imageStyles}>
            <img
              src={imgSrc}
              alt=""
              width={50}
              height={50}
              style={{ objectFit: "contain" }}
              className="flex justify-start"
            />
          </div>
          <div className="flex flex-row justify-between w-full gap-2 pl-2">
            <h2 style={titleStyles}>{title}</h2>
            <h2>ID : {id}</h2>
          </div>

          <Menu
            model={items}
            popup
            ref={menuRight}
            id="popup_menu_right"
            popupAlignment="right"
          />
          <button
            type="button"
            className="flex justify-end w-2/5 mb-2"
            onClick={(event) => menuRight.current?.toggle(event)}
            aria-controls="popup_menu_right"
            aria-haspopup="true"
          >
            <CustomIcons.ThreeDotsVerticalIcon
              height={20}
              width={20}
              svgColor="#000000" // Updated with a value
            />
          </button>
        </div>
        <div style={contentStyles} className="py-2">
          <div style={subheaderStyles}>
            <div className="flex flex-row w-full">
              <CustomIcons.GroupIcon
                width={20}
                height={20}
                viewBox={null}
                svgColor={Colors.appMainColor}
              />
              <p className="ml-2">
                Corporate agreement :{" "}
                <button
                  className="text-appMainColor"
                  type="button"
                  onClick={() => navigateToMembers(id)}
                >
                  {" "}
                  {corporateAgreement} User(s)
                </button>
              </p>
            </div>
          </div>
        </div>
        {basicPlan > 0 && (
          <div style={contentStyles} className="py-2">
            <div style={subheaderStyles}>
              <div className="w-full">
                <p>Active Basic plan : {basicPlan} User(s)</p>
              </div>
            </div>
          </div>
        )}

        {familyofTwo > 0 && (
          <div style={contentStyles} className="py-2">
            <div style={subheaderStyles}>
              <div className="w-full">
                <p>Active Family Of Two : {familyofTwo} User(s)</p>
              </div>
            </div>
          </div>
        )}

        {familyofFour > 0 && (
          <div style={contentStyles} className="py-2">
            <div style={subheaderStyles}>
              <div className="w-full">
                <p>Active Family Of Four : {familyofFour} User(s) </p>
              </div>
            </div>
          </div>
        )}

        {familyofSix > 0 && (
          <div style={contentStyles} className="py-2">
            <div style={subheaderStyles}>
              <div className="w-full">
                <p>Active Family Of Six : {familyofSix} User(s) </p>
              </div>
            </div>
          </div>
        )}

        {pBasicPlan > 0 && (
          <div style={contentStyles} className="py-2">
            <div style={subheaderStyles}>
              <div className="w-full">
                <p>Active Premium : {pBasicPlan} User(s) </p>
              </div>
            </div>
          </div>
        )}

        {pFamilyofTwo > 0 && (
          <div style={contentStyles} className="py-2">
            <div style={subheaderStyles}>
              <div className="w-full">
                <p>Active Family Of Two Premium : {pFamilyofTwo} User(s) </p>
              </div>
            </div>
          </div>
        )}

        {pFamilyofFour > 0 && (
          <div style={contentStyles} className="py-2">
            <div style={subheaderStyles}>
              <div className="w-full">
                <p>Active Family Of Four Premium : {pFamilyofFour} User(s) </p>
              </div>
            </div>
          </div>
        )}

        {pFamilyofSix > 0 && (
          <div style={contentStyles} className="py-2">
            <div style={subheaderStyles}>
              <div className="w-full">
                <p>Active Family Of Six Premium : {pFamilyofSix} User(s) </p>
              </div>
            </div>
          </div>
        )}

        {referralCode != "" && (
          <div style={contentStyles} className="py-2">
            <div style={subheaderStyles}>
              <div className="w-full">
                <p>Referral Code : {referralCode} </p>
              </div>
            </div>
          </div>
        )}

        {communityCode != "" && (
          <div style={contentStyles} className="py-2">
            <div style={subheaderStyles}>
              <div className="w-full">
                <p>Community Code : {communityCode} </p>
              </div>
            </div>
          </div>
        )}

        <div style={contentStyles} className="py-2">
          <div style={subheaderStyles}>
            <div className="w-full">
              <p>Turnover : {turnover} User(s) </p>
            </div>
          </div>
        </div>

        {total > 0 && (
          <div style={contentStyles} className="py-2">
            <div style={subheaderStyles}>
              <div className="flex flex-row w-full">
                <CustomIcons.CashIcon
                  width={20}
                  height={20}
                  viewBox={null}
                  svgColor={Colors.appMainColor}
                />
                <p className="ml-2">R{total} Price per month </p>
              </div>
            </div>
          </div>
        )}

        <button
          style={btnStyles}
          type="button"
          onClick={() => {
            setOpenNewCompanyUsersModal(true);
          }}
        >
          <div>Add new {title} Corporate Members</div>
        </button>
      </div>
      <Modal
        centered
        open={openNewCompanyUsersModal}
        onOk={() => setOpenNewCompanyUsersModal(false)}
        onCancel={() => setOpenNewCompanyUsersModal(false)}
        width={550}
        closeIcon={
          <CustomIcons.CloseIcon
            width={30}
            height={30}
            viewBox={null}
            svgColor="#1C274C"
          />
        }
        footer={null}
      >
        <div
          className="flex w-12 py-3 justify-content-start"
          style={modelTitle}
        >
          Add company users
        </div>

        <NewCompanyUsers
          onModalClose={() => {
            handleClose();
          }}
          onFormFinish={async (newCompanyMembers: ICompanyMembers) => {
            await handleAddCompanyMembers(newCompanyMembers);
          }}
          isLoading={addCompnayMembers.isLoading}
          isSuccess={addCompnayMembers.isSuccess}
        />
      </Modal>
      <Modal
        centered
        open={openEditCompanyModal}
        onOk={() => setOpenEditCompanyModal(false)}
        onCancel={() => setOpenEditCompanyModal(false)}
        width={550}
        closeIcon={
          <CustomIcons.CloseIcon
            width={30}
            height={30}
            viewBox={null}
            svgColor="#1C274C"
          />
        }
        footer={null}
      >
        <div
          className="flex w-12 py-3 justify-content-start"
          style={modelTitle}
        >
          Edit company
        </div>

        <EditCompany
          editCompanyValues={editCompany}
          onFormFinish={async (company: IEditCompany) => {
            await handleEditCompany(company);
          }}
          onModalClose={() => {
            handleClose();
          }}
          isLoading={editCompanyhook.isLoading}
          isSuccess={editCompanyhook.isSuccess}
        />
      </Modal>
      <Modal
        centered
        open={openCancelModal}
        onOk={() => setOpenCancelModal(false)}
        onCancel={() => setOpenCancelModal(false)}
        width={550}
        closeIcon={
          <CustomIcons.CloseIcon
            width={30}
            height={30}
            viewBox={null}
            svgColor="#1C274C"
          />
        }
        footer={null}
      >
        <div
          className="flex w-12 py-3 justify-content-start"
          style={modelTitle}
        >
          Schedule Cancellation
        </div>
        <Space direction="vertical" className="w-full mb-3">
          <DatePicker onChange={onChangeDate} className="w-1/2" />
        </Space>
        <div className="flex w-full gap-2 col-12 justify-content-end align-content-center">
          <Button
            type="primary"
            ghost
            onClick={handleClose}
            size="large"
            className="w-3"
          >
            Cancel
          </Button>
          <Button
            type="primary"
            size="large"
            className="w-3 bg-[#1757a2] shadow-none"
            onClick={handleScheduleCancelCompany}
          >
            Save
          </Button>
        </div>
      </Modal>
      <Modal
        centered
        open={openImmediateCancelModal}
        onOk={() => setOpenImmediateCancelModal(false)}
        onCancel={() => setOpenImmediateCancelModal(false)}
        width={550}
        closeIcon={
          <CustomIcons.CloseIcon
            width={30}
            height={30}
            viewBox={null}
            svgColor="#1C274C"
          />
        }
        footer={null}
      >
        <GenericDialog
          onClose={() => setOpenImmediateCancelModal(false)}
          onConfirm={handleImmediateCancelCompany}
          text={`immediately cancel the subscription for ${title}`}
        />
      </Modal>
      <Modal
        centered
        open={openRenewModal}
        onOk={() => setOpenRenewModal(false)}
        onCancel={() => setOpenRenewModal(false)}
        width={550}
        closeIcon={
          <CustomIcons.CloseIcon
            width={30}
            height={30}
            viewBox={null}
            svgColor="#1C274C"
          />
        }
        footer={null}
      >
        <div
          className="flex w-12 py-3 justify-content-start"
          style={modelTitle}
        >
          Renew Subscription
        </div>
        <RenewCompanySubscriptions
          onFormFinish={async (companyRenewal: ICompanyUserRenewal) => {
            await handleCompanyRenewal(companyRenewal);
          }}
          onModalClose={() => {
            handleClose();
          }}
          isLoading={renewcompanyUsers.isLoading}
          isSuccess={renewcompanyUsers.isSuccess}
        />
      </Modal>
    </>
  );
};

export default CompaniesCard;

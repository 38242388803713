import React from "react";
import InitialsAvatar from "../../widgets/Avatars/InitialsAvatar";
import { getInitials, getTimePostedCustom } from "../../../util/helpers";

type CommentProps = {
  comment: IComment;
};

function CommentCard({ comment }: CommentProps) {
  const { createdAt, content, firstName, lastName, profilePicture } = comment;
  return (
    <div className="flex flex-row items-center justify-start w-full h-full gap-2 py-1">
      <div className="pb-3" style={{ alignSelf: "flex-start" }}>
        <InitialsAvatar
          url={profilePicture}
          initials={getInitials(`${firstName} ${lastName}`)}
        />
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col rounded-olg bg-[#F5F5F5] w-full">
          <h5 className="px-3 py-1 mb-0 pl-3 text-sm text-[#1C274C] text-start">{`${firstName} ${lastName}`}</h5>
          <p
            className="px-3 text-mainTextColor text-start"
            style={{ fontSize: 10 }}
          >{`${content}`}</p>
        </div>
        <div className="px-2 text-xs text-mainTextColor text-start">
          {" "}
          {getTimePostedCustom(createdAt)}
        </div>
      </div>
    </div>
  );
}

export default React.memo(CommentCard);

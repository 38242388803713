import React from "react";
import { Avatar } from "primereact/avatar";
import { Badge } from "primereact/badge";
import Images from "../../../assets";
import "./UserAvatar.css";

interface IUserAvatarProps {
  user: User;
  showBadge?: boolean;
}

const UserAvatar: React.FC<IUserAvatarProps> = ({
  user,
  showBadge = false,
}) => {
  const getInitials = (firstName: string, lastName: string) => {
    const firstInitial = firstName.charAt(0);
    const lastInitial = lastName.charAt(0);
    return `${firstInitial}${lastInitial}`;
  };

  return (
    <div className="UserAvatar">
      {user.profilePicture && user.profilePicture?.length > 0 ? (
        <Avatar
          imageAlt="user avatar"
          className={`${showBadge ? "p-overlay-badge" : ""} mr-2`}
          image={user.profilePicture}
          imageFallback={Images?.userAvatar}
          shape="circle"
          size="large"
        >
          {showBadge ? <Badge /> : null}
        </Avatar>
      ) : (
        <Avatar
          label={getInitials(user.firstName, user.lastName)}
          className={`${showBadge ? "p-overlay-badge" : ""} mr-2`}
          shape="circle"
          size="large"
          style={{ borderRadius: "50%" }}
        >
          {showBadge ? <Badge /> : null}
        </Avatar>
      )}
    </div>
  );
};

export default UserAvatar;

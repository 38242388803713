import { CSSProperties } from "react";
import styled from "styled-components";
import Colors from "../../config/Colors";

export const WINDOW_WIDTH = window.innerWidth;
export const WINDOW_HEIGHT = window.innerHeight;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: WINDOW_WIDTH;
  height: WINDOW_HEIGHT;
  background: ${Colors.backgroundColor};
`;

export const BodyContainer = styled.div`
  justify-content: space-between;
  overflow-y: auto;
  width: 90%;
  max-height: 100vh;
`;

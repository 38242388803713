import React, { useState, useEffect } from "react";
import { Avatar } from "primereact/avatar";
import Images from "../../../assets";

type InitialsAvatarProps = {
  url: string | null;
  initials: string;
};

const InitialsAvatar: React.FC<InitialsAvatarProps> = ({ url, initials }) => {
  const [backgroundColor, setBackgroundColor] = useState("#");
  const [textColor, setTextColor] = useState("#");

  useEffect(() => {
    const getRandomColor = () => {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };

    const getContrastColor = (hexColor: string) => {
      const r = parseInt(hexColor.substr(1, 2), 16);
      const g = parseInt(hexColor.substr(3, 2), 16);
      const b = parseInt(hexColor.substr(5, 2), 16);
      const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
      const contrastRatio = (luminance + 0.05) / (1.0 - luminance + 0.05);
      return contrastRatio > 4.5 ? "#000000" : "#ffffff"; // WCAG AA level contrast ratio threshold
    };

    const bgColor = getRandomColor();
    setBackgroundColor(bgColor);
    setTextColor(getContrastColor(bgColor));
  }, []);

  return (
    <div className="InitialsAvatar">
      {url ? (
        <Avatar
          imageAlt="user avatar"
          image={url}
          imageFallback={Images?.userAvatar}
          shape="circle"
          size="large"
          style={{ borderRadius: "50%" }}
        />
      ) : (
        <Avatar
          label={initials}
          shape="circle"
          size="large"
          style={{ backgroundColor, color: textColor, borderRadius: "50%" }}
        />
      )}
    </div>
  );
};

export default InitialsAvatar;

/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
import { API } from "../config/axios";

class Emergencies {
  async getEmergencies(query?: string): Promise<ResponseFormat<IEmergency>> {
    const url = query ? `/Emergency?${query}` : "Emergency";
    const response = await API.get(url);

    let emergencies: IEmergency[] = [];
    let pagination: Pagination = null;

    if (response?.data) {
      emergencies = response.data;
    }

    if (response.headers["x-pagination"]) {
      pagination = JSON.parse(response.headers["x-pagination"]);
    }

    return { data: emergencies, pagination };
  }

  addEmergencies = async (
    body: IEmergency,
    emergencies: IEmergency[]
  ): Promise<ResponseFormat<IEmergency>> => {
    const response = await API.post("Emergency", body);

    emergencies.push(response.data as IEmergency);
    return { data: emergencies };
  };

  getAll = async (): Promise<IEmergency[]> => {
    const url = `Emergency`;
    const response = await API.get<IEmergency[]>(url);
    return response.data;
  };

  getEmergenciesList = async (
    filters: Filters | null,
    page: number | string | null | any = null
  ): Promise<IEmergency[]> => {
    // Define the base URL for the API call
    const url = `Emergency`;
    // Define the parameters for the API call
    const params: { [key: string]: any } = {};

    // Add filters to the parameters if provided
    if (filters) {
      params.filters = filters;
    }

    // Add pagination to the parameters if provided
    if (page) {
      params.page = page;
    }
    // Make the API call and return the response data
    const response = await API.get<IEmergency[]>(url, { params });
    return response.data;
  };

  getSearchEmergencies = async (
    filters: Filters | null,
    page: number | string | null | any = null,
    limit: number | null = null,
    search: string | null
  ): Promise<IEmergency[]> => {
    // Define the base URL for the API call
    const url = `Emergency`;
    // Define the parameters for the API call
    const params: { [key: string]: any } = {};

    // Add filters to the parameters if provided
    if (filters) {
      params.filters = filters;
    }

    // Add search to the parameters if provided
    if (search) {
      params.search = search;
    }

    // Add pagination to the parameters if provided
    if (page) {
      params.page = page;
    }

    if (limit) {
      params.limit = limit;
    }
    // Make the API call and return the response data
    const response = await API.get<IEmergency[]>(url, { params });
    return response.data;
  };

  addEmergency = async (body: IEmergency) => {
    return API.post("Emergency", body);
  };

  updateEmergencies = async (body: IEmergency): Promise<IEmergency> => {
    return API.put(`Emergency/${body.id}`, body);
  };

  updateEmergency = async (
    body: IEmergency,
    emergencies: IEmergency[]
  ): Promise<ResponseFormat<IEmergency>> => {
    emergencies = [...emergencies];

    await API.put(`Emergency/${body.id}`, body);

    const elementPos = emergencies.map((x) => x.id).indexOf(body.id);
    emergencies[elementPos!] = body;
    return { data: emergencies };
  };

  deleteEmergency = async (
    body: IEmergency,
    emergencies: IEmergency[]
  ): Promise<ResponseFormat<IEmergency>> => {
    await API.delete(`Emergency/${body.id}`);

    return { data: emergencies };
  };

  getById = async (id: string | number): Promise<IEmergency> => {
    const response = await API.get<IEmergency>(`Emergency/${id}`);
    return response.data;
  };
}

export default new Emergencies();

/* eslint-disable no-return-await */
/* eslint-disable no-useless-catch */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
import moment from "moment";
import { API, APILocal, APIStaging } from "../config/axios";

class Users {
  getUsers = async (): Promise<IUser[]> => {
    const response = await API.get("UserAuth/GetUserWithSubscriptions");
    return response.data;
  };

  getFilteredUsers = async (
    first: number = 0,
    rows: number = 10,
    filters: Filters
  ): Promise<IGetUsersResponse> => {
    const response = await API.get(
      `Admin_UserAuth/GetUsers?first=${first}&rows=${rows}&filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`
    );
    return response.data;
  };

  downloadPdfUsers = async (filters: Filters) => {
    const response = await API.get(
      `Admin_UserAuth/DownloadUsersPdf?filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`,
      { responseType: "blob" }
    );

    // Create a Blob from the PDF Stream
    const file = new Blob([response.data], { type: "application/pdf" });

    // Build a URL from the file
    const fileURL = URL.createObjectURL(file);

    const link = document.createElement("a");
    link.href = fileURL;
    link.setAttribute("download", `Users_${moment().format("YYYY-MM-DD")}.pdf`); // You can format this as needed
    document.body.appendChild(link);
    link.click();

    // Clean up
    URL.revokeObjectURL(fileURL);
    link.remove();
  };

  downloadExcelUsers = async (filters: Filters) => {
    try {
      const response = await API.get(
        `Admin_UserAuth/DownloadUsersExcel?filters=${encodeURIComponent(
          JSON.stringify(filters)
        )}`,
        { responseType: "blob" }
      );

      // Create a Blob from the PDF Stream
      const file = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Build a URL from the file
      const fileURL = URL.createObjectURL(file);

      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute(
        "download",
        `Users_${moment().format("YYYY-MM-DD")}.xlsx`
      ); // You can format this as needed
      document.body.appendChild(link);
      link.click();

      // Clean up
      URL.revokeObjectURL(fileURL);
      link.remove();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  getUsersList = async (
    filters: Filters | null,
    page: number | string | null | any = null
  ): Promise<IUser[]> => {
    const url = `GetUserWithSubscriptions`;
    const params: { [key: string]: any } = {};
    if (filters) {
      params.filters = filters;
    }
    if (page) {
      params.page = page;
    }
    // Make the API call and return the response data
    const response = await API.get<IUser[]>(url, { params });
    return response.data;
  };

  getSearchUsers = async (
    filters: Filters | null,
    page: number | string | null | any = null,
    limit: number | null = null,
    search: string | null
  ): Promise<IUser[]> => {
    const url = `GetUserWithSubscriptions`;
    const params: { [key: string]: any } = {};

    if (filters) {
      params.filters = filters;
    }
    if (search) {
      params.search = search;
    }
    if (page) {
      params.page = page;
    }

    if (limit) {
      params.limit = limit;
    }
    // Make the API call and return the response data
    const response = await API.get<IUser[]>(url, { params });
    return response.data;
  };

  async getUsers2(query?: string): Promise<ResponseFormat<IUser>> {
    const url = query
      ? `/UserAuth?${query}`
      : "UserAuth/GetUserWithSubscriptions";
    const response = await API.get(url);

    let users: IUser[] = [];
    let pagination: Pagination = null;

    if (response?.data) {
      users = response.data;
    }

    if (response.headers["x-pagination"]) {
      pagination = JSON.parse(response.headers["x-pagination"]);
    }

    return { data: users, pagination };
  }

  addUser = async (body: IAddNewUser): Promise<IUser> => {
    try {
      const response = await API.post("Admin_UserAuth/addUserFromPortal", body);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  addPractitioner = async (body: IAddPractitioner) => {
    const response = await API.post("UserAuth/PostPractitioner", body);

    try {
      if (response.data === 0) return { isSuccess: false };
      return { isSuccess: true, data: response.data };
    } catch (error) {
      return { isSuccess: false };
    }
  };

  updateUser = async (
    body: IUser,
    users: IUser[]
  ): Promise<ResponseFormat<IUser>> => {
    users = [...users];

    await API.put(`UserAuth/${body.id}`, body);

    const elementPos = users.map((x) => x.id).indexOf(body.id);
    users[elementPos!] = body;
    return { data: users };
  };

  updateAdmin = async (body: IUser): Promise<IUser> => {
    return await API.put(`UserAuth/${body.id}`, body);
  };

  editUser = async (body: IEditUser) => {
    const response = await API.put("Admin_UserAuth/EditUserFromPortal", body);

    try {
      if (response.data === 0) return { isSuccess: false };
      return { isSuccess: true, data: response.data };
    } catch (error) {
      return { isSuccess: false };
    }
  };

  deleteUser = async (
    body: IUser,
    users: IUser[]
  ): Promise<ResponseFormat<IUser>> => {
    await API.delete(`UserAuth/${body.id}`);

    return { data: users };
  };

  getById = async (id: string | number | null | undefined): Promise<IUser> => {
    const response = await API.get<IUser>(`Admin_UserAuth/${id}`);
    return response.data;
  };

  changePassword = async (body: IChangePassword): Promise<string> => {
    return await API.post(`Admin_UserAuth/changePassword`, body);
  };

  getUserChatHistory = async (
    userId: string | null
  ): Promise<IUserChatHistoryData> => {
    let params = "";
    if (userId) {
      params = `?userId=${userId}`;
    }
    const response = await API.get(`Admin_Users/getUsersChatHistory${params}`);
    return response.data;
  };

  getUserProfileDetails = async (
    userId: string | null
  ): Promise<IUserProfileDetail> => {
    const response = await API.get(
      `Admin_Users/getUserProfile?userId=${userId}`
    );
    return response.data;
  };

  userRenewal = async (body: IUserSubRenewal) => {
    try {
      const response = await API.put(
        "Admin_UserAuth/UpdateUserSubscription",
        body
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  userCompanyRenewal = async (body: IUserCompanyRenewal) => {
    try {
      const response = await API.put(
        "Admin_UserAuth/UpdateUserCompanySubscription",
        body
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}

export default new Users();

import React, {
  useState,
  useRef,
  useContext,
  useEffect,
  useCallback,
} from "react";
import HeaderComponent from "@/components/Header/Header";
import { tableBody, tableHeader } from "@/styles/TableStyles";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import {
  useBulkCreateNewCPFResponders,
  useGetCPFTableData,
  usePostCPFResponder,
} from "@/hooks/useCPFs";
import { Button as AButton, message, Modal, Tag } from "antd";
import { CustomIcons } from "@/assets";
import AddNewCPFResponder from "@/components/Forms/AddNewCPFResponder";
import moment from "moment";
import { ActionBodyText } from "@/styles/ContainerStyles";
import Colors from "@/config/Colors";
import { toast } from "sonner";
import Dragger from "antd/es/upload/Dragger";
import DaggerCard from "@/components/Cards/DaggerCard/DaggerCard";
import { Button } from "@/components/ui/button";
import { File } from "lucide-react";
import CPFDetailsTabs from "@/components/Forms/CPFDetailsTabs/CPFDetailsTabs";
import { RcFile, UploadFile, UploadProps } from "antd/es/upload";
import { uploadUrl } from "@/config/urls";
import AuthContext from "@/contexts/AuthContext";
import { modelTitle } from "../Emergencies/styles";
import { debounce } from "lodash";

const ResponderCPFs = () => {
  const { user } = useContext(AuthContext);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Default to 10 or your preferred starting page size
  const [first, setFirst] = useState(0);

  const { data, isLoading, refetch } = useGetCPFTableData(page, pageSize);

  // const debouncedRefetch = useRef(debounce(() => refetch(), 300)).current;

  const postCPFResponder = usePostCPFResponder();
  const templateUrl =
    "https://safercity.azureedge.net/safercityfiles/c0377088-c115-4cfd-bb2b-e4f27c3fb6ca-CPF_Resonder_Template.xlsx";
  const postBulkCPFResponders = useBulkCreateNewCPFResponders();
  const [openAddNewCPFModal, setOpenAddNewCPFModal] = useState(false);
  const [openUploadBulkExcel, setOpenUploadBulkExcel] = useState(false);
  const [openEditCPFModal, setOpenEditCPFModal] = useState(false);
  const [cpfTableId, setCPFTableId] = useState<number>();
  const [fileUrl, setFileUrl] = useState<string | null>();
  const [uploading, setUploading] = useState<boolean>();

  const beforeUpload = (file: RcFile) => {
    const isExcelFile =
      file.type === "application/vnd.ms-excel" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.oasis.opendocument.spreadsheet";

    if (!isExcelFile) {
      message.error("You can only upload Excel files!");
    }

    return isExcelFile;
  };

  const props: UploadProps = {
    name: "file",
    multiple: false,
    //The end point to upload the file
    action: uploadUrl,
    onChange(info: any) {
      const { status } = info.file;
      if (status === "uploading") {
        setUploading(true);
      }
      if (status === "done") {
        setFileUrl(info.file.response.path);
        setUploading(false);
        toast.success(`The Excel file has been uploaded successfully.`);
      } else if (status === "error") {
        toast.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e: any) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleExcelUpload = async (uploadParams: ICPFResponderExcelUpload) => {
    try {
      await postBulkCPFResponders.mutateAsync(uploadParams);
      toast.success("Successfully uploaded new CPF Responder");
      setOpenUploadBulkExcel(false);
    } catch (error: any) {
      // Improved error handling: Check if error is an instance of Error to safely access its message
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";

      // Provide a more user-friendly and specific error message
      toast.error(
        `Error uploading new CPF Responder. Please try again later. Details: ${errorMessage}`
      );

      // Log the error object for debugging purposes
      console.error("Error uploading new CPF Responder", error);
    } finally {
      refetch();
    }
  };

  const handleAddNewCPFResponder = async (newCPFUser: ICPFResponder) => {
    try {
      await postCPFResponder.mutateAsync(newCPFUser);
      toast.success("Successfully added new CPF Responder");
      refetch();
    } catch (error) {
      toast.error("Error adding new CPF Responder. Please try again later.");
      console.log("Error adding new CPF Responder", error);
    }
  };
  const handleClose = async () => {
    setOpenAddNewCPFModal(false);
    setOpenUploadBulkExcel(false);
    postCPFResponder.reset();
    postBulkCPFResponders.reset();
  };

  const handleCloseEditModal = () => {
    setOpenEditCPFModal(false);
  };

  const onPage = (event: any) => {
    setFirst(event.first);
    setPage(event.page + 1);
    // setPageSize(event.rows);
  };

  const getSeverity = (status: string) => {
    switch (status) {
      case "Active":
        return "#008000";
      case "Suspended":
        return "#FF0000";
      default:
        return "#FF0000";
    }
  };

  const dateBodyTemplate = (rowData: ICPFResponderTable) => {
    return moment(rowData.dateAdded).format("YYYY-MM-DD");
  };

  const statusBodyTemplate = (rowData: ICPFResponderTable) => {
    return <Tag color={getSeverity(rowData.status)}>{rowData.status}</Tag>;
  };

  const viewMoreBodyTemplate = (rowData: ICPFResponderTable) => {
    return (
      <ActionBodyText
        onClick={() => {
          setOpenEditCPFModal(!openEditCPFModal);
          setCPFTableId(rowData.id);
        }}
        className="cursor-pointer"
      >
        View More
      </ActionBodyText>
    );
  };

  //Set ICPFResponderExcelUpload values
  const excelParams: ICPFResponderExcelUpload = {
    url: fileUrl as string,
    uploadBy: user?.id as number,
  };

  return (
    <div className="flex w-full h-full bg-backgroundColor">
      <div className="w-full h-full max-h-screen overflow-y-auto disable-scrollbars">
        <HeaderComponent title="CPF" />
        {isLoading ? (
          <div className="flex h-full flex-column justify-content-center align-items-center">
            <CustomIcons.EOSThreeDotsLoading
              width={80}
              height={80}
              viewBox={null}
              svgColor={Colors.appMainColor}
            />
          </div>
        ) : (
          <div className="w-full h-full pr-5">
            <div className="flex flex-row justify-end w-full gap-1 mb-2 align-items-center">
              <AButton
                type="primary"
                ghost
                size="large"
                className="w-2"
                onClick={() => setOpenAddNewCPFModal(!openAddNewCPFModal)}
              >
                +Add new CPF Responder
              </AButton>
            </div>
            <DataTable
              value={data?.data || []}
              totalRecords={data?.totalCount || 0}
              paginator
              first={first}
              rows={pageSize}
              onPage={onPage}
              // rowsPerPageOptions={[10, 25, 50, 100]}
              lazy
              dataKey="id"
              emptyMessage="No CPFs found."
              rowHover
              scrollable
              scrollHeight="40rem"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Users"
              stateStorage="session"
              stateKey="dt-state-cpf-responders-table"
              className="w-full mx-3"
            >
              <Column
                field="dateAdded"
                header="Date Added"
                headerStyle={tableHeader}
                dataType="date"
                bodyStyle={tableBody}
                style={{ background: "#FBFBFB" }}
                body={dateBodyTemplate}
              />
              <Column
                field="firstName"
                header="First name"
                headerStyle={tableHeader}
                style={{ background: "#FBFBFB" }}
                bodyStyle={tableBody}
              />
              <Column
                field="lastName"
                header="Last name"
                headerStyle={tableHeader}
                style={{ background: "#FBFBFB" }}
                bodyStyle={tableBody}
              />
              <Column
                field="email"
                header="Email"
                headerStyle={tableHeader}
                style={{ background: "#FBFBFB" }}
                bodyStyle={tableBody}
              />
              <Column
                field="area"
                header="Area"
                headerStyle={tableHeader}
                style={{ background: "#FBFBFB" }}
                bodyStyle={tableBody}
              />
              <Column
                field="is"
                header="Status"
                headerStyle={tableHeader}
                style={{ background: "#FBFBFB" }}
                filterMenuStyle={{ width: "14rem" }}
                body={statusBodyTemplate}
              />

              <Column
                headerStyle={tableHeader}
                style={{ background: "#FBFBFB" }}
                bodyStyle={tableBody}
                body={viewMoreBodyTemplate}
              />
            </DataTable>
          </div>
        )}
      </div>
      <Modal
        centered
        open={openAddNewCPFModal}
        onOk={() => setOpenAddNewCPFModal(false)}
        onCancel={() => setOpenAddNewCPFModal(false)}
        width={550}
        closeIcon={
          <CustomIcons.CloseIcon
            width={30}
            height={30}
            viewBox={null}
            svgColor="#000"
          />
        }
        footer={null}
      >
        <div className="w-full pt-2 mx-2">
          <>
            <div className="flex items-center justify-start w-full gap-3 py-1">
              <div
                className="flex w-12 justify-content-start"
                style={modelTitle}
              >
                +Add CPF Responder
              </div>
            </div>
            <div className="flex justify-end py-2">
              <AButton
                type="primary"
                ghost
                size="large"
                onClick={() => {
                  setOpenUploadBulkExcel(true);
                  setOpenAddNewCPFModal(false);
                }}
              >
                Upload Excel Document
              </AButton>
            </div>

            <AddNewCPFResponder
              onFormFinish={async (cpfResponder: ICPFResponder) =>
                handleAddNewCPFResponder(cpfResponder)
              }
              onModalClose={() => setOpenAddNewCPFModal(false)}
              isLoading={postCPFResponder.isLoading}
              isSuccess={postCPFResponder.isSuccess}
            />
          </>
        </div>
      </Modal>
      <Modal
        centered
        open={openUploadBulkExcel}
        onOk={() => setOpenUploadBulkExcel(false)}
        onCancel={() => setOpenUploadBulkExcel(false)}
        width={550}
        closeIcon={
          <CustomIcons.CloseIcon
            width={30}
            height={30}
            viewBox={null}
            svgColor="#1C274sC"
          />
        }
        footer={null}
      >
        <div className="w-full h-full animate-distance-full animate-fadeInRight animate-duration-[0.31s]">
          <div className="flex flex-col items-center justify-center gap-1 my-4">
            <span className="text-sm text-mainTextColor">
              1. Download spreadsheet
            </span>
            <Button variant="link" className="text-appMainColor" asChild>
              <a href={templateUrl} download={`CPFResponder2_template.xlsx`}>
                <File className="w-4 h-4 mr-1" />
                Download Template
              </a>
            </Button>
            <span className="text-sm text-mainTextColor">2. Upload</span>
            <span className="text-xs text-mainTextColor">
              Fill and Submit the excel document
            </span>
          </div>
          <Dragger
            {...props}
            beforeUpload={beforeUpload}
            className="w-full"
            style={{ background: "transparent" }}
          >
            <DaggerCard description="File should be .xlsx" />
          </Dragger>
          <div className="flex w-full gap-2 my-4 col-12 justify-content-end align-content-center">
            <AButton
              type="primary"
              ghost
              onClick={handleClose}
              size="large"
              className="w-3"
            >
              Cancel
            </AButton>
            <AButton
              type="primary"
              htmlType="button"
              size="large"
              className="w-3 bg-[#1757a2] shadow-none"
              disabled={uploading}
              onClick={() => handleExcelUpload(excelParams)}
              loading={postBulkCPFResponders.isLoading}
            >
              Save
            </AButton>
          </div>
        </div>
      </Modal>
      <Modal
        centered
        open={openEditCPFModal}
        onOk={() => setOpenEditCPFModal(false)}
        onCancel={() => setOpenEditCPFModal(false)}
        width={550}
        closeIcon={
          <CustomIcons.CloseIcon
            width={30}
            height={30}
            viewBox={null}
            svgColor="#000"
          />
        }
        footer={null}
      >
        <div className="w-full pt-2 mx-2">
          <CPFDetailsTabs
            cpfTableId={cpfTableId as number}
            onModalClose={handleCloseEditModal}
            refetch={() => refetch()}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ResponderCPFs;

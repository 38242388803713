import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Divider, App } from "antd";
import { DateTime } from "luxon";
import UserAvatar from "../widgets/Avatars/UserAvatar";
import {
  Dispatcher,
  isFirstMessageOfDay,
  decodeMessage,
  encodeMessage,
} from "../../util/helpers";
import {
  useGetCommunityCaseMessages,
  usePostCommunityMessage,
  useResolveCommunityCase,
} from "../../hooks/useCommunityMessage";
import { CommunityMessageType } from "../../util/enums";
import { CustomIcons } from "../../assets";
import Colors from "../../config/Colors";
import AuthContext from "../../contexts/AuthContext";
import { copyObjects } from "../../util/copyObjects";
import CustomInputField from "../widgets/Inputs/CustomInputField";
import CommunityMessageCard from "../Cards/CommunityMessageCard/CommunityMessageCard";

type CommunityMessageProps = {
  currentCase: ICommunityAdminCase;
  setCurrentCase: Dispatcher<ICommunityAdminCase | null>;
};

function CommunityMessages({
  currentCase,
  setCurrentCase,
}: CommunityMessageProps) {
  const { user } = useContext(AuthContext);
  const currentOperatorId = user?.id;
  const endOfMessagesRef = useRef<HTMLDivElement>(null);
  const {
    data: Messages,
    isLoading,
    refetch,
  } = useGetCommunityCaseMessages(currentCase.id);
  const postMessage = usePostCommunityMessage();
  const resolveCase = useResolveCommunityCase();

  const [newMessage, setNewMessage] = useState("");
  const { message } = App.useApp();

  useEffect(() => {
    if (currentCase.id) {
      refetch();
    }
  }, [currentCase]);

  const getUniqueOperatorCount = (messages: ICommunityMessage[]) => {
    const uniqueOperators = new Set();
    messages.forEach((message: ICommunityMessage) => {
      uniqueOperators.add(message.handlingCommunityAdminId);
    });
    return uniqueOperators.size;
  };
  const getUniqueOperators = (messages: ICommunityMessage[]) => {
    const uniqueOperators = new Set<number>();
    messages.forEach((message: ICommunityMessage) => {
      uniqueOperators.add(message.handlingCommunityAdminId);
    });
    return Array.from(uniqueOperators);
  };

  useEffect(() => {
    // Scroll into view when messages change
    if (endOfMessagesRef.current) {
      endOfMessagesRef.current.scrollIntoView({ behavior: "smooth" });
    }
    // endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [Messages]);

  const operatorCount = getUniqueOperatorCount(Messages || []);
  const operators = getUniqueOperators(Messages || []);

  if (isLoading) {
    return (
      <div className="flex items-center self-center justify-center flex-1 h-5/6">
        <CustomIcons.EOSloading
          width={60}
          height={60}
          svgColor={Colors.appMainColor}
        />
      </div>
    );
  }
  const customChatInputTheme = {
    token: {
      colorPrimaryHover: `${Colors.appMainColor}`,
      colorBgContainer: "#FFFFFF",
      lineWidth: 1,
      colorBorder: " #9B9B9B",
      borderRadius: 12,
    },
  };

  function handleInputChange(event: string) {
    setNewMessage(event);
  }

  async function handleSendClick() {
    if (newMessage.length > 1) {
      const storeMessage = newMessage;
      setNewMessage("");
      const messageData: ICommunityMessage = copyObjects(
        {},
        {
          id: 0,
          communityCaseId: currentCase.id,
          messageText: encodeMessage(newMessage),
          senderId: user?.id as number,
          senderType: CommunityMessageType.Operator,
          receiverId: currentCase.user.id as number,
          handlingCommunityAdminId: user?.id as number,
          receiverType: CommunityMessageType.User,
          createdAt: DateTime.utc().toString(),
          updatedAt: DateTime.utc().toString(),
        }
      );

      try {
        await postMessage.mutateAsync(messageData);
        // setCanBulkEdit(true);
        // await loadMessageByCase.refetch();
      } catch (error) {
        setNewMessage(storeMessage);
        console.error(error);
      }
    } else {
      message.error("Please enter something!");
    }
  }

  async function handleResolveCase() {
    try {
      message.loading("Resolving case...");
      await resolveCase.mutateAsync(currentCase.id);
      setCurrentCase(null);
      message.success("Resolved case.");
    } catch (error) {
      message.destroy();
      console.error(error);
    }
  }

  return (
    <div className="flex flex-col w-full h-full">
      {/* User Component Here */}
      <div className="flex flex-row items-center justify-between w-full gap-2 pt-2 px-3 bg-[#F4F9FD]">
        {currentCase && (
          <UserAvatar user={currentCase?.user} showBadge={false} />
        )}

        <div className="flex-1 text-base text-left text-black">
          {`${currentCase?.user.firstName} ${currentCase?.user.lastName}`}
        </div>
        {/* <Button
          type="primary"
          size="large"
          className="w-2 bg-[#1757a2] shadow-none"
          loading={resolveCase.isLoading}
          onClick={() => handleResolveCase()}
        >
          Resolve Case
        </Button> */}
      </div>
      {/* Chat Messages Component Here */}
      <div className="flex-col flex-1 p-3 space-y-2 overflow-y-auto disable-scrollbars">
        {Messages?.map((message, index) => (
          <React.Fragment key={message.id}>
            {isFirstMessageOfDay(index, Messages) && (
              <Divider plain>
                {DateTime.fromISO(message.createdAt).toRelativeCalendar() ?? ""}
              </Divider>
            )}
            <CommunityMessageCard
              message={message}
              isUser={message.senderType === CommunityMessageType.User}
              operators={operators}
              currentOperatorId={currentOperatorId || 0}
            />
          </React.Fragment>
        ))}
        <div ref={endOfMessagesRef} />
      </div>
      {/* Input Component Here */}
      <div className="flex flex-row items-center justify-between gap-2 mb-1">
        <CustomInputField
          value={newMessage}
          onChange={(e: string) => handleInputChange(e)}
          onPressEnter={() => handleSendClick()}
          inputProps={{
            size: "large",
            placeholder: "Type your message here...",
            allowClear: false,
            className: "ml-2 w-11",
            prefix: <span />,
          }}
          theme={customChatInputTheme}
          mergeThemes
        />

        <Button
          type="primary"
          size="large"
          className="w-1 bg-[#1757a2] shadow-none"
          onClick={() => handleSendClick()}
          loading={postMessage.isLoading || resolveCase.isLoading}
        >
          Send
        </Button>
      </div>
    </div>
  );
}

export default CommunityMessages;

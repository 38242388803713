import React, { useState } from "react";
import { Tabs, message } from "antd";
import type { TabsProps } from "antd";
import Post from "./Post";
import PostSettings from "./PostSettings";
import { usePostToCommunity } from "../../../../hooks/useCommunities";

type CommunityTabProps = {
  communityId: number | null;
  userId: number | undefined;
  setOpenCommunityPostModal: (open: boolean) => void;
  refreshPage: () => void;
};

const CommunityPostTabs = ({
  communityId,
  userId,
  setOpenCommunityPostModal,
  refreshPage,
}: CommunityTabProps) => {
  const [nextTab, setNextTab] = useState(true);
  const [activeKey, setActiveKey] = useState<string>("1");
  const [title, setTitle] = useState<string | undefined>();
  const [content, setContent] = useState<string | undefined>();
  const [tags, setTags] = useState<string | undefined>();
  const [mediaPath, setMediaPath] = useState<string | undefined>();
  const postToCommunity = usePostToCommunity();

  const handleClose = async () => {
    setOpenCommunityPostModal(false);
  };

  const handleNextTab = () => {
    const nextTab = (parseInt(activeKey) % 3) + 1; // Assuming 3 tabs
    setActiveKey(nextTab.toString());
  };
  var postCommunity: ICommunityPost;
  const handleAddCommunityPost = async (post: ICommunityPost) => {
    handleNextTab();
    setTitle(post.title);
    setContent(post.content);
    setTags(post.tags);
    setMediaPath(post.mediaPath);
  };
  const handleAddCommunityPostSettings = async (
    settings: ICommunitySettngs
  ) => {
    let post: IPostContent = {
      userId: userId,
      communityId: communityId,
      isCommentable: settings.isCommentable,
      content: content,
      tags: tags,
      type: settings.type,
      mediaPath: mediaPath,
      title: title,
    };

    if (post) {
      try {
        await postToCommunity.mutateAsync(post);
        message.success("Post has been successfully uploaded");
        handleClose();
        refreshPage();
      } catch (error) {
        console.error(error);
      }
    } else {
      message.error("Please ensure all fields are filed correctly");
    }
  };
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Post",
      children: (
        <Post
          onFormFinish={async (communityPost: ICommunityPost) => {
            await handleAddCommunityPost(communityPost);
          }}
        />
      ),
    },
    {
      key: "2",
      label: "Post Settings",
      children: (
        <PostSettings
          onFormFinish={(communityPostSettings: ICommunitySettngs) => {
            handleAddCommunityPostSettings(communityPostSettings);
          }}
          onModalClose={() => handleClose()}
          isLoading={postToCommunity.isLoading}
          isSuccess={postToCommunity.isSuccess}
        />
      ),
      disabled: nextTab,
    },
  ];
  return (
    <Tabs
      defaultActiveKey="1"
      activeKey={activeKey}
      items={items}
      onChange={(key) => setActiveKey(key)}
    />
  );
};

export default CommunityPostTabs;

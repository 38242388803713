import React, { useEffect, useState } from "react";
import { Button, Form, Input } from "antd";
import MultipleSelector, { Option } from "@/components/ui/multiple-selector";

type CommunityCodeProps = {
  countriesData: ICountry[];
  onFormFinish: (code: IPostCommunityCodeHandler) => void;
  onModelClose: () => void;
  isLoading: boolean;
  isSuccess: boolean;
};

const NewCommunityCodeForm = ({
  countriesData,
  onFormFinish,
  onModelClose,
  isLoading,
  isSuccess,
}: CommunityCodeProps) => {
  const [form] = Form.useForm();
  const [seledtedCounty, setSelectedCountry] = useState<Option | null>(null);

  const countryOptions: Option[] =
    countriesData?.map((c) => ({ label: c.name, value: c.name })) || [];

  const handleCountryChange = (value: any) => {
    console.log("Value", value);
    setSelectedCountry(value[0].value);
  };

  const onFinish = async (formValues: any) => {
    const values: IPostCommunityCodeHandler = {
      communityCode: formValues.communityCode,
      country: formValues.country[0].value,
      province: formValues.province[0].value,
    };
    onFormFinish(values);
  };

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
    }
  }, [isSuccess]);

  const handleClose = () => {
    form.resetFields();
    onModelClose();
  };

  return (
    <Form
      name="newReferral"
      layout="vertical"
      form={form}
      onFinish={onFinish}
      className="grid"
    >
      <div className="col-12">
        <Form.Item
          name="communityCode"
          label="Add New Code"
          rules={[{ required: true, message: "Please enter community code!" }]}
          className="mb-1"
        >
          <Input size="large" placeholder="Enter Code" className="mb-2" />
        </Form.Item>
      </div>
      <div className="col-12 md:col-6">
        <Form.Item
          name="country"
          label="Country"
          rules={[
            { required: true, message: "Please input community code country" },
          ]}
          className="mb-1"
        >
          <MultipleSelector
            value={seledtedCounty ? [seledtedCounty] : []}
            onChange={(e: any) => handleCountryChange(e)}
            defaultOptions={countryOptions}
            hidePlaceholderWhenSelected={true}
            creatable
            maxSelected={1}
            placeholder="Enter or select a crime category"
          />
        </Form.Item>
      </div>

      <div className="col-12 md:col-6">
        <Form.Item
          name="province"
          label="Province"
          rules={[
            {
              required: true,
              message: "Please input community code Province ",
            },
          ]}
          className="mb-1"
        >
          <MultipleSelector
            hidePlaceholderWhenSelected={true}
            placeholder="Select a province or enter a new province"
            creatable
            maxSelected={1}
          />
        </Form.Item>
      </div>
      <Form.Item className="w-12 mb-1">
        <div className="flex w-full gap-2 col-12 justify-content-end align-content-center">
          <Button
            type="primary"
            ghost
            onClick={handleClose}
            size="large"
            className="w-3"
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            size="large"
            className="w-3 bg-[#1757a2] shadow-none"
          >
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default NewCommunityCodeForm;

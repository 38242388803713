import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import { queries } from "../services/queries";
import { Users } from "../services";

export function useUsers(): UseQueryResult<IUser[]> {
  return useQuery({
    ...queries.users.all,
    queryFn: () => Users.getUsers(),
    refetchOnWindowFocus: false,
  });
}

export function useGetFilteredUsers(
  first: number = 0,
  rows: number = 10,
  filters: Filters = {}
): UseQueryResult<IGetUsersResponse> {
  return useQuery({
    ...queries.users.getFilteredUsers(first, rows, filters),
    refetchOnWindowFocus: false,
  });
}

export function useUserDetail(id: string) {
  return useQuery({ ...queries.users.detail(id), refetchOnWindowFocus: false });
}

export function useUserProfile(id: string) {
  return useQuery({
    ...queries.users.getUserProfile(id),
    refetchOnWindowFocus: false,
  });
}

export const useFetchUser = (userId: string | number | null | undefined) => {
  return useQuery(["userData", userId], () => Users.getById(userId), {
    enabled: !!userId,
    retry: false, // only runs once
    refetchOnWindowFocus: false,
  });
};

export function useUserList(filter: Filters) {
  return useQuery({
    ...queries.users.list(filter),
    refetchOnWindowFocus: false,
  });
}

export function useSearchUser(filters: Filters, query: string, limit = 15) {
  return useQuery({
    ...queries.users.list(filters)._ctx.search(query, limit),
    enabled: Boolean(query),
    refetchOnWindowFocus: false,
  });
}

export function useUserChatHistory(userId: string | null) {
  return useQuery({
    ...queries.users.getUserChatHistory(userId || ""),
    enabled: !!userId,
    refetchOnWindowFocus: false,
  });
}

export function useAddUser() {
  const queryClient = useQueryClient();

  return useMutation(Users.addUser, {
    // onMutate: async (newUser) => {
    //   // get previous users data
    //   const previousUsersData = queryClient.getQueryData(
    //     queries.users.all.queryKey
    //   );

    //   // optimistic update
    //   queryClient.setQueryData(
    //     queries.users.all.queryKey,
    //     (oldUsersData: IAddNewUser[] = []) => {
    //       return [...oldUsersData, newUser];
    //     }
    //   );

    //   return { previousUsersData };
    // },
    // onError: (err: AxiosError, newUser, context) => {
    //   // rollback to the previous value if mutation fails
    //   if (context) {
    //     queryClient.setQueryData(
    //       queries.users.all.queryKey,
    //       context.previousUsersData
    //     );
    //   }
    // },
    onSuccess: (newUser) => {
      queryClient.setQueriesData(
        queries.users.detail(newUser.id as number).queryKey,
        newUser
      );
      queryClient.invalidateQueries({
        queryKey: queries.users._def,
      });
    },
    meta: {
      conflict: "Phone Number or Email Address Already Exists",
      badRequest: "Please make sure all fields are entered correctly.",
    },
  });
}

export function useEditUser() {
  const queryClient = useQueryClient();

  return useMutation(Users.editUser, {
    onMutate: async (newUser) => {
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: queries.users.all.queryKey });
      // get previous users data
      const previousUsersData = queryClient.getQueryData(
        queries.users.all.queryKey
      );

      // optimistic update
      queryClient.setQueryData(
        queries.users.all.queryKey,
        (oldUsersData: IEditUser[] | undefined) => {
          if (oldUsersData)
            return oldUsersData.map((user) =>
              user.id === newUser.id
                ? { ...user, ...newUser } // merge new user data with old user data
                : user
            );
        }
      );

      return { previousUsersData };
    },
    onError: (err, newUser, context) => {
      // rollback to the previous value if mutation fails
      if (context) {
        queryClient.setQueryData(
          queries.users.all.queryKey,
          context.previousUsersData
        );
      }
    },
    onSuccess: (editUser, variables) => {
      queryClient.setQueriesData(
        queries.users.detail(variables.id).queryKey,
        editUser
      );
      queryClient.invalidateQueries({
        queryKey: queries.users.all.queryKey,
      });
    },
    meta: {
      conflict: "Found whilst making changes",
      badRequest: "Please make sure all fields are entered correctly.",
    },
  });
}

export function useUpdateUser() {
  const queryClient = useQueryClient();

  return useMutation(Users.updateAdmin, {
    onSuccess: (editUser, variables) => {
      queryClient.setQueriesData(
        queries.users.detail(variables.id).queryKey,
        editUser
      );
      queryClient.invalidateQueries({
        queryKey: queries.users.all.queryKey,
      });
    },
    meta: {
      conflict: "Found whilst making changes",
      badRequest: "Please make sure all fields are entered correctly.",
    },
  });
}

export function useChangePassword() {
  const queryClient = useQueryClient();

  return useMutation(Users.changePassword, {
    meta: {
      conflict: "New password can't be the same as old password",
      badRequest: "Please make you have entered the correct old password.",
    },
  });
}

export function useUserSubRenewal() {
  const queryClient = useQueryClient();

  return useMutation(Users.userRenewal, {
    meta: {
      conflict: "Errors found whilst making changes",
      badRequest: "Please make sure all fields are entered correctly.",
      notFound: "User could not be found. Please try again",
    },
  });
}

export function useUserCompanySubRenewal() {
  const queryClient = useQueryClient();

  return useMutation(Users.userCompanyRenewal, {
    meta: {
      conflict: "Errors found whilst making changes",
      badRequest: "Please make sure all fields are entered correctly.",
      notFound: "User could not be found. Please try again",
    },
  });
}

import { CSSProperties } from "react";
import Colors from "../../../config/Colors";

export const cardTitleStyle: CSSProperties = {
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "24px",
  textAlign: "center",
  color: Colors.mainTextColor,
};

export const numberStyle: CSSProperties = {
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "30px",
  textAlign: "center",
  //lineHeight: "45px",
  color: Colors.appMainColor,
};

/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-unused-vars */
import {
  Button,
  DatePicker,
  Form,
  FormInstance,
  Input,
  message,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { en, faker } from "@faker-js/faker";
import { copyObjects } from "../../util/copyObjects";
import { useGetCompanies } from "../../hooks/useCompanies";

type NewUserFormProp = {
  onFormFinish: (user: IUser) => void;
  onModelClose: () => void;
  isLoading: boolean;
  isSuccess: boolean;
};

const NewUserForm = ({
  onFormFinish,
  onModelClose,
  isLoading,
  isSuccess,
}: NewUserFormProp) => {
  const [form] = Form.useForm();
  const [optionCompany, setOptionCompany] = useState<string>();
  const [subId, setSubId] = useState<number>();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();

  const handleDateChange = (dates: any, dateString: [string, string]) => {
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
  };

  const handleChangeCompany = (value: string) => {
    setOptionCompany(value);
  };

  const handleSubChange = (value: number) => {
    setSubId(value);
  };

  const { RangePicker } = DatePicker;

  const onFinish = async (formValues: any) => {
    const values: IUser = copyObjects(formValues, {
      userTypeId: 5,
      password: faker.internet.password(),
      userCode: faker.string.alphanumeric(4),
    });
    // console.log(values);
    //await onFormFinish(values);
  };

  const { data } = useGetCompanies();

  const handleClose = () => {
    form.resetFields();
    setOptionCompany("");
    onModelClose();
  };

  const onFinishFailed = (errorInfo: any) => {
    message.error("Could not create user, please try again.");
  };

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
    }
  }, [isSuccess]);

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Select.Option value="+27">+27</Select.Option>
      </Select>
    </Form.Item>
  );

  return (
    <Form
      form={form}
      name="newUser"
      onFinish={onFinish}
      layout="vertical"
      style={{ width: "100%" }}
      className="grid"
    >
      <div className="col-12 md:col-6">
        <Form.Item
          name="firstName"
          label="First Name"
          rules={[
            { required: true, message: "Please input the user's first name!" },
          ]}
          className="mb-1"
        >
          <Input size="large" placeholder="Amanda" />
        </Form.Item>
      </div>

      <div className="col-12 md:col-6">
        <Form.Item
          name="lastName"
          label="Last Name"
          rules={[
            { required: true, message: "Please input the user's last name!" },
          ]}
          className="mb-1"
        >
          <Input size="large" placeholder="Neli" />
        </Form.Item>
      </div>

      <div className="col-12 md:col-6">
        <Form.Item
          name="emailAddress"
          label="Email"
          rules={[
            { required: true, message: "Please input the user's email!" },
            { type: "email", message: "Please input a valid email!" },
          ]}
          className="mb-1"
        >
          <Input size="large" placeholder="Amando@gmail.com" />
        </Form.Item>
      </div>

      <div className="col-12 md:col-6">
        <Form.Item
          name="phoneNumber"
          label="Phone Number"
          rules={[
            {
              required: true,
              message: "Please enter user's phone number!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                //const phoneNumberPattern = /^(\+27|0)[6-8][0-9]{8}$/;
                const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;
                if (phoneNumberPattern.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Please enter a valid phoneNumber!")
                );
              },
            }),
          ]}
          className="mb-1"
        >
          <Input size="large" placeholder="+2763256392" />
        </Form.Item>
      </div>

      <div className="col-12 md:col-6">
        <Form.Item
          name="dateOfBirth"
          label="Date of birth"
          rules={[
            { required: true, message: "Please enter user's date of birth!" },
          ]}
          className="mb-3"
        >
          <DatePicker size="large" style={{ width: "100%" }} />
        </Form.Item>
      </div>

      <div className="col-12 md:col-6">
        <Form.Item
          name="gender"
          label="Gender"
          rules={[
            { required: true, message: "Please select the user's gender!" },
          ]}
          className="mb-3"
        >
          <Select placeholder="Select user's gender" size="large">
            <Select.Option value="Male">Male</Select.Option>
            <Select.Option value="Female">Female</Select.Option>
            <Select.Option value="Other">Other</Select.Option>
          </Select>
        </Form.Item>
      </div>
      <Form.Item className="w-12 mb-1">
        <div className="flex w-full gap-2 col-12 justify-content-end align-content-center">
          <Button
            type="primary"
            ghost
            onClick={handleClose}
            size="large"
            className="w-3"
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            size="large"
            className="w-3 bg-[#1757a2] shadow-none"
          >
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default NewUserForm;

/* eslint-disable no-unused-vars */
/* eslint-disable lines-between-class-members */
/* eslint-disable no-var */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
import { API, APILocal, APIStaging } from "../config/axios";

class Carousel {
  getCarouselData = async (): Promise<ICarouselData[]> => {
    const url = `Admin_Carousel`;
    const response = await API.get<ICarouselData[]>(url);
    return response.data;
  };

  addCarousel = async (body: IAddCarouselData) => {
    try {
      const response = await API.post(`Admin_Carousel`, body);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  editCarousel = async (
    editedCarousel: IEditCarouselData
  ): Promise<IEditCarouselData> => {
    try {
      const response = await API.put(
        `Admin_Carousel/${editedCarousel.id}`,
        editedCarousel
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  getSelectedCarousel = async (
    id: number | null | string
  ): Promise<IEditCarouselData> => {
    if (id) {
      let url = `Admin_Carousel/${id}`;
      const response = await API.get<IEditCarouselData>(url);
      return response.data;
    }
    return Promise.reject<IEditCarouselData>("Carousal Id is required");
  };
}

export default new Carousel();

import React, { useEffect, useRef, useState } from "react";
import HeaderComponent from "../../components/Header/Header";
import { message, Spin, Tag } from "antd";
import CustomInputField from "../../components/widgets/Inputs/CustomInputField";
import { SearchOutlined } from "@ant-design/icons";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { tableBody, tableHeader } from "../../styles/TableStyles";
import { useGetCompanyMemberData } from "../../hooks/useCompanies";
import { useParams } from "react-router-dom";
import BackButton from "../../components/widgets/common/BackButton";
import moment from "moment";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Calendar, CalendarChangeEvent } from "primereact/calendar";
import Colors from "@/config/Colors";
import DeleteButton from "@/components/widgets/Buttons/DeleteButton";
import DeleteModal from "@/components/Modals/DeleteModal";
import Companies from "@/services/Companies";
import { toast } from "sonner";
import CustomPaginator from "@/components/widgets/Paginator/TablePaginator";
import debounce from "lodash/debounce";

type CompanyParams = {
  id: string | undefined;
};

const CompanyMembers = () => {
  // @ts-ignore
  const dt = useRef<DataTable>(null);
  const { id } = useParams<CompanyParams>();
  const [filters, setFilters] = useState<DataTableFilterMeta | undefined>(
    undefined
  );
  const [nextPage, setNextPage] = useState<number>(0); // Start from 0 for zero-based indexing
  const [searchCompanyMember, setSearchCompanyMember] = useState<string | null>(
    ""
  );

  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const [globalFilterSearch, setGlobalFilterSearch] = useState<string>("");

  const limit = 10;

  const { data, isFetching, isLoading, refetch } = useGetCompanyMemberData(
    id as string,
    nextPage + 1,
    limit,
    globalFilterValue
  );

  const debounceSetGlobalFilterValue = debounce((value) => {
    setGlobalFilterValue(value);
    setNextPage(0); // Reset to first page
    refetch();
  }, 500);

  const handlePageChange = (e: {
    first: number;
    rows: number;
    page: number;
    pageCount: number;
  }) => {
    setNextPage(e.page);
    refetch();
  };

  const handleSearch = (value: string) => {
    if (value.length >= 3) {
      setNextPage(0); // Reset to first page
      setSearchCompanyMember(value);
      refetch();
    } else {
      toast.error("Please enter at least 3 characters to search");
    }
  };

  const handleRefresh = () => {
    setSearchCompanyMember("");
    setNextPage(0);
    refetch();
  };

  const [companyUsers, setCompanyUsers] = useState<ICompanyUsers[]>([]);

  const [statuses] = useState<string[]>(["Active", "Expired"]);

  const [turnoverStatus] = useState<string[]>(["Logged In", "Pending"]);
  const [selectedMember, setSelectedMember] =
    useState<SelectedCompanyMember | null>(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false);
  const [isRemovingMember, setIsRemovingMember] = useState<boolean>(false);

  const onGlobalFilterChange = (e: string) => {
    const _filters = { ...filters };
    // @ts-ignore
    _filters.global.value = e;

    setFilters(_filters);
    debounceSetGlobalFilterValue(e);
    setGlobalFilterSearch(e);
  };

  const getSeverity = (status: string) => {
    switch (status) {
      case "Active":
        return "success";
      case "Expired":
        return "error";
      default:
        return "success";
    }
  };

  const getTurnoverStatus = (status: string) => {
    switch (status) {
      case "Logged In":
        return "success";
      case "Pending":
        return "warning";
      default:
        return "success";
    }
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      firstName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      lastName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      subName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },

      status: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      subStartDate: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      subEndDate: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      turnover: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
    });
    setGlobalFilterValue("");
  };

  const getCompanyUsers = (userData: ICompanyUsers[]) => {
    if (Array.isArray(userData)) {
      return [...(userData || [])].map((d) => {
        // @ts-ignore
        d.subStartDate = new Date(d.subStartDate);
        return d;
      });
    }
    return [];
  };

  useEffect(() => {
    // if (data) {
    //   setCompanyUsers(getCompanyUsers(data.data));
    // }

    initFilters();
  }, []);

  const validToDateBodyTemplate = (rowData: ICompanyUsers) => {
    return moment(rowData.subEndDate).format("YYYY/MM/DD");
  };
  const validFromDateBodyTemplate = (rowData: ICompanyUsers) => {
    return moment(rowData.subStartDate).format("YYYY/MM/DD");
  };

  const statusBodyTemplate = (rowData: ICompanyUsers) => {
    return <Tag color={getSeverity(rowData.status)}>{rowData.status}</Tag>;
  };

  const turnoverBodyTemplate = (rowData: ICompanyUsers) => {
    return (
      <Tag color={getTurnoverStatus(rowData.turnover)}>{rowData.turnover}</Tag>
    );
  };

  const statusItemTemplate = (option: string) => {
    return <Tag color={getSeverity(option)}>{option}</Tag>;
  };

  const statusFilterTemplate = (
    options: ColumnFilterElementTemplateOptions
  ) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e: DropdownChangeEvent) =>
          options.filterCallback(e.value, options.index)
        }
        itemTemplate={statusItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
      />
    );
  };

  const turnoverItemTemplate = (option: string) => {
    return <Tag color={getTurnoverStatus(option)}>{option}</Tag>;
  };

  const turnoverFilterTemplate = (
    options: ColumnFilterElementTemplateOptions
  ) => {
    return (
      <Dropdown
        value={options.value}
        options={turnoverStatus}
        onChange={(e: DropdownChangeEvent) =>
          options.filterCallback(e.value, options.index)
        }
        itemTemplate={turnoverItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
      />
    );
  };

  const validToDateFilterTemplate = (
    options: ColumnFilterElementTemplateOptions
  ) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e: CalendarChangeEvent) => {
          options.filterCallback(e.value, options.index);
        }}
        dateFormat="yy/mm/dd"
        placeholder="yyyy/mm/dd"
        mask="9999/99/99"
      />
    );
  };

  const validFromDateFilterTemplate = (
    options: ColumnFilterElementTemplateOptions
  ) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e: CalendarChangeEvent) => {
          options.filterCallback(e.value, options.index);
        }}
        dateFormat="yy/mm/dd"
        placeholder="yyyy/mm/dd"
        mask="9999/99/99"
      />
    );
  };

  const handleDelete = async (id: string) => {
    setIsRemovingMember(true);
    await Companies.removeCompanyMember(id);
    message.success("Company Member Removed Successfully.");
    setIsDeleteModalVisible(false);
    refetch();
    setIsRemovingMember(false);
  };

  const showDeleteModal = (item: SelectedCompanyMember) => {
    setSelectedMember(item);
    setIsDeleteModalVisible(true);
  };

  const deleteActionBodyTemplate = (rowData: {
    id: string;
    firstName: string;
    lastName: string;
  }) => {
    return (
      <DeleteButton
        onPress={() =>
          showDeleteModal({
            id: rowData.id,
            name: `${rowData.firstName} ${rowData.lastName}`,
          })
        }
      />
    );
  };

  return (
    <div className="flex w-full h-full bg-backgroundColor">
      <div className="w-full h-full max-h-screen overflow-y-auto disable-scrollbars">
        <div className="flex items-center justify-start w-full ">
          <BackButton />
          <HeaderComponent title="Company Members" />
        </div>
        <Spin
          tip=""
          spinning={isLoading || isFetching || isRemovingMember}
          delay={500}
        >
          <div className="w-full h-full px-3 mb-2">
            <div className="flex flex-row justify-end w-full">
              <CustomInputField
                value={globalFilterSearch}
                onChange={(e: string) => onGlobalFilterChange(e)}
                inputProps={{
                  size: "large",
                  placeholder: "Search",
                  allowClear: true,
                  className: "ml-2 w-3",
                  prefix: <SearchOutlined />,
                }}
                theme={CustomInputField}
                mergeThemes
              />
            </div>
          </div>
          <DataTable
            value={data?.data || []}
            ref={dt}
            paginator={false}
            lazy
            rows={limit}
            dataKey="id"
            filters={filters}
            globalFilterFields={[
              "firstName",
              "lastName",
              "status",
              "subStartDate",
              "subEndDate",
              "turnover",
            ]}
            emptyMessage="No Company Members found."
            rowHover
            scrollable
            scrollHeight="40rem"
            className="mx-3"
            size="large"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Users"
            stateStorage="session"
            stateKey="dt-state-company-members-table"
          >
            <Column
              field="firstName"
              header="First Name"
              headerStyle={tableHeader}
              style={{ background: "#FBFBFB" }}
              bodyStyle={tableBody}
            />
            <Column
              field="lastName"
              header="Last Name"
              headerStyle={tableHeader}
              style={{ background: "#FBFBFB" }}
              bodyStyle={tableBody}
            />
            <Column
              field="subName"
              header="Subscription Name"
              headerStyle={tableHeader}
              style={{ background: "#FBFBFB" }}
              bodyStyle={tableBody}
              filter
            />
            <Column
              field="status"
              header="Status"
              headerStyle={tableHeader}
              style={{ background: "#FBFBFB" }}
              bodyStyle={tableBody}
              body={statusBodyTemplate}
              filter
              filterElement={statusFilterTemplate}
            />
            <Column
              field="subStartDate"
              header="Valid From"
              headerStyle={tableHeader}
              dataType="date"
              style={{ background: "#FBFBFB" }}
              bodyStyle={tableBody}
              body={validFromDateBodyTemplate}
              // filter
              filterElement={validFromDateFilterTemplate}
            />
            <Column
              field="subEndDate"
              header="Valid To"
              headerStyle={tableHeader}
              dataType="date"
              style={{ background: "#FBFBFB" }}
              bodyStyle={tableBody}
              body={validToDateBodyTemplate}
              //filter
              filterElement={validToDateFilterTemplate}
            />
            <Column
              field="turnover"
              header="Turnover"
              headerStyle={tableHeader}
              style={{ background: "#FBFBFB" }}
              bodyStyle={tableBody}
              body={turnoverBodyTemplate}
              filter
              filterElement={turnoverFilterTemplate}
            />
            <Column
              field="Action"
              header="Action"
              headerStyle={tableHeader}
              style={{ background: "#FBFBFB" }}
              body={deleteActionBodyTemplate}
            />
          </DataTable>
          <CustomPaginator
            totalRecords={data?.totalCount || 0}
            rows={limit}
            currentPage={nextPage}
            onPageChange={handlePageChange}
          />
          {selectedMember && (
            <DeleteModal
              itemName={`${selectedMember.name} from company`}
              visible={isDeleteModalVisible}
              onConfirm={() => handleDelete(selectedMember.id)}
              onCancel={() => setIsDeleteModalVisible(false)}
              isLoading={isRemovingMember}
            />
          )}
        </Spin>
      </div>
    </div>
  );
};

export default CompanyMembers;

import React, { useEffect, useState } from "react";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { Column } from "primereact/column";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { SearchOutlined } from "@ant-design/icons";
import { ActionBodyText, tableBody, tableHeader } from "../styles";
import { useDraftsReportedCrime } from "../../../hooks/useCrimes";
import Colors from "../../../config/Colors";
import { tableName } from "../../../styles/TableStyles";
import CustomInputField from "../../../components/widgets/Inputs/CustomInputField";

const DraftsPage = () => {
  const navigate = useHistory();
  const navigateTo = (category: string) => {
    navigate.push(`/home/drafted-reported-crimes-category/${category}`);
  };
  const { data, isLoading, isFetching, refetch } = useDraftsReportedCrime();

  const customInputTheme = {
    token: {
      colorPrimaryHover: `${Colors.appMainColor}`,
    },
  };

  const [filters, setFilters] = useState<DataTableFilterMeta | undefined>(
    undefined
  );

  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      lastupdated: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      category: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    });
    setGlobalFilterValue("");
  };

  const clearFilter = () => {
    initFilters();
  };

  const onGlobalFilterChangeV2 = (e: string) => {
    const _filters = { ...filters };
    // @ts-ignore
    _filters.global.value = e;

    setFilters(_filters);
    setGlobalFilterValue(e);
  };

  useEffect(() => {
    initFilters();
  }, []);

  useEffect(() => {
    refetch();
  }, []);

  const dateBodyTemplate = (rowData: ITabItemReportedCrime) => {
    return moment(rowData.lastupdated).format("YYYY/MM/DD");
  };
  const categoryBodyTemplate = (rowData: ITabItemReportedCrime) => {
    return rowData.category;
  };
  const viewMoreBodyTemplate = (rowData: ITabItemReportedCrime) => {
    const category = rowData.category;
    return (
      <ActionBodyText
        onClick={() => {
          navigateTo(category);
        }}
      >
        View More
      </ActionBodyText>
    );
  };
  return (
    <div className="px-3">
      <div className="flex flex-row gap-1 justify-between mb-2 w-full">
        <div className="flex flex-row align-items-center" style={tableName}>
          Reported Crimes
        </div>
        <CustomInputField
          value={globalFilterValue}
          onChange={(e: string) => onGlobalFilterChangeV2(e)}
          onPressEnter={() => {}}
          theme={customInputTheme}
          inputProps={{
            size: "large",
            placeholder: "Search",
            allowClear: true,
            className: "ml-2 w-3",
            prefix: <SearchOutlined />,
          }}
          mergeThemes
        />
      </div>
      <DataTable
        value={data}
        paginator
        rows={10}
        filters={filters}
        globalFilterFields={["lastupdated", "category"]}
        loading={isLoading}
        rowsPerPageOptions={[10, 25, 50, 100]}
        tableStyle={{ minWidth: "50rem" }}
        sortMode="multiple"
        emptyMessage="No drafted reported crimes found."
        scrollable
        scrollHeight="40rem"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Operators"
        stateStorage="session"
        stateKey={`dt-state-operators-drafts-table`}
      >
        <Column
          header="Last Updated"
          headerStyle={tableHeader}
          style={{ background: "#FBFBFB" }}
          bodyStyle={tableBody}
          body={dateBodyTemplate}
        />
        <Column
          field="numbers"
          header="Numbers"
          headerStyle={tableHeader}
          style={{ background: "#FBFBFB" }}
          bodyStyle={tableBody}
        />
        <Column
          header="Category of Crime"
          headerStyle={tableHeader}
          style={{ background: "#FBFBFB" }}
          bodyStyle={tableBody}
          body={categoryBodyTemplate}
        />
        <Column
          header=""
          headerStyle={tableHeader}
          style={{ background: "#FBFBFB" }}
          bodyStyle={tableBody}
          body={viewMoreBodyTemplate}
        />
      </DataTable>
    </div>
  );
};

export default DraftsPage;

/* eslint-disable max-len */
import React from "react";
import Colors from "../../config/Colors";

export function LogOutIcon({
  height, // number type by default
  width,
  viewBox = "0 0 24 24",
  svgColor = `${Colors.secondaryColor}`,
}: CustomSVGProp) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox={viewBox || "0 0 24 24"}
      fill="none"
    >
      <path
        d="M8.25 12.25H23.25"
        stroke={svgColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.25 6.25L23.25 12.25L17.25 18.25"
        stroke={svgColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.75 23.5H3C2.40326 23.5 1.83097 23.2629 1.40901 22.841C0.987053 22.419 0.75 21.8467 0.75 21.25V3.25C0.75 2.65326 0.987053 2.08097 1.40901 1.65901C1.83097 1.23705 2.40326 1 3 1H12.75"
        stroke={svgColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default LogOutIcon;

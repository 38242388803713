import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { queries } from "../services/queries";
import PostApplication from "../services/PostApplication";
import { isErrorMessage } from "../util/helpers";
import { PostApplicationStatus } from "../util/enums";

const getPostApplicationStatusString = (status: PostApplicationStatus) => {
  switch (status) {
    case PostApplicationStatus.Pending:
      return "pending";
    case PostApplicationStatus.Approved:
      return "approved";
    case PostApplicationStatus.Blocked:
      return "rejected";
    case PostApplicationStatus.Disabled:
      return "cancelled";
    case PostApplicationStatus.Rejected:
      return "rejected";
    default:
      return "pending";
  }
};

export function useGetPostApplicationDashboardDetails(
  communityId: number | null | undefined
): UseQueryResult<IPostApplicationDashboard[]> {
  return useQuery({
    ...queries.postApplications.getDashboardDetails(communityId),
    enabled: !!communityId,
  });
}

export function useGetCommunityUsersByStatus(
  communityId: number | null | undefined,
  status: number | string | null | undefined
): UseQueryResult<IPostApplicationUser[]> {
  return useQuery({
    ...queries.postApplications.getCommunityUsersByStatus(communityId, status),
    enabled: !!communityId,
  });
}

export function useGetApplicationDetails(
  applicationId: number | string | null | undefined
): UseQueryResult<IPostApplicationDetails> {
  return useQuery({
    ...queries.postApplications.getApplicationDetails(applicationId),
    enabled: !!applicationId,
  });
}

export function useApprovePostApplication() {
  const queryClient = useQueryClient();
  return useMutation(PostApplication.approveApplication, {
    onError: (error: AxiosError, application, context) => {
      const errorMessage = error.response?.data;

      if (typeof errorMessage === "string") {
        toast.error(errorMessage);
      } else if (isErrorMessage(errorMessage)) {
        const errorTitle = errorMessage.title;
        const errorKeys = Object.keys(errorMessage.errors || {});
        if (errorKeys.length > 0 && errorMessage.errors) {
          const errorFieldMessage = `${errorKeys[0]}: ${
            errorMessage.errors[errorKeys[0]][0]
          }`;
          toast.error(errorFieldMessage);
        } else {
          toast.error(errorTitle);
        }
      } else {
        // If errorMessage is not a string and not an IErrorMessage, show a generic error message
        toast.error("Something went wrong");
      }
    },
    onSuccess: (application) => {
      queryClient.invalidateQueries(
        queries.postApplications.getDashboardDetails(application.communityId)
      );
      queryClient.invalidateQueries(
        queries.postApplications.getApplicationDetails(
          application.applicationId
        )
      );

      queryClient.invalidateQueries(
        queries.postApplications.getCommunityUsersByStatus(
          application.communityId,
          getPostApplicationStatusString(application.status)
        )
      );
    },
    meta: {
      useError: true,
    },
  });
}

export function useDisableCanPost() {
  const queryClient = useQueryClient();
  return useMutation(PostApplication.disableCanPost, {
    onError: (error: AxiosError, application, context) => {
      const errorMessage = error.response?.data;

      if (typeof errorMessage === "string") {
        toast.error(errorMessage);
      } else if (isErrorMessage(errorMessage)) {
        const errorTitle = errorMessage.title;
        const errorKeys = Object.keys(errorMessage.errors || {});
        if (errorKeys.length > 0 && errorMessage.errors) {
          const errorFieldMessage = `${errorKeys[0]}: ${
            errorMessage.errors[errorKeys[0]][0]
          }`;
          toast.error(errorFieldMessage);
        } else {
          toast.error(errorTitle);
        }
      } else {
        // If errorMessage is not a string and not an IErrorMessage, show a generic error message
        toast.error("Something went wrong");
      }
    },
    onSuccess: (application) => {
      queryClient.invalidateQueries(
        queries.postApplications.getDashboardDetails(application.communityId)
      );
      queryClient.invalidateQueries(
        queries.postApplications.getApplicationDetails(
          application.applicationId
        )
      );

      queryClient.invalidateQueries(
        queries.postApplications.getCommunityUsersByStatus(
          application.communityId,
          getPostApplicationStatusString(application.status)
        )
      );
    },
    meta: {
      useError: true,
    },
  });
}

export function useEnableCanPost() {
  const queryClient = useQueryClient();
  return useMutation(PostApplication.enableCanPost, {
    onError: (error: AxiosError, application, context) => {
      const errorMessage = error.response?.data;

      if (typeof errorMessage === "string") {
        toast.error(errorMessage);
      } else if (isErrorMessage(errorMessage)) {
        const errorTitle = errorMessage.title;
        const errorKeys = Object.keys(errorMessage.errors || {});
        if (errorKeys.length > 0 && errorMessage.errors) {
          const errorFieldMessage = `${errorKeys[0]}: ${
            errorMessage.errors[errorKeys[0]][0]
          }`;
          toast.error(errorFieldMessage);
        } else {
          toast.error(errorTitle);
        }
      } else {
        // If errorMessage is not a string and not an IErrorMessage, show a generic error message
        toast.error("Something went wrong");
      }
    },
    onSuccess: (application) => {
      queryClient.invalidateQueries(
        queries.postApplications.getDashboardDetails(application.communityId)
      );
      queryClient.invalidateQueries(
        queries.postApplications.getApplicationDetails(
          application.applicationId
        )
      );

      queryClient.invalidateQueries(
        queries.postApplications.getCommunityUsersByStatus(
          application.communityId,
          getPostApplicationStatusString(application.status)
        )
      );
    },
    meta: {
      useError: true,
    },
  });
}

export function useUnBlockApplication() {
  const queryClient = useQueryClient();
  return useMutation(PostApplication.unBlockApplication, {
    onError: (error: AxiosError, application, context) => {
      const errorMessage = error.response?.data;

      if (typeof errorMessage === "string") {
        toast.error(errorMessage);
      } else if (isErrorMessage(errorMessage)) {
        const errorTitle = errorMessage.title;
        const errorKeys = Object.keys(errorMessage.errors || {});
        if (errorKeys.length > 0 && errorMessage.errors) {
          const errorFieldMessage = `${errorKeys[0]}: ${
            errorMessage.errors[errorKeys[0]][0]
          }`;
          toast.error(errorFieldMessage);
        } else {
          toast.error(errorTitle);
        }
      } else {
        // If errorMessage is not a string and not an IErrorMessage, show a generic error message
        toast.error("Something went wrong");
      }
    },
    onSuccess: (application) => {
      queryClient.invalidateQueries(
        queries.postApplications.getDashboardDetails(application.communityId)
      );
      queryClient.invalidateQueries(
        queries.postApplications.getApplicationDetails(
          application.applicationId
        )
      );

      queryClient.invalidateQueries(
        queries.postApplications.getCommunityUsersByStatus(
          application.communityId,
          getPostApplicationStatusString(application.status)
        )
      );
    },
    meta: {
      useError: true,
    },
  });
}

export function useRejectApplication() {
  const queryClient = useQueryClient();
  return useMutation(PostApplication.rejectApplication, {
    onError: (error: AxiosError, application, context) => {
      const errorMessage = error.response?.data;

      if (typeof errorMessage === "string") {
        toast.error(errorMessage);
      } else if (isErrorMessage(errorMessage)) {
        const errorTitle = errorMessage.title;
        const errorKeys = Object.keys(errorMessage.errors || {});
        if (errorKeys.length > 0 && errorMessage.errors) {
          const errorFieldMessage = `${errorKeys[0]}: ${
            errorMessage.errors[errorKeys[0]][0]
          }`;
          toast.error(errorFieldMessage);
        } else {
          toast.error(errorTitle);
        }
      } else {
        // If errorMessage is not a string and not an IErrorMessage, show a generic error message
        toast.error("Something went wrong");
      }
    },
    onSuccess: (application) => {
      queryClient.invalidateQueries(
        queries.postApplications.getDashboardDetails(application.communityId)
      );
      queryClient.invalidateQueries(
        queries.postApplications.getApplicationDetails(
          application.applicationId
        )
      );

      queryClient.invalidateQueries(
        queries.postApplications.getCommunityUsersByStatus(
          application.communityId,
          getPostApplicationStatusString(application.status)
        )
      );
    },
    meta: {
      useError: true,
    },
  });
}

export function useBlockApplication() {
  const queryClient = useQueryClient();
  return useMutation(PostApplication.blockApplication, {
    onError: (error: AxiosError, application, context) => {
      const errorMessage = error.response?.data;

      if (typeof errorMessage === "string") {
        toast.error(errorMessage);
      } else if (isErrorMessage(errorMessage)) {
        const errorTitle = errorMessage.title;
        const errorKeys = Object.keys(errorMessage.errors || {});
        if (errorKeys.length > 0 && errorMessage.errors) {
          const errorFieldMessage = `${errorKeys[0]}: ${
            errorMessage.errors[errorKeys[0]][0]
          }`;
          toast.error(errorFieldMessage);
        } else {
          toast.error(errorTitle);
        }
      } else {
        // If errorMessage is not a string and not an IErrorMessage, show a generic error message
        toast.error("Something went wrong");
      }
    },
    onSuccess: (application) => {
      queryClient.invalidateQueries(
        queries.postApplications.getDashboardDetails(application.communityId)
      );
      queryClient.invalidateQueries(
        queries.postApplications.getApplicationDetails(
          application.applicationId
        )
      );

      queryClient.invalidateQueries(
        queries.postApplications.getCommunityUsersByStatus(
          application.communityId,
          getPostApplicationStatusString(application.status)
        )
      );
    },
    meta: {
      useError: true,
    },
  });
}

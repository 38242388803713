import {
  Button,
  DatePicker,
  DatePickerProps,
  Form,
  Input,
  UploadProps,
  message,
} from "antd";
import Dragger from "antd/es/upload/Dragger";
import React, { useEffect, useState } from "react";
import { CustomIcons } from "../../assets";
import Colors from "../../config/Colors";
import { headerDraggerStyles } from "./styles";
import {
  clickHereDraggerStyles,
  subtitleDraggerStyles,
} from "../Cards/DaggerCard/styles";
import { StagingUrl, uploadUrl } from "../../config/urls";
import { RcFile, UploadFile } from "antd/es/upload";
import moment from "moment";

type companyPaymentProp = {
  paymentValues?: ICompanyUploadPayments;
  onFormFinish: (company: ICompanyUploadPayments) => void;
  onModalClose: () => void;
  isLoading: boolean;
  isSuccess: boolean;
};

const CompanyPayment = ({
  paymentValues,
  onFormFinish,
  onModalClose,
  isLoading,
  isSuccess,
}: companyPaymentProp) => {
  const [form] = Form.useForm();
  const [fileUrl, setFileUrl] = useState<string | null>();

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log(date, dateString);
  };

  const onFinish = async (formValues: ICompanyUploadPayments) => {
    const values: ICompanyUploadPayments = formValues as ICompanyUploadPayments;
    values.dateOfPayment = moment(values.dateOfPayment?.toString()).format(
      "YYYY/MM/DD"
    );
    values.pdfLink = fileUrl as string;
    await onFormFinish(values);
    handleClose();
  };

  const props: UploadProps = {
    name: "file",
    multiple: false,
    //The end point to upload the file

    action: uploadUrl,
    onChange(info) {
      const { status } = info.file;
      console.log(info.file);
      if (status === "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (status === "done") {
        setFileUrl(info.file.response.path);
        message.success(`The PDF file has been uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const beforeUpload = (file: RcFile, fileList: UploadFile[]) => {
    const isPDF = file.type === "application/pdf";
    if (!isPDF) {
      message.error("You can only upload PDF files, Please remove file!");
    }
    return isPDF;
  };

  const handleClose = () => {
    form.resetFields();
    onModalClose();
  };

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
    }
  }, [isSuccess]);

  return (
    <div>
      <Form
        form={form}
        name="addCompanyPayment"
        layout="vertical"
        onFinish={onFinish}
      >
        <div className="w-full flex flex-row justify-between">
          <div className="col-12 md:col-6">
            <Form.Item
              name="dateOfPayment"
              label="Date of Payment"
              rules={[
                {
                  required: true,
                  message: "Please select date of payment",
                },
              ]}
              className="mb-1"
            >
              <DatePicker onChange={onChange} size="large" className="w-full" />
            </Form.Item>
          </div>

          <div className="col-12 md:col-6">
            <Form.Item
              name="amount"
              label="Amount"
              rules={[
                { required: true, message: "Please enter amount" },
                {
                  pattern: /^\d{1,3}(?:\s?\d{3})*(?:\.\d{1,2})?$/,
                  message: "Invalid price format",
                },
              ]}
              className="mb-1"
            >
              <Input size="large" placeholder="1399.99" />
            </Form.Item>
          </div>
        </div>

        <Form.Item
          name={"pdfLink"}
          label="Upload PDF file "
          rules={[
            {
              required: true,
              message: "Please upload a proof of payment pdf",
            },
          ]}
          className="mb-5 col-12"
        >
          <div className="flex w-full gap-6 flx-row justify-content-start">
            <Dragger
              {...props}
              beforeUpload={beforeUpload}
              className="w-full"
              style={{ background: "transparent" }}
              accept=".pdf"
            >
              <div className="flex flex-col items-center content-center w-full h-full gap-3">
                <CustomIcons.UploadIcon
                  width={46}
                  height={46}
                  svgColor={Colors.appMainColor}
                />
                <div className="flex gap-1 justify-content-center">
                  <div className="text-center" style={headerDraggerStyles}>
                    Drop your pdf file here or to upload
                  </div>
                  <div style={clickHereDraggerStyles}>click here</div>
                  <div className="text-center" style={headerDraggerStyles}>
                    to upload
                  </div>
                </div>

                <div style={subtitleDraggerStyles}>
                  File should be PDF, .pdf{" "}
                </div>
              </div>
            </Dragger>
          </div>
        </Form.Item>

        <Form.Item className="w-12 pt-2 mb-1">
          <div className="flex w-full gap-2 col-12 justify-content-end align-content-center">
            <Button
              onClick={handleClose}
              type="primary"
              ghost
              size="large"
              className="w-3"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="w-3 bg-[#1757a2] shadow-none"
              loading={isLoading}
            >
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CompanyPayment;

/* eslint-disable no-underscore-dangle */
import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { message } from "antd";
import { AxiosError } from "axios";
import { queries } from "../services/queries";
import { Users, Admins } from "../services";
import toast from "react-hot-toast";
import { isErrorMessage } from "../util/helpers";

export function useAdmins(): UseQueryResult<IAdmin[]> {
  return useQuery({
    ...queries.admins.all,
    queryFn: () => Admins.getAdmins(),
    refetchOnWindowFocus: false,
  });
}

export function useAdminList(filter: Filters) {
  return useQuery({
    ...queries.admins.list(filter),
    refetchOnWindowFocus: false,
  });
}

export function useSearchAdmin(filters: Filters, query: string, limit = 15) {
  return useQuery({
    ...queries.admins.list(filters)._ctx.search(query, limit),
    enabled: Boolean(query),
    refetchOnWindowFocus: false,
  });
}
export function usePostAdmin() {
  const queryClient = useQueryClient();

  return useMutation(Admins.addAdmin, {
    onSuccess: (newAdmin) => {
      queryClient.invalidateQueries({
        queryKey: queries.admins._def,
      });
    },
    meta: {
      conflict: "Phone Number or Email Address Already Exists",
      badRequest: "Please make sure all fields are entered correctly.",
    },
  });
}

export function usePostEmergencyAdmin() {
  const queryClient = useQueryClient();

  return useMutation(Admins.addEmergencyAdmin, {
    onSuccess: (newAdmin) => {
      queryClient.invalidateQueries({
        queryKey: queries.admins._def,
      });
    },
    meta: {
      conflict: "Phone Number or Email Address Already Exists",
      badRequest: "Please make sure all fields are entered correctly.",
    },
  });
}

export function usePostCommunityAdmin() {
  const queryClient = useQueryClient();

  return useMutation(Admins.addCommunityAdmin, {
    onSuccess: (newAdmin, request) => {
      queryClient.invalidateQueries({
        queryKey: ["communityOperators", request.CommunityId], // unique key,
      });
      queryClient.invalidateQueries({
        queryKey: queries.communities._def,
      });
    },
    onError: (error: AxiosError, newUser, context) => {
      const errorMessage = error.response?.data;

      if (typeof errorMessage === "string") {
        toast.error(errorMessage);
      } else if (isErrorMessage(errorMessage)) {
        const errorTitle = errorMessage.title;
        const errorKeys = Object.keys(errorMessage.errors || {});
        if (errorKeys.length > 0 && errorMessage.errors) {
          const errorFieldMessage = `${errorKeys[0]}: ${
            errorMessage.errors[errorKeys[0]][0]
          }`;
          toast.error(errorFieldMessage);
        } else {
          toast.error(errorTitle);
        }
      } else {
        // If errorMessage is not a string and not an IErrorMessage, show a generic error message
        toast.error("Something went wrong");
      }
    },
    meta: {
      useError: true,
    },
  });
}

export function usePostGeneralAdmin() {
  const queryClient = useQueryClient();

  return useMutation(Admins.addGenericAdmin, {
    onSuccess: (newAdmin, request) => {
      if (request.role === "Community Admin") {
        queryClient.invalidateQueries({
          queryKey: ["communityOperators", request.typeId], // unique key,
        });
        queryClient.invalidateQueries({
          queryKey: queries.communities._def,
        });
      }
      queryClient.invalidateQueries({
        queryKey: queries.admins._def,
      });
    },
    onError: (error: AxiosError, newUser, context) => {
      const errorMessage = error.response?.data;

      if (typeof errorMessage === "string") {
        toast.error(errorMessage);
      } else if (isErrorMessage(errorMessage)) {
        const errorTitle = errorMessage.title;
        const errorKeys = Object.keys(errorMessage.errors || {});
        if (errorKeys.length > 0 && errorMessage.errors) {
          const errorFieldMessage = `${errorKeys[0]}: ${
            errorMessage.errors[errorKeys[0]][0]
          }`;
          toast.error(errorFieldMessage);
        } else {
          toast.error(errorTitle);
        }
      } else {
        toast.error("Something went wrong");
      }
    },
    meta: {
      useError: true,
    },
  });
}

export function useRemoveAdmin() {
  const queryClient = useQueryClient();

  return useMutation(Admins.removeAdmin, {
    onMutate: async (removedUser) => {
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({
        queryKey: queries.admins.all.queryKey,
      });
      // get previous admins data
      const previousAdminsData = queryClient.getQueryData(
        queries.admins.all.queryKey
      );

      // optimistic update
      queryClient.setQueryData(
        queries.admins.all.queryKey,
        (oldAdminsData: IUser[] | undefined) => {
          if (oldAdminsData)
            return oldAdminsData.filter((admin) => admin.id !== removedUser);
        }
      );

      return { previousAdminsData };
    },
    onError: (err, removedUser, context) => {
      // rollback to the previous value if mutation fails
      if (context) {
        queryClient.setQueryData(
          queries.admins.all.queryKey,
          context.previousAdminsData
        );
      }
    },
    onSuccess: (removedAdmin, variables) => {
      queryClient.invalidateQueries({
        queryKey: queries.admins.all.queryKey,
      });
    },
    meta: {
      conflict: "Found whilst making changes",
      badRequest: "Please make sure all fields are entered correctly.",
    },
  });
}

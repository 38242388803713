import { createQueryKeys } from "@lukemorales/query-key-factory";
import Users from "../Users";

export const users = createQueryKeys("users", {
  all: null,
  getFilteredUsers: (first: number, rows: number, filters: Filters) => ({
    queryKey: [{ first, rows, filters }],
    queryFn: () => Users.getFilteredUsers(first, rows, filters),
  }),
  detail: (userId: string | number) => ({
    queryKey: [userId],
    queryFn: () => Users.getById(userId),
  }),
  list: (filter: Filters) => ({
    queryKey: [filter],
    queryFn: (ctx) => Users.getUsersList(filter, ctx.pageParam),
    contextQueries: {
      search: (query: string, limit: number) => ({
        queryKey: [query, limit],
        queryFn: (ctx) =>
          Users.getSearchUsers(filter, ctx.pageParam, limit, query),
      }),
    },
  }),
  getUserChatHistory: (userId: string) => ({
    queryKey: [userId],
    queryFn: () => Users.getUserChatHistory(userId),
  }),
  getUserProfile: (userId: string) => ({
    queryKey: [userId],
    queryFn: () => Users.getUserProfileDetails(userId),
  }),
});

/* eslint-disable no-nested-ternary */
/* eslint-disable comma-dangle */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-alert */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable  implicit-arrow-linebreak */
import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import GoogleMapReact from "google-map-react";
import { DateTime } from "luxon";
import { List, AutoComplete, Input, Space, Tag } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { CustomIcons, Images } from "../../assets/index";
import HeaderComponent from "../../components/Header/Header";
import Colors from "../../config/Colors";
import {
  useCrimeDashboardData,
  useCrimeMapData,
  useCrimeTopCrimesByArea,
  useTotalReview,
} from "../../hooks/useCrimes";
import Spin from "../../components/widgets/Spinner/Spin";
import { NavLink } from "react-router-dom";
import AddNewCrimeType from "@/components/Modals/AddNewCrimeType";

const _ = require("lodash");

type MarkerProps = {
  text: string;
  lat: number;
  lng: number;
};

const Marker: React.FC<MarkerProps> = ({ text }) => {
  return (
    <div className="w-full h-full">
      <div
        style={{
          color: "black",
          background: `url(${Images.googleMakerIcon}) no-repeat center center`,
          backgroundSize: "fit",
          display: "inline-flex",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
          height: "55px",
          width: "55px",
          borderRadius: "50%",
          position: "absolute",
          transform: "translate(-50%, -100%)",
          padding: "1rem",
        }}
      >
        <div className="pb-2">{text}</div>
      </div>
    </div>
  );
};
const CrimeDashboard = observer(() => {
  const mapRef = useRef();

  const [searchQuery, setSearchQuery] = React.useState("");
  const [selectedArea, setSelectedArea] = React.useState("");
  const [canSearch, setCanSearch] = useState(false);
  const [areas, setAreas] = React.useState<string[]>([]);
  const [autocompleteOptions, setAutoCompleteOptions] = useState<
    { value: string }[]
  >([]);
  const [totalCrimeTypes, setTotalCrimeTypes] = useState(0);

  const {
    isLoading: loadingMapData,
    data: mapData,
    isError,
    isFetching: fetchingMapData,
  } = useCrimeMapData();

  const { data: TotalReviews } = useTotalReview();

  const { data: crimesDashboard, isLoading: loadingCrimeDashboard } =
    useCrimeDashboardData();

  const topAreaCrimeData = useCrimeTopCrimesByArea(selectedArea, canSearch);

  const onSearch = (data: string) => {
    // filter the suggestions based on the query
    const query = data.toLowerCase();
    const filtered = crimesDashboard?.hotspots.filter((area: string) =>
      area.toLowerCase().includes(query)
    );
    // check if filter is null or undefined
    if (!filtered) {
      setAreas([]);
    } else {
      setAreas(filtered);
    }
  };

  const onSelect = (data: string) => {
    if (data.length > 0) {
      setSelectedArea(data);
      setCanSearch(true);
    } else {
      setSelectedArea("");
      setCanSearch(false);
    }
  };

  useEffect(() => {
    const newOptions = areas.map((area: string) => ({ value: area }));
    if (JSON.stringify(newOptions) !== JSON.stringify(autocompleteOptions)) {
      setAutoCompleteOptions(newOptions);
    }
  }, [areas, autocompleteOptions]);

  return (
    <div className="w-full h-full bg-backgroundColor ">
      <div className="w-full max-h-full disable-scrollbars static">
        <HeaderComponent title="Report crime" />
        {loadingCrimeDashboard ? (
          <div className="flex h-full flex-column justify-content-center align-items-center">
            <CustomIcons.EOSThreeDotsLoading
              width={80}
              height={80}
              viewBox={null}
              svgColor={Colors.appMainColor}
            />
          </div>
        ) : (
          <Spin
            tip=""
            spinning={
              loadingMapData || fetchingMapData || loadingCrimeDashboard
            }
            delay={500}
          >
            <div className="flex w-full h-full px-2 md:flex-row">
              <div className="w-full h-full px-3 md:w-3/5 static">
                {/* <Spin tip="" spinning={loadingMapData || fetchingMapData}> */}
                <div style={{ height: "90vh", width: "100%" }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
                        ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY
                        : "",
                    }}
                    defaultCenter={{ lat: -26.204103, lng: 28.047304 }}
                    defaultZoom={8}
                    onGoogleApiLoaded={({ map }) => {
                      mapRef.current = map;
                    }}
                    yesIWantToUseGoogleMapApiInternals
                  >
                    {mapData &&
                      mapData?.map((item: ICrimeAreaCount, index) => {
                        return (
                          <Marker
                            key={`area-${index + 1}-${item.area}`}
                            lat={item.latitude}
                            lng={item.longitude}
                            text={`${item.crimeCount}`}
                          />
                        );
                      })}
                  </GoogleMapReact>
                </div>
                {/* </Spin> */}
              </div>
              <div className="flex flex-col w-full h-full px-3 space-y-10 overflow-y-auto md:w-2/5 ">
                <div className="scroll-m-2 px-2 py-2 space-y-10">
                  {/* Crime Metric Here */}

                  <div className="flex flex-row w-9/12 h-32 px-1 py-3 bg-white rounded shadow-1">
                    <div className="flex flex-col flex-none px-3 items-starts">
                      <div className="flex justify-start text-lg font-bold text-mainTextColor">
                        Crimes reported
                      </div>
                      <div className="flex justify-start text-sm font-normal text-subtleTextColor">
                        Last updated:{" "}
                        {DateTime.now().toLocaleString(DateTime.DATE_MED)}
                      </div>
                    </div>
                    <div className="flex flex-col items-start justify-start h-full grow">
                      <div className="flex flex-row justify-start gap-3">
                        <div className="text-6xl font-bold text-appMainColor">
                          {crimesDashboard?.metrics?.total}
                        </div>
                        <div className="flex items-center text-2xl text-errorColor">
                          +{crimesDashboard?.metrics?.increase}%{" "}
                          <CustomIcons.ArrowUpward
                            svgColor={Colors.errorColor}
                            viewBox={null}
                            height={20}
                            width={20}
                          />
                        </div>
                      </div>

                      <div className="flex justify-start pt-1 text-lg font-normal underline">
                        <NavLink to="./reported-crimes">
                          View reported crimes
                        </NavLink>
                      </div>
                    </div>
                  </div>

                  <div className="flex w-full gap-4">
                    {/* Rviews card */}

                    <div className="w-5/12 h-32 px-3  py-3 bg-white rounded shadow-1">
                      <div className=" flex justify-start text-lg font-bold text-mainTextColor">
                        Review Crimes
                      </div>
                      <div className=" flex justify-start text-5xl font-bold text-appMainColor">
                        {TotalReviews?.total}
                      </div>
                      <div className=" flex justify-start text-lg font-normal underline">
                        <NavLink to="./review-reported-crimes">
                          Review reported crimes
                        </NavLink>
                      </div>
                    </div>

                    {/* Crime types card */}
                    <div className="w-5/12 h-32 px-3  py-3 bg-white rounded shadow-1">
                      <AddNewCrimeType />
                    </div>
                  </div>

                  {/* Top 5 reported crimes Here */}
                  <div className="px-3 py-3 bg-white rounded h-3/4 shadow-1">
                    <div className="flex flex-row items-center justify-between w-full">
                      <div className="flex items-center justify-start text-lg font-bold text-mainTextColor">
                        Top 5 reported crimes
                      </div>
                      <div className="flex justify-start text-lg font-bold text-mainTextColor">
                        <AutoComplete
                          value={searchQuery}
                          options={autocompleteOptions}
                          style={{ width: 200 }}
                          onSelect={onSelect}
                          onSearch={(text) => onSearch(text)}
                          onChange={(s: string) => {
                            setSearchQuery(s);
                          }}
                          allowClear
                          onClear={() => {
                            setSearchQuery("");
                            setCanSearch(false);
                          }}
                        >
                          <Input
                            prefix={<SearchOutlined />}
                            placeholder="Search"
                            className="custom"
                          />
                        </AutoComplete>
                      </div>
                    </div>
                    <div className="w-full pt-2">
                      {canSearch ? (
                        <>
                          {topAreaCrimeData.isLoading ? (
                            <Spin
                              tip=""
                              spinning={topAreaCrimeData.isLoading}
                            />
                          ) : topAreaCrimeData.data ? (
                            <Spin tip="" spinning={topAreaCrimeData.isFetching}>
                              <List
                                dataSource={topAreaCrimeData.data}
                                renderItem={(item, index) => (
                                  <List.Item>
                                    <div className="flex flex-row justify-between w-full">
                                      <div className="flex flex-row gap-1 text-lg font-normal text-mainTextColor">
                                        <div>{index + 1}.</div>
                                        <div>{item.crimeName}</div>
                                      </div>
                                      <div className="text-lg font-bold text-appMainColor">
                                        {item.count}
                                      </div>
                                    </div>
                                  </List.Item>
                                )}
                              />
                            </Spin>
                          ) : (
                            <div className="w-full text-lg font-semibold text-center text-mainAppColor">
                              No Crimes Found
                            </div>
                          )}
                        </>
                      ) : (
                        <Spin tip="" spinning={topAreaCrimeData.isFetching}>
                          <List
                            dataSource={crimesDashboard?.topCrimes}
                            renderItem={(item, index) => (
                              <List.Item>
                                <div className="flex flex-row justify-between w-full">
                                  <div className="flex flex-row gap-1 text-lg font-normal text-mainTextColor">
                                    <div>{index + 1}.</div>
                                    <div>{item.crimeName}</div>
                                  </div>
                                  <div className="text-lg font-bold text-appMainColor">
                                    {item.count}
                                  </div>
                                </div>
                              </List.Item>
                            )}
                          />
                        </Spin>
                      )}
                    </div>
                  </div>

                  {/* Crime hotSpots here */}
                  <div className="flex-grow px-3 py-3 overflow-y-auto bg-white rounded shadow-1">
                    <div className="flex flex-row items-center justify-between w-full">
                      <div className="flex items-center justify-start text-lg font-bold text-mainTextColor">
                        Crime hotspot
                      </div>
                    </div>
                    <div className="flex justify-start flex-grow pt-3 h-36 w-full overflow-auto">
                      <Space size={[0, 8]} wrap>
                        {crimesDashboard?.hotspots.map((item, index) => (
                          <Tag key={`crime-hotSpots-${index}`}>{item}</Tag>
                        ))}
                      </Space>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Spin>
        )}
      </div>
    </div>
  );
});

export default CrimeDashboard;

import { Radio } from "antd";
import TextArea from "antd/es/input/TextArea";
import { Skeleton } from "primereact/skeleton";
import React from "react";

type ResponderReportQuestionsProps = {
  question?: string;
  answer?: boolean;
  answerInfo?: string;
  isLoadingProp: boolean;
  isFetchingProp: boolean;
};

const ResponderReportQuestions = ({
  question,
  answer,
  answerInfo,
  isLoadingProp: isLoading,
  isFetchingProp: isFetching,
}: ResponderReportQuestionsProps) => {
  return (
    <>
      {isLoading || isFetching ? (
        <div className="flex w-full mt-3">
          <Skeleton height="2rem" width="90%" />
        </div>
      ) : (
        <>
          {answer != null && (
            <div className="flex w-full text-sm">{question}</div>
          )}
        </>
      )}
      {isLoading || isFetching ? (
        <div className="flex w-full mt-3">
          <Skeleton height="2rem" width="50%" />
        </div>
      ) : (
        <>
          {answer != null && (
            <div className="flex w-full mb-3 flex-col">
              <Radio.Group
                value={answer ? 1 : 2}
                className="flex justify-start"
              >
                <Radio value={1}>Yes</Radio>
                <Radio value={2}>No</Radio>
              </Radio.Group>
              {(answerInfo?.length as number) > 0 && (
                <TextArea
                  rows={3}
                  className="flex w-full mt-1"
                  value={answerInfo}
                />
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ResponderReportQuestions;

import React, { FC, ReactNode, ChangeEvent } from "react";
import { InputProps } from "antd/lib/input";
import { ConfigProviderProps } from "antd/lib/config-provider";
import { Input, ConfigProvider } from "antd";
import Colors from "../../../config/Colors";

interface TextFieldInputProps extends InputProps {
  prefix?: ReactNode;
  size?: "large" | "middle" | "small";
  placeholder?: string;
  allowClear?: boolean;
  className?: string;
}

interface ICustomInputFieldProps extends ConfigProviderProps {
  value: string;
  onChange: (value: string) => void;
  inputProps: TextFieldInputProps;
  theme?: any;
  onPressEnter?: () => void;
  mergeThemes?: boolean;
}

function deepMerge(target: any, ...sources: any[]): any {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        deepMerge(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    });
  }

  return deepMerge(target, ...sources);
}

function isObject(item: any): boolean {
  return item && typeof item === "object" && !Array.isArray(item);
}

const CustomInputField: FC<ICustomInputFieldProps> = ({
  inputProps = {
    size: "large",
    placeholder: "Enter text here",
    allowClear: false,
    className: "",
  },
  value = "Text",
  onChange = () => {},
  theme = null,
  onPressEnter = null,
  mergeThemes = false,
  ...otherProps
}) => {
  const defaultTheme = {
    token: {
      colorBgContainer: "transparent",
      colorText: "rgba(0, 0, 0, 0.85)",
      colorTextPlaceholder: "rgba(0, 0, 0, 0.45)",
      fontSizeLG: 14,
      lineHeightLG: 1.3125,
      colorPrimaryHover: `${Colors.appMainColor}`,
      colorBorder: `${Colors.subtleTextColor}`,
      lineWidth: 0.3,
      borderRadius: 8,
    },
  };

  const finalTheme = mergeThemes
    ? deepMerge({}, defaultTheme, theme)
    : theme || defaultTheme;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.currentTarget.value);
  };

  return (
    <ConfigProvider theme={finalTheme} {...otherProps}>
      <Input
        size={inputProps.size}
        placeholder={inputProps.placeholder}
        prefix={inputProps.prefix}
        className={inputProps.className || ""}
        value={value}
        onChange={handleChange}
        onPressEnter={onPressEnter || undefined}
        allowClear={inputProps.allowClear}
      />
    </ConfigProvider>
  );
};

export default CustomInputField;

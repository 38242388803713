import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  Form,
  Input,
  Upload,
  Select,
  Button,
  UploadFile,
  message,
  UploadProps,
  Checkbox,
  Tag,
} from "antd";

import TextArea from "antd/es/input/TextArea";
import mime from "mime";
import { RcFile } from "antd/es/upload";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { SearchOutlined } from "@ant-design/icons";
import { Rating } from "primereact/rating";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { SubHeading } from "../Home/styles";
import Colors from "../../config/Colors";
import { CustomIcons } from "../../assets";
import HeaderComponent from "../../components/Header/Header";
import { tableBody, tableHeader } from "../../styles/TableStyles";
import {
  useAddCarousel,
  useCarouselData,
  useEditCarousel,
} from "../../hooks/useCarousel";
import moment from "moment";
import NewCarouselForm from "../../components/Forms/NewCarouselForm";
import { modelTitle } from "../../styles/ModalStyles";
import EditCarousel from "../../components/Forms/EditCarousel";
import { set } from "mobx";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import CustomInputField from "../../components/widgets/Inputs/CustomInputField";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import { Calendar, CalendarChangeEvent } from "primereact/calendar";

const CarouselManager = () => {
  // @ts-ignore
  const dt = useRef<DataTable>(null);
  const addCarousel = useAddCarousel();
  const editCarousel = useEditCarousel();
  const { isLoading, data, refetch, isFetching } = useCarouselData();

  const [openAddNewCarouselModal, setOpenAddNewCarouselModal] = useState(false);
  const [openEditCarouselModal, setOpenEditCarouselModal] = useState(false);
  const [form] = Form.useForm();
  const [fileListImage, setFileListImage] = useState<UploadFile[]>([]);
  const [mediaArr, setMediaArr] = useState<ICarouselMedia[]>([]);
  const [filters, setFilters] = useState<DataTableFilterMeta | undefined>(
    undefined
  );
  const [slides, setSlides] = useState<ICarouselData[]>([]);

  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const [id, setId] = useState<number>();

  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [status] = useState<string[]>(["Active", "InActive"]);

  const getSeverity = (status: string) => {
    switch (status) {
      case "Active":
        return "#008000";
      case "InActive":
        return "#FF0000";
      default:
        return "processing";
    }
  };

  const handleClose = async () => {
    await setOpenAddNewCarouselModal(false);
    await setOpenEditCarouselModal(false);
    form.resetFields();
    addCarousel.reset();
    editCarousel.reset();
  };

  const imageBodyTemplate = (carousalDetails: ICarouselData) => {
    return (
      <img
        src={carousalDetails.icon}
        className="w-10 h-10  shadow-2 border-round"
      />
    );
  };

  const onGlobalFilterChange = (e: string) => {
    const _filters = { ...filters };
    // @ts-ignore
    _filters.global.value = e;

    setFilters(_filters);
    setGlobalFilterValue(e);
  };

  const handleAddCarousel = async (carousel: IAddCarouselData) => {
    if (carousel) {
      try {
        setOpenAddNewCarouselModal(false);
        await addCarousel.mutateAsync(carousel);
        message.success("Carousel saved successfully");
        await refetch();
      } catch (error) {
        message.error("Failed to save carousel");
        console.log(error);
      }
    } else {
      message.error("Please ensure all fields are filed correctly");
    }
  };

  const handleEditdCarousel = async (carousel: IEditCarouselData) => {
    console.log(carousel);
    if (carousel) {
      try {
        setOpenEditCarouselModal(false);
        await editCarousel.mutateAsync(carousel);
        message.success("Carousel saved successfully");
        await refetch();
      } catch (error) {
        message.error("Failed to save carousel");
        console.log(error);
      }
    } else {
      message.error("Please ensure all fields are filed correctly");
    }

    console.log(carousel);
  };
  const createdAtDateBodyTemplate = (rowData: ICarouselData) => {
    return moment(rowData.createdAt).format("YYYY/MM/DD");
  };

  const updatedAtDateBodyTemplate = (rowData: ICarouselData) => {
    return moment(rowData.updatedAt).format("YYYY/MM/DD");
  };

  const actionTypeBodyTemplate = (rowData: ICarouselData) => {
    let action = "";
    switch (rowData.actionType.toString()) {
      case "0":
        action = "Community Feed";
        break;
      case "1":
        action = "Panic";
        break;
      case "2":
        action = "Subscription";
        break;
      case "3":
        action = "Track Me";
        break;
      case "4":
        action = "Cell Find";
        break;
      case "5":
        action = "Crime Stats";
        break;
      case "6":
        action = "Report Crime";
        break;
      case "7":
        action = "Profile";
        break;
      case "8":
        action = "Physical Button";
        break;
      case "9":
        action = "Communities";
        break;
      case "10":
        action = "Emergencies";
        break;
      default:
        action = "N/A";
        break;
    }

    return action;
  };

  const titleBodyTemplate = (rowData: ICarouselData) => {
    return rowData.title;
  };

  const callToactionBodyTemplate = (rowData: ICarouselData) => {
    return rowData.callToAction;
  };

  const statusBodyTemplate = (rowData: ICarouselData) => {
    let status = rowData.status;
    return <Tag color={getSeverity(status)}>{status}</Tag>;
  };

  const statusItemTemplate = (status: string) => {
    return <Tag color={getSeverity(status)}>{status}</Tag>;
  };

  const actionBodyTemplate = (rowData: ICarouselData) => {
    return (
      <div style={{ minWidth: "50px" }}>
        <Button
          type="text"
          icon={
            <CustomIcons.PencilIcon
              width={20}
              height={20}
              viewBox={null}
              svgColor={Colors.appMainColor}
            />
          }
          style={{ color: `${Colors.appMainColor}` }}
          onClick={() => {
            setOpenEditCarouselModal(true);
            setSelectedRow(rowData.id);
            setId(rowData.id);
          }}
        />
      </div>
    );
  };

  const statusFilterTemplate = (
    options: ColumnFilterElementTemplateOptions
  ) => {
    return (
      <Dropdown
        value={options.value}
        options={status}
        onChange={(e: DropdownChangeEvent) =>
          options.filterCallback(e.value, options.index)
        }
        itemTemplate={statusItemTemplate}
        placeholder="Select Status"
        className="p-column-filter"
        showClear
      />
    );
  };

  const createdAtDateFilterTemplate = (
    options: ColumnFilterElementTemplateOptions
  ) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e: CalendarChangeEvent) => {
          options.filterCallback(e.value, options.index);
        }}
        dateFormat="yy/mm/dd"
        placeholder="yyyy/mm/dd"
        mask="9999/99/99"
      />
    );
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      createdAt: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      title: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      callToAction: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      status: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
    });
    setGlobalFilterValue("");
  };

  const getSlides = (data: ICarouselData[]) => {
    if (Array.isArray(data)) {
      return [...(data || [])].map((d) => {
        // @ts-ignore
        d.createdAt = new Date(d.createdAt);
        return d;
      });
    }
    return [];
  };

  useEffect(() => {
    if (data) {
      setSlides(getSlides(data));
    }

    initFilters();
  }, [data]);

  return (
    <div className="flex w-full h-full bg-backgroundColor">
      <div className="w-full h-full max-h-screen overflow-y-auto">
        <HeaderComponent title="Carousel Manager" />
        <div className="w-full h-full px-4">
          <div className="flex flex-row justify-end w-full gap-1 mb-2 align-items-center">
            <Button
              type="primary"
              ghost
              size="large"
              className="w-2 ml-2"
              onClick={() => setOpenAddNewCarouselModal(true)}
            >
              Add new Carousel Slide
            </Button>

            <CustomInputField
              value={globalFilterValue}
              onChange={(e: string) => onGlobalFilterChange(e)}
              onPressEnter={() => {}}
              inputProps={{
                size: "large",
                placeholder: "Search",
                allowClear: true,
                className: "ml-2 w-2",
                prefix: <SearchOutlined />,
              }}
              theme={CustomInputField}
              mergeThemes
            />
          </div>

          <DataTable
            ref={dt}
            value={slides}
            paginator
            filters={filters}
            rows={10}
            rowsPerPageOptions={[10, 25, 50, 100]}
            dataKey="id"
            globalFilterFields={[
              "createdAt",
              "title",
              "callToAction",
              "status",
            ]}
            emptyMessage="No carousel slides found."
            rowHover
            scrollable
            scrollHeight="40rem"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Carousel items"
            stateStorage="session"
            stateKey="dt-state-carousel-table"
            className="mx-3"
          >
            <Column
              field="createdAt"
              body={createdAtDateBodyTemplate}
              header="Created At"
              headerStyle={tableHeader}
              filterField="createdAt"
              dataType="date"
              bodyStyle={tableBody}
              style={{ background: "#FBFBFB" }}
              filter
              filterElement={createdAtDateFilterTemplate}
            />
            <Column
              field="updatedAt"
              body={updatedAtDateBodyTemplate}
              header="Updated At"
              headerStyle={tableHeader}
              bodyStyle={tableBody}
              style={{ background: "#FBFBFB" }}
            />
            <Column
              field="title"
              header="Title"
              headerStyle={tableHeader}
              bodyStyle={tableBody}
              body={titleBodyTemplate}
              style={{ background: "#FBFBFB" }}
              filter
            />
            <Column
              field="actionType"
              header="Action Type"
              body={actionTypeBodyTemplate}
              headerStyle={tableHeader}
              bodyStyle={tableBody}
              style={{ background: "#FBFBFB" }}
            />
            <Column
              field="callToAction"
              header="Call To Action"
              headerStyle={tableHeader}
              bodyStyle={tableBody}
              style={{ background: "#FBFBFB" }}
              filter
              body={callToactionBodyTemplate}
            />
            <Column
              field="status"
              body={statusBodyTemplate}
              header="Status"
              headerStyle={tableHeader}
              bodyStyle={tableBody}
              style={{ background: "#FBFBFB" }}
              filter
              filterElement={statusFilterTemplate}
            />
            <Column
              header="Image"
              body={imageBodyTemplate}
              headerStyle={tableHeader}
              bodyStyle={tableBody}
              style={{ background: "#FBFBFB" }}
            />
            <Column
              headerStyle={tableHeader}
              exportable={false}
              body={actionBodyTemplate}
              bodyStyle={tableBody}
              style={{ background: "#FBFBFB" }}
            />
          </DataTable>
        </div>
      </div>
      <Modal
        centered
        open={openAddNewCarouselModal}
        onOk={() => setOpenAddNewCarouselModal(false)}
        onCancel={() => setOpenAddNewCarouselModal(false)}
        width={550}
        closeIcon={
          <CustomIcons.CloseIcon
            width={30}
            height={30}
            viewBox={null}
            svgColor="#1C274C"
          />
        }
        footer={null}
      >
        <div className="flex w-full items-center">
          <img
            src={require("../../assets/images/SaferCity.png")}
            height={50}
            width={40}
            alt="Safer City Badge"
          />
          <div
            className="w-12 flex justify-content-start py-3"
            style={modelTitle}
          >
            Carousel
          </div>
        </div>
        <NewCarouselForm
          onFormFinish={async (carousel: IAddCarouselData) =>
            await handleAddCarousel(carousel)
          }
          onModalClose={() => {
            handleClose();
          }}
          isLoading={addCarousel.isLoading}
          isSuccess={addCarousel.isSuccess}
        />
      </Modal>
      <Modal
        centered
        open={openEditCarouselModal}
        onOk={() => setOpenEditCarouselModal(false)}
        onCancel={() => setOpenEditCarouselModal(false)}
        width={550}
        closeIcon={
          <CustomIcons.CloseIcon
            width={30}
            height={30}
            viewBox={null}
            svgColor="#1C274C"
          />
        }
        footer={null}
      >
        <div className="flex w-full items-center">
          <img
            src={require("../../assets/images/SaferCity.png")}
            height={50}
            width={40}
            alt="Safer City Badge"
          />
          <SubHeading className="ml-2 pt-3">Edit Carousel</SubHeading>
        </div>
        <EditCarousel
          id={selectedRow}
          onModalClose={handleClose}
          onFormFinish={(editcarousel: IEditCarouselData) =>
            handleEditdCarousel(editcarousel)
          }
          isLoading={editCarousel.isLoading}
          isSuccess={editCarousel.isSuccess}
        />
      </Modal>
    </div>
  );
};

export default CarouselManager;

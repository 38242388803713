import styled, { CSSProperties } from "styled-components";
import Colors from "../../config/Colors";

export const WINDOW_WIDTH = window.innerWidth;
export const WINDOW_HEIGHT = window.innerHeight;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: WINDOW_WIDTH;
  height: WINDOW_HEIGHT;
  color: black;
  background: ${Colors.backgroundColor};
`;

export const BodyContainer = styled.div`
  justify-content: space-between;
  p {
    font-weight: bold;
  }
  overflow-y: auto;
  width: 90%;
  max-height: 100vh;
`;

export const SubHeading = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #515151;
`;
export const tableHeader: CSSProperties = {
  color: `${Colors.mainTextColor}`,
  fontWeight: 600,
  fontSize: 18,
  fontStyle: "normal",
  background: "#F1F0F0",
};

export const tableName: CSSProperties = {
  color: `${Colors.mainTextColor}`,
  fontWeight: 600,
  fontSize: 24,
  fontStyle: "normal",
};

export const tableBody: CSSProperties = {
  color: `${Colors.mainTextColor}`,
  fontWeight: 400,
  fontSize: 18,
  fontStyle: "normal",
};

export const ActionBodyText = styled.p`
  font-style: normal;
  font-size: 18;
  color: ${Colors.appMainColor};
  text-decoration: underline;
  justify-content: center;
  align-items: center;
`;

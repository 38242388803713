/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
import { API, API_2, APILocal, APIStaging } from "../config/axios";

class ResponderVehicles {
  getResponderCompanyVehicles = async (
    pageNumber: number,
    pageSize: number,
    companyId: number,
    search?: string
  ) => {
    const { data } = await API.get<PaginatedResponderCompaniesVehiclesResponse>(
      `Admin_ResponderVehicles/company?pageNumber=${pageNumber}&pageSize=${pageSize}&companyId=${companyId}&search=${search}`
    );
    return data;
  };

  async createVehicle(payload: IEditResponderVehicle) {
    try {
      const response = await API_2.post<APIResult<IEditResponderVehicle>>(
        `/Admin_ResponderVehicles`,
        payload
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async updateVehicle(payload: IEditResponderVehicle) {
    try {
      const response = await API_2.put<APIResult<IEditResponderVehicle>>(
        `/Admin_ResponderVehicles`,
        payload
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}

export default new ResponderVehicles();

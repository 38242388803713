import React, { useEffect, useState } from "react";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import Colors from "@/config/Colors";
import {
  useGetResponderCardData,
  useGetResponderIncidentPieGraph,
  useGetResponderMapData,
  useGetResponderTopCompanies,
  useGetResponderCallOutsTable,
  useGetResponderTableData,
} from "@/hooks/useResponderAnalytics";

interface DispatchSource {
  name: string;
  id: number;
}

type DispatchSourceFilterProps = {
  onFilterChange: (name: string, value: any[]) => void;
};

const DispatchSourceDropDown = ({
  onFilterChange,
}: DispatchSourceFilterProps) => {
  const { data, isLoading, isFetching } = useGetResponderTableData();
  const [selectedSourceIds, setSelectedSourceIds] = useState<number[]>([]);
  const [selectedSource, setSelectedSources] = useState<DispatchSource[]>([]);

  const onSelectionChange = (e: MultiSelectChangeEvent) => {
    let newSourceIds: number[] = [];
    if (Array.isArray(e.value)) {
      setSelectedSources(e.value.map((op: DispatchSource) => op));
      setSelectedSourceIds(e.value.map((op: DispatchSource) => op.id));
      newSourceIds = e.value.map((op: DispatchSource) => op.id);
    }
    onFilterChange("dispatchSourceIds", newSourceIds);
  };
  const dispatchSources: DispatchSource[] = data?.panicTypes || [];

  return (
    <div className="responderAnalytics flex h-20 w-3 py-1 px-1 mr-0.5 bg-appMainColor justify-center items-center flex-shrink-0">
      <MultiSelect
        value={selectedSource}
        onChange={onSelectionChange}
        options={dispatchSources}
        optionLabel="name"
        filter
        placeholder="Dispatch Source"
        maxSelectedLabels={3}
        style={{
          backgroundColor: Colors.appMainColor,
          borderColor: Colors.appMainColor,
          color: "#fff",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    </div>
  );
};

export default DispatchSourceDropDown;

/* eslint-disable react/no-array-index-key */
import React, { useContext } from "react";
import { Input, Button, App } from "antd";
import InfiniteScroll from "react-infinite-scroller";
import { useInfiniteQuery } from "@tanstack/react-query";
import Communities from "../../services/Communities";
import { CustomIcons } from "../../assets";
import Colors from "../../config/Colors";
import SkeletonComment from "../widgets/skeletons/SkeletonComment";
import CommentCard from "../Cards/CommentCard/CommentCard";
import AuthContext from "../../contexts/AuthContext";
import { usePostComment } from "../../hooks/useCommunities";

const { TextArea } = Input;

const ContainerHeight = 420;

type CommentsProps = {
  postId: string | number;
  communityId?: number | null;
  visible: boolean;
};

function SaferCityComments({ postId, visible, communityId }: CommentsProps) {
  const { user } = useContext(AuthContext);

  const [commentText, setCommentText] = React.useState("");
  const postComment = usePostComment();

  const { message } = App.useApp();

  const limit = 5;
  const sortBy = "old";

  const {
    data: QueryData,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery(
    ["getSaferCityPostComments", postId], // unique key
    ({ pageParam = 0 }) =>
      Communities.getSaferCityPostComments(postId, limit, pageParam, sortBy), // query function
    {
      getNextPageParam: (lastPage: ICommentsResponse) => {
        // Calculate the next page number based on the skip and limit values.
        const nextPage = Math.ceil(
          (lastPage.skip + lastPage.limit) / lastPage.limit
        );
        // Return the next page number if there are more comments to fetch.
        return lastPage.hasMore ? nextPage : undefined;
      },

      enabled: visible,
    }
  );

  if (isLoading) {
    return (
      <div className="flex flex-row items-center self-center justify-center w-full h-48">
        {/* display loader here */}
        <CustomIcons.EOSloading
          width={30}
          height={30}
          svgColor={Colors.appMainColor}
        />
      </div>
    );
  }
  const loadFunc = () => {
    if (hasNextPage) {
      fetchNextPage();
    } else {
      message.info("That's all, nothing more 🤐");
    }
  };

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCommentText(e.target.value);
  };

  const handlePost = async () => {
    const state = commentText;
    setCommentText("");
    try {
      const commentBody: IPostComment = {
        content: state,
        userId: user?.id || 0,
        postId,
      };
      message.loading("Posting comment...");
      await postComment.mutateAsync(commentBody);
      refetch();
      message.success("Comment posted successfully");
    } catch (error) {
      setCommentText(state);
    }
  };

  const flattenedComments =
    QueryData?.pages.flatMap((page) => page.comments) || [];

  return (
    <div className="flex flex-col w-full h-full overflow-hidden">
      <p className="px-0 py-1 mb-0 text-xl text-appMainColor text-start">
        Comments
      </p>
      <div
        style={{ maxHeight: "calc(62vh - 100px)" }}
        className="flex-grow overflow-y-auto disable-scrollbars"
      >
        <InfiniteScroll
          pageStart={0}
          loadMore={loadFunc}
          hasMore={
            QueryData?.pages[QueryData.pages.length - 1].hasMore || false
          }
          loader={
            <div className="pb-2" key={0}>
              <SkeletonComment height={200} />
            </div>
          }
          useWindow={false}
        >
          {flattenedComments.map((comment, index) => (
            <CommentCard comment={comment} key={index} />
          ))}
        </InfiniteScroll>
      </div>
      {/* display input field */}
      <div className="flex flex-row items-center justify-between w-full gap-2 py-2">
        <TextArea
          showCount
          maxLength={240}
          onChange={onChange}
          value={commentText}
          autoSize={{ minRows: 2, maxRows: 3 }}
          className="ml-1"
        />
        <Button
          ghost
          className="font-bold text-appMainColor"
          loading={postComment.isLoading}
          onClick={handlePost}
        >
          Post
        </Button>
      </div>
    </div>
  );
}

export default React.memo(SaferCityComments);

/* eslint-disable max-len */
import React from "react";
import Colors from "../../config/Colors";

export function PencilIcon({
  height, // number type by default
  width,
  viewBox = "0 0 22 22",
  svgColor = `${Colors.appMainColor}`,
}: CustomSVGProp) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox={viewBox || "0 0 22 22"}
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7566 1.62145C15.5852 -0.207149 18.55 -0.207149 20.3786 1.62145C22.2072 3.45005 22.2072 6.41479 20.3786 8.24339L10.8933 17.7287C10.3514 18.2706 10.0323 18.5897 9.67745 18.8665C9.25915 19.1927 8.80655 19.4725 8.32766 19.7007C7.92136 19.8943 7.49334 20.037 6.76623 20.2793L3.43511 21.3897L2.63303 21.6571C1.98247 21.8739 1.26522 21.7046 0.780321 21.2197C0.29542 20.7348 0.126102 20.0175 0.342956 19.367L1.72068 15.2338C1.96303 14.5067 2.10568 14.0787 2.29932 13.6724C2.52755 13.1935 2.80727 12.7409 3.13354 12.3226C3.41035 11.9677 3.72939 11.6487 4.27137 11.1067L13.7566 1.62145ZM3.40051 19.8201L6.24203 18.8729C7.03314 18.6092 7.36927 18.4958 7.68233 18.3466C8.06287 18.1653 8.42252 17.943 8.75492 17.6837C9.02837 17.4704 9.28013 17.2205 9.86979 16.6308L17.4393 9.06137C16.6506 8.78321 15.6346 8.26763 14.6835 7.31651C13.7324 6.36538 13.2168 5.34939 12.9387 4.56075L5.36917 12.1302C4.77951 12.7199 4.52959 12.9716 4.3163 13.2451C4.05704 13.5775 3.83476 13.9371 3.65341 14.3177C3.50421 14.6307 3.3908 14.9669 3.12709 15.758L2.17992 18.5995L3.40051 19.8201ZM14.1554 3.34404C14.1896 3.519 14.2474 3.75684 14.3438 4.03487C14.561 4.66083 14.9712 5.48288 15.7442 6.25585C16.5171 7.02881 17.3392 7.43903 17.9651 7.6562C18.2432 7.75266 18.481 7.81046 18.656 7.84466L19.3179 7.18272C20.5607 5.93991 20.5607 3.92492 19.3179 2.68211C18.0751 1.4393 16.0601 1.4393 14.8173 2.68211L14.1554 3.34404Z"
        fill={svgColor || "#1757A2"}
      />
    </svg>
  );
}
export default PencilIcon;

/* eslint-disable no-unused-vars */
export const BaseUrl = process.env.REACT_APP_URL;
export const StagingUrl = "https://safercityapi-staging.azurewebsites.net/api";
export const LocalHost = "https://localhost:7224/api";
export const APP_LOCAL_URL_HOST = "https://localhost:7224";
export const APP_URL_HOST_S = "https://safercityapi-staging.azurewebsites.net";
export const APP_URL_HOST = "https://safercityapi.azurewebsites.net"; // change this !!!
export const uploadUrl =
  "https://safercityapi.azurewebsites.net/api/util/post/document";
// eslint-disable-next-line import/prefer-default-export

export const apiUrl = BaseUrl;

import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
  useInfiniteQuery,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { ResponderCompany } from "@/services";
import { toast } from "sonner";
import { queries } from "../services/queries";

export const useAllResponderCompanies = () => {
  return useQuery({
    ...queries.responderCompanies.all,
    queryFn: () => ResponderCompany.getAllResponderCompanies(),
    refetchOnWindowFocus: false,
  });
};

export const useOverViewResponderCompanies = (
  pageSize: number,
  name: string | null = null
) => {
  return useInfiniteQuery(
    ["responderCompanies", { pageSize, name }],
    ({ pageParam = 1 }) =>
      ResponderCompany.getOverViewCompanies(pageParam, pageSize, name),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return lastPage.hasNextPage ? nextPage : undefined; // Assuming lastPage includes hasNextPage indicator
      },
    }
  );
};

export const useCreateResponderCompany = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (company: IEditResponderCompany) => ResponderCompany.createCompany(company),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["responderCompanies"]);
        return data;
      },
      onError: (err: Error) => {
        if (err.message) {
          toast.error(err.message);
        } else {
          toast.error("An error occurred");
        }
      },
      meta: {
        useError: true,
      },
    }
  );
};

export const useUpdateResponderCompany = () => {
  const queryClient = useQueryClient();
  return useMutation<
    APIResult<IEditResponderCompany>,
    Error,
    IEditResponderCompany
  >(ResponderCompany.updateCompany, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["responderCompanies"]);
      return data;
    },
    onError: (err: Error) => {
      if (err.message) {
        toast.error(err.message);
      } else {
        toast.error("An error occurred");
      }
    },
    meta: {
      useError: true,
    },
  });
};

import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { queries } from "../services/queries";
import Companies from "../services/Companies";

export function useCompanyCardsData(): UseQueryResult<ICompanyCard[]> {
  return useQuery({
    ...queries.companies.getCompanyCardsData,
    queryFn: () => Companies.getCompanyCardsData(),
  });
}

export function useGetCompanies(): UseQueryResult<IActiveCompanies[]> {
  return useQuery({
    ...queries.companies.getCompanies,
    queryFn: () => Companies.getCompanies(),
  });
}

export function useGetCompanyMemberData(
  id: string | number,
  pageNumber: string | number | null,
  pageSize: number | string | null = 6,
  userName?: string | null,
  subscriptionName?: string | null,
  status?: number | null,
  turnover?: boolean | null
): UseQueryResult<IPaginatedCompanyMembersResponse> {
  return useQuery({
    ...queries.companies.getCompanyMembersData(
      id,
      pageNumber,
      pageSize,
      userName,
      subscriptionName,
      status,
      turnover
    ),
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
  });
}

export function useGetCompanyPaymentsData(
  id: string | number
): UseQueryResult<ICompanyPayments[]> {
  return useQuery({
    ...queries.companies.getCompanyPaymentsData(id),
  });
}

export function useFetchEditComapnyData(
  companyId: string | number
): UseQueryResult<IEditCompany> {
  return useQuery({
    ...queries.companies.getEditCompanyData(companyId),
  });
}

export function useAddCompany() {
  const queryClient = useQueryClient();

  return useMutation(Companies.addCompany, {
    meta: {
      conflict: "Found whilst making changes",
      badRequest: "Please make sure all fields are entered correctly.",
      notFound: "Company could not be found. Please try again",
    },
  });
}

export function useCompanyRenewal() {
  const queryClient = useQueryClient();

  return useMutation(Companies.companyRenewal, {
    meta: {
      conflict: "Found whilst making changes",
      badRequest: "Please make sure all fields are entered correctly.",
      notFound: "Company could not be found. Please try again",
    },
  });
}
export function useDeactivateCompany() {
  return useMutation(
    async (companyId: string | undefined) =>
      Companies.deactivateCompany(companyId),
    {
      meta: {
        conflict: "Found whilst making changes",
        badRequest: "Please make sure all fields are entered correctly.",
        notFound: "Company could not be found. Please try again",
      },
    }
  );
}

export function useCancelCompanyImmediately() {
  return useMutation(
    async (companyId: string) => Companies.cancelCompanyImmediately(companyId),
    {
      meta: {
        conflict: "Found whilst making changes",
        badRequest: "Please make sure all fields are entered correctly.",
        notFound: "Company could not be found. Please try again",
      },
    }
  );
}

export function useScheduleCompanyCancel() {
  return useMutation(
    async ({ companyId, cancelDate }: IScheduleCancelParams) => {
      return Companies.scheduleCancelCompany(companyId, cancelDate);
    },
    {
      meta: {
        conflict: "Found whilst making changes",
        badRequest: "Please make sure all fields are entered correctly.",
        notFound: "Company could not be found. Please try again",
      },
    }
  );
}

export function useReactivateCompany() {
  return useMutation(
    async (companyId: string | null) => Companies.ReactivateCompany(companyId),
    {
      meta: {
        conflict: "Found whilst making changes",
        badRequest: "Please make sure all fields are entered correctly.",
        notFound: "Company could not be found. Please try again",
      },
    }
  );
}

export function useAddCompanyMembers() {
  return useMutation(
    async (company: ICompanyMembers) => Companies.AddCompanyMembers(company),
    {
      meta: {
        conflict: "Found whilst making changes",
        badRequest: "Please make sure all fields are entered correctly.",
        notFound: "Company could not be found. Please try again",
      },
    }
  );
}

export function useEditCompany() {
  const queryClient = useQueryClient();
  return useMutation(
    async (company: IEditCompany) => Companies.editCompany(company),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queries.companies.getCompanyCardsData);
      },
      meta: {
        conflict: "Found whilst making changes",
        badRequest: "Please make sure all fields are entered correctly.",
        notFound: "Company could not be found. Please try again",
      },
    }
  );
}

export function useAddCompanyPayment() {
  const queryClient = useQueryClient();

  return useMutation(Companies.AddCompanyPayment, {
    meta: {
      conflict: "Found whilst making changes",
      badRequest: "Please make sure all fields are entered correctly.",
      notFound: "Company could not be found. Please try again",
    },
  });
}

export function useGetPaginatedCompanyCardsData(
  pageNumber: string | number | null,
  pageSize: number | string | null,
  name: string | null
): UseQueryResult<ICompanyCardResponse> {
  return useQuery({
    ...queries.companies.getCompanyPaginatedCardsData(
      pageNumber,
      pageSize,
      name
    ),
    refetchOnWindowFocus: false,
  });
}

import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import Colors from "@/config/Colors";

ChartJS.register(ArcElement, Tooltip, Legend);

interface IResponderDoughnutAnalyticsProps {
  doughnutAnalytics: IIncidentPieGraph;
}

export default function ResponderCallOutDoughnut({
  doughnutAnalytics,
}: IResponderDoughnutAnalyticsProps) {
  const data = {
    labels: [
      "Security",
      "Medical",
      "Test Call out",
      "Duplicate Activation",
      "False/Accidental Panic",
      "Existing User Test Call out",
      "Domestic violence",
      "Dispute",
      "Property/Malicious damage",
      "Assault Robbery",
      "Armed Robbery",
      "Break-in",
      "Suspicious Activity",
      "Vandalism",
      "Protests",
      "Medical: Seizure(fits)",
      "Medical: Breathing Difficulty(SOB)",
      "Medical: Suicide/Attempted Suicide",
      "User not answering phone",
      "Other",
    ],
    datasets: [
      {
        label: "# of Incident types",
        data: [
          doughnutAnalytics.Security as number,
          doughnutAnalytics.Medical as number,
          doughnutAnalytics["Test Call out"] as number,
          doughnutAnalytics["Duplicate Activation"] as number,
          doughnutAnalytics["False/Accidental Panic"] as number,
          doughnutAnalytics["Existing User Test Call out"] as number,
          doughnutAnalytics["Domestic violence"] as number,
          doughnutAnalytics.Dispute as number,
          doughnutAnalytics["Property/Malicious damage"] as number,
          doughnutAnalytics["Assault Robbery"] as number,
          doughnutAnalytics["Armed Robbery"] as number,
          doughnutAnalytics["Break-in"] as number,
          doughnutAnalytics["Suspicious Activity"] as number,
          doughnutAnalytics["Vandalism"] as number,
          doughnutAnalytics["Protests"] as number,
          doughnutAnalytics["Medical: Seizure(fits)"] as number,
          doughnutAnalytics["Medical: Breathing Difficulty(SOB)"] as number,
          doughnutAnalytics["Medical: Suicide/Attempted Suicide"] as number,
          doughnutAnalytics["User not answering phone"] as number,
          doughnutAnalytics.Other as number,
        ],
        backgroundColor: [
          "#CC3300",
          "#F79009",
          "#99C7EB",
          "#1757A2",
          "#FF5733",
          "#C70039",
          "#900C3F",
          "#581845",
          "#1A5276",
          "#2471A3",
          "#2E86C1",
          "#AED6F1",
          "#A569BD",
          "#884EA0",
          "#633974",
          "#17202A",
          "#566573",
          "#FF7F50",
          "#00FA9A",
          "#FFD700",
        ],
        borderColor: [
          "#CC3300",
          "#F79009",
          "#99C7EB",
          "#1757A2",
          "#FF5733",
          "#C70039",
          "#900C3F",
          "#581845",
          "#1A5276",
          "#2471A3",
          "#2E86C1",
          "#AED6F1",
          "#A569BD",
          "#884EA0",
          "#633974",
          "#17202A",
          "#566573",
          "#FF7F50",
          "#00FA9A",
          "#FFD700",
        ],
        borderWidth: 1,
        hoverBorderWidth: 20,
      },
    ],
  };
  return (
    <div
      style={{ background: "#FFFFFF" }}
      className="shadow-5 surface-card border-round px-3 "
    >
      <div className="w-12 flex flex-row justify-between items-center">
        <div
          className="pt-3"
          style={{
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "18px",
            lineHeight: "27px",
            color: `${Colors.mainTextColor}`,
          }}
        >
          Call-out Activity
        </div>
      </div>
      <div className="pb-2">
        <Doughnut
          data={data}
          options={{
            responsive: true,
            aspectRatio: 2,
            maintainAspectRatio: true,
            plugins: {
              legend: {
                display: true,
                position: "right" as const,
                align: "center",
                labels: {
                  usePointStyle: true,
                  padding: 30,
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
}

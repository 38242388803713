import React, { useEffect, useState } from "react";
import { FileUp } from "lucide-react";
import { Upload, Form, Button, Input, Modal, Select, Tabs, Tag } from "antd";
import { UploadFile, RcFile, UploadProps } from "antd/es/upload/interface";
import moment from "moment";
import { toast } from "sonner";

import { useModal } from "@/hooks/useModalStore";
import { uploadUrl } from "@/config/urls";
import { CustomIcons } from "@/assets/index";
import { modelTitle } from "@/styles/ModalStyles";
import MomentAntdDateTimePicker from "@/components/widgets/DateTimePicker";
import { usePanicTypes } from "@/hooks/usePanicTypes";
import { useEditResponder } from "@/hooks/useResponder";
import CommonUserFields from "../Forms/CommonUserFields";

const { Option } = Select;
const { TabPane } = Tabs;
// Filter `option.label` match the user type `input`
const filterOption = (
  input: string,
  option?: { id?: number; label: string; value: string } | any
) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

const dateFormat = "YYYY-MM-DD";

function EditResponderModal() {
  const panicTypes = usePanicTypes();
  const useEditMutation = useEditResponder();
  const { isOpen, onClose, type, data } = useModal();
  const [form] = Form.useForm();
  const [medicalLicenseUri, setMedicalLicenseUri] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [selectedPanicTypes, setSelectedPanicTypes] = useState<
    IResponderResponsePanicTypes[]
  >([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isLicenseValid, setIsLicenseValid] = useState(true); // Add this line
  const [isPsiraValid, setIsPsiraValid] = useState(true);

  const [activeTabKey, setActiveTabKey] = useState("1");

  const isModalOpen = isOpen && type === "editResponder";

  const editDetails = data.editResponder ? data.editResponder : null; // TODO: Replace with actual edit details

  const isValidDate = (validToDate: string | Date) => {
    return moment(validToDate).isAfter(moment());
  };
  const handleReset = () => {
    form.resetFields();
    setSelectedPanicTypes([]); //
    setMedicalLicenseUri("");
    setFileList([]);
    setActiveTabKey("1");
    setIsLicenseValid(true);
    setIsPsiraValid(true);
    setIsUploading(false);
    useEditMutation.reset();
  };

  // Use useEffect to set medicalLicenseUri if editing
  useEffect(() => {
    if (isModalOpen) {
      if (editDetails) {
        form.setFieldsValue(editDetails); // Make sure editDetails structure matches form fields
        // Update PhoneNumberPrefixSelector based on editDetails
        if (editDetails.phoneNumber) {
          const prefix = editDetails.phoneNumber.slice(0, 3); // Extract the country code
          form.setFieldsValue({ prefix }); // Set the country code
          form.setFieldsValue({
            phoneNumber: editDetails.phoneNumber.slice(3),
          }); // Set the phone number without the country code
        }
        // Update medicalLicenseUri and fileList based on editDetails
        if (editDetails.medicalLicense) {
          setMedicalLicenseUri(editDetails.medicalLicense);
          // If you also need to display the certificate in an Upload component,
          const initialFileList = [
            {
              uid: "-1", // A temporary UID for the initial file
              name: "License.pdf", // Extract or set a meaningful name if possible
              status: "done",
              url: editDetails.medicalLicense, // The URL or path of the uploaded file
            },
          ] as const;
          setFileList([...initialFileList]);
          form.setFieldsValue({
            medicalLicense: initialFileList,
          }); // Make sure medicalLicense is set
        }
        if (editDetails && editDetails.panicTypes) {
          setSelectedPanicTypes(editDetails.panicTypes);
          form.setFieldsValue({
            panicTypes: editDetails.panicTypes.map((pt) => pt.id),
          });
          // Set default values for the panics
          editDetails.panicTypes.forEach((pt) => {
            form.setFieldsValue({
              [`status-${pt.id}`]: pt.status,
              [`reason-${pt.id}`]: pt.reason,
            });
          });
        }

        if (editDetails.companyId) {
          form.setFieldsValue({ companyId: editDetails.companyId }); // Make sure companyId is set
        }
        // Ensure editDetails.dateOfBirth is properly formatted as a moment object
        const formattedDateOfBirth = editDetails.dateOfBirth
          ? moment(editDetails.dateOfBirth, dateFormat)
          : null;
        form.setFieldsValue({
          dateOfBirth: formattedDateOfBirth,
        });
        if (editDetails.medicalLicenseExpiryDate) {
          const formattedValidToDate = moment(
            editDetails.medicalLicenseExpiryDate,
            dateFormat
          );
          form.setFieldsValue({
            medicalLicenseExpiryDate: formattedValidToDate,
          });
          // Check if the medical license is valid or not
          const isValid = isValidDate(editDetails.medicalLicenseExpiryDate);
          setIsPsiraValid(isValid);
          if (!isValid) {
            form.setFieldsValue({
              status: "Expired",
            });
          }
        }
        if (editDetails.psiraExpiryDate) {
          const formattedValidToDate = moment(
            editDetails.psiraExpiryDate,
            dateFormat
          );
          form.setFieldsValue({
            psiraExpiryDate: formattedValidToDate,
          });
          const isValid = isValidDate(editDetails.psiraExpiryDate);
          setIsLicenseValid(isValid);
          if (!isValid) {
            form.setFieldsValue({
              status: "Expired",
            });
          }
        }
      } else {
        // Reset for add new
        handleReset();
      }
    } else {
      // Reset whenever modal is closed
      handleReset();
    }
  }, [isModalOpen, editDetails, form]);

  const handleClose = async () => {
    handleReset();
    onClose();
  };

  const handleTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  const beforeCertificateUpload = (file: RcFile) => {
    const isPdfOrWord = ["application/pdf"].includes(file.type);
    const isLt15M = file.size / 1024 / 1024 < 15;
    if (!isPdfOrWord) {
      toast.error("You can only upload PDF file!");
      return false; // This prevents the file from being added to the list
    }
    if (!isLt15M) {
      toast.error("file must smaller than 15MB!");
      return false; // This prevents the file from being added to the list
    }
    return isPdfOrWord && isLt15M;
  };

  // Base Props for common settings
  const baseUploadProps = {
    name: "file",
    action: uploadUrl,
    multiple: false,
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent: number | undefined) =>
        percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  // Props for certificate upload

  const certificateUploadProps: UploadProps = {
    ...baseUploadProps,
    onChange(info) {
      const lastSuccessfulUpload = fileList;

      const currentFile = info.file; // Get the current (latest) file
      // setFileList([currentFile]); // Keep only the latest file in the fileList
      const newFileList = [...info.fileList];

      // Update state based on the current fileList
      setFileList(newFileList);

      if (currentFile.status === "uploading") {
        setIsUploading(true);
      } else if (currentFile.status === "done") {
        // Update state to reflect the successful upload
        setIsUploading(false);
        setFileList([currentFile]); // Keep only the successfully uploaded file
        setMedicalLicenseUri(currentFile.response.path);
        form.setFieldsValue({ medicalLicense: currentFile.response.path });
        toast.success(`${currentFile.name} file uploaded successfully.`);
      } else if (currentFile.status === "error") {
        setIsUploading(false);
        toast.error(`${currentFile.name} file upload failed.`);
        // Revert to the last successful upload if the current upload fails
        if (lastSuccessfulUpload) {
          setFileList(lastSuccessfulUpload);
        } else {
          setFileList([]); // If there was no successful upload before, clear the fileList
        }
      }
    },
    beforeUpload: beforeCertificateUpload,
    fileList,
    accept: ".pdf",
  };

  const handlePanicTypeChange = (selectedIds: number[]) => {
    const updatedPanicTypes = selectedIds.map((id) => {
      const existingType = selectedPanicTypes.find((pt) => pt.id === id);
      return (
        existingType || {
          id,
          name: panicTypes.data?.find((pt) => pt.id === id)?.name || "",
          status: "Active", // Default status for new panic types
          reason: "", // Default reason for new panic types
        }
      );
    }) as IResponderResponsePanicTypes[];
    setSelectedPanicTypes(updatedPanicTypes);
  };

  const handleNextClick = () => {
    // Extract the necessary dates directly from the form for a manual check
    const medicalLicenseExpiryDate = form.getFieldValue(
      "medicalLicenseExpiryDate"
    );
    const psiraExpiryDate = form.getFieldValue("psiraExpiryDate");

    // Manually update validity states based on these values
    setIsLicenseValid(isValidDate(medicalLicenseExpiryDate));
    setIsPsiraValid(isValidDate(psiraExpiryDate));

    // List all field names in the first tab that you want to validate
    const fieldsToValidate = [
      "firstName",
      "lastName",
      "emailAddress",
      "phoneNumber",
      "dateOfBirth",
      "gender",
      "panicTypes",
      "cpfId",
      "medicalLicense",
      "medicalLicenseExpiryDate",
      "psiraNumber",
      "psiraExpiryDate",
    ];

    form
      .validateFields(fieldsToValidate)
      .then(() => {
        setActiveTabKey("2"); // Switch to the next tab only if validation passes
      })
      .catch((errorInfo) => {
        console.log("Validation Failed:", errorInfo);
      });
  };

  // Check if to disable Active Status
  const showActiveStatus = (pT: IResponderResponsePanicTypes): boolean => {
    const nameLower = pT.name.toLowerCase(); // Handle case sensitivity

    // For "medical", both the medical license and PSIRA need to be valid.
    if (nameLower.includes("medical")) {
      return isLicenseValid && isPsiraValid;
    }

    // For "security", only the PSIRA validity is considered.
    if (nameLower.includes("security")) {
      return isPsiraValid;
    }

    // Default to showing "Active" for other types, unless you have more conditions.
    return true;
  };

  const onFinish = async (formValues: any) => {
    // Extract prefix and phoneNumber for fullPhoneNumber
    const { prefix, phoneNumber, psiraNumber, ...rest } = formValues;
    const fullPhoneNumber = `${prefix}${phoneNumber}`;

    // Initialize an array to hold panic type information
    const panicTypesInfo = selectedPanicTypes.map((pt) => {
      return {
        Id: pt.id,
        Status: formValues[`status-${pt.id}`] || "Active", // Defaulting to 'Active' if not specified
        Reason: formValues[`reason-${pt.id}`] || "", // Defaulting to empty string if not specified
      };
    });

    // Construct final submission object
    const submissionData: IEditResponder = {
      ...rest, // Spread the rest of the form values
      fullPhoneNumber, // Include the combined full phone number
      panicTypes: panicTypesInfo, // Include the constructed panic types info
      medicalLicense: medicalLicenseUri,
      psira: psiraNumber,
      companyId: editDetails?.companyId,
      userId: editDetails?.userId,
    };

    // Call the edit mutation
    try {
      await useEditMutation.mutateAsync(submissionData);
      toast.success("Responder updated successfully");
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  const onValuesChange = (changedValues: any, allValues: any) => {
    if (changedValues.panicTypes) {
      handlePanicTypeChange(changedValues.panicTypes);
    }
    if (changedValues.medicalLicenseExpiryDate) {
      // Check if the medical license is valid or not
      const isValid = isValidDate(changedValues.medicalLicenseExpiryDate);
      console.log("medicalLicenseExpiryDate:ISValid====>", isValid);
      setIsLicenseValid(isValid);
    }
    if (changedValues.psiraExpiryDate) {
      const isValid = isValidDate(changedValues.psiraExpiryDate);
      setIsPsiraValid(isValid);
    }
  };

  // Before the return statement, directly access the form values to determine visibility
  // Assuming selectedPanicTypes is an array of objects with a 'name' property
  const showPRISAFields = selectedPanicTypes.some(
    (pt) =>
      pt.name.toLowerCase().includes("security") ||
      pt.name.toLowerCase().includes("medical")
  );
  const showMedicalFields = selectedPanicTypes.some((pt) =>
    pt.name.toLowerCase().includes("medical")
  );

  return (
    <Modal
      centered
      open={isModalOpen}
      onCancel={() => handleClose()}
      width={550}
      closeIcon={
        <CustomIcons.CloseIcon
          width={30}
          height={30}
          viewBox={null}
          svgColor="#1C274C"
        />
      }
      footer={null}
    >
      <div className="flex w-12 py-3 justify-content-start" style={modelTitle}>
        Edit Responder
      </div>

      <Form
        form={form}
        name="newResponder"
        onFinish={onFinish}
        layout="vertical"
        className="grid w-full"
        onValuesChange={onValuesChange}
      >
        <Tabs
          activeKey={activeTabKey}
          onChange={handleTabChange}
          tabBarExtraContent={null}
          className="w-full"
        >
          <TabPane tab="Personal Details" key="1" className="grid w-full">
            <CommonUserFields form={form} editMode useMomentDatePicker />
            <div className="col-12">
              <Form.Item
                name="panicTypes"
                label="Type of Panic"
                className="mb-1"
                rules={[
                  {
                    required: true,
                    message:
                      "Please select panics the responder can respond to!",
                  },
                ]}
              >
                <Select
                  placeholder="Select panics responder can respond to"
                  size="large"
                  mode="multiple"
                  loading={panicTypes.isLoading}
                  filterOption={filterOption}
                  placement="topLeft"
                >
                  {panicTypes.data?.map((item: IPanicTypes) => (
                    <Select.Option
                      key={`key-panicTypes-${item.id}`}
                      value={item.id}
                    >
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            {showPRISAFields && (
              <>
                <div className="col-12 md:col-6">
                  <Form.Item
                    name="psiraNumber"
                    label="PSIRA For Responder"
                    rules={[
                      {
                        required: true,
                        message: "Please input the responder psira number!",
                      },
                      {
                        pattern: /^\d{7,}$/,
                        message:
                          "Please enter a valid PSIRA number. It should be at least 7 digits long.",
                      },
                    ]}
                    className="mb-1"
                  >
                    <Input size="large" placeholder="237838345773" />
                  </Form.Item>
                </div>
                <div className="col-12 md:col-6">
                  <Form.Item
                    name="psiraExpiryDate"
                    label="PSIRA for Responder Valid To"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the responder psira valid to!",
                      },
                    ]}
                    className="mb-1"
                  >
                    <MomentAntdDateTimePicker
                      size="large"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </div>
              </>
            )}

            {showMedicalFields && (
              <div className="transition-opacity duration-500 ease-in-out opacity-100 col-12">
                <Form.Item
                  name="medicalLicense"
                  label="Medical License"
                  rules={[
                    {
                      required: true,
                      message: "Please upload medical license!",
                    },
                    // Custom validator to check if a valid certificate file has been uploaded
                    () => ({
                      validator() {
                        if (!medicalLicenseUri && !isUploading) {
                          // Check if certificate URL is not set
                          return Promise.reject(
                            new Error(
                              "Please upload a valid PDF certificate file."
                            )
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                    () => ({
                      validator() {
                        if (
                          fileList.length === 0 ||
                          fileList.some((file) => file.status !== "done")
                        ) {
                          return Promise.reject(
                            new Error(
                              "Please upload a valid PDF certificate file."
                            )
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                  className="mb-1"
                >
                  <Upload
                    {...certificateUploadProps}
                    beforeUpload={beforeCertificateUpload}
                    accept=".pdf"
                    fileList={fileList}
                  >
                    <Button
                      className="flex items-center justify-between w-12 h-12 gap-2"
                      icon={<FileUp />}
                    >
                      Click to Upload
                    </Button>
                  </Upload>
                </Form.Item>
              </div>
            )}

            {showMedicalFields && (
              <div className="ease-in-out col-12">
                <Form.Item
                  name="medicalLicenseExpiryDate"
                  label="Medical License Valid To"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please enter the responder medical license valid to!",
                    },
                  ]}
                  className="mb-3"
                >
                  <MomentAntdDateTimePicker
                    size="large"
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </div>
            )}
          </TabPane>
          <TabPane tab="Status" key="2">
            {/* Dynamically generated panic type status and reason fields */}
            {selectedPanicTypes.map((pt: IResponderResponsePanicTypes) => (
              <React.Fragment key={pt.id}>
                <div className="col-12 md:col-6">
                  <Form.Item
                    name={`status-${pt.id}`}
                    label={`Status for ${pt.name}`} // Adjust label based on panic type name
                    rules={[
                      { required: true, message: "Please select a status" },
                    ]}
                    className="mb-1"
                  >
                    <Select
                      placeholder="Select responder's status"
                      size="large"
                    >
                      {showActiveStatus(pt) && (
                        <Option value="Active">
                          <Tag color="green">Active</Tag>
                        </Option>
                      )}
                      <Option value="Expired">
                        <Tag color="yellow">Expired</Tag>
                      </Option>
                      <Option value="Suspended">
                        <Tag color="red">Suspended</Tag>
                      </Option>
                    </Select>
                  </Form.Item>
                </div>

                {!showActiveStatus(pt) && (
                  <span
                    style={{ color: "darkred", fontSize: 12 }}
                    className="py-0 mx-2 my-0"
                  >
                    Valid to dates has expired
                  </span>
                )}
                <div className="col-12">
                  <Form.Item
                    name={`reason-${pt.id}`}
                    label={`Reason for ${pt.name}`} // Adjust label based on panic type name
                    rules={[
                      {
                        required: true,
                        message: `Please enter a reason for ${pt.name}`,
                      },
                    ]}
                    className="mb-1"
                  >
                    <Input.TextArea
                      placeholder="This is the description of your decision. It should precise."
                      rows={3}
                      count={{
                        show: true,
                        max: 200,
                        strategy: (txt) => txt.length,
                        exceedFormatter: (txt, { max }) => txt.slice(0, max),
                      }}
                    />
                  </Form.Item>
                </div>
              </React.Fragment>
            ))}
          </TabPane>
        </Tabs>
        <Form.Item className="w-12 pt-2 mb-1">
          <div className="flex w-full gap-2 col-12 justify-content-end align-content-center">
            <Button
              type="primary"
              ghost
              onClick={handleClose}
              size="large"
              className="w-3"
            >
              Cancel
            </Button>
            {activeTabKey === "1" && (
              <Button
                type="primary"
                onClick={handleNextClick}
                size="large"
                className="w-3 bg-[#1757a2] shadow-none"
                disabled={isUploading} // Disable the button while uploading
              >
                Next
              </Button>
            )}
            {activeTabKey === "2" && (
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="w-3 bg-[#1757a2] shadow-none"
                disabled={isUploading} // Disable the button while uploading
                loading={useEditMutation.isLoading}
              >
                Save
              </Button>
            )}
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default EditResponderModal;

import { createQueryKeys } from "@lukemorales/query-key-factory";
import Emergencies from "../Emergencies";

export const emergencies = createQueryKeys("emergencies", {
  all: null,
  detail: (userId: string | number) => ({
    queryKey: [userId],
    queryFn: () => Emergencies.getById(userId),
  }),
  list: (filter: Filters) => ({
    queryKey: [filter],
    queryFn: (ctx) => Emergencies.getEmergenciesList(filter, ctx.pageParam),
    contextQueries: {
      search: (query: string, limit: number) => ({
        queryKey: [query, limit],
        queryFn: (ctx) =>
          Emergencies.getSearchEmergencies(filter, ctx.pageParam, limit, query),
      }),
    },
  }),
});

import React, { useEffect, useState } from "react";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import Colors from "@/config/Colors";
import { useGetResponderTableData } from "@/hooks/useResponderAnalytics";
import "./Multiselect.css";

interface Company {
  companyName: string;
  id: number;
}

type CompaniesFilterProps = {
  onFilterChange: (name: string, value: any[]) => void;
};

const CompaniesDropDown = ({ onFilterChange }: CompaniesFilterProps) => {
  const { data, isLoading, isFetching } = useGetResponderTableData();

  const companies: Company[] = data?.responderCompanies || [];
  const [selectedCompanieIds, setSelectedCompanieIds] = useState<number[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<Company[]>([]); // Provide the correct type for selectedCompanies
  const onCompanyChange = (e: MultiSelectChangeEvent) => {
    let newCompanyIds: number[] = [];
    if (Array.isArray(e.value)) {
      setSelectedCompanies(e.value.map((company: Company) => company)); // Remove the mapping to company.Id
      newCompanyIds = e.value.map((company: Company) => company.id);
      setSelectedCompanieIds(newCompanyIds); // Remove the mapping to company
      onFilterChange("companyIds", newCompanyIds);
    }
  };

  const filters: IResponderFilters = {
    companyIds: selectedCompanieIds,
  };

  return (
    <div className="responderAnalytics flex h-20 w-3 py-1 px-1 mr-0.5 bg-appMainColor justify-center items-center flex-shrink-0">
      <MultiSelect
        value={selectedCompanies}
        options={companies}
        onChange={onCompanyChange}
        optionLabel="companyName"
        filter
        placeholder="Responder Companies"
        maxSelectedLabels={3}
        style={{
          backgroundColor: Colors.appMainColor,
          borderColor: Colors.appMainColor,
          color: "#fff",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    </div>
  );
};

export default CompaniesDropDown;

import React, { useState } from "react";
import { Spin } from "antd";
import { Tabs } from "antd";
import { TabProps } from "@tremor/react";
import HeaderComponent from "../../components/Header/Header";
import { BodyContainer, Container } from "../../styles/ContainerStyles";
import ReviewPage from "./Components/ReviewPage";
import DraftsPage from "./Components/DraftsPage";
import PostedPage from "./Components/PostedPage";
import BackButton from "../../components/widgets/common/BackButton";

interface TabItem {
  key: string;
  label: string;
}

const items: TabItem[] = [
  {
    key: "1",
    label: "Review",
  },
  {
    key: "2",
    label: "Drafts",
  },
  {
    key: "3",
    label: "Posted",
  },
];

const TabItemsReportedCrimes: React.FC<TabProps> = () => {
  const handleTabChange = (newKey: string) => {
    setSelectedTab(newKey);
  };

  const [selectedTab, setSelectedTab] = useState<string>(items[0].key);
  return (
    <div className="h-full w-full">
      <Container className="h-full w-full">
        <BodyContainer className="h-full w-full">
          <div className="flex flex-row w-full justify-start align-items-center">
            <BackButton />
            <HeaderComponent title="Crime Report" />
          </div>
          <Spin tip="" spinning={false} delay={500}>
            <Tabs
              className="px-3"
              defaultActiveKey="1"
              items={items}
              onChange={handleTabChange}
            />
            <div>
              <div className="tab-content">
                {selectedTab === "1" && <ReviewPage />}
                {selectedTab === "2" && <DraftsPage />}
                {selectedTab === "3" && <PostedPage />}
              </div>
            </div>
          </Spin>
        </BodyContainer>
      </Container>
    </div>
  );
};

export default TabItemsReportedCrimes;

/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React from "react";
import { useHistory } from "react-router-dom";
import { useModal } from "@/hooks/useModalStore";
import { Menu, Settings } from "lucide-react";
import { Avatar, Tag } from "antd";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

type ResponderCompanyVehicleCardProps = {
  vehicle: IResponderVehicleDetails;
};

export function ResponderCompanyVehicleCard({
  vehicle,
}: ResponderCompanyVehicleCardProps) {
  const {
    id,
    vehicleMake,
    vehicleModel,
    vehicleYear,
    vehicleImage,
    vehicleColour,
    createdAt,
    registrationPlate,
    responderCompanyId,
    status,
  } = vehicle;
  const navigate = useHistory();
  const { onOpen } = useModal();

  const handleEdit = () => {
    onOpen("editResponderVehicle", {
      editResponderVehicle: vehicle,
      activeResponderCompanyId: responderCompanyId,
    });
  };

  const getSeverity = () => {
    switch (status) {
      case "Active":
        return { bgColor: "#D2F6C1", textColor: "#369A03" };
      case "InActive":
        return { bgColor: "#FDD6C7", textColor: "#CC3201" };
      default:
        return { bgColor: "#D2F6C1", textColor: "#369A03" };
    }
  };

  return (
    <div className="relative flex flex-col items-start gap-1 p-4 bg-white shadow-md rounded-olg sm:w-full md:w-1/3 lg:max-w-sm company-card hover:bg-gray-100">
      <div className="flex w-full mb-4 space-x-4 justify-evenly">
        {/* Company Image Here In the Middle of a Blue Rounded Background */}
        <div className="flex items-center content-center justify-center w-16 h-[4rem] rounded-omd shadow bg-appMainColor transition-transform duration-500 ease-in-out transform hover:scale-105 hover:shadow-lg  hover:bg-[#D6E8F7]-dark">
          {vehicleImage ? (
            <img
              src={vehicleImage}
              alt={`${vehicleMake} ${vehicleModel}`}
              className="content-center justify-center object-cover w-1/2 rounded h-1/2"
            />
          ) : (
            <Avatar style={{ backgroundColor: "transparent" }}>
              {vehicleMake.charAt(0).toUpperCase()}
            </Avatar>
          )}
        </div>

        <div className="flex flex-col items-start justify-center flex-1">
          <h3 className="font-normal text-md text-mainTextColor">
            {vehicleMake} {vehicleModel}
          </h3>
        </div>
        <div className="div">
          <Tag
            color={getSeverity().bgColor} // Assuming getSeverity returns an array, adjust if your implementation differs
            className={`${getSeverity().bgColor} px-2.5 py-0.5 rounded`}
          >
            <p
              className={`${
                getSeverity().textColor
              } flex justify-center items-center mx-0 my-0`}
              style={{ color: `${getSeverity().textColor}` }}
            >
              {status}
            </p>
          </Tag>
        </div>
        {/* Menu Icon here for Options */}
        <DropdownMenu>
          <DropdownMenuTrigger asChild className="flex items-start justify-end">
            <button
              type="submit"
              className="flex items-start justify-end h-6 hover:border-neutral-800 transaction"
            >
              <Menu className="flex items-start justify-end w-full h-full hover:bg-gray-700/10" />
            </button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56 text-xs font-medium text-black space-y-[2px]">
            <DropdownMenuLabel>Options</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              className="py-2 text-sm cursor-pointer"
              onClick={handleEdit}
            >
              <Settings className="h-4 mr-2" />
              Edit
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      {/* Company Details such as number of responders and company date added */}
      <div className="flex flex-row items-center justify-between w-full space-x-4">
        <div className="flex flex-col items-start justify-between">
          <span className="text-sm font-medium text-gray-900">
            {vehicleColour}
          </span>
          <span className="text-sm font-medium text-subtleTextColor">
            Vehicle Color
          </span>
        </div>
        <div className="flex flex-col items-start justify-between">
          <span className="text-sm font-medium text-gray-900">
            {registrationPlate}
          </span>
          <span className="text-sm font-medium text-subtleTextColor">
            Registration Plate
          </span>
        </div>
      </div>
    </div>
  );
}

export default ResponderCompanyVehicleCard;

import React from "react";
import { Button } from "antd";

interface ITextButton {
  title: string;
  color: string;
  className?: string;
  onclick?: () => void;
}

const TextUnderlineButton: React.FC<ITextButton> = ({
  title,
  color,
  className = "",
  onclick,
  ...props
}) => {
  return (
    <Button
      type="text"
      style={{
        color,
        fontWeight: 400,
        fontSize: "14px",
      }}
      onClick={onclick}
      {...props}
      className={className}
    >
      <div
        style={{
          textDecoration: "underline",
        }}
      >
        {title}
      </div>
    </Button>
  );
};

export default TextUnderlineButton;

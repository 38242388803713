/* eslint-disable max-len */
import React from "react";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import { useParams, useHistory } from "react-router-dom";

import ResponderTable from "@/components/Table/ResponderTable";
import HeaderComponent from "@/components/Header/Header";
import { usePanicTypes } from "@/hooks/usePanicTypes";
import { useAllResponderCompanies } from "@/hooks/useResponderCompany";
import AllResponderTable from "@/components/Table/AllResponderTable";

const { TabPane } = Tabs;

type responderParams = {
  companyId: string;
};

const AllResponders = () => {
  const history = useHistory();
  const { companyId } = useParams<responderParams>();

  const panicTypes = usePanicTypes();
  // Will fetch from the backend
  const { data: companies } = useAllResponderCompanies();
  // Will fetch from the backend

  const onTabChange = (key: string) => {
    if (key.toLowerCase() === "all") {
      history.replace(`/home/All-responders`);
      return;
    }
    history.replace(`/home/All-responders/${key}`);
  };

  return (
    <div className="flex w-full h-full bg-backgroundColor">
      <div className="w-full h-full max-h-screen overflow-y-auto disable-scrollbars">
        <HeaderComponent title="All Responders" />
        <Tabs
          defaultActiveKey={companyId || "0"}
          className="px-3"
          onChange={onTabChange}
        >
          <TabPane tab="All" key="0">
            <AllResponderTable companyId={0} />
          </TabPane>
          {companies?.map((company: IBasicResponderCompanyDto) => (
            <TabPane tab={company.companyName} key={company.id.toString()}>
              <AllResponderTable companyId={company.id} />
            </TabPane>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default AllResponders;

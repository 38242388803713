/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-vars */
import { Button, Form, Input, message, Select } from "antd";

import React, { useEffect, useRef, useState } from "react";
import { copyObjects } from "../../util/copyObjects";
import Colors from "../../config/Colors";

const MAX_LENGTH = 200;

type OperatorNoteFormProp = {
  note?: any;
  onFormFinish: (note: string) => void;
  onModelClose: () => void;
  isLoading: boolean;
  isSuccess: boolean;
};

const OperatorNoteForm = ({
  note,
  onFormFinish,
  onModelClose,
  isLoading,
  isSuccess,
}: OperatorNoteFormProp) => {
  const [form] = Form.useForm();
  const [charactersLeft, setCharactersLeft] = useState(MAX_LENGTH);

  const onFinish = async (formValues: any) => {
    const values: string = formValues.note;

    onFormFinish(values);
  };

  // New reset function
  const resetFormAndState = () => {
    form.resetFields();
  };

  const handleClose = () => {
    resetFormAndState();
    onModelClose();
  };

  const onFinishFailed = (errorInfo: any) => {
    message.error("Could not add note, please try again.");
  };

  useEffect(() => {
    if (note) {
      form.setFieldsValue({ note });
      setCharactersLeft(MAX_LENGTH - note.length);
    } else {
      resetFormAndState();
    }
  }, [note, form]);

  //   useEffect(() => {
  //     if (isSuccess) {
  //       resetFormAndState();
  //     }
  //   }, [isSuccess]);

  return (
    <Form
      form={form}
      name="operatorNote"
      onFinish={onFinish}
      layout="vertical"
      style={{ width: "100%" }}
      className="grid"
      initialValues={{ note }}
    >
      <div className="col-12">
        <Form.Item
          name="note"
          label="Note"
          rules={[
            { required: true, message: "Please enter note!" },
            {
              max: MAX_LENGTH,
              message: `Note cannot be longer than ${MAX_LENGTH} characters`,
            },
          ]}
          className="mb-1"
        >
          <>
            <Input.TextArea
              value={form.getFieldValue("note")}
              placeholder="Enter note here"
              maxLength={MAX_LENGTH}
              onChange={(e) => {
                form.setFieldsValue({ note: e.target.value });
                setCharactersLeft(MAX_LENGTH - e.target.value.length);
              }}
              rows={3}
            />
            {charactersLeft <= 100 && (
              <div
                className="py-1"
                style={{
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  color: Colors.subtleTextColor,
                }}
              >
                {charactersLeft} characters left
              </div>
            )}
          </>
        </Form.Item>
      </div>

      <Form.Item className="w-12 pt-2 mb-1">
        <div className="flex w-full gap-2 col-12 justify-content-end align-content-center">
          <Button
            type="primary"
            ghost
            onClick={handleClose}
            size="large"
            className="w-3"
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            size="large"
            className="w-3 bg-[#1757a2] shadow-none"
          >
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default OperatorNoteForm;

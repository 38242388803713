/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
import { API, APILocal } from "../config/axios";

class DirectMessage {
  getAll = async (): Promise<IDirectMessage[]> => {
    const url = `directMessage`;
    const response = await API.get<IDirectMessage[]>(url);
    return response.data;
  };

  postMessage = async (body: IDirectMessage) => {
    return API.post("directMessage", body);
  };

  updateMessage = async (body: IDirectMessage): Promise<IDirectMessage> => {
    return API.put(`directMessage/${body.id}`, body);
  };

  updateMessageList = async (body: IDirectMessage[]): Promise<void> => {
    return API.put(`directMessage/updateMessages`, body);
  };

  getConversationByCase = async (
    id: string | number | null
  ): Promise<IDirectMessage[]> => {
    if (id) {
      const response = await API.get<IDirectMessage[]>(
        `directMessage/caseHistory?caseId=${id}`
      );
      return response.data;
    }
    return Promise.reject();
  };

  getConversationByIds = async (
    userId: string | number,
    receiverId: string | number
  ): Promise<IDirectMessage[]> => {
    const response = await API.get<IDirectMessage[]>(
      `directMessage/chatHistory?userId=${userId}&receiverId=${receiverId}`
    );
    return response.data;
  };
}

export default new DirectMessage();

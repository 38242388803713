// CommonUserFields.tsx
import React from "react";
import { Form, Input, DatePicker, Select, FormInstance } from "antd";
import PhoneNumberPrefixSelector from "@/components/widgets/Inputs/PhoneNumberPrefixSelector";
import MomentAntdDateTimePicker from "../widgets/DateTimePicker";

const { Option } = Select;

// Define the props type
interface CommonUserFieldsProps {
  form: FormInstance;
  editMode?: boolean;
  useMomentDatePicker?: boolean;
}

const CommonUserFields: React.FC<CommonUserFieldsProps> = ({
  form,
  editMode = false,
  useMomentDatePicker = false,
}) => (
  <>
    <div className="col-12 md:col-6">
      <Form.Item
        name="firstName"
        label="First Name"
        rules={[
          {
            required: true,
            message: "Please input the user's first name!",
          },
        ]}
        className="mb-1"
      >
        <Input size="large" placeholder="Amanda" />
      </Form.Item>
    </div>

    <div className="col-12 md:col-6">
      <Form.Item
        name="lastName"
        label="Last Name"
        rules={[
          { required: true, message: "Please input the user's last name!" },
        ]}
        className="mb-1"
      >
        <Input size="large" placeholder="Neli" />
      </Form.Item>
    </div>

    <div className="col-12 md:col-6">
      <Form.Item
        name="emailAddress"
        label="Email"
        rules={[
          { required: true, message: "Please input the user's email!" },
          { type: "email", message: "Please input a valid email!" },
        ]}
        className="mb-1"
      >
        <Input
          size="large"
          placeholder="Amando@gmail.com"
          readOnly={editMode}
        />
      </Form.Item>
    </div>

    <div className="col-12 md:col-6">
      <Form.Item
        name="phoneNumber"
        label="Phone Number"
        rules={[
          {
            required: true,
            message: "Please enter user's phone number!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              // Assuming the country code (+27) is automatically included and not part of the input
              const phoneNumberPattern = /^[6-8][0-9]{8}$/;
              const fullPhoneNumber = getFieldValue("prefix") + value;
              // Validate the full phone number including the country code
              // ^\+27[6-8][0-9]{8}$
              // ^(\+27|0)[6-8][0-9]{8}$ // if you want to use 0 as the country code
              if (/^(\+27)[6-8][0-9]{8}$/.test(fullPhoneNumber)) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(
                  "Please enter a valid South African phone number without a leading 0 after the country code!"
                )
              );
            },
          }),
        ]}
        className="mb-1"
      >
        <Input
          size="large"
          addonBefore={PhoneNumberPrefixSelector()}
          placeholder="63256392"
        />
      </Form.Item>
    </div>

    <div className="col-12 md:col-6">
      <Form.Item
        name="dateOfBirth"
        label="Date of birth"
        rules={[
          { required: true, message: "Please enter user's date of birth!" },
        ]}
        className="mb-1"
      >
        {useMomentDatePicker ? (
          <MomentAntdDateTimePicker size="large" style={{ width: "100%" }} />
        ) : (
          <DatePicker size="large" style={{ width: "100%" }} />
        )}
      </Form.Item>
    </div>

    <div className="col-12 md:col-6">
      <Form.Item
        name="gender"
        label="Gender"
        rules={[
          { required: true, message: "Please select the user's gender!" },
        ]}
        className="mb-1"
      >
        <Select placeholder="Select user's gender" size="large">
          <Select.Option value="Male">Male</Select.Option>
          <Select.Option value="Female">Female</Select.Option>
          <Select.Option value="Other">Other</Select.Option>
        </Select>
      </Form.Item>
    </div>
  </>
);

export default CommonUserFields;

import React from "react";
import Colors from "../../config/Colors";

export function ThumbsUpIcon({
  height, // number type by default
  width,
  viewBox = "0 0 48 48",
  svgColor,
  fill,
}: ThumbsUpSVGProp) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox || "0 0 48 48"}
    >
      <path
        fill={fill}
        stroke={svgColor}
        strokeLinejoin="round"
        strokeWidth="4"
        d="M27.6 18.6v-7.2A5.4 5.4 0 0 0 22.2 6L15 22.2V42h20.916a3.6 3.6 0 0 0 3.6-3.06L42 22.74a3.6 3.6 0 0 0-3.6-4.14H27.6ZM15 22h-4.806C8.085 21.963 6.283 23.71 6 25.8v12.6a4.158 4.158 0 0 0 4.194 3.6H15V22Z"
      />
    </svg>
  );
}
export default ThumbsUpIcon;

import { Button, Form, Input, Radio, Space } from "antd";
import React, { useEffect, useState } from "react";
import type { RadioChangeEvent } from "antd";

type NewCommunityPostSettings = {
  communityPostSettings?: ICommunitySettngs;
  onFormFinish: (communityPostSettings: ICommunitySettngs) => void;
  onModalClose: () => void;
  isLoading: boolean;
  isSuccess: boolean;
};

const PostSettings = ({
  communityPostSettings: communityPost,
  onFormFinish,
  isLoading,
  isSuccess,
}: NewCommunityPostSettings) => {
  const [form] = Form.useForm();
  const [postFor, setPostFor] = useState();
  const [comment, setComment] = useState();

  const onFinish = async (formValues: ICommunitySettngs) => {
    const values: ICommunitySettngs = formValues as ICommunitySettngs;
    await onFormFinish(values);
  };

  const onChangePostFor = (e: RadioChangeEvent) => {
    setPostFor(e.target.value);
  };

  const onChangeComment = (e: RadioChangeEvent) => {
    setComment(e.target.value);
  };

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
    }
  }, [isSuccess]);

  return (
    <div className="w-full h-full flex flex-col items-start">
      <Form
        form={form}
        layout="vertical"
        style={{ width: "100%" }}
        onFinish={onFinish}
      >
        <div className="w-full mb-4">
          <Form.Item name="type" label="Who is the post for ?">
            <Radio.Group onChange={onChangePostFor} value={postFor}>
              <Space direction="vertical">
                <Radio value="GENERAL">General Post</Radio>
                <Radio value="MISSING">Missing Persons</Radio>
                <Radio value="WANTED">Wanted Persons</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </div>

        <div className="w-full mb-4">
          <Form.Item name="isCommentable" label="Who can comment on the post ?">
            <Radio.Group onChange={onChangeComment} value={comment}>
              <Space direction="vertical">
                <Radio value={true}>Everyone</Radio>
                <Radio value={false}>No one</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        </div>

        <div className="w-full mb-4 flex justify-end">
          <Form.Item className="pt-2 mb-1">
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="bg-[#1757a2] shadow-none"
              loading={isLoading}
            >
              Done
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default PostSettings;

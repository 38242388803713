import React, { ReactNode, FC, useContext } from "react";
import { Title, NameStyle, Header, Profile } from "./Styles";
import Images from "../../assets";
import AuthContext from "../../contexts/AuthContext";

interface HeaderProps {
  title: string;
  children?: ReactNode;
}

const HeaderComponent: FC<HeaderProps> = ({ title, children = null }) => {
  const { user } = useContext(AuthContext);

  return (
    <Header>
      <Title>{title} </Title>
      {user && (
        <div className="flex flex-row gap-1 align-items-center">
          {children && children}
          <NameStyle>{user.firstName}</NameStyle>
          <Profile>
            <img
              src={
                user.profilePicture ? user.profilePicture : Images?.userAvatar
              }
              alt=""
            />
          </Profile>
        </div>
      )}
    </Header>
  );
};

export default HeaderComponent;
// export {};

/* eslint-disable no-nested-ternary */
/* eslint-disable no-empty */
import React, { useState, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { Form, Input, message, Alert, Button, Modal } from "antd";
import HeaderComponent from "../../components/Header/Header";
import AuthContext from "../../contexts/AuthContext";
import {
  useApprovePostApplication,
  useBlockApplication,
  useDisableCanPost,
  useEnableCanPost,
  useGetApplicationDetails,
  useRejectApplication,
  useUnBlockApplication,
} from "../../hooks/usePostApplication";
import { CustomIcons } from "../../assets";
import Colors from "../../config/Colors";
import { PostApplicationStatus } from "../../util/enums";
import SuccessMessage from "../../components/widgets/common/SuccessMessage";
import { modelTitle } from "../Users/styles";
import RejectApplicationReasonForm from "../../components/Forms/RejectAppplicationReason";
import Breadcrumbs from "../../components/widgets/Breadcrumbs/Breadcrumbs";

const LoadingComponent = () => (
  <div className="fixed inset-0 flex items-center justify-center">
    <CustomIcons.EOSThreeDotsLoading
      width={70}
      height={70}
      svgColor={Colors.appMainColor}
    />
  </div>
);

const ErrorAlert = ({ error }: { error: string }) => (
  <div className="fixed inset-0 flex items-center justify-center">
    <Alert
      message="Error"
      description={error}
      type="error"
      showIcon
      className="w-6/12 mx-auto "
    />
  </div>
);

const ApplicationForm = ({ data }: { data: IPostApplicationDetails }) => {
  const {
    firstName,
    lastName,
    emailAddress,
    phoneNumber,
    dateOfBirth,
    reason,
    physicalAddress,
    status,
    isResident,
    // ... you can destructure other fields as needed
  } = data;

  const formatDate = (dateStr: string): string => {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // +1 because months are 0-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <Form
      layout="vertical"
      className="flex flex-wrap w-11/12 px-3 py-1"
      initialValues={{ ...data, dateOfBirth: formatDate(dateOfBirth) }}
    >
      <div className="w-full pb-3 text-xl text-left text-mainTextColor">
        User details
      </div>
      <div className="flex flex-row w-full gap-x-4">
        <Form.Item name="firstName" label="First Name" className="w-1/2">
          <Input
            placeholder="Amanda"
            size="large"
            disabled
            className="bg-white"
          />
        </Form.Item>
        <Form.Item name="lastName" label="Last Name" className="w-1/2">
          <Input
            placeholder="Neli"
            size="large"
            disabled
            className="bg-white"
          />
        </Form.Item>
      </div>
      <div className="flex flex-row w-full gap-x-4">
        <Form.Item name="emailAddress" label="Email" className="w-1/2">
          <Input
            placeholder="amanda@email.com"
            size="large"
            disabled
            className="bg-white"
          />
        </Form.Item>
        <Form.Item name="phoneNumber" label="Phone Number" className="w-1/2">
          <Input
            placeholder="0635678978"
            size="large"
            disabled
            className="bg-white"
          />
        </Form.Item>
      </div>
      <div className="flex flex-row w-full gap-4">
        <Form.Item name="dateOfBirth" label="Date of Birth" className="w-1/2">
          <Input
            placeholder="12/09/1997"
            size="large"
            disabled
            className="bg-white"
          />
        </Form.Item>
      </div>
      <div className="w-full pb-3 text-xl text-left text-mainTextColor">
        Questionnaire
      </div>
      <div className="flex flex-row w-full gap-4">
        <Form.Item
          name="physicalAddress"
          label="Where do you live?"
          className="w-1/2"
        >
          <Input
            placeholder="Sandton"
            size="large"
            disabled
            className="bg-white"
          />
        </Form.Item>
        <Form.Item
          name="childSchool"
          label="Child's school address"
          className="w-1/2"
        >
          <Input
            placeholder="Sandton"
            size="large"
            disabled
            className="bg-white"
          />
        </Form.Item>
      </div>
      <div className="flex flex-row w-full gap-4">
        {isResident ? (
          <Form.Item label="Do you live in this area?" className="w-1/2">
            <Input
              placeholder="Sandton"
              size="large"
              disabled
              className="bg-white"
              value="Yes"
            />
          </Form.Item>
        ) : (
          <Form.Item label="Do you live in this area?" className="w-1/2">
            <Input
              placeholder="Sandton"
              size="large"
              disabled
              className="bg-white"
              value="No"
            />
          </Form.Item>
        )}
        <Form.Item
          name="workOrSchoolStatus"
          label="Do you work in this area or do you have a school going child in this area?"
          className="w-1/2"
        >
          <Input
            placeholder="Sandton"
            size="large"
            disabled
            className="bg-white"
          />
        </Form.Item>
      </div>
      <div className="flex flex-row w-full gap-4">
        <Form.Item name="reason" label="Reason for joining" className="w-12">
          <Input.TextArea
            placeholder="Sandton"
            size="large"
            disabled
            className="bg-white"
            minLength={3}
          />
        </Form.Item>
      </div>
      {(status === PostApplicationStatus.Rejected ||
        status === PostApplicationStatus.Blocked) && (
        <div className="flex flex-row w-full gap-4">
          <Form.Item
            name="rejectReason"
            label={`${
              status === PostApplicationStatus.Rejected ? "Reject" : "Block"
            } reason`}
            className="w-12"
          >
            <Input.TextArea
              placeholder="Community admin reason"
              size="large"
              disabled
              className="bg-white"
              minLength={3}
            />
          </Form.Item>
        </div>
      )}
    </Form>
  );
};

type MessageType = "info" | "success" | "error" | "warning" | "loading";

const RenderButtons = ({
  applicationId,
  status,
  refresh,
}: {
  applicationId: number;
  status: PostApplicationStatus;
  refresh: () => void;
}) => {
  const [action, setAction] = useState<"reject" | "block" | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const approveApplication = useApprovePostApplication();
  const rejectApplication = useRejectApplication();
  const blockApplication = useBlockApplication();
  const unblockApplication = useUnBlockApplication();
  const disableCanPost = useDisableCanPost();
  const enableCanPost = useEnableCanPost();

  const [messageApi, contextHolder] = message.useMessage();
  const messageKey = "postApplicationPermissions";

  const showMessage = (messageText: string, type: MessageType) => {
    messageApi.open({
      content: messageText,
      key: messageKey,
      type,
    });
    if (type === "success") {
      setTimeout(() => {
        messageApi.destroy(messageKey);
      }, 2000);
    }
  };

  const handleRejectClick = () => {
    setAction("reject");
    setOpenModal(true);
  };

  const handleBlockClick = () => {
    setAction("block");
    setOpenModal(true);
  };

  const handleClose = () => {
    rejectApplication.reset();
    blockApplication.reset();
    setAction(null);
    setOpenModal(false);
  };

  const handleApprove = async () => {
    try {
      showMessage("Approving application...", "loading");
      await approveApplication.mutateAsync(applicationId);
      refresh();
      showMessage("Application approved successfully", "success");
    } catch (err) {}
  };

  const handleDisableCanPost = async () => {
    try {
      showMessage("disabling...", "loading");
      await disableCanPost.mutateAsync(applicationId);
      refresh();
      showMessage("Disabled ability to post successfully", "success");
    } catch (err) {}
  };

  const handleEnableCanPost = async () => {
    try {
      showMessage("enabling...", "loading");
      await approveApplication.mutateAsync(applicationId);
      refresh();
      showMessage("Enabled ability to post successfully", "success");
    } catch (err) {}
  };

  const handleUnBlock = async () => {
    try {
      showMessage("unblocking application...", "loading");
      await approveApplication.mutateAsync(applicationId);
      refresh();
      showMessage("Application unblocked successfully", "success");
    } catch (err) {}
  };

  const handleReject = async (reason: string) => {
    try {
      showMessage("Rejecting application...", "loading");
      const body: IRejectApplication = {
        applicationId,
        rejectReason: reason,
      };
      await rejectApplication.mutateAsync(body);
      // create a timeout object of 1000ms
      setTimeout(() => {
        refresh();
      }, 1000);
      // refresh();
      showMessage("Application rejected successfully", "success");
    } catch (err) {}
  };

  const handleBlock = async (reason: string) => {
    try {
      showMessage("Blocking application...", "loading");
      const body: IRejectApplication = {
        applicationId,
        rejectReason: reason,
      };
      await blockApplication.mutateAsync(body);
      setTimeout(() => {
        refresh();
      }, 1000);
      showMessage("Application blocked successfully", "success");
    } catch (err) {}
  };

  return (
    <>
      {contextHolder}
      <div className="flex flex-row items-center justify-end gap-3 pb-3">
        {status === PostApplicationStatus.Pending && (
          <>
            <Button
              type="primary"
              className="w-2/12"
              onClick={handleRejectClick}
              ghost
              loading={rejectApplication.isLoading}
            >
              Reject
            </Button>
            <Button
              type="primary"
              className="w-2/12 bg-[#1757a2] shadow-none"
              loading={approveApplication.isLoading}
              onClick={handleApprove}
            >
              Approve
            </Button>
          </>
        )}
        {status === PostApplicationStatus.Rejected && (
          <Button
            type="primary"
            className="w-2/12 bg-[#1757a2] shadow-none"
            onClick={handleApprove}
            loading={approveApplication.isLoading}
          >
            Approve
          </Button>
        )}
        {status === PostApplicationStatus.Blocked && (
          <Button
            type="primary"
            className="w-2/12 bg-[#1757a2] shadow-none"
            onClick={handleUnBlock}
            loading={unblockApplication.isLoading}
          >
            Unblock
          </Button>
        )}
        {status === PostApplicationStatus.Approved && (
          <>
            <Button
              type="primary"
              className="w-2/12"
              onClick={handleDisableCanPost}
              ghost
              loading={disableCanPost.isLoading}
            >
              Disable
            </Button>
            <Button
              type="primary"
              className="w-2/12 bg-[#1757a2] shadow-none"
              onClick={handleBlockClick}
            >
              Block
            </Button>
          </>
        )}
        {status === PostApplicationStatus.Disabled && (
          <>
            <Button
              type="primary"
              className="w-2/12"
              onClick={handleBlockClick}
              ghost
              loading={blockApplication.isLoading}
            >
              Block
            </Button>
            <Button
              type="primary"
              className="w-2/12 bg-[#1757a2] shadow-none"
              onClick={handleEnableCanPost}
              loading={enableCanPost.isLoading}
            >
              Enable
            </Button>
          </>
        )}
      </div>

      <Modal
        open={openModal}
        onOk={() => setOpenModal(false)}
        onCancel={handleClose}
        width={550}
        closeIcon={
          <CustomIcons.CloseIcon
            width={30}
            height={30}
            viewBox={null}
            svgColor="#1C274C"
          />
        }
        footer={null}
      >
        <div className="w-full pt-2 mx-2">
          {action === "block" && blockApplication.isSuccess ? (
            <SuccessMessage
              subtitle="Your block request has been saved."
              onClose={handleClose}
            />
          ) : action === "reject" && rejectApplication.isSuccess ? (
            <SuccessMessage
              subtitle="Your reject request has been saved."
              onClose={handleClose}
            />
          ) : (
            <div>
              <div className="flex justify-center w-12 py-2" style={modelTitle}>
                {`Why do you want to ${
                  action === "block" ? "block" : "reject"
                }  the application?`}
              </div>
              <div
                className="flex justify-center w-12 pb-3"
                style={{ color: Colors.subtleTextColor }}
              >
                {`Please provide a reason below to why you want to ${
                  action === "block" ? "block" : "reject"
                } the application?`}
              </div>
              <RejectApplicationReasonForm
                onFormFinish={async (reason: string) => {
                  if (action === "block") {
                    await handleBlock(reason);
                  } else {
                    await handleReject(reason);
                  }
                }}
                onModelClose={() => {
                  handleClose();
                }}
                isLoading={
                  rejectApplication.isLoading || blockApplication.isLoading
                }
                isSuccess={
                  rejectApplication.isSuccess || blockApplication.isSuccess
                }
              />
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

type PostApplicationDetailParams = {
  id: string | undefined;
};

const PostApplicationDetail = () => {
  const { getCommunityId } = useContext(AuthContext);
  const communityId = getCommunityId();
  const { id } = useParams<PostApplicationDetailParams>();

  const { isLoading, data, refetch } = useGetApplicationDetails(id);

  const breadcrumbPath = [
    { title: <Link to="/home/community-home">Home</Link> },
    { title: <Link to="/home/community-applications">Applications</Link> },
    { title: id || "" },
  ];

  if (isLoading) {
    return <LoadingComponent />;
  }

  if (data) {
    return data.communityId === communityId ? (
      <div className="flex flex-col w-full h-full bg-backgroundColor">
        {/* {contextHolder} */}
        <HeaderComponent title="Applicant details" />
        {/* <div className="pb-3 mx-3">
          <Breadcrumbs path={breadcrumbPath} />
        </div> */}

        <ApplicationForm data={data} />
        <div className="w-11/12">
          <RenderButtons
            applicationId={data?.applicationId}
            status={data?.status}
            refresh={() => {
              refetch();
            }}
          />
        </div>
      </div>
    ) : (
      <ErrorAlert error="You do not have permission to view this application" />
    );
  }

  return <ErrorAlert error="No application found" />;
};

export default PostApplicationDetail;

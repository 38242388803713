/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import { Modal, Image, App } from "antd";
import React, { useEffect, useRef, useState, useContext } from "react";
import { Carousel } from "primereact/carousel";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";
import ReactPlayer from "react-player";
import { useHistory } from "react-router-dom";
import { CustomIcons } from "../../../assets";
import Colors from "../../../config/Colors";
import CommunityTag from "../../Tags/CommunityTag";
import { SubHeading } from "../../../pages/Home/styles";
import { useDeletePost, usePostReaction } from "../../../hooks/useCommunities";
import GenericDialog from "../../Dialog/GenericDialog";
import { getInitials, getTimePosted } from "../../../util/helpers";
import LikeButton from "../../widgets/Buttons/LikeButton";
import AuthContext from "../../../contexts/AuthContext";
import InitialsAvatar from "../../widgets/Avatars/InitialsAvatar";
import { VideoPlayerAnimationIcon } from "../../Icons/index";

const CommunityFeedCard: React.FC<{
  card: ICommunityFeed;
}> = ({ card }) => {
  const navigate = useHistory();
  const { user, isCommunityAdmin, isAdmin } = useContext(AuthContext);
  const {
    id,
    title,
    profilePicture,
    content,
    tags,
    mediaPath,
    createdAt,
    isCommentAble,
  } = card;
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const postReaction = usePostReaction();
  const deletePost = useDeletePost();
  const menuRight = useRef<Menu>(null);
  const { message } = App.useApp();

  const hyperlinkDetector = (text: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(
      urlRegex,
      (url) =>
        `<a href="${url}" target="_blank" rel="noopener noreferrer" style="color: blue; text-decoration: underline;">${url}</a>`
    );
  };

  const items: MenuItem[] = [
    {
      label: "Options",
      items: [
        {
          label: "Delete",
          icon: "pi pi-times",
          command: () => {
            setOpenDeleteModal(true);
          },
        },
      ],
    },
  ];

  const postTemplate = (postMedia: IMedia) => {
    switch (postMedia.fileType) {
      case "image":
        return (
          <div className="flex flex-wrap items-center justify-center w-full h-6/6">
            <Image
              src={postMedia.url}
              fallback="https://images.wondershare.com/repairit/aticle/2021/07/resolve-images-not-showing-problem-1.jpg"
              className="flex justify-center object-cover w-full"
              height="500px"
            />
          </div>
        );
      case "video":
        return (
          <div className="flex justify-center w-full h-6/6">
            <ReactPlayer
              style={{
                flex: 1,
                justifyContent: "center",
                width: "100%",
              }}
              height="500px"
              width="100%"
              controls
              url={postMedia.url}
              pip
              light={
                <div className="pt-5 pr-3">
                  <VideoPlayerAnimationIcon />
                </div>
              }
            />
          </div>
        );
      default:
        return <SubHeading>Unsupported media found</SubHeading>;
    }
  };

  const destroyMessage = () => {
    message.destroy();
  };

  const handleDeletePost = async () => {
    if (id) {
      try {
        message.loading("Deleting post...");
        await deletePost.mutateAsync(id);
        message.success("Post deleted successfully.");

        setOpenDeleteModal(false);

        setTimeout(() => {
          destroyMessage();
        }, 2000);
      } catch (error) {
        destroyMessage();
      }
    } else {
      message.error("Post not found");
    }
  };

  function userHasReacted(): boolean {
    const value = card.reactions.some(
      (reaction) => reaction.userId === user?.id
    );
    return value;
  }

  const handlePostReaction = async () => {
    try {
      const reaction: ICreatePostReaction = {
        userId: user?.id || 0,
        postId: card.id,
        reactionId: 1,
        communityId: card.communityId || 0,
      };
      await postReaction.mutateAsync(reaction);
    } catch (error) {
      // log something here
    }
  };

  const handleOpenComments = async () => {
    if (!isCommentAble) {
      message.error("This post does not allow comments");
    }

    //link to community-post
    if (isAdmin) {
      navigate.push(`/home/safer-city-community-post/${card.id}`);
    } else {
      navigate.push(`/home/community-post/${card.id}`);
    }
  };

  return (
    <div className="flex flex-col w-full h-5/5 shadow-5 surface-card border-round">
      <div className="flex flex-row w-full">
        <div
          className="flex items-center w-4/5 ml-4"
          style={{ marginTop: "1rem" }}
        >
          <InitialsAvatar
            url={profilePicture}
            initials={getInitials(`${card?.firstName} ${card?.lastName}`)}
          />
          <div className="flex flex-col px-2 ml-1">
            <p
              className="flex justify-start text-lg font-bold text-appMainColor "
              style={{ marginBottom: "0px" }}
            >
              {`${card?.firstName} ${card?.lastName}`}
            </p>
            <p
              className="flex justify-start text-sm text-mainTextColor "
              style={{ marginBottom: "0px" }}
            >
              Posted {getTimePosted(createdAt)}
            </p>
          </div>
        </div>
        <Menu
          model={items}
          popup
          ref={menuRight}
          id="popup_menu_right"
          popupAlignment="right"
        />
        <div
          className="flex justify-end w-1/5 pt-4 pr-3"
          onClick={(event) => menuRight.current?.toggle(event)}
          aria-controls="popup_menu_right"
          aria-haspopup="true"
        >
          <CustomIcons.ThreeDotsVerticalIcon
            height={20}
            width={20}
            svgColor="#000000" // Updated with a value
          />
        </div>
      </div>
      {/* Title */}

      <p className="flex justify-start px-4 pt-3 text-lg font-bold w-6/6">
        {title}
      </p>

      {/* Content */}
      <p className="flex justify-start px-4 pb-3 w-6/6">
        <div dangerouslySetInnerHTML={{ __html: hyperlinkDetector(content) }} />
      </p>
      <div className="flex justify-start w-full gap-2 pb-2 pl-4">
        {tags?.map((item, index) => (
          <CommunityTag tag={item} key={`tag-${index}`} />
        ))}
      </div>

      {mediaPath && mediaPath.length > 0 ? (
        <div className="flex items-center justify-center w-full h-4/5">
          <Carousel
            value={mediaPath}
            numVisible={1}
            numScroll={1}
            itemTemplate={postTemplate}
            className="w-full h-5/6"
            showIndicators={false}
          />
        </div>
      ) : (
        <SubHeading>No media found</SubHeading>
      )}

      <div className="flex justify-between w-full px-4 mt-3">
        <LikeButton
          active={userHasReacted()}
          amount={card?.reactions ? card.reactions.length : 0}
          onLike={handlePostReaction}
        />
        <button
          type="button"
          className={`flex justify-center gap-1 ${
            isCommentAble ? "opacity-100" : "opacity-50"
          }`}
          onClick={handleOpenComments}
        >
          <CustomIcons.CommentIcon
            height={25}
            width={25}
            svgColor={Colors.appMainColor}
          />
          <p className="text-lg text-mainTextColor">
            {card?.commentsAmount ? card.commentsAmount : 0} Comments
          </p>
        </button>
      </div>
      <Modal
        centered
        open={openDeleteModal}
        onOk={() => setOpenDeleteModal(false)}
        onCancel={() => setOpenDeleteModal(false)}
        width={550}
        closeIcon={
          <CustomIcons.CloseIcon
            width={30}
            height={30}
            viewBox={null}
            svgColor="#1C274C"
          />
        }
        footer={null}
      >
        <GenericDialog
          onClose={() => setOpenDeleteModal(false)}
          onConfirm={handleDeletePost}
          text="delete this post"
        />
      </Modal>
    </div>
  );
};

export default React.memo(CommunityFeedCard);

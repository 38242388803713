import React from "react";
import { ProgressBar } from "primereact/progressbar";
import Colors from "../../../config/Colors";

// can add props later
const PrimaryProgressBar: React.FC = () => {
  return (
    <ProgressBar
      mode="indeterminate"
      style={{
        height: "0.5rem",
        width: "40rem",
        background: "#B4C9E1",
      }}
      color={Colors.appMainColor}
    />
  );
};

export default PrimaryProgressBar;

import React, { FC, ButtonHTMLAttributes } from "react";
import Colors from "../../../config/Colors";
import { cardTitleStyle, numberStyle, percentageStyle } from "./styles";
import { CustomIcons } from "../../../assets";

interface DashboardCardProps {
  title: string;
  number: number;
  percentage?: string | number;
  trend?: Boolean;
}

export const DashboardCard: FC<DashboardCardProps> = ({
  title,
  number,
  percentage = "+ 10,7%",
  trend = true,
  ...props
}) => {
  return (
    <div
      className="col-12 md:col-6 lg:col-4 shadow-5 h-8rem  w-20rem surface-card border-round animate-fadeInRightBig animate-fast "
      {...props}
      style={{ backgroundColor: `${Colors.popupColor}` }}
    >
      <div className="p-3 text-center" style={{ borderRadius: 12 }}>
        <div className="pt-2 w-full truncate" style={cardTitleStyle}>
          {title}
        </div>
        <div
          className="pt-2 flex flex-row gap-3 justify-content-center align-content-center"
          style={{ lineHeight: "34px" }}
        >
          <div style={numberStyle}>{number}</div>
          <div className="flex justify-content-center align-content-center gap-2 animate-pulse animate-repeat-5">
            <div style={percentageStyle}>
              {trend ? "+ " : "- "} {percentage}%{" "}
            </div>
            {trend ? (
              <CustomIcons.ArrowUpward
                svgColor={Colors.appMainColor}
                viewBox={null}
                height={30}
                width={30}
              />
            ) : (
              <CustomIcons.ArrowDownward
                svgColor={Colors.errorColor}
                viewBox={null}
                height={30}
                width={30}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-unused-vars */
import { Input } from "antd";
import React, { useEffect } from "react";
import { useUserSalesIQ } from "../../hooks/useSalesIQ";
import { CustomIcons } from "../../assets";

type SalesIQModalProp = {
  userId: string | number | null;
};

const SalesIQModal = ({ userId }: SalesIQModalProp) => {
  const { data, isLoading, isFetching, remove } = useUserSalesIQ(userId);
  return (
    <div className="grid w-full h-full">
      {isLoading ? (
        <div>
          <CustomIcons.EOSThreeDotsLoading
            width={30}
            height={30}
            viewBox={null}
            svgColor="#1C274C"
          />
        </div>
      ) : (
        <>
          <div className="col-12 md:col-6">
            <label className="pb-2 label as-label">First Name</label>
            <Input
              size="large"
              placeholder="Amanda"
              className="mb-1"
              readOnly
              value={data?.firstName}
            />
          </div>
          <div className="col-12 md:col-6">
            <label className="pb-2 label as-label">Last Name</label>
            <Input
              size="large"
              placeholder="Neli"
              className="mb-1"
              readOnly
              value={data?.lastName}
            />
          </div>
          <div className="col-12 md:col-6">
            <label className="pb-2 label as-label">Email</label>
            <Input
              size="large"
              placeholder="Amando@gmail.com"
              className="mb-1"
              readOnly
              value={data?.email}
            />
          </div>
          <div className="col-12 md:col-6">
            <label className="pb-2 label as-label">Phone Number</label>
            <Input
              size="large"
              placeholder="+2763256392"
              className="mb-1"
              readOnly
              value={data?.phoneNumber}
            />
          </div>
          <div className="col-12 md:col-6">
            <label className="pb-2 label as-label">Account Created</label>
            <Input
              size="large"
              placeholder="23/09/2023"
              className="mb-1"
              readOnly
              value={data?.accountCreated}
            />
          </div>

          <div className="col-12 md:col-6">
            <label className="pb-2 label as-label">Community Code</label>
            <Input
              size="large"
              placeholder="BOKSBURGNORTHCPF"
              className="mb-1"
              readOnly
              value={data?.CommunityCode}
            />
          </div>
          <div className="col-12 md:col-6">
            <label className="pb-2 label as-label">Sale</label>
            <Input
              size="large"
              placeholder="New Sale"
              className="mb-1"
              readOnly
              value={data?.sales}
            />
          </div>
          <div className="col-12 md:col-6">
            <label className="pb-2 label as-label">Subscribed Date</label>
            <Input
              size="large"
              placeholder="23/09/2023"
              className="mb-1"
              readOnly
              value={data?.subscribedDate}
            />
          </div>
          <div className="col-12 md:col-6">
            <label className="pb-2 label as-label">Subscription Plan</label>
            <Input
              size="large"
              placeholder="Basic Plan"
              className="mb-1"
              readOnly
              value={data?.subscriptionType}
            />
          </div>
          <div className="col-12 md:col-6">
            <label className="pb-2 label as-label">Subscription Amount</label>
            <Input
              size="large"
              placeholder="R35.99"
              className="mb-1"
              readOnly
              value={data?.subscriptionAmount}
            />
          </div>
          <div className="col-12 md:col-6">
            <label className="pb-2 label as-label">Subscription Status</label>
            <Input
              size="large"
              placeholder="Subscribed"
              className="mb-1"
              readOnly
              value={data?.subscriptionStatus}
            />
          </div>
          <div className="col-12 md:col-6">
            <label className="pb-2 label as-label">Next Billing Date</label>
            <Input
              size="large"
              placeholder="23/09/2023"
              className="mb-1"
              readOnly
              value={data?.nextBillingDate}
            />
          </div>
          <div className="col-12 md:col-6">
            <label className="pb-2 label as-label">Member Role</label>
            <Input
              size="large"
              placeholder="Primary Member"
              className="mb-1"
              readOnly
              value={data?.memberRole}
            />
          </div>
          <div className="col-12 md:col-6">
            <label className="pb-2 label as-label">Operating System</label>
            <Input size="large" placeholder="IOS" className="mb-1" readOnly />
          </div>
          <div className="col-12 md:col-6">
            <label className="pb-2 label as-label">Physical Button</label>
            <Input
              size="large"
              placeholder="Safer City Button 1"
              className="mb-1"
              readOnly
              value={data?.physicalButton}
            />
          </div>
          <div className="col-12 md:col-6">
            <label className="pb-2 label as-label">Physical Button Id</label>
            <Input
              size="large"
              placeholder="98880w9w7"
              className="mb-1"
              readOnly
              value={data?.physicalButtonId}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SalesIQModal;

/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/self-closing-comp */
import { useState, useEffect, useRef } from "react";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Tag, Button, Modal } from "antd";
import { Dropdown, DropdownChangeEvent } from "primereact/dropdown";
import moment from "moment";
import { debounce } from "lodash";
import { toast } from "sonner";
import { BodyContainer, Container } from "./styles";
import Spin from "../../components/widgets/Spinner/Spin";
import CustomInputField from "../../components/widgets/Inputs/CustomInputField";
import { tableBody, tableHeader } from "../../styles/TableStyles";
import {
  usePostCommunityCode,
  useAllCommunityCodes,
  useGetPaginatedCommmunityCode,
  useGetCountries,
  useGetProvinces,
} from "../../hooks/useCommunityCodes";
import {
  modelTitle,
  successSubtitle,
  successTitle,
} from "../../styles/ModalStyles";
import { CommunityCode as CommunityCodeService } from "../../services";
import { CustomIcons } from "../../assets";
import CommunityCodeFormIndex from "../../components/Forms/CommunityCodeForms/CommunityCodeFormIndex";

const CommunityCodes = () => {
  // @ts-ignore
  const dt = useRef<DataTable>(null);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [filtersState, setFiltersState] = useState<AffiliationFilters>({
    code: { values: [""], matchModes: ["contains"] },
    status: { values: [""], matchModes: ["contains"] },
    subscriptionPlan: { values: [""], matchModes: ["contains"] },
    country: { values: [""], matchModes: ["equals"] },
    province: { values: [""], matchModes: ["equals"] },
  });
  const {
    isLoading,
    isFetching,
    data: communityCodeData,
    refetch,
  } = useGetPaginatedCommmunityCode(first, rows, filtersState);

  const { data: countries } = useGetCountries();

  // const { isLoading, data, refetch, isFetching } = useUsersWithReferral();

  const allCommunityCodes = useAllCommunityCodes();
  const postReferral = usePostCommunityCode();
  const [isExporting, setIsExporting] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [filters, setFilters] = useState<DataTableFilterMeta | undefined>(
    undefined
  );
  const [globalFilterSearch, setGlobalFilterSearch] = useState<string>("");
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  // Debounce function
  const debounceSetGlobalFilterValue = debounce((value) => {
    setGlobalFilterValue(value);
  }, 500);
  const [openDownloadButtonsModal, setOpenDownloadButtonsModal] =
    useState(false);

  const [statuses] = useState<string[]>([
    "Subscribed",
    "Unsubscribed",
    "Non-Renewing",
  ]);
  const [codes] = useState<string[]>([]);

  const onPage = (event: any) => {
    console.log(event);
    setFirst(event.first);
    setRows(event.rows);
  };

  const getSeverity = (status: string) => {
    switch (status) {
      case "Subscribed":
        return "success";
      case "Unsubscribed":
        return "error";
      case "Non-Renewing":
        return "warning";
      default:
        return "processing";
    }
  };

  const onFilter = (event: { filters: ITableFilters }) => {
    console.log(event);

    const newFilters: { [key: string]: IFilterParam } = {};
    Object.keys(event.filters).forEach((key) => {
      const filter: ITableFilter = event.filters[key];

      // Check if the filter has 'constraints' (for complex filters)
      if (filter.constraints) {
        newFilters[key] = {
          values: filter.constraints.map(
            (constraint) => constraint.value || ""
          ),
          matchModes: filter.constraints.map(
            (constraint) => constraint.matchMode
          ),
        };
      } else {
        // Simple filter
        newFilters[key] = {
          values: [filter.value || ""],
          matchModes: [filter.matchMode || ""],
        };
      }
    });
    setFiltersState(newFilters);
    setFirst(0); // Reset to the first page
  };

  const onGlobalFilterChange = (e: string) => {
    const _filters = { ...filters };
    // @ts-ignore
    // _filters.global.value = e;

    // setFilters(_filters);
    // Use the debounce function
    debounceSetGlobalFilterValue(e);
    setGlobalFilterSearch(e);
    setFirst(0);
  };

  const initFilters = () => {
    setFilters({
      // global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      date: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      status: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      companyId: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      code: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      subscriptionPlan: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      country: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      province: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
    });
    setGlobalFilterValue("");
  };

  const exportPdf = async () => {
    toast.promise(
      CommunityCodeService.downloadPdfCommunityCodes(filtersState),
      {
        loading: "Exporting...",
        success: () => {
          return "Exported successfully";
        },
        error: "Error exporting Community Code table, please try again later.",
      }
    );
  };

  const exportCSV = async (selectionOnly: boolean) => {
    toast.promise(
      CommunityCodeService.downloadExcelCommunityCodes(filtersState),
      {
        loading: "Exporting...",
        success: () => {
          return "Exported successfully";
        },
        error: "Error exporting Community Code table, please try again later.",
      }
    );
  };

  useEffect(() => {
    if (allCommunityCodes.data) {
      codes.length = 0;
      codes.push(...allCommunityCodes.data.map((d) => d.code));
    }
  }, [allCommunityCodes.data]);

  useEffect(() => {
    initFilters();
  }, []);

  useEffect(() => {
    const currentFilers = { ...filtersState };
    if (globalFilterValue) {
      currentFilers.code = {
        values: [globalFilterValue],
        matchModes: ["contains"],
      };
    } else {
      delete currentFilers.code;
    }

    setFiltersState(currentFilers);
    //refetchFilteredUsers();
  }, [globalFilterValue]);

  const statusItemTemplate = (option: string) => {
    return <Tag color={getSeverity(option)}>{option}</Tag>;
  };

  const statusBodyTemplate = (rowData: IReferralUser) => {
    return <Tag color={getSeverity(rowData.status)}>{rowData.status}</Tag>;
  };

  const statusFilterTemplate = (
    options: ColumnFilterElementTemplateOptions
  ) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e: DropdownChangeEvent) =>
          options.filterCallback(e.value, options.index)
        }
        itemTemplate={statusItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
      />
    );
  };

  const referralFilterTemplate = (
    options: ColumnFilterElementTemplateOptions
  ) => {
    return (
      <Dropdown
        value={options.value}
        options={codes}
        onChange={(e: DropdownChangeEvent) =>
          options.filterCallback(e.value, options.index)
        }
        itemTemplate={statusItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
      />
    );
  };

  const dateBodyTemplate = (rowData: IReferralUser) => {
    return moment(rowData.firstUsed).format("YYYY/MM/DD");
  };

  const handleClose = async () => {
    setOpenAddModal(false);
    postReferral.reset();
  };

  return (
    <div className="w-full h-full">
      <Container className="w-full h-full">
        <BodyContainer className="w-full">
          <Spin tip="" spinning={isLoading || isFetching} delay={500}>
            <div className="w-full h-full px-3 card Users">
              <div className="flex flex-row justify-end w-full gap-1 mb-2 align-items-center">
                <Button
                  type="primary"
                  ghost
                  size="large"
                  className="w-3 ml-2"
                  onClick={() => setOpenAddModal(!openAddModal)}
                >
                  Manage Community code
                </Button>
                <Button
                  icon={<DownloadOutlined />}
                  onClick={() => setOpenDownloadButtonsModal(true)}
                  loading={isExporting}
                  type="primary"
                  ghost
                  size="large"
                  className="w-1"
                />
                <CustomInputField
                  value={globalFilterSearch}
                  onChange={(e: string) => onGlobalFilterChange(e)}
                  onPressEnter={() => {}}
                  inputProps={{
                    size: "large",
                    placeholder: "Search",
                    allowClear: true,
                    className: "ml-2 w-2",
                    prefix: <SearchOutlined />,
                  }}
                  theme={CustomInputField}
                  mergeThemes
                />
              </div>
            </div>
            <DataTable
              ref={dt}
              value={communityCodeData?.userCodes || []}
              first={first}
              totalRecords={communityCodeData?.totalRecords || 0}
              paginator
              rows={rows}
              onPage={onPage}
              onFilter={onFilter}
              lazy
              rowsPerPageOptions={[10, 25, 50, 100]}
              dataKey="id"
              filters={filters}
              globalFilterFields={[
                "code",
                "subscriptionPlan",
                "status",
                "country",
                "province",
              ]}
              emptyMessage="No users found."
              rowHover
              scrollable
              scrollHeight="40rem"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Users"
              stateStorage="session"
              stateKey="dt-state-community-code-table"
              className="mx-3"
            >
              <Column
                field="firstUsed"
                header="First Used"
                headerStyle={tableHeader}
                dataType="date"
                body={dateBodyTemplate}
                bodyStyle={tableBody}
                style={{ background: "#FBFBFB" }}
              />
              <Column
                field="firstName"
                header="First Name"
                headerStyle={tableHeader}
                style={{ background: "#FBFBFB" }}
                bodyStyle={tableBody}
              />
              <Column
                field="lastName"
                header="Last name"
                headerStyle={tableHeader}
                style={{ background: "#FBFBFB" }}
                bodyStyle={tableBody}
              />
              <Column
                field="code"
                header="Community Code"
                headerStyle={tableHeader}
                style={{ background: "#FBFBFB" }}
                bodyStyle={tableBody}
                filter
                filterElement={referralFilterTemplate}
                filterMatchModeOptions={[
                  { label: "Contains", value: "contains" },
                ]}
              />
              <Column
                field="country"
                header="Country"
                headerStyle={tableHeader}
                style={{ background: "#FBFBFB", width: "70rem" }}
                bodyStyle={tableBody}
                filter
                filterPlaceholder="Search by country"
                filterMatchModeOptions={[{ label: "Equals", value: "equals" }]}
              />
              <Column
                field="province"
                header="Province"
                headerStyle={tableHeader}
                style={{ background: "#FBFBFB", width: "70rem" }}
                bodyStyle={tableBody}
                filter
                filterPlaceholder="Search by province"
                filterMatchModeOptions={[{ label: "Equals", value: "equals" }]}
              />
              <Column
                field="subscriptionPlan"
                header="Subscription Plan"
                headerStyle={tableHeader}
                style={{ background: "#FBFBFB" }}
                filter
                filterPlaceholder="Search by plan"
                filterMenuStyle={{ width: "14rem" }}
                filterMatchModeOptions={[{ label: "Equals", value: "equals" }]}
              />
              <Column
                field="status"
                header="Subscription status"
                headerStyle={tableHeader}
                style={{ background: "#FBFBFB" }}
                body={statusBodyTemplate}
                filter
                filterElement={statusFilterTemplate}
                filterMatchModeOptions={[{ label: "Equals", value: "equals" }]}
              />
            </DataTable>
          </Spin>
        </BodyContainer>
      </Container>
      <Modal
        centered
        open={openAddModal}
        onOk={() => setOpenAddModal(false)}
        onCancel={handleClose}
        width={550}
        closeIcon={
          <CustomIcons.CloseIcon
            width={30}
            height={30}
            viewBox={null}
            svgColor="#1C274C"
          />
        }
        footer={null}
      >
        <div className="w-full pt-2 mx-2">
          {postReferral.isSuccess ? (
            <div className="flex w-full h-full gap-2 flex-column align-items-center justify-content-center">
              <div className="flex w-full justify-content-center">
                <CustomIcons.SuccessIcon
                  width={100}
                  height={100}
                  viewBox={null}
                  svgColor="#179717"
                />
              </div>
              <div style={successTitle}> Successful</div>
              <div style={successSubtitle} className="pb-4">
                {" "}
                You have successfully added a new Community Code
              </div>
              <Button
                type="primary"
                ghost
                onClick={handleClose}
                size="large"
                className="w-4"
              >
                close
              </Button>
            </div>
          ) : (
            <>
              <div
                className="flex w-full py-3 justify-content-start"
                style={modelTitle}
              >
                Manage Community code
              </div>
              <CommunityCodeFormIndex
                countriesData={countries as ICountry[]}
                onModalClose={handleClose}
                refetch={refetch}
              />
            </>
          )}
        </div>
      </Modal>
      <Modal
        centered
        open={openDownloadButtonsModal}
        onOk={() => setOpenDownloadButtonsModal(false)}
        onCancel={() => setOpenDownloadButtonsModal(false)}
        width={550}
        closeIcon={
          <CustomIcons.CloseIcon
            width={30}
            height={30}
            viewBox={null}
            svgColor="#1C274C"
          />
        }
        footer={null}
      >
        <div
          className="flex w-12 py-3 justify-content-start"
          style={modelTitle}
        >
          Download As
        </div>
        <Button
          className="flex justify-center w-full px-2 mb-1 text-white bg-successColor"
          onClick={() => exportCSV(false)}
        >
          CSV
        </Button>
        <Button
          className="flex justify-center w-full px-2 mb-1 text-white bg-errorColor"
          onClick={() => exportPdf()}
        >
          PDF
        </Button>
      </Modal>
    </div>
  );
};

export default CommunityCodes;

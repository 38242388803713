import React, { useEffect, useRef, useState } from "react";
import HeaderComponent from "../../components/Header/Header";
import { Button, Modal, Spin, Tag, message } from "antd";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { tableBody, tableHeader } from "../../styles/TableStyles";
import { useParams } from "react-router-dom";
import {
  useAddCompanyPayment,
  useGetCompanyPaymentsData,
} from "../../hooks/useCompanies";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import moment from "moment";
import { Calendar, CalendarChangeEvent } from "primereact/calendar";
import BackButton from "../../components/widgets/common/BackButton";
import { modelTitle } from "../../styles/ModalStyles";
import { CustomIcons } from "../../assets";
import CompanyPayment from "../../components/Forms/CompanyPayment";

type CompanyParams = {
  id: string | undefined;
};

const ProofOfPayment = () => {
  // @ts-ignore
  const dt = useRef<DataTable>(null);
  const { id } = useParams<CompanyParams>();
  const [payments, setPayments] = useState<ICompanyPayments[]>([]);
  const [filters, setFilters] = useState<DataTableFilterMeta | undefined>(
    undefined
  );
  const addPayment = useAddCompanyPayment();
  const [openCompanyPaymentModal, setOpenCompanyPaymentModal] = useState(false);
  const { isFetching, data, isLoading, refetch } = useGetCompanyPaymentsData(
    id as string
  );

  const handleClose = async () => {
    await setOpenCompanyPaymentModal(false);
    addPayment.reset();
  };

  const handleUploadPayment = async (payment: ICompanyUploadPayments) => {
    try {
      payment.companyId = id as string;
      payment.amount = Number(payment.amount);
      await addPayment.mutateAsync(payment);
      refetch();
      message.success("Payment has been saved successfully.");
    } catch (error) {
      console.log(error);
    }
  };

  const getSeverity = (status: boolean) => {
    switch (status) {
      case true:
        return "success";
      case false:
        return "error";
      default:
        return "success";
    }
  };

  const getPayments = (payments: ICompanyPayments[]) => {
    if (Array.isArray(payments)) {
      return [...(payments || [])].map((d) => {
        // @ts-ignore
        d.dateOfPayment = new Date(d.dateOfPayment);
        return d;
      });
    }
    return [];
  };

  const dateBodyTemplate = (rowData: ICompanyPayments) => {
    return moment(rowData.dateOfPayment).format("YYYY/MM/DD");
  };

  const statusBodyTemplate = (rowData: ICompanyPayments) => {
    return rowData.status == true ? (
      <Tag color={getSeverity(rowData.status)}>POP Uploaded</Tag>
    ) : (
      <Tag color={getSeverity(rowData.status)}>No POP Available</Tag>
    );
  };

  const linkBodyTemplate = (rowData: ICompanyPayments) => {
    return rowData.pdfLink ? (
      <a
        target="_blank"
        className="text-secondaryColor underline"
        href={rowData.pdfLink}
      >
        Popdocument.pdf
      </a>
    ) : (
      ""
    );
  };

  const AmountBodyTemplate = (rowData: ICompanyPayments) => {
    return `R${rowData.amount}`;
  };

  const initFilters = () => {
    setFilters({
      amount: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },

      dateOfPayment: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
    });
  };

  useEffect(() => {
    if (data) {
      setPayments(getPayments(data));
    }

    initFilters();
  }, [data]);

  const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e: CalendarChangeEvent) => {
          options.filterCallback(e.value, options.index);
        }}
        dateFormat="yy/mm/dd"
        placeholder="yyyy/mm/dd"
        mask="9999/99/99"
      />
    );
  };

  return (
    <div className="flex w-full h-full bg-backgroundColor">
      <div className="w-full h-full max-h-screen overflow-y-auto disable-scrollbars">
        <div className="flex w-full justify-start items-center">
          <BackButton />
          <HeaderComponent title="Payment History" />
        </div>

        <Spin tip="" spinning={isFetching || isLoading} delay={500}>
          <div className="w-full h-full px-3 py-2">
            <div className="flex w-full flex-row justify-end">
              <Button
                type="primary"
                ghost
                size="large"
                className="w-2 ml-2"
                onClick={() => {
                  setOpenCompanyPaymentModal(true);
                }}
              >
                Upload New Payment
              </Button>
            </div>
          </div>
          <DataTable
            value={payments}
            ref={dt}
            paginator
            rows={10}
            rowsPerPageOptions={[10, 25, 50, 100]}
            dataKey="id"
            filters={filters}
            globalFilterFields={["dateOfPayment", "status", "amount"]}
            emptyMessage="No payments found."
            rowHover
            scrollable
            scrollHeight="40rem"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Users"
            stateStorage="session"
            stateKey="dt-state-proof-of-payment-table"
            className="mx-3"
          >
            <Column
              field="dateOfPayment"
              header="Date Of Payment"
              headerStyle={tableHeader}
              filterField="dateOfPayment"
              dataType="date"
              style={{ background: "#FBFBFB" }}
              bodyStyle={tableBody}
              body={dateBodyTemplate}
              filter
              filterElement={dateFilterTemplate}
            />
            <Column
              field="status"
              header="Status"
              headerStyle={tableHeader}
              style={{ background: "#FBFBFB" }}
              bodyStyle={tableBody}
              body={statusBodyTemplate}
            />
            <Column
              field="amount"
              header="Amount"
              headerStyle={tableHeader}
              style={{ background: "#FBFBFB" }}
              bodyStyle={tableBody}
              filter
              body={AmountBodyTemplate}
            />
            <Column
              field="pdfLink"
              header="PDF"
              headerStyle={tableHeader}
              style={{ background: "#FBFBFB" }}
              bodyStyle={tableBody}
              body={linkBodyTemplate}
            />
          </DataTable>
        </Spin>
      </div>
      <Modal
        centered
        open={openCompanyPaymentModal}
        onOk={() => setOpenCompanyPaymentModal(false)}
        onCancel={() => setOpenCompanyPaymentModal(false)}
        width={550}
        closeIcon={
          <CustomIcons.CloseIcon
            width={30}
            height={30}
            viewBox={null}
            svgColor="#1C274C"
          />
        }
        footer={null}
      >
        <div className="w-full pt-2 mx-2">
          <div
            className="w-12 flex justify-content-start py-3"
            style={modelTitle}
          >
            Upload new payment
          </div>

          <CompanyPayment
            onModalClose={() => {
              handleClose();
            }}
            onFormFinish={(companyPayment: ICompanyUploadPayments) => {
              handleUploadPayment(companyPayment);
            }}
            isLoading={addPayment.isLoading}
            isSuccess={addPayment.isSuccess}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ProofOfPayment;

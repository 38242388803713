import { carousel } from "./../services/queries/carouselQueries";
/* eslint-disable no-underscore-dangle */
import {
  InfiniteData,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { queries } from "../services/queries";
import Carousel from "../services/Carousel";
import { isErrorMessage } from "../util/helpers";
import { users } from "../services/queries/usersQueries";
import { UserType } from "../util/enums";

export function useCarouselData(): UseQueryResult<ICarouselData[]> {
  return useQuery({
    ...queries.carousel.getCarouselData,
    queryFn: () => Carousel.getCarouselData(),
  });
}

export function useAddCarousel() {
  const queryClient = useQueryClient();

  return useMutation(Carousel.addCarousel, {
    meta: {
      conflict: "Found whilst making changes",
      badRequest: "Please make sure all fields are entered correctly.",
      notFound: "Case could not be found. Please try again",
    },
  });
}

export function useGetCarouselDataById(
  id: number | null | string
): UseQueryResult<IEditCarouselData> {
  return useQuery({
    ...queries.carousel.GetCarousalDataById(id),
  });
}

export function useEditCarousel() {
  return useMutation(
    async (carousel: IEditCarouselData) => Carousel.editCarousel(carousel),
    {
      meta: {
        conflict: "Found whilst making changes",
        badRequest: "Please make sure all fields are entered correctly.",
        notFound: "Case could not be found. Please try again",
      },
    }
  );
}

import React from "react";
import type { UploadProps } from "antd";
import { message, Upload } from "antd";
import UploadIcon from "../../assets/icons/UploadIcon";
import Colors from "../../config/Colors";
import { RcFile, UploadFile } from "antd/es/upload/interface";

type UploadDocumentProp = {
  actionURL: string;
  onComplete: (done: boolean) => void;
};

const UploadDocument = ({ actionURL, onComplete }: UploadDocumentProp) => {
  const { Dragger } = Upload;
  const props: UploadProps = {
    name: "file",
    multiple: false,
    action: actionURL,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(
          `${info.file.name} file uploaded successfully. Job started successfully`
        );
        onComplete(true);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
        onComplete(false);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const beforeUpload = (file: RcFile, fileList: UploadFile[]) => {
    const isExcelFile =
      file.type === "application/vnd.ms-excel" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.oasis.opendocument.spreadsheet";

    if (!isExcelFile) {
      message.error("You can only upload Excel files!");
    }

    return isExcelFile;
  };
  return (
    <Dragger {...props} beforeUpload={beforeUpload}>
      <div className="ant-upload-drag-icon flex justify-content-center">
        <UploadIcon height={30} width={30} svgColor={Colors.appMainColor} />
      </div>
      <div className="ant-upload-text mt-4">
        Drop your files here or click here to upload
      </div>
    </Dragger>
  );
};

export default UploadDocument;

/* eslint-disable no-console */
/* eslint-disable default-case */
import { observer } from "mobx-react-lite";
import React, { useState, useEffect } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Modal, Tag, Tabs, TabsProps, message } from "antd";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import { BodyContainer, Container } from "../../styles/ContainerStyles";
import { tableBody, tableHeader, tableName } from "../../styles/TableStyles";
import {
  modelTitle,
  successTitle,
  successSubtitle,
} from "../../styles/ModalStyles";
import HeaderComponent from "../../components/Header/Header";
import Spin from "../../components/widgets/Spinner/Spin";
import Colors from "../../config/Colors";
import CustomInputField from "../../components/widgets/Inputs/CustomInputField";
import { CustomIcons } from "../../assets";
import "./Admins.css";
import {
  useAdmins,
  usePostAdmin,
  usePostEmergencyAdmin,
  usePostGeneralAdmin,
  useRemoveAdmin,
} from "../../hooks/useAdmin";
import NewAdminForm from "../../components/Forms/NewAdmin";
import { copyObjects } from "../../util/copyObjects";
import AddExistingAdminForm from "../../components/Forms/AddExistingAdmin";
import { UserType } from "../../util/enums";

const Admins = observer(() => {
  const { isLoading, data: admins, refetch, isFetching } = useAdmins();
  const addAdmin = usePostAdmin();
  const addEmergencyAdmin = usePostEmergencyAdmin();
  const addGeneralAdmin = usePostGeneralAdmin();
  const removeAdmin = useRemoveAdmin();
  const [currentAdmin, setCurrentAdmin] = useState<IUser | null>(null);
  const [openPostModal, setOpenPostAdminModal] = useState(false);
  const [openRemoveAdminModal, setRemoveAdminUserModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState("1");
  const [filters, setFilters] = useState<DataTableFilterMeta | undefined>(
    undefined
  );
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");

  const [messageApi, contextHolder] = message.useMessage();
  const messageKey = "createGeneralAdmin";

  const destroyMessage = () => {
    messageApi.destroy(messageKey);
  };

  useEffect(() => {
    initFilters();
  }, []);

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      firstName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      lastName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    });
    setGlobalFilterValue("");
  };
  const clearFilter = () => {
    initFilters();
  };

  const onGlobalFilterChange = (e: string) => {
    const _filters = { ...filters };
    // @ts-ignore
    _filters.global.value = e;

    setFilters(_filters);
    setGlobalFilterValue(e);
  };

  const roleBodyTemplate = (rowData: IAdmin) => {
    return (
      <div className="flex flex-row items-center gap-2">
        {rowData.role}
        <div className="mb-3">
          <Tag bordered={false} color="blue">
            {rowData.roleCount}
          </Tag>
        </div>
      </div>
    );
  };

  const customInputTheme = {
    token: {
      colorPrimaryHover: `${Colors.appMainColor}`,
    },
  };

  const handlePostAdmin = async (
    newAdmin: IUser,
    role: number | null,
    emergencyId: number | null | undefined
  ) => {
    try {
      if (role === 21) {
        const updatedAdmin = { ...newAdmin, userTypeId: 21 };
        await addAdmin.mutateAsync(updatedAdmin);
      } else {
        const emergencyAdmin: IEmergencyAdmin = copyObjects(newAdmin, {
          EmergencyId: emergencyId || 17,
        });
        await addEmergencyAdmin.mutateAsync(emergencyAdmin);
      }

      await refetch();
    } catch (error) {
      console.error(error);
    }
  };
  const handleRemoveAdmin = async () => {
    try {
      if (currentAdmin) {
        await removeAdmin.mutateAsync(currentAdmin?.id);
        await refetch();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlePostGeneralAdmin = async (
    key: string,
    role: number | null,
    typeId: number | null | undefined
  ) => {
    try {
      messageApi.open({
        key: messageKey,
        type: "loading",
        content: "Creating admin..",
        duration: 0,
      });

      const Admin: IGeneralAdmin = {
        role:
          role === UserType.SUPER_ADMIN ? "Super Admin " : "Emergency Admin",
        key,
        typeId: typeId ?? null,
      };

      await addGeneralAdmin.mutateAsync(Admin);

      messageApi.open({
        key: messageKey,
        type: "success",
        content: "Created admin..",
        duration: 0,
      });
      setTimeout(() => {
        destroyMessage();
      }, 2000);

      await refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = async () => {
    setOpenPostAdminModal(false);
    setRemoveAdminUserModal(false);
    setCurrentAdmin(null);
    addAdmin.reset();
    addEmergencyAdmin.reset();
    addGeneralAdmin.reset();
    removeAdmin.reset();
  };

  const onTabChange = (key: string) => {
    setActiveIndex(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `New`,
      children: (
        <NewAdminForm
          onFormFinish={(
            admin: IUser,
            role: number | null,
            emergencyId: number | null | undefined
          ) => {
            handlePostAdmin(admin, role, emergencyId);
          }}
          onModelClose={() => setOpenPostAdminModal(false)}
          isLoading={addAdmin.isLoading || addEmergencyAdmin.isLoading}
          isSuccess={addAdmin.isSuccess || addEmergencyAdmin.isSuccess}
        />
      ),
    },
    {
      key: "2",
      label: `Existing`,
      children: (
        <AddExistingAdminForm
          onFormFinish={(
            user: string,
            role: number | null,
            emergencyId: number | null | undefined
          ) => {
            handlePostGeneralAdmin(user, role, emergencyId);
          }}
          onModelClose={() => setOpenPostAdminModal(false)}
          isLoading={addGeneralAdmin.isLoading}
          isSuccess={addGeneralAdmin.isSuccess}
        />
      ),
    },
  ];

  return (
    <div className="w-full h-full">
      {contextHolder}
      <Container className="w-full h-full">
        <BodyContainer className="w-full">
          <HeaderComponent title="Manage admin" />
          <Spin tip="" spinning={isLoading || isFetching} delay={500}>
            <div className="w-full h-full px-3 card Users">
              <div className="flex py-3 justify-content-between align-content-center">
                <div
                  className="flex flex-row align-items-center"
                  style={tableName}
                >
                  Manage admin
                </div>
                <div className="flex flex-row gap-1 justify-content-center align-items-center">
                  <Button
                    type="primary"
                    ghost
                    onClick={() => setOpenPostAdminModal(true)}
                    size="large"
                    className="w-8"
                  >
                    +Add admin
                  </Button>
                  <CustomInputField
                    value={globalFilterValue}
                    onChange={(e: string) => onGlobalFilterChange(e)}
                    onPressEnter={() => {}}
                    inputProps={{
                      size: "large",
                      placeholder: "Search",
                      allowClear: true,
                      className: "ml-2 w-11",
                      prefix: <SearchOutlined />,
                    }}
                    theme={customInputTheme}
                    mergeThemes
                  />
                </div>
              </div>
              <DataTable
                value={admins}
                paginator
                rows={10}
                rowsPerPageOptions={[10, 25, 50, 100]}
                dataKey="id"
                filters={filters}
                globalFilterFields={[
                  "firstName",
                  "lastName",
                  "phoneNumber",
                  "emailAddress",
                ]}
                emptyMessage="No admins found."
                rowHover
                scrollable
                scrollHeight="40rem"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} admins"
                stateStorage="session"
                stateKey="dt-state-admins-table"
              >
                <Column
                  field="firstName"
                  header="First Name"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                />
                <Column
                  field="lastName"
                  header="Last Name"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                />
                <Column
                  field="role"
                  header="Admin Role"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                  body={roleBodyTemplate}
                />
                <Column
                  field="emailAddress"
                  header="Email"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                />
                <Column
                  field="phoneNumber"
                  header="Number"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                />
              </DataTable>
            </div>
          </Spin>
        </BodyContainer>
        <Modal
          centered
          open={openPostModal}
          onOk={() => setOpenPostAdminModal(false)}
          onCancel={() => setOpenPostAdminModal(false)}
          width={550}
          closeIcon={
            <CustomIcons.CloseIcon
              width={30}
              height={30}
              viewBox={null}
              svgColor="#1C274C"
            />
          }
          footer={null}
        >
          <div className="w-full pt-2 mx-2">
            {addAdmin.isSuccess || addEmergencyAdmin.isSuccess ? (
              <div className="flex w-full h-full gap-2 flex-column align-items-center justify-content-center">
                <div className="flex w-full justify-content-center">
                  <CustomIcons.SuccessIcon
                    width={100}
                    height={100}
                    viewBox={null}
                    svgColor="#179717"
                  />
                </div>
                <div style={successTitle}> Successful</div>
                <div style={successSubtitle} className="pb-4">
                  {" "}
                  You have successfully added a new admin
                </div>
                <Button
                  type="primary"
                  ghost
                  onClick={handleClose}
                  size="large"
                  className="w-4"
                >
                  close
                </Button>
              </div>
            ) : (
              <>
                <div
                  className="flex w-12 py-3 justify-content-start"
                  style={modelTitle}
                >
                  +Add admin
                </div>
                <Tabs
                  defaultActiveKey={activeIndex}
                  items={items}
                  onChange={onTabChange}
                  animated
                  tabBarStyle={{ paddingLeft: 15 }}
                />
              </>
            )}
          </div>
        </Modal>
        <Modal
          centered
          open={openRemoveAdminModal}
          onOk={() => setRemoveAdminUserModal(false)}
          onCancel={handleClose}
          width={550}
          closeIcon={
            <CustomIcons.CloseIcon
              width={30}
              height={30}
              viewBox={null}
              svgColor="#1C274C"
            />
          }
          footer={null}
        >
          <div className="w-full pt-2 mx-2">
            {removeAdmin.isSuccess ? (
              <div className="flex w-full h-full gap-2 flex-column align-items-center justify-content-center">
                <div className="flex w-full justify-content-center">
                  <CustomIcons.SuccessIcon
                    width={100}
                    height={100}
                    viewBox={null}
                    svgColor="#179717"
                  />
                </div>
                <div style={successTitle}> Successful</div>
                <div style={successSubtitle} className="pb-4">
                  {" "}
                  You have successfully removed admin
                </div>
                <Button
                  type="primary"
                  ghost
                  onClick={handleClose}
                  size="large"
                  className="w-4"
                >
                  close
                </Button>
              </div>
            ) : (
              <>
                <div
                  className="flex w-12 py-3 justify-content-start"
                  style={modelTitle}
                >
                  Remove Admin
                </div>

                <div className="flex w-full gap-2 col-12 justify-content-end align-content-center">
                  <Button
                    type="primary"
                    ghost
                    onClick={handleClose}
                    size="large"
                    className="w-3"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    loading={removeAdmin.isLoading}
                    size="large"
                    className="w-3 bg-[#1757a2] shadow-none"
                    onClick={handleRemoveAdmin}
                  >
                    Confirm
                  </Button>
                </div>
              </>
            )}
          </div>
        </Modal>
      </Container>
    </div>
  );
});

export default Admins;

import React, { useState, useEffect, useRef } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import VirtualList from "rc-virtual-list";
import {
  Avatar,
  Divider,
  List,
  Button,
  Row,
  Col,
  message,
  Drawer,
  Tabs,
} from "antd";
import type { TabsProps } from "antd";
import { useInfiniteQuery } from "@tanstack/react-query";
import { getInitials } from "../../../util/helpers";
import Colors from "../../../config/Colors";
import Spin from "../../widgets/Spinner/Spin";
import { modelTitle } from "../../../styles/ModalStyles";
import NewCommunityAdmin from "../../General/NewCommunityAdmin";
import Communities from "../../../services/Communities";
import ExistingUserTab from "./ExistingUser";

const ContainerHeight = 150;

const CommunityAdmins: React.FC<{ communityId: number | null }> = ({
  communityId,
}) => {
  // List related stuff
  const limit = 5;
  // const query = useGetCommunityOperators(communityId, limit, offset);
  const {
    data: QueryData,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isError,
    error,
    refetch,
  } = useInfiniteQuery(
    ["getCommunityOperators", communityId], // unique key
    ({ pageParam = 0 }) =>
      Communities.getCommunityOperators(communityId, limit, pageParam), // query function
    {
      getNextPageParam: (lastPage: ICommunityOperatorsResponse, allPages) =>
        lastPage.hasMore ? allPages.length * limit : undefined, // Use the length of the last page as the next page parameter
    }
  );

  // New related stuff
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState("1");

  const isMounted = useRef(true);

  const onTabChange = (key: string) => {
    setActiveIndex(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `New`,
      children: (
        <NewCommunityAdmin
          communityId={communityId}
          setCloseDrawer={setOpenDrawer}
          refetchAdmins={() => {
            refetch();
          }}
        />
      ),
    },
    {
      key: "2",
      label: `Existing`,
      children: (
        <ExistingUserTab
          communityId={communityId}
          setCloseDrawer={setOpenDrawer}
          refetchAdmins={() => {
            refetch();
          }}
        />
      ),
    },
  ];

  const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    if (
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
      ContainerHeight
    ) {
      if (hasNextPage) {
        fetchNextPage();
      }
    }
  };

  useEffect(() => {
    // Component mounted

    return () => {
      // Component will unmount
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      refetch();
    }
  }, [communityId]);

  useEffect(() => {
    if (QueryData?.pages) {
      const lastPage = QueryData.pages[QueryData.pages.length - 1];
      if (lastPage?.operators && lastPage.operators.length >= 1) {
        message.success(
          `${lastPage.operators.length} admins loaded successfully`
        );
      }
    }
  }, [QueryData]);

  const renderNothing = () => {
    return <Divider plain>It's all, nothing more 🤐</Divider>;
  };

  return (
    <div>
      <div>
        <Spin tip="" spinning={isFetching || isLoading}>
          <List>
            <VirtualList
              data={QueryData?.pages.flatMap((page) => page.operators) || []}
              height={ContainerHeight}
              itemHeight={10}
              itemKey="id"
              onScroll={onScroll}
            >
              {(item: ICommunityOperators) => (
                <List.Item key={item.id}>
                  <Row align="middle" style={{ marginBottom: "5px" }}>
                    <Col>
                      {item.avatarUrl ? (
                        <Avatar
                          src={item.avatarUrl}
                          size={32}
                          shape="square"
                          style={{ borderColor: Colors.appMainColor }}
                        />
                      ) : (
                        <Avatar
                          shape="square"
                          style={{ backgroundColor: "#87d068" }}
                        >
                          {getInitials(`${item.firstName}  ${item.lastName}`)}
                        </Avatar>
                      )}
                    </Col>
                    <Col
                      style={{
                        marginLeft: "16px",
                        fontWeight: "bold",
                        fontSize: "16px",
                        color: Colors.mainTextColor,
                      }}
                    >
                      {item.firstName}
                    </Col>
                    <Col style={{ marginLeft: "16px" }}>{item.email}</Col>
                  </Row>
                </List.Item>
              )}
            </VirtualList>
          </List>
          {!hasNextPage && <>{renderNothing()}</>}
        </Spin>

        <div className="flex w-full gap-2 pt-4 col-12 justify-content-end align-content-center">
          <Button
            type="primary"
            ghost
            onClick={() => setOpenDrawer(true)}
            size="large"
            className="w-4"
          >
            + Add new admin
          </Button>
        </div>
      </div>
      {/* This className comes from Messaging css */}
      <div className="Messaging">
        <Drawer
          //title="Community"
          placement="right"
          size="default"
          width={640}
          closable={false}
          onClose={() => {
            setOpenDrawer(false);
          }}
          open={openDrawer}
        >
          <div
            className="flex w-12 py-3 justify-content-start"
            style={modelTitle}
          >
            + Add new admin
          </div>
          <Tabs
            defaultActiveKey={activeIndex}
            items={items}
            onChange={onTabChange}
            animated
          />
        </Drawer>
      </div>
    </div>
  );
};

export default React.memo(CommunityAdmins);

import React, { useEffect, useState } from "react";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import notification from "antd/es/notification";
import { MessageOutlined } from "@ant-design/icons";
import { IRouteProps } from "../pages";
import Body from "./styles";
import { APP_LOCAL_URL_HOST } from "../config/urls";
import { NotificationSound } from "../assets";
import {
  useDirectMessageByCase,
  useDirectMessageByIds,
} from "../hooks/useDirectMessage";
import { useOperatorEmergencyCases } from "../hooks/useOperators";
import { useQueryClient } from "@tanstack/react-query";
import { queries } from "../services/queries";

const PlainLayout = ({ component: Component }: IRouteProps) => {
  // const queryClient = useQueryClient();
  // const user = window.localStorage.getItem("user");
  // const [userData, setUserData] = useState<IUser>();
  // const [currentUserId, setCurrentUserId] = useState<string | null | number>(0);
  // const [currentChatId, setCurrentChatId] = useState<string | number | null>(0);
  // const [currentReceiverId, setCurrentReceiverId] = useState<
  //   string | number | null
  // >(0);
  // const [queriesEnabled, setQueriesEnabled] = useState(false);

  // // Fetches Messages by Emergency Case Id and stores it in Cache
  // const loadMessageByCase = useDirectMessageByCase(currentChatId);

  // // Fetches Messages By CurrentUser and Partner/Sender of the new message and Stores it in Cache
  // const loadMssageByIds = useDirectMessageByIds(
  //   currentUserId,
  //   currentReceiverId,
  //   queriesEnabled
  // );

  // // Fetches Emergency Cases By Current Operator, allows us to refetch the emergency cases details
  // const { refetch } = useOperatorEmergencyCases(currentUserId);

  // const buildHubConnection = (userId: string): HubConnection => {
  //   return new HubConnectionBuilder()
  //     .withUrl(`${APP_LOCAL_URL_HOST}/chatHub?userId=${userId}`)
  //     .withAutomaticReconnect()
  //     .build();
  // };

  // const startMessageConnection = async (userId: string) => {
  //   const messageConnection = buildHubConnection(userId);

  //   messageConnection.on("DirectMessage", (message: any, ...args) => {
  //     const receivedMessage = JSON.parse(message) as IDirectMessage;
  //     // this.unreadMessages.push(receivedMessage);
  //     notification.info({
  //       message: "New Message",
  //       icon: React.createElement(MessageOutlined),
  //       description: receivedMessage.messageText,
  //       className: "message-notification",
  //     });
  //     const audio = new Audio(NotificationSound);
  //     audio.play().catch(() => {
  //       audio.muted = true;
  //       audio.play().catch(() => {});
  //     });
  //     setCurrentReceiverId(receivedMessage.senderId);
  //     if (receivedMessage.emergencyCaseId) {
  //       setCurrentChatId(receivedMessage.emergencyCaseId);
  //       //  queryClient.setQueryData(
  //       //    queries.directMessage.conversationByCase(receivedMessage.emergencyCaseId)
  //       //      .queryKey,
  //       //    (oldDirectMessagesData: IDirectMessage[] = []) => {
  //       //      return [...oldDirectMessagesData, receivedMessage];
  //       //    }
  //       //  );
  //     }
  //     setQueriesEnabled(true);
  //     refetch();
  //   });

  //   messageConnection.onclose((error) => {
  //     console.error("SignalR connection closed: ", error);
  //   });

  //   try {
  //     await messageConnection.start();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   if (user) {
  //     setUserData(JSON.parse(user));
  //     setCurrentUserId(JSON.parse(user).id);
  //     startMessageConnection(JSON.parse(user).id);
  //   }
  // }, [user]);
  return (
    <Body>
      <Component />
    </Body>
  );
};

export default PlainLayout;

import { createQueryKeys } from "@lukemorales/query-key-factory";
import ResponderCompany from "../ResponderCompany";

export const responderCompanies = createQueryKeys("responderCompanies", {
  all: null,
  overview: (pageNumber: number, pageSize: number) => ({
    queryKey: [{ pageNumber, pageSize }],
    queryFn: () => ResponderCompany.getOverViewCompanies(pageNumber, pageSize),
  }),
});

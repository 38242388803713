/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-vars */
import { Avatar, Button, Form, Input, message, Upload } from "antd";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import React, { useEffect, useRef, useState } from "react";
import { copyObjects } from "../../util/copyObjects";
import Colors from "../../config/Colors";
import DaggerCard from "../Cards/DaggerCard/DaggerCard";
import { uploadUrl } from "@/config/urls";

const MAX_LENGTH = 200;
const { Dragger } = Upload;

type NewCommunityFormProp = {
  communityValues?: ICommunity;
  onFormFinish: (emergency: IAddCommunity) => void;
  onModelClose: () => void;
  isLoading: boolean;
  isSuccess: boolean;
};

const NewCommunityForm = ({
  communityValues,
  onFormFinish,
  onModelClose,
  isLoading,
  isSuccess,
}: NewCommunityFormProp) => {
  const [form] = Form.useForm();
  const [charactersLeft, setCharactersLeft] = useState(MAX_LENGTH);
  const [imageUrl, setImageUrl] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const onFinish = async (formValues: any) => {
    const values: IAddCommunity = formValues as IAddCommunity;
    onFormFinish(values);
  };

  const handleClose = () => {
    form.resetFields();
    setFileList([]);
    setImageUrl("");
    setCharactersLeft(MAX_LENGTH);
    onModelClose();
  };

  const beforeUpload = (file: RcFile) => {
    const isJpegOrPngOrGif = ["image/jpeg", "image/png", "image/gif"].includes(
      file.type
    );
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isJpegOrPngOrGif) {
      message.error("You can only upload JPG/PNG/GIF file!");
      return false; // This prevents the file from being added to the list
    }
    if (!isLt2M) {
      message.error("Image must smaller than 10MB!");
      return false; // This prevents the file from being added to the list
    }
    return isJpegOrPngOrGif && isLt2M;
  };

  const props: UploadProps = {
    name: "file",
    multiple: false,
    action: uploadUrl,
    onChange(info) {
      setFileList([...info.fileList]); // Update the local fileList state
      const { status } = info.file;
      if (status === "done") {
        form.setFieldsValue({ communityIcon: info.file.response.path });
        setImageUrl(info.file.response.path);
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      //console.log("Dropped files", e.dataTransfer.files);
    },
  };

  useEffect(() => {
    if (communityValues) {
      form.setFieldsValue(communityValues);
      if (communityValues.communityIcon) {
        setImageUrl(communityValues.communityIcon);
      }
      if (communityValues.communityDescription) {
        setCharactersLeft(
          MAX_LENGTH - communityValues.communityDescription.length
        );
      }
    } else {
      form.resetFields();
    }
  }, [communityValues, form]);

  useEffect(() => {
    if (isSuccess) {
      setCharactersLeft(MAX_LENGTH);
      form.resetFields();
    }
  }, [isSuccess]);

  return (
    <Form
      form={form}
      name="newCommunity"
      onFinish={onFinish}
      layout="vertical"
      style={{ width: "100%" }}
      className="grid"
      initialValues={communityValues}
    >
      <div className="col-12">
        <Form.Item
          name="communityName"
          label="Name of community"
          rules={[{ required: true, message: "Please enter community name!" }]}
          className="mb-1"
        >
          <Input size="large" placeholder="Sandton CPF" />
        </Form.Item>
      </div>
      <div className="col-12">
        <Form.Item
          name="communityDescription"
          label="Description"
          rules={[
            { required: true, message: "Please enter community description!" },
            {
              max: MAX_LENGTH,
              message: `Description cannot be longer than ${MAX_LENGTH} characters`,
            },
          ]}
          className="mb-1"
        >
          <>
            <Input.TextArea
              value={form.getFieldValue("communityDescription")}
              placeholder="This is the community of Sandton CPF"
              maxLength={MAX_LENGTH}
              onChange={(e) => {
                form.setFieldsValue({ communityDescription: e.target.value });
                setCharactersLeft(MAX_LENGTH - e.target.value.length);
              }}
              rows={3}
            />
            {charactersLeft <= 100 && (
              <div
                className="py-1"
                style={{
                  fontSize: "13px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  color: Colors.subtleTextColor,
                }}
              >
                {charactersLeft} characters left
              </div>
            )}
          </>
        </Form.Item>
      </div>
      <Form.Item
        name="communityIcon"
        label="Icon"
        rules={[
          { required: true, message: "Please enter community icon!" }, // Custom validator to check if a valid logo file has been uploaded
          () => ({
            validator(_, value) {
              // If the file is currently uploading, skip this validation check
              if (!imageUrl) {
                // Check if logo URL is not set
                return Promise.reject(new Error("Please upload a valid logo."));
              }
              return Promise.resolve();
            },
          }),
        ]}
        className="mb-5 col-12"
      >
        <div className="flex w-full gap-6 flx-row justify-content-start">
          {imageUrl.length > 0 ? (
            <Avatar
              shape="square"
              size={64}
              alt="emergency icon"
              src={imageUrl}
            />
          ) : (
            <Avatar shape="square" size={64} alt="emergency icon" />
          )}
          <Dragger
            {...props}
            beforeUpload={beforeUpload}
            className="w-full"
            style={{ background: "transparent" }}
            maxCount={1}
            fileList={fileList} // Use the local fileList state
            accept=".jpg,.jpeg,.png" // This allows only .jpg, .jpeg, and .png files
          >
            <DaggerCard />
          </Dragger>
        </div>
      </Form.Item>
      <Form.Item className="w-12 pt-2 mb-1">
        <div className="flex w-full gap-2 col-12 justify-content-end align-content-center">
          <Button
            type="primary"
            ghost
            onClick={handleClose}
            size="large"
            className="w-3"
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            size="large"
            className="w-3 bg-[#1757a2] shadow-none"
          >
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default NewCommunityForm;

import React, { FC, ButtonHTMLAttributes } from "react";
import Colors from "../../../config/Colors";
import { cardTitleStyle, numberStyle } from "./styles";
import { CustomIcons } from "../../../assets";
import ResponderAnalytics from "../../../pages/Responder Analytics/ResponderAnalytics";

interface ResponderAnalyticsCardProps {
  title: string;
  number: number | string;
}

export const ResponderAnalyticsCard: FC<ResponderAnalyticsCardProps> = ({
  title,
  number,
}) => {
  return (
    <div
      className="col-12 md:col-6 lg:col-4 shadow-5 h-8rem  w-20rem surface-card border-round animate-fadeInRightBig animate-fast "
      style={{ backgroundColor: `${Colors.popupColor}` }}
    >
      <div className="p-3 text-center" style={{ borderRadius: 12 }}>
        <div className="pt-2 w-full truncate" style={cardTitleStyle}>
          {title}
        </div>
        <div style={numberStyle}>{number}</div>
      </div>
    </div>
  );
};

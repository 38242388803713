/* eslint-disable import/newline-after-import */
import React, { useRef, useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import { Button, Spin, Rate, Select } from "antd";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { BodyContainer, Container } from "./styles";
import HeaderComponent from "../../components/Header/Header";
import { DashboardCard } from "../../components/Cards/DashboardCard";
import { tableBody, tableHeader, tableName } from "../../styles/TableStyles";
import { useOperatorAnalytics } from "../../hooks/useOperators";
import Colors from "../../config/Colors";
import AverageRatingDoughnut from "../../components/Charts/OperatorAnalytics/AverageRatingDoughnut";
import OperatorChatsTrend from "../../components/Charts/OperatorAnalytics/ChatsTrendLine";
import { useEmergencies } from "../../hooks/useEmergencies";
import AuthContext from "../../contexts/AuthContext";
import { ActionBodyText } from "../../styles/ContainerStyles";

export const OperatorAnalytics = () => {
  const { user, getEmergencyId, isAdmin } = useContext(AuthContext);
  const [selectedEmergency, setSelectedEmergency] = useState<number | null>(
    isAdmin ? null : getEmergencyId
  );
  const { isLoading, isFetching, data } =
    useOperatorAnalytics(selectedEmergency);

  const {
    data: emergencies,
    isLoading: loadingEmergencies,
    isFetching: refetchingEmergencies,
  } = useEmergencies();

  function formatTime(mins: number): string {
    if (mins < 60) {
      return `${Math.round(mins)} min`;
    }
    if (mins < 1440) {
      return `${Math.round(mins / 60)} hours`;
    }
    if (mins < 10080) {
      return `${Math.round(mins / 1440)} days`;
    }
    if (mins < 40320) {
      return `${Math.round(mins / 10080)} weeks`;
    }
    if (mins < 525600) {
      return `${Math.round(mins / 40320)} months`;
    }
    return `${Math.round(mins / 525600)} years`;
  }

  const averageResponseTemplate = (rowData: ITop5Operator) => {
    return formatTime(rowData.averageResponseTime);
  };

  const ratingBodyTemplate = (rowData: ITop5Operator) => {
    return (
      <Rate
        disabled
        defaultValue={rowData.rating}
        style={{ color: `${Colors.appMainColor}` }}
      />
    );
  };

  const handleChange = (value: number | null) => {
    setSelectedEmergency(value);
  };

  return (
    <div className="h-full w-full">
      <Container className="w-full h-full">
        <BodyContainer className="w-full">
          <HeaderComponent title="Operator Analytics" />
          <Spin tip="" spinning={isLoading || isFetching} delay={500}>
            {isAdmin && (
              <div className="w-full py-1 px-4 flex justify-end">
                <Select
                  defaultValue={null}
                  placeholder="Operator's type"
                  size="large"
                  loading={loadingEmergencies || refetchingEmergencies}
                  onChange={handleChange}
                  style={{ width: 120 }}
                >
                  <Select.Option value={null}>All</Select.Option>
                  {emergencies?.map((item: IEmergency) => (
                    <Select.Option
                      value={item.id}
                      key={`key-emergencies-${item.id}`}
                    >
                      {item.emergencyType}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            )}

            {data !== null && data !== undefined && (
              <>
                <div className="py-1">
                  <div className="flex flex-row gap-3 overflow-x-scroll disable-scrollbars py-3 px-2">
                    {data.metrics.map((item: IAnalyticsMetric) => (
                      <div key={`key-${item.name}`}>
                        <DashboardCard
                          title={item.name}
                          number={Number(item.total.toFixed(2))}
                          percentage={Number(item.increase.toFixed(2))}
                          trend={item.increase >= 0}
                          key={`key-${item.name}`}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="grid px-2 animate-fadeInUp animate-faster pb-2">
                  <div className="col-12 md:col-6 animate-bounce animate-repeat-2">
                    <div className="w-12 h-full">
                      {data.averageOperatorRatings && (
                        <AverageRatingDoughnut
                          operators={data.averageOperatorRatings}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-12 md:col-6 animate-bounce animate-repeat-2">
                    <div className="w-12 h-full">
                      {data.chatMonthlyTrend && (
                        <OperatorChatsTrend
                          chatMonthly={data.chatMonthlyTrend}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-full h-full px-2 pb-5 Users">
                  <div className="px-3 py-3 card  border-round shadow-3">
                    <div className="flex flex-row gap-1 justify-content-between align-items-center mb-2 w-full">
                      <div
                        className="flex flex-row align-items-center"
                        style={tableName}
                      >
                        Top Operators
                      </div>
                      <NavLink
                        to={
                          isAdmin ? "/home/operators" : "/home/operator-table"
                        }
                        className="ml-2 w-2"
                      >
                        <Button type="primary" ghost size="large">
                          View all operators
                        </Button>
                      </NavLink>
                    </div>
                    <DataTable
                      paginator
                      rows={10}
                      value={data.top5Operators}
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      dataKey="id"
                      globalFilterFields={[
                        "number",
                        "firstName",
                        "lastName",
                        "emergencyType",
                        "averageResponseTime",
                        "rating",
                      ]}
                      emptyMessage="No operators found."
                      rowHover
                      scrollable
                      scrollHeight="40rem"
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} operators"
                      stateStorage="session"
                      stateKey="dt-state-top-5-operators-table"
                      className="mx-0"
                    >
                      <Column
                        field="number"
                        header="Number"
                        headerStyle={tableHeader}
                        bodyStyle={tableBody}
                        style={{ background: "#FBFBFB" }}
                      />
                      <Column
                        field="firstName"
                        header="First name"
                        headerStyle={tableHeader}
                        style={{ background: "#FBFBFB" }}
                        bodyStyle={tableBody}
                      />
                      <Column
                        field="lastName"
                        header="Last name"
                        headerStyle={tableHeader}
                        style={{ background: "#FBFBFB" }}
                        bodyStyle={tableBody}
                      />
                      <Column
                        field="emergencyType"
                        header="Operator Type"
                        headerStyle={tableHeader}
                        style={{ background: "#FBFBFB" }}
                        bodyStyle={tableBody}
                      />
                      <Column
                        field="averageResponseTime"
                        header="Average Response Time"
                        headerStyle={tableHeader}
                        bodyStyle={tableBody}
                        style={{ background: "#FBFBFB" }}
                        body={averageResponseTemplate}
                      />
                      <Column
                        field="rating"
                        header="Star Rating"
                        headerStyle={tableHeader}
                        style={{ background: "#FBFBFB" }}
                        bodyStyle={tableBody}
                        body={ratingBodyTemplate}
                      />
                    </DataTable>
                  </div>
                </div>
              </>
            )}
          </Spin>
        </BodyContainer>
      </Container>
    </div>
  );
};

/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
import { Button, Form, Input, Modal, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import {
  BodyContainer,
  Container,
  SubHeading,
  Warning,
  modelTitle,
  successSubtitle,
  successTitle,
} from "./styles";
import HeaderComponent from "../../components/Header/Header";
import "./Profile.css";
import { Avatar } from "primereact/avatar";
import Images, { CustomIcons } from "../../assets";
import ChangePassword from "../../components/Forms/ChangePassword";
import { copyObjects, toIUser, toUser } from "../../util/copyObjects";
import {
  useChangePassword,
  useUpdateUser,
  useUsers,
} from "../../hooks/useUsers";
import AuthContext from "../../contexts/AuthContext";

const Profile = () => {
  const [form] = Form.useForm();
  const { user, setUser } = useContext(AuthContext);
  const updateUser = useUpdateUser();
  const updateUserPassword = useChangePassword();
  const [openEditPasswordModal, setEditPasswordModal] = useState(false);
  const [editPassword, setEditPassword] = useState(null);
  const handleClose = async () => {
    setEditPasswordModal(false);
    updateUserPassword.reset();
  };
  const onFinish = async (userValues: any) => {
    const values: IUser = copyObjects(user, userValues);
    try {
      await updateUser.mutateAsync(values);
      setUser(values);
      message.success("Your profile has been updated successfully");
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangePassword = async (passwordBody: IChangePassword) => {
    if (user?.id === null || user?.id === undefined) {
      message.error("Please refresh the page");
      return;
    }

    const passwordObject: IChangePassword = copyObjects(passwordBody, {
      UserId: user?.id,
    });

    try {
      await updateUserPassword.mutateAsync(passwordObject);
      message.success("Your password has been updated successfully");
      setEditPasswordModal(false);
      //updateUserPassword.reset();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="w-full h-full">
      <Container className="w-full h-full">
        <BodyContainer className="w-full h-full">
          <HeaderComponent title="Profile" />
          {user && (
            <div className="flex justify-center align-items-center">
              {/* Personal Informnation card */}
              <div className="w-3/5 px-3 rounded surface-card shadow-5">
                <SubHeading className="pt-4 text-left">
                  Personal Information
                </SubHeading>
                <div className="flex flex-row justify-center w-full">
                  <Avatar
                    imageAlt="user avatar"
                    image={
                      user.profilePicture
                        ? user.profilePicture
                        : Images?.userAvatar
                    }
                    shape="circle"
                    size="xlarge"
                  />
                </div>
                <Form
                  layout="vertical"
                  className="w-full mt-6 h-3/5"
                  initialValues={user}
                  onFinish={onFinish}
                  form={form}
                >
                  <div className="flex flex-row w-full gap-2">
                    <Form.Item
                      name="firstName"
                      label="First Name"
                      className="w-1/2"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your first name!",
                        },
                      ]}
                    >
                      <Input placeholder="Neli" size="large" />
                    </Form.Item>
                    <Form.Item
                      name="lastName"
                      label="Last Name"
                      className="w-1/2"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your last name!",
                        },
                      ]}
                    >
                      <Input placeholder="Neli" size="large" />
                    </Form.Item>
                  </div>
                  <Form.Item name="emailAddress" label="Email Address">
                    <Input
                      placeholder="example@email.com"
                      size="large"
                      disabled
                    />
                  </Form.Item>
                  <Form.Item
                    name="phoneNumber"
                    label="Phone Number"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your phone number!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;
                          if (phoneNumberPattern.test(value)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Please enter a valid phoneNumber!")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input placeholder="+27721234567" size="large" />
                  </Form.Item>
                  <Form.Item>
                    <Warning
                      className="pl-1 text-start"
                      onClick={() => {
                        setEditPasswordModal(true);
                      }}
                    >
                      Change Password
                    </Warning>
                  </Form.Item>
                  <Form.Item className="w-full">
                    <div className="flex items-center justify-end w-full">
                      <Button
                        loading={updateUser.isLoading}
                        type="primary"
                        ghost
                        size="large"
                        className="w-2 bg-[#1757a2] shadow-none"
                        htmlType="submit"
                      >
                        Update
                      </Button>
                    </div>
                  </Form.Item>
                </Form>
              </div>
            </div>
          )}
        </BodyContainer>
        <Modal
          centered
          open={openEditPasswordModal}
          onOk={() => setEditPasswordModal(false)}
          onCancel={() => setEditPasswordModal(false)}
          width={550}
          closeIcon={
            <CustomIcons.CloseIcon
              width={30}
              height={30}
              viewBox={null}
              svgColor="#1C274C"
            />
          }
          footer={null}
        >
          <div className="w-full pt-2 mx-2">
            {editPassword === true ? (
              <div className="flex w-full h-full gap-2 flex-column align-items-center justify-content-center">
                <div className="flex w-full justify-content-center">
                  <CustomIcons.SuccessIcon
                    width={100}
                    height={100}
                    viewBox={null}
                    svgColor="#179717"
                  />
                </div>
                <div style={successTitle}> Successful</div>
                <div style={successSubtitle} className="pb-4">
                  {" "}
                  You have successfully edit user details
                </div>
                <Button
                  type="primary"
                  ghost
                  onClick={handleClose}
                  size="large"
                  className="w-4"
                >
                  close
                </Button>
              </div>
            ) : (
              <>
                <div
                  className="flex w-12 py-3 justify-content-start"
                  style={modelTitle}
                >
                  Change Password
                </div>

                <ChangePassword
                  onFormFinish={(body: IChangePassword) =>
                    handleChangePassword(body)
                  }
                  onModelClose={() => setEditPasswordModal(false)}
                  isLoading={updateUserPassword.isLoading}
                  isSuccess={updateUserPassword.isSuccess}
                />
              </>
            )}
          </div>
        </Modal>
      </Container>
    </div>
  );
};
export default Profile;

/* eslint-disable max-len */
import React from "react";
import Colors from "../../config/Colors";

export function CloseIcon({
  height = 1, // number type by default
  width = 1,
  viewBox = "0 0 24 24",
  svgColor = Colors.appMainColor,
}: CustomSVGProp) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox={viewBox || "0 0 24 24"}
      fill="none"
    >
      <g fill="none" stroke={svgColor} strokeWidth="1.5">
        <path d="M2 12c0-4.714 0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22s-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12Z" />
        <path strokeLinecap="round" d="m14.5 9.5l-5 5m0-5l5 5" />
      </g>
    </svg>
  );
}
export default CloseIcon;

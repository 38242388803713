/* eslint-disable no-underscore-dangle */
import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { queries } from "../services/queries";
import { Emergencies, Operators } from "../services";

export function useEmergencies() {
  return useQuery({
    ...queries.emergencies.all,
    queryFn: () => Emergencies.getAll(),
    staleTime: 60 * 1000, // 1 Min
  });
}

export function useEmergencyDetail(id: string) {
  return useQuery(queries.emergencies.detail(id));
}

export function useEmergenciesList(filter: Filters) {
  return useQuery({
    ...queries.emergencies.list(filter),
    staleTime: 60 * 1000,
  });
}

export function useSearchEmergencies(
  filters: Filters,
  query: string,
  limit = 15
) {
  return useQuery({
    ...queries.emergencies.list(filters)._ctx.search(query, limit),
    enabled: Boolean(query),
  });
}
export function useAddEmergency() {
  const queryClient = useQueryClient();

  return useMutation(Emergencies.addEmergency, {
    onSuccess: (newEmergency) => {
      queryClient.setQueriesData(
        queries.emergencies.detail(newEmergency.data.id).queryKey,
        newEmergency.data
      );
      queryClient.invalidateQueries({
        queryKey: queries.emergencies._def,
      });
      queryClient.invalidateQueries({
        queryKey: ["operators"],
      });
    },
  });
}

export function useEditEmergency() {
  const queryClient = useQueryClient();

  return useMutation(Emergencies.updateEmergencies, {
    onSuccess: (newEmergency) => {
      queryClient.setQueriesData(
        queries.emergencies.detail(newEmergency.id).queryKey,
        newEmergency
      );
      queryClient.invalidateQueries({
        queryKey: queries.emergencies._def,
      });
      queryClient.invalidateQueries({
        queryKey: ["operators"],
      });
    },
  });
}

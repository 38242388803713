/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import { LeftOutlined } from "@ant-design/icons";
import { Form, Input, Button, Checkbox, Alert } from "antd";
import UAParser from "ua-parser-js";
import { Images } from "../../assets/index";
import { apiUrl } from "../../config/urls";
import "./AccountLogin.css";
import PrimaryProgressBar from "../../components/widgets/Spinner/ProgressBar";
import Colors from "../../config/Colors";
import { copyObjects } from "../../util/copyObjects";
import { AuthContext } from "../../contexts/AuthContext";
import { Auth } from "../../services";
import { redirectToDashboard } from "../../util/helpers";

const AccountLogin: React.FC = () => {
  const navigate = useHistory();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const {
    user,
    setUser,
    isLoggedIn,
    setToken,
    tempUser,
    setTempUser,
    tempToken,
    setTempToken,
    setTempRefreshToken,
  } = useContext(AuthContext);

  // useEffect to check if user is logged in if so redirect
  useEffect(() => {
    if (user && isLoggedIn) {
      redirectToDashboard(user, navigate);
    }
  }, [user, isLoggedIn]);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [remember, setRemember] = useState(false);

  const handleRememberChange = (e: any) => {
    setRemember(e.target.checked);
  };

  const handleSubmit = async (values: any) => {
    setLoading(true);

    const url = `${apiUrl}/authentication/login/portal`;

    try {
      const parser = new UAParser();
      const deviceInfo = parser.getResult();
      const response = await axios.post(url, {
        username: values.email,
        password: values.password,
        device: "WEB",
        deviceModel: deviceInfo.device.model,
        operatingSystem: `${deviceInfo.os.name} ${deviceInfo.os.version}`,
        deviceDetails: JSON.stringify(deviceInfo),
      });

      const responseUser = response?.data;
      // Check if the user has roles and if any of those are not just 'User'
      const hasAdminRoles = responseUser?.userRoles.some(
        (role: { name: string }) => role.name !== "User"
      );
      if (hasAdminRoles) {
        const userData: IUser = copyObjects({}, { ...responseUser });
        // Check environment before OTP verification
        if (process.env.REACT_APP_ENV?.toLowerCase() === "production") {
          // Perform OTP verification in production
          const otpResponse = await Auth.sendOtpVerify(
            `%2B${userData.phoneNumber}`
          );
          if (otpResponse.data) {
            setTempUser(userData);
            setTempToken(userData.token);
            setTempRefreshToken(userData.refreshToken);
            navigate.push("/otp");
          } else {
            setSuccessMessage("OTP not sent! Please try again later.");
            setShowAlert(true);
          }
        } else {
          // Skip OTP verification in local development
          setUser(userData);
          setToken(userData.token);
          redirectToDashboard(userData, navigate);
        }
      } else {
        setSuccessMessage(
          "You are not authorized to access this portal! Only users with admin privileges can access this portal. Contact your administrator."
        );
        setShowAlert(true);
      }
    } catch (error) {
      console.log(error);
      setSuccessMessage("Wrong email or password!");
      setShowAlert(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-screen h-screen Account-Login-Container sm:overflow-auto md:overflow-hidden">
      <div className="flex flex-col items-center justify-start w-full h-screen md:h-full md:flex-row ">
        <img
          src={Images.backgroundImage}
          alt="safer city logo"
          className="hidden w-12 h-full md:flex md:w-2/4 md:min-h-full"
          style={{
            objectFit: "cover",
          }}
        />
        <div className="flex flex-col items-center justify-center w-full h-full px-2 md:w-2/4 ">
          <div className="flex w-full py-4 justify-content-center md:py-8">
            {showAlert && (
              <Alert message={successMessage} type="error" closable />
            )}
            {loading && <PrimaryProgressBar />}
          </div>
          <div className="flex flex-col justify-center w-full h-full align-items-center">
            <h2
              className="flex w-full pt-4 text-2xl font-bold justify-content-center md:pt-0"
              style={{
                color: `${Colors.appMainColor}`,
              }}
            >
              Account Login
            </h2>
            <Form
              name="loginForm"
              initialValues={{ remember: true }}
              onFinish={handleSubmit}
              className="w-2/3"
            >
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please enter your email!" },
                  { type: "email", message: "Please enter a valid email!" },
                ]}
              >
                <Input
                  size="large"
                  style={{
                    borderBottom: `1px solid ${Colors.appMainColor}`,
                    borderLeft: "none",
                    borderRight: "none",
                    borderTop: "none",
                    borderRadius: 0,
                  }}
                  placeholder="Email"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please enter your password!" },
                ]}
              >
                <Input.Password
                  size="large"
                  style={{
                    borderBottom: `1px solid ${Colors.appMainColor}`,
                    borderLeft: "none",
                    borderRight: "none",
                    borderTop: "none",
                    borderRadius: 0,
                  }}
                  placeholder="Password"
                  iconRender={(visible) =>
                    visible ? (
                      <Button
                        type="text"
                        style={{
                          color: `${Colors.appMainColor}`,
                          fontWeight: "bold",
                        }}
                        onClick={toggleShowPassword}
                      >
                        Hide
                      </Button>
                    ) : (
                      <Button
                        type="text"
                        style={{
                          color: `${Colors.appMainColor}`,
                          fontWeight: "bold",
                        }}
                        onClick={toggleShowPassword}
                      >
                        Show
                      </Button>
                    )
                  }
                  visibilityToggle
                  autoComplete="current-password"
                />
              </Form.Item>
              {/* <div className="flex py-5 justify-content-start">
                <Checkbox
                  checked={remember}
                  onChange={handleRememberChange}
                  className="flex align-self-start"
                  style={{ color: `${Colors.appMainColor}` }}
                >
                  <span
                    className="flex text-left align-items-start "
                    style={{
                      color: `${Colors.mainTextColor}`,
                    }}
                  >
                    Remember Me
                  </span>
                </Checkbox>
              </div> */}
              <Link to="/forgot_password">
                <p
                  className="pb-6 font-bold text-left"
                  style={{
                    color: `${Colors.appMainColor}`,
                  }}
                >
                  Forgot password?
                </p>
              </Link>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  className="w-8"
                  style={{
                    width: "100%",
                    backgroundColor: `${Colors.appMainColor}`,
                  }}
                >
                  Login
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountLogin;

import { UseQueryResult, useMutation, useQuery } from "@tanstack/react-query";
import ResponderAnalytics from "@/services/ResponderAnalytics";
import { responderAnalytics } from "../services/queries/responderAnalyticsQueries";

export function useGetResponderTableData(): UseQueryResult<IResponderAnalyticsFilters> {
  return useQuery({
    ...responderAnalytics.getResponderAnalyticsTableData,
    queryFn: () => ResponderAnalytics.getResponderTableData(),
  });
}

export function useGetResponderCardData(
  filters?: IResponderFilters
): UseQueryResult<IResponderAnalyticsCardDTO> {
  return useQuery({
    ...responderAnalytics.getResponderAnalyticsCardData(filters),
  });
}

export function useGetResponderMapData(
  filters?: IResponderFilters
): UseQueryResult<IMaplocations[]> {
  return useQuery({
    ...responderAnalytics.getResponderAnalyticsMapData(filters),
  });
}

export function useGetResponderIncidentPieGraph(
  filters?: IResponderFilters
): UseQueryResult<IIncidentPieGraph> {
  return useQuery({
    ...responderAnalytics.getResponderAnalyticsIncidentPieGraph(filters),
  });
}

export function useGetResponderTopCompanies(
  filters?: IResponderFilters
): UseQueryResult<IResponderAnalyticsCard[]> {
  return useQuery({
    ...responderAnalytics.getResponderAnalyticsTopCompanies(filters),
  });
}

export function useGetResponderCallOutsTable(
  filters?: IResponderFilters
): UseQueryResult<ICallOutsDTO[]> {
  return useQuery({
    ...responderAnalytics.getResponderCallOutsTable(filters),
  });
}

export function useResponderAnalyticsCallOutDetails(
  id: string | number
): UseQueryResult<ICallOutDetails> {
  return useQuery({
    ...responderAnalytics.getResponderAnalyticsCallOutDetails(id),
  });
}

export function useGetResponderDetailsCharts(
  id: string | number
): UseQueryResult<IResponderAnalyticsCharts> {
  return useQuery({
    ...responderAnalytics.getResponderDetailsCharts(id),
  });
}

export function useGetResponderDetailsTableData(
  id: string | number
): UseQueryResult<IResponderAnalyticsTableData> {
  return useQuery({
    ...responderAnalytics.getResponderDetailsTableData(id),
  });
}

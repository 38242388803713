import React, { useContext, useState } from "react";
import { Button } from "antd";
import AuthContext from "../../contexts/AuthContext";
import { useGetCommunityAdminCases } from "../../hooks/useCommunities";
import { CustomIcons } from "../../assets";
import Colors from "../../config/Colors";
import CommunityChatPanel from "../../components/MessagePanel/CommunityChatPanel";
import CommunityMessages from "../../components/General/CommunityMessage";

function CommunityChat() {
  const { user, getUserType, isCommunityAdmin } = useContext(AuthContext);
  const [currentCase, setCurrentCase] = useState<ICommunityAdminCase | null>(
    null
  );

  const communityCases = useGetCommunityAdminCases(user?.id, isCommunityAdmin);

  if (communityCases.isLoading) {
    return (
      <div className="flex items-center self-center justify-center flex-1 h-5/6">
        <CustomIcons.EOSloading
          width={60}
          height={60}
          svgColor={Colors.appMainColor}
        />
      </div>
    );
  }

  return (
    <div className="flex w-full h-full bg-backgroundColor">
      <div className="w-full h-full max-h-screen overflow-y-auto disable-scrollbars">
        <div className="px-3" style={{ width: "100%", height: "98%" }}>
          <div className="flex w-full h-full">
            <div className="flex flex-col w-1/4 h-full pt-3 overflow-y-auto">
              {/* community chat previews here */}
              <CommunityChatPanel
                cases={communityCases.data || []}
                setCurrentCase={setCurrentCase}
              />
            </div>
            <div className="flex flex-col w-3/4 h-full overflow-y-auto">
              {currentCase ? (
                <CommunityMessages
                  currentCase={currentCase}
                  setCurrentCase={setCurrentCase}
                />
              ) : (
                <div className="flex flex-col items-center justify-center flex-1 h-full">
                  <div className="text-lg font-bold text-mainTextColor">
                    Please select a message
                  </div>
                  <CustomIcons.MessageIcon
                    width={150}
                    height={150}
                    svgColor={Colors.appMainColor}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommunityChat;

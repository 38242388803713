import { message, Tabs, TabsProps } from "antd";
import React from "react";
import NewCommunityCodeForm from "./NewCommunityCode";
import EditCommunityCodes from "./EditCommunityCodes";
import {
  useGetAllCommunityCodeByCode,
  useGetCountries,
  useGetProvinces,
  usePostCommunityCode,
  useUpdateCommunity,
} from "@/hooks/useCommunityCodes";

type CommunityCodeModelProps = {
  countriesData: ICountry[];
  onModalClose: () => void;
  refetch: () => void;
};

const CommunityCodeFormIndex = ({
  //provincesData,
  countriesData,
  onModalClose,
  refetch,
}: CommunityCodeModelProps) => {
  const postCommunityCode = usePostCommunityCode();
  const updateCommunityCode = useUpdateCommunity();

  const handleAddCommunityCode = async (
    communityCodeProps: IPostCommunityCodeHandler
  ) => {
    try {
      if (communityCodeProps) {
        await postCommunityCode.mutateAsync(communityCodeProps);
        message.success("Community Code Added Successfully");
      }
      refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateCommunityCode = async (
    updateCommunityCodeProps: IUpdateCommuntiyCode
  ) => {
    try {
      if (updateCommunityCodeProps) {
        await updateCommunityCode.mutateAsync(updateCommunityCodeProps);
        message.success("Community Code Updated Successfully");
        refetch();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Add New Community Code",
      children: (
        <NewCommunityCodeForm
          onFormFinish={async (body: IPostCommunityCodeHandler) => {
            handleAddCommunityCode(body);
          }}
          onModelClose={() => {
            postCommunityCode.reset();
            onModalClose();
          }}
          countriesData={countriesData as ICountry[]}
          isLoading={postCommunityCode.isLoading}
          isSuccess={postCommunityCode.isSuccess}
        />
      ),
    },
    {
      key: "2",
      label: "Edit existing Code",
      children: (
        <EditCommunityCodes
          countriesData={countriesData as ICountry[]}
          isLoading={updateCommunityCode.isLoading}
          isSuccess={updateCommunityCode.isSuccess}
          onFormFinish={async (body: IUpdateCommuntiyCode) => {
            handleUpdateCommunityCode(body);
          }}
          onModelClose={() => {
            updateCommunityCode.reset();
            onModalClose();
          }}
        />
      ),
    },
  ];
  return (
    <div className="flex w-full h-full">
      <div className="w-full h-full px-3">
        <Tabs defaultActiveKey="1" items={items} />
      </div>
    </div>
  );
};

export default CommunityCodeFormIndex;

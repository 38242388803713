import React, { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import moment from "moment";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Calendar, CalendarChangeEvent } from "primereact/calendar";
import {
  ActionBodyText,
  BodyContainer,
  Container,
} from "../../../styles/ContainerStyles";
import HeaderComponent from "../../../components/Header/Header";
import { tableBody, tableHeader, tableName } from "../../../styles/TableStyles";
import Colors from "../../../config/Colors";
import CustomInputField from "../../../components/widgets/Inputs/CustomInputField";
import { useDraftedCategory } from "../../../hooks/useCrimes";
import BackButton from "../../../components/widgets/common/BackButton";

type categoryParams = {
  category: string;
};

const DraftsCategory = () => {
  const { category } = useParams<categoryParams>();
  const navigate = useHistory();
  const { data, isLoading, refetch } = useDraftedCategory(category);
  const navigateTo = (id: string) => {
    navigate.push(`/home/crime-report-detail/${id}`);
  };
  const customInputTheme = {
    token: {
      colorPrimaryHover: `${Colors.appMainColor}`,
    },
  };

  const [crime, setCrime] = useState<ITabItemCategory[]>();

  const [filters, setFilters] = useState<DataTableFilterMeta | undefined>(
    undefined
  );

  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      lastUpdated: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      category: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      reportedBy: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      crimeType: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      location: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      dateReported: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      caseNo: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    });
    setGlobalFilterValue("");
  };

  const clearFilter = () => {
    initFilters();
  };

  const onGlobalFilterChangeV2 = (e: string) => {
    const _filters = { ...filters };
    // @ts-ignore
    _filters.global.value = e;

    setFilters(_filters);
    setGlobalFilterValue(e);
  };

  const getCrimes = (dataTabs: ITabItemCategory[]) => {
    return [...(dataTabs || [])].map((d) => {
      // @ts-ignore
      d.dateReported = new Date(d.dateReported);

      return d;
    });
  };

  useEffect(() => {
    if (Array.isArray(data)) {
      setCrime(getCrimes(data));
    }
    setGlobalFilterValue("");

    initFilters();
  }, [data]);
  const dateBodyTemplate = (rowData: ITabItemCategory) => {
    return moment(rowData.lastUpdated).format("YYYY/MM/DD");
  };
  const categoryBodyTemplate = (rowData: ITabItemCategory) => {
    return rowData.category;
  };
  const reportedbyBodyTemplate = (rowData: ITabItemCategory) => {
    return rowData.reportedBy;
  };
  const locationBodyTemplate = (rowData: ITabItemCategory) => {
    return rowData.location;
  };
  const timeOfCrimeBodyTemplate = (rowData: ITabItemCategory) => {
    return rowData.time;
  };
  const dateReportedBodyTemplate = (rowData: ITabItemCategory) => {
    return moment(rowData.dateReported).format("YYYY/MM/DD");
  };
  const caseNoBodyTemplate = (rowData: ITabItemCategory) => {
    return rowData.caseNo;
  };
  const crimeTypeBodyTemplate = (rowData: ITabItemCategory) => {
    return rowData.crimeType;
  };

  const viewMoreBodyTemplate = (rowData: ITabItemCategory) => {
    const id = rowData.crimeId;
    return (
      <ActionBodyText
        onClick={() => {
          navigateTo(id);
        }}
      >
        View More
      </ActionBodyText>
    );
  };
  const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e: CalendarChangeEvent) => {
          options.filterCallback(e.value, options.index);
        }}
        dateFormat="yy/mm/dd"
        placeholder="yyyy/mm/dd"
        mask="9999/99/99"
      />
    );
  };
  return (
    <div className="w-full h-full">
      <Container className="h-full w-full">
        <BodyContainer className="h-full w-full">
          <div className="flex flex-row w-full justify-start align-items-center">
            <BackButton />
            <HeaderComponent title="Reported Crimes" />
          </div>
          <div className="px-3 mt-3">
            <div className="flex flex-row gap-1 justify-between mb-2 w-full">
              <div
                className="flex flex-row align-items-center"
                style={tableName}
              >
                {category}
              </div>
              <CustomInputField
                value={globalFilterValue}
                onChange={(e: string) => onGlobalFilterChangeV2(e)}
                onPressEnter={() => {}}
                theme={customInputTheme}
                inputProps={{
                  size: "large",
                  placeholder: "Search",
                  allowClear: true,
                  className: "ml-2 w-3",
                  prefix: <SearchOutlined />,
                }}
                mergeThemes
              />
            </div>
            <DataTable
              value={Array.isArray(crime) ? crime : []}
              paginator
              loading={isLoading}
              rows={10}
              filters={filters}
              globalFilterFields={[
                "lastUpdated",
                "category",
                "reportedBy",
                "crimeType",
                "location",
                "dateReported",
                "caseNo",
              ]}
              rowsPerPageOptions={[10, 25, 50, 100]}
              tableStyle={{ minWidth: "50rem" }}
              sortMode="multiple"
              emptyMessage="No reported crimes found."
              scrollable
              scrollHeight="40rem"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Operators"
              stateStorage="session"
              stateKey={`dt-state-crime-drafted-category-table`}
            >
              <Column
                header="Last Updated"
                headerStyle={tableHeader}
                style={{ background: "#FBFBFB", minWidth: "15rem" }}
                bodyStyle={tableBody}
                body={dateBodyTemplate}
                filter
              />
              <Column
                header="Reported By"
                headerStyle={tableHeader}
                style={{ background: "#FBFBFB", minWidth: "15rem" }}
                bodyStyle={tableBody}
                body={reportedbyBodyTemplate}
                filter
              />
              <Column
                header="Category of Crime"
                headerStyle={tableHeader}
                style={{ background: "#FBFBFB", minWidth: "25rem" }}
                bodyStyle={tableBody}
                body={categoryBodyTemplate}
                filter
              />
              <Column
                header="Crime Type"
                headerStyle={tableHeader}
                style={{ background: "#FBFBFB", minWidth: "25rem" }}
                bodyStyle={tableBody}
                body={crimeTypeBodyTemplate}
                filter
              />

              <Column
                header="Location"
                headerStyle={tableHeader}
                style={{ background: "#FBFBFB", minWidth: "15rem" }}
                bodyStyle={tableBody}
                body={locationBodyTemplate}
                filter
              />

              <Column
                header="Case Number"
                headerStyle={tableHeader}
                style={{ background: "#FBFBFB", minWidth: "15rem" }}
                bodyStyle={tableBody}
                body={caseNoBodyTemplate}
                filter
              />
              <Column
                field="dateReported"
                header="Date Reported"
                headerStyle={tableHeader}
                filterField="dateReported"
                dataType="date"
                body={dateReportedBodyTemplate}
                bodyStyle={tableBody}
                filter
                filterElement={dateFilterTemplate}
                style={{ background: "#FBFBFB", minWidth: "15rem" }}
              />
              <Column
                header="Time of Crime"
                headerStyle={tableHeader}
                style={{ background: "#FBFBFB", minWidth: "10rem" }}
                bodyStyle={tableBody}
                body={timeOfCrimeBodyTemplate}
              />

              <Column
                header=""
                headerStyle={tableHeader}
                style={{ background: "#FBFBFB", minWidth: "10 rem" }}
                bodyStyle={tableBody}
                body={viewMoreBodyTemplate}
              />
            </DataTable>
          </div>
        </BodyContainer>
      </Container>
    </div>
  );
};

export default DraftsCategory;

import React from "react";
import HeaderComponent from "../../components/Header/Header";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import CommunityCodes from "./CommunityCodes";
import ReferralCode from "./ReferralCode";

const AffliationCode = () => {
  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Community Code",
      children: <CommunityCodes />,
    },
    {
      key: "2",
      label: "Referral Code",
      children: <ReferralCode />,
    },
  ];

  return (
    <div className="flex w-full h-full bg-backgroundColor">
      <div className="w-full h-full max-h-screen overflow-y-auto disable-scrollbars">
        <HeaderComponent title="Affiliation Code" />
        <div className="w-full h-full px-3">
          <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </div>
      </div>
    </div>
  );
};

export default AffliationCode;

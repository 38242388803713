/* eslint-disable camelcase */
import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import { Tag } from "antd";
import { useModal } from "@/hooks/useModalStore";
import { Pencil } from "lucide-react";

import { Button } from "@/components/ui/button"; // Adjust the path as necessary
import { tableBody_2, TableHeader_2 } from "@/styles/TableStyles"; // Adjust the path as necessary
import Colors from "@/config/Colors";
import Spin from "@/components/widgets/Spinner/Spin";
import { useGetControlRoomOperators } from "@/hooks/useControlRoomOperators";

type OperatorTableProps = {};

type OperatorStatusColor = {
  bgColor: string;
  textColor: string;
};

const ControlRoomOperatorsTable: React.FC<OperatorTableProps> = ({}) => {
  const { onOpen, data } = useModal();

  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);

  const { data: operatorsData, isLoading } = useGetControlRoomOperators(
    page,
    rows
  );

  const onPage = (event: any) => {
    setRows(event.rows);
    setPage(event.page + 1);
  };

  const getSeverity = (
    ApplicationStatus: ResponderStatus
  ): OperatorStatusColor => {
    switch (ApplicationStatus) {
      case "Active":
        return { bgColor: "#D2F6C1", textColor: "#369A03" };
      case "Expired":
        return { bgColor: "bg-orange-400", textColor: "text-orange-900" };
      case "Suspended":
        return { bgColor: "#FDD6C7", textColor: "#CC3201" };
      default:
        return { bgColor: "#D2F6C1", textColor: "#369A03" };
    }
  };

  const statusBodyTemplate = (rowData: IControlRoomOperatorDetails) => {
    const { status } = rowData;
    const color = getSeverity(status);
    return (
      <Tag
        color={color.bgColor}
        className={`${color.bgColor} px-2.5 py-0.5 rounded`}
      >
        <p
          className={`${color.textColor} flex justify-center items-center mx-0 my-0`}
          style={{ color: `${color.textColor}` }}
        >
          {status}
        </p>
      </Tag>
    );
  };

  const dateBodyTemplate = (rowData: IControlRoomOperatorDetails) => {
    return moment(rowData.createdAt).format("YYYY-MM-DD");
  };

  const actionBodyTemplate = (rowData: IControlRoomOperatorDetails) => {
    return (
      <Button
        variant="ghost"
        size="icon"
        className="w-4 h-6 rounded-md hover:bg-transparent"
        onClick={() => {
          onOpen("editControlRoomOperator", {
            editControlRoomOperator: rowData,
          });
        }}
      >
        <Pencil color={Colors.appMainColor} />
      </Button>
    );
  };
  return (
    <Spin tip="Fetching operators" spinning={isLoading}>
      <DataTable
        value={operatorsData?.data || []}
        totalRecords={operatorsData?.totalCount || 0}
        paginator
        rows={rows}
        rowsPerPageOptions={[10, 25, 50, 100]}
        onPage={onPage}
        lazy // need to enable this so it knows to use backend
        dataKey="id"
        emptyMessage="No operators found."
        rowHover
        scrollable
        scrollHeight="45rem"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} operators"
        stateStorage="session"
        stateKey="dt-state-control-room-operators-table"
        className="mx-3 bg-transparent"
      >
        <Column
          field="firstName"
          header="First name"
          headerStyle={TableHeader_2}
          bodyStyle={tableBody_2}
          className="bg-transparent"
        />
        <Column
          field="lastName"
          header="Last name"
          headerStyle={TableHeader_2}
          bodyStyle={tableBody_2}
          className="bg-transparent"
        />
        <Column
          field="gender"
          header="Gender"
          headerStyle={TableHeader_2}
          bodyStyle={tableBody_2}
          className="bg-transparent"
        />
        <Column
          field="emailAddress"
          header="Email"
          headerStyle={TableHeader_2}
        />
        <Column
          field="specializationName"
          header="Specialization"
          headerStyle={TableHeader_2}
          bodyStyle={tableBody_2}
        />
        <Column
          field="createdAt"
          header="Created At"
          headerStyle={TableHeader_2}
          filterField="date"
          dataType="date"
          bodyStyle={tableBody_2}
          body={dateBodyTemplate}
        />
        <Column
          field="status"
          header="Status"
          headerStyle={TableHeader_2}
          body={statusBodyTemplate}
        />

        <Column
          headerStyle={TableHeader_2}
          exportable={false}
          body={actionBodyTemplate}
          bodyStyle={tableBody_2}
        />
      </DataTable>
    </Spin>
  );
};

export default ControlRoomOperatorsTable;

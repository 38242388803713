import React, { useRef } from "react";
import Lottie, { LottieProps } from "react-lottie-player";
import settings from "../../../assets/lottie/settings.json";

export default function SettingAnimation(props: LottieProps) {
  const animationRef = useRef<any | null>(null);

  const startPlay = () => {
    animationRef.current?.play();
  };
  const stopPlay = () => {
    animationRef?.current?.stop();
  };
  return (
    <div onMouseEnter={startPlay} onMouseLeave={stopPlay}>
      <Lottie animationData={settings} {...props} ref={animationRef} />
    </div>
  );
}

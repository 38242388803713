/* eslint-disable max-len */
/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Carousel, Input, Modal, Spin, message, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "sonner";
import {
  GoogleMap,
  GoogleMapApiLoader,
  Marker,
} from "react-google-map-wrapper";
import { BodyContainer, Container, SubHeading } from "./styles";
import HeaderComponent from "../../components/Header/Header";
import {
  useDraftCrime,
  useEditCrimeTypeCategory,
  useEditCrimeVehicleStatus,
  useReportedCrimeDetail,
  useVerifyCrime,
} from "../../hooks/useCrimes";
import AuthContext from "../../contexts/AuthContext";
import { CustomIcons, Images } from "../../assets";
import { successSubtitle, successTitle } from "../../styles/ModalStyles";
import Colors from "../../config/Colors";
import BackButton from "../../components/widgets/common/BackButton";
import CrimesData from "../../util/CrimesData.json";
import VehicleStatusDropdown from "@/components/DropDownMenus/VehicleStatusDropdown";
import { VehicleStatus } from "@/util/enums";

type userParams = {
  id?: string | undefined;
};

type MarkerProps = {
  text: string;
  lat: number;
  lng: number;
};

const CrimeReportDetail = () => {
  const { id } = useParams<userParams>();

  const history = useHistory();
  const [longitude, setLongitude] = useState<number>(28.047304);
  const [latitud, setLatitude] = useState<number>(-26.204103);
  const { data, isFetching, isLoading, refetch } = useReportedCrimeDetail(id);
  const { isAdmin } = useContext(AuthContext);
  const postVeify = useVerifyCrime();
  const postDraft = useDraftCrime();

  const editTypeCategory = useEditCrimeTypeCategory();
  const editCrimeVehicleStatus = useEditCrimeVehicleStatus();
  const [openPostModal, setPostModal] = useState(false);
  const [openReasonModal, setReasonModal] = useState(false);
  const [reason, setReason] = useState<string>("");
  const [openCrimeTypeModal, setCrimeTypeModal] = useState(false);
  const [openCrimeCategoryModal, setCrimeCategoryModal] = useState(false);
  const [editCrimeCategory, setEditCrimeCategory] = useState<
    string | undefined
  >(data?.category);
  const [editCrimeType, setEditCrimeType] = useState<string>("");
  const [selectedVehicleStatus, setSelectedVehicleStatus] = useState<VehicleStatus | null>(null);

  const handleCrimeCategoryChange = (value: string) => {
    setEditCrimeCategory(value);
  };
  const handleCrimeTypeChange = (value: string) => {
    setEditCrimeType(value);
  };

  const handleClosePostReject = async () => {
    setPostModal(false);
    setReasonModal(false);
    history.go(0);
  };

  const handleEditCategory = () => {
    message.info("Please update Crime Type");
    setCrimeCategoryModal(false);
  };

  const handleEditCrimeType = async () => {
    const crimeTypeCategoryValues: IEditCrimeTypeCategory = {
      id: Number(id),
      crimeType: editCrimeType,
      category: editCrimeCategory ?? (data?.category as string),
    };
    try {
      await editTypeCategory.mutateAsync(crimeTypeCategoryValues);
      setCrimeTypeModal(false);
      message.success(
        "Crime Type and Crime Category have been successfully updated"
      );
      refetch();
    } catch (error) {}
  };

  const selectedCategory = editCrimeCategory || data?.category;

  const handleClose = async () => {
    setCrimeTypeModal(false);
    setCrimeCategoryModal(false);
    setReasonModal(false);
  };

  const handlePost = async () => {
    try {
      const crimeId = Number(id);
      if (crimeId) {
        // show loader here
      
        await postVeify.mutateAsync(crimeId);
        setPostModal(true);
        // if it reach here the code ran and worked
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDraft = async () => {
    try {
      const Id = Number(id);
      const values: IDraftReportParams = {
        id: Id,
        reason,
      };

      if (values.reason.length > 1) {
        await postDraft.mutateAsync(values);
        setReasonModal(false);
        message.success("Crime has been successfully rejected");
        refetch();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEditVehicleStatus = async () => {
    if (selectedVehicleStatus) {
      const crimeVehicleValues: IEditCrimeVehicleStatus = {
        crimeId: Number(id),
        vehicleStatus: selectedVehicleStatus,
      };
      // eslint-disable-next-line no-useless-catch
      try {
        await editCrimeVehicleStatus.mutateAsync(crimeVehicleValues);
        setCrimeTypeModal(false);
        message.success("Crime Vehicle Status has been successfully updated");
        refetch();
      } catch (error) {
        throw error;
      }
    } else {
      message.warning("You need to select vehicle status to save the changes");
    }
  };

  useEffect(() => {
    setLatitude(data?.latitude as number);
    setLongitude(data?.longitude as number);
  }, [data?.latitude, data?.longitude]);

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center flex-1">
        <CustomIcons.EOSThreeDotsLoading
          width={100}
          height={100}
          viewBox={null}
          svgColor="#1C274C"
        />
      </div>
    );
  }
  return (
    <div className="w-full h-full">
      <Container className="w-full h-full">
        <BodyContainer className="w-full h-full py-2">
          <div className="flex flex-row justify-start w-full align-items-center">
            <BackButton />
            <HeaderComponent title="Crime Report" />
          </div>
          <div className="flex flex-row w-full gap-2">
            <div className="w-1/2 h-full mt-6 ml-3 ">
              <div className="flex flex-row w-full h-full">
                <div className="w-1/2 mb-5">
                  <div className="w-full pl-1 mb-1">
                    <label className="flex justify-start w-full">
                      First Name
                    </label>
                  </div>
                  <div className="w-full pr-2">
                    <Input
                      className="w-full"
                      readOnly
                      value={data?.firstName}
                    />
                  </div>
                </div>
                <div className="w-1/2 mb-5">
                  <div className="w-full pl-1 mb-1">
                    <label className="flex justify-start w-full">
                      Last Name
                    </label>
                  </div>
                  <div className="w-full pr-2">
                    <Input className="w-full" readOnly value={data?.lastName} />
                  </div>
                </div>
              </div>
              <div className="flex flex-row w-full h-full">
                <div className="w-1/2 mb-5">
                  <div className="w-full pl-1 mb-1">
                    <label className="flex justify-start w-full">Email</label>
                  </div>
                  <div className="w-full pr-2">
                    <Input className="w-full" readOnly value={data?.email} />
                  </div>
                </div>
                <div className="w-1/2 mb-5">
                  <div className="w-full pl-1 mb-1">
                    <label className="flex justify-start w-full">
                      Phone Number
                    </label>
                  </div>
                  <div className="w-full pr-2">
                    <Input className="w-full" readOnly value={data?.phoneNo} />
                  </div>
                </div>
              </div>
              <SubHeading className="flex justify-start w-full">
                Crime Report
              </SubHeading>
              <div className="w-full mb-5">
                <div className="w-full pl-1 mb-1">
                  <label className="flex justify-start w-full">
                    Location of Crime
                  </label>
                </div>
                <div className="w-full pr-2">
                  <Input className="w-full" readOnly value={data?.location} />
                </div>
              </div>
              <div className="w-full mb-5">
                <div className="w-full pl-1 mb-1">
                  <label className="flex justify-start w-full">
                    Case Number
                  </label>
                </div>
                <div className="w-full pr-2">
                  <Input className="w-full" readOnly value={data?.caseNumber} />
                </div>
              </div>
              {/* {(data?.carInsurance && data?.carRegistration) ?? ( */}
              <div className="flex flex-row w-full h-full">
                <div className="w-1/2 mb-5">
                  <div className="w-full pl-1 mb-1">
                    <label className="flex justify-start w-full">
                      Car Insurance
                    </label>
                  </div>
                  <div className="w-full pr-2">
                    <Input
                      className="w-full"
                      readOnly
                      value={data?.carInsurance}
                    />
                  </div>
                </div>
                <div className="w-1/2 mb-5">
                  <div className="w-full pl-1 mb-1">
                    <label className="flex justify-start w-full">
                      Car Registartion
                    </label>
                  </div>
                  <div className="w-full pr-2">
                    <Input
                      className="w-full"
                      readOnly
                      value={data?.carRegistration}
                    />
                  </div>
                </div>
              </div>
              {/* )} */}
              {/* {(data?.vehicleMake && data?.vehicleType) ?? ( */}
              <div className="flex flex-row w-full h-full">
                <div className="w-1/2 mb-5">
                  <div className="w-full pl-1 mb-1">
                    <label className="flex justify-start w-full">
                      Vehicle Make
                    </label>
                  </div>
                  <div className="w-full pr-2">
                    <Input
                      className="w-full"
                      readOnly
                      value={data?.vehicleMake}
                    />
                  </div>
                </div>
                <div className="w-1/2 mb-5">
                  <div className="w-full pl-1 mb-1">
                    <label className="flex justify-start w-full">
                      Vehicle Type
                    </label>
                  </div>
                  <div className="w-full pr-2">
                    <Input
                      className="w-full"
                      readOnly
                      value={data?.vehicleType}
                    />
                  </div>
                </div>
              </div>
              {/* )} */}
              <div className="flex flex-row w-full h-full">
                <div className="w-1/2 mb-5">
                  <div className="w-full pl-1 mb-1">
                    <label className="flex justify-start w-full">
                      Date of reported crime
                    </label>
                  </div>
                  <div className="w-full pr-2">
                    <Input
                      className="w-full"
                      readOnly
                      value={data?.dateOfCrime}
                    />
                  </div>
                </div>
                <div className="w-1/2 mb-5">
                  <div className="w-full pl-1 mb-1">
                    <label className="flex justify-start w-full">
                      Time of crime
                    </label>
                  </div>
                  <div className="w-full pr-2">
                    <Input
                      className="w-full"
                      readOnly
                      value={data?.timeOfCrime}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-row w-full h-full">
                <div className="w-1/2 mb-5">
                  <div className="flex items-center w-full pl-1 mb-1">
                    <label className="flex justify-start flex-grow">
                      Category of Crime
                    </label>
                    <button
                      type="button"
                      className="items-center h-2 pb-1 pr-3"
                      onClick={() => {
                        setCrimeCategoryModal(true);
                      }}
                    >
                      <CustomIcons.PencilIcon
                        height={12}
                        width={12}
                        svgColor={Colors.appMainColor}
                      />
                    </button>
                  </div>
                  <div className="w-full pr-2">
                    <Input className="w-full" readOnly value={data?.category} />
                  </div>
                </div>
                <div className="w-1/2 mb-5">
                  <div className="flex items-center w-full pl-1 mb-1">
                    <label className="flex justify-start flex-grow">
                      Crime Type
                    </label>
                    <button
                      type="button"
                      className="items-center h-2 pb-1 pr-3"
                      onClick={() => setCrimeTypeModal(true)}
                    >
                      <CustomIcons.PencilIcon
                        height={12}
                        width={12}
                        svgColor={Colors.appMainColor}
                      />
                    </button>
                  </div>
                  <div className="w-full pr-2">
                    <Input
                      className="w-full"
                      readOnly
                      value={data?.crimeType}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full mb-5">
                <div className="w-full pl-1 mb-1">
                  <label className="flex justify-start w-full">
                    Crime Description
                  </label>
                </div>
                <div className="w-full pr-2">
                  <TextArea
                    className="w-full"
                    readOnly
                    value={data?.crimeDescription}
                    autoSize
                  />
                </div>
              </div>
              <div className="w-full mb-5">
                <div className="w-full pl-1 mb-1">
                  <label className="flex justify-start w-full">
                    Reference Number
                  </label>
                </div>
                <div className="w-full pr-2">
                  <Input
                    className="w-full"
                    readOnly
                    value={data?.referenceNo}
                  />
                </div>
              </div>
              <div className="w-full mb-5">
                <div className="w-full pl-1 mb-1">
                  <label className="flex justify-start w-full">
                    Vehicle Status
                  </label>
                </div>
                <div className="w-full pr-2">
                  <VehicleStatusDropdown
                    selectedStatus={selectedVehicleStatus}
                    setSelectedStatus={setSelectedVehicleStatus}
                  />
                </div>
              </div>

              {isAdmin && (
                <div className="flex flex-row items-center justify-end w-full">
                  <Button
                    type="primary"
                    size="large"
                    loading={postDraft.isLoading}
                    ghost
                    disabled={data?.status === "Drafted" || data?.status === ""}
                    className="w-3 bg-[#1757a2] shadow-none mr-2"
                    onClick={() => {
                      setReasonModal(true);
                    }}
                  >
                    Reject crime
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    loading={postVeify.isLoading || editCrimeVehicleStatus.isLoading}
                    className="w-4 bg-[#1757a2] shadow-none"
                    onClick={() => {
                      // eslint-disable-next-line no-unused-expressions
                      data?.status === "Verified"
                        ? handleEditVehicleStatus()
                        : handlePost();
                    }}
                    disabled={
                      data?.status === "Verified" && !selectedVehicleStatus
                    }
                  >
                    {data?.status === "Verified"
                      ? "Save Changes"
                      : "Post crime"}
                  </Button>
                </div>
              )}
            </div>
            <div className="flex flex-col w-1/2 h-full mx-3 mt-6">
              <Spin tip="" spinning={isFetching || isLoading}>
                <div className="w-full h-1/2">
                  <GoogleMapApiLoader
                    apiKey={
                      process.env.REACT_APP_GOOGLE_MAPS_API_KEY
                        ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY
                        : ""
                    }
                  >
                    <GoogleMap
                      className="h-[400px] w-full"
                      zoom={4.5}
                      initialCenter={{
                        lat: -26.204103,
                        lng: 28.047304,
                      }}
                      center={{
                        lat: latitud,
                        lng: longitude,
                      }}
                    >
                      {data && (
                        <Marker
                          lat={data?.latitude as number}
                          lng={data?.longitude as number}
                        />
                      )}
                    </GoogleMap>
                  </GoogleMapApiLoader>
                </div>
              </Spin>
              <div className="w-full h-1/2">
                <SubHeading className="text-left">Media</SubHeading>
                {data?.mediaPath && data.mediaPath.length > 0 ? (
                  <div className="w-full h-full px-3">
                    <Carousel
                      className="w-full h-full pb-5"
                      autoplay
                      autoplaySpeed={5000}
                      dotPosition="top"
                      nextArrow={
                        <div style={{ color: "red", fontSize: "24px" }}>
                          Next
                        </div>
                      }
                      prevArrow={
                        <div style={{ color: "green", fontSize: "24px" }}>
                          Prev
                        </div>
                      }
                    >
                      {data.mediaPath.map((item: IMediaAssets, index) => (
                        <div key={`mapped-media-path-${index}`}>
                          {item.fileType === "image" ? (
                            <img
                              src={item.url}
                              className="w-full"
                              alt={`Media ${index}`}
                            />
                          ) : (
                            <div>
                              <p className="text-center">
                                {item.fileType === "application"
                                  ? "Click the link to download PDF"
                                  : item.fileType === "audio"
                                  ? "Click the link to download audio file"
                                  : item.fileType === "video"
                                  ? "Click the link to download video"
                                  : ""}
                              </p>
                              <a
                                href={item.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-base text-center"
                              >
                                Click here
                              </a>
                            </div>
                          )}
                        </div>
                      ))}
                    </Carousel>
                  </div>
                ) : (
                  <div className="flex items-center justify-center h-full">
                    <SubHeading>No Media found</SubHeading>
                  </div>
                )}
              </div>
            </div>
          </div>
        </BodyContainer>
        <Modal
          centered
          open={openPostModal}
          onOk={() => setPostModal(false)}
          onCancel={handleClosePostReject}
          width={550}
          closeIcon={
            <CustomIcons.CloseIcon
              width={30}
              height={30}
              viewBox={null}
              svgColor="#1C274C"
            />
          }
          footer={null}
        >
          <div className="w-full pt-2 mx-2">
            {postVeify.isSuccess && (
              <div className="flex w-full h-full gap-2 flex-column align-items-center justify-content-center">
                <div className="flex w-full justify-content-center">
                  <CustomIcons.SuccessIcon
                    width={100}
                    height={100}
                    viewBox={null}
                    svgColor="#179717"
                  />
                </div>
                <div style={successTitle}> Successful</div>
                <div style={successSubtitle} className="pb-4">
                  {" "}
                  You have successfully posted the crime
                </div>
                <Button
                  type="primary"
                  ghost
                  onClick={handleClosePostReject}
                  size="large"
                  className="w-4"
                >
                  close
                </Button>
              </div>
            )}
          </div>
        </Modal>
        <Modal
          centered
          open={openReasonModal}
          onOk={() => setReasonModal(false)}
          onCancel={handleClose}
          width={550}
          closeIcon={
            <CustomIcons.CloseIcon
              width={30}
              height={30}
              viewBox={null}
              svgColor="#1C274C"
            />
          }
          footer={null}
        >
          <SubHeading>Enter Rejection Reason </SubHeading>
          <TextArea
            rows={4}
            className="w-full"
            onChange={(event) => setReason(event.target.value)}
          />

          <div className="flex flex-row items-center justify-end w-full mt-3">
            <Button
              type="primary"
              size="large"
              ghost
              className="w-3 bg-[#1757a2] shadow-none mr-2"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              size="large"
              className="w-3 bg-[#1757a2] shadow-none"
              onClick={handleDraft}
              loading={postDraft.isLoading}
            >
              Save
            </Button>
          </div>
        </Modal>
        <Modal
          centered
          open={openCrimeTypeModal}
          onOk={() => setCrimeTypeModal(false)}
          onCancel={handleClose}
          width={550}
          closeIcon={
            <CustomIcons.CloseIcon
              width={30}
              height={30}
              viewBox={null}
              svgColor="#1C274C"
            />
          }
          footer={null}
        >
          <SubHeading>Select Crime Type</SubHeading>
          <Select
            defaultValue={data?.crimeType}
            style={{ width: 369, marginBottom: 10 }}
            onChange={handleCrimeTypeChange}
          >
            {CrimesData.filter(
              (crime) => crime.category === selectedCategory
            ).map((crime) => (
              <>
                {crime.labels.map((label) => (
                  <Select.Option key={label} value={label}>
                    {label}
                  </Select.Option>
                ))}
              </>
            ))}
          </Select>

          <div className="flex flex-row items-center justify-end w-full">
            <Button
              type="primary"
              size="large"
              ghost
              className="w-3 bg-[#1757a2] shadow-none mr-2"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              size="large"
              className="w-3 bg-[#1757a2] shadow-none"
              onClick={handleEditCrimeType}
              loading={editTypeCategory.isLoading}
            >
              Save
            </Button>
          </div>
        </Modal>
        <Modal
          centered
          open={openCrimeCategoryModal}
          onOk={() => setCrimeCategoryModal(false)}
          onCancel={handleClose}
          width={550}
          closeIcon={
            <CustomIcons.CloseIcon
              width={30}
              height={30}
              viewBox={null}
              svgColor="#1C274C"
            />
          }
          footer={null}
        >
          <SubHeading>Select Crime Category</SubHeading>
          <Select
            defaultValue={data?.category}
            style={{ width: 369, marginBottom: 10 }}
            onChange={handleCrimeCategoryChange}
          >
            {CrimesData.map((crime) => (
              <Select.Option value={crime.category}>
                {crime.category}
              </Select.Option>
            ))}
          </Select>
          <div className="flex flex-row items-center justify-end w-full">
            <Button
              type="primary"
              size="large"
              ghost
              className="w-3 bg-[#1757a2] shadow-none mr-2"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              size="large"
              className="w-3 bg-[#1757a2] shadow-none"
              onClick={handleEditCategory}
            >
              Save
            </Button>
          </div>
        </Modal>
      </Container>
    </div>
  );
};

export default CrimeReportDetail;

/* eslint-disable max-len */
import React from "react";
import Colors from "../../config/Colors";

export function ResponderCompanyIcon({
  height, // number type by default
  width,
  viewBox = "0 0 24 24",
  svgColor = `${Colors.appMainColor}`,
}: CustomSVGProp) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox={viewBox || "0 0 24 24"}
      fill="none"
    >
      <path
        fill={svgColor || "#888888"}
        d="M22 3H2C.9 3 0 3.9 0 5v14c0 1.1.9 2 2 2h20c1.1 0 1.99-.9 1.99-2L24 5c0-1.1-.9-2-2-2M9 8c1.65 0 3 1.35 3 3s-1.35 3-3 3s-3-1.35-3-3s1.35-3 3-3M2.08 19c1.38-2.39 3.96-4 6.92-4s5.54 1.61 6.92 4zm18.89-9.15l-.75 1.3l-1.47-.85V12h-1.5v-1.7l-1.47.85l-.75-1.3L16.5 9l-1.47-.85l.75-1.3l1.47.85V6h1.5v1.7l1.47-.85l.75 1.3L19.5 9z"
      />
    </svg>
  );
}
export default ResponderCompanyIcon;

/* eslint-disable no-unused-vars */
export enum Layouts {
  Landing = "",
  Home = "/home",
}

export enum COMMUNITY_ROLES {
  ACTIVE = 0,
  OPERATOR = 1,
}

export enum UserType {
  OPERATOR = 20,
  OPERATOR_ADMIN = 22,
  SUPER_ADMIN = 21,
  COMMUNITY_ADMIN = 23,
  // add other types as necessary
}

export enum PostApplicationStatus {
  Pending,
  Approved,
  Disabled,
  Rejected,
  Blocked,
}

export const PostApplicationStatusString = {
  [PostApplicationStatus.Pending]: "pending",
  [PostApplicationStatus.Approved]: "approved",
  [PostApplicationStatus.Disabled]: "disabled",
  [PostApplicationStatus.Rejected]: "rejected",
  [PostApplicationStatus.Blocked]: "blocked",
};

export enum OnlineStatus {
  Offline = 0,
  Online = 1,
}

export enum CommunityCaseStatus {
  UnResolved = 0,
  Resolved = 1,
}

export enum CommunityMessageType {
  User = 0,
  Operator = 1,
}

export enum VehicleStatus {
  Missing = 0,
  Found = 1,
}

import React, { useEffect, useState } from "react";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import Colors from "@/config/Colors";
import { useGetResponderTableData } from "@/hooks/useResponderAnalytics";

type AreaFilterProps = {
  onFilterChange: (name: string, value: any[]) => void;
};

const AreaDropDown = ({ onFilterChange }: AreaFilterProps) => {
  const { data, isLoading, isFetching } = useGetResponderTableData();
  const [selectedAreas, setSelectedAreas] = useState<string[]>([]);
  const areas: string[] = data?.areas || [];
  const areaOptions = areas.map((area) => ({ label: area, value: area }));

  const onHandleChange = (e: MultiSelectChangeEvent) => {
    let newSelectedAreas: string[] = [];
    setSelectedAreas(e.value as string[]);
    newSelectedAreas = e.value as string[];
    onFilterChange("areas", newSelectedAreas);
  };

  return (
    <div className="responderAnalytics flex h-20 w-3 py-1 px-1 mr-0.5 bg-appMainColor justify-center items-center flex-shrink-0">
      <MultiSelect
        value={selectedAreas}
        onChange={(e: MultiSelectChangeEvent) => onHandleChange(e)}
        options={areaOptions}
        filter
        placeholder="Area"
        maxSelectedLabels={3}
        style={{
          backgroundColor: Colors.appMainColor,
          borderColor: Colors.appMainColor,
          color: "#fff",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    </div>
  );
};

export default AreaDropDown;

import React from "react";
import { Spin as AntdSpin, SpinProps } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import styles from "./styles.module.css";

interface ISpinProps extends SpinProps {}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Spin = ({ indicator, className, ...props }: ISpinProps) => (
  <AntdSpin
    className={[styles.labelText, className].join(" ")}
    indicator={indicator}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
);

Spin.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  indicator: antIcon,
};

export default Spin;

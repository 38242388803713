import { create } from "zustand";

export type ModalType =
  | "createResponderCompany"
  | "editResponderCompany"
  | "createResponder"
  | "editResponder"
  | "responderBulkUpload"
  | "createControlRoomOperator"
  | "editControlRoomOperator"
  | "createResponderVehicle"
  | "editResponderVehicle";

interface ModalData {
  responderCompany?: IEditResponderCompany;
  activeResponderCompanyId?: number;
  editResponder?: IResponderDetails;
  editControlRoomOperator?: IControlRoomOperatorDetails;
  editResponderVehicle?: IResponderVehicleDetails;
}

interface ModalStore {
  type: ModalType | null;
  data: ModalData;
  isOpen: boolean;
  onOpen: (type: ModalType, data?: ModalData) => void;
  onClose: () => void;
}

export const useModal = create<ModalStore>((set) => ({
  type: null,
  data: {},
  isOpen: false,
  onOpen: (type, data = {}) => set({ isOpen: true, type, data }),
  onClose: () => set({ isOpen: false, type: null }),
}));

/* eslint-disable max-len */
import React from "react";
import Colors from "../../config/Colors";

export function CPFResponderIcon({
  height, // number type by default
  width,
  viewBox = "0 0 24 24",
  svgColor = `${Colors.appMainColor}`,
}: CustomSVGProp) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox || "0 0 24 24"}
    >
      <path
        fill={svgColor || "#888888"}
        d="m12 13l1.51 1.163q.13.106.252.013q.12-.093.07-.23l-.594-1.9l1.687-1.308q.125-.086.065-.22q-.06-.133-.196-.133h-1.963l-.639-1.964q-.05-.136-.192-.136t-.192.136l-.639 1.964H9.206q-.137 0-.2.133t.063.22l1.643 1.308l-.595 1.944q-.05.137.071.23t.252-.012L12 13Zm0 7.842q-.137 0-.287-.025t-.28-.075Q8.48 19.617 6.74 16.926Q5 14.235 5 11.1V6.817q0-.51.295-.923q.295-.413.755-.6l5.385-2q.292-.106.565-.106q.273 0 .565.106l5.385 2q.46.187.755.6q.295.414.295.923V11.1q0 3.135-1.74 5.826t-4.693 3.816q-.13.05-.28.075t-.287.025Z"
      />
    </svg>
  );
}

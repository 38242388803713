import React, { useCallback, useEffect, useState } from "react";
import { ResponderAnalyticsCard } from "@/components/Cards/ResponderAnalyticsCard";
import { tableBody, tableHeader, tableName } from "@/styles/TableStyles";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import ResponderCallOutDoughnut from "@/components/Charts/ResponderCharts/ResponderAnalyticsDoughnut";
import {
  useGetResponderCallOutsTable,
  useGetResponderCardData,
  useGetResponderIncidentPieGraph,
  useGetResponderMapData,
  useGetResponderTopCompanies,
} from "@/hooks/useResponderAnalytics";

import {
  GoogleMap,
  GoogleMapApiLoader,
  Marker,
} from "react-google-map-wrapper";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { ActionBodyText } from "@/styles/ContainerStyles";
import { Skeleton } from "primereact/skeleton";
import DateFilterDropDown from "@/components/AnalyticsCards/DateFilterDropDown";
import CompaniesDropDown from "@/components/AnalyticsCards/CompaniesDropDown";
import CallOutDropDown from "@/components/AnalyticsCards/CallOutDropDown";
import AreaDropDown from "@/components/AnalyticsCards/AreaDropDown";
import DispatchSourceDropDown from "@/components/AnalyticsCards/DispatchSourceDropDown";
import ResponderOperatorDropDown from "@/components/AnalyticsCards/ResponderOperatorDropDown";

const ResponderAnalytics = () => {
  const defaultValues: IResponderFilters = {
    startDate: undefined,
    endDate: undefined,
    callOutIds: [],
    companyIds: [],
    dispatchSource: [],
    operatorIds: [],
    areas: [],
  };
  const [filter, setFilter] = useState<IResponderFilters>(defaultValues);

  const {
    data: responderCard,
    isLoading: isLoadingCardResponderCard,
    isFetching: isFetchingCardResponderCard,
  } = useGetResponderCardData(filter);

  const {
    data: responderIncidentChart,
    isLoading: isLoadingResponderIncidentChart,
    isFetching: isFetchingResponderIncidentReport,
  } = useGetResponderIncidentPieGraph(filter);

  const {
    data: responderMaplocations,
    isLoading: isLoadingResponderMapLocations,
    isFetching: isFetchingResponderMapLocations,
  } = useGetResponderMapData(filter);

  const {
    data: responderTopCompanies,
    isLoading: isLoadingTopCompanies,
    isFetching: isFetchingTopCompanies,
  } = useGetResponderTopCompanies(filter);

  const {
    data: responderCallOutsTable,
    isLoading: isLoadingResponderCallOutsTable,
    isFetching: isFetchingResponderCallOutsTable,
  } = useGetResponderCallOutsTable(filter);

  const navigate = useHistory();

  const navigateTo = (id: string) => {
    navigate.push(`./responder-report/${id}`);
  };

  const naviageToAllResponders = (id: string) => {
    if (id === "0") {
      navigate.push(`/home/all-responders/`);
    } else {
      navigate.push(`/home/all-responders/${id}`);
    }
  };

  const dateBodyTemplate = (rowData: ICallOutsDTO) => {
    return moment(rowData.date).format("YYYY/MM/DD");
  };

  const timeOnSceneTemplate = (rowData: ICallOutsDTO) => {
    return moment(rowData.timeOnScene).format("HH:mm");
  };

  const viewMoreBodyTemplate = (rowData: ICallOutsDTO) => {
    return (
      <ActionBodyText
        onClick={() => {
          navigateTo(rowData.id.toString());
        }}
        className="cursor-pointer"
      >
        View More
      </ActionBodyText>
    );
  };

  const handleFilterChange = useCallback((name: string, value: any) => {
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  return (
    <div className="flex w-full h-full bg-backgroundColor">
      <div className="w-full h-full max-h-screen overflow-y-auto disable-scrollbars">
        <div className="w-full flex flex-row items-start disable-scrollbars justify-start px-0.5 overflow-x-auto pb-2 mb-2">
          <DateFilterDropDown onFilterChange={handleFilterChange} />
          <CompaniesDropDown onFilterChange={handleFilterChange} />
          <CallOutDropDown onFilterChange={handleFilterChange} />
          <AreaDropDown onFilterChange={handleFilterChange} />
          <DispatchSourceDropDown onFilterChange={handleFilterChange} />
          <ResponderOperatorDropDown onFilterChange={handleFilterChange} />
        </div>
        <div className="w-full px-1 mb-6">
          <div className="flex w-full">
            <div className="flex flex-row w-full gap-2 pb-2 overflow-auto disable-scrollbars">
              {isLoadingCardResponderCard || isFetchingCardResponderCard ? (
                <Skeleton width="20rem" height="8rem" />
              ) : (
                <div>
                  <ResponderAnalyticsCard
                    title={responderCard?.callOuts.callOuts as string}
                    number={responderCard?.callOuts.total as number}
                  />
                </div>
              )}
              {isLoadingCardResponderCard || isFetchingCardResponderCard ? (
                <Skeleton width="20rem" height="8rem" />
              ) : (
                <div>
                  <ResponderAnalyticsCard
                    title={
                      responderCard?.activeResponders?.activeResponder as string
                    }
                    number={responderCard?.activeResponders?.total as number}
                  />
                </div>
              )}

              {isLoadingCardResponderCard || isFetchingCardResponderCard ? (
                <Skeleton width="20rem" height="8rem" />
              ) : (
                <div>
                  <ResponderAnalyticsCard
                    title={responderCard?.averageETA?.averageETA as string}
                    number={responderCard?.averageETA?.total as number}
                  />
                </div>
              )}

              {isFetchingCardResponderCard || isLoadingCardResponderCard ? (
                <Skeleton width="20rem" height="8rem" />
              ) : (
                <div>
                  <ResponderAnalyticsCard
                    title={
                      responderCard?.averagePredictedETA
                        ?.averagePredictedETA as string
                    }
                    number={responderCard?.averagePredictedETA?.total as number}
                  />
                </div>
              )}

              {isFetchingCardResponderCard || isLoadingCardResponderCard ? (
                <Skeleton width="20rem" height="8rem" />
              ) : (
                <div>
                  <ResponderAnalyticsCard
                    title={
                      responderCard?.averageResponseTime
                        ?.averageResponseTime as string
                    }
                    number={responderCard?.averageResponseTime?.total as number}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="w-full px-1 mb-2">
          <div className="grid flex-row animate-fadeInUp animate-faster">
            <div className="col-12 md:col-6 animate-bounce animate-repeat-2">
              <div className="w-12 mt-3 h-96">
                {isLoadingResponderIncidentChart ||
                isFetchingResponderIncidentReport ? (
                  <Skeleton width="40rem" height="23rem" />
                ) : (
                  <ResponderCallOutDoughnut
                    doughnutAnalytics={
                      responderIncidentChart as IIncidentPieGraph
                    }
                  />
                )}
              </div>
            </div>
            <div className="col-12 md:col-6 animate-bounce animate-repeat-2">
              <div className="px-3 py-3 mb-2 h-96">
                {isLoadingResponderMapLocations ||
                isFetchingResponderMapLocations ? (
                  <Skeleton width="40rem" height="23rem" />
                ) : (
                  <GoogleMapApiLoader
                    apiKey={
                      process.env.REACT_APP_GOOGLE_MAPS_API_KEY
                        ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY
                        : ""
                    }
                  >
                    <GoogleMap
                      className="w-full h-full"
                      zoom={4.5}
                      initialCenter={{
                        lat: -26.204103,
                        lng: 28.047304,
                      }}
                      center={{
                        lat: -26.204103,
                        lng: 28.047304,
                      }}
                    >
                      {Array.isArray(responderMaplocations) &&
                        responderMaplocations?.map((marker: IMaplocations) => (
                          <Marker
                            lat={marker?.latitude as number}
                            lng={marker?.longitude as number}
                          />
                        ))}
                    </GoogleMap>
                  </GoogleMapApiLoader>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="w-full h-4/6 px-1 mt-5 mb-1.5">
          {isLoadingTopCompanies || isFetchingTopCompanies ? (
            <Skeleton height="25rem" width="100%" />
          ) : (
            <div className="px-3 py=3 card border-round shadow-3 m-0">
              <div className="flex flex-row w-full gap-1 mb-2 justify-content-between align-items-center">
                <div
                  className="flex flex-row align-items-center"
                  style={tableName}
                >
                  Top Responder
                </div>
                <div className="flex justify-end pr-1">
                  <button
                    className="text-lg underline text-appMainColor"
                    type="button"
                    onClick={() => naviageToAllResponders("0")}
                  >
                    View All Responders
                  </button>
                </div>
              </div>
              <DataTable
                paginator
                rows={10}
                value={
                  Array.isArray(responderTopCompanies)
                    ? responderTopCompanies
                    : []
                }
                rowsPerPageOptions={[10, 25, 50, 100]}
                dataKey="id"
                globalFilterFields={[]}
                emptyMessage="No call outs found."
                rowHover
                scrollable
                scrollHeight="40rem"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} operators"
                stateStorage="session"
                stateKey="dt-state-responder-Call-out-table"
                className="mx-0"
              >
                <Column
                  field="company"
                  header="Company"
                  headerStyle={tableHeader}
                  bodyStyle={tableBody}
                  style={{ background: "#FBFBFB" }}
                />
                <Column
                  field="completedCallouts"
                  header="Completed Callouts"
                  headerStyle={tableHeader}
                  bodyStyle={tableBody}
                  style={{ background: "#FBFBFB" }}
                />
                <Column
                  field="averageResponseTime"
                  header="Average Response Time"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                />
                <Column
                  field="averageETA"
                  header="Average ETA"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                />
                <Column
                  field="hoursOnline"
                  header="Hours Online (%)"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                />
              </DataTable>
            </div>
          )}
        </div>

        <div className="w-full h-full px-1">
          {isLoadingResponderCallOutsTable ||
          isFetchingResponderCallOutsTable ? (
            <Skeleton height="25rem" width="100%" />
          ) : (
            <div className="px-3 py-3 m-0 card border-round shadow-3">
              <div className="flex flex-row w-full gap-1 mb-2 justify-content-between align-items-center">
                <div
                  className="flex flex-row align-items-center"
                  style={tableName}
                >
                  Call-outs
                </div>
              </div>
              <DataTable
                paginator
                rows={10}
                value={
                  Array.isArray(responderCallOutsTable)
                    ? responderCallOutsTable
                    : []
                }
                rowsPerPageOptions={[10, 25, 50, 100]}
                dataKey="id"
                globalFilterFields={[]}
                emptyMessage="No call outs found."
                rowHover
                scrollable
                scrollHeight="40rem"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} operators"
                stateStorage="session"
                stateKey="dt-state-responder-Call-out-table"
                className="mx-0"
              >
                <Column
                  field="id"
                  header="ID"
                  headerStyle={tableHeader}
                  bodyStyle={tableBody}
                  style={{ background: "#FBFBFB" }}
                />
                <Column
                  field="date"
                  header="Date"
                  headerStyle={tableHeader}
                  bodyStyle={tableBody}
                  style={{ background: "#FBFBFB" }}
                  body={dateBodyTemplate}
                />
                <Column
                  field="responseTime"
                  header="Response Time"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                />
                <Column
                  field="eta"
                  header="ETA"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                />
                <Column
                  field="callOutDetails"
                  header="CallOut Details"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                />
                <Column
                  field="timeOnScene"
                  header="Time on scene"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                  body={timeOnSceneTemplate}
                />
                <Column
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                  body={viewMoreBodyTemplate}
                />
              </DataTable>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResponderAnalytics;

/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable  spaced-comment */
/* eslint-disable arrow-body-style */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-fragments */
/* eslint-disable  prefer-destructuring */

import React, { useState, useEffect, useReducer, useRef } from "react";
import { MoreOutlined, DownloadOutlined } from "@ant-design/icons";
import { Button as AButton, Modal, Rate, Tabs } from "antd";
import type { TabsProps } from "antd";
import moment from "moment";
import { Button } from "primereact/button";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { NavLink, useHistory } from "react-router-dom";
import { InputNumber } from "primereact/inputnumber";
import jsPDF from "jspdf";
import autoTable, { applyPlugin } from "jspdf-autotable";
import { OperatorsCard } from "../../../components/Cards/EmergencyOperators/OperatorsCard";
import { CustomIcons } from "../../../assets";
import Colors from "../../../config/Colors";
import { tableHeader, tableBody, tableName } from "../../../styles/TableStyles";
import { ActionBodyText } from "../../../styles/ContainerStyles";
import {
  modelTitle,
  successSubtitle,
  successTitle,
} from "../../../styles/ModalStyles";
import { useEditOperator } from "../../../hooks/useOperators";
import EditUserForm from "../../../components/Forms/EditUser";
import OperatorMaxCasesForm from "../../../components/Forms/OperatorMaxCases";

interface IAllEmergencyOperatorsProps {
  allUsers: IGeneralCommunity[];
  //onDelete: (id: string) => void
  //onEdit: (id: string) => void
}

const AllEmergencyOperators: React.FC<IAllEmergencyOperatorsProps> = ({
  allUsers,
}) => {
  // @ts-ignore
  const dt = useRef<DataTable>(null);
  const [isExporting, setIsExporting] = useState(false);

  const [openEditOperatorModal, setOpenEditOperatorModal] = useState(false);
  const [filters, setFilters] = useState<DataTableFilterMeta | undefined>(
    undefined
  );
  const [openDownloadButtonsModal, setOpenDownloadButtonsModal] =
    useState(false);
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");

  const [activeIndex, setActiveIndex] = useState("1");

  const navigate = useHistory();
  const [currentOperator, setCurrentOperator] =
    useState<IGeneralCommunity | null>(null);
  const convertDateToString = (date: string): string => {
    return moment(date).format("YYYY/MM/DD");
  };
  const navigateTo = (id: number | string) => {
    navigate.push(`/home/detailed-operator/${id}`);
  };

  // Hooks

  const editOperator = useEditOperator();

  const onTabChange = (key: string) => {
    setActiveIndex(key);
  };

  const cols = [
    { field: "fullName", header: "Full Name" },
    { field: "totalMessages", header: "Total Messages" },
    { field: "totalCases", header: "Total Cases" },
    { field: "resolvedCases", header: "Resolved Cases" },
    { field: "unresolvedCases", header: "Unresolved Cases" },
    { field: "transferredCases", header: "Transferred Cases" },
    { field: "averageResponseTime", header: "Average Response Time" },
    { field: "rating", header: "Rating by Stars" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const exportPdf = () => {
    if (allUsers) {
      const doc = new jsPDF();
      const rowInputData = allUsers.map((user) => ({
        fullName: `${user.fullName} `,
        totalMessages: user.totalMessages,
        totalCases: user.totalCases,
        resolvedCases: user.resolvedCases,
        unresolvedCases: user.unresolvedCases,
        transferredCases: user.transferredCases,
        averageResponseTime: `${formatTime(user.averageResponseTime)}`,
        rating: user.rating,
      }));
      autoTable(doc, { columns: exportColumns, body: rowInputData });
      doc.save("Help Desk.pdf");
    }
  };

  const exportCSV = (selectionOnly: boolean) => {
    setIsExporting(true);
    dt.current.exportCSV({ selectionOnly });
    setIsExporting(false);
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      fullName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      totalMessages: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      totalCases: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      resolvedCases: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      unresolvedCases: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      transferredCases: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      averageResponseTime: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      rating: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
    });
    setGlobalFilterValue("");
  };

  function formatTime(mins: number): string {
    if (mins < 60) {
      return `${Math.round(mins)} min`;
    }
    if (mins < 1440) {
      return `${Math.round(mins / 60)} hours`;
    }
    if (mins < 10080) {
      return `${Math.round(mins / 1440)} days`;
    }
    if (mins < 40320) {
      return `${Math.round(mins / 10080)} weeks`;
    }
    if (mins < 525600) {
      return `${Math.round(mins / 40320)} months`;
    }
    return `${Math.round(mins / 525600)} years`;
  }

  useEffect(() => {
    initFilters();
  }, []);

  const numericFilterTemplate = (options: any) => {
    return (
      <InputNumber
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
      />
    );
  };

  const dateBodyTemplate = (rowData: IEmergencyOperators) => {
    return (
      <p
        style={{
          color: "#212121",
          fontWeight: 400,
          fontSize: 14,
          fontStyle: "normal",
        }}
      >
        {convertDateToString(rowData.user.createdAt)}
      </p>
    );
  };

  const ratingBodyTemplate = (rowData: IGeneralCommunity) => {
    return (
      <Rate
        disabled
        defaultValue={rowData.rating}
        style={{ color: `${Colors.appMainColor}` }}
      />
    );
  };
  const viewMoreBodyTemplate = (rowData: IGeneralCommunity) => {
    const userid = rowData.userId;

    return (
      <ActionBodyText onClick={() => navigateTo(userid)}>
        View More
      </ActionBodyText>
    );
  };

  const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;

  const handleClose = async () => {
    setCurrentOperator(null);
    setOpenEditOperatorModal(false);
    editOperator.reset();
  };

  const actionBodyTemplate = (rowData: IGeneralCommunity) => {
    return (
      <div style={{ minWidth: "50px" }}>
        <AButton
          type="text"
          icon={
            <CustomIcons.PencilIcon
              width={20}
              height={20}
              viewBox={null}
              svgColor={Colors.appMainColor}
            />
          }
          style={{ color: `${Colors.appMainColor}` }}
          onClick={() => {
            setCurrentOperator(rowData);
            setOpenEditOperatorModal(true);
          }}
        />
      </div>
    );
  };

  const numberFilterTemplate = (options: any) => {
    return (
      <InputNumber
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
      />
    );
  };

  const averageResponseTemplate = (rowData: IGeneralCommunity) => {
    return formatTime(rowData.averageResponseTime);
  };

  const handleEditUser = async (user: IUser, subId: string | number | null) => {
    try {
      if (currentOperator) {
        currentOperator.user = user;
        await editOperator.mutateAsync(currentOperator);
      }

      //await refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Personal Details`,
      children: (
        <>
          <EditUserForm
            userValues={currentOperator?.user}
            onFormFinish={(user: IUser, subId: string | number | null) =>
              handleEditUser(user, subId)
            }
            onModelClose={handleClose}
            isLoading={editOperator.isLoading}
            isSuccess={editOperator.isSuccess}
            showValues={false}
          />
        </>
      ),
    },
    {
      key: "2",
      label: `Settings`,
      children: (
        <>
          <OperatorMaxCasesForm
            userId={currentOperator?.userId ?? null}
            onModelClose={handleClose}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <div className="w-full h-full py-1">
        <div className="pt-2">
          <div className="flex flex-row justify-end w-full h-full mb-1">
            <AButton
              icon={<DownloadOutlined />}
              onClick={() => setOpenDownloadButtonsModal(true)}
              loading={isExporting}
              type="primary"
              ghost
              size="large"
              className="w-1 h-1"
            />
          </div>
          {allUsers ? (
            <DataTable
              ref={dt}
              value={allUsers}
              paginator
              rows={5}
              rowsPerPageOptions={[5, 10, 25, 50]}
              sortMode="multiple"
              emptyMessage="No operators found."
              dataKey="user.id"
              filters={filters}
              globalFilterFields={[
                "fullName",
                "totalMessages",
                "totalCases",
                "resolvedCases",
                "unresolvedCases",
                "transferredCases",
                "averageResponseTime",
                "rating",
              ]}
              scrollable
              scrollHeight="40rem"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Operators"
              //selectionMode="single"
            >
              <Column
                field="fullName"
                header="Operators name"
                headerStyle={tableHeader}
                style={{ background: "#FBFBFB" }}
                bodyStyle={tableBody}
                filter
              />
              <Column
                field="totalMessages"
                header="Total Messages"
                headerStyle={tableHeader}
                style={{ background: "#FBFBFB" }}
                dataType="numeric"
                filterField="totalMessages"
                filter
                filterElement={numberFilterTemplate}
              />
              <Column
                field="totalCases"
                header="Total cases"
                headerStyle={tableHeader}
                style={{ background: "#FBFBFB" }}
                bodyStyle={tableBody}
                dataType="numeric"
                filterField="totalCases"
                filter
                filterElement={numberFilterTemplate}
              />
              <Column
                field="resolvedCases"
                header="Resolved cases"
                headerStyle={tableHeader}
                style={{ background: "#FBFBFB" }}
                bodyStyle={tableBody}
                dataType="numeric"
                filterField="resolvedCases"
                filter
                filterElement={numberFilterTemplate}
              />
              <Column
                field="unresolvedCases"
                header="Unresolved cases"
                headerStyle={tableHeader}
                style={{ background: "#FBFBFB" }}
                bodyStyle={tableBody}
                dataType="numeric"
                filterField="unresolvedCases"
                filter
                filterElement={numberFilterTemplate}
              />
              <Column
                field="transferredCases"
                header="Transferred cases"
                headerStyle={tableHeader}
                style={{ background: "#FBFBFB" }}
                bodyStyle={tableBody}
                dataType="numeric"
                filterField="transferredCases"
                filter
                filterElement={numberFilterTemplate}
              />
              <Column
                field="averageResponseTime"
                header="Average Response Time"
                headerStyle={tableHeader}
                bodyStyle={tableBody}
                style={{ background: "#FBFBFB" }}
                body={averageResponseTemplate}
              />
              <Column
                field="rating"
                header="Rating"
                headerStyle={tableHeader}
                style={{ background: "#FBFBFB" }}
                bodyStyle={tableBody}
                body={ratingBodyTemplate}
                dataType="numeric"
                filterField="transferredCases"
                filter
                filterElement={numberFilterTemplate}
              />
              <Column
                headerStyle={tableHeader}
                exportable={false}
                body={actionBodyTemplate}
                bodyStyle={tableBody}
                style={{ background: "#FBFBFB" }}
              />
              <Column
                field="viewMore"
                header=""
                headerStyle={tableHeader}
                style={{ background: "#FBFBFB" }}
                body={viewMoreBodyTemplate}
                // filter
                // filterElement={statusFilterTemplate}
                filterMenuStyle={{ width: "14rem" }}
              />
            </DataTable>
          ) : (
            <h1 className="text-center">No operator data found</h1>
          )}
        </div>

        <Modal
          centered
          open={openEditOperatorModal}
          onOk={() => setOpenEditOperatorModal(false)}
          onCancel={handleClose}
          width={550}
          closeIcon={
            <CustomIcons.CloseIcon
              width={30}
              height={30}
              viewBox={null}
              svgColor="#1C274C"
            />
          }
          footer={null}
        >
          <div className="w-full pt-2 mx-2">
            {editOperator.isSuccess ? (
              <div className="flex w-full h-full gap-2 flex-column align-items-center justify-content-center">
                <div className="flex w-full justify-content-center">
                  <CustomIcons.SuccessIcon
                    width={100}
                    height={100}
                    viewBox={null}
                    svgColor="#179717"
                  />
                </div>
                <div style={successTitle}> Successful</div>
                <div style={successSubtitle} className="pb-4">
                  {" "}
                  You have successfully edited a operator
                </div>
                <AButton
                  type="primary"
                  ghost
                  onClick={handleClose}
                  size="large"
                  className="w-4"
                >
                  close
                </AButton>
              </div>
            ) : (
              <>
                <div
                  className="flex w-12 py-3 justify-content-start"
                  style={modelTitle}
                >
                  Edit Operator
                </div>
                <Tabs
                  defaultActiveKey={activeIndex}
                  items={items}
                  onChange={onTabChange}
                  animated
                />
              </>
            )}
          </div>
        </Modal>
        <Modal
          centered
          open={openDownloadButtonsModal}
          onOk={() => setOpenDownloadButtonsModal(false)}
          onCancel={() => setOpenDownloadButtonsModal(false)}
          width={550}
          closeIcon={
            <CustomIcons.CloseIcon
              width={30}
              height={30}
              viewBox={null}
              svgColor="#1C274C"
            />
          }
          footer={null}
        >
          <div
            className="flex w-12 py-3 justify-content-start"
            style={modelTitle}
          >
            Download As
          </div>
          <AButton
            className="flex justify-center w-full px-2 mb-1 text-white bg-successColor"
            onClick={() => {
              exportCSV(false);
            }}
          >
            CSV
          </AButton>
          <AButton
            className="flex justify-center w-full px-2 mb-1 text-white bg-errorColor"
            onClick={() => exportPdf()}
          >
            PDF
          </AButton>
        </Modal>
      </div>
    </>
  );
};

export default AllEmergencyOperators;

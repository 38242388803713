/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from "react";
import { Button, Input, Modal, message } from "antd";
import { debounce } from "lodash";
import { toast } from "sonner";
import Colors from "@/config/Colors";
import { Container, BodyContainer } from "../../styles/ContainerStyles";
import HeaderComponent from "../../components/Header/Header";
import {
  AddBtnStyle,
  InformationContainer,
  InnerContainer,
  modelTitle,
  successSubtitle,
  successTitle,
} from "./styles";

import CompaniesCard from "../../components/Cards/CompaniesCard/CompaniesCard";
import { CustomIcons } from "../../assets";
import NewCompanyForm from "../../components/Forms/NewCompanyForm";
import {
  useAddCompany,
  useGetPaginatedCompanyCardsData,
} from "../../hooks/useCompanies";

const Companies = () => {
  const { Search } = Input;
  const addCompany = useAddCompany();
  const [nextPage, setNextPage] = useState<number>(1);
  const [searchCompany, setSearchCompany] = useState<string | null>("");
  const limit = 6;

  const [openNewCompanyModal, setOpenNewCompanyModal] = useState(false);

  const {
    isLoading,
    data: companyCard,
    isFetching,
    refetch,
  } = useGetPaginatedCompanyCardsData(nextPage, limit, searchCompany);

  const handleCloseSuccessModal = async () => {
    setOpenNewCompanyModal(false);
    addCompany.reset();
  };

  const handleClose = async () => {
    setOpenNewCompanyModal(false);
    addCompany.reset();
  };

  const handleAddCompany = async (company: IAddCompany) => {
    if (company.companyImage && company.companyName && company.pricePerMonth) {
      try {
        await addCompany.mutateAsync(company);
        refetch();
      } catch (error) {
        console.log(error);
      }
    } else {
      message.error("Please ensure all fields are filed correctly");
    }
  };

  const handleNextPage = () => {
    if (companyCard?.hasNextPage) {
      setNextPage(nextPage + 1);
      refetch();
    }
  };

  const handlePrevious = () => {
    if (companyCard?.hasPreviousPage) {
      setNextPage(nextPage - 1);
      refetch();
    }
  };

  const handleGoTo = debounce((pageNo: number) => {
    if (pageNo >= 1 && pageNo <= (companyCard?.totalPages as number)) {
      setNextPage(pageNo);
      refetch();
    } else {
      toast.error(
        "Please enter a valid page number within the range of 1 - " +
          (companyCard?.totalPages as number)
      );
    }
  }, 300);

  const handleRefresh = () => {
    setSearchCompany("");
    setNextPage(1);
    refetch();
  };

  const handleSearch = (value: string) => {
    if (value.length >= 3) {
      setSearchCompany(value);
      setNextPage(1);
      refetch();
    } else {
      toast.error("Please enter at least 3 characters to search");
    }
  };
  return (
    <div className="w-full h-full">
      <Container className="w-full h-full">
        <BodyContainer className="w-full h-full">
          <HeaderComponent title="Companies" />
          {isLoading || isFetching ? (
            <div className="flex h-full flex-column justify-content-center align-items-center">
              <CustomIcons.EOSThreeDotsLoading
                width={80}
                height={80}
                viewBox={null}
                svgColor={Colors.appMainColor}
              />
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                height: "100%",
                flex: 1,
              }}
            >
              <InformationContainer>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                    paddingRight: 20,
                    flexDirection: "row",
                  }}
                >
                  {searchCompany && searchCompany?.length > 0 && (
                    <button
                      type="button"
                      style={{ marginRight: 2 }}
                      onClick={handleRefresh}
                    >
                      <CustomIcons.CrossIcon
                        width={35}
                        height={35}
                        svgColor="#000"
                      />
                    </button>
                  )}

                  <Search
                    placeholder="Search for a Company"
                    allowClear
                    enterButton
                    size="large"
                    className="w-3"
                    onSearch={(value: string) => handleSearch(value)}
                  />
                </div>
                <InnerContainer>
                  <div className="card-container">
                    {Array.isArray(companyCard?.data) &&
                      companyCard?.data.map((card: ICompanyCard) => (
                        <CompaniesCard
                          key={card.companyId}
                          id={card.companyId}
                          title={card.companyName}
                          corporateAgreement={card.companyAgreement}
                          basicPlan={card.basicPlan}
                          familyofTwo={card.familyOfTwo}
                          familyofFour={card.familyOfFour}
                          familyofSix={card.familyOfSix}
                          pBasicPlan={card.pBasicPlan}
                          pFamilyofTwo={card.pFamilyOfTwo}
                          pFamilyofFour={card.pFamilyOfFour}
                          pFamilyofSix={card.pFamilyOfSix}
                          turnover={card.turnover}
                          referralCode={card.referralCode ?? ""}
                          communityCode={card.communityCode ?? ""}
                          total={card.pricePerMonth}
                          imgSrc={card.companyLogo}
                        />
                      ))}

                    <button
                      style={AddBtnStyle}
                      type="button"
                      onClick={() => {
                        setOpenNewCompanyModal(true);
                      }}
                    >
                      <p
                        style={{
                          color: `${Colors.appMainColor}`,
                          fontSize: 26,
                          fontWeight: "bold",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        +
                      </p>
                    </button>
                  </div>
                </InnerContainer>
              </InformationContainer>

              <div className="w-full flex flex-row pl-3 pr-3 justify-end items-center pb-2">
                <Button
                  type="primary"
                  ghost
                  size="large"
                  className="w-1"
                  disabled={!companyCard?.hasPreviousPage}
                  onClick={handlePrevious}
                >
                  Previous
                </Button>
                <div className="w-1/5 flex flex-row justify-content-center align-items-center gap-1">
                  <Input
                    size="middle"
                    style={{ width: 50, marginRight: 2, fontSize: "16px" }}
                    value={nextPage}
                    readOnly
                  />
                  <span style={{ fontSize: "17px" }}> Of </span>
                  <Input
                    size="middle"
                    style={{ width: 50, marginLeft: 2, fontSize: "16px" }}
                    value={
                      companyCard?.totalPages === 0
                        ? 1
                        : companyCard?.totalPages
                    }
                    readOnly
                  />
                  <span style={{ fontSize: "17px" }}> Go To </span>
                  <Input
                    size="middle"
                    style={{ width: 50, marginLeft: 2, fontSize: "16px" }}
                    onChange={(e) => handleGoTo(Number(e.target.value))}
                  />
                </div>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  size="large"
                  className="w-1 bg-[#1757a2] shadow-none justify-center"
                  disabled={!companyCard?.hasNextPage}
                  onClick={handleNextPage}
                >
                  Next
                </Button>
              </div>
            </div>
          )}
        </BodyContainer>
        <Modal
          centered
          open={openNewCompanyModal}
          onOk={() => setOpenNewCompanyModal(false)}
          onCancel={() => setOpenNewCompanyModal(false)}
          width={550}
          closeIcon={
            <CustomIcons.CloseIcon
              width={30}
              height={30}
              viewBox={null}
              svgColor="#1C274C"
            />
          }
          footer={null}
        >
          <div className="w-full pt-2 mx-2">
            {addCompany.isSuccess ? (
              <div className="flex w-full h-full gap-2 flex-column align-items-center justify-content-center">
                <div className="flex w-full justify-content-center">
                  <CustomIcons.SuccessIcon
                    width={100}
                    height={100}
                    viewBox={null}
                    svgColor="#179717"
                  />
                </div>
                <div style={successTitle}> Successful</div>
                <div style={successSubtitle} className="pb-4">
                  {" "}
                  You have successfully added a new company
                </div>
                <Button
                  type="primary"
                  ghost
                  onClick={handleCloseSuccessModal}
                  size="large"
                  className="w-4"
                >
                  close
                </Button>
              </div>
            ) : (
              <>
                <div
                  className="flex w-12 py-3 justify-content-start"
                  style={modelTitle}
                >
                  Add a new Company
                </div>

                <NewCompanyForm
                  onFormFinish={async (company: IAddCompany) =>
                    handleAddCompany(company)
                  }
                  onModalClose={() => {
                    handleClose();
                  }}
                  isLoading={addCompany.isLoading}
                  isSuccess={addCompany.isSuccess}
                />
              </>
            )}
          </div>
        </Modal>
      </Container>
    </div>
  );
};

export default Companies;

import { createQueryKeys } from "@lukemorales/query-key-factory";
import ControlRoomOperator from "../ControlRoomOperator";

export const controlRoomOperators = createQueryKeys("controlRoomOperators", {
  all: null,
  getControlRoomOperators: (pageNumber: number, pageSize: number) => ({
    queryKey: [{ pageNumber, pageSize }],
    queryFn: () =>
      ControlRoomOperator.getControlRoomOperators(pageNumber, pageSize),
  }),
});

import { Button, DatePicker, Form, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useGetCompanies } from "../../hooks/useCompanies";

type userCompanyRenewalProp = {
  onFormFinish: (user: IUserCompanyRenewal) => void;
  onModalClose: () => void;
  isLoading: boolean;
  isSuccess: boolean;
};

const RenewUserCompany = ({
  onFormFinish,
  onModalClose,
  isLoading,
  isSuccess,
}: userCompanyRenewalProp) => {
  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();
  const [companyId, setCompanyId] = useState<string>();
  const [selectedSubPlan, setSelectedSubPlan] = useState<Number>();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();

  const handleCompanyChange = (value: string) => {
    setCompanyId(value);
  };

  const handleSubChange = (value: any) => {
    setSelectedSubPlan(value);
  };
  const { data } = useGetCompanies();

  const handleDateChange = (dates: any, dateString: [string, string]) => {
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
  };

  const handleClose = () => {
    onModalClose();
    form.resetFields();
  };

  const onFinish = async (formValues: IUserCompanyRenewal) => {
    const values: IUserCompanyRenewal = formValues as IUserCompanyRenewal;
    values.companyId = Number(companyId);
    values.validFrom = startDate as string;
    values.validTo = endDate as string;

    await onFormFinish(values);
  };

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess]);

  return (
    <div>
      <Form
        name="renewUserCompanySubscription"
        layout="vertical"
        onFinish={onFinish}
      >
        <div className="col-12">
          <Form.Item
            name="companyId"
            label="Company"
            className="w-full mb-3"
            rules={[{ required: true, message: "Please select a company" }]}
          >
            <Select placeholder="-" size="large" onChange={handleCompanyChange}>
              {data?.map((item: IActiveCompanies) => (
                <Select.Option
                  key={`key-companies-${item.companyId}`}
                  value={item.companyId}
                >
                  {item.companyName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div className="col-12">
          <Form.Item
            name={"subTypeId"}
            label="Select Subscription"
            rules={[
              { required: true, message: "Please select a subscription" },
            ]}
            className="mb-1"
          >
            <Select
              onChange={handleSubChange}
              placeholder="Subscription Plan"
              size="large"
              style={{ width: 485 }}
              options={[
                { value: 2, label: "Basic Plan" },
                { value: 8, label: "Family of Two" },
                { value: 3, label: "Family of Four" },
                { value: 4, label: "Family of Six" },
                { value: 5, label: "Premium" },
                { value: 9, label: "Family of Two Premium " },
                {
                  value: 6,
                  label: "Family of Four Premium",
                },
                {
                  value: 7,
                  label: "Family of Six Premium",
                },
              ]}
            />
          </Form.Item>
        </div>
        <div className="col-12">
          <Space
            direction="vertical"
            size={12}
            className="flex flex-row align-items-centers mt-2"
          >
            <Form.Item
              name={"dateRange"}
              label="Select Contract Period"
              rules={[
                { required: true, message: "Please select contract period" },
              ]}
              className="mb-1"
            >
              <RangePicker onChange={handleDateChange} />
            </Form.Item>
          </Space>
        </div>
        <Form.Item className="w-12 pt-2 mb-1">
          <div className="flex w-full gap-2 col-12 justify-content-end align-content-center">
            <Button
              onClick={handleClose}
              type="primary"
              ghost
              size="large"
              className="w-3"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="w-3 bg-[#1757a2] shadow-none"
              loading={isLoading}
            >
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RenewUserCompany;

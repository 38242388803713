/* eslint-disable no-nested-ternary */
/* eslint-disable comma-dangle */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-alert */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React from "react";
import { Select } from "antd";
import { VehicleStatus } from "@/util/enums";
import { VehicleStatusLabels } from "@/util/helpers";

// Type for dropdown options
interface DropdownOption {
  label: string;
  value: VehicleStatus;
}

interface VehicleStatusDropdownProps {
  selectedStatus: VehicleStatus | null;
  setSelectedStatus: (status: VehicleStatus) => void;
}

const VehicleStatusDropdown: React.FC<VehicleStatusDropdownProps> = ({
  selectedStatus,
  setSelectedStatus,
}) => {
  const statuses: DropdownOption[] = Object.values(VehicleStatus)
    .filter((value) => typeof value === "number")
    .map((value) => ({
      label: VehicleStatusLabels[value as VehicleStatus],
      value: value as VehicleStatus,
    }));

  const handleChange = (value: VehicleStatus) => {
    setSelectedStatus(value);
    console.log(`Selected status: ${value}`);
  };

  return (
    <Select
      defaultValue={selectedStatus}
      className="w-full items-start"
      onChange={handleChange}
      placeholder={selectedStatus !== null ? VehicleStatusLabels[selectedStatus] : "Change Vehicle Status"}
      options={statuses}
    />
  );
};

export default VehicleStatusDropdown;

/* eslint-disable no-useless-catch */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
import { API, APILocal, APIStaging } from "../config/axios";

class Crimes {
  getMapData = async (): Promise<ICrimeAreaCount[]> => {
    const response = await API.get("crimeReport/mapData");
    return response.data;
  };

  getDashboardData = async (): Promise<ICrimeDashboard> => {
    const response = await API.get("crimeReport/dashboard");
    return response.data;
  };

  getTopCrimesByArea = async (
    area: string | null | undefined
  ): Promise<ITopCrime[]> => {
    const response = await API.post(`crimeReport/topCrimesByArea`, area, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  };

  getReportedCrime = async (): Promise<IReportedCrimes[]> => {
    const response = await API.get("Admin_ReportedCrime/getReportedCrime");
    return response.data;
  };

  async getReportedCrimeDetails(
    id: string | number | null | undefined
  ): Promise<IReportedCrimesDetails> {
    if (id) {
      const response = await API.get(
        `/Admin_ReportedCrime/getReportedCrimeDetails?id=${id}`
      );
      return response.data;
    }
    return Promise.reject<IReportedCrimesDetails>("Crime Id is not provided");
  }

  async getPostedReportedCrime(): Promise<ITabItemReportedCrime[]> {
    const response = await API.get(
      `/Admin_ReportedCrime/getPostedReportedCrime`
    );

    return response.data;
  }

  async getDraftsReportedCrime(): Promise<ITabItemReportedCrime[]> {
    const response = await API.get(
      `/Admin_ReportedCrime/getDraftsReportedCrime`
    );

    return response.data;
  }

  async getReviewReportedCrime(): Promise<ITabItemReportedCrime[]> {
    const response = await API.get(
      `/Admin_ReportedCrime/getReviewReportedCrime`
    );

    return response.data;
  }

  getTotalReview = async (): Promise<ITotalReview> => {
    const response = await API.get("Admin_ReportedCrime/GetTotalReview");
    return response.data;
  };

  async getReviewCategory(category: string): Promise<ITabItemCategory[]> {
    if (category) {
      const response = await API.get(
        `/Admin_ReportedCrime/getReviewCategory?category=${category}`
      );
      return response.data;
    }
    return Promise.reject<ITabItemCategory[]>("");
  }

  async getPostedCategory(category: string): Promise<ITabItemCategory[]> {
    if (category) {
      const response = await API.get(
        `/Admin_ReportedCrime/getPostedCategory?category=${category}`
      );
      return response.data;
    }
    return Promise.reject<ITabItemCategory[]>("");
  }

  async getDraftedCategory(category: string): Promise<ITabItemCategory[]> {
    if (category) {
      const response = await API.get(
        `/Admin_ReportedCrime/getDraftedCategory?category=${category}`
      );
      return response.data;
    }
    return Promise.reject<ITabItemCategory[]>("");
  }

  verifyCrime = async (crimeId: number) => {
    const response = await API.post(
      `Admin_ReportedCrime/VerifyCrime/${crimeId}`
    );
    return response;
  };

  draftCrime = async (
    reasonBody: IDraftReportParams
  ): Promise<IDraftReportParams> => {
    try {
      const response = await API.post(
        `Admin_ReportedCrime/DraftCrime/${reasonBody.id}`,
        reasonBody
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  editCrimeTypeCategory = async (
    crimeTypeCategory: IEditCrimeTypeCategory
  ): Promise<IEditCrimeTypeCategory> => {
    try {
      const response = await API.put(
        `Admin_ReportedCrime/EditCrimeTypeCategory/${crimeTypeCategory.id}`,
        crimeTypeCategory
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  fetchCrimeCategories = async (): Promise<ICrimeApiResponse> => {
    try {
      const response = await API.get<ICrimeCategory[]>(
        `CrimeReport/categories`
      );

      return { data: response.data };
    } catch (error: any) {
      return {
        error:
          error.response?.statusText ||
          error.message ||
          "An unknown error occurred",
      };
    }
  };

  submitCrimeCategoryAndType = async (postData: INewCateGoryData): Promise<ISubmitCrimeResponse> => {
    try {
      const response = await API.post(
        `CrimeReport/add-crime-type`,
        postData
      );

      return {
        data: response?.data
      };
    } catch (error: any) {
      return {
        error:
          error.response?.statusText ||
          error.message ||
          "An unknown error occurred",
      };
    }
  };

  editCrimeVehicleStatus = async (postData: IEditCrimeVehicleStatus): Promise<ISubmitCrimeResponse> => {
    try {
      const response = await API.post(
        `CrimeReport/edit-vehicle-status`,
        postData
      );

      return {
        data: response?.data
      };
    } catch (error: any) {
      return {
        error:
          error.response?.statusText ||
          error.message ||
          "An unknown error occurred",
      };
    }
  };
}

export default new Crimes();

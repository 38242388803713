import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import Colors from "../../../config/Colors";

ChartJS.register(ArcElement, Tooltip, Legend);

interface IAverageRatingDoughnutProps {
  operators: IAverageOperatorRatingsRange;
}

export default function AverageRatingDoughnut({
  operators,
}: IAverageRatingDoughnutProps) {
  const data = {
    labels: ["0 Stars", "1 Star", "2 Stars", "3 Stars", "4 Stars", "5 Stars"],
    datasets: [
      {
        label: "# of operators",
        data: [
          operators[0],
          operators[1],
          operators[2],
          operators[3],
          operators[4],
          operators[5],
        ],
        backgroundColor: [
          "#363840",
          `#CC3300`,
          `#F79009`,
          `#339900`,
          `#99C7EB`,
          `#1757A2`,
        ],
        borderColor: [
          "#363840",
          `#CC3300`,
          `#F79009`,
          `#339900`,
          `#99C7EB`,
          `#1757A2`,
        ],
        borderWidth: 1,
        hoverBorderWidth: 20,
      },
    ],
  };
  return (
    <div
      style={{ background: "#FFFFFF" }}
      className="shadow-5 surface-card border-round px-3"
    >
      <div className="w-12 flex flex-row justify-between items-center">
        <div
          className="pt-3"
          style={{
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "18px",
            lineHeight: "27px",
            color: `${Colors.mainTextColor}`,
          }}
        >
          Average operator rating
        </div>
      </div>
      <div className="pb-2">
        <Doughnut
          data={data}
          options={{
            responsive: true,
            aspectRatio: 2,
            maintainAspectRatio: true,
            plugins: {
              legend: {
                display: true,
                position: "right" as const,
                align: "center",
                labels: {
                  usePointStyle: true,
                  padding: 30,
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
}

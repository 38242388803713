/* eslint-disable max-len */
import React from "react";
import Colors from "../../config/Colors";

export function Headphones({
  height, // number type by default
  width,
  viewBox = "0 0 24 24",
  svgColor = `${Colors.secondaryColor}`,
}: CustomSVGProp) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox={viewBox || "0 0 24 24"}
      fill="none"
    >
      <path
        d="M4 17.584V19C4 21.209 5.791 23 8 23H12"
        stroke={svgColor}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M4 8.406V8C4 4.134 7.134 1 11 1H13C16.866 1 20 4.134 20 8V8.422"
        stroke={svgColor}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M7 8.101C6.677 8.035 6.343 8 6 8C3.239 8 1 10.239 1 13C1 15.761 3.239 18 6 18C6.343 18 6.677 17.965 7 17.899V8.101Z"
        stroke={svgColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M17 17.899C17.323 17.965 17.657 18 18 18C20.761 18 23 15.761 23 13C23 10.239 20.761 8 18 8C17.657 8 17.323 8.035 17 8.101V17.899Z"
        stroke={svgColor}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
}
export default Headphones;

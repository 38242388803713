import React from "react";
import HeaderComponent from "../../components/Header/Header";
import CommunityFeedCard from "../../components/Cards/CommunityCard/CommunityFeedCard";
import {
  useDeletePost,
  useGetReportedPostDetails,
} from "../../hooks/useCommunities";
import { useHistory, useParams } from "react-router-dom";
import { Button, message } from "antd";
import { SubHeading } from "../Home/styles";

type reportedPostParams = {
  postId: string;
};

const DeleteReportedPost = () => {
  const { postId } = useParams<reportedPostParams>();
  const { data } = useGetReportedPostDetails(parseInt(postId));
  const deletePost = useDeletePost();
  const navigate = useHistory();
  const destroyMessage = () => {
    message.destroy();
  };

  let postDetails: ICommunityFeed = {
    id: data?.id ?? 0,
    title: data?.title ?? "",
    content: data?.content ?? "",
    createdAt: data?.createdAt ?? new Date(Date.now().toString()),
    updatedAt: data?.updatedAt ?? new Date(Date.now().toString()),
    userId: data?.userId ?? 0,
    firstName: data?.firstName ?? "",
    lastName: data?.lastName ?? "",
    profilePicture: data?.profilePicture ?? null,
    isCommentAble: data?.isCommentAble ?? false,
    isFromAdmin: data?.isFromAdmin ?? false,
    status: data?.status ?? "",
    type: data?.type ?? "",
    commentsAmount: data?.commentsAmount ?? 0,
    reactions: data?.reactions ?? [],
    tags: data?.tags ?? [],
    mediaPath: data?.mediaPath ?? [],
    likeAmount: data?.likeAmount ?? 0,
    reportedCategory: data?.reportedCategory ?? [],
    reportedCount: data?.reportedCount ?? 0,
  };

  const handleDeletePost = async () => {
    if (postId) {
      try {
        message.loading("Deleting post...");
        await deletePost.mutateAsync(parseInt(postId));
        message.success("Post deleted successfully.");
        setTimeout(() => {
          destroyMessage();
        }, 2000);
        navigate.goBack();
      } catch (error) {
        destroyMessage();
      }
    } else {
      message.error("Post not found");
    }
  };

  return (
    <div className="flex w-full h-full bg-backgroundColor">
      <div className="w-full h-full max-h-screen overflow-y-auto disable-scrollbars">
        <HeaderComponent title="Delete Reported Post" />
        <div className="flex flex-col w-full h-full px-3">
          <SubHeading className="flex justify-start">Reported Post</SubHeading>
          <p className="flex justify-start">
            {" "}
            Post was reported as being spam and misleading
          </p>
          <p className="flex justify-start">
            Reported as: (
            <span className="text-errorColor">{postDetails.reportedCount}</span>
            ) spam
          </p>
          <div className="flex gap-4 pb-4">
            <div className="flex-1 h-2/4 w-4/5">
              <CommunityFeedCard card={postDetails} />
            </div>
            <div className="flex-none flex justify-end items-end">
              <div className="flex flex-row">
                <Button
                  type="primary"
                  ghost
                  size="large"
                  className="bg-[#1757a2] shadow-none mr-2 "
                  onClick={() => navigate.goBack()}
                >
                  Cancel
                </Button>

                <Button
                  type="primary"
                  size="large"
                  className="bg-[#1757a2] shadow-none "
                  onClick={() => handleDeletePost()}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteReportedPost;

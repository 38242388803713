import React, { useState } from "react";
import CommunityCard from "../../components/Cards/CommunityCard/CommunityCard";
import { BodyContainer, Container } from "../../styles/ContainerStyles";
import HeaderComponent from "../../components/Header/Header";
import {
  useCommunities,
  useGetPaginatedCommunitiesCardsData,
} from "../../hooks/useCommunities";
import { CustomIcons } from "../../assets/index";
import Colors from "../../config/Colors";
import { AddBtnStyle } from "../Emergencies/styles";
import NewCommunityModal from "../../components/Modals/NewCommunityModal";
import EditCommunityModal from "../../components/Modals/EditCommunityModal";
import CommunitySettingsModal from "../../components/Modals/CommunitySettings";
import { COMMUNITY_ROLES } from "../../util/enums";
import { Button, Input } from "antd";
import { toast } from "sonner";

const AllCommunities = () => {
  // const { isLoading, data: communities, isFetching } = useCommunities();
  const [currentCommunity, setCurrentCommunity] = useState<
    ICommunity | undefined
  >(undefined);
  const [currentId, setCurrentId] = useState<number | null>(null);

  const [openNewModal, setOpenNewModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const { Search } = Input;
  const [nextPage, setNextPage] = useState<number>(1);
  const [searchCommunity, setSearchCommunity] = useState<string | null>("");
  const limit = 16;

  const {
    isLoading,
    data: communityCard,
    isFetching,
    refetch,
  } = useGetPaginatedCommunitiesCardsData(nextPage, limit, searchCommunity);

  const handleNextPage = () => {
    if (communityCard?.hasNextPage) {
      setNextPage(nextPage + 1);
      refetch();
    }
  };

  const handlePrevious = () => {
    if (communityCard?.hasPreviousPage) {
      setNextPage(nextPage - 1);
      refetch();
    }
  };

  const handleRefresh = () => {
    setSearchCommunity("");
    setNextPage(1);
    refetch();
  };

  const handleSearch = (value: string) => {
    if (value.length >= 3) {
      setNextPage(1);
      setSearchCommunity(value);
      refetch();
    } else {
      toast.error("Please enter at least 3 characters to search");
    }
  };

  return (
    <div className="w-full h-full">
      <Container className="w-full h-full">
        <BodyContainer className="w-full h-full">
          <HeaderComponent title="Communities" />
          {isLoading ? (
            <div className="fixed inset-0 flex items-center justify-center">
              <CustomIcons.EOSThreeDotsLoading
                width={80}
                height={80}
                viewBox={null}
                svgColor={Colors.appMainColor}
              />
            </div>
          ) : (
            <div className="mx-4">
              <div className="grid grid-cols-4 gap-4">
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                    paddingRight: 10,
                    flexDirection: "row",
                  }}
                >
                  {searchCommunity && searchCommunity.length > 0 && (
                    <button
                      type="button"
                      style={{ marginRight: 2 }}
                      onClick={handleRefresh}
                    >
                      <CustomIcons.CrossIcon
                        width={35}
                        height={35}
                        svgColor="#000"
                      />
                    </button>
                  )}

                  <Search
                    placeholder="Search for a Community"
                    allowClear
                    enterButton
                    size="large"
                    className="w-3"
                    onSearch={(value: string) => handleSearch(value)}
                  />
                </div>
                {communityCard &&
                  Array.isArray(communityCard.data) &&
                  communityCard?.data?.map((card: ICommunity) => (
                    <CommunityCard
                      key={card.id}
                      title={card.communityName}
                      subtitle={card.communityDescription}
                      imgSrc={card.communityIcon}
                      editCard={() => {
                        setCurrentCommunity(card);
                        setOpenEditModal(true);
                      }}
                      openSettings={() => {
                        setCurrentId(card.id);
                        setOpenSettingsModal(true);
                      }}
                    />
                  ))}

                <button
                  style={AddBtnStyle}
                  type="button"
                  onClick={() => {
                    setOpenNewModal(true);
                  }}
                >
                  <p
                    style={{
                      color: `${Colors.appMainColor}`,
                      fontSize: 26,
                      fontWeight: "bold",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    +
                  </p>
                </button>
                <div
                  style={{
                    bottom: 0,
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                >
                  <Button
                    type="primary"
                    ghost
                    size="large"
                    className="w-3"
                    disabled={!communityCard?.hasPreviousPage}
                    onClick={handlePrevious}
                  >
                    Previous
                  </Button>
                  <div
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "center",
                      paddingBottom: 10,
                    }}
                  >
                    <Input
                      size="middle"
                      style={{ width: 50, marginRight: 2, fontSize: "16px" }}
                      value={nextPage}
                      readOnly
                    />
                    <span style={{ fontSize: "26px" }}>/</span>
                    <Input
                      size="middle"
                      style={{ width: 50, marginLeft: 2, fontSize: "16px" }}
                      value={communityCard?.totalPages}
                      readOnly
                    />
                  </div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    className="w-3 bg-[#1757a2] shadow-none"
                    disabled={!communityCard?.hasNextPage}
                    onClick={handleNextPage}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
          )}
        </BodyContainer>
      </Container>
      <NewCommunityModal
        openNewModal={openNewModal}
        setOpenNewModal={() => {
          setOpenNewModal(false);
        }}
      />
      <EditCommunityModal
        values={currentCommunity}
        openEditModal={openEditModal}
        setOpenEditModal={() => {
          setCurrentCommunity(undefined);
          setOpenEditModal(false);
        }}
      />
      <CommunitySettingsModal
        communityId={currentId}
        openSettingsModal={openSettingsModal}
        setOpenSettingsModal={() => {
          setCurrentId(null);
          setOpenSettingsModal(false);
        }}
      />
    </div>
  );
};

export default AllCommunities;

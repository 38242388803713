import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { communityCodes } from "../services/queries/communityCodeQueries";
/* eslint-disable no-underscore-dangle */
import { queries } from "../services/queries";
import { CommunityCode } from "../services";
import { users } from "../services/queries/usersQueries";
import { get } from "lodash";

export function useAllCommunityCodes(): UseQueryResult<IAllCommunityCode[]> {
  return useQuery({
    ...queries.communityCodes.all,
    queryFn: () => CommunityCode.getAllCommunityCodes(),
    refetchOnWindowFocus: false,
  });
}

export function useGetPrimaryCategory(): UseQueryResult<
  IAffiliationPrimaryCategory[]
> {
  return useQuery({
    ...queries.communityCodes.getPrimaryCategory,
    queryFn: () => CommunityCode.getPrimaryCategory(),
    refetchOnWindowFocus: false,
  });
}

export function useGetSecondaryCategory(): UseQueryResult<
  IAffiliationSecondaryCategory[]
> {
  return useQuery({
    ...queries.communityCodes.getSecondaryCategory,
    queryFn: () => CommunityCode.getSecondaryCategory(),
    refetchOnWindowFocus: false,
  });
}

export function useGetTertiaryCategory(): UseQueryResult<
  IAffiliationTertiaryCategory[]
> {
  return useQuery({
    ...queries.communityCodes.getTertiaryCategory,
    queryFn: () => CommunityCode.getTertiaryCategory(),
    refetchOnWindowFocus: false,
  });
}

export function useGetPaginatedCommmunityCode(
  first: number = 0,
  rows: number = 10,
  filters: AffiliationFilters = {}
): UseQueryResult<ICommunityCodeResponse> {
  return useQuery({
    ...queries.communityCodes.getPaginatedCommunity(first, rows, filters),
    refetchOnWindowFocus: false,
  });
}

export function useGetAllCommunityCodeByCode(
  code: string
): UseQueryResult<IAllCommunityCode> {
  return useQuery({
    ...queries.communityCodes.getAllCommunityCodeByCode(code),
    refetchOnWindowFocus: false,
    enabled: code.length > 0,
  });
}

export function useGetPaginatedAffiliationCode(
  first: number = 0,
  rows: number = 10,
  filters: AffiliationFilters = {}
): UseQueryResult<IAffiliationCodeResponse> {
  return useQuery({
    ...queries.communityCodes.getPaginatedAffiliation(first, rows, filters),
    refetchOnWindowFocus: false,
  });
}

export function useUnusedAllCommunityCodes(): UseQueryResult<
  IAllCommunityCode[]
> {
  return useQuery({
    ...queries.communityCodes.getUnusedAllCommunityCodes,
    queryFn: () => CommunityCode.getUnusedAllCommunityCodes(),
    refetchOnWindowFocus: false,
  });
}

export function useGetCountries(): UseQueryResult<ICountry[]> {
  return useQuery({
    ...queries.communityCodes.getCountries,
    queryFn: () => CommunityCode.getCountries(),
    refetchOnWindowFocus: false,
  });
}

export function useGetProvinces(
  countryId: number | null
): UseQueryResult<IProvince[]> {
  return useQuery({
    ...queries.communityCodes.getProvinces,
    queryFn: () => CommunityCode.getProvinces(countryId),
    refetchOnWindowFocus: false,
  });
}

export function useGetProvincesByCountryName(
  countryName: string
): UseQueryResult<IProvince[]> {
  return useQuery({
    ...queries.communityCodes.getProvincesByCountryName,
    queryFn: () => CommunityCode.getProvincesByCountryName(countryName),
    refetchOnWindowFocus: false,
    enabled: countryName.length > 0,
  });
}

export function useUnlinkedCommunityCodes(): UseQueryResult<
  IAllCommunityCode[]
> {
  return useQuery({
    ...queries.communityCodes.getUnlinkedCommunityCodes,
    queryFn: () => CommunityCode.getUnlinkedCommunityCodes(),
  });
}

export function useUsersWithReferral(): UseQueryResult<IReferralUser[]> {
  return useQuery({
    ...queries.communityCodes.usersWithReferral,
    queryFn: () => CommunityCode.getUserWithReferral(),
    refetchOnWindowFocus: false,
  });
}

export function useReferralUsersList(filter: Filters) {
  return useQuery({
    ...queries.communityCodes.list(filter),
    refetchOnWindowFocus: false,
  });
}

export function useSearchReferralUsers(
  filters: Filters,
  query: string,
  limit = 15
) {
  return useQuery({
    ...queries.communityCodes.list(filters)._ctx.search(query, limit),
    enabled: Boolean(query),
    refetchOnWindowFocus: false,
  });
}
export function usePostCommunityCode() {
  const queryClient = useQueryClient();

  return useMutation(CommunityCode.addCommunityCode, {
    onSuccess: (editUser, variables) => {
      queryClient.invalidateQueries({
        queryKey: queries.communityCodes.all.queryKey,
      });
    },
    meta: {
      conflict: "Code Already Exists",
      badRequest: "Please make sure all fields are entered correctly.",
    },
  });
}

export function useUpdateCommunity() {
  const queryClient = useQueryClient();

  return useMutation(CommunityCode.updateCommunityCode, {
    onSuccess: (editUser, variables) => {
      queryClient.invalidateQueries({
        queryKey: queries.communityCodes.all.queryKey,
      });
    },
    meta: {
      conflict: "Code Already Exists",
      badRequest: "Please make sure all fields are entered correctly.",
    },
  });
}

export function useUsersWithAffiliaition(): UseQueryResult<IReferralUser[]> {
  return useQuery({
    ...queries.communityCodes.usersWithAffiliation,
    queryFn: () => CommunityCode.getUserWithAffiliation(),
    refetchOnWindowFocus: false,
  });
}

export function usePostAffiliationCode() {
  const queryClient = useQueryClient();

  return useMutation(CommunityCode.addAffiliationCode, {
    onSuccess: (editUser, variables) => {
      queryClient.invalidateQueries({
        queryKey: queries.communityCodes.all.queryKey,
      });
    },
    meta: {
      conflict: "Code Already Exists",
      badRequest: "Please make sure all fields are entered correctly.",
    },
  });
}

export function useUpdateAffiliationCode() {
  const queryClient = useQueryClient();

  return useMutation(CommunityCode.updateAffiliationCode, {
    onSuccess: (editUser, variables) => {
      queryClient.invalidateQueries({
        queryKey: queries.communityCodes.all.queryKey,
      });
    },
    meta: {
      conflict: "Code Already Exists",
      badRequest: "Please make sure all fields are entered correctly.",
    },
  });
}

export function useAllAffiliationCodes(): UseQueryResult<
  IAllAffiliationCode[]
> {
  return useQuery({
    ...queries.communityCodes.allAffilition,
    queryFn: () => CommunityCode.getAllAffiliationCodes(),
    refetchOnWindowFocus: false,
  });
}

export function useUnusedAllReferralCode(): UseQueryResult<
  IAllAffiliationCode[]
> {
  return useQuery({
    ...queries.communityCodes.getUnusedAllAffiliationCodes,
    queryFn: () => CommunityCode.getUnusedAllAffiliationCodes(),
    refetchOnWindowFocus: false,
  });
}

export function useGetAffiliationCodeByCode(
  code: string
): UseQueryResult<IAffiliationCode> {
  return useQuery({
    ...queries.communityCodes.getAffiliationCodeByCode,
    queryFn: () => CommunityCode.getAffiliationCodeByCode(code),
    refetchOnWindowFocus: false,
    enabled: code != null,
  });
}

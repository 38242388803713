import { createQueryKeys } from "@lukemorales/query-key-factory";
import CommunityCodes from "../CommunityCodes";
import { get } from "lodash";

export const communityCodes = createQueryKeys("communityCodes", {
  all: null,
  allAffilition: null,
  usersWithReferral: null,
  usersWithAffiliation: null,
  getUnusedAllCommunityCodes: null,
  getUnusedAllAffiliationCodes: null,
  getUnlinkedCommunityCodes: null,
  getCountries: null,
  getPrimaryCategory: null,
  getSecondaryCategory: null,
  getTertiaryCategory: null,

  list: (filter: Filters) => ({
    queryKey: [filter],
    queryFn: (ctx) =>
      CommunityCodes.getReferralUsersList(filter, ctx.pageParam),
    contextQueries: {
      search: (query: string, limit: number) => ({
        queryKey: [{ query, limit }],
        queryFn: (ctx) =>
          CommunityCodes.getSearchReferralUsers(
            filter,
            ctx.pageParam,
            limit,
            query
          ),
      }),
    },
  }),
  getProvinces: (countryId: number) => ({
    queryKey: [{ countryId }],
    queryFn: () => CommunityCodes.getProvinces(countryId),
  }),
  getProvincesByCountryName: (countryName: string) => ({
    queryKey: [{ countryName }],
    queryFn: () => CommunityCodes.getProvincesByCountryName(countryName),
  }),
  getPaginatedCommunity: (
    first: number,
    rows: number,
    filters: AffiliationFilters
  ) => ({
    queryKey: [{ first, rows, filters }],
    queryFn: () =>
      CommunityCodes.getPagintedCommuntiyCode(first, rows, filters),
  }),

  getAllCommunityCodeByCode: (code: string) => ({
    queryKey: [{ code }],
    queryFn: () => CommunityCodes.getAllCommunityCodeByCode(code),
  }),
  getPaginatedAffiliation: (
    first: number,
    rows: number,
    filters: AffiliationFilters
  ) => ({
    queryKey: [{ first, rows, filters }],
    queryFn: () =>
      CommunityCodes.getPagintedAffiliationCode(first, rows, filters),
  }),
  getAffiliationCodeByCode: (code: string) => ({
    queryKey: [{ code }],
    queryFn: () => CommunityCodes.getAffiliationCodeByCode(code),
  }),
});

import { createQueryKeys } from "@lukemorales/query-key-factory";
import SalesIQ from "../SalesIQ";

export const salesIQ = createQueryKeys("salesIQ", {
  all: null,
  getById: (userId: number | string | null) => ({
    queryKey: [{ userId }],
    queryFn: () => SalesIQ.getById(userId),
  }),
});

import React, { useEffect, useState } from "react";
import { Button, DatePicker, Form, Input, message, Select, Spin } from "antd";
import PhoneNumberPrefixSelector from "@/components/widgets/Inputs/PhoneNumberPrefixSelector";
import { useGetCPFResponderDetails } from "@/hooks/useCPFs";
import MomentAntdDateTimePicker from "@/components/widgets/DateTimePicker/MomentAntdDateTimePicker";
import moment from "moment";

type CPFPersonalDetailsProp = {
  editCPFResponderValues?: ICPFResponderPeronalDetails;
  onFormFinish: (cpfResponder: ICPFResponderDetails) => Promise<void>;
  onModalClose: () => void;
  isLoading: boolean;
  isSuccess: boolean;
  isUpdating: boolean;
};
const CPFPersonalDetails = ({
  editCPFResponderValues,
  onFormFinish,
  onModalClose,
  isLoading,
  isSuccess,
  isUpdating,
}: CPFPersonalDetailsProp) => {
  const [form] = Form.useForm();

  const dateFormat = "YYYY-MM-DD";

  const handleClose = () => {
    form.resetFields();
    onModalClose();
  };

  const onFinish = async (formValues: any) => {
    const values: ICPFResponderDetails = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      dateOfBirth: formValues.dateOfBirth,
      gender: formValues.gender,
      fullPhoneNumber: formValues.prefix + formValues.fullPhoneNumber,
      emailAddress: formValues.emailAddress,
      cpfid: formValues.cpfid,
      area: formValues.area,
    };
    onFormFinish(values);
    form.resetFields();
  };

  useEffect(() => {
    if (editCPFResponderValues != null) {
      form.setFieldsValue(editCPFResponderValues);
    }

    const formattedDateOfBirth = editCPFResponderValues?.dateOfBirth
      ? moment(editCPFResponderValues?.dateOfBirth, dateFormat)
      : null;
    form.setFieldsValue({
      dateOfBirth: formattedDateOfBirth,
    });

    const formattedPhoneNo = editCPFResponderValues?.fullPhoneNumber
      ? editCPFResponderValues?.fullPhoneNumber.slice(3)
      : null;
    form.setFieldsValue({
      fullPhoneNumber: formattedPhoneNo,
    });
  }, [editCPFResponderValues]);

  return (
    <div className="flex w-full h-full">
      <Spin spinning={isLoading}>
        <Form
          form={form}
          name="editCPFResponderPersonalDetails"
          onFinish={onFinish}
          layout="vertical"
          style={{ width: "100%" }}
          className="grid"
          initialValues={{
            prefix: "+27", // Set the default value for the prefix here
          }}
        >
          <div className="col-12 md:col-6">
            <Form.Item
              name="firstName"
              label="First Name"
              rules={[
                {
                  required: true,
                  message: "Please input the user's first name!",
                },
              ]}
              className="mb-1"
            >
              <Input
                size="large"
                placeholder="Amanda"
                value={editCPFResponderValues?.firstName}
              />
            </Form.Item>
          </div>

          <div className="col-12 md:col-6">
            <Form.Item
              name="lastName"
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: "Please input the user's last name!",
                },
              ]}
              className="mb-1"
            >
              <Input
                size="large"
                placeholder="Neli"
                value={editCPFResponderValues?.lastName}
              />
            </Form.Item>
          </div>

          <div className="col-12 md:col-6">
            <Form.Item
              name="emailAddress"
              label="Email"
              rules={[
                { required: true, message: "Please input the user's email!" },
                { type: "email", message: "Please input a valid email!" },
              ]}
              className="mb-1"
            >
              <Input
                size="large"
                placeholder="Amando@gmail.com"
                value={editCPFResponderValues?.emailAddress}
              />
            </Form.Item>
          </div>

          <div className="col-12 md:col-6">
            <Form.Item
              name="fullPhoneNumber"
              label="Phone Number"
              rules={[
                {
                  required: true,
                  message: "Please enter user's phone number!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    // Assuming the country code (+27) is automatically included and not part of the input
                    const phoneNumberPattern = /^[6-8][0-9]{8}$/;
                    const fullPhoneNumber = getFieldValue("prefix") + value;
                    // Validate the full phone number including the country code
                    // ^\+27[6-8][0-9]{8}$
                    // ^(\+27|0)[6-8][0-9]{8}$ // if you want to use 0 as the country code
                    if (/^(\+27)[6-8][0-9]{8}$/.test(fullPhoneNumber)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "Please enter a valid South African phone number without a leading 0 after the country code!"
                      )
                    );
                  },
                }),
              ]}
              className="mb-1"
            >
              <Input
                size="large"
                addonBefore={PhoneNumberPrefixSelector()}
                placeholder="63256392"
                value={editCPFResponderValues?.fullPhoneNumber}
              />
            </Form.Item>
          </div>

          <div className="col-12 md:col-6">
            <Form.Item
              name="dateOfBirth"
              label="Date of Birth"
              rules={[
                {
                  required: true,
                  message:
                    "Please enter the responder medical license valid to!",
                },
              ]}
              className="mb-3"
            >
              <MomentAntdDateTimePicker
                size="large"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </div>

          <div className="col-12 md:col-6">
            <Form.Item
              name="gender"
              label="Gender"
              rules={[
                { required: true, message: "Please select the user's gender!" },
              ]}
              className="mb-3"
            >
              <Select
                placeholder="Select user's gender"
                size="large"
                value={editCPFResponderValues?.gender}
              >
                <Select.Option value="Male">Male</Select.Option>
                <Select.Option value="Female">Female</Select.Option>
                <Select.Option value="Other">Other</Select.Option>
              </Select>
            </Form.Item>
          </div>

          <div className="col-12 md:col-6">
            <Form.Item
              name="cpfid"
              label="CPF ID"
              rules={[
                { required: true, message: "Please input the user's CPF ID" },
              ]}
              className="mb-1"
            >
              <Input
                size="large"
                placeholder="564875873"
                value={editCPFResponderValues?.cpfid}
              />
            </Form.Item>
          </div>

          <div className="col-12 md:col-6">
            <Form.Item
              name="area"
              label="CPF Station"
              rules={[
                {
                  required: true,
                  message: "Please input the user's CPF Station",
                },
              ]}
              className="mb-1"
            >
              <Input
                size="large"
                placeholder="Enter CPF Station"
                value={editCPFResponderValues?.area}
              />
            </Form.Item>
          </div>

          <Form.Item className="w-12 mb-1">
            <div className="flex w-full gap-2 col-12 justify-content-end align-content-center">
              <Button
                type="primary"
                ghost
                onClick={handleClose}
                size="large"
                className="w-3"
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={isUpdating}
                size="large"
                className="w-3 bg-[#1757a2] shadow-none"
              >
                Next
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};

export default CPFPersonalDetails;

/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable max-len */
import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import debounce from "lodash/debounce";

import HeaderComponent from "@/components/Header/Header";
import { useModal } from "@/hooks/useModalStore";
import { CustomIcons } from "@/assets";
import Colors from "@/config/Colors";
import { useGetResponderCompanyVehicles } from "@/hooks/useResponderVehicles";
import ResponderCompanyVehicleCard from "@/components/Cards/ResponderCompanyVehicleCard";
import { Button } from "@/components/ui/button";
import { SearchOutlined } from "@ant-design/icons";
import CustomInputField from "@/components/widgets/Inputs/CustomInputField";
import Spin from "@/components/widgets/Spinner/Spin";

type ResponderCompanyVehicleParams = {
  companyId: string;
};

function ResponderCompanyVehicles() {
  const history = useHistory();
  const { companyId } = useParams<ResponderCompanyVehicleParams>();
  const [search, setSearch] = useState("");
  const [searchTextValue, setSearchTextValue] = useState("");

  const debounceSetGlobalFilterValue = debounce((value) => {
    setSearch(value);
  }, 1000); // 500ms delay

  const { data, hasNextPage, fetchNextPage, isLoading, isFetchingNextPage } =
    useGetResponderCompanyVehicles(20, Number(companyId), search);

  const { onOpen } = useModal();

  // This function will be called when the user scrolls near the bottom of the list
  // It's a simplistic way to implement infinite loading. For a real app, consider using a more robust solution like 'react-infinite-scroll-component' or observing scroll position.
  const handleScroll = (event: any) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (scrollHeight - scrollTop === clientHeight && hasNextPage) {
      fetchNextPage();
    }
  };

  const handleSearchChange = (e: string) => {
    debounceSetGlobalFilterValue(e);
    setSearchTextValue(e);
  };

  if (isLoading && !search)
    return (
      <div className="flex items-center justify-center w-full h-full bg-backgroundColor">
        <CustomIcons.EOSThreeDotsLoading
          width={90}
          height={90}
          svgColor={Colors.appMainColor}
        />
      </div>
    );

  return (
    <div className="flex w-full h-full bg-backgroundColor">
      <div
        className="w-full h-full max-h-screen overflow-y-auto disable-scrollbars"
        onScroll={handleScroll}
      >
        <HeaderComponent title="Vehicles" />
        <div className="flex flex-row items-center justify-end w-full gap-3 px-3 mt-1 mb-3">
          <CustomInputField
            value={searchTextValue}
            onChange={(e: string) => handleSearchChange(e)}
            onPressEnter={() => {}}
            inputProps={{
              size: "large",
              placeholder: "Search for registration plate",
              allowClear: true,
              className: "ml-2 w-1/4",
              prefix: <SearchOutlined />,
            }}
            theme={{
              token: {
                colorPrimaryHover: `${Colors.appMainColor}`,
              },
            }}
            mergeThemes
          />
          <Button
            variant="default"
            className="w-2 h-8 rounded-lg bg-appMainColor hover:bg-appMainColor"
            onClick={() => {
              onOpen("createResponderVehicle", {
                activeResponderCompanyId: Number(companyId),
              });
            }}
          >
            +Add new vehicle
          </Button>
        </div>
        <Spin spinning={isLoading} tip="searching for vehicles">
          <div className="grid items-center grid-cols-1 gap-4 px-4 md:grid-cols-2">
            {data?.pages.map((group, i) => (
              <React.Fragment key={i}>
                {group.data.map((vehicle) => (
                  <ResponderCompanyVehicleCard
                    key={vehicle.id}
                    vehicle={vehicle}
                  />
                ))}
              </React.Fragment>
            ))}
          </div>
        </Spin>

        {isFetchingNextPage && (
          <div className="flex items-center justify-center w-ful">
            <CustomIcons.EOSThreeDotsLoading
              width={60}
              height={60}
              svgColor={Colors.appMainColor}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ResponderCompanyVehicles;

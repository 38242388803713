import { mergeQueryKeys } from "@lukemorales/query-key-factory";
import { panicTypes } from "@/services/queries/panicTypesQueries";
import { dashboard } from "./dashboardQueries";
import { emergencies } from "./emergenciesQueries";
import { operators } from "./operatorsQueries";
import { users } from "./usersQueries";
import { directMessage } from "./directMessageQueries";
import { admins } from "./adminsQueries";
import { crimes } from "./crimeQueries";
import { communityCodes } from "./communityCodeQueries";
import { salesIQ } from "./salesIQQueries";
import { communities } from "./communitiesQueries";
import { postApplication } from "./postApplicationsQueries";
import { companies } from "./companiesQueries";
import { communityMessages } from "./communityMessageQueries";
import { carousel } from "./carouselQueries";
import { responderCompanies } from "./responderCompanyQueries";
import { responders } from "./responderQueries";
import { controlRoomOperators } from "./controlRoomOperatorQueries";
import { cpfResponder } from "./cpfResponderQueries";
import { responderAnalytics } from "./responderAnalyticsQueries";

export const queries = mergeQueryKeys(
  dashboard,
  emergencies,
  operators,
  users,
  directMessage,
  admins,
  crimes,
  communityCodes,
  salesIQ,
  communities,
  postApplication,
  companies,
  communityMessages,
  carousel,
  responderCompanies,
  panicTypes,
  responders,
  controlRoomOperators,
  cpfResponder,
  responderAnalytics
);

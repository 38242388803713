/* eslint-disable no-console */
/* eslint-disable no-unreachable */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable prefer-template */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/self-closing-comp */
import React, { useEffect, useState, useRef } from "react";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button, Modal, Tag } from "antd";
import moment from "moment";
import { NavLink, useHistory } from "react-router-dom";
import { Calendar, CalendarChangeEvent } from "primereact/calendar";
import jsPDF from "jspdf";
import autoTable, { applyPlugin } from "jspdf-autotable";
import {
  ActionBodyText,
  BodyContainer,
  Container,
  SubHeading,
  tableBody,
  tableHeader,
  tableName,
} from "./styles";
import HeaderComponent from "../../components/Header/Header";
import Spin from "../../components/widgets/Spinner/Spin";
import { useUsers } from "../../hooks/useUsers";
import CustomInputField from "../../components/widgets/Inputs/CustomInputField";
import Colors from "../../config/Colors";
import { CustomIcons } from "../../assets";
import { modelTitle } from "../../styles/ModalStyles";
import { useReportedCrimes } from "../../hooks/useCrimes";
import BackButton from "../../components/widgets/common/BackButton";

const ReportedCrimes = () => {
  // @ts-ignore
  const dt = useRef<DataTable>(null);
  const navigate = useHistory();
  const [isExporting, setIsExporting] = useState(false);
  const { isLoading, data, isFetching } = useReportedCrimes();
  const [crime, setCrime] = useState<IReportedCrimes[]>();
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const [filters, setFilters] = useState<DataTableFilterMeta | undefined>(
    undefined
  );

  const navigateTo = (id: string | number) => {
    navigate.push(`/home/crime-report-detail/${id}`);
  };

  const [openDownloadButtonsModal, setOpenDownloadButtonsModal] =
    useState(false);

  const cols = [
    { field: "reportedDate", header: "Last Updated" },
    { field: "fullName", header: "Reported By" },
    { field: "categoryOfCrime", header: "Category of Crime" },
    { field: "crimeType", header: "Crime Type" },
    { field: "location", header: "Area" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const exportPdf = () => {
    if (crime) {
      const doc = new jsPDF();
      const rowInputData = crime.map((crime) => ({
        reportedDate: new Date(crime.reportedDate).toLocaleDateString(),
        fullName: `${crime.fullName}`,
        categoryOfCrime: `${crime.category}`,
        crimeType: `${crime.crimeType}`,
        Area: `${crime.location}`,
      }));
      autoTable(doc, { columns: exportColumns, body: rowInputData });
      doc.save("Reported Crime.pdf");
    }
  };

  const exportCSV = (selectionOnly: boolean) => {
    setIsExporting(true);
    dt.current.exportCSV({ selectionOnly });
    setIsExporting(false);
  };

  const clearFilter = () => {
    initFilters();
  };

  const getCrimes = (data: IReportedCrimes[]) => {
    return [...(data || [])].map((d) => {
      // @ts-ignore
      d.reportedDate = new Date(d.reportedDate);

      return d;
    });
  };

  const onGlobalFilterChange = (e: string) => {
    const _filters = { ...filters };
    // @ts-ignore
    _filters.global.value = e;

    setFilters(_filters);
    setGlobalFilterValue(e);
  };

  const customInputTheme = {
    token: {
      colorPrimaryHover: `${Colors.appMainColor}`,
    },
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      reportedDate: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      fullName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      crimeType: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      category: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      location: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
    });
    setGlobalFilterValue("");
  };

  useEffect(() => {
    if (data) {
      setCrime(getCrimes(data));
    }
    setGlobalFilterValue("");

    initFilters();
  }, [data]);

  const dateBodyTemplate = (rowData: IReportedCrimes) => {
    return moment(rowData.reportedDate).format("YYYY/MM/DD");
  };
  const fullNameBodyTemplate = (rowData: IReportedCrimes) => {
    return rowData.fullName.toString();
  };
  const crimeTypeBodyTemplate = (rowData: IReportedCrimes) => {
    return rowData.crimeType;
  };

  const crimeCategoryBodyTemplate = (rowData: IReportedCrimes) => {
    return rowData.category;
  };

  const areaBodyTemplate = (rowData: IReportedCrimes) => {
    return rowData.location;
  };

  const actionBodyTemplate = (rowData: IReportedCrimes) => {
    return (
      <ActionBodyText
        onClick={() => {
          navigateTo(rowData.id);
        }}
      >
        View More
      </ActionBodyText>
    );
  };
  const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e: CalendarChangeEvent) => {
          options.filterCallback(e.value, options.index);
        }}
        dateFormat="yy/mm/dd"
        placeholder="yyyy/mm/dd"
        mask="9999/99/99"
      />
    );
  };

  return (
    <div className="h-full w-full">
      <Container className="h-full w-full">
        <BodyContainer className="w-full h-full">
          <div className="flex flex-row w-full justify-start align-items-center">
            <BackButton />
            <HeaderComponent title="Reported Crimes" />
          </div>
          <Spin tip="" spinning={isLoading || isFetching} delay={500}>
            <div className="w-full h-full px-3 card Users">
              <div className="flex py-3 justify-content-between align-content-center">
                <div
                  className="flex flex-row align-items-center"
                  style={tableName}
                >
                  Reported Crimes
                </div>
                <div className="flex flex-row gap-1 justify-content-center align-items-center">
                  <Button
                    icon={<DownloadOutlined />}
                    type="primary"
                    ghost
                    size="large"
                    className="w-5"
                    onClick={() => {
                      setOpenDownloadButtonsModal(true);
                    }}
                  />
                  <CustomInputField
                    value={globalFilterValue}
                    onChange={(e: string) => onGlobalFilterChange(e)}
                    onPressEnter={() => {}}
                    inputProps={{
                      size: "large",
                      placeholder: "Search",
                      allowClear: true,
                      className: "ml-2 w-13",
                      prefix: <SearchOutlined />,
                    }}
                    theme={customInputTheme}
                    mergeThemes
                  />
                </div>
              </div>
            </div>

            {data && (
              <DataTable
                value={crime}
                ref={dt}
                paginator
                rows={10}
                rowsPerPageOptions={[10, 25, 50, 100]}
                dataKey="id"
                filters={filters}
                globalFilterFields={[
                  "reportedDate",
                  "fullName",
                  "crimeType",
                  "category",
                  "location",
                ]}
                emptyMessage="No Reported crimes found."
                rowHover
                scrollable
                scrollHeight="40rem"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} reported crimes"
                stateStorage="session"
                stateKey="dt-state-Reported-Crime-table"
                className="mx-3"
              >
                <Column
                  field="reportedDate"
                  header="Last Updated"
                  headerStyle={tableHeader}
                  filterField="reportedDate"
                  dataType="date"
                  body={dateBodyTemplate}
                  bodyStyle={tableBody}
                  filter
                  filterElement={dateFilterTemplate}
                  style={{ background: "#FBFBFB" }}
                />
                <Column
                  field="fullName"
                  header="Reported By"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                  body={fullNameBodyTemplate}
                  filter
                />
                <Column
                  field="category"
                  header="Category of Crime"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  body={crimeCategoryBodyTemplate}
                  bodyStyle={tableBody}
                  filter
                  filterPlaceholder="Search by Crime Category"
                />
                <Column
                  field="crimeType"
                  header="Crime Type"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                  body={crimeTypeBodyTemplate}
                  filter
                  filterPlaceholder="Search by Crime Type"
                />
                <Column
                  field="location"
                  header="Area"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                  body={areaBodyTemplate}
                  filter
                  filterPlaceholder="Search by Area"
                />
                <Column
                  field="viewMore"
                  header=""
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  body={actionBodyTemplate}
                  filterMenuStyle={{ width: "14rem" }}
                />
              </DataTable>
            )}
          </Spin>
        </BodyContainer>
      </Container>
      <Modal
        centered
        open={openDownloadButtonsModal}
        onOk={() => {
          setOpenDownloadButtonsModal(false);
        }}
        onCancel={() => {
          setOpenDownloadButtonsModal(false);
        }}
        width={550}
        closeIcon={
          <CustomIcons.CloseIcon
            width={30}
            height={30}
            viewBox={null}
            svgColor="#1C274C"
          />
        }
        footer={null}
      >
        <div
          className="flex w-12 py-3 justify-content-start"
          style={modelTitle}
        >
          Download As
        </div>
        <Button
          className="w-full px-2 bg-successColor mb-1 flex justify-center text-white"
          onClick={() => {
            exportCSV(false);
          }}
        >
          CSV
        </Button>
        <Button
          className="w-full px-2 bg-errorColor mb-1 flex justify-center text-white"
          onClick={() => exportPdf()}
        >
          PDF
        </Button>
      </Modal>
    </div>
  );
};

export default ReportedCrimes;

import { createQueryKeys } from "@lukemorales/query-key-factory";
import Operators from "../Operator";

export const operators = createQueryKeys("operators", {
  all: null,
  history: (operatorId: string | number) => ({
    queryKey: [operatorId],
    queryFn: () => Operators.getOperatorsHistory(operatorId),
  }),
  detail: (userId: string | number) => ({
    queryKey: [userId],
    queryFn: () => Operators.getById(userId),
  }),
  list: (filter: Filters) => ({
    queryKey: [filter],
    queryFn: (ctx) => Operators.getOperatorsList(filter, ctx.pageParam),
    contextQueries: {
      search: (query: string, limit: number) => ({
        queryKey: [query, limit],
        queryFn: (ctx) =>
          Operators.getSearchOperators(filter, ctx.pageParam, limit, query),
      }),
    },
  }),
  byEmergencyAll: (emergencyId: string | number) => ({
    queryKey: [emergencyId],
    queryFn: () => Operators.getAllByEmergency(emergencyId),
  }),
  getAllEmergenciesOperators: null,
  byEmergencyList: (emergencyId: string | number, filter: Filters) => ({
    queryKey: [emergencyId, filter],
    queryFn: (ctx) =>
      Operators.getOperatorsByEmergencyList(emergencyId, filter, ctx.pageParam),
    contextQueries: {
      search: (query: string, limit: number) => ({
        queryKey: [query, limit],
        queryFn: (ctx) =>
          Operators.getByEmergencySearchOperators(
            emergencyId,
            query,
            filter,
            ctx.pageParam,
            limit
          ),
      }),
    },
  }),
  emergencyCases: (userId: string | number) => ({
    queryKey: [userId],
    queryFn: () => Operators.getEmergencyCases(userId),
  }),
  getOperatorsAnalytics: (emergencyId: number | null) => ({
    queryKey: [{ emergencyId }],
    queryFn: () => Operators.getOperatorAnalytics(emergencyId),
  }),
  getSelectedOperatorAnalytics: (
    operatorId: string | undefined,
    startDate: string | Date | undefined | null,
    endDate: string | Date | undefined | null
  ) => ({
    queryKey: [{ operatorId, startDate, endDate }],
    queryFn: () =>
      Operators.getSelectedOperatorAnalytics(operatorId, startDate, endDate),
  }),
  getEmergencyCaseOperatorNote: (emergencyCaseId: number | string | null) => ({
    queryKey: [{ emergencyCaseId }],
    queryFn: () => Operators.getEmergencyCaseOperatorsNote(emergencyCaseId),
  }),
  getOperatorMaxConCurrentCase: (userId: number | string | null) => ({
    queryKey: [{ userId }],
    queryFn: () => Operators.getOperatorMaxConcurrentCases(userId),
  }),
  getTransferableOperators: (
    emergencyId: number | string | null,
    operatorId: number | string | null
  ) => ({
    queryKey: [{ emergencyId, operatorId }],
    queryFn: () => Operators.getTransferableOperators(emergencyId, operatorId),
  }),
});

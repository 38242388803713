/* eslint-disable no-underscore-dangle */
import {
  InfiniteData,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { queries } from "../services/queries";
import Communities from "../services/Communities";
import { isErrorMessage } from "../util/helpers";
import { users } from "../services/queries/usersQueries";
import { UserType } from "../util/enums";

export function useCommunities(): UseQueryResult<ICommunity[]> {
  return useQuery({
    ...queries.communities.all,
    queryFn: () => Communities.getAll(),
  });
}

export function useGetCommunityOperators(
  communityId: number | null | undefined,
  limit: number | null | undefined = 10,
  page: number | null | undefined = 0
): UseQueryResult<ICommunityOperatorsResponse> {
  return useQuery({
    ...queries.communities.GetCommunityOperators(communityId, limit, page),
    enabled: !!communityId,
  });
}

export function useGetPostByPostId(
  postId: number | string | null | undefined
): UseQueryResult<ICommunityFeed> {
  return useQuery({
    ...queries.communities.GetPostByPostId(postId),
    enabled: !!postId,
  });
}

export function useGetSaferCityPostByPostId(
  postId: number | string | null | undefined
): UseQueryResult<ICommunityFeed> {
  return useQuery({
    ...queries.communities.GetSaferCityPostByPostId(postId),
    enabled: !!postId,
  });
}

export function useGetReportedPostDetails(
  postId: number | null
): UseQueryResult<ICommunityFeed> {
  return useQuery({
    ...queries.communities.GetReportedPost(postId),
    enabled: !!postId,
  });
}

export function useAddCommunity() {
  const queryClient = useQueryClient();
  return useMutation(Communities.addCommunity, {
    onMutate: async (newCommunity: IAddCommunity) => {
      await queryClient.cancelQueries(queries.communities.all.queryKey);
      const previousCommunities = queryClient.getQueryData(
        queries.communities.all.queryKey
      ) as ICommunity[];
      const newCommunityToAdd: ICommunity = {
        communityName: newCommunity.communityName,
        communityDescription: newCommunity.communityDescription,
        communityIcon: newCommunity.communityIcon,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        id: Math.floor(Math.random() * 10000),
      };
      // optimistic update
      queryClient.setQueryData(
        queries.communities.all.queryKey,
        (oldCommunitiesData: ICommunity[] = []) => {
          return [...oldCommunitiesData, newCommunityToAdd];
        }
      );
      return { previousCommunities };
    },
    onError: (error: AxiosError, newUser, context) => {
      // rollback to the previous value if mutation fails
      if (context) {
        queryClient.setQueryData(
          queries.communities.all.queryKey,
          context.previousCommunities
        );
      }

      const errorMessage = error.response?.data;

      if (typeof errorMessage === "string") {
        toast.error(errorMessage);
      } else if (isErrorMessage(errorMessage)) {
        const errorTitle = errorMessage.title;
        const errorKeys = Object.keys(errorMessage.errors || {});
        if (errorKeys.length > 0 && errorMessage.errors) {
          const errorFieldMessage = `${errorKeys[0]}: ${
            errorMessage.errors[errorKeys[0]][0]
          }`;
          toast.error(errorFieldMessage);
        } else {
          toast.error(errorTitle);
        }
      } else {
        // If errorMessage is not a string and not an IErrorMessage, show a generic error message
        toast.error("Something went wrong");
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queries.communities.all.queryKey);
    },
    meta: {
      useError: true,
    },
  });
}

export function useUpdateCommunity() {
  const queryClient = useQueryClient();
  return useMutation(Communities.updateCommunity, {
    onError: (error: AxiosError, newUser, context) => {
      const errorMessage = error.response?.data;

      if (typeof errorMessage === "string") {
        toast.error(errorMessage);
      } else if (isErrorMessage(errorMessage)) {
        const errorTitle = errorMessage.title;
        const errorKeys = Object.keys(errorMessage.errors || {});
        if (errorKeys.length > 0 && errorMessage.errors) {
          const errorFieldMessage = `${errorKeys[0]}: ${
            errorMessage.errors[errorKeys[0]][0]
          }`;
          toast.error(errorFieldMessage);
        } else {
          toast.error(errorTitle);
        }
      } else {
        // If errorMessage is not a string and not an IErrorMessage, show a generic error message
        toast.error("Something went wrong");
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queries.communities.all.queryKey);
    },
    meta: {
      useError: true,
    },
  });
}

export function useDeletePost() {
  const queryClient = useQueryClient();
  return useMutation(Communities.deletePost, {
    onSuccess: (apiResponse, variables, context) => {
      const deletedPost: ICommunityFeed = apiResponse.data;
      queryClient.invalidateQueries([
        "getCommunityFeed",
        deletedPost.communityId,
      ]);
    },
    onError: (error: AxiosError) => {
      const errorMessage = error.response?.data;

      if (typeof errorMessage === "string") {
        toast.error(errorMessage);
      } else if (isErrorMessage(errorMessage)) {
        const errorTitle = errorMessage.title;
        const errorKeys = Object.keys(errorMessage.errors || {});
        if (errorKeys.length > 0 && errorMessage.errors) {
          const errorFieldMessage = `${errorKeys[0]}: ${
            errorMessage.errors[errorKeys[0]][0]
          }`;
          toast.error(errorFieldMessage);
        } else {
          toast.error(errorTitle);
        }
      } else {
        // If errorMessage is not a string and not an IErrorMessage, show a generic error message
        toast.error("Something went wrong");
      }
    },
    meta: {
      useError: true,
    },
  });
}

export function useLinkCodeToCommunity() {
  const queryClient = useQueryClient();
  return useMutation(Communities.linkCodeToCommunity, {
    onSuccess: (newAdmin, request) => {
      queryClient.invalidateQueries({
        queryKey: ["getCommunityCodes", request.communityId], // unique key,
      });
    },
    onError: (error: AxiosError) => {
      const errorMessage = error.response?.data;

      if (typeof errorMessage === "string") {
        toast.error(errorMessage);
      } else if (isErrorMessage(errorMessage)) {
        const errorTitle = errorMessage.title;
        const errorKeys = Object.keys(errorMessage.errors || {});
        if (errorKeys.length > 0 && errorMessage.errors) {
          const errorFieldMessage = `${errorKeys[0]}: ${
            errorMessage.errors[errorKeys[0]][0]
          }`;
          toast.error(errorFieldMessage);
        } else {
          toast.error(errorTitle);
        }
      } else {
        // If errorMessage is not a string and not an IErrorMessage, show a generic error message
        toast.error("Something went wrong");
      }
    },
    meta: {
      useError: true,
    },
  });
}

export function usePostReaction() {
  const queryClient = useQueryClient();

  return useMutation(Communities.postReaction, {
    onMutate: async (newReaction: ICreatePostReaction) => {
      // Get current data from cache
      const currentCommunityData = queryClient.getQueryData([
        "getCommunityFeed",
        newReaction.communityId,
      ]);

      const currentSaferCityData = queryClient.getQueryData([
        "getSaferCityPosts",
      ]);

      const currentSaferCityCommentsData = queryClient.getQueryData([
        "getSaferCityPostComments",
        newReaction.postId,
      ]);

      const postIdKey = [
        "communities",
        "GetPostByPostId",
        { postId: newReaction.postId.toString() },
      ];

      const SaferCityPostIdKey = [
        "communities",
        "GetSaferCityPostByPostId",
        { postId: newReaction.postId.toString() },
      ];

      const currentPostData = queryClient.getQueryData(
        postIdKey
      ) as ICommunityFeed;

      const currentSaferCityPostData = queryClient.getQueryData(
        SaferCityPostIdKey
      ) as ICommunityFeed;

      const rollback: any = {};

      if (currentCommunityData) {
        // If the data exists, proceed to deep copy
        rollback.communityFeed = JSON.parse(
          JSON.stringify(currentCommunityData)
        );

        // Optimistically update to the new value
        queryClient.setQueryData(
          ["getCommunityFeed", newReaction.communityId],
          (old: { pages: ICommunityFeedResponse[] } | undefined) => {
            if (!old) return undefined;

            const newPages = old.pages.map((page) => {
              const newFeed = page.feed.map((post) => {
                if (post.id !== newReaction.postId) return post;

                const existingReactionIndex = post.reactions.findIndex(
                  (reaction) =>
                    reaction.userId === newReaction.userId &&
                    reaction.reactionId === newReaction.reactionId
                );
                const newReactions = [...post.reactions];

                if (existingReactionIndex !== -1) {
                  newReactions.splice(existingReactionIndex, 1);
                } else {
                  newReactions.push({
                    id: Date.now(), // Temp ID until the server responds
                    userId: newReaction.userId,
                    postId: newReaction.postId,
                    reactionId: newReaction.reactionId,
                    createdAt: new Date(),
                    updatedAt: new Date(),
                  });
                }

                return { ...post, reactions: newReactions };
              });

              return { ...page, feed: newFeed };
            });

            return { ...old, pages: newPages };
          }
        );
      }

      if (currentSaferCityData) {
        // If the data exists, proceed to deep copy
        rollback.SaferCityCommunityFeed = JSON.parse(
          JSON.stringify(currentSaferCityData)
        );

        // Optimistically update to the new value
        queryClient.setQueryData(
          ["getSaferCityPosts"],
          (old: { pages: ICommunityFeedResponse[] } | undefined) => {
            if (!old) return undefined;

            const newPages = old.pages.map((page) => {
              const newFeed = page.feed.map((post) => {
                if (post.id !== newReaction.postId) return post;

                const existingReactionIndex = post.reactions.findIndex(
                  (reaction) =>
                    reaction.userId === newReaction.userId &&
                    reaction.reactionId === newReaction.reactionId
                );
                const newReactions = [...post.reactions];

                if (existingReactionIndex !== -1) {
                  newReactions.splice(existingReactionIndex, 1);
                } else {
                  newReactions.push({
                    id: Date.now(), // Temp ID until the server responds
                    userId: newReaction.userId,
                    postId: newReaction.postId,
                    reactionId: newReaction.reactionId,
                    createdAt: new Date(),
                    updatedAt: new Date(),
                  });
                }

                return { ...post, reactions: newReactions };
              });

              return { ...page, feed: newFeed };
            });

            return { ...old, pages: newPages };
          }
        );
      }

      if (currentPostData) {
        rollback.currentPostData = JSON.parse(JSON.stringify(currentPostData));

        // Optimistic update for GetPostByPostId
        const existingReactionIndex = currentPostData.reactions?.findIndex(
          (reaction) =>
            reaction.userId === newReaction.userId &&
            reaction.reactionId === newReaction.reactionId
        );

        if (existingReactionIndex !== -1) {
          currentPostData.reactions.splice(existingReactionIndex, 1);
        } else {
          currentPostData.reactions.push({
            id: Date.now(), // Temp ID until the server responds
            userId: newReaction.userId,
            postId: newReaction.postId,
            reactionId: newReaction.reactionId,
            createdAt: new Date(),
            updatedAt: new Date(),
          });
        }
        queryClient.setQueryData(
          queries.communities.GetPostByPostId(newReaction.postId).queryKey,
          currentPostData
        );
      }
      if (currentSaferCityPostData) {
        rollback.currentSaferCityPostData = JSON.parse(
          JSON.stringify(currentSaferCityPostData)
        );

        // Optimistic update for GetPostByPostId
        const existingReactionIndex =
          currentSaferCityPostData.reactions?.findIndex(
            (reaction) =>
              reaction.userId === newReaction.userId &&
              reaction.reactionId === newReaction.reactionId
          );

        if (existingReactionIndex !== -1) {
          currentSaferCityPostData.reactions.splice(existingReactionIndex, 1);
        } else {
          currentSaferCityPostData.reactions.push({
            id: Date.now(), // Temp ID until the server responds
            userId: newReaction.userId,
            postId: newReaction.postId,
            reactionId: newReaction.reactionId,
            createdAt: new Date(),
            updatedAt: new Date(),
          });
        }
        queryClient.setQueryData(
          queries.communities.GetSaferCityPostByPostId(newReaction.postId)
            .queryKey,
          currentSaferCityPostData
        );
      }

      // Return the rollback function
      return rollback;
    },

    onError: (
      error: AxiosError,
      newReaction: ICreatePostReaction,
      rollback: any
    ) => {
      if (rollback) {
        if (rollback.communityFeed) {
          queryClient.setQueryData(
            ["getCommunityFeed", newReaction.communityId],
            rollback.communityFeed
          );
        }
        if (rollback.currentPostData) {
          const postIdKey = [
            "communities",
            "GetPostByPostId",
            { postId: newReaction.postId.toString() },
          ];

          queryClient.setQueryData(postIdKey, rollback.currentPostData);
        }
        if (rollback.currentSaferCityPostData) {
          const SaferCityPostIdKey = [
            "communities",
            "GetSaferCityPostByPostId",
            { postId: newReaction.postId.toString() },
          ];

          queryClient.setQueryData(
            SaferCityPostIdKey,
            rollback.currentSaferCityPostData
          );
        }
        if (rollback.getSaferCityCommunityFeed) {
          queryClient.setQueryData(
            ["getSaferCityPosts"],
            rollback.saferCityCommunityFeed
          );
        }
      }

      const errorMessage = error.response?.data;

      if (typeof errorMessage === "string") {
        toast.error(errorMessage);
      } else if (isErrorMessage(errorMessage)) {
        const errorTitle = errorMessage.title;
        const errorKeys = Object.keys(errorMessage.errors || {});
        if (errorKeys.length > 0 && errorMessage.errors) {
          const errorFieldMessage = `${errorKeys[0]}: ${
            errorMessage.errors[errorKeys[0]][0]
          }`;
          toast.error(errorFieldMessage);
        } else {
          toast.error(errorTitle);
        }
      } else {
        toast.error("Something went wrong");
      }
    },

    onSuccess: (data, variables, context) => {
      console.log(data);
      // Update the post with the real ID from the server
      queryClient.setQueryData(
        ["getCommunityFeed", variables.communityId],
        (old: { pages: ICommunityFeedResponse[] } | undefined) => {
          if (!old) return undefined;

          const newPages = old.pages.map((page) => {
            const newFeed = page.feed.map((post) => {
              if (post.id !== variables.postId) return post;

              const reaction = post.reactions.find(
                (postReaction) => postReaction.userId === variables.userId
              );

              const newReactions = [...post.reactions];
              if (reaction) {
                const index = newReactions.indexOf(reaction);
                if (index !== -1) {
                  newReactions[index] = { ...reaction, id: data.id };
                }
              }

              return { ...post, reactions: newReactions };
            });

            return { ...page, feed: newFeed };
          });

          return { ...old, pages: newPages };
        }
      );

      queryClient.setQueryData(
        ["GetPostByPostId", variables.postId],
        (old: { pages: ICommunityFeedResponse[] } | undefined) => {
          if (!old) return undefined;

          const newPages = old.pages.map((page) => {
            const newFeed = page.feed.map((post) => {
              if (post.id !== variables.postId) return post;

              const reaction = post.reactions.find(
                (postReaction) => postReaction.userId === variables.userId
              );

              const newReactions = [...post.reactions];
              if (reaction) {
                const index = newReactions.indexOf(reaction);
                if (index !== -1) {
                  newReactions[index] = { ...reaction, id: data.id };
                }
              }

              return { ...post, reactions: newReactions };
            });

            return { ...page, feed: newFeed };
          });

          return { ...old, pages: newPages };
        }
      );

      // Update the individual post with the real ID from the server
      const postIdKey = [
        "communities",
        "GetPostByPostId",
        { postId: variables.postId.toString() },
      ];
      queryClient.setQueryData(
        postIdKey,
        (oldPost: ICommunityFeed | undefined) => {
          if (!oldPost) return undefined;

          const reaction = oldPost.reactions.find(
            (postReaction) => postReaction.userId === variables.userId
          );

          const newReactions = [...oldPost.reactions];
          if (reaction) {
            const index = newReactions.indexOf(reaction);
            if (index !== -1) {
              newReactions[index] = { ...reaction, id: data.id };
            }
          }

          return { ...oldPost, reactions: newReactions };
        }
      );
      const SaferCityPostIdKey = [
        "communities",
        "GetSaferCityPostByPostId",
        { postId: variables.postId.toString() },
      ];
      queryClient.setQueryData(
        SaferCityPostIdKey,
        (oldPost: ICommunityFeed | undefined) => {
          if (!oldPost) return undefined;

          const reaction = oldPost.reactions.find(
            (postReaction) => postReaction.userId === variables.userId
          );

          const newReactions = [...oldPost.reactions];
          if (reaction) {
            const index = newReactions.indexOf(reaction);
            if (index !== -1) {
              newReactions[index] = { ...reaction, id: data.id };
            }
          }

          return { ...oldPost, reactions: newReactions };
        }
      );
    },

    meta: {
      useError: true,
    },
  });
}

export function usePostToCommunity() {
  const queryClient = useQueryClient();

  return useMutation(Communities.postToCommunity, {
    onError: (error: AxiosError) => {
      const errorMessage = error.response?.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queries.communities.all.queryKey);
    },
  });
}
export function usePostComment() {
  const queryClient = useQueryClient();
  return useMutation(Communities.postComment, {
    onSuccess: (apiResponse, request) => {
      // const newComment = apiResponse;
      // // Get the current cached data
      // const currentData = queryClient.getQueryData<any>([
      //   "getPostComments",
      //   request.postId,
      // ]);
      // // If there's no data, then there's nothing to update
      // if (!currentData) return;
      // console.log(currentData);
      // // Add the new comment to the beginning of the comments array of the first page
      // currentData.pages[0].comments.unshift(newComment);
      // // Update the cache with the new data
      // queryClient.setQueryData(
      //   ["getPostComments", request.postId],
      //   currentData
      // );
    },
    onError: (error: AxiosError) => {
      const errorMessage = error.response?.data;
    },
    meta: {
      useError: true,
    },
  });
}

export function useGetPaginatedCommunitiesCardsData(
  pageNumber: string | number | null,
  pageSize: number | string | null,
  name: string | null
): UseQueryResult<IPaginatedCommunityResponse> {
  return useQuery({
    ...queries.communities.getCommunitiesPaginatedCardsData(
      pageNumber,
      pageSize,
      name
    ),
    refetchOnWindowFocus: false,
  });
}

export function useGetCommunityAdminCases(
  communityAdminId: number | string | null | undefined,
  isCommunityAdmin: boolean
): UseQueryResult<ICommunityAdminCase[]> {
  return useQuery({
    ...queries.communities.GetCommunityAdminCases(communityAdminId),
    enabled: !!communityAdminId && isCommunityAdmin,
    refetchInterval: 30 * 1000, // refetch every 30 seconds
    refetchIntervalInBackground: true,
  });
}

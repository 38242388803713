import React, { useState } from "react";
import { Button, Avatar, Tag } from "antd";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Spin from "../widgets/Spinner/Spin";
import { tableBody, tableHeader } from "../../styles/TableStyles";
import { getInitials } from "../../util/helpers";
import { OnlineStatus } from "../../util/enums";

interface TransferableOperatorProp {
  data: ITransferableOperators[] | null;
  isLoading: boolean;
  onTransfer: (operatorId: number) => Promise<void>;
}

function TransferableOperators({
  data,
  isLoading,
  onTransfer,
}: TransferableOperatorProp) {
  const onTransferBodyTemplate = (rowData: ITransferableOperators) => {
    const [isTransferring, setIsTransferring] = useState(false);

    return (
      <Button
        type="primary"
        size="large"
        loading={isTransferring}
        className={`w-12 ${"bg-green-500"} shadow-none hover:${"bg-green-600"}`}
        onClick={async () => {
          setIsTransferring(true);
          try {
            await onTransfer(rowData.id);
          } catch (error) {
            console.error(error);
            // Handle error if needed.
          }
          setIsTransferring(false);
        }}
      >
        Transfer
      </Button>
    );
  };

  const avatarBodyTemplate = (rowData: ITransferableOperators) => {
    if (rowData.avatar) {
      return (
        <Avatar
          src={rowData.avatar}
          size={32}
          className="mr-2"
          style={{ background: "#FBFBFB" }}
        />
      );
    }
    return (
      <Avatar size={32} className="mr-2" style={{ background: "#7265e6" }}>
        {getInitials(`${rowData.firstName}  ${rowData.lastName}`)}
      </Avatar>
    );
  };

  const getSeverity = (status: OnlineStatus) => {
    switch (status) {
      case OnlineStatus.Online:
        return "success";
      case OnlineStatus.Offline:
        return "error";
      default:
        return "processing";
    }
  };

  const statusBodyTemplate = (rowData: ITransferableOperators) => {
    return (
      <Tag color={getSeverity(rowData.status)}>
        {rowData.status === OnlineStatus.Online ? "Online" : "Offline"}
      </Tag>
    );
  };
  return (
    <Spin tip="" spinning={isLoading} delay={500}>
      <div className="pt-2 card General">
        {data ? (
          <DataTable
            value={Array.isArray(data) ? data : []}
            paginator
            rows={10}
            rowsPerPageOptions={[10, 25, 50, 100]}
            tableStyle={{ minWidth: "50rem" }}
            sortMode="multiple"
            emptyMessage="No operators found."
            dataKey="id"
            scrollable
            scrollHeight="40rem"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Operators"
            stateStorage="session"
            stateKey="dt-state-transferable-operator-table"
          >
            <Column
              field="avatar"
              headerStyle={tableHeader}
              style={{ background: "#FBFBFB" }}
              bodyStyle={tableBody}
              body={avatarBodyTemplate}
            />
            <Column
              field="firstName"
              header="First Name"
              headerStyle={tableHeader}
              style={{ background: "#FBFBFB" }}
              bodyStyle={tableBody}
            />
            <Column
              field="lastName"
              header="Last Name"
              headerStyle={tableHeader}
              style={{ background: "#FBFBFB" }}
              bodyStyle={tableBody}
            />
            <Column
              field="email"
              header="Email"
              headerStyle={tableHeader}
              style={{ background: "#FBFBFB" }}
              bodyStyle={tableBody}
            />
            <Column
              field="slot"
              header="Availability"
              headerStyle={tableHeader}
              style={{ background: "#FBFBFB" }}
              bodyStyle={tableBody}
            />
            <Column
              field="status"
              header="Status"
              headerStyle={tableHeader}
              style={{ background: "#FBFBFB" }}
              body={statusBodyTemplate}
            />
            <Column
              header=""
              headerStyle={tableHeader}
              style={{ background: "#FBFBFB" }}
              body={onTransferBodyTemplate}
            />
          </DataTable>
        ) : (
          <h1 className="text-center">No operators data found.</h1>
        )}
      </div>
    </Spin>
  );
}

export default TransferableOperators;

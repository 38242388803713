/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-vars */
import { Button, Form, Input, message, Select } from "antd";

import React, { useEffect, useRef, useState } from "react";
import { copyObjects } from "../../util/copyObjects";

type NewEmergencyFormProp = {
  userValues?: any;
  onFormFinish: (user: IUser, subId: string | number | null) => void;
  onModelClose: () => void;
  isLoading: boolean;
  isSuccess: boolean;
  showValues?: boolean;
};

const EditUserForm = ({
  userValues,
  onFormFinish,
  onModelClose,
  isLoading,
  isSuccess,
  showValues = true,
}: NewEmergencyFormProp) => {
  const [form] = Form.useForm();
  const [disableSelect, setDisableSelect] = useState(false);

  const onFinish = async (formValues: any) => {
    const values: IUser = copyObjects(userValues, formValues);

    await onFormFinish(
      values,
      disableSelect === true ? null : formValues.subId ? formValues.subId : null
    );
  };

  // New reset function
  const resetFormAndState = () => {
    form.resetFields();
    setDisableSelect(false);
  };

  const handleClose = () => {
    resetFormAndState();
    onModelClose();
  };

  const onFinishFailed = (errorInfo: any) => {
    message.error("Could not update user, please try again.");
  };

  useEffect(() => {
    if (userValues) {
      form.setFieldsValue(userValues);
      // setDefaultSubId(userValues.subId);
      if (userValues.auraSubscriptionUsers) {
        if (userValues.auraSubscriptionUsers.length > 0) {
          switch (userValues.auraSubscriptionUsers[0].subscriptionTypeId) {
            case 2:
              setDisableSelect(true);
              form.setFieldsValue({
                subId: { value: "2" },
              });
              break;
            case 3:
              setDisableSelect(true);
              form.setFieldsValue({
                subId: { value: "3" },
              });
              break;
            case 4:
              setDisableSelect(true);
              form.setFieldsValue({
                subId: { value: "4" },
              });
              break;
            case -1:
              setDisableSelect(false);
              break;
            default:
              setDisableSelect(false);
              break;
          }
        }
      } else {
        setDisableSelect(false);
        form.setFieldsValue({
          subId: null,
        });
      }
    } else {
      resetFormAndState();
    }
  }, [userValues, form]);

  useEffect(() => {
    if (isSuccess) {
      resetFormAndState();
    }
  }, [isSuccess]);

  return (
    <Form
      form={form}
      name="newEmergency"
      onFinish={onFinish}
      // onFinishFailed={onFinishFailed}
      layout="vertical"
      style={{ width: "100%" }}
      className="grid"
      initialValues={userValues}
    >
      <div className="col-12 md:col-6">
        <Form.Item
          name="firstName"
          label="First Name"
          rules={[
            { required: true, message: "Please input the user's first name!" },
          ]}
          className="mb-1"
        >
          <Input size="large" placeholder="Amanda" />
        </Form.Item>
      </div>

      <div className="col-12 md:col-6">
        <Form.Item
          name="lastName"
          label="Last Name"
          rules={[
            { required: true, message: "Please input the user's last name!" },
          ]}
          className="mb-1"
        >
          <Input size="large" placeholder="Neli" />
        </Form.Item>
      </div>

      <div className="col-12 md:col-6">
        <Form.Item
          name="emailAddress"
          label="Email"
          rules={[
            { required: true, message: "Please input the user's email!" },
            { type: "email", message: "Please input a valid email!" },
          ]}
          className="mb-1"
        >
          <Input
            size="large"
            placeholder="Amando@gmail.com"
            bordered={false}
            disabled
          />
        </Form.Item>
      </div>

      <div className="col-12 md:col-6">
        <Form.Item
          name="phoneNumber"
          label="Phone Number"
          rules={[
            {
              required: true,
              message: "Please enter user's phone number!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                //const phoneNumberPattern = /^(\+27|0)[6-8][0-9]{8}$/;
                const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;
                if (phoneNumberPattern.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Please enter a valid phoneNumber!")
                );
              },
            }),
          ]}
          className="mb-1"
        >
          <Input size="large" placeholder="+2763256392" />
        </Form.Item>
      </div>

      {showValues && (
        <>
          <div className="col-12 md:col-6">
            <Form.Item name="auraId" label="Aura ID" className="mb-3">
              <Input size="large" placeholder="-" disabled />
            </Form.Item>
          </div>

          <div className="col-12 md:col-6">
            <Form.Item name="subId" label="Subscription" className="mb-3">
              <Select placeholder="-" size="large" disabled={disableSelect}>
                <Select.Option value="2">Basic plan</Select.Option>
                <Select.Option value="3">Family of 4</Select.Option>
                <Select.Option value="4">Family of 6</Select.Option>
              </Select>
            </Form.Item>
          </div>
        </>
      )}

      <Form.Item className="w-12 pt-2 mb-1">
        <div className="flex w-full gap-2 col-12 justify-content-end align-content-center">
          <Button
            type="primary"
            ghost
            onClick={handleClose}
            size="large"
            className="w-3"
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            size="large"
            className="w-3 bg-[#1757a2] shadow-none"
          >
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default EditUserForm;

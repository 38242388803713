/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Badge } from "primereact/badge";
import { SearchOutlined } from "@ant-design/icons";
import { Select, Tabs } from "antd";
import type { TabsProps } from "antd";
import { DateTime } from "luxon";
import {
  Title,
  UserPreview,
  UserName,
  UserPreviewMessage,
  SortSelect,
} from "./styles";
import "./messagePanel.css";
import UserAvatar from "../widgets/Avatars/UserAvatar";
import CustomInputField from "../widgets/Inputs/CustomInputField";
import Colors from "../../config/Colors";
import { decodeMessage, truncateDescription } from "../../util/helpers";

const _ = require("lodash");

interface IMessagePanelProp {
  // eslint-disable-next-line no-unused-vars
  onUserSelect: (selectedCase: IOperatorEmergencyCase) => void;
  cases: IOperatorEmergencyCase[];
}

interface IUserPreviewProp {
  c: IOperatorEmergencyCase;
}
const { Option } = Select;

const MessagePanel = ({ onUserSelect, cases }: IMessagePanelProp) => {
  const [searchText, setSearchText] = useState("");
  const [sortOption, setSortOption] = useState("newest");
  const [activeIndex, setActiveIndex] = useState("1");
  const [openCases, setOpenCases] = useState<IOperatorEmergencyCase[]>(
    cases.filter((c) => c.emergencyCase?.resolvedStatus === 0)
  );
  const [pausedCases, setPausedCases] = useState<IOperatorEmergencyCase[]>(
    cases.filter((c) => c.emergencyCase?.resolvedStatus === 2)
  );

  const onTabChange = (key: string) => {
    setActiveIndex(key);
  };

  const onSortChange = (value: any) => {
    setSortOption(value);
  };

  const customTheme = {
    token: {
      colorPrimaryHover: `${Colors.appMainColor}`,
    },
  };

  const openCasesHeight = "95%";
  const pausedCasesHeight = "95%";

  const UserPreviewComponent = ({ c }: IUserPreviewProp) => {
    return (
      <UserPreview
        className="w-12"
        key={`Key-${c.user.id}`}
        onClick={() => {
          onUserSelect(c);
        }}
      >
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center justify-center messagePanel">
            <UserAvatar user={c.user} showBadge={false} />
            <div>
              <UserName>
                {c.user.firstName} {c.user.lastName}
              </UserName>
              {c.messagePreview && c.messagePreview?.messageText && (
                <UserPreviewMessage>
                  {c.messagePreview?.senderId === c.practitioner?.id && (
                    <>You: </>
                  )}
                  {truncateDescription(
                    decodeMessage(c.messagePreview?.messageText),
                    20
                  )}
                </UserPreviewMessage>
              )}
            </div>
          </div>
          <div className="flex flex-col items-center justify-center messagePanelDate">
            {c.messagePreview && (
              <>
                <p
                  className="m-0 text-sm"
                  style={{ color: `${Colors.subtleTextColor}` }}
                >
                  {DateTime.fromISO(c.messagePreview.dateAdded, {
                    zone: "utc",
                  })
                    .toLocal()
                    .toFormat("dd/MM/yyyy")}
                </p>
                <p
                  className="py-1 m-0 text-sm"
                  style={{ color: `${Colors.subtleTextColor}` }}
                >
                  {DateTime.fromISO(c.messagePreview.dateAdded, {
                    zone: "utc",
                  })
                    .toLocal()
                    .toFormat("HH:mm")}
                </p>
                {c.messagePreview.receiverId === c.practitioner?.id &&
                  c.messagePreview.readStatus === 0 && (
                    <Badge style={{ color: `${Colors.secondaryColor}` }} />
                  )}
              </>
            )}
          </div>
        </div>
      </UserPreview>
    );
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Open`,
      children: (
        <div
          className="pt-1 overflow-auto messagePanel-messages"
          style={{ height: openCasesHeight }}
          key={1}
        >
          {openCases.length ? (
            <>
              {openCases.map((c: IOperatorEmergencyCase) => (
                <div key={c.user.id}>
                  <UserPreviewComponent c={c} />
                </div>
              ))}
            </>
          ) : (
            <div
              className="text-center"
              style={{
                fontWeight: 400,
                fontSize: "30px",
                color: "#7B7979",
              }}
            >
              No open chat
            </div>
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: `Paused`,
      children: (
        <div
          className="pt-1 overflow-auto messagePanel-messages"
          style={{ height: pausedCasesHeight }}
          key={2}
        >
          {pausedCases.length ? (
            <>
              {pausedCases.map((c: IOperatorEmergencyCase) => (
                <div key={c.user.id}>
                  <UserPreviewComponent c={c} />
                </div>
              ))}
            </>
          ) : (
            <div
              className="text-center"
              style={{
                fontWeight: 400,
                fontSize: "30px",
                color: "#7B7979",
              }}
            >
              No on-hold chat
            </div>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    let tempCases = cases;
    if (searchText) {
      tempCases = tempCases.filter((c) =>
        c.user.firstName.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    let sortedCases: IOperatorEmergencyCase[] = [...tempCases];

    if (sortOption) {
      if (sortOption === "newest") {
        sortedCases = _.sortBy(sortedCases, (c: IOperatorEmergencyCase) =>
          DateTime.fromISO(c.emergencyCase.dateAdded, {
            zone: "utc",
          }).toMillis()
        ).reverse();
      } else if (sortOption === "name") {
        sortedCases = _.sortBy(
          sortedCases,
          (c: IOperatorEmergencyCase) => c.user.firstName
        );
      }
    }

    const filteredOpenCases = sortedCases.filter(
      (c) => c.emergencyCase.resolvedStatus === 0
    );
    setOpenCases(filteredOpenCases);
    const filteredPausedCases = sortedCases.filter(
      (c) => c.emergencyCase.resolvedStatus === 2
    );
    setPausedCases(filteredPausedCases);
  }, [searchText, sortOption, cases]);

  return (
    <div
      className="w-4 h-full messagePanel-container flex-column align-items-start justify-content-start"
      style={{
        background: `${Colors.backgroundColor}`,
        borderRight: "1px solid #f0f0f0",
      }}
    >
      <Title className="pt-5 pb-2 ml-4">Messages</Title>
      <div className="flex w-12 messagePanel-search justify-content-start">
        <CustomInputField
          value={searchText}
          onChange={(e: string) => setSearchText(e)}
          onPressEnter={() => console.log("hit press")}
          inputProps={{
            size: "large",
            placeholder: "Enter name here",
            allowClear: true,
            className: "ml-2 w-11",
            prefix: <SearchOutlined />,
          }}
          theme={customTheme}
          mergeThemes
        />
      </div>
      <div className="flex w-12 pt-3 pl-3 messagePanel-sortable justify-content-start">
        <div className="flex flex-row gap-1 align-items-center">
          <span className="text-sm text-appMainColor">Sort by</span>
          <SortSelect
            placeholder="Newest"
            optionFilterProp="children"
            onChange={onSortChange}
            bordered={false}
          >
            <Option value="newest" style={{ color: `${Colors.appMainColor}` }}>
              Newest
            </Option>
            <Option value="name" style={{ color: `${Colors.appMainColor}` }}>
              Name
            </Option>
            {/* <Option value="date" style={{ color: `${Colors.appMainColor}` }}>
              Date
            </Option> */}
          </SortSelect>
        </div>
      </div>
      {cases ? (
        <Tabs
          defaultActiveKey={activeIndex}
          items={items}
          onChange={onTabChange}
          animated
          tabBarStyle={{ paddingLeft: 15 }}
        />
      ) : (
        <div className="flex flex-row justify-content-center align-items-center">
          <div
            className="text-center"
            style={{
              fontWeight: 400,
              fontSize: "30px",
              color: "#7B7979",
            }}
          >
            No Messages{" "}
          </div>
        </div>
      )}
    </div>
  );
};

export default MessagePanel;

import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { queries } from "../services/queries";
import { Crimes } from "../services";

export function useCrimeMapData(): UseQueryResult<ICrimeAreaCount[]> {
  return useQuery({
    ...queries.crimes.MapData,
    queryFn: () => Crimes.getMapData(),
    staleTime: 60 * 1000,
    refetchOnWindowFocus: false,
  });
}

export function useCrimeDashboardData(): UseQueryResult<ICrimeDashboard> {
  return useQuery({
    ...queries.crimes.dashboard,
    queryFn: () => Crimes.getDashboardData(),
    staleTime: 60 * 1000,
    refetchOnWindowFocus: false,
  });
}

export function useCrimeTopCrimesByArea(
  area: string,
  status: boolean
): UseQueryResult<ITopCrime[]> {
  return useQuery({
    ...queries.crimes.topCrimesByArea(area),
    queryFn: () => Crimes.getTopCrimesByArea(area),
    enabled: !!area && area.length > 0 && status,
    refetchOnWindowFocus: false,
  });
}

export function useReportedCrimes(): UseQueryResult<IReportedCrimes[]> {
  return useQuery({
    ...queries.crimes.getReportedCrime,
    queryFn: () => Crimes.getReportedCrime(),
    refetchOnWindowFocus: false,
  });
}

export function useReportedCrimeDetail(
  id: string | undefined
): UseQueryResult<IReportedCrimesDetails> {
  return useQuery({
    ...queries.crimes.getReportedCrimeDetails,
    queryFn: () => Crimes.getReportedCrimeDetails(id),
    refetchOnWindowFocus: false,
  });
}

export function usePostedCategory(
  category: string
): UseQueryResult<ITabItemCategory[]> {
  return useQuery({
    ...queries.crimes.getPostedCategory,
    queryFn: () => Crimes.getPostedCategory(category),
    refetchOnWindowFocus: false,
  });
}

export function useReviewCategory(
  category: string
): UseQueryResult<ITabItemCategory[]> {
  return useQuery({
    ...queries.crimes.getReviewCategory,
    queryFn: () => Crimes.getReviewCategory(category),
    refetchOnWindowFocus: false,
  });
}

export function useDraftedCategory(
  category: string
): UseQueryResult<ITabItemCategory[]> {
  return useQuery({
    ...queries.crimes.getDraftedCategory,
    queryFn: () => Crimes.getDraftedCategory(category),
    refetchOnWindowFocus: false,
  });
}

export function useTotalReview(): UseQueryResult<ITotalReview> {
  return useQuery({
    ...queries.crimes.getTotalReview,
    queryFn: () => Crimes.getTotalReview(),
    refetchOnWindowFocus: false,
  });
}

export function useReviewReportedCrime(): UseQueryResult<
  ITabItemReportedCrime[]
> {
  return useQuery({
    ...queries.crimes.getReviewReportedCrime,
    queryFn: () => Crimes.getReviewReportedCrime(),
    refetchOnWindowFocus: false,
  });
}

export function useDraftsReportedCrime(): UseQueryResult<
  ITabItemReportedCrime[]
> {
  return useQuery({
    ...queries.crimes.getDraftsReportedCrime,
    queryFn: () => Crimes.getDraftsReportedCrime(),
    refetchOnWindowFocus: false,
  });
}

export function usePostedReportedCrime(): UseQueryResult<
  ITabItemReportedCrime[]
> {
  return useQuery({
    ...queries.crimes.getPostedReportedCrime,
    queryFn: () => Crimes.getPostedReportedCrime(),
    refetchOnWindowFocus: false,
  });
}

export function useVerifyCrime() {
  const queryClient = useQueryClient();

  return useMutation(Crimes.verifyCrime, {
    meta: {
      conflict: "No Data",
      badRequest: "No Data",
    },
  });
}

// export function useDraftCrime() {
//   const queryClient = useQueryClient();

//   return useMutation(Crimes.draftCrime, {
//     meta: {
//       conflict: "No Data",
//       badRequest: "No Data",
//     },
//   });
// }

export function useDraftCrime() {
  return useMutation(
    async (reasonBody: IDraftReportParams) => {
      return Crimes.draftCrime(reasonBody);
    },
    {
      meta: {
        conflict: "Conflict found whilst making changes",
        badRequest: "Please make sure all fields are entered correctly.",
        notFound: "Crime could not be found. Please try again",
      },
    }
  );
}

export function useEditCrimeTypeCategory() {
  return useMutation(
    async (crimeType: IEditCrimeTypeCategory) =>
      Crimes.editCrimeTypeCategory(crimeType),
    {
      meta: {
        conflict: "Found whilst making changes",
        badRequest: "Please make sure all fields are entered correctly.",
        notFound: "Case could not be found. Please try again",
      },
    }
  );
}

export function useEditCrimeVehicleStatus() {
  return useMutation(
    async (crimeType: IEditCrimeVehicleStatus) =>
      Crimes.editCrimeVehicleStatus(crimeType),
    {
      meta: {
        conflict: "Found whilst making changes",
        badRequest: "Please make sure all fields are entered correctly.",
        notFound: "Case could not be found. Please try again",
      },
    }
  );
}

export function useGetCategoryAndTypes(): UseQueryResult<ICrimeCategory | any, any> {
  return useQuery({
    queryKey: ['getCategoryAndTypes'],
    queryFn: Crimes.fetchCrimeCategories,
    refetchInterval: 60000,
    refetchOnWindowFocus: true,
  });
}

import React, { useEffect, useState } from "react";
import HeaderComponent from "@/components/Header/Header";
import { Input, Spin, Tag } from "antd";
import ResponderOnlineTimeChart from "@/components/Charts/ResponderCharts/ResponderOnlineTimeChart";
import ResponderResponseTimeChart from "@/components/Charts/ResponderCharts/ResponderResponseTimeChart";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { Column } from "primereact/column";
import { tableHeader, tableName } from "@/styles/TableStyles";
import { tableBody } from "../ResponderCompany/styles";
import {
  useGetResponderDetailsCharts,
  useGetResponderDetailsTableData,
} from "@/hooks/useResponderAnalytics";
import { SearchOutlined } from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { ActionBodyText } from "@/styles/ContainerStyles";
import { Skeleton } from "primereact/skeleton";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import CustomInputField from "@/components/widgets/Inputs/CustomInputField";

type responderProp = {
  id?: string | undefined;
};
const ResponderDetails = () => {
  const navigate = useHistory();
  const { id } = useParams<responderProp>();
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");
  const [filters, setFilters] = useState<DataTableFilterMeta | undefined>(
    undefined
  );
  const {
    data: responderAnalyticsTableData,
    isLoading: isLoadingResponderAnalyticsTableData,
    isFetching: isFetchingResponderAnalyticsTableData,
    isError: isErrorResponderAnalyticsTableData,
  } = useGetResponderDetailsTableData(id as string);

  const {
    data: responderAnalyticsChartData,
    isLoading: isLoadingResponderAnalyticsChartData,
    isFetching: isFetchingResponderAnalyticsChartData,
    isError: isErrorResponderAnalyticsChartData,
  } = useGetResponderDetailsCharts(id as string);

  const onGlobalFilterChange = (e: string) => {
    const _filters = { ...filters };
    // @ts-ignore
    _filters.global.value = e;

    setFilters(_filters);
    setGlobalFilterValue(e);
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      vehicle: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      referenceNumber: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      responseTime: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },

      status: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      date: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
    });
    setGlobalFilterValue("");
  };

  const dateBodyTemplate = (rowData: IResponderHistory) => {
    return moment(rowData.date).format("YYYY/MM/DD");
  };

  const navigateToResponderReport = (id: string) => {
    navigate.push(`/home/responder-report/${id}`);
  };

  const actionBodyTemplate = (rowData: IResponderHistory) => {
    return (
      <ActionBodyText
        onClick={() =>
          navigateToResponderReport(rowData?.callOutId?.toString())
        }
        className="cursor-pointer"
      >
        View more
      </ActionBodyText>
    );
  };

  useEffect(() => {
    initFilters();
  }, [responderAnalyticsTableData]);

  return (
    <div className="flex w-full h-full bg-backgroundColor">
      <div className="w-full h-full max-h-screen overflow-y-auto disable-scrollbars">
        {isLoadingResponderAnalyticsTableData ||
        isFetchingResponderAnalyticsTableData ? (
          <>
            <Skeleton height="2rem" width="100%" />
          </>
        ) : (
          <>
            <HeaderComponent
              title={`${responderAnalyticsTableData?.responderFirstName} ${responderAnalyticsTableData?.responderLastName}`}
            />
          </>
        )}

        <div className="grid px-2 animate-fadeInUp animate-faster mb-3">
          <div className="col-12 md:col-6 animate-bounce animate-repeat-2">
            <div className="w-12">
              {isLoadingResponderAnalyticsChartData ||
              isFetchingResponderAnalyticsChartData ? (
                <div className="flex w-full h-80">
                  <Skeleton height="100%" width="100%" />
                </div>
              ) : (
                <>
                  <ResponderOnlineTimeChart
                    analytics={
                      responderAnalyticsChartData?.responderOnlineTimeChart
                    }
                    companyName={responderAnalyticsTableData?.responderCompany}
                  />
                </>
              )}
            </div>
          </div>
          <div className="col-12 md:col-6 animate-bounce animate-repeat-2">
            <div className="w-12">
              {isLoadingResponderAnalyticsChartData ||
              isFetchingResponderAnalyticsChartData ? (
                <div className="flex w-full h-80">
                  <Skeleton height="100%" width="100%" />
                </div>
              ) : (
                <>
                  <ResponderResponseTimeChart
                    analytics={
                      responderAnalyticsChartData?.responderResponseTimeChart
                    }
                    companyName={responderAnalyticsTableData?.responderCompany}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex w-full h-full pb-2">
          {isLoadingResponderAnalyticsTableData ||
          isFetchingResponderAnalyticsTableData ? (
            <Skeleton height="100%" width="100%" />
          ) : (
            <div className="flex flex-col w-full h-full px-3 py-3 card border-round shadow-3">
              <div className="flex flex-row gap-1 justify-content-between align-items-center mb-2 w-full">
                <div
                  className="flex flex-row align-items-center"
                  style={tableName}
                >
                  Call-out History
                </div>
                <div className="flex w-3/4 justify-end">
                  <CustomInputField
                    value={globalFilterValue}
                    onChange={(e: string) => onGlobalFilterChange(e)}
                    inputProps={{
                      size: "large",
                      placeholder: "Search",
                      allowClear: true,
                      className: "ml-2 w-3",
                      prefix: <SearchOutlined />,
                    }}
                    theme={CustomInputField}
                    mergeThemes
                  />
                </div>
              </div>
              <div className="flex w-full h-full">
                <DataTable
                  paginator
                  rows={10}
                  value={
                    Array.isArray(responderAnalyticsTableData?.callOutHistory)
                      ? responderAnalyticsTableData?.callOutHistory
                      : []
                  }
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  dataKey="id"
                  filters={filters}
                  globalFilterFields={[
                    "date",
                    "vehicle",
                    "referenceNumber",
                    "status",
                  ]}
                  emptyMessage="No responder call outs found."
                  rowHover
                  scrollable
                  scrollHeight="40rem"
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} operators"
                  stateStorage="session"
                  stateKey="dt-state-responder-Call-out-table"
                  className="mx-0 w-full h-full"
                >
                  <Column
                    field="date"
                    header="Date"
                    headerStyle={tableHeader}
                    bodyStyle={tableBody}
                    style={{ background: "#FBFBFB" }}
                    body={dateBodyTemplate}
                  />
                  <Column
                    field="vehicle"
                    header="Vehicle Information"
                    headerStyle={tableHeader}
                    bodyStyle={tableBody}
                    style={{ background: "#FBFBFB" }}
                  />
                  <Column
                    field="referenceNumber"
                    header="Panic Reference number"
                    headerStyle={tableHeader}
                    style={{ background: "#FBFBFB" }}
                    bodyStyle={tableBody}
                  />
                  <Column
                    field="responseTime"
                    header="Response Time"
                    headerStyle={tableHeader}
                    style={{ background: "#FBFBFB" }}
                    bodyStyle={tableBody}
                  />
                  <Column
                    field="status"
                    header="Status"
                    headerStyle={tableHeader}
                    style={{ background: "#FBFBFB" }}
                    bodyStyle={tableBody}
                  />
                  <Column
                    headerStyle={tableHeader}
                    style={{ background: "#FBFBFB" }}
                    bodyStyle={tableBody}
                    body={actionBodyTemplate}
                  />
                </DataTable>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResponderDetails;

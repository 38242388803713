import { useHistory } from "react-router-dom";
// eslint-disable-next-line import/no-anonymous-default-export
import BridgeLabsLogo from "./BridgeLabsIcon.png";
import armedResponse from "./images/armed_response.png";
import backgroundImage from "./images/login.svg";
import inBlue from "./images/inblue.png";
import road from "./images/road.png";
import medical from "./images/medical.png";
import GVB from "./images/GBV.png";
import userAvatar from "./images/userAvatar.png";
import userpro from "./images/userpro.png";
import SaferCityLogo from "./images/safer_city_logo.png";
import SaferCityLogo2 from "./images/safer_city_logo.svg";
import SaferCityLogoReversed from "./images/Safer_City_Reversed_Logo.svg";
import clipIcon from "./images/clip.png";
import micIcon from "./images/mic.png";
import googleMakerIcon from "./images/Safer_City_Maker_Icon.svg";

// Icons Imports here
import OcticonDashboard from "./icons/OcticonDashboard";
import Ambulance from "./icons/Ambulance";
import Headphones from "./icons/Headphones";
import LogOutIcon from "./icons/LogOutIcon";
import EOSloading from "./icons/Eosloading";
import EOSThreeDotsLoading from "./icons/EOSThreeDotsLoading";
import MoreVertical from "./icons/MoreVertical";
import SuccessIcon from "./icons/SuccessIcon";
import MessageIcon from "./icons/MessageIcon";
import ArrowUpward from "./icons/ArrowUpward";
import ArrowDownward from "./icons/ArrowDownward";
import UsersIcon from "./icons/UsersIcon";
import HomeIcon from "./icons/HomeIcon";
import WarningIcon from "./icons/WarningIcon";
import PencilIcon from "./icons/PencilIcon";
import CloseIcon from "./icons/CloseIcon";
import UploadIcon from "./icons/UploadIcon";
import AccountVoice from "./icons/AccountVoiceIcon";
import UserHistoryIcon from "./icons/UserHistoryIcon";
import Profile from "./icons/ProfileIcon";
import TrashBinIcon from "./icons/TrashBinIcon";
import AdminIcon from "./icons/AdminIcon";
import { CrimeIcon } from "./icons/CrimesIcon";
import { CommunityCode } from "./icons/ReferralCodeIcon";
import { StarIcon } from "./icons/StarIcon";
import ArrowTrendingUp from "./icons/ArrowTrendingUp";
import PersonIcon from "./icons/PersonIcon";
import ThumbsUpIcon from "./icons/ThumbsUpIcon";
import CommentIcon from "./icons/CommentIcon";
import { ThreeDotsVerticalIcon } from "./icons/ThreeDotsVerticalIcon";
import CompaniesIcon from "./icons/CompaniesIcon";
import GroupIcon from "./icons/GroupIcon";
import CashIcon from "./icons/CashIcon";
import BackIcon from "./icons/BackIcon";
import CalendarCancelIcon from "./icons/CalendarCancelIcon";
import CrossIcon from "./icons/CrossIcon";
import PictureIcon from "./icons/PictureIcon";
import VideoIcon from "./icons/VideoIcon";
import FeedIcon from "./icons/FeedIcon";
import CarouselIcon from "./icons/CarouselIcon";
import RefreshIcon from "./icons/RefreshIcons";
import MultipleUsersIcon from "./icons/MultipleUsers";
import ReceiptIcon from "./icons/ReceiptIcon";
import EmergencyAlarmIcon from "./icons/EmergencyAlarm";
import InformationIcon from "./icons/InformationIcon";
import LineGraphIcon from "./icons/LineGraphIcon";
import ResponderCompanyIcon from "./icons/ResponderCompanyIcon";
import { CPFResponderIcon } from "./icons/CPFResponderIcon";
import { EmergencyResponderIcon } from "./icons/EmergencyResponderIcon";
import { ControlRoomOperatorIcon } from "./icons/ControlRoomOperatorIcon";

export const Images = {
  BridgeLabsLogo,
  armedResponse,
  backgroundImage,
  inBlue,
  road,
  medical,
  GVB,
  userAvatar,
  userpro,
  SaferCityLogo,
  SaferCityLogo2,
  SaferCityLogoReversed,
  clipIcon,
  micIcon,
  googleMakerIcon,
};

export const CustomIcons = {
  OcticonDashboard,
  GroupIcon,
  CarouselIcon,
  Ambulance,
  Headphones,
  LogOutIcon,
  EOSloading,
  EOSThreeDotsLoading,
  MoreVertical,
  SuccessIcon,
  MessageIcon,
  ArrowUpward,
  ArrowDownward,
  UsersIcon,
  HomeIcon,
  WarningIcon,
  PencilIcon,
  CloseIcon,
  UploadIcon,
  AccountVoice,
  UserHistoryIcon,
  Profile,
  TrashBinIcon,
  AdminIcon,
  CrimeIcon,
  CommunityCode,
  StarIcon,
  ArrowTrendingUp,
  PersonIcon,
  ThumbsUpIcon,
  CommentIcon,
  ThreeDotsVerticalIcon,
  CompaniesIcon,
  CashIcon,
  BackIcon,
  CalendarCancelIcon,
  CrossIcon,
  PictureIcon,
  VideoIcon,
  FeedIcon,
  MultipleUsersIcon,
  RefreshIcon,
  ReceiptIcon,
  EmergencyAlarmIcon,
  InformationIcon,
  LineGraphIcon,
  ResponderCompanyIcon,
  CPFResponderIcon,
  EmergencyResponderIcon,
  ControlRoomOperatorIcon,
};

export { default as NotificationSound } from "./audio/sound-3.mp3";

export default Images;

import React, { useState, useEffect, useContext, SetStateAction } from "react";
import { Select } from "antd";
import { DateTime } from "luxon";
import { SearchOutlined } from "@ant-design/icons";
import Colors from "../../config/Colors";
import { SortSelect } from "./styles";
import "./messagePanel.css";
import AuthContext from "../../contexts/AuthContext";
import CommunityMessagePreviewCard from "../Cards/CommunityMessagePreviewCard/CommunityMessagePreviewCard";
import CustomInputField from "../widgets/Inputs/CustomInputField";
import { Dispatcher } from "../../util/helpers";

const _ = require("lodash");

type CommunityChatPanelProps = {
  cases: ICommunityAdminCase[];
  setCurrentCase: Dispatcher<ICommunityAdminCase | null>;
};

const { Option } = Select;

export default function CommunityChatPanel({
  cases,
  setCurrentCase,
}: CommunityChatPanelProps) {
  const { user } = useContext(AuthContext);
  const [searchText, setSearchText] = useState("");
  const [sortOption, setSortOption] = useState("newest");
  const [openCases, setOpenCases] = useState<ICommunityAdminCase[]>(cases);

  const onSortChange = (value: any) => {
    setSortOption(value);
  };

  const handleOnClick = (value: ICommunityAdminCase) => {
    setCurrentCase(value);
  };

  const customTheme = {
    token: {
      colorPrimaryHover: `${Colors.appMainColor}`,
    },
  };

  useEffect(() => {
    let tempCases = cases;
    if (searchText) {
      tempCases = tempCases.filter((c) =>
        c.user.firstName.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    let sortedCases: ICommunityAdminCase[] = [...tempCases];

    if (sortOption) {
      if (sortOption === "newest") {
        sortedCases = _.sortBy(sortedCases, (c: ICommunityAdminCase) =>
          DateTime.fromISO(c.createdAt, {
            zone: "utc",
          }).toMillis()
        ).reverse();
      } else if (sortOption === "name") {
        sortedCases = _.sortBy(
          sortedCases,
          (c: IOperatorEmergencyCase) => c.user.firstName
        );
      }
    }

    setOpenCases(sortedCases);
  }, [searchText, sortOption, cases]);
  return (
    <div
      className="flex flex-col w-full h-full px-1 overflow-y-auto disable-scrollbars"
      style={{
        background: `${Colors.backgroundColor}`,
        borderRight: "1px solid #f0f0f0",
      }}
    >
      {/* TItle Here */}
      <p className="pt-1 text-3xl font-bold text-left text-mainTextColor">
        Messages
      </p>
      {/* Search & Sort Here */}
      <div className="flex justify-start w-full messagePanel-search">
        <CustomInputField
          value={searchText}
          onChange={(e: string) => setSearchText(e)}
          onPressEnter={() => console.log("hit press")}
          inputProps={{
            size: "large",
            placeholder: "Enter name here",
            allowClear: true,
            className: "ml-1 w-3/4",
            prefix: <SearchOutlined />,
          }}
          theme={customTheme}
          mergeThemes
        />
      </div>
      <div className="flex justify-start w-full px-1 py-3 messagePanel-sortable">
        <div className="flex flex-row items-center gap-1">
          <span className="text-sm">Sort by</span>
          <SortSelect
            placeholder="Newest"
            optionFilterProp="children"
            onChange={onSortChange}
            bordered={false}
          >
            <Option value="newest" style={{ color: `${Colors.appMainColor}` }}>
              Newest
            </Option>
            <Option value="name" style={{ color: `${Colors.appMainColor}` }}>
              Name
            </Option>
          </SortSelect>
        </div>
      </div>
      {/* Message Preview Here */}
      <div className="animate-fadeInUpBig">
        {openCases.map((c) => (
          <button
            type="button"
            className="w-full hover:bg-gray-200 hover:rounded-omd"
            key={c.id}
            onClick={() => handleOnClick(c)}
          >
            <CommunityMessagePreviewCard caseItem={c} key={c.id} />
          </button>
        ))}
      </div>

      {openCases.length <= 0 && (
        <div className="flex items-center justify-center w-full h-full text-lg font-bold animate-bounceInUp">
          No Message
        </div>
      )}
    </div>
  );
}

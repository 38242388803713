import React, { FC, CSSProperties } from "react";

interface TruncateTextBlockProps {
  limit: number;
  children: string;
  className?: string;
  style?: CSSProperties;
}

const TruncateTextBlock: FC<TruncateTextBlockProps> = ({
  limit,
  children,
  className,
  style,
}) => {
  const truncate = (str: string, n: number) =>
    str.length > n ? `${str.slice(0, n - 1)}...` : str;
  return (
    <div className={className} style={style}>
      {truncate(children, limit)}
    </div>
  );
};

export default TruncateTextBlock;

import React from "react";
import "./App.css";
import { ConfigProvider, message, App as AntdApp } from "antd";
import {
  QueryClient,
  QueryClientProvider,
  QueryCache,
  MutationCache,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AxiosError } from "axios";
import { Toaster } from "sonner";
import toast, { Toaster as HotToaster } from "react-hot-toast";
import Router from "./route";

import "antd/dist/reset.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import Colors from "./config/Colors";
import { ModalProvider } from "./providers/ModalProvider";

// Create a client
const queryClient = new QueryClient({
  queryCache: new QueryCache({
    defaultOptions: {
      queries: {
        cacheTime: 1000 * 60 * 60 * 12, // 12 hours
      },
    },
    onError: (error, query) => {
      // 🎉 only show error toasts if we already have data in the cache
      // which indicates a failed background update
      // message.error(`Something went wrong: ${error.message}`);
      // Check if data is null, undefined, or an empty object
      if (error) {
        if (
          query.state.data === null ||
          query.state.data === undefined ||
          Object.keys(query.state.data).length === 0
        ) {
          if (error.message) {
            toast.error(`Something went wrong: ${error.message}`);
          } else if (error instanceof AxiosError) {
            toast.error(
              `Something went wrong: ${error.response?.data?.message}`
            );
          } else {
            toast.error(`Something went wrong`);
          }
        }
      }
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, variables, context, mutation) => {
      if (mutation.meta?.useError && mutation.meta?.useError === true) {
      } else if (error instanceof AxiosError) {
        // switch case error status
        switch (error.response?.status) {
          case 400:
            toast.error(`Bad request: ${mutation.meta?.badRequest}`);
            break;
          case 409:
            toast.error(`Conflict: ${mutation.meta?.conflict}`);
            break;
          case 404:
            toast.error(
              `Error 404: ${
                mutation.meta?.notFound
                  ? mutation.meta?.notFound
                  : "It seems the resource you're seeking is not in our system. Please check your request and try again"
              } `
            );
            break;
          case 500:
            toast.error(
              "Unfortunately, a server error has occurred. Please try again."
            );
            break;
          default:
            toast.error(`Something went wrong: ${error.message}`);
        }
      } else {
        toast.error(`Something went wrong: ${error.message}`);
      }
    },
  }),
});

function App() {
  const [contextHolder] = message.useMessage();
  return (
    <AntdApp>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider
          theme={{
            token: {
              // primary
              colorPrimary: Colors.appMainColor,
              colorPrimaryText: Colors.appMainColor,
              //colorBorder: Colors.appMainColor,
              colorPrimaryBg: Colors.secondaryColor,
              colorPrimaryBorder: Colors.appMainColor,
              colorPrimaryHover: Colors.appMainColor,
              // Error
              colorError: Colors.errorColor,
              colorErrorText: Colors.errorColor,
              colorErrorBg: Colors.backgroundErrorColor,
              // Warning
              colorWarning: Colors.warningColor,
              colorWarningText: Colors.warningColor,
              colorWarningBg: Colors.backgroundWarningColor,
              // MASk
              colorBgMask: `rgba(153, 199, 235, 0.5)`,
              // Success
              colorSuccess: Colors.successColor,
              colorSuccessText: Colors.successColor,
              colorSuccessBg: Colors.backgroundSuccessColor,
              // Text
              colorText: Colors.mainTextColor,
              colorTextDescription: Colors.subtleTextColor,
              colorTextHeading: Colors.mainTextColor,
              colorTextPlaceholder: Colors.subtleTextColor,
              colorTextLabel: Colors.mainTextColor,

              // background
              colorBgTextHover: "transparent",
            },
            // components: {
            //   Select: {
            //     optionSelectedBg: Colors.appMainColor,
            //     optionActiveBg: Colors.appMainColor,
            //     multipleItemBg: Colors.appMainColor,
            //   },
            // },
          }}
        >
          <div className="App">
            <Toaster richColors position="top-center" />
            <HotToaster />
            <Router />
            <ModalProvider />
          </div>
        </ConfigProvider>
        <ReactQueryDevtools initialIsOpen={false} position="top-center" />
      </QueryClientProvider>
    </AntdApp>
  );
}

export default App;

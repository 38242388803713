/* eslint-disable comma-dangle */
/* eslint-disable function-paren-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable  implicit-arrow-linebreak */
import styled from "styled-components";
import Colors from "../../config/Colors";

export const WINDOW_WIDTH = window.innerWidth;
export const WINDOW_HEIGHT = window.innerHeight;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: WINDOW_WIDTH;
  height: WINDOW_HEIGHT;
  color: black;
  background: ${Colors.backgroundColor};
`;

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const BodyContainer = styled.div`
  justify-content: space-between;
  p {
    font-weight: bold;
  }
  overflow-y: auto;
  width: 90%;
  max-height: 100vh;
`;

export const InnerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;

  .card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    grid-gap: 20px;
    width: 100%;
    padding: 20px;
    margin-top: 50px;
    border: 0px solid #fff;
  }
`;

export const SubmitButton = styled.button`
  width: 120px;
  height: 40px;
  border: none;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SubHeading = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  align-items: center;
  color: #515151;
`;

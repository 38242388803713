import React, { useEffect } from "react";
import { Button, DatePicker, Form, Input, message, Select, Spin } from "antd";
import TextArea from "antd/es/input/TextArea";

type CPFRepsonderStatusProp = {
  editResponderValues?: ICPFResponderStatus;
  onFormFinish: (cpfResponder: ICPFResponderDetails) => Promise<void>;
  onModalClose: () => void;
  isLoading: boolean;
  isSuccess: boolean;
  isUpdating: boolean;
};
const CPFStatus = ({
  editResponderValues,
  onFormFinish,
  onModalClose,
  isLoading,
  isSuccess,
  isUpdating,
}: CPFRepsonderStatusProp) => {
  const onFinish = async (formValues: any) => {
    const values: ICPFResponderDetails = {
      isActive: formValues.isActive,
      reason: formValues.reason,
    };
    onFormFinish(values);
    form.resetFields();
  };

  const handleClose = () => {
    onModalClose();
    form.resetFields();
  };

  useEffect(() => {
    if (editResponderValues != null) {
      form.setFieldsValue(editResponderValues);
    }
  }, [editResponderValues]);

  useEffect(() => {
    if (isSuccess) {
      onModalClose();

      form.resetFields();
    }
  }, [isSuccess]);

  const [form] = Form.useForm();

  return (
    <>
      <Spin spinning={isLoading}>
        <Form
          form={form}
          name="editCPFResponderStatus"
          onFinish={onFinish}
          layout="vertical"
          style={{ width: "100%" }}
        >
          <div className="flex w-full">
            <Form.Item
              name="isActive"
              label="Status"
              rules={[
                {
                  required: true,
                  message: "Please select the CPF Repsonder status!",
                },
              ]}
              className="mb-3 w-2/4"
            >
              <Select placeholder="Select CPF Status" size="large">
                <Select.Option value={true}>Active</Select.Option>
                <Select.Option value={false}>Suspended</Select.Option>
              </Select>
            </Form.Item>
          </div>

          <div className="flex w-full">
            <Form.Item
              name="reason"
              label="Reason"
              rules={[
                {
                  required: true,
                  message: "Please enter a reason for updating",
                },
              ]}
              className="mb-1 w-full"
            >
              <TextArea
                className="flex w-full"
                style={{ height: 120, resize: "none" }}
              />
            </Form.Item>
          </div>

          <Form.Item className="w-12 mb-1">
            <div className="flex w-full gap-2 col-12 justify-content-end align-content-center">
              <Button
                type="primary"
                ghost
                onClick={handleClose}
                size="large"
                className="w-3"
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={isUpdating}
                size="large"
                className="w-3 bg-[#1757a2] shadow-none"
              >
                Save
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};

export default CPFStatus;

import { Avatar, Button, Form, Input, Select, message } from "antd";
import Dragger from "antd/es/upload/Dragger";
import React, { useEffect, useState } from "react";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { uploadUrl } from "@/config/urls";
import {
  useUnusedAllCommunityCodes,
  useUnusedAllReferralCode,
} from "@/hooks/useCommunityCodes";
import {
  clickHereDraggerStyles,
  headerDraggerStyles,
  subtitleDraggerStyles,
} from "./styles";
import Colors from "../../config/Colors";
import { CustomIcons } from "../../assets";
import { useDeactivateCompany } from "../../hooks/useCompanies";
import GenericDialog from "../Dialog/GenericDialog";

type editCompanyProp = {
  editCompanyValues?: IEditCompany;
  onFormFinish: (company: IEditCompany) => Promise<void>;
  onModalClose: () => void;
  isLoading: boolean;
  isSuccess: boolean;
};

const EditCompany = ({
  editCompanyValues,
  onFormFinish,
  onModalClose,
  isLoading,
  isSuccess,
}: editCompanyProp) => {
  const [form] = Form.useForm();
  const deactivateCompany = useDeactivateCompany();
  const [imageUrl, setImageUrl] = useState("");
  const communityCodes = useUnusedAllCommunityCodes();
  const referralCodes = useUnusedAllReferralCode();
  const [uploading, setUploading] = useState<boolean>();
  const [communtiyCode, setCommunityCode] = useState<string>("");
  const [referralCode, setReferralCode] = useState<string>("");

  const handleClose = () => {
    form.resetFields();
    setImageUrl("");
    setUploading(false);
    onModalClose();
  };

  const onFinish = async (formValues: IEditCompany) => {
    const values: IEditCompany = formValues as IEditCompany;
    values.companyId = editCompanyValues?.companyId;
    if (communtiyCode != "") {
      values.communityCode = communtiyCode;
    }

    if (referralCode != "") {
      values.referralCode = referralCode;
    }

    await onFormFinish(values);
    handleClose();
  };

  const handleChangeCommunityCode = (value: string) => {
    setCommunityCode(value);
  };

  const handleChangeReferralCode = (value: string) => {
    setReferralCode(value);
  };

  const filterOption = (
    input: string,
    option?: { id?: number; label: string; value: string } | any
  ) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase());

  // For Uploading images to azure
  const props: UploadProps = {
    name: "file",
    multiple: false,
    action: uploadUrl,
    onChange(info) {
      const { status } = info.file;
      if (status === "uploading") {
        setUploading(true);
      }
      if (status === "done") {
        form.setFieldsValue({ companyImage: info.file.response.path });
        setImageUrl(info.file.response.path);
        message.success(`${info.file.name} file uploaded successfully.`);
        setUploading(false);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
        setUploading(true);
      }
    },
    onDrop(e) {
      // console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const beforeUpload = (file: RcFile, fileList: UploadFile[]) => {
    const isValidFile =
      file.type === "image/jpeg" ||
      "image/png" ||
      "image/svg+xml" ||
      "image/gif";
    if (!isValidFile) {
      message.error("You can only upload JPG/PNG/SVG/GIF file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error("Image must smaller than 10MB!");
    }
    return isValidFile && isLt2M;
  };

  useEffect(() => {
    if (editCompanyValues) {
      form.setFieldsValue(editCompanyValues);
      if (editCompanyValues.companyImage) {
        setImageUrl(editCompanyValues.companyImage);
      }
    } else {
      form.resetFields();
    }
  }, [form]);

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
    }
  }, [isSuccess]);
  return (
    <div>
      <Form
        form={form}
        name="editCompany"
        layout="vertical"
        onFinish={onFinish}
        initialValues={editCompanyValues}
      >
        <div className="col-12">
          <Form.Item
            name="companyName"
            label="Name of Company"
            rules={[{ required: true, message: "Please enter company name!" }]}
            className="mb-1"
          >
            <Input size="large" placeholder="ABC Ltd" />
          </Form.Item>
        </div>

        <div className="col-12">
          {editCompanyValues?.communityCode ? (
            <Form.Item label="Commuity Code" className="mb-1">
              <Input
                size="large"
                value={editCompanyValues.communityCode}
                readOnly
              />
            </Form.Item>
          ) : (
            <Form.Item label="Community Code" className="mb-1">
              <Select
                showSearch
                size="large"
                filterOption={filterOption}
                placeholder="Search to select a Community Code"
                onChange={handleChangeCommunityCode}
                loading={communityCodes.isLoading}
              >
                {communityCodes.data?.map((item: IAllCommunityCode) => (
                  <Select.Option
                    key={`key-community-code-${item.id}`}
                    value={item.code}
                  >
                    {item.code}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </div>

        <div className="col-12">
          {editCompanyValues?.referralCode ? (
            <Form.Item label="Referral Code" className="mb-1">
              <Input
                size="large"
                value={editCompanyValues.referralCode}
                readOnly
              />
            </Form.Item>
          ) : (
            <Form.Item label="Referral Code" className="mb-1">
              <Select
                showSearch
                size="large"
                filterOption={filterOption}
                placeholder="Search to select a Referral Code"
                onChange={handleChangeReferralCode}
                loading={referralCodes.isLoading}
              >
                {referralCodes.data?.map((item: IAllAffiliationCode) => (
                  <Select.Option
                    key={`key-referral-code-${item.id}`}
                    value={item.code}
                  >
                    {item.code}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </div>

        <Form.Item
          name="companyImage"
          label="Icon"
          rules={[{ required: true, message: "Please enter company icon!" }]}
          className="mb-5 col-12"
        >
          <div className="flex w-full gap-6 flx-row justify-content-start">
            {imageUrl.length > 0 ? (
              <Avatar
                shape="square"
                size={64}
                alt="emergency icon"
                src={imageUrl}
              />
            ) : (
              <Avatar shape="square" size={64} alt="company icon" />
            )}
            <Dragger
              {...props}
              beforeUpload={beforeUpload}
              className="w-full"
              style={{ background: "transparent" }}
              accept=".svg, .png, .jpg, .gif"
            >
              <div className="flex flex-col items-center content-center w-full h-full gap-3">
                <CustomIcons.UploadIcon
                  width={46}
                  height={46}
                  svgColor={Colors.appMainColor}
                />
                <div className="flex gap-1 justify-content-center">
                  <div className="text-center" style={headerDraggerStyles}>
                    Drop your icon here or to upload
                  </div>
                  <div style={clickHereDraggerStyles}>click here</div>
                  <div className="text-center" style={headerDraggerStyles}>
                    to upload
                  </div>
                </div>

                <div style={subtitleDraggerStyles}>
                  File should be SVG, PNG,JPG or GIF (max 800x400px){" "}
                </div>
              </div>
            </Dragger>
          </div>
        </Form.Item>
        <Form.Item className="w-12 pt-2 mb-1">
          <div className="flex w-full gap-2 col-12 justify-content-end align-content-center">
            <Button
              onClick={handleClose}
              type="primary"
              ghost
              size="large"
              className="w-7"
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              loading={isLoading}
              className="w-7 bg-[#1757a2] shadow-none"
              disabled={uploading}
            >
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditCompany;

import styled from "styled-components";
import Colors from "../config/Colors";

const Body = styled.main`
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0px;
  display: flex;

  .column {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  #left {
    flex-shrink: 0;
    background-color: white;
  }
  #right {
    width: 100%;
    flex-shrink: 0;
  }
  .top-left {
    flex-shrink: 0;
    background-color: white;
    color: ${Colors.appMainColor};
    font-size: 24px;
    font-weight: bold;
    padding: 20px;
  }
  .top-right {
    display: flex;
    color: ${Colors.appMainColor};
    flex-shrink: 0;
    background-color: #333;
    padding: 10px;
  }
  .bottom {
    flex-grow: 1;
    overflow-y: auto;
  }

  // Without a top right
  .right-full {
    flex-grow: 1;
    overflow-y: auto;
  }
  ul {
    display: inline-flex;
    list-style: none;
    margin: 0;
  }
  li {
    margin-right: 20px;
  }
`;

export default Body;

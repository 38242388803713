/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Divider,
  Dropdown,
  MenuProps,
  message,
  Modal,
  Select,
  Spin,
} from "antd";
import InfiniteScroll from "react-infinite-scroller";
import { useInfiniteQuery } from "@tanstack/react-query";
import HeaderComponent from "../../components/Header/Header";
import CommunityReportedPost from "../../components/Cards/CommunityCard/CommunityReportedPost";
import CommunityFeedCard from "../../components/Cards/CommunityCard/CommunityFeedCard";
import Communities from "../../services/Communities";
import AuthContext from "../../contexts/AuthContext";
import { CustomIcons } from "../../assets";
import CommunityPostTabs from "../../components/Tabs/Community/Feed/CommunityPostTabs";
import { SubHeading } from "./styles";
import SkeletonComment from "../../components/widgets/skeletons/SkeletonComment";
import CommentCard from "../../components/Cards/CommentCard/CommentCard";
import Colors from "../../config/Colors";

const ContainerHeight = 900;

export default function CommunityHome() {
  const { getCommunityId } = useContext(AuthContext);
  const { getCommunityName } = useContext(AuthContext);
  const { user } = useContext(AuthContext);
  const prevPagesLengthRef = useRef<null | number>(null);
  const [openCommunityPostModal, setOpenCommunityPostModal] = useState(false);
  const communityId = getCommunityId();
  const communityName = getCommunityName();
  // const [orderBy, setOrderBy] = useState<string>("recent");

  let orderBy: string = "recent";
  const userId = user?.id;

  const limit = 10;

  const {
    data: QueryDataFeed,
    fetchNextPage: fetchNextPageFeed,
    hasNextPage: hasNextPageFeed,
    isLoading: isLoadingFeed,
    isFetching: isFetchingFeed,
    isError: isErrorFeed,
    error: errorFeed,
    refetch: refetchFeed,
  } = useInfiniteQuery(
    ["getCommunityFeed", communityId], // unique key
    ({ pageParam = 0 }) =>
      Communities.getCommunityFeed(communityId, limit, pageParam, orderBy), // query function
    {
      getNextPageParam: (lastPage: ICommunityFeedResponse) =>
        lastPage.hasMore
          ? Math.ceil((lastPage.skip + limit) / limit)
          : undefined, // Use the length of the last page as the next page parameter
    }
  );

  const {
    data: QueryDataReportedPosts,
    fetchNextPage: fetchNextReportedPostsPage,
    hasNextPage: reportedPostsHasNextPage,
    isLoading: reportedPostsIsLoading,
    isFetching: reportedPostsIsFetching,
    isError: reportedPostsIsError,
    error: reportedPostsError,
    refetch: refetchReportedPosts,
  } = useInfiniteQuery(
    ["getReportedPosts", communityId], // unique key
    ({ pageParam = 0 }) =>
      Communities.getReportedPosts(communityId, limit, pageParam), // query function
    {
      getNextPageParam: (lastPage) =>
        lastPage.hasMore ? lastPage.skip / 10 + 1 : undefined, // Use the length of the last page as the next page parameter
    }
  );

  const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    if (
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
      ContainerHeight
    ) {
      if (hasNextPageFeed) {
        fetchNextPageFeed();
      } else {
        // setShowNothing(true);
        message.info("That's all, nothing more 🤐");
      }
    }
  };

  const RefreshFeed = () => {
    refetchFeed();
  };
  // const getTimeDifff=()=>{
  //   const today = new Date();
  //   today.setHours(0, 0, 0, 0);

  const RefreshReportedPosts = () => {
    refetchReportedPosts();
  };

  const handleChange = (value: string) => {
    orderBy = value;
    refetchFeed();
  };

  const renderNothing = () => {
    return <Divider plain>That&apos;s all, nothing more 🤐</Divider>;
  };

  const loadFuncFeed = () => {
    if (hasNextPageFeed) {
      fetchNextPageFeed();
    } else {
      message.info("That's all, nothing more 🤐");
    }
  };

  const loadFuncReportedPosts = () => {
    if (reportedPostsHasNextPage) {
      fetchNextReportedPostsPage();
    } else {
      message.info("That's all, nothing more 🤐");
    }
  };

  const flattenedFeed = QueryDataFeed?.pages.flatMap((page) => page.feed) || [];

  const flattenedReportedPosts =
    QueryDataReportedPosts?.pages.flatMap((page) => page.reports) || [];

  return (
    <div className="flex w-full h-full bg-backgroundColor">
      <div className="w-full h-full max-h-screen overflow-y-auto disable-scrollbars">
        <HeaderComponent title="Community feed" />
        <div className="flex w-full h-full px-3">
          {/* Community Feed Cards */}
          <div className="flex flex-col items-start justify-center w-1/3 h-full">
            <div className="flex flex-row w-full pt-3">
              <p className="flex justify-start mb-1 text-xl">
                Reported Posts(
                <span className="text-errorColor">
                  {QueryDataReportedPosts?.pages[0].total}
                </span>
                )
              </p>
            </div>
            <div className="w-full h-full pt-2 pr-3">
              {/* Community Reported Posts */}
              <Spin tip="" spinning={reportedPostsIsLoading} delay={500}>
                <div
                  style={{ maxHeight: "95vh" }}
                  className="flex-grow pt-1 my-2 overflow-y-auto disable-scrollbars"
                >
                  <InfiniteScroll
                    pageStart={0}
                    loadMore={loadFuncReportedPosts}
                    hasMore={
                      QueryDataReportedPosts?.pages[
                        QueryDataReportedPosts.pages.length - 1
                      ].hasMore || false
                    }
                    loader={
                      <div className="flex justify-center pb-2" key={0}>
                        <CustomIcons.EOSloading
                          width={60}
                          height={60}
                          svgColor={Colors.appMainColor}
                        />
                      </div>
                    }
                    useWindow={false}
                  >
                    {flattenedReportedPosts.map((item, index) => (
                      <div className="px-1 py-2" key={item?.id}>
                        <CommunityReportedPost card={item} key={item.id} />
                      </div>
                    ))}
                  </InfiniteScroll>
                </div>
              </Spin>
            </div>
          </div>

          {/* Community Feed */}
          <div className="flex items-start justify-center w-2/3 h-full">
            <div className="w-full px-6">
              <Spin tip="" spinning={isLoadingFeed}>
                <div className="w-full h-full">
                  <button
                    aria-label="Create a post"
                    className="flex flex-row items-center flex-1 w-full h-12 gap-2 px-3 py-1 shadow-5 surface-card border-round"
                    onClick={() => setOpenCommunityPostModal(true)}
                    type="button"
                  >
                    <img
                      src={require("../../assets/images/SaferCity.png")}
                      height={50}
                      width={40}
                    />
                    <div className="flex items-center justify-start w-full pl-3 rounded-omd h-9 bg-[#F5F5F5]">
                      Create a post
                    </div>
                  </button>
                  <div className="flex justify-end w-full mt-3">
                    <Select
                      defaultValue="recent"
                      placeholder="Operator's type"
                      size="large"
                      onChange={handleChange}
                      style={{ width: 120 }}
                    >
                      <Select.Option value="recent">Recent</Select.Option>
                      <Select.Option value="old">Old</Select.Option>
                    </Select>
                  </div>
                  <div
                    style={{ maxHeight: "95vh" }}
                    className="flex-grow my-2 overflow-y-auto disable-scrollbars"
                  >
                    <InfiniteScroll
                      pageStart={0}
                      loadMore={loadFuncFeed}
                      hasMore={
                        QueryDataFeed?.pages[QueryDataFeed.pages.length - 1]
                          .hasMore || false
                      }
                      loader={
                        <div className="flex justify-center pb-2" key={0}>
                          <CustomIcons.EOSloading
                            width={60}
                            height={60}
                            svgColor={Colors.appMainColor}
                          />
                        </div>
                      }
                      useWindow={false}
                    >
                      {flattenedFeed.map((item, index) => (
                        <div className="px-1 py-2" key={item?.id}>
                          <CommunityFeedCard card={item} key={item?.id} />
                        </div>
                      ))}
                    </InfiniteScroll>
                  </div>
                  {/* {showNothing && <>{renderNothing()}</>} */}
                </div>
              </Spin>
            </div>
          </div>
        </div>
      </div>
      <Modal
        centered
        open={openCommunityPostModal}
        onOk={() => setOpenCommunityPostModal(false)}
        onCancel={() => setOpenCommunityPostModal(false)}
        width={550}
        closeIcon={
          <CustomIcons.CloseIcon
            width={30}
            height={30}
            viewBox={null}
            svgColor="#1C274C"
          />
        }
        footer={null}
      >
        <div className="flex items-center w-full">
          <img
            src={require("../../assets/images/SaferCity.png")}
            height={50}
            width={40}
            alt="SAPS Badge"
          />
          <SubHeading className="pt-3 ml-2">{communityName} CPF</SubHeading>
        </div>

        <CommunityPostTabs
          communityId={communityId}
          userId={userId}
          setOpenCommunityPostModal={() => {
            setOpenCommunityPostModal(false);
          }}
          refreshPage={() => RefreshFeed()}
        />
      </Modal>
    </div>
  );
}

/* eslint-disable no-underscore-dangle */
import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { queries } from "../services/queries";
import { SalesIQ } from "../services";

export function useSalesIQAll(): UseQueryResult<ISalesIQTable[]> {
  return useQuery({
    ...queries.salesIQ.all,
    queryFn: () => SalesIQ.getAll(),
    refetchOnWindowFocus: false,
  });
}

export function useUserSalesIQ(
  userId: string | number | null
): UseQueryResult<ISalesIQUser> {
  return useQuery({
    ...queries.salesIQ.getById(userId),
    enabled: !!userId && Number(userId) > 0,
    refetchOnWindowFocus: false,
    meta: {
      conflict: "Found whilst making changes",
      badRequest: "Please make sure all fields are entered correctly.",
      notFound: "User does not have a subscription",
    },
  });
}

/* eslint-disable no-unused-vars */
import React from "react";
import Home from "./Home/Home";
import { Layouts } from "../util/enums";
import { CustomIcons } from "../assets";
import Colors from "../config/Colors";
import OperatorHome from "./Home/OperatorHome";
import Emergencies from "./Emergencies/Emergencies";
import Users from "./Users/Users";
import EmergencyOperators from "./EmergencyOperators/EmergencyOperators";
import Messaging from "./Messaging/Messaging";
import UserHistory from "./UserHistory/UserHistory";
import Profile from "./Profile/Profile";
import Admins from "./Admins/Admins";
import CrimeDashboard from "./CrimeReport/CrimeDashboard";
import CommunityCodes from "./CommunityCode/CommunityCodes";
import CrimeReportDetail from "./CrimeReport/CrimeReportDetail";
import ReportedCrimes from "./CrimeReport/ReportedCrimes";
import { OperatorAnalytics } from "./OperatorAnalytics/OperatorAnalytics";
import AccountVoice from "../assets/icons/AccountVoiceIcon";
import OperatorTable from "./EmergencyOperators/OperatorTable";
import AddUsers from "./AddUsers/AddUsers";
import DetailedOperator from "./DetailedOperator/DetailedOperator";
import SalesIQ from "./SalesIQ/SalesIQ";
import UserChatHistoryTable from "./Users/UserChatHistoryTable";
import Companies from "./Companies/Companies";
import DeactivatedCompanies from "./Companies/DeactivatedCompanies";
import ReviewCrimes from "./CrimeReport/TabItemsReportedCrimes";
import TabItemsReportedCrimes from "./CrimeReport/TabItemsReportedCrimes";
import ReviewCategory from "./CrimeReport/Components/ReviewCategory";
import PostedCategory from "./CrimeReport/Components/PostedCategory";
import DraftsCategory from "./CrimeReport/Components/DraftsCategory";
import UserProfile from "./Users/ViewUserProfile";
import AllCommunities from "./Communities/AllCommunities";
import CommunityHome from "./Home/CommunityHome";
import PostApplications from "./PostApplications/PostApplication";
import PostApplicationDetail from "./PostApplications/PostApplicationDetail";
import CommunityPost from "./CommunityPost/CommunityPost";
import DeleteReportedPost from "./Communities/DeleteReportedPost";
import SaferCityCommunityFeed from "./Communities/SaferCityCommunityFeed";
import CommunityChat from "./CommunityChat/CommunityChat";
import SaferCityCommunityPost from "./Communities/SaferCityCommunityPost";
import CarouselManager from "./Carousel/CarouselManager";
import AffliationCode from "./CommunityCode/AffiliationCode";
import MultipleUsersIcon from "../assets/icons/MultipleUsers";
import ProofOfPayment from "./Companies/ProofOfPayment";
import CompanyMembers from "./Companies/CompanyMembers";
import ResponderCompany from "./ResponderCompany/ResponderCompany";
import Responders from "./Responders/Responders";
import ControlRoomOperators from "./ControlRoomOperators/ControlRoomOperators";
import ResponderCompanyVehicles from "./ResponderVehicles/ResponderCompanyVehicle";
import ResponderAnalytics from "./Responder Analytics/ResponderAnalytics";

import ResponderCPFs from "./ResponderCPF/ResponderCPFs";
import ResponderReport from "./Responder Analytics/ResponderReport";
import ResponderDetails from "./Responder Analytics/ResponderDetails";
import AllResponders from "./Responder Analytics/AllResponders";

import { isProdEnvironment } from "@/constants/siteSettings";

export interface IRouteProps {
  component: React.FunctionComponent<any>;
}

export const homePages: PageRoute[] = [
  {
    path: "/dashboard",
    name: "Home",
    exact: true,
    icon: (
      <CustomIcons.HomeIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.HomeIcon,
    component: () => <Home />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },
  {
    path: "/operator-dashboard",
    name: "Dashboard",
    exact: true,
    icon: (
      <CustomIcons.HomeIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.HomeIcon,
    component: () => <OperatorHome />,
    layout: Layouts.Home,
    platformRights: ["EmergencyOperator", "EmergencyAdmin"],
  },
  {
    path: "/admin",
    name: "Admin",
    exact: true,
    icon: (
      <CustomIcons.AdminIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.AdminIcon,
    component: () => <Admins />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },
  {
    path: "/sales-iq",
    name: "Sales IQ",
    exact: true,
    icon: (
      <CustomIcons.ArrowTrendingUp
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.ArrowTrendingUp,
    component: () => <SalesIQ />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },
  {
    path: "/affiliation-code",
    name: "Affiliation",
    exact: true,
    icon: (
      <CustomIcons.CommunityCode
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.CommunityCode,
    component: () => <AffliationCode />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },

  {
    path: "/referral-code",
    name: "Referral",
    exact: true,
    hideFromSidebar: true,
    icon: (
      <CustomIcons.CommunityCode
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.CommunityCode,
    component: () => <CommunityCodes />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },
  {
    path: "/add-users",
    name: "Users",
    exact: true,
    hideFromSidebar: true,
    icon: (
      <CustomIcons.CommunityCode
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.UsersIcon,
    component: () => <AddUsers />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },
  {
    path: "/users",
    name: "Users",
    exact: true,
    icon: (
      <CustomIcons.UsersIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.UsersIcon,
    component: () => <Users />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },
  {
    path: "/community-feed",
    name: "Feed",
    exact: true,
    icon: (
      <CustomIcons.HomeIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.FeedIcon,
    component: () => <SaferCityCommunityFeed />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },
  {
    path: "/carousel-manager",
    name: "Carousel",
    exact: true,
    icon: (
      <CustomIcons.CarouselIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.CarouselIcon,
    component: () => <CarouselManager />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },
  {
    path: "/communities",
    name: "Communities",
    hideFromSidebar: false,
    exact: true,
    icon: (
      <CustomIcons.MultipleUsersIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.MultipleUsersIcon,
    component: () => <AllCommunities />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },
  {
    path: "/user-profile/:id",
    name: "User Profile",
    hideFromSidebar: true,
    exact: true,
    icon: (
      <CustomIcons.UsersIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.UsersIcon,
    component: () => <UserProfile />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },
  {
    path: "/users-chat-history/:id",
    name: "Chat History",
    hideFromSidebar: true,
    exact: true,
    icon: (
      <CustomIcons.UsersIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.UsersIcon,
    component: () => <UserChatHistoryTable />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },
  {
    path: "/companies",
    name: "Companies",
    exact: true,
    icon: (
      <CustomIcons.WarningIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.CompaniesIcon,
    component: () => <Companies />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },
  {
    path: "/proof-of-payment/:id",
    name: "POP",
    exact: true,
    hideFromSidebar: true,
    icon: (
      <CustomIcons.WarningIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.CompaniesIcon,
    component: () => <ProofOfPayment />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },
  {
    path: "/company-members/:id",
    name: "Members",
    hideFromSidebar: true,
    exact: true,
    icon: (
      <CustomIcons.WarningIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.CompaniesIcon,
    component: () => <CompanyMembers />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },
  {
    path: "/deactivated-companies",
    name: "Deactivated Companies",
    hideFromSidebar: true,
    exact: true,
    icon: (
      <CustomIcons.WarningIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.CompaniesIcon,
    component: () => <DeactivatedCompanies />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },
  {
    path: "/emergencies",
    name: "Emergencies",
    exact: true,
    icon: (
      <CustomIcons.WarningIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.WarningIcon,
    component: () => <Emergencies />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },
  {
    path: "/operators-analytics",
    name: "Operators",
    exact: true,
    icon: (
      <CustomIcons.AccountVoice
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.AccountVoice,
    component: () => <OperatorAnalytics />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin", "EmergencyAdmin"],
  },
  {
    path: "/operators",
    name: "Operators",
    exact: true,
    hideFromSidebar: true, // will turn true when analytics is ready
    icon: (
      <CustomIcons.AccountVoice
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.AccountVoice,
    component: () => <EmergencyOperators />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },
  {
    path: "/detailed-operator/:id",
    name: "Details",
    exact: true,
    hideFromSidebar: true,
    icon: (
      <CustomIcons.AccountVoice
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.AccountVoice,
    component: () => <DetailedOperator />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin", "EmergencyAdmin"],
  },
  {
    path: "/crime-dashboard",
    name: "Crime",
    exact: true,
    icon: (
      <CustomIcons.CrimeIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.CrimeIcon,
    component: () => <CrimeDashboard />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },
  {
    path: "/review-reported-crimes/",
    name: "Tabs",
    hideFromSidebar: true,
    exact: true,
    icon: (
      <CustomIcons.CrimeIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.CrimeIcon,
    component: () => <TabItemsReportedCrimes />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },
  {
    path: "/review-reported-crimes-category/:category",
    name: "Review Category",
    hideFromSidebar: true,
    exact: true,
    icon: (
      <CustomIcons.CrimeIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.CrimeIcon,
    component: () => <ReviewCategory />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },
  {
    path: "/posted-reported-crimes-category/:category",
    name: "Posted Category",
    hideFromSidebar: true,
    exact: true,
    icon: (
      <CustomIcons.CrimeIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.CrimeIcon,
    component: () => <PostedCategory />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },
  {
    path: "/drafted-reported-crimes-category/:category",
    name: "Drafted Category",
    hideFromSidebar: true,
    exact: true,
    icon: (
      <CustomIcons.CrimeIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.CrimeIcon,
    component: () => <DraftsCategory />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },
  {
    path: "/messaging",
    name: "HelpDesk",
    exact: true,
    icon: (
      <CustomIcons.Headphones
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.Headphones,
    component: () => <Messaging />,
    layout: Layouts.Home,
    platformRights: ["EmergencyOperator", "EmergencyAdmin"],
  },
  {
    path: "/crime-report-detail/:id",
    name: "Crime report",
    exact: true,
    hideFromSidebar: true,
    icon: (
      <CustomIcons.CrimeIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.CrimeIcon,
    component: () => <CrimeReportDetail />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },
  {
    path: "/reported-crimes",
    name: "Reported Crimes",
    hideFromSidebar: true,
    exact: true,
    icon: (
      <CustomIcons.CrimeIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.CrimeIcon,
    component: () => <ReportedCrimes />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },
  {
    path: "/operator-table",
    name: "Table",
    hideFromSidebar: true,
    exact: true,
    icon: (
      <CustomIcons.Headphones
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.AccountVoice,
    component: () => <OperatorTable />,
    layout: Layouts.Home,
    platformRights: ["EmergencyAdmin"],
  },
  {
    path: "/operator-history",
    name: "History",
    exact: true,
    icon: (
      <CustomIcons.UserHistoryIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.UserHistoryIcon,
    component: () => <UserHistory />,
    layout: Layouts.Home,
    platformRights: ["EmergencyOperator", "EmergencyAdmin"],
  },
  {
    path: "/community-home",
    name: "Community",
    exact: true,
    icon: (
      <CustomIcons.HomeIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.HomeIcon,
    component: () => <CommunityHome />,
    layout: Layouts.Home,
    platformRights: ["CommunityAdmin"],
  },
  {
    path: "/delete-reported-post/:postId",
    name: "DeletereportedPost",
    hideFromSidebar: true,
    exact: true,
    icon: (
      <CustomIcons.HomeIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.HomeIcon,
    component: () => <DeleteReportedPost />,
    layout: Layouts.Home,
    platformRights: ["CommunityAdmin"],
  },
  {
    path: "/community-applications",
    name: "Applications",
    exact: true,
    icon: (
      <CustomIcons.WarningIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.WarningIcon,
    component: () => <PostApplications />,
    layout: Layouts.Home,
    platformRights: ["CommunityAdmin"],
  },
  {
    path: "/community-application/:id",
    name: "Application Detail",
    exact: true,
    hideFromSidebar: true,
    icon: (
      <CustomIcons.WarningIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.WarningIcon,
    component: () => <PostApplicationDetail />,
    layout: Layouts.Home,
    platformRights: ["CommunityAdmin"],
  },
  {
    path: "/community-post/:id",
    name: "Post Detail",
    exact: true,
    hideFromSidebar: true,
    icon: (
      <CustomIcons.CommentIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.WarningIcon,
    component: () => <CommunityPost />,
    layout: Layouts.Home,
    platformRights: ["CommunityAdmin"],
  },
  {
    path: "/safer-city-community-post/:id",
    name: "Safer City Post Detail",
    exact: true,
    hideFromSidebar: true,
    icon: (
      <CustomIcons.CommentIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.WarningIcon,
    component: () => <SaferCityCommunityPost />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },
  {
    path: "/community-chat",
    name: "CPF Chat",
    exact: true,
    hideFromSidebar: false,
    icon: (
      <CustomIcons.Headphones
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.Headphones,
    component: () => <CommunityChat />,
    layout: Layouts.Home,
    platformRights: ["CommunityAdmin"],
  },
  {
    path: "/control-room-operators",
    name: "Operators",
    exact: true,
    hideFromSidebar: false, //isProdEnvironment,
    icon: (
      <CustomIcons.EmergencyAlarmIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.ControlRoomOperatorIcon,
    component: () => <ControlRoomOperators />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },
  {
    path: "/responder-company",
    name: "Responder Company",
    exact: true,
    hideFromSidebar: false, //isProdEnvironment,
    icon: (
      <CustomIcons.ResponderCompanyIcon
        width={30}
        height={30}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.ResponderCompanyIcon,
    component: () => <ResponderCompany />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },
  {
    path: "/responder-company/vehicles/:companyId",
    name: "Responder Vehicles",
    exact: true,
    hideFromSidebar: true, //isProdEnvironment,
    icon: (
      <CustomIcons.EmergencyAlarmIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.EmergencyAlarmIcon,
    component: () => <ResponderCompanyVehicles />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },
  {
    path: "/responders/:companyId?",
    name: "Responders",
    exact: true,
    hideFromSidebar: false, //isProdEnvironment,
    icon: (
      <CustomIcons.EmergencyAlarmIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.EmergencyResponderIcon,
    component: () => <Responders />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },
  {
    path: "/all-cpf-responders",
    name: "CPF",
    exact: true,
    hideFromSidebar: false, //isProdEnvironment,
    icon: (
      <CustomIcons.EmergencyAlarmIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.CPFResponderIcon,
    component: () => <ResponderCPFs />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },

  {
    path: "/responder-analytics",
    name: "Analytics",
    exact: true,
    hideFromSidebar: false, //isProdEnvironment,
    icon: (
      <CustomIcons.EmergencyAlarmIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.LineGraphIcon,
    component: () => <ResponderAnalytics />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },
  {
    path: "/responder-report/:id",
    name: "Report",
    exact: true,
    hideFromSidebar: true, //isProdEnvironment,
    icon: (
      <CustomIcons.EmergencyAlarmIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.EmergencyAlarmIcon,
    component: () => <ResponderReport />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },

  {
    path: "/responder-details/:id",
    name: "Details",
    exact: true,
    hideFromSidebar: true, //isProdEnvironment,
    icon: (
      <CustomIcons.EmergencyAlarmIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.EmergencyAlarmIcon,
    component: () => <ResponderDetails />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },
  {
    path: "/all-responders/:companyId?",
    name: "All Responders",
    exact: true,
    hideFromSidebar: true, //isProdEnvironment,
    icon: (
      <CustomIcons.EmergencyAlarmIcon
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.EmergencyAlarmIcon,
    component: () => <AllResponders />,
    layout: Layouts.Home,
    platformRights: ["SuperAdmin"],
  },

  {
    path: "/profile",
    name: "Profile",
    exact: true,
    icon: (
      <CustomIcons.Profile
        width={30}
        height={30}
        viewBox={null}
        svgColor={Colors.secondaryColor}
      />
    ),
    pageIcon: CustomIcons.Profile,
    component: () => <Profile />,
    layout: Layouts.Home,
    platformRights: ["EmergencyOperator", "SuperAdmin", "EmergencyAdmin"],
  },
];

import React, { useEffect, useState } from "react";
import { Rate, Spin } from "antd";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { StarOutlined, SearchOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import moment from "moment";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { InputNumber } from "primereact/inputnumber";
import { tableBody, tableHeader, tableName } from "../../styles/TableStyles";
import HeaderComponent from "../../components/Header/Header";
import { BodyContainer, Container } from "../../styles/ContainerStyles";
import CustomInputField from "../../components/widgets/Inputs/CustomInputField";
import Colors from "../../config/Colors";
import { useUserChatHistory } from "../../hooks/useUsers";
import { Calendar, CalendarChangeEvent } from "primereact/calendar";

type userParams = {
  id?: string | undefined;
};

const UserChatHistoryTable = () => {
  const [filters, setFilters] = useState<DataTableFilterMeta | undefined>(
    undefined
  );

  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");

  const { id } = useParams<userParams>();

  const { data, isLoading, isFetching } = useUserChatHistory(id || "");

  const customInputTheme = {
    token: {
      colorPrimaryHover: `${Colors.appMainColor}`,
    },
  };

  const userFullName = data != undefined ? data.fullname : "";

  useEffect(() => {
    initFilters();
  }, []);

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      chatDate: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      firstName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      lastName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      helpDesk: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      resolvedCases: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      resolveDate: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      responseTime: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      rating: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
    });
    setGlobalFilterValue("");
  };

  const clearFilter = () => {
    initFilters();
  };

  const onGlobalFilterChange = (e: string) => {
    const _filters = { ...filters };
    // @ts-ignore
    _filters.global.value = e;

    setFilters(_filters);
    setGlobalFilterValue(e);
  };

  function formatTime(mins: number): string {
    if (mins < 60) {
      return `${Math.round(mins)} min`;
    }
    if (mins < 1440) {
      return `${Math.round(mins / 60)} hours`;
    }
    if (mins < 10080) {
      return `${Math.round(mins / 1440)} days`;
    }
    if (mins < 40320) {
      return `${Math.round(mins / 10080)} weeks`;
    }
    if (mins < 525600) {
      return `${Math.round(mins / 40320)} months`;
    }
    return `${Math.round(mins / 525600)} years`;
  }

  const statusBodyTemplate = (rowData: IUserChatHistory) => {
    return rowData.resolvedCases;
  };

  const averageResponseTemplate = (rowData: IUserChatHistory) => {
    return formatTime(rowData.responseTime);
  };

  const helpDeskBodyTemplate = (rowData: IUserChatHistory) => {
    return rowData.helpDesk;
  };

  const ratingBodyTemplate = (rowData: IUserChatHistory) => {
    return (
      <Rate
        disabled
        defaultValue={rowData.rating}
        style={{ color: `${Colors.appMainColor}` }}
        key={rowData.caseId + rowData.firstName}
      />
    );
  };

  const responseTimeFilterTemplate = (options: any) => {
    return (
      <InputNumber
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
      />
    );
  };
  const ratingFilterTemplate = (options: any) => {
    return (
      <InputNumber
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
      />
    );
  };

  const resolveDateBodyTemplate = (rowData: IUserChatHistory) => {
    return rowData.resolvedDate != ""
      ? moment(rowData.resolvedDate).format("YYYY/MM/DD")
      : "N/A";
  };

  const chatDateBodyTemplate = (rowData: IUserChatHistory) => {
    return moment(rowData.chatDate).format("YYYY/MM/DD");
  };

  const chatDateFilterTemplate = (
    options: ColumnFilterElementTemplateOptions
  ) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e: CalendarChangeEvent) => {
          options.filterCallback(e.value, options.index);
        }}
        dateFormat="yy/mm/dd"
        placeholder="yyyy/mm/dd"
        mask="9999/99/99"
      />
    );
  };
  return (
    <div className="w-full h-full">
      <Container className="w-full h-full">
        <BodyContainer className="w-full h-full">
          <HeaderComponent title="User" />
          <Spin tip="" spinning={isLoading || isFetching} delay={500}>
            <div
              className="flex flex-row align-items-center pl-3 mb-4"
              style={tableName}
            >
              {userFullName}
            </div>
            <div className="w-full h-full px-3">
              <div className="flex flex-row gap-1 justify-between mb-2 w-full">
                <div
                  className="flex flex-row align-items-center"
                  style={tableName}
                >
                  Chat History
                </div>
                <CustomInputField
                  value={globalFilterValue}
                  onChange={(e: string) => onGlobalFilterChange(e)}
                  theme={customInputTheme}
                  inputProps={{
                    size: "large",
                    placeholder: "Search",
                    allowClear: true,
                    className: "ml-2 w-3",
                    prefix: <SearchOutlined />,
                  }}
                  mergeThemes
                />
              </div>
              <DataTable
                paginator
                rows={10}
                value={data?.userChatHistory || []}
                rowsPerPageOptions={[10, 25, 50, 100]}
                dataKey="id"
                filters={filters}
                globalFilterFields={[
                  "chatDate",
                  "firstName",
                  "lastName",
                  "helpDesk",
                  "resolvedCases",
                  "resolveDate",
                  "responseTime",
                  "rating",
                ]}
                emptyMessage="No Chats found."
                rowHover
                scrollable
                scrollHeight="40rem"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} operators"
                stateStorage="session"
                stateKey="dt-state-user-chat-history-table"
                className="mx-0"
              >
                <Column
                  field="chatDate"
                  header="Chat Date"
                  dataType="date"
                  headerStyle={tableHeader}
                  bodyStyle={tableBody}
                  style={{ background: "#FBFBFB" }}
                  body={chatDateBodyTemplate}
                  filter
                  filterElement={chatDateFilterTemplate}
                  filterMenuStyle={{ width: "14rem" }}
                />
                <Column
                  field="firstName"
                  header="First name"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                  filter
                />
                <Column
                  field="lastName"
                  header="Last name"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                  filter
                />
                <Column
                  field="helpDesk"
                  header="Help Desk"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                  body={helpDeskBodyTemplate}
                  filter
                />
                <Column
                  field="resolvedCases"
                  header="Resolved Cases"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                  body={statusBodyTemplate}
                  filter
                />
                <Column
                  field="resolveDate"
                  header="Resolve Date"
                  dataType="date"
                  headerStyle={tableHeader}
                  bodyStyle={tableBody}
                  style={{ background: "#FBFBFB" }}
                  body={resolveDateBodyTemplate}
                  filter
                  filterElement={chatDateFilterTemplate}
                  filterMenuStyle={{ width: "14rem" }}
                />
                <Column
                  field="responseTime"
                  header="Response Time"
                  dataType="numeric"
                  headerStyle={tableHeader}
                  bodyStyle={tableBody}
                  style={{ background: "#FBFBFB" }}
                  body={averageResponseTemplate}
                  filter
                  filterElement={responseTimeFilterTemplate}
                  filterMenuStyle={{ width: "14rem" }}
                />
                <Column
                  field="rating"
                  header="Star Rating"
                  dataType="numeric"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                  body={ratingBodyTemplate}
                  filter
                  filterElement={ratingFilterTemplate}
                  filterMenuStyle={{ width: "14rem" }}
                />
              </DataTable>
            </div>
          </Spin>
        </BodyContainer>
      </Container>
    </div>
  );
};

export default UserChatHistoryTable;

/* eslint-disable max-len */
import React from "react";
import Colors from "../../config/Colors";

export function FeedIcon({
  height, // number type by default
  width,
  viewBox = "0 0 14 14",
  svgColor = `${Colors.appMainColor}`,
}: CustomSVGProp) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox || "0 0 14 14"}
    >
      <g
        fill="none"
        stroke={svgColor || "#000000"}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <rect width="13" height="13" x=".5" y=".5" rx="1" />
        <path d="M5.49 10.56V6.73A.36.36 0 0 1 6 6.42l3.32 1.91a.37.37 0 0 1 0 .63L6 10.88a.37.37 0 0 1-.51-.32ZM.5 4h13M4 4L5.5.5m3 3.5L10 .5" />
      </g>
    </svg>
  );
}
export default FeedIcon;

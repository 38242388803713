/* eslint-disable max-len */
import React from "react";
import Colors from "../../config/Colors";

export function AdminIcon({
  height, // number type by default
  width,
  viewBox = "0 0 24 24",
  svgColor = "#888888",
}: CustomSVGProp) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox={viewBox || "0 0 24 24"}
      fill="none"
    >
      <g fill={svgColor}>
        <path d="M16 6a4 4 0 1 1-8 0a4 4 0 0 1 8 0Zm-.322 7.503c-.473.005-.914.023-1.298.074c-.643.087-1.347.293-1.928.875c-.582.581-.788 1.285-.874 1.928c-.078.578-.078 1.284-.078 2.034v.172c0 .75 0 1.456.078 2.034c.06.451.18.932.447 1.38H12c-8 0-8-2.015-8-4.5S7.582 13 12 13c1.326 0 2.577.181 3.678.503Z" />
        <path
          fillRule="evenodd"
          d="M16.5 22c-1.65 0-2.475 0-2.987-.513C13 20.975 13 20.15 13 18.5c0-1.65 0-2.475.513-2.987C14.025 15 14.85 15 16.5 15c1.65 0 2.475 0 2.987.513C20 16.025 20 16.85 20 18.5c0 1.65 0 2.475-.513 2.987C18.975 22 18.15 22 16.5 22Zm1.968-4.254a.583.583 0 1 0-.825-.825l-1.92 1.92l-.366-.365a.583.583 0 1 0-.825.825l.778.778a.583.583 0 0 0 .825 0l2.333-2.333Z"
          clipRule="evenodd"
        />
      </g>
    </svg>
  );
}
export default AdminIcon;

import { CSSProperties } from "react";
import styled from "styled-components";
import Colors from "../../config/Colors";

export const WINDOW_WIDTH = window.innerWidth;
export const WINDOW_HEIGHT = window.innerHeight;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: WINDOW_WIDTH;
  height: WINDOW_HEIGHT;
  background: ${Colors.backgroundColor};
`;

export const BodyContainer = styled.div`
  justify-content: space-between;
  overflow-y: auto;
  width: 90%;
  max-height: 100vh;
`;

export const tableHeader: CSSProperties = {
  color: `${Colors.mainTextColor}`,
  fontWeight: 600,
  fontSize: 18,
  fontStyle: "normal",
  background: "#F1F0F0",
};

export const tableName: CSSProperties = {
  color: `${Colors.mainTextColor}`,
  fontWeight: 600,
  fontSize: 24,
  fontStyle: "normal",
};

export const tableBody: CSSProperties = {
  color: `${Colors.mainTextColor}`,
  fontWeight: 400,
  fontSize: 18,
  fontStyle: "normal",
};

export const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

import React from "react";
import { DateTime } from "luxon";
import { decodeMessage } from "../../../util/helpers";

type CommunityMessageCardProps = {
  message: ICommunityMessage;
  isUser: boolean;
  operators: number[];
  currentOperatorId: number;
};

const CommunityMessageCard = ({
  message,
  isUser,
  operators,
  currentOperatorId,
}: CommunityMessageCardProps) => {
  const messageTime = DateTime.fromISO(message.createdAt, {
    zone: "utc",
  })
    .toLocal()
    .toFormat("HH:mm");
  const messageDate = DateTime.fromISO(message.createdAt).toRelativeCalendar();
  const isCurrentOperator =
    message.handlingCommunityAdminId === currentOperatorId;
  const operatorIndex = `Operator ${
    message.handlingCommunityAdminId === currentOperatorId
      ? ""
      : operators.indexOf(message.handlingCommunityAdminId) + 1
  }`;

  return (
    <div className={`flex ${isUser ? "justify-start" : "justify-end"}`}>
      <div className="max-w-xs sm:max-w-md md:max-w-lg lg:max-w-xl">
        {!isCurrentOperator && (
          <div className="text-xs text-gray-500">
            {isUser ? `Replying to ${operatorIndex}` : operatorIndex}
          </div>
        )}
        <div
          className={`p-2 rounded-omd ${
            isUser ? "bg-secondaryColor" : "bg-appMainColor text-white"
          }`}
        >
          {decodeMessage(message.messageText)}
        </div>
        <div className="text-xs text-left text-gray-500">{messageTime}</div>
      </div>
    </div>
  );
};

export default CommunityMessageCard;

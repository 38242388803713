/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable  spaced-comment */
/* eslint-disable arrow-body-style */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-fragments */
/* eslint-disable  prefer-destructuring */

import React, { useState, useEffect, useReducer } from "react";
import { Rate, Button, Modal, Tabs } from "antd";
import type { TabsProps } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { NavLink, useHistory } from "react-router-dom";
import { InputNumber } from "primereact/inputnumber";
import Colors from "../../../config/Colors";
import {
  useAddOperator,
  useEditOperator,
  useOperatorsByEmergencyAll,
} from "../../../hooks/useOperators";
import Spin from "../../../components/widgets/Spinner/Spin";
import { tableBody, tableHeader, tableName } from "../../../styles/TableStyles";
import CustomInputField from "../../../components/widgets/Inputs/CustomInputField";
import "../styles.css";
import {
  modelTitle,
  successSubtitle,
  successTitle,
} from "../../../styles/ModalStyles";
import { CustomIcons } from "../../../assets";
import { copyObjects } from "../../../util/copyObjects";
import NewUserForm from "../../../components/Forms/NewUser";
import EditUserForm from "../../../components/Forms/EditUser";
import { ActionBodyText } from "../../../styles/ContainerStyles";
import SkeletonDashboard from "../../../components/widgets/skeletons/SkeletonDashboard";
import AllEmergencyOperators from "./AllEmergencyOperators";
import OperatorMaxCasesForm from "../../../components/Forms/OperatorMaxCases";

interface GeneralOperatorsProps {
  emergencyId: string | null | number;
  emergencyName: string;
}

const GeneralOperators: React.FC<GeneralOperatorsProps> = ({
  emergencyId,
  emergencyName,
}) => {
  const navigate = useHistory();
  // States
  const [openOperatorModal, setOpenOperatorModal] = useState(false);
  const [openEditOperatorModal, setOpenEditOperatorModal] = useState(false);
  const [currentOperator, setCurrentOperator] =
    useState<IGeneralCommunity | null>(null);

  const [activeIndex, setActiveIndex] = useState("1");

  const onTabChange = (key: string) => {
    setActiveIndex(key);
  };

  // Query
  const {
    isLoading,
    data: operatorsList,
    refetch,
  } = useOperatorsByEmergencyAll(emergencyId);

  // Mutation
  const addOperator = useAddOperator();
  const editOperator = useEditOperator();

  // Filters
  const [filters, setFilters] = useState<DataTableFilterMeta | undefined>(
    undefined
  );
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      fullName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      totalMessages: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      totalCases: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      resolvedCases: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      unresolvedCases: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      transferredCases: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      averageResponseTime: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      rating: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
    });
    setGlobalFilterValue("");
  };

  function formatTime(mins: number): string {
    if (mins < 60) {
      return `${Math.round(mins)} min`;
    }
    if (mins < 1440) {
      return `${Math.round(mins / 60)} hours`;
    }
    if (mins < 10080) {
      return `${Math.round(mins / 1440)} days`;
    }
    if (mins < 40320) {
      return `${Math.round(mins / 10080)} weeks`;
    }
    if (mins < 525600) {
      return `${Math.round(mins / 40320)} months`;
    }
    return `${Math.round(mins / 525600)} years`;
  }

  const onGlobalFilterChange = (e: string) => {
    const _filters = { ...filters };
    // @ts-ignore
    _filters.global.value = e;

    setFilters(_filters);
    setGlobalFilterValue(e);
  };

  // UseEffect

  useEffect(() => {
    if (emergencyId !== null) {
      refetch();
    }
  }, [emergencyId, refetch]);

  useEffect(() => {
    initFilters();
  }, []);

  // Functions
  const handleAddUser = async (practitioner: IUser) => {
    if (emergencyId) {
      const newPractitioner: IUser = copyObjects(practitioner, {
        userTypeId: 20,
      });
      const newData: IAddPractitioner = {
        user: newPractitioner,
        emergencyId,
      };

      try {
        await addOperator.mutateAsync(newData);
        //await refetch();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleEditUser = async (user: IUser, subId: string | number | null) => {
    try {
      if (currentOperator) {
        currentOperator.user = user;
        await editOperator.mutateAsync(currentOperator);
      }

      //await refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = async () => {
    setCurrentOperator(null);
    setOpenOperatorModal(false);
    setOpenEditOperatorModal(false);
    addOperator.reset();
    editOperator.reset();
  };

  // ReactNode
  const dateBodyTemplate = (rowData: IGeneralCommunity) => {
    return moment(rowData.accountCreated).format("YYYY/MM/DD");
  };

  const ratingBodyTemplate = (rowData: IGeneralCommunity) => {
    return (
      <Rate
        disabled
        defaultValue={rowData.rating}
        style={{ color: `${Colors.appMainColor}` }}
      />
    );
  };

  const averageResponseTemplate = (rowData: IGeneralCommunity) => {
    return formatTime(rowData.averageResponseTime);
  };

  const customInputTheme = {
    token: {
      colorPrimaryHover: `${Colors.appMainColor}`,
    },
  };

  const actionBodyTemplate = (rowData: IGeneralCommunity) => {
    return (
      <div style={{ minWidth: "50px" }}>
        <Button
          type="text"
          icon={
            <CustomIcons.PencilIcon
              width={20}
              height={20}
              viewBox={null}
              svgColor={Colors.appMainColor}
            />
          }
          style={{ color: `${Colors.appMainColor}` }}
          onClick={() => {
            setCurrentOperator(rowData);
            setOpenEditOperatorModal(true);
          }}
        />
      </div>
    );
  };

  const navigateTo = (id: number | string) => {
    navigate.push(`/home/detailed-operator/${id}`);
  };

  const viewMoreBodyTemplate = (rowData: IGeneralCommunity) => {
    const userid = rowData.userId;

    return (
      <ActionBodyText onClick={() => navigateTo(userid)}>
        View More
      </ActionBodyText>
    );
  };

  const numberFilterTemplate = (options: any) => {
    return (
      <InputNumber
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
      />
    );
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Personal Details`,
      children: (
        <>
          <EditUserForm
            userValues={currentOperator?.user}
            onFormFinish={(user: IUser, subId: string | number | null) =>
              handleEditUser(user, subId)
            }
            onModelClose={handleClose}
            isLoading={editOperator.isLoading}
            isSuccess={editOperator.isSuccess}
            showValues={false}
          />
        </>
      ),
    },
    {
      key: "2",
      label: `Settings`,
      children: (
        <>
          <OperatorMaxCasesForm
            userId={currentOperator?.userId ?? null}
            onModelClose={handleClose}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <div className="w-full h-full py-1">
        <div className="flex py-3 justify-content-between align-content-center">
          <div className="flex flex-row align-items-center" style={tableName}>
            {emergencyName}
          </div>
          <div className="flex flex-row gap-1 justify-content-center align-items-center">
            <Button
              type="primary"
              ghost
              size="large"
              className="w-8"
              onClick={() => setOpenOperatorModal(true)}
            >
              Add new operator
            </Button>
            <CustomInputField
              value={globalFilterValue}
              onChange={(e: string) => onGlobalFilterChange(e)}
              onPressEnter={() => {}}
              inputProps={{
                size: "large",
                placeholder: "Search",
                allowClear: true,
                className: "ml-2 w-11",
                prefix: <SearchOutlined />,
              }}
              theme={customInputTheme}
              mergeThemes
            />
          </div>
        </div>
        <Spin tip="" spinning={isLoading} delay={500}>
          <div className="pt-2 card General">
            {operatorsList ? (
              <DataTable
                value={operatorsList}
                paginator
                rows={10}
                rowsPerPageOptions={[10, 25, 50, 100]}
                tableStyle={{ minWidth: "50rem" }}
                sortMode="multiple"
                emptyMessage="No operators found."
                dataKey="userId"
                scrollable
                scrollHeight="40rem"
                filters={filters}
                globalFilterFields={[
                  "fullName",
                  "user.firstName",
                  "user.lastName",
                  "user.emailAddress",
                ]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Operators"
                stateStorage="session"
                stateKey={`dt-state-operators-${emergencyId}-table`}
              >
                <Column
                  field="fullName"
                  header="Operators name"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                />
                <Column
                  field="totalMessages"
                  header="Total Messages"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                />
                <Column
                  field="totalCases"
                  header="Total cases"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                />
                <Column
                  field="resolvedCases"
                  header="Resolved cases"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                />
                <Column
                  field="unresolvedCases"
                  header="Unresolved cases"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                />
                <Column
                  field="transferredCases"
                  header="Transferred cases"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                />
                <Column
                  field="averageResponseTime"
                  header="Average Response Time"
                  headerStyle={tableHeader}
                  bodyStyle={tableBody}
                  style={{ background: "#FBFBFB" }}
                  body={averageResponseTemplate}
                />
                <Column
                  field="rating"
                  header="Rating"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                  body={ratingBodyTemplate}
                />
                <Column
                  headerStyle={tableHeader}
                  exportable={false}
                  body={actionBodyTemplate}
                  bodyStyle={tableBody}
                  style={{ background: "#FBFBFB" }}
                />
                <Column
                  field="viewMore"
                  header=""
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  body={viewMoreBodyTemplate}
                  filterMenuStyle={{ width: "14rem" }}
                />
              </DataTable>
            ) : (
              <h1 className="text-center">
                No operators data found, add new operators
              </h1>
            )}
          </div>
        </Spin>
        <Modal
          centered
          open={openOperatorModal}
          onOk={() => setOpenOperatorModal(false)}
          onCancel={() => setOpenOperatorModal(false)}
          width={550}
          closeIcon={
            <CustomIcons.CloseIcon
              width={30}
              height={30}
              viewBox={null}
              svgColor="#1C274C"
            />
          }
          footer={null}
        >
          <div className="w-full pt-2 mx-2">
            {addOperator.isSuccess ? (
              <div className="flex w-full h-full gap-2 flex-column align-items-center justify-content-center">
                <div className="flex w-full justify-content-center">
                  <CustomIcons.SuccessIcon
                    width={100}
                    height={100}
                    viewBox={null}
                    svgColor="#179717"
                  />
                </div>
                <div style={successTitle}> Successful</div>
                <div style={successSubtitle} className="pb-4">
                  {" "}
                  You have successfully added a new operator
                </div>
                <Button
                  type="primary"
                  ghost
                  onClick={handleClose}
                  size="large"
                  className="w-4"
                >
                  close
                </Button>
              </div>
            ) : (
              <>
                <div
                  className="flex w-12 py-3 justify-content-start"
                  style={modelTitle}
                >
                  Create new operator
                </div>

                <NewUserForm
                  onFormFinish={(user: IUser) => handleAddUser(user)}
                  onModelClose={() => setOpenOperatorModal(false)}
                  isLoading={addOperator.isLoading}
                  isSuccess={addOperator.isSuccess}
                />
              </>
            )}
          </div>
        </Modal>
        <Modal
          centered
          open={openEditOperatorModal}
          onOk={() => setOpenEditOperatorModal(false)}
          onCancel={handleClose}
          width={550}
          closeIcon={
            <CustomIcons.CloseIcon
              width={30}
              height={30}
              viewBox={null}
              svgColor="#1C274C"
            />
          }
          footer={null}
        >
          <div className="w-full pt-2 mx-2">
            {editOperator.isSuccess ? (
              <div className="flex w-full h-full gap-2 flex-column align-items-center justify-content-center">
                <div className="flex w-full justify-content-center">
                  <CustomIcons.SuccessIcon
                    width={100}
                    height={100}
                    viewBox={null}
                    svgColor="#179717"
                  />
                </div>
                <div style={successTitle}> Successful</div>
                <div style={successSubtitle} className="pb-4">
                  {" "}
                  You have successfully edited a operator
                </div>
                <Button
                  type="primary"
                  ghost
                  onClick={handleClose}
                  size="large"
                  className="w-4"
                >
                  close
                </Button>
              </div>
            ) : (
              <>
                <div
                  className="flex w-12 py-3 justify-content-start"
                  style={modelTitle}
                >
                  Edit Operator
                </div>
                {/* <EditUserForm
                  userValues={currentOperator?.user}
                  onFormFinish={(user: IUser, subId: string | number | null) =>
                    handleEditUser(user, subId)
                  }
                  onModelClose={handleClose}
                  isLoading={editOperator.isLoading}
                  isSuccess={editOperator.isSuccess}
                  showValues={false}
                /> */}
                <Tabs
                  defaultActiveKey={activeIndex}
                  items={items}
                  onChange={onTabChange}
                  animated
                />
              </>
            )}
          </div>
        </Modal>
      </div>
    </>
  );
};

export default GeneralOperators;

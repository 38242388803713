import React, { useRef } from "react";
import Lottie, { LottieProps } from "react-lottie-player";
import settings from "../../../assets/lottie/VideoPlayer.json";

export default function VideoPlayerAnimation(props: LottieProps) {
  const animationRef = useRef<any | null>(null);

  const startPlay = () => {
    animationRef.current?.play();
  };
  const stopPlay = () => {
    animationRef?.current?.stop();
  };
  return (
    <div>
      <Lottie
        animationData={settings}
        {...props}
        ref={animationRef}
        loop={false}
        play
      />
    </div>
  );
}

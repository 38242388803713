import React, { useEffect } from "react";
import { Button, DatePicker, Form, Input, message, Select } from "antd";
import PhoneNumberPrefixSelector from "../widgets/Inputs/PhoneNumberPrefixSelector";

type NewCPFResponderProps = {
  onFormFinish: (cpfResponder: ICPFResponder) => Promise<void>;
  onModalClose: () => void;
  isLoading: boolean;
  isSuccess: boolean;
};

const AddNewCPFResponder = ({
  onFormFinish,
  onModalClose,
  isLoading,
  isSuccess,
}: NewCPFResponderProps) => {
  const [form] = Form.useForm();

  const onFinish = async (formValues: any) => {
    const values: ICPFResponder = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      dateOfBirth: formValues.dateOfBirth,
      gender: formValues.gender,
      fullPhoneNumber: formValues.prefix + formValues.phoneNumber,
      emailAddress: formValues.emailAddress,
      cpfid: formValues.cpfId,
      area: formValues.cpfStation,
    };
    await onFormFinish(values);

    onModalClose();
  };

  const handleClose = () => {
    form.resetFields();
    onModalClose();
  };

  const onFinishFailed = (errorInfo: any) => {
    message.error("Could not create CPF Responder, please try again.");
  };

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
    }
  }, [isSuccess]);

  return (
    <Form
      form={form}
      name="newCPFResponder"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout="vertical"
      style={{ width: "100%" }}
      className="grid"
      initialValues={{
        prefix: "+27", // Set the default value for the prefix here
      }}
    >
      <div className="col-12 md:col-6">
        <Form.Item
          name="firstName"
          label="First Name"
          rules={[
            { required: true, message: "Please input the user's first name!" },
          ]}
          className="mb-1"
        >
          <Input size="large" placeholder="Amanda" />
        </Form.Item>
      </div>

      <div className="col-12 md:col-6">
        <Form.Item
          name="lastName"
          label="Last Name"
          rules={[
            { required: true, message: "Please input the user's last name!" },
          ]}
          className="mb-1"
        >
          <Input size="large" placeholder="Neli" />
        </Form.Item>
      </div>

      <div className="col-12 md:col-6">
        <Form.Item
          name="emailAddress"
          label="Email"
          rules={[
            { required: true, message: "Please input the user's email!" },
            { type: "email", message: "Please input a valid email!" },
          ]}
          className="mb-1"
        >
          <Input size="large" placeholder="Amando@gmail.com" />
        </Form.Item>
      </div>

      <div className="col-12 md:col-6">
        <Form.Item
          name="phoneNumber"
          label="Phone Number"
          rules={[
            {
              required: true,
              message: "Please enter user's phone number!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                // Assuming the country code (+27) is automatically included and not part of the input
                const phoneNumberPattern = /^[6-8][0-9]{8}$/;
                const fullPhoneNumber = getFieldValue("prefix") + value;
                // Validate the full phone number including the country code
                // ^\+27[6-8][0-9]{8}$
                // ^(\+27|0)[6-8][0-9]{8}$ // if you want to use 0 as the country code
                if (/^(\+27)[6-8][0-9]{8}$/.test(fullPhoneNumber)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    "Please enter a valid South African phone number without a leading 0 after the country code!"
                  )
                );
              },
            }),
          ]}
          className="mb-1"
        >
          <Input
            size="large"
            addonBefore={PhoneNumberPrefixSelector()}
            placeholder="63256392"
          />
        </Form.Item>
      </div>

      <div className="col-12 md:col-6">
        <Form.Item
          name="dateOfBirth"
          label="Date of birth"
          rules={[
            { required: true, message: "Please enter user's date of birth!" },
          ]}
          className="mb-3"
        >
          <DatePicker size="large" style={{ width: "100%" }} />
        </Form.Item>
      </div>

      <div className="col-12 md:col-6">
        <Form.Item
          name="gender"
          label="Gender"
          rules={[
            { required: true, message: "Please select the user's gender!" },
          ]}
          className="mb-3"
        >
          <Select placeholder="Select user's gender" size="large">
            <Select.Option value="Male">Male</Select.Option>
            <Select.Option value="Female">Female</Select.Option>
            <Select.Option value="Other">Other</Select.Option>
          </Select>
        </Form.Item>
      </div>

      <div className="col-12 md:col-6">
        <Form.Item
          name="cpfId"
          label="CPF ID"
          rules={[
            { required: true, message: "Please input the user's CPF ID" },
          ]}
          className="mb-1"
        >
          <Input size="large" placeholder="564875873" />
        </Form.Item>
      </div>

      <div className="col-12 md:col-6">
        <Form.Item
          name="cpfStation"
          label="CPF Station"
          rules={[
            { required: true, message: "Please input the user's CPF Station" },
          ]}
          className="mb-1"
        >
          <Input size="large" placeholder="Enter CPF Station" />
        </Form.Item>
      </div>

      <Form.Item className="w-12 mb-1">
        <div className="flex w-full gap-2 col-12 justify-content-end align-content-center">
          <Button
            type="primary"
            ghost
            onClick={handleClose}
            size="large"
            className="w-3"
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            size="large"
            className="w-3 bg-[#1757a2] shadow-none"
          >
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default AddNewCPFResponder;

import React, { useEffect, useState } from "react";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import Colors from "@/config/Colors";

import { useGetResponderTableData } from "@/hooks/useResponderAnalytics";

interface ControlRoomOp {
  fullName: string;
  userId: number;
  controlRoomId: number;
}

type ResponderOperatorFilterProps = {
  onFilterChange: (name: string, value: any[]) => void;
};

const ResponderOperatorDropDown = ({
  onFilterChange,
}: ResponderOperatorFilterProps) => {
  const { data, isLoading, isFetching } = useGetResponderTableData();
  const [selectedOperatorIds, setSelectedOperatorIds] = useState<number[]>([]);
  const [selectedOperators, setSelectedOperators] = useState<ControlRoomOp[]>(
    []
  ); // Change the type to ControlRoomOp[]
  const onSelectionChange = (e: MultiSelectChangeEvent) => {
    let newOperatorIds: number[] = [];
    if (Array.isArray(e.value)) {
      setSelectedOperators(e.value.map((op: ControlRoomOp) => op));
      setSelectedOperatorIds(
        e.value.map((op: ControlRoomOp) => op.controlRoomId)
      );
      newOperatorIds = e.value.map((op: ControlRoomOp) => op.controlRoomId);
    }

    onFilterChange("controlRoomIds", newOperatorIds);
  };

  const operators: ControlRoomOp[] = data?.controlRoomOperators || [];

  return (
    <div className="responderAnalytics flex h-20 w-3 py-1 px-1 mr-0.5 bg-appMainColor justify-center items-center flex-shrink-0">
      <MultiSelect
        value={selectedOperators}
        onChange={onSelectionChange}
        options={operators}
        optionLabel="fullName"
        filter
        placeholder="Operator"
        maxSelectedLabels={3}
        style={{
          backgroundColor: Colors.appMainColor,
          borderColor: Colors.appMainColor,
          color: "#fff",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    </div>
  );
};

export default ResponderOperatorDropDown;

import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Responder } from "@/services";
import { toast } from "sonner";
import { queries } from "../services/queries";

export const useGetRespondersByCompanyId = (
  page: number,
  pageSize: number,
  companyId?: number
) => {
  return useQuery({
    ...queries.responder.GetRespondersByCompanyId(page, pageSize, companyId),
    refetchOnWindowFocus: false,
  });
};

export const useCreateResponder = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (responder: ICreateResponder) => Responder.createResponder(responder),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["responderCompanies"]);
        return data;
      },
      onError: (err: Error) => {
        if (err.message) {
          toast.error(err.message);
        } else {
          toast.error("An error occurred");
        }
      },
      meta: {
        useError: true,
      },
    }
  );
};

export const useEditResponder = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (responder: IEditResponder) => Responder.updateResponder(responder),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(queries.responder._def);
        return data;
      },
      onError: (err: Error) => {
        if (err.message) {
          toast.error(err.message);
        } else {
          toast.error("An error occurred");
        }
      },
      meta: {
        useError: true,
      },
    }
  );
};

export const useUploadResponder = () => {
  return useMutation(
    (responder: IResponderUploadRequest) =>
      Responder.UploadResponder(responder),
    {
      onError: (err: Error) => {
        if (err.message) {
          toast.error(err.message);
        } else {
          toast.error("An error occurred");
        }
      },
      meta: {
        useError: true,
      },
    }
  );
};

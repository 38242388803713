import React, { useEffect, useState } from "react";
import { FileUp } from "lucide-react";
import { useModal } from "@/hooks/useModalStore";
import { Upload, Form, Button, Input, Modal, DatePicker, Select } from "antd";
import { UploadFile, RcFile, UploadProps } from "antd/es/upload/interface";
import { uploadUrl } from "@/config/urls";
import Colors from "@/config/Colors";
import { CustomIcons } from "@/assets/index";
import { modelTitle } from "@/styles/ModalStyles";
import { usePanicTypes } from "@/hooks/usePanicTypes";
import { useCreateResponder } from "@/hooks/useResponder";
import { toast } from "sonner";
import CommonUserFields from "../Forms/CommonUserFields";

const companies = [
  {
    companyId: 1,
    companyName: "Company 1",
  },
  {
    companyId: 2,
    companyName: "Company 2",
  },
  {
    companyId: 3,
    companyName: "Company 3",
  },
];
// Filter `option.label` match the user type `input`
const filterOption = (
  input: string,
  option?: { id?: number; label: string; value: string } | any
) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

function NewResponderModal() {
  const panicTypes = usePanicTypes();
  const createResponderMutation = useCreateResponder();
  const { isOpen, onOpen, onClose, type, data } = useModal();
  const [form] = Form.useForm();
  const [medicalLicenseUri, setMedicalLicenseUri] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [selectedPanicTypes, setSelectedPanicTypes] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const isModalOpen = isOpen && type === "createResponder";
  const activeCompanyId = data.activeResponderCompanyId || null;

  const handleReset = () => {
    form.resetFields();
    setSelectedPanicTypes([]); //
    setMedicalLicenseUri("");
    setFileList([]);
  };

  // Use useEffect to set medicalLicenseUri if editing
  useEffect(() => {
    if (isModalOpen) {
      // Reset for add new
      handleReset();
      // Set companyId if activeCompanyId is available and not in edit mode
      if (activeCompanyId) {
        form.setFieldsValue({ companyId: activeCompanyId });
      }
    } else {
      // Reset whenever modal is closed
      handleReset();
    }
  }, [isModalOpen, form, activeCompanyId]);

  const handleBulkUpload = () => {
    onOpen("responderBulkUpload", {
      activeResponderCompanyId: data.activeResponderCompanyId,
    });
  };

  const handleClose = async () => {
    handleReset();
    onClose();
  };

  const beforeCertificateUpload = (file: RcFile) => {
    const isPdfOrWord = ["application/pdf"].includes(file.type);
    const isLt15M = file.size / 1024 / 1024 < 15;
    if (!isPdfOrWord) {
      toast.error("You can only upload PDF file!");
      return false; // This prevents the file from being added to the list
    }
    if (!isLt15M) {
      toast.error("file must smaller than 15MB!");
      return false; // This prevents the file from being added to the list
    }
    return isPdfOrWord && isLt15M;
  };

  // Base Props for common settings
  const baseUploadProps = {
    name: "file",
    action: uploadUrl,
    multiple: false,
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent: number | undefined) =>
        percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  // Props for certificate upload
  const certificateUploadProps: UploadProps = {
    ...baseUploadProps,
    onChange(info) {
      const lastSuccessfulUpload = fileList;
      const currentFile = info.file; // Get the current (latest) file
      const { status } = currentFile;
      // setFileList([currentFile]); // Keep only the latest file in the fileList
      const newFileList = [...info.fileList];

      // Update state based on the current fileList
      setFileList(newFileList);
      if (status === "uploading") {
        setIsUploading(true);
      } else if (status === "done") {
        setIsUploading(false);
        setFileList([currentFile]); // Keep only the successfully uploaded file
        form.setFieldsValue({ medicalLicense: info.file.response.path });
        setMedicalLicenseUri(info.file.response.path); // Update certificate URL state
        toast.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        setIsUploading(false);
        toast.error(`${currentFile.name} file upload failed.`);
        // Revert to the last successful upload if the current upload fails
        if (lastSuccessfulUpload) {
          setFileList(lastSuccessfulUpload);
        } else {
          setFileList([]); // If there was no successful upload before, clear the fileList
        }
      }
    },
    beforeUpload: beforeCertificateUpload,
    fileList,
    accept: ".pdf",
  };

  // This function takes in an array of panicTypes values and panicTypes label and check if the value means the panicTypes label
  const isLabelSelected = (label: string): boolean => {
    return selectedPanicTypes.some((typeId) => {
      const type = panicTypes.data?.find((p) => p.id === typeId);
      return type?.name === label;
    });
  };

  const onFinish = async (formValues: any) => {
    // Set Company Id here if not in edit mode and formValues does not have companyId
    if (!formValues.companyId) {
      formValues.companyId = activeCompanyId;
    }
    // Assuming 'prefix' is the name attribute for your Select component for the country code
    // and 'phoneNumber' is the name attribute for your Input component for the phone number
    const fullPhoneNumber = `${formValues.prefix}${formValues.phoneNumber}`;

    // const values = formValues;
    const values = {
      ...formValues,
      fullPhoneNumber, // This is the full phone number including the country code
    };

    try {
      await createResponderMutation.mutateAsync(values);
      toast.success("Responder created successfully");
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const onValuesChange = (changedValues: any, allValues: any) => {
    if (changedValues.panicTypes) {
      setSelectedPanicTypes(changedValues.panicTypes);
    }
  };

  return (
    <Modal
      centered
      open={isModalOpen}
      onCancel={() => handleClose()}
      width={550}
      closeIcon={
        <CustomIcons.CloseIcon
          width={30}
          height={30}
          viewBox={null}
          svgColor="#1C274C"
        />
      }
      footer={null}
    >
      <div className="flex w-12 py-3 justify-content-start" style={modelTitle}>
        +Add New Responder
      </div>
      <div className="flex justify-end pt-1 pb-3">
        <Button type="primary" ghost size="large" onClick={handleBulkUpload}>
          Upload Excel Document
        </Button>
      </div>
      <Form
        form={form}
        name="newResponder"
        onFinish={onFinish}
        layout="vertical"
        className="grid w-full"
        onValuesChange={onValuesChange}
        initialValues={{
          prefix: "+27", // Set the default value for the prefix here
        }}
      >
        <CommonUserFields form={form} />
        {/* Only Render this if there is no activeCompanyId and not in Edit Mode */}
        {!activeCompanyId && (
          <div className="col-12">
            <Form.Item
              name="companyId"
              label="Company"
              className="mb-1"
              rules={[
                { required: true, message: "Please select the user's gender!" },
              ]}
            >
              <Select
                placeholder="Select user company"
                size="large"
                loading={false} // set when we make a request to fetch companies
                filterOption={filterOption}
              >
                {companies?.map((item: any) => (
                  <Select.Option
                    key={`key-companies-${item.companyId}`}
                    value={item.companyId}
                  >
                    {item.companyName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        )}

        <div className="col-12">
          <Form.Item
            name="panicTypes"
            label="Type of Panic"
            className="mb-1"
            rules={[
              {
                required: true,
                message: "Please select panics the responder can respond to!",
              },
            ]}
          >
            <Select
              placeholder="Select panics responder can respond to"
              size="large"
              mode="multiple"
              loading={panicTypes.isLoading}
              filterOption={filterOption}
              placement="topRight"
            >
              {panicTypes.data?.map((item: IPanicTypes) => (
                <Select.Option
                  key={`key-panicTypes-${item.id}`}
                  value={item.id}
                >
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        {(isLabelSelected("Security") || isLabelSelected("Medical")) && (
          <>
            <div className="col-12 md:col-6">
              <Form.Item
                name="psira"
                label="PSIRA For Responder"
                rules={[
                  {
                    required: true,
                    message: "Please input the responder psira number!",
                  },
                  {
                    pattern: /^\d{7,}$/,
                    message:
                      "Please enter a valid PSIRA number. It should be at least 7 digits long.",
                  },
                ]}
                className="mb-1"
              >
                <Input size="large" placeholder="237838345773" />
              </Form.Item>
            </div>
            <div className="col-12 md:col-6">
              <Form.Item
                name="PsiraExpiryDate"
                label="PSIRA for Responder Valid To"
                rules={[
                  {
                    required: true,
                    message: "Please enter the responder psira valid to!",
                  },
                ]}
                className="mb-1"
              >
                <DatePicker size="large" style={{ width: "100%" }} />
              </Form.Item>
            </div>
          </>
        )}

        {isLabelSelected("Medical") && (
          <div className="transition-opacity duration-500 ease-in-out opacity-100 col-12">
            <Form.Item
              name="medicalLicense"
              label="Medical License"
              rules={[
                {
                  required: true,
                  message: "Please upload medical license!",
                },
                // Custom validator to check if a valid certificate file has been uploaded
                () => ({
                  validator(_) {
                    if (!medicalLicenseUri && !isUploading) {
                      // Check if certificate URL is not set
                      return Promise.reject(
                        new Error("Please upload a valid PDF certificate file.")
                      );
                    }
                    return Promise.resolve();
                  },
                }),
                () => ({
                  validator() {
                    if (
                      fileList.length === 0 ||
                      fileList.some((file) => file.status !== "done")
                    ) {
                      return Promise.reject(
                        new Error("Please upload a valid PDF certificate file.")
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              className="mb-1"
            >
              <Upload
                {...certificateUploadProps}
                beforeUpload={beforeCertificateUpload}
                accept=".pdf"
                fileList={fileList}
              >
                <Button
                  className="flex items-center justify-between w-12 h-12 gap-2"
                  icon={<FileUp />}
                >
                  Click to Upload
                </Button>
              </Upload>
            </Form.Item>
          </div>
        )}

        {isLabelSelected("Medical") && (
          <div className="ease-in-out col-12">
            <Form.Item
              name="MedicalLicenseExpiryDate"
              label="Medical License Valid To"
              rules={[
                {
                  required: true,
                  message:
                    "Please enter the responder medical license valid to!",
                },
              ]}
              className="mb-3"
            >
              <DatePicker size="large" style={{ width: "100%" }} />
            </Form.Item>
          </div>
        )}

        <Form.Item className="w-12 pt-2 mb-1">
          <div className="flex w-full gap-2 col-12 justify-content-end align-content-center">
            <Button
              type="primary"
              ghost
              onClick={handleClose}
              size="large"
              className="w-3"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="w-3 bg-[#1757a2] shadow-none"
              disabled={isUploading} // Disable the button while uploading
              loading={createResponderMutation.isLoading}
            >
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default NewResponderModal;

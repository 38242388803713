import React from "react";
import { DateTime } from "luxon";
import { decodeMessage, truncateDescription } from "../../../util/helpers";
import UserAvatar from "../../widgets/Avatars/UserAvatar";

type CommunityMessagePreviewCardProps = {
  caseItem: ICommunityAdminCase;
};

function CommunityMessagePreviewCard({
  caseItem,
}: CommunityMessagePreviewCardProps) {
  const fullName = `${caseItem.user.firstName} ${caseItem.user.lastName}`;
  return (
    <div className="flex flex-row items-center justify-between w-full gap-2 mb-3">
      {/* Avatar Here */}
      <UserAvatar user={caseItem.user} />
      {/* Message Here */}
      <div className="flex-col justify-start flex-1 gap-1">
        <p className="mb-0 text-sm text-left truncate text-mainTextColor">
          {fullName}
        </p>
        {caseItem.preview && (
          <p className="mb-0 text-sm text-left text-mainTextColor">
            {caseItem.preview?.senderId === caseItem.communityAdmin?.id && (
              <>You: </>
            )}
            {truncateDescription(
              decodeMessage(caseItem.preview?.messageText ?? ""),
              20
            )}
          </p>
        )}
      </div>
      {/* Date Here */}
      {caseItem.preview && (
        <div className="text-xs text-mainTextColor">
          {DateTime.fromISO(caseItem.preview.createdAt, {
            zone: "utc",
          })
            .toLocal()
            .toFormat("dd/MM/yyyy")}{" "}
          {DateTime.fromISO(caseItem.preview.createdAt, {
            zone: "utc",
          })
            .toLocal()
            .toFormat("HH:mm")}
        </div>
      )}
    </div>
  );
}
export default CommunityMessagePreviewCard;

/* eslint-disable comma-dangle */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-alert */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable  implicit-arrow-linebreak */
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
// import Modal from 'react-modal';
import { Modal, Button } from "antd";
import { observer } from "mobx-react-lite";
import {
  Container,
  InformationContainer,
  InnerContainer,
  AddBtnStyle,
  BodyContainer,
  successTitle,
  successSubtitle,
  modelTitle,
} from "./styles";
import { Images, CustomIcons } from "../../assets/index";
import EmergencyCard from "../../components/Cards/EmergencyCard/EmergencyCard";
import HeaderComponent from "../../components/Header/Header";
import "./styles.css";
import Colors from "../../config/Colors";
import NewEmergencyForm from "../../components/Forms/NewEmergency";
import NewUserForm from "../../components/Forms/NewUser";
import { copyObjects } from "../../util/copyObjects";
import {
  useAddEmergency,
  useEditEmergency,
  useEmergencies,
} from "../../hooks/useEmergencies";
import { useAddOperator } from "../../hooks/useOperators";

const Emergencies = observer(() => {
  const navigate = useHistory();
  const [openNewModal, setOpenNewModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openPractitionerModal, setOpenPractitionerModal] = useState(false);
  const [emergencyId, setEmergencyId] = useState<number | null>(null);
  const [currentEmergency, setCurrentEmergency] = useState<IEmergency | null>(
    null
  );
  const addEmergency = useAddEmergency();
  const editEmergency = useEditEmergency();
  const addOperator = useAddOperator();

  const { isLoading, data: emergencies, isFetching } = useEmergencies();

  const toggleModal = (card: IEmergency) => {
    setEmergencyId(card.id);
    setOpenPractitionerModal(true);
  };

  const handleAddEmergency = async (emergency: IEmergency) => {
    addEmergency.mutate(emergency);
  };
  const handleEditEmergency = async (emergency: IEmergency) => {
    if (currentEmergency) {
      const updatedBody = copyObjects(currentEmergency, emergency);
      editEmergency.mutate(updatedBody);
    }
  };

  const handleAddUser = async (practitioner: IUser) => {
    if (emergencyId) {
      const newPractitioner: IUser = copyObjects(practitioner, {
        userTypeId: 20,
      });
      const newData: IAddPractitioner = {
        user: newPractitioner,
        emergencyId,
      };
      addOperator.mutate(newData);
    }
  };

  const handleClose = async () => {
    await setOpenNewModal(false);
    await setOpenEditModal(false);
    await setEmergencyId(null);
    await setCurrentEmergency(null);
    editEmergency.reset();
    addEmergency.reset();
    addOperator.reset();
  };

  return (
    <div className="w-full h-full">
      <Container className="w-full h-full">
        <BodyContainer className="w-full">
          <HeaderComponent title="Emergencies" />
          {isLoading ? (
            <div className="flex h-full flex-column justify-content-center align-items-center">
              <CustomIcons.EOSThreeDotsLoading
                width={80}
                height={80}
                viewBox={null}
                svgColor={Colors.appMainColor}
              />
            </div>
          ) : (
            <InformationContainer>
              <InnerContainer>
                <div className="card-container">
                  {emergencies &&
                    Array.isArray(emergencies) &&
                    emergencies.map((card: IEmergency, index: any) => (
                      <EmergencyCard
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        title={card.emergencyType}
                        subtitle={card.emergencyDiscription}
                        imgSrc={card.iconPath}
                        editEmergencyCard={() => {
                          setCurrentEmergency(card);
                          setOpenEditModal(true);
                        }}
                        addNewOperator={() => toggleModal(card)}
                      />
                    ))}
                  <button
                    style={AddBtnStyle}
                    type="button"
                    onClick={() => setOpenNewModal(true)}
                  >
                    <p
                      style={{
                        color: `${Colors.appMainColor}`,
                        fontSize: 26,
                        fontWeight: "bold",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      +
                    </p>
                  </button>
                </div>
              </InnerContainer>
            </InformationContainer>
          )}
        </BodyContainer>
      </Container>
      <Modal
        centered
        open={openNewModal}
        onOk={() => setOpenNewModal(false)}
        onCancel={() => handleClose()}
        width={550}
        closeIcon={
          <CustomIcons.CloseIcon
            width={30}
            height={30}
            viewBox={null}
            svgColor="#1C274C"
          />
        }
        footer={null}
      >
        <div className="w-full pt-2 mx-2">
          {addEmergency.isSuccess ? (
            <div className="flex w-full h-full gap-2 flex-column align-items-center justify-content-center">
              <div className="flex w-full justify-content-center">
                <CustomIcons.SuccessIcon
                  width={100}
                  height={100}
                  viewBox={null}
                  svgColor="#179717"
                />
              </div>
              <div style={successTitle}> Successful</div>
              <div style={successSubtitle} className="pb-4">
                {" "}
                You have successfully added a new emergency
              </div>
              <Button
                type="primary"
                ghost
                onClick={handleClose}
                size="large"
                className="w-4"
              >
                close
              </Button>
            </div>
          ) : (
            <>
              <div
                className="flex w-12 py-3 justify-content-start"
                style={modelTitle}
              >
                Create new emergencies
              </div>

              <NewEmergencyForm
                onFormFinish={(emergency: IEmergency) =>
                  handleAddEmergency(emergency)
                }
                onModelClose={() => {
                  handleClose();
                }}
                isLoading={addEmergency.isLoading}
                isSuccess={addEmergency.isSuccess}
              />
            </>
          )}
        </div>
      </Modal>
      <Modal
        centered
        open={openEditModal}
        onOk={() => setOpenEditModal(false)}
        onCancel={() => setOpenEditModal(false)}
        width={550}
        closeIcon={
          <CustomIcons.CloseIcon
            width={30}
            height={30}
            viewBox={null}
            svgColor="#1C274C"
          />
        }
        footer={null}
      >
        <div className="w-full pt-2 mx-2">
          {editEmergency.isSuccess ? (
            <div className="flex w-full h-full gap-2 flex-column align-items-center justify-content-center">
              <div className="flex w-full justify-content-center">
                <CustomIcons.SuccessIcon
                  width={100}
                  height={100}
                  viewBox={null}
                  svgColor="#179717"
                />
              </div>
              <div style={successTitle}> Successful</div>
              <div style={successSubtitle} className="pb-4">
                {" "}
                You have successfully edit an emergency
              </div>
              <Button
                type="primary"
                ghost
                onClick={handleClose}
                size="large"
                className="w-4"
              >
                close
              </Button>
            </div>
          ) : (
            <>
              <div
                className="flex w-12 py-3 justify-content-start"
                style={modelTitle}
              >
                Edit {currentEmergency?.emergencyType}
              </div>

              <NewEmergencyForm
                emergencyValues={currentEmergency}
                onFormFinish={(emergency: IEmergency) =>
                  handleEditEmergency(emergency)
                }
                onModelClose={() => setOpenEditModal(false)}
                isLoading={editEmergency.isLoading}
                isSuccess={editEmergency.isSuccess}
              />
            </>
          )}
        </div>
      </Modal>
      <Modal
        centered
        open={openPractitionerModal}
        onOk={() => setOpenPractitionerModal(false)}
        onCancel={() => setOpenPractitionerModal(false)}
        width={550}
        closeIcon={
          <CustomIcons.CloseIcon
            width={30}
            height={30}
            viewBox={null}
            svgColor="#1C274C"
          />
        }
        footer={null}
      >
        <div className="w-full pt-2 mx-2">
          {addOperator.isSuccess ? (
            <div className="flex w-full h-full gap-2 flex-column align-items-center justify-content-center">
              <div className="flex w-full justify-content-center">
                <CustomIcons.SuccessIcon
                  width={100}
                  height={100}
                  viewBox={null}
                  svgColor="#179717"
                />
              </div>
              <div style={successTitle}> Successful</div>
              <div style={successSubtitle} className="pb-4">
                {" "}
                You have successfully added a new practitioner
              </div>
              <Button
                type="primary"
                ghost
                onClick={handleClose}
                size="large"
                className="w-4"
              >
                close
              </Button>
            </div>
          ) : (
            <>
              <div
                className="flex w-12 py-3 justify-content-start"
                style={modelTitle}
              >
                Create new practitioner
              </div>

              <NewUserForm
                onFormFinish={(user: IUser) => handleAddUser(user)}
                onModelClose={() => setOpenPractitionerModal(false)}
                isLoading={addOperator.isLoading}
                isSuccess={addOperator.isSuccess}
              />
            </>
          )}
        </div>
      </Modal>
    </div>
  );
});

export default Emergencies;

/* eslint-disable no-useless-catch */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
import { API } from "../config/axios";

class CPFResponder {
  getCPFResponders = async (
    page: number,
    pageSize: number
  ): Promise<ICPFResponse> => {
    const response = await API.get(
      `Admin_CPFResponder/CPFResponderTable?pageNo=${page}&pageSize=${pageSize}`
    );

    return response.data;
  };

  createNewCPFResponder = async (body: ICPFResponder) => {
    try {
      const response = await API.post("Admin_CPFResponder", body);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  getCPFResponderDetails = async (
    id: number
  ): Promise<ICPFResponderDetails> => {
    const response = await API.get(`Admin_CPFResponder/${id}`);
    return response.data;
  };

  updateCPFResponder = async (body: ICPFResponderDetails) => {
    try {
      const response = await API.put(`Admin_CPFResponder`, body);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  bulkCreateNewCPFResponders = async (body: ICPFResponderExcelUpload) => {
    try {
      const response = await API.post("Admin_CPFResponder/Upload", body);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}

export default new CPFResponder();

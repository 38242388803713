/* eslint-disable @typescript-eslint/no-shadow */
import React from "react";
import { Button, ConfigProvider } from "antd";
import styled from "styled-components";

interface ICustomOutlinedButton {
  title: string;
  color: string;
  borderColor?: string;
  className?: string;
  onClick?: () => void;
  [x: string]: any;
}

const CustomOutlinedButton: React.FC<ICustomOutlinedButton> = ({
  title,
  color,
  className = "",
  onClick,
  borderColor,
  ...props
}) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: color,
        },
      }}
    >
      <Button
        style={{ borderColor: color }}
        className={className}
        onClick={onClick}
        {...props}
      >
        <div
          style={{
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "14px",
            textAlign: "center",
            color,
          }}
        >
          {title}
        </div>
      </Button>
    </ConfigProvider>
  );
};

export default CustomOutlinedButton;

import { Button, Form, Input, Select } from "antd";
import React, { useEffect } from "react";
import { faker } from "@faker-js/faker";
import { copyObjects } from "../../util/copyObjects";

type ChangePasswordProp = {
  onFormFinish: (changePassword: IChangePassword) => void;
  onModelClose: () => void;
  isLoading: boolean;
  isSuccess: boolean;
};

const ChangePassword = ({
  onFormFinish,
  onModelClose,
  isLoading,
  isSuccess,
}: ChangePasswordProp) => {
  const [form] = Form.useForm();

  const onFinish = async (formValues: any) => {
    const values: IChangePassword = copyObjects(
      {},
      {
        userId: 0,
        newPassword: formValues.newPassword,
        oldPassword: formValues.oldPassword,
      }
    );

    await onFormFinish(values);
  };

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
    }
  }, [isSuccess]);

  const handleClose = () => {
    form.resetFields();
    onModelClose();
  };

  return (
    <Form
      name="changePassword"
      layout="vertical"
      form={form}
      onFinish={onFinish}
    >
      <Form.Item
        name="oldPassword"
        label="Current Password"
        rules={[
          { required: true, message: "Please input the current password!" },
        ]}
        className="mb-1"
      >
        <Input.Password
          size="large"
          placeholder="Current Password"
          className="mb-2"
        />
      </Form.Item>

      <Form.Item
        name="newPassword"
        label="New Password"
        rules={[{ required: true, message: "Please input the new password!" }]}
        className="mb-1"
      >
        <Input.Password
          size="large"
          placeholder="New Password"
          className="mb-2"
        />
      </Form.Item>
      <Form.Item
        name="confirmPassword"
        label="Confirm New Password"
        rules={[
          { required: true, message: "Please input the confirm password!" },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("newPassword") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("The new password that you entered do not match!")
              );
            },
          }),
        ]}
        className="mb-1"
      >
        <Input.Password
          size="large"
          placeholder="Confirm Password"
          className="mb-2"
        />
      </Form.Item>
      <Form.Item className="w-12 mb-1">
        <div className="flex w-full gap-2 col-12 justify-content-end align-content-center">
          <Button
            type="primary"
            ghost
            onClick={handleClose}
            size="large"
            className="w-3"
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            size="large"
            className="w-3 bg-[#1757a2] shadow-none"
          >
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default ChangePassword;

import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
  useInfiniteQuery,
} from "@tanstack/react-query";
import Cookies from "js-cookie";

import { AxiosError } from "axios";
import { PanicType } from "@/services";
import { toast } from "sonner";
import { queries } from "../services/queries";

export const usePanicTypes = () => {
  const token = Cookies.get("token"); // Retrieve the token from cookies
  return useQuery({
    ...queries.panicTypes.getAllPanicTypes,
    queryFn: () => PanicType.getAllPanicTypes(),
    refetchOnWindowFocus: false,
    enabled: !!token,
  });
};

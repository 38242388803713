/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
import { API } from "../config/axios";

class Auth {
  async login(email: string, password: string) {
    try {
      const response = await API.post("/UserAuth/authorizeUser", {
        emailAddress: email,
        password,
      });
      if (response.data === 0) return { isSuccess: false };
      return { isSuccess: true, data: response.data };
    } catch (error) {
      return { isSuccess: false };
    }
  }

  async sendOtpVerify(phoneNumber: string) {
    try {
      // Make a GET request using the axios API instance
      const response = await API.get(
        `/userAuth/SendSMSVerify?phoneNumber=${phoneNumber}`
      );

      // Check if response status is within the range 200-299 (success range for HTTP status codes)
      if (response.status >= 200 && response.status < 300) {
        // Return the response data
        return { data: response.data };
      }
      // Return null data if status code is outside 200-299 range
      return { data: null };
    } catch (error) {
      // Return null data on error
      return { data: null };
    }
  }

  async verifyOtp(phoneNumber: string, otp: string) {
    try {
      const response = await API.post("/userAuth/CheckVerify", {
        phoneNumber,
        otpCode: otp,
      });
      console.log(response);
      if (response.status >= 200 && response.status < 300) {
        // Return the response data
        return { isSuccess: true };
      }
      // Return null data if status code is outside 200-299 range
      return { isSuccess: false };
    } catch (error) {
      return { isSuccess: false };
    }
  }
}

export default new Auth();

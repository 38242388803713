/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import { Tag } from "antd";
import { Button } from "@/components/ui/button"; // Adjust the path as necessary
import {
  tableBody,
  tableBody_2,
  tableHeader,
  TableHeader_2,
} from "@/styles/TableStyles"; // Adjust the path as necessary
import { useModal } from "@/hooks/useModalStore";
import { useGetRespondersByCompanyId } from "@/hooks/useResponder";
import Spin from "../widgets/Spinner/Spin";
import { useHistory } from "react-router-dom";

type ResponderTableProps = {
  companyId: number;
};

type ResponderStatusColor = {
  bgColor: string;
  textColor: string;
};

const AllResponderTable: React.FC<ResponderTableProps> = ({ companyId }) => {
  const [first, setFirst] = useState(0);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(10);

  const { data: responseData, isLoading } = useGetRespondersByCompanyId(
    page,
    rows,
    companyId
  );
  useEffect(() => {
    // reset page and row
    setFirst(0);
    setRows(10);
    setPage(1);
  }, [companyId]);

  const navigate = useHistory();

  const navigateTo = (id: string) => {
    navigate.push(`/home/responder-details/${id}`);
  };

  const onPage = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
    setPage(event.page + 1);
  };

  const actionBodyTemplate = (rowData: IResponderDetails) => {
    return (
      <Button
        variant="link"
        className="min-w-12 text-appMainColor"
        onClick={() => navigateTo(rowData.id.toString())}
      >
        View More
      </Button>
    );
  };

  const dateBodyTemplate = (rowData: IResponderDetails) => {
    return moment(rowData.createdAt).format("YYYY-MM-DD");
  };

  const statusBodyTemplate = (rowData: IResponderDetails) => {
    // Assuming that each panicType can have a status, map through them
    return (
      <div>
        {rowData.panicTypes.map((panicType, index) => {
          const color = getSeverity(panicType.status);
          return (
            <Tag
              key={index}
              color={color.bgColor} // Assuming getSeverity returns an array, adjust if your implementation differs
              className={`${color.bgColor} px-2.5 py-0.5 rounded`}
            >
              <p
                className={`${color.textColor} flex justify-center items-center mx-0 my-0`}
                style={{ color: `${color.textColor}` }}
              >
                {panicType.name}-{panicType.status}
              </p>
            </Tag>
          );
        })}
      </div>
    );
  };

  const getShiftSeverity = (status: string) => {
    switch (status) {
      case "Active":
        return "success";
      case "InActive":
        return "error";
      default:
        return "success";
    }
  };

  const shiftStatusBodyTemplate = (rowData: IResponderDetails) => {
    return (
      <Tag color={getShiftSeverity(rowData.shiftStatus as string)}>
        {rowData.shiftStatus}
      </Tag>
    );
  };

  const getSeverity = (
    ApplicationStatus: ResponderStatus
  ): ResponderStatusColor => {
    switch (ApplicationStatus) {
      case "Active":
        return { bgColor: "#D2F6C1", textColor: "#369A03" };
      case "Expired":
        return { bgColor: "bg-orange-400", textColor: "text-orange-900" };
      case "Suspended":
        return { bgColor: "#FDD6C7", textColor: "#CC3201" };
      default:
        return { bgColor: "#D2F6C1", textColor: "#369A03" };
    }
  };
  return (
    <Spin tip="Fetching responders" spinning={isLoading}>
      <DataTable
        value={responseData?.data || []}
        totalRecords={responseData?.totalCount || 0}
        paginator
        first={first}
        rows={rows}
        lazy // need to enable this so it knows to use backend
        rowsPerPageOptions={[10, 25, 50, 100]}
        onPage={onPage}
        dataKey="id"
        emptyMessage="No responders found."
        rowHover
        scrollable
        scrollHeight="45rem"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} responders"
        stateStorage="session"
        stateKey="dt-state-responders-table"
        className="mx-3"
      >
        <Column
          field="firstName"
          header="First name"
          headerStyle={TableHeader_2}
          bodyStyle={tableBody_2}
          className="bg-transparent"
        />
        <Column
          field="lastName"
          header="Last name"
          headerStyle={TableHeader_2}
          bodyStyle={tableBody_2}
          className="bg-transparent"
        />
        <Column
          field="companyName"
          header="Responders"
          headerStyle={TableHeader_2}
          bodyStyle={tableBody_2}
          className="bg-transparent"
        />
        <Column
          field="emailAddress"
          header="Email"
          headerStyle={TableHeader_2}
          bodyStyle={tableBody_2}
          className="bg-transparent"
        />
        <Column
          field="status"
          header="Status"
          headerStyle={TableHeader_2}
          bodyStyle={tableBody_2}
          className="bg-transparent"
          body={shiftStatusBodyTemplate}
        />
        <Column
          field="createdAt"
          header="Created At"
          filterField="date"
          dataType="date"
          headerStyle={TableHeader_2}
          bodyStyle={tableBody_2}
          className="bg-transparent"
          body={dateBodyTemplate}
        />
        <Column
          exportable={false}
          body={actionBodyTemplate}
          headerStyle={TableHeader_2}
          bodyStyle={tableBody_2}
          className="bg-transparent"
        />
      </DataTable>
    </Spin>
  );
};

export default AllResponderTable;

import React, { useContext, useEffect } from "react";
import { Container, BodyContainer } from "../../styles/ContainerStyles";
import HeaderComponent from "../../components/Header/Header";
import { Tabs, TabsProps } from "antd";
import AuthContext from "../../contexts/AuthContext";
import GeneralOperators from "./components/GeneralOperators";
import Item from "antd/es/list/Item";

const OperatorTable = () => {
  const { user } = useContext(AuthContext);
  const key = `key-${user?.emergencyPractitioners[0].emergencyId}`;
  const id = `${user?.emergencyPractitioners[0].emergencyId}`;
  const emergency =
    user?.emergencyPractitioners?.[0].emergency?.emergencyType.toString();

  const items: TabsProps["items"] = [
    {
      key: key,
      label: emergency,
      children: <div key={key}></div>,
    },
  ];

  return (
    <div className="w-full h-full">
      <Container className="w-full h-full">
        <BodyContainer className="w-full">
          <HeaderComponent title="Operators" />
          <div className="px-3">
            {emergency ? (
              <GeneralOperators
                emergencyId={id}
                emergencyName={emergency}
                key={key}
              />
            ) : (
              <GeneralOperators emergencyId="" emergencyName="" />
            )}
          </div>
        </BodyContainer>
      </Container>
    </div>
  );
};

export default OperatorTable;

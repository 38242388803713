import { Button, Form, Input, Alert, message } from "antd";
import React, { useEffect, useState } from "react";
import { CustomIcons } from "../../assets";
import Colors from "../../config/Colors";

type RejectApplicationReasonFormProp = {
  onFormFinish: (reason: string) => void;
  onModelClose: () => void;
  isLoading: boolean;
  isSuccess: boolean;
};

const MAX_LENGTH = 200;

const RejectApplicationReasonForm = ({
  onFormFinish,
  onModelClose,
  isLoading,
  isSuccess,
}: RejectApplicationReasonFormProp) => {
  const [charactersLeft, setCharactersLeft] = useState(MAX_LENGTH);
  const [form] = Form.useForm();
  const onFinish = async (formValues: any) => {
    const values: string = formValues.reason;
    onFormFinish(values);
  };

  // New reset function
  const resetFormAndState = () => {
    form.resetFields();
  };

  const handleClose = () => {
    resetFormAndState();
    setCharactersLeft(MAX_LENGTH);
    onModelClose();
  };

  useEffect(() => {
    if (isSuccess) {
      setCharactersLeft(MAX_LENGTH);
      form.resetFields();
    }
  }, [isSuccess]);

  return (
    <>
      <Form
        form={form}
        name="existingUserForm"
        onFinish={onFinish}
        layout="vertical"
        style={{ width: "100%" }}
        className="grid"
        labelWrap
      >
        <div className="pt-3 col-12">
          <Form.Item
            name="reason"
            label="Provide a reason"
            rules={[
              { required: true, message: "Please enter value!" },
              {
                max: MAX_LENGTH,
                message: `Description cannot be longer than ${MAX_LENGTH} characters`,
              },
            ]}
            className="mb-1"
          >
            <>
              <Input.TextArea
                placeholder="Enter paragraph text here"
                size="large"
                className="w-12"
                maxLength={MAX_LENGTH}
                onChange={(e) => {
                  form.setFieldsValue({ reason: e.target.value });
                  setCharactersLeft(MAX_LENGTH - e.target.value.length);
                }}
              />
              {charactersLeft <= 100 && (
                <div
                  className="py-1"
                  style={{
                    fontSize: "13px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    color: Colors.subtleTextColor,
                  }}
                >
                  {charactersLeft} characters left
                </div>
              )}
            </>
          </Form.Item>
        </div>

        <Form.Item className="w-12 pt-2 mb-1">
          <div className="flex w-full gap-2 col-12 justify-content-end align-content-center">
            <Button
              type="primary"
              ghost
              onClick={handleClose}
              size="large"
              className="w-3"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              size="large"
              className="w-3 bg-[#1757a2] shadow-none"
            >
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};

export default RejectApplicationReasonForm;

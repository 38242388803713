import { Button, Form, Input, Alert } from "antd";
import React, { useEffect } from "react";
import { CustomIcons } from "../../assets";

type ExistingUserFormProp = {
  onFormFinish: (key: string) => void;
  onModelClose: () => void;
  isLoading: boolean;
  isSuccess: boolean;
};

const ExistingUserForm = ({
  onFormFinish,
  onModelClose,
  isLoading,
  isSuccess,
}: ExistingUserFormProp) => {
  const [form] = Form.useForm();
  const onFinish = async (formValues: any) => {
    const values: string = formValues.key;
    onFormFinish(values);
  };

  // New reset function
  const resetFormAndState = () => {
    form.resetFields();
  };

  const handleClose = () => {
    resetFormAndState();
    onModelClose();
  };

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
    }
  }, [isSuccess]);

  return (
    <>
      <Form
        form={form}
        name="existingUserForm"
        onFinish={onFinish}
        layout="vertical"
        style={{ width: "100%" }}
        className="grid"
        labelWrap
      >
        <Alert
          message="Please make sure that the user already has an account with Safer City."
          type="info"
          showIcon
        />
        <div className="pt-3 col-12">
          <Form.Item
            name="key"
            label=""
            rules={[
              { required: true, message: "Please enter value!" },
              {
                validator: async (_, value) => {
                  const emailRegex =
                    /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
                  const phoneRegex = /^\+27\d{9}$/;

                  if (emailRegex.test(value) || phoneRegex.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      "Please enter a valid email or phone number with +27 code."
                    )
                  );
                },
              },
            ]}
            className="mb-1"
            extra="The number needs to contain a +27 instead of 0"
          >
            <Input
              placeholder="Enter Email or Phone Number Here"
              size="large"
              className="w-12"
              min={1}
              max={50}
            />
          </Form.Item>
        </div>

        <Form.Item className="w-12 pt-2 mb-1">
          <div className="flex w-full gap-2 col-12 justify-content-end align-content-center">
            <Button
              type="primary"
              ghost
              onClick={handleClose}
              size="large"
              className="w-3"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              size="large"
              className="w-3 bg-[#1757a2] shadow-none"
            >
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </>
  );
};

export default ExistingUserForm;

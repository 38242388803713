/* eslint-disable lines-between-class-members */
/* eslint-disable no-var */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
import { API, APILocal, APIStaging } from "../config/axios";

class CommunityMessage {
  getCommunityCaseMessage = async (
    communityCaseId: number | null | undefined | string
  ): Promise<ICommunityMessage[]> => {
    let url = `CommunityMessage/CommunityCase/`;
    if (communityCaseId) {
      url = `${url}${communityCaseId}`;
      const response = await API.get<ICommunityMessage[]>(url);
      return response.data;
    }

    return Promise.reject<ICommunityMessage[]>("Id is required");
  };

  postMessage = async (body: ICommunityMessage) => {
    return API.post("CommunityMessage", body);
  };

  resolveCase = async (
    caseId: string | number
  ): Promise<APIResponse<ICommunityCase>> => {
    return API.put(`Admin_CommunityCase/ResolveCase?id=${caseId}`);
  };
}

export default new CommunityMessage();

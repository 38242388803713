// import { cUser } from "../@types/User";

import { cUser } from "../services/Classes/Cuser";

/* eslint-disable no-restricted-syntax */
export const copyObjects = <T, Y>(obj: T, values: Y) => ({ ...obj, ...values });

export function toUser(userObject: IUser): cUser {
  const user = new cUser();
  Object.assign(user, userObject);
  return user;
}

export function toIUser(userInstance: cUser): IUser {
  //const userObject = { ...userInstance };
  return userInstance as IUser;
}

import React from 'react';
import { Modal, Button } from 'antd';

interface DeleteModalProps {
  itemName: string;
  onConfirm: () => void;
  onCancel: () => void;
  visible: boolean;
  isLoading: boolean;
}

const DeleteModal: React.FC<DeleteModalProps> = ({ itemName, onConfirm, onCancel, visible, isLoading }) => {
  return (
    <Modal
      title="Confirm Deletion"
      visible={visible}
      onOk={onConfirm}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={isLoading} danger onClick={onConfirm}>
          Delete
        </Button>,
      ]}
    >
      <p>You are about to delete {itemName}. Are you sure you want to proceed?</p>
    </Modal>
  );
};

export default DeleteModal;

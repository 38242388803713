import { createQueryKeys } from "@lukemorales/query-key-factory";
import { PostApplication } from "../index";

export const postApplication = createQueryKeys("postApplications", {
  all: null,
  getDashboardDetails: (communityId: number | string | null | undefined) => ({
    queryKey: [{ communityId }],
    queryFn: () => PostApplication.getDashboardDetails(communityId),
  }),
  getCommunityUsersByStatus: (
    communityId: number | string | null | undefined,
    status: number | string | null | undefined
  ) => ({
    queryKey: [{ communityId, status }],
    queryFn: () =>
      PostApplication.getCommunityUsersByStatus(communityId, status),
  }),
  getApplicationDetails: (
    applicationId: number | string | null | undefined
  ) => ({
    queryKey: [{ applicationId }],
    queryFn: () => PostApplication.getApplicationDetails(applicationId),
  }),
});

import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { queries } from "../services/queries";
import { Dashboard } from "../services";
import { dashboard } from "../services/queries/dashboardQueries";

export function useMainDashboard(): UseQueryResult<IDashboard> {
  return useQuery({
    ...queries.dashboard.all,
    queryFn: () => Dashboard.getDashboard(),
    staleTime: 60 * 1000,
  });
}

export function useDetailOperatorDashboard(id: string | null) {
  return useQuery({ ...queries.dashboard.detail(id || 0), enabled: !!id });
}

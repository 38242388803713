/* eslint-disable no-unused-vars */
// styles.ts

// eslint-disable-next-line import/no-extraneous-dependencies
import styled from "styled-components";
import Colors from "../../config/Colors";

export const Title = styled.div`
  font-size: 33px;
  font-weight: normal;
  text-align: left;
  align-self: flex-start;
  font-weight: 700;
  line-height: 51px;
  display: flex;
  color: ${Colors.mainTextColor};
`;

export const NameStyle = styled.div`
  color: ${Colors.mainTextColor};
  text-align: center;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0px solid black;
  width: 100%;
  padding: 16px;
  background-color: #fbfbfb;
  top: 0;
`;

export const Profile = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 16px;
  background-color: gray;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

/* eslint-disable no-useless-catch */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
import moment from "moment";
import { API, APILocal, APIStaging } from "../config/axios";
import { useGetProvinces } from "../hooks/useCommunityCodes";
import { get } from "lodash";
import Body from "@/layouts/styles";

class CommunityCode {
  getAllCommunityCodes = async (): Promise<IAllCommunityCode[]> => {
    const response = await API.get("Admin_CommunityCode");
    return response.data;
  };

  getCountries = async (): Promise<ICountry[]> => {
    const response = await API.get("Admin_CommunityCode/GetCountries");
    return response.data;
  };

  getProvinces = async (
    countryId: number | undefined | null
  ): Promise<IProvince[]> => {
    const url = `Admin_CommunityCode/GetProvinces${
      countryId && Number(countryId) > 0 ? `?countryId=${countryId}` : ""
    }`;
    const response = await API.get(url);
    return response.data as IProvince[];
  };

  getProvincesByCountryName = async (
    countryName: string
  ): Promise<IProvince[]> => {
    const response = await API.get(
      `Admin_CommunityCode/GetProvincesByCountryName?countryName=${countryName}`
    );
    return response.data as IProvince[];
  };

  getUnusedAllCommunityCodes = async (): Promise<IAllCommunityCode[]> => {
    const response = await API.get("Admin_CommunityCode/GetUsedCommunityCode");
    return response.data;
  };

  addCommunityCode = async (
    body: IPostCommunityCodeHandler
  ): Promise<IAllCommunityCode> => {
    try {
      const response = await API.post("Admin_CommunityCode", body);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  updateCommunityCode = async (body: IUpdateCommuntiyCode): Promise<any> => {
    try {
      const response = await API.put("Admin_CommunityCode", body);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  getUserWithReferral = async (): Promise<IReferralUser[]> => {
    const response = await API.get("Admin_CommunityCode/getUserWithReferral");
    return response.data;
  };

  getAllCommunityCodeByCode = async (
    code: string
  ): Promise<IAllCommunityCode> => {
    const response = await API.get(
      `Admin_CommunityCode/GetCommunityCodeByCode?code=${code}`
    );
    return response.data;
  };

  getPagintedCommuntiyCode = async (
    first: number = 0,
    rows: number = 10,
    filters: AffiliationFilters
  ): Promise<ICommunityCodeResponse> => {
    const response = await API.get(
      `Admin_CommunityCode/PaginatedCommunityCodes?first=${first}&rows=${rows}&filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`
    );
    return response.data;
  };

  getReferralUsersList = async (
    filters: Filters | null,
    page: number | string | null | any = null
  ): Promise<IReferralUser[]> => {
    const url = `Admin_CommunityCode/getUserWithReferral`;
    const params: { [key: string]: any } = {};
    if (filters) {
      params.filters = filters;
    }
    if (page) {
      params.page = page;
    }
    // Make the API call and return the response data
    const response = await API.get<IReferralUser[]>(url, { params });
    return response.data;
  };

  getSearchReferralUsers = async (
    filters: Filters | null,
    page: number | string | null | any = null,
    limit: number | null = null,
    search: string | null
  ): Promise<IReferralUser[]> => {
    const url = `Admin_CommunityCode/getUserWithReferral`;
    const params: { [key: string]: any } = {};

    if (filters) {
      params.filters = filters;
    }
    if (search) {
      params.search = search;
    }
    if (page) {
      params.page = page;
    }

    if (limit) {
      params.limit = limit;
    }
    // Make the API call and return the response data
    const response = await API.get<IReferralUser[]>(url, { params });
    return response.data;
  };

  getUnlinkedCommunityCodes = async () => {
    const response = await API.get<IAllCommunityCode[]>(
      `Admin_CommunityCode/getUnlinkedReferralCodes`
    );
    return response.data;
  };

  downloadPdfCommunityCodes = async (filters: AffiliationFilters) => {
    const response = await API.get(
      `Admin_CommunityCode/DownloadUsersPdfCommunity?filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`,
      { responseType: "blob" }
    );

    // Create a Blob from the PDF Stream
    const file = new Blob([response.data], { type: "application/pdf" });

    // Build a URL from the file
    const fileURL = URL.createObjectURL(file);

    const link = document.createElement("a");
    link.href = fileURL;
    link.setAttribute(
      "download",
      `Community_Codes_${moment().format("YYYY-MM-DD")}.pdf`
    ); // You can format this as needed
    document.body.appendChild(link);
    link.click();

    // Clean up
    URL.revokeObjectURL(fileURL);
    link.remove();
  };

  downloadExcelCommunityCodes = async (filters: AffiliationFilters) => {
    try {
      const response = await API.get(
        `Admin_CommunityCode/DownloadUsersExcelCommunity?filters=${encodeURIComponent(
          JSON.stringify(filters)
        )}`,
        { responseType: "blob" }
      );

      // Create a Blob from the PDF Stream
      const file = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Build a URL from the file
      const fileURL = URL.createObjectURL(file);

      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute(
        "download",
        `Community_Codes_${moment().format("YYYY-MM-DD")}.xlsx`
      ); // You can format this as needed
      document.body.appendChild(link);
      link.click();

      // Clean up
      URL.revokeObjectURL(fileURL);
      link.remove();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  // Affiliation Code

  getPagintedAffiliationCode = async (
    first: number = 0,
    rows: number = 10,
    filters: AffiliationFilters
  ): Promise<IAffiliationCodeResponse> => {
    const response = await API.get(
      `Admin_CommunityCode/PaginatedReferralCodes?first=${first}&rows=${rows}&filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`
    );
    return response.data;
  };

  getAllAffiliationCodes = async (): Promise<IAllAffiliationCode[]> => {
    const response = await API.get("Admin_CommunityCode/GetAllAffiliation");
    return response.data;
  };

  getUnusedAllAffiliationCodes = async (): Promise<IAllAffiliationCode[]> => {
    const response = await API.get("Admin_CommunityCode/GetUsedReferralCode");
    return response.data;
  };

  getUserWithAffiliation = async (): Promise<IReferralUser[]> => {
    const response = await API.get(
      "Admin_CommunityCode/getUserWithAffiliation"
    );
    return response.data;
  };

  addAffiliationCode = async (
    body: IAffiliationCodeCreateHandler
  ): Promise<IAllAffiliationCode> => {
    try {
      const response = await API.post(
        "Admin_CommunityCode/AffiliationCode",
        body
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  updateAffiliationCode = async (
    body: IUpdateAffiliationCode
  ): Promise<IAllAffiliationCode> => {
    try {
      const response = await API.put(
        "Admin_CommunityCode/UpdateReferralCode",
        body
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  downloadPdfReferralCodes = async (filters: AffiliationFilters) => {
    const response = await API.get(
      `Admin_CommunityCode/DownloadUsersPdfAffiliation?filters=${encodeURIComponent(
        JSON.stringify(filters)
      )}`,
      { responseType: "blob" }
    );

    // Create a Blob from the PDF Stream
    const file = new Blob([response.data], { type: "application/pdf" });

    // Build a URL from the file
    const fileURL = URL.createObjectURL(file);

    const link = document.createElement("a");
    link.href = fileURL;
    link.setAttribute(
      "download",
      `Referral_Codes_${moment().format("YYYY-MM-DD")}.pdf`
    ); // You can format this as needed
    document.body.appendChild(link);
    link.click();

    // Clean up
    URL.revokeObjectURL(fileURL);
    link.remove();
  };

  downloadExcelReferralCodes = async (filters: AffiliationFilters) => {
    try {
      const response = await API.get(
        `Admin_CommunityCode/DownloadUsersExcelAffiliation?filters=${encodeURIComponent(
          JSON.stringify(filters)
        )}`,
        { responseType: "blob" }
      );

      // Create a Blob from the PDF Stream
      const file = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // Build a URL from the file
      const fileURL = URL.createObjectURL(file);

      const link = document.createElement("a");
      link.href = fileURL;
      link.setAttribute(
        "download",
        `Referral_Codes_${moment().format("YYYY-MM-DD")}.xlsx`
      ); // You can format this as needed
      document.body.appendChild(link);
      link.click();

      // Clean up
      URL.revokeObjectURL(fileURL);
      link.remove();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  getPrimaryCategory = async (): Promise<IAffiliationPrimaryCategory[]> => {
    const response = await API.get("Admin_CommunityCode/GetPrimaryCategory");
    return response.data;
  };

  getSecondaryCategory = async (): Promise<IAffiliationSecondaryCategory[]> => {
    const response = await API.get("Admin_CommunityCode/GetSecondaryCategory");
    return response.data;
  };

  getTertiaryCategory = async (): Promise<IAffiliationTertiaryCategory[]> => {
    const response = await API.get("Admin_CommunityCode/GetTertiaryCategory");
    return response.data;
  };

  getAffiliationCodeByCode = async (
    code: string
  ): Promise<IAffiliationCode> => {
    const response = await API.get(
      `Admin_CommunityCode/GetAffiliationCodeByCode?code=${code}`
    );
    return response.data;
  };
}

export default new CommunityCode();

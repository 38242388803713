import { createQueryKeys } from "@lukemorales/query-key-factory";
import Companies from "../Companies";

export const companies = createQueryKeys("companies", {
  getCompanyCardsData: null,
  getCompanies: null,
  getCompanyMembersData: (
    id: string | number,
    pageNumber: string | number | null,
    pageSize: number | string | null = 6,
    userName?: string | null,
    subscriptionName?: string | null,
    status?: number | null,
    turnover?: boolean | null
  ) => ({
    queryKey: ["CompanyMembers", { id, pageNumber, userName }],
    queryFn: (): Promise<IPaginatedCompanyMembersResponse> =>
      Companies.getCompanyMembersData(
        id,
        pageNumber,
        pageSize,
        userName,
        subscriptionName,
        status,
        turnover
      ),
  }),

  getCompanyPaymentsData: (id: string | number) => ({
    queryKey: [id],
    queryFn: (): Promise<ICompanyPayments[]> =>
      Companies.getCompanyPaymentData(id),
  }),

  getEditCompanyData: (companyId: string | number) => ({
    queryKey: [companyId],
    queryFn: (): Promise<IEditCompany> =>
      Companies.getEditCompanyData(companyId),
  }),

  getCompanyPaginatedCardsData: (
    pageNumber: string | number | null,
    pageSize: number | string | null,
    name: string | null
  ) => ({
    queryKey: [pageNumber ?? 1, pageSize ?? 6, name ?? ""],
    queryFn: (): Promise<ICompanyCardResponse> =>
      Companies.getPaginatedCompanyCardsData(pageNumber, pageSize, name),
  }),

  //  getSpreadSheetUrl: null,
});

import React, { useEffect, useState } from "react";
import { Modal, message, Tabs } from "antd";
import type { TabsProps } from "antd";
import { modelTitle } from "../../styles/ModalStyles";
import { CustomIcons } from "../../assets/index";
import CommunityAdmins from "../Tabs/Community/CommunityAdmins";

import { useLinkCodeToCommunity } from "../../hooks/useCommunities";
import LinkedCommunityCodes from "../Tabs/Community/LinkedCommunityCodes";
import SearchReferralCode from "../Tabs/Community/SearchReferralCode";

interface CommunitySettingsProp {
  communityId: number | null;
  openSettingsModal: boolean;
  setOpenSettingsModal: (open: boolean) => void;
}

const CommunitySettingsModal = ({
  communityId,
  openSettingsModal,
  setOpenSettingsModal,
}: CommunitySettingsProp) => {
  const [activeIndex, setActiveIndex] = useState("1");
  const linkToCommunity = useLinkCodeToCommunity();

  const onTabChange = (key: string) => {
    setActiveIndex(key);
  };

  const handleClose = async () => {
    setOpenSettingsModal(false);
    linkToCommunity.reset();
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Admins`,
      children: <CommunityAdmins communityId={communityId} />,
    },
    {
      key: "2",
      label: `Link new referral code`,
      children: communityId ? (
        <SearchReferralCode communityId={communityId} />
      ) : null,
    },
    {
      key: "3",
      label: `Linked`,
      children: <LinkedCommunityCodes communityId={communityId} />,
    },
  ];
  return (
    <Modal
      centered
      open={openSettingsModal}
      onOk={() => setOpenSettingsModal(false)}
      onCancel={() => handleClose()}
      width={550}
      closeIcon={
        <CustomIcons.CloseIcon
          width={30}
          height={30}
          viewBox={null}
          svgColor="#1C274C"
        />
      }
      footer={null}
    >
      <div className="flex w-12 py-3 justify-content-start" style={modelTitle}>
        Community Settings
      </div>
      <Tabs
        defaultActiveKey={activeIndex}
        items={items}
        onChange={onTabChange}
        animated
      />
    </Modal>
  );
};

export default CommunitySettingsModal;

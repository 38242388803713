import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
  useInfiniteQuery,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { ResponderVehicles, ResponderCompany } from "@/services";
import { toast } from "sonner";
import { queries } from "../services/queries";

export const useGetResponderCompanyVehicles = (
  pageSize: number,
  companyId: number,
  search?: string
) => {
  return useInfiniteQuery(
    ["responderVehicles", "responderCompany", { pageSize, companyId, search }],
    ({ pageParam = 1 }) =>
      ResponderVehicles.getResponderCompanyVehicles(
        pageParam,
        pageSize,
        companyId,
        search
      ),
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPage = allPages.length + 1;
        return lastPage.hasNextPage ? nextPage : undefined; // Assuming lastPage includes hasNextPage indicator
      },
    }
  );
};

export const useCreateResponderVehicle = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (vehicle: IEditResponderVehicle) =>
      ResponderVehicles.createVehicle(vehicle),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["responderVehicles"]);
        return data;
      },
      onError: (err: Error) => {
        if (err.message) {
          toast.error(err.message);
        } else {
          toast.error("An error occurred");
        }
      },
      meta: {
        useError: true,
      },
    }
  );
};

export const useUpdateResponderVehicle = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (vehicle: IEditResponderVehicle) =>
      ResponderVehicles.updateVehicle(vehicle),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(["responderVehicles"]);
        return data;
      },
      onError: (err: Error) => {
        if (err.message) {
          toast.error(err.message);
        } else {
          toast.error("An error occurred");
        }
      },
      meta: {
        useError: true,
      },
    }
  );
};

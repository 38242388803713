import styled, { CSSProperties } from "styled-components";
import Colors from "../../config/Colors";

export const WINDOW_WIDTH = window.innerWidth;
export const WINDOW_HEIGHT = window.innerHeight;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: WINDOW_WIDTH;
  height: WINDOW_HEIGHT;
  color: black;
  background: ${Colors.backgroundColor};
`;

export const BodyContainer = styled.div`
  justify-content: space-between;
  p {
    font-weight: bold;
  }
  overflow-y: auto;
  width: 90%;
  max-height: 100vh;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #ffffff;
  border-radius: 10px;
  display: flex;
  width: 100%;
  height: 100%;
`;
export const SubHeading = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  align-items: center;
  color: #515151;
`;

export const Warning = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  align-items: center;
  text-decoration: underline;
  color: ${Colors.appMainColor};
`;

export const successTitle: CSSProperties = {
  color: `${Colors.mainTextColor}`,
  fontWeight: 700,
  fontSize: 22,
  fontStyle: "normal",
};
export const successSubtitle: CSSProperties = {
  color: `${Colors.subtleTextColor}`,
  fontWeight: 400,
  fontSize: 14,
  fontStyle: "normal",
};

export const modelTitle: CSSProperties = {
  color: `${Colors.mainTextColor}`,
  fontWeight: 700,
  fontSize: 24,
  fontStyle: "normal",
};

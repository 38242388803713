/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-shadow */
import React, {
  createContext,
  useState,
  useEffect,
  ReactNode,
  useRef,
} from "react";
import localForage from "localforage";
import Cookies from "js-cookie";
import { toast } from "sonner";
import { AxiosError } from "axios";
import { jwtDecode } from "jwt-decode";
import UAParser from "ua-parser-js";
import { API, axiosInterceptor } from "../config/axios";
import "../index.css";
import { CustomIcons } from "../assets";
import Colors from "../config/Colors";
import { UserType } from "../util/enums";
import { isSuccessResponse } from "@/util/helpers";

type AuthContextType = {
  user: IUser | null;
  setUser: React.Dispatch<React.SetStateAction<IUser | null>>;
  tempUser: IUser | null;
  setTempUser: React.Dispatch<React.SetStateAction<IUser | null>>;
  token: string | null;
  setToken: React.Dispatch<React.SetStateAction<string | null>>;
  tempToken: string | null;
  setTempToken: React.Dispatch<React.SetStateAction<string | null>>;
  refreshToken: string | null;
  setRefreshToken: React.Dispatch<React.SetStateAction<string | null>>;
  tempRefreshToken: string | null;
  setTempRefreshToken: React.Dispatch<React.SetStateAction<string | null>>;
  isLoggedIn: boolean;
  hasRole: (roleName: string) => boolean;
  isAdmin: boolean;
  isOperator: boolean;
  isOperatorAdmin: boolean;
  isCommunityAdmin: boolean;
  getUserType: () => number | null;
  getEmergencyId: () => number | null;
  getCommunityId: () => number | null;
  getCommunityName: () => string | null;
  hasRoutePermission: (allowedRoles: string[]) => boolean;
  logout: () => void;
};

const initialAuthContext: AuthContextType = {
  user: null,
  setUser: () => {},
  tempUser: null,
  setTempUser: () => {},
  token: null,
  setToken: () => {},
  tempToken: null,
  setTempToken: () => {},
  refreshToken: null,
  setRefreshToken: () => {},
  tempRefreshToken: null,
  setTempRefreshToken: () => {},
  isLoggedIn: false,
  hasRole: () => false,
  isAdmin: false,
  isOperator: false,
  isOperatorAdmin: false,
  isCommunityAdmin: false,
  getUserType: () => null,
  getCommunityId: () => null,
  getEmergencyId: () => null,
  getCommunityName: () => null,
  hasRoutePermission: () => false,
  logout: () => {},
};

export const AuthContext = createContext<AuthContextType>(initialAuthContext);

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<IUser | null>(null);
  const [tempUser, setTempUser] = useState<IUser | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [tempToken, setTempToken] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);
  const [tempRefreshToken, setTempRefreshToken] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const initialized = useRef(false); // to stop the useEffects from running twice
  const initializedApp = useRef(false);

  const removeLocalStorage = () => {
    setToken(null);
    setRefreshToken(null);
    Cookies.remove("token");
    Cookies.remove("refreshToken");
    localForage.removeItem("user").then(() => setUser(null));
  };

  const logout = () => {
    // Clear the token and user.
    const toastId = toast.loading("Logging you out...");
    API.post("/authentication/logout", {
      token,
      refreshToken,
      device: "WEB",
    })
      .then(() => {
        removeLocalStorage();
        toast.success("Successfully logged out!", {
          id: toastId,
        });
      })
      .catch((error) => {
        toast.error(
          "Something went wrong on our end logging you out, please try again. if the problem persists please contact support.",
          {
            id: toastId,
          }
        );
        // eslint-disable-next-line no-console
        console.error("Logout error:", error);
      })
      .finally(() => {
        setTimeout(() => {
          toast.dismiss(toastId);
        }, 3000);
      });
  };

  useEffect(() => {
    if (!initialized.current) {
      // Attempt to load the user from storage.
      localForage.getItem<IUser>("user").then((storedUser) => {
        setUser(storedUser || null);
        setLoading(false);
      });
      // Attempt to load the token from cookies.
      const cookieToken = Cookies.get("token");
      if (cookieToken) {
        setToken(cookieToken);
        axiosInterceptor(cookieToken);
      }
      const refreshToken = Cookies.get("refreshToken");
      if (refreshToken) {
        setRefreshToken(refreshToken);
      }
    }
  }, []);

  // This for people who don't have refreshToken(for backward compatibility)
  // Reason why we are returning something is because the state doesn't change immediately
  const ensureRefreshToken = async (): Promise<string | null> => {
    if (!refreshToken && token && user) {
      try {
        // designed to issue refresh tokens for valid access tokens
        const { data } = await API.get<string>(
          `/authentication/issueRefreshToken?userId=${user.id}`
        );
        // the backend returns a refresh token
        if (data) {
          setRefreshToken(data);
          // returns the refresh token from the backend
          return data;
        }
        // returns null if there is no data
        return null;
      } catch (error) {
        // return null if there is no data
        return null;
        // Handle errors appropriately (e.g., showing a message, logging out users, etc.)
      }
    }
    // defaults to the the existing refresh token from local storage
    return refreshToken;
  };

  const checkTokenExpiration = async (
    refreshTokenValue: string | null
  ): Promise<string | null> => {
    if (token) {
      const currentTime = Math.floor(Date.now() / 1000); // Current time in Unix timestamp
      const tokenData = jwtDecode(token);
      const tokenExpiration = tokenData.exp || 0;
      const isTokenExpiring = tokenExpiration < currentTime + 5 * 24 * 60 * 60; // 5 days in seconds
      const parser = new UAParser();
      const deviceInfo = parser.getResult();

      const rT = refreshTokenValue || refreshToken;

      if (isTokenExpiring && rT) {
        try {
          // Refresh token logic here
          const { data } = await API.post<APIResult<IRefreshTokenData>>(
            "/authentication/refreshToken",
            {
              token: rT,
              device: "WEB",
              deviceModel: deviceInfo.device.model,
              operatingSystem: `${deviceInfo.os.name} ${deviceInfo.os.version}`,
              deviceDetails: JSON.stringify(deviceInfo),
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (isSuccessResponse(data)) {
            if ("data" in data && data.status === "success") {
              // sets the current token and refresh token
              setToken(data.data.newAccessToken);
              setRefreshToken(data.data.newRefreshToken);
              return data.data.newAccessToken;
            }
            // return token from local storage if there is no data
            return token;
          }
          // return token from local storage if there is an error
          return token;
        } catch (error) {
          // return token from local storage if there is an error
          return token;
          // Handle refresh token errors (e.g., showToast, removeLocalStorage, etc.)
        }
      }
      // returns true if the token is still valid
      return token;
    }
    // return null if there is no token at all
    return null;
  };

  const validateTokenAndFetchUser = async (tokenValue: string | null) => {
    const tokenToUse = tokenValue || Cookies.get("token");
    if (tokenToUse) {
      try {
        const { data, status } = await API.post<IValidateToken>(
          "/authentication/validateToken",
          {
            token: tokenToUse,
            device: "WEB",
          }
        );
        if (data.user) {
          setUser(data.user);
          setToken(token); // Token is still valid
        }
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error("Token validation error:", error);
        if (error instanceof AxiosError) {
          switch (error.response?.status) {
            case 401:
            case 403:
            case 419:
            case 440:
              toast.error("Session expired, please login again");
              break;
            case 404:
              toast.error("User not found");
              break;
            default:
              toast.error("Something went wrong, please try again.");
              break;
          }
          await removeLocalStorage();
        }
      }
    }
    setLoading(false);
  };

  // Whenever the user changes, save it to storage.
  useEffect(() => {
    if (user) {
      localForage.setItem("user", user);
    }
  }, [user]);
  // Whenever the token changes, save it to cookies.
  useEffect(() => {
    if (token) {
      Cookies.set("token", token, {
        expires: 30,
        secure: process.env.NODE_ENV === "production", // Secure only in production
        httpOnly: false,
      });
    }
  }, [token]);
  // Whenever the refresh token changes, save it to cookies.
  useEffect(() => {
    if (refreshToken) {
      Cookies.set("refreshToken", refreshToken, {
        expires: 30,
        secure: process.env.NODE_ENV === "production", // Secure only in production
        httpOnly: false,
      });
    }
  }, [refreshToken]);

  useEffect(() => {
    const initializeApp = async () => {
      if (initializedApp.current) {
        return; // Prevent re-initialization
      }
      // Fetch and check user data presence
      const hasUserData = !!user && !!token; // Simplified check, adapt as needed

      if (!hasUserData) {
        return; // Early return if no user data
      }
      initializedApp.current = true;

      try {
        const refreshTokenValue = await ensureRefreshToken();
        const tokenValue = await checkTokenExpiration(refreshTokenValue);
        await validateTokenAndFetchUser(tokenValue); // Assuming validateToken function handles setting `isLogged` and `isSplashLoading` correctly.
      } catch (error) {}
    };

    initializeApp();
  }, [user]);

  const hasRole = (roleName: string): boolean => {
    return user?.userRoles.some((role) => role.role.name === roleName) || false;
  };

  const isLoggedIn = !!user && !!token;
  const isAdmin = hasRole("SuperAdmin");
  const isOperator = hasRole("EmergencyOperator");
  const isOperatorAdmin = hasRole("EmergencyAdmin");
  const isCommunityAdmin = hasRole("CommunityAdmin");
  const getUserType = () => user?.userTypeId || null;

  const getEmergencyId = (): number | null => {
    if (isOperator || isOperatorAdmin) {
      const emergencyPractitioner = user?.emergencyPractitioners?.[0];
      if (emergencyPractitioner) {
        return emergencyPractitioner.emergencyId;
      }
    }
    return null;
  };

  const getCommunityId = (): number | null => {
    if (isCommunityAdmin) {
      const communityOperator = user?.communityOperators?.[0];
      if (communityOperator) {
        return communityOperator.communityId;
      }
    }
    return null;
  };
  const getCommunityName = (): string | null => {
    if (isCommunityAdmin) {
      return user?.communityOperators?.[0]?.community.communityName || "";
    }
    return null;
  };

  function hasRoutePermission(allowedRoles: string[]): boolean {
    if (user) {
      // Check if any of the user's roles match the allowed roles
      return user.userRoles.some((userRole) =>
        allowedRoles.includes(userRole.role.name)
      );
    }
    return false;
  }

  // If we're still loading the user, don't render the children.
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // This makes the div take up the full height of the viewport.
        }}
      >
        <CustomIcons.EOSThreeDotsLoading
          width={30}
          height={30}
          viewBox={null}
          svgColor={Colors.appMainColor}
        />
      </div>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        tempUser,
        setTempUser,
        token,
        setToken,
        tempToken,
        setTempToken,
        refreshToken,
        setRefreshToken,
        tempRefreshToken,
        setTempRefreshToken,
        isLoggedIn,
        hasRole,
        isAdmin,
        isOperator,
        isOperatorAdmin,
        isCommunityAdmin,
        getUserType,
        getEmergencyId,
        getCommunityId,
        hasRoutePermission,
        getCommunityName,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

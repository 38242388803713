/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
import { API, API_2, APILocal, APIStaging } from "../config/axios";

class Responder {
  getRespondersByCompanyId = async (
    pageNumber: number,
    pageSize: number,
    companyId?: number
  ) => {
    const { data } = await API_2.get<PaginatedResponderByCompanyIdResponse>(
      `Admin_EmergencyResponder/company?pageNumber=${pageNumber}&pageSize=${pageSize}&companyId=${companyId}`
    );
    return data;
  };

  async createResponder(payload: ICreateResponder) {
    try {
      const response = await API_2.post<APIResult<ICreateResponder>>(
        `/Admin_EmergencyResponder`,
        payload
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async updateResponder(payload: IEditResponder) {
    try {
      const response = await API_2.put<APIResult<ICreateResponder>>(
        `/Admin_EmergencyResponder`,
        payload
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async UploadResponder(payload: IResponderUploadRequest) {
    try {
      const response = await API_2.post<APIResult<IResponderUploadRequest>>(
        `/Admin_EmergencyResponder/upload`,
        payload
      );
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}

export default new Responder();

import {
  Button,
  DatePicker,
  Form,
  Select,
  Space,
  UploadFile,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { RcFile, UploadProps } from "antd/es/upload";
import Dragger from "antd/es/upload/Dragger";
import {
  clickHereDraggerStyles,
  headerDraggerStyles,
  subtitleDraggerStyles,
} from "./styles";
import { CustomIcons } from "../../assets";
import Colors from "../../config/Colors";
import { BaseUrl, StagingUrl } from "../../config/urls";

type newCompanyMembersProp = {
  onFormFinish: (company: ICompanyMembers) => Promise<void>;
  onModalClose: () => void;
  isLoading: boolean;
  isSuccess: boolean;
};

const NewCompanyUsers = ({
  onFormFinish,
  onModalClose,
  isLoading,
  isSuccess,
}: newCompanyMembersProp) => {
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const [selectedSubPlan, setSelectedSubPlan] = useState();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [fileUrl, setFileUrl] = useState<string | null>();
  const [uploading, setUploading] = useState<boolean>();

  const onFinish = async (formValues: ICompanyMembers) => {
    const values: ICompanyMembers = formValues as ICompanyMembers;
    values.validFrom = startDate;
    values.validTo = endDate;
    values.subscription = selectedSubPlan;
    values.url = fileUrl;

    await onFormFinish(values);
  };

  const handleClose = () => {
    form.resetFields();
    onModalClose();
  };

  const handleSubChange = (value: any) => {
    setSelectedSubPlan(value);
  };
  const handleDateChange = (dates: any, dateString: [string, string]) => {
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
  };
  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess]);

  const props: UploadProps = {
    name: "file",
    multiple: false,
    //The end point to upload the file
    action: `${BaseUrl}/Admin_Companies/GetSpreadSheetUrl`,
    onChange(info) {
      const { status } = info.file;
      if (status === "uploading") {
        setUploading(true);
      }
      if (status === "done") {
        setFileUrl(info.file.response);
        setUploading(false);
        message.success(`The Excel file has been uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const beforeUpload = (file: RcFile, fileList: UploadFile[]) => {
    const isExcelFile =
      file.type === "application/vnd.ms-excel" ||
      file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      file.type === "application/vnd.oasis.opendocument.spreadsheet";

    if (!isExcelFile) {
      message.error("You can only upload Excel files!");
    }

    return isExcelFile;
  };

  return (
    <div>
      <Form
        form={form}
        name="addCompanyMembers"
        layout="vertical"
        onFinish={onFinish}
      >
        <div className="col-12">
          <Form.Item
            name={"subscription"}
            label="Select Subscription"
            rules={[
              { required: true, message: "Please select a subscription" },
            ]}
            className="mb-1"
          >
            <Select
              onChange={handleSubChange}
              placeholder="Subscription Plan"
              size="large"
              style={{ width: 485 }}
              options={[
                { value: 2, label: "Basic Plan" },
                { value: 8, label: "Family of Two" },
                { value: 3, label: "Family of Four" },
                { value: 4, label: "Family of Six" },
                { value: 5, label: "Premium" },
                { value: 9, label: "Family of Two Premium" },
                {
                  value: 6,
                  label: "Family of Four Premium",
                },
                {
                  value: 7,
                  label: "Family of Six Premium",
                },
              ]}
            />
          </Form.Item>
        </div>
        <div className="col-12">
          <Space
            direction="vertical"
            size={12}
            className="flex flex-row mt-2 align-items-centers"
          >
            <Form.Item
              name={"dateRange"}
              label="Select Contract Period"
              rules={[
                { required: true, message: "Please select contract period" },
              ]}
              className="mb-1"
            >
              <RangePicker onChange={handleDateChange} />
            </Form.Item>
          </Space>
        </div>
        <Form.Item
          name={"fileUrl"}
          label="Upload Excel File"
          rules={[
            {
              required: true,
              message: "Please upload a company excel Spreadsheet",
            },
          ]}
          className="mb-5 col-12"
        >
          <div className="flex w-full gap-6 flx-row justify-content-start">
            <Dragger
              {...props}
              beforeUpload={beforeUpload}
              className="w-full"
              style={{ background: "transparent" }}
            >
              <div className="flex flex-col items-center content-center w-full h-full gap-3">
                <CustomIcons.UploadIcon
                  width={46}
                  height={46}
                  svgColor={Colors.appMainColor}
                />
                <div className="flex gap-1 justify-content-center">
                  <div className="text-center" style={headerDraggerStyles}>
                    Drop your excel file here or to upload
                  </div>
                  <div style={clickHereDraggerStyles}>click here</div>
                  <div className="text-center" style={headerDraggerStyles}>
                    to upload
                  </div>
                </div>

                <div style={subtitleDraggerStyles}>
                  File should be excel, .xlsx{" "}
                </div>
              </div>
            </Dragger>
          </div>
        </Form.Item>
        <Form.Item className="w-12 pt-2 mb-1">
          <div className="flex w-full gap-2 col-12 justify-content-end align-content-center">
            <Button
              onClick={handleClose}
              type="primary"
              ghost
              size="large"
              className="w-3"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="w-3 bg-[#1757a2] shadow-none"
              loading={isLoading}
              disabled={uploading as boolean}
            >
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default NewCompanyUsers;

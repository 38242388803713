import React, { useState } from "react";
import { CustomIcons } from "../../../assets";
import Colors from "../../../config/Colors";
import { Button, Modal, message } from "antd";
import CommunityFeedCard from "./CommunityFeedCard";
import {
  useDeletePost,
  useGetPostByPostId,
  useGetReportedPostDetails,
} from "../../../hooks/useCommunities";
import { SubHeading } from "../../../pages/Home/styles";
import { useHistory } from "react-router-dom";

const CommunityReportedPost: React.FC<{
  card: ICommunityReportedPosts;
}> = ({ card }) => {
  const navigate = useHistory();
  const navigateTo = (postId: string) => {
    navigate.push(`/home/delete-reported-post/${postId}`);
  };

  return (
    <div className="flex flex-col w-full h-24 px-3 pt-1 shadow-5 surface-card border-round bg-popupColor">
      <div className="flex items-center text-left">
        <CustomIcons.PersonIcon
          height={15}
          width={15}
          svgColor={Colors.mainTextColor}
        />
        <span className=" text-mainTextColor">
          Post has been reported {card.amount} time(s)
        </span>
      </div>
      <div className="ml-3 text-mainTextColor text-left">
        {card.postCreatorFirstName} {card.postCreatorLastName}'s post is spam
        and misleading
      </div>

      <button
        className="ml-3 underline text-appMainColor text-left"
        onClick={() => navigateTo(card?.postId.toString())}
        type="button"
      >
        View more
      </button>
    </div>
  );
};

export default CommunityReportedPost;

import React from "react";

import HeaderComponent from "@/components/Header/Header";
import ControlRoomOperatorsTable from "@/components/Table/ControlRoomOperatorsTable";
import { Button } from "@/components/ui/button";
import { useModal } from "@/hooks/useModalStore";
import { usePanicTypes } from "@/hooks/usePanicTypes";

function ControlRoomOperators() {
  const panicTypes = usePanicTypes();
  const { onOpen } = useModal();

  return (
    <div className="flex w-full h-full bg-backgroundColor">
      <div className="w-full h-full max-h-screen overflow-y-auto disable-scrollbars">
        <HeaderComponent title="Control Room Operators" />
        <div className="flex flex-row items-center justify-end w-full gap-1 px-3 mb-2">
          <Button
            variant="default"
            className="w-2 h-12 rounded-lg bg-appMainColor hover:bg-appMainColor"
            onClick={() => {
              onOpen("createControlRoomOperator");
            }}
          >
            +Add new operator
          </Button>
        </div>
        <ControlRoomOperatorsTable />
      </div>
    </div>
  );
}

export default ControlRoomOperators;

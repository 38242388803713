/* eslint-disable no-underscore-dangle */
import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { ControlRoomOperator } from "@/services";
import { toast } from "sonner";
import { queries } from "../services/queries";

export const useGetControlRoomOperators = (page: number, pageSize: number) => {
  return useQuery({
    ...queries.controlRoomOperators.getControlRoomOperators(page, pageSize),
    refetchOnWindowFocus: false,
  });
};

export const useCreateControlRoomOperator = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (operator: ICreateOrEditControlRoomOperator) =>
      ControlRoomOperator.createOrEditControlRoomOperator(operator),
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries(queries.controlRoomOperators._def);
        return data;
      },
      onError: (err: Error) => {
        if (err.message) {
          toast.error(err.message);
        } else {
          toast.error("An error occurred");
        }
      },
      meta: {
        useError: true,
      },
    }
  );
};

export function useGetControlRoomOperatorChats() {
  return useMutation(
    async (params: IControlRoomOperatorChats) =>
      ControlRoomOperator.postControlRoomOperatorChats(params),

    {
      onError: (err: Error) => {
        if (err.message) {
          toast.error(err.message);
        } else {
          toast.error("An error occurred");
        }
      },
      meta: {
        conflict: "Found whilst making changes",
        badRequest: "Please make sure all fields are entered correctly.",
        notFound: "Case could not be found. Please try again",
      },
    }
  );
}

import React, { useEffect } from "react";
import { useModal } from "@/hooks/useModalStore";
import { Form, Select, Button, Tag } from "antd";
import { usePanicTypes } from "@/hooks/usePanicTypes";
import { useCreateControlRoomOperator } from "@/hooks/useControlRoomOperators";
import { toast } from "sonner";
import moment from "moment";
import SharedModalHeader from "../widgets/Headers/SharedModalHeader";
import CommonUserFields from "../Forms/CommonUserFields";

const { Option } = Select;

const dateFormat = "YYYY-MM-DD";

function NewControlRoomOperatorModal() {
  const panicTypes = usePanicTypes();
  const createOrUpdateControlRoomOperatorMutation =
    useCreateControlRoomOperator();
  const { isOpen, onClose, type, data } = useModal();
  const [form] = Form.useForm();
  // Helpers
  const isModalOpen =
    isOpen &&
    (type === "createControlRoomOperator" ||
      type === "editControlRoomOperator");
  const isEdit = type === "editControlRoomOperator";
  const editDetails = data?.editControlRoomOperator;
  // Modal Helpers

  const handleReset = () => {
    form.resetFields();
  };

  useEffect(() => {
    if (isModalOpen) {
      if (isEdit && editDetails) {
        form.setFieldsValue(editDetails);
        if (editDetails.phoneNumber) {
          const prefix = editDetails.phoneNumber.slice(0, 3); // Extract the country code
          form.setFieldsValue({ prefix }); // Set the country code
          form.setFieldsValue({
            phoneNumber: editDetails.phoneNumber.slice(3),
          }); // Set the phone number without the country code
        }
        const formattedDateOfBirth = editDetails.dateOfBirth
          ? moment(editDetails.dateOfBirth, dateFormat)
          : null;
        form.setFieldsValue({
          dateOfBirth: formattedDateOfBirth,
        });
      } else {
        handleReset();
      }
    }
  }, [isModalOpen, editDetails, form]);

  // UI functions

  // Async Functions
  const handleClose = async () => {
    handleReset();
    onClose();
  };

  const onFinish = async (formValues: any) => {
    const { prefix, phoneNumber, ...rest } = formValues;
    const fullPhoneNumber = `${prefix}${phoneNumber}`;

    // const values = formValues;
    const values: ICreateOrEditControlRoomOperator = {
      ...formValues,
      fullPhoneNumber, // This is the full phone number including the country code
    };

    try {
      await createOrUpdateControlRoomOperatorMutation.mutateAsync(values);
      toast.success(
        `Control Room Operator ${isEdit ? "updated" : "created"} successfully`
      );
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <SharedModalHeader
      title={
        isEdit ? "Edit Control Room Operator" : "+Add New Control Room Operator"
      }
      open={isModalOpen}
      onClose={handleClose}
      width={550}
    >
      <Form
        form={form}
        name="newControlRoomOperator"
        onFinish={onFinish}
        layout="vertical"
        className="grid w-full"
        initialValues={{
          prefix: "+27", // Set the default value for the prefix here
        }}
      >
        <CommonUserFields
          form={form}
          useMomentDatePicker={isEdit}
          editMode={isEdit}
        />
        <div className="col-12 md:col-12">
          <Form.Item
            name="specializationId"
            label="Specialization"
            rules={[
              {
                required: true,
                message: "Please select the operator's specialization!",
              },
            ]}
            className="mb-1"
          >
            <Select
              placeholder="Select operator's specialization"
              size="large"
              loading={panicTypes.isLoading}
            >
              {panicTypes.data?.map((item: IPanicTypes) => {
                if (item.name.toLowerCase() === "cpf") return null;
                return (
                  <Select.Option
                    key={`key-panicTypes-${item.id}`}
                    value={item.id}
                  >
                    {item.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
        {editDetails?.status && (
          <div className="col-12 md:col-12">
            <Form.Item
              name="status"
              label="Status"
              rules={[{ required: true, message: "Please select a status" }]}
              className="mb-3"
            >
              <Select placeholder="Select operator's status" size="large">
                <Option value="Active">
                  <Tag color="green">Active</Tag>
                </Option>
                <Option value="Suspended">
                  <Tag color="red">Suspended</Tag>
                </Option>
              </Select>
            </Form.Item>
          </div>
        )}
        <Form.Item className="w-12 pt-2 mb-1">
          <div className="flex w-full gap-2 col-12 justify-content-end align-content-center">
            <Button
              type="primary"
              ghost
              onClick={handleClose}
              size="large"
              className="w-3"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="w-3 bg-[#1757a2] shadow-none"
              loading={createOrUpdateControlRoomOperatorMutation.isLoading}
            >
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </SharedModalHeader>
  );
}

export default NewControlRoomOperatorModal;

// 1. Design Frontend
// 2. Create a hook and service to take in operator Id
// 3. Create an interface for detailed analytics page
import React, { useEffect, useState } from "react";
import { DatePicker, Rate, Space, Spin } from "antd";
import { Column } from "primereact/column";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { StarOutlined, SearchOutlined } from "@ant-design/icons";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { BodyContainer, Container } from "../../styles/ContainerStyles";
import HeaderComponent from "../../components/Header/Header";
import { tableBody, tableHeader, tableName } from "../../styles/TableStyles";
import { DashboardCard } from "../../components/Cards/DashboardCard";
import OperatorChatsTrend from "../../components/Charts/OperatorAnalytics/ChatsTrendLine";
import { useSelectedOperatorAnalytics } from "../../hooks/useOperators";
import CustomInputField from "../../components/widgets/Inputs/CustomInputField";
import Colors from "../../config/Colors";
import moment from "moment";
import { CustomIcons } from "../../assets";
import { Line } from "react-chartjs-2";
import { options } from "../../components/Charts/OperatorAnalytics/ChatsTrendLine";

type OperatorParams = {
  id?: string | undefined;
};

const { RangePicker } = DatePicker;

const DetailedOperator = () => {
  const location = useLocation();

  const { id } = useParams<OperatorParams>();
  const [startDate, setStartDate] = useState<string | null | Date | undefined>(
    null
  );
  const [endDate, setEndDate] = useState<string | null | Date | undefined>(
    null
  );
  const [filters, setFilters] = useState<DataTableFilterMeta | undefined>(
    undefined
  );
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");

  const {
    data: analytics,
    isLoading,
    isFetching,
  } = useSelectedOperatorAnalytics(id, startDate, endDate);

  const customInputTheme = {
    token: {
      colorPrimaryHover: `${Colors.appMainColor}`,
    },
  };

  const handleDateChange = (dates: any, dateString: [string, string]) => {
    // dateString is an array where the first element is the start date as a string
    // and the second element is the end date as a string.

    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
  };

  const clearFilter = () => {
    initFilters();
  };

  const onGlobalFilterChangeV2 = (e: string) => {
    const _filters = { ...filters };
    // @ts-ignore
    _filters.global.value = e;

    setFilters(_filters);
    setGlobalFilterValue(e);
  };

  useEffect(() => {
    initFilters();
  }, []);

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      firstName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      lastName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    });
    setGlobalFilterValue("");
  };

  function formatTime(mins: number): string {
    if (mins < 60) {
      return `${Math.round(mins)} min`;
    }
    if (mins < 1440) {
      return `${Math.round(mins / 60)} hours`;
    }
    if (mins < 10080) {
      return `${Math.round(mins / 1440)} days`;
    }
    if (mins < 40320) {
      return `${Math.round(mins / 10080)} weeks`;
    }
    if (mins < 525600) {
      return `${Math.round(mins / 40320)} months`;
    }
    return `${Math.round(mins / 525600)} years`;
  }

  const dateBodyTemplate = (rowData: IChatHistory) => {
    // return formatDate(rowData.date);
    return moment(rowData.caseDate).format("YYYY/MM/DD");
  };

  const averageResponseTemplate = (rowData: IChatHistory) => {
    return formatTime(rowData.averageResponseTime);
  };

  const resolvedDateBodyTemplate = (rowData: IChatHistory) => {
    return rowData.resolvedDate != null
      ? moment(rowData.resolvedDate).format("YYYY/MM/DD")
      : "";
  };
  const ratingBodyTemplate = (rowData: IChatHistory) => {
    return (
      <Rate
        disabled
        defaultValue={rowData.rating}
        style={{ color: `${Colors.appMainColor}` }}
        key={rowData.caseDate + rowData.firstName}
      />
    );
  };
  return (
    <div className="h-full w-full">
      <Container className="w-full h-full">
        <BodyContainer className="w-full h-full">
          <HeaderComponent title="" />
          <Spin tip="" spinning={isLoading || isFetching} delay={500}>
            <div className="w-full py-1 px-4 flex justify-between">
              <div
                className="flex flex-row align-items-center"
                style={tableName}
              >
                {analytics?.operatorName}
              </div>
              <Space
                direction="vertical"
                size={12}
                className="flex flex-row align-items-centers mt-2"
              >
                <RangePicker onChange={handleDateChange} />
              </Space>
            </div>
            {analytics !== null && analytics !== undefined && (
              <>
                <div className="py-1">
                  <div className="flex flex-row gap-3 overflow-x-scroll disable-scrollbars py-3 px-2">
                    {analytics.metrics.map((item: IAnalyticsMetric) => (
                      <div key={`key-${item.name}`}>
                        <DashboardCard
                          title={item.name}
                          number={Number(item.total.toFixed(2))}
                          percentage={Number(item.increase.toFixed(2))}
                          trend={item.increase >= 0}
                          key={`key-${item.name}`}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex flex-row justify-evenly animate-fadeInUp animate-faster">
                  <div className="col-12 md:col-6 animate-bounce animate-repeat-2">
                    <div className="w-full h-full">
                      <div
                        style={{ background: "#FFFFFF" }}
                        className="h-full shadow-5 surface-card border-round px-3"
                      >
                        <div className="w-12 flex flex-row justify-between items-center">
                          <div
                            className="pt-3"
                            style={{
                              fontStyle: "normal",
                              fontWeight: 700,
                              fontSize: "18px",
                              lineHeight: "27px",
                              color: `${Colors.mainTextColor}`,
                            }}
                          >
                            Practitioner Rating
                          </div>
                        </div>
                        <div className="h-full ">
                          <div className="flex flex-row justify-center gap-5 items-center h-full w-full pb-5">
                            <CustomIcons.StarIcon
                              height={40}
                              width={40}
                              svgColor={Colors.appMainColor}
                            />
                            <div
                              className="pb-3"
                              style={{
                                fontStyle: "normal",
                                fontWeight: 700,
                                fontSize: "18px",
                                lineHeight: "27px",
                                color: `${Colors.mainTextColor}`,
                              }}
                            >
                              {analytics.rating.toString()} Stars
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 md:col-6 animate-bounce animate-repeat-2">
                    <div className="w-full h-full">
                      <OperatorChatsTrend
                        chatMonthly={analytics.chatMonthlyTrend}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full h-full px-2 pb-5 Users">
                  <div className="px-3 py-3 card  border-round shadow-3">
                    <div className="flex flex-row gap-1 justify-between mb-2 w-full">
                      <div
                        className="flex flex-row align-items-center"
                        style={tableName}
                      >
                        Chat history
                      </div>
                      <CustomInputField
                        value={globalFilterValue}
                        onChange={(e: string) => onGlobalFilterChangeV2(e)}
                        onPressEnter={() => {}}
                        theme={customInputTheme}
                        inputProps={{
                          size: "large",
                          placeholder: "Search",
                          allowClear: true,
                          className: "ml-2 w-3",
                          prefix: <SearchOutlined />,
                        }}
                        mergeThemes
                      />
                    </div>
                    {analytics.operatorChats && (
                      <DataTable
                        paginator
                        rows={10}
                        value={analytics.operatorChats}
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        dataKey="id"
                        filters={filters}
                        globalFilterFields={["firstName", "lastName", "status"]}
                        emptyMessage="No Chats found."
                        rowHover
                        scrollable
                        scrollHeight="40rem"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} operators"
                        stateStorage="session"
                        stateKey="dt-state--detailed-operator-chat-history-table"
                        className="mx-0"
                      >
                        <Column
                          field="chatDate"
                          header="Chat Date"
                          headerStyle={tableHeader}
                          bodyStyle={tableBody}
                          style={{ background: "#FBFBFB" }}
                          body={dateBodyTemplate}
                        />
                        <Column
                          field="firstName"
                          header="First name"
                          headerStyle={tableHeader}
                          style={{ background: "#FBFBFB" }}
                          bodyStyle={tableBody}
                        />
                        <Column
                          field="lastName"
                          header="Last name"
                          headerStyle={tableHeader}
                          style={{ background: "#FBFBFB" }}
                          bodyStyle={tableBody}
                        />
                        <Column
                          field="status"
                          header="Resolved Cases"
                          headerStyle={tableHeader}
                          style={{ background: "#FBFBFB" }}
                          bodyStyle={tableBody}
                        />
                        <Column
                          field="resolvedDate"
                          header="Resolved Date"
                          headerStyle={tableHeader}
                          style={{ background: "#FBFBFB" }}
                          bodyStyle={tableBody}
                          body={resolvedDateBodyTemplate}
                        />
                        <Column
                          field="responseTime"
                          header="Response Time"
                          headerStyle={tableHeader}
                          bodyStyle={tableBody}
                          style={{ background: "#FBFBFB" }}
                          body={averageResponseTemplate}
                        />
                        <Column
                          field="rating"
                          header="Star Rating"
                          headerStyle={tableHeader}
                          style={{ background: "#FBFBFB" }}
                          bodyStyle={tableBody}
                          body={ratingBodyTemplate}
                        />
                      </DataTable>
                    )}
                  </div>
                </div>
              </>
            )}
          </Spin>
        </BodyContainer>
      </Container>
    </div>
  );
};

export default DetailedOperator;

import { createQueryKeys } from "@lukemorales/query-key-factory";
import Crimes from "../Crimes";

export const crimes = createQueryKeys("crimes", {
  MapData: null,
  dashboard: null,
  topCrimesByArea: (area: string = "") => ({
    queryKey: [{ area }],
    queryFn: () => Crimes.getTopCrimesByArea(area),
  }),
  getReportedCrime: null,
  getReportedCrimeDetails: (id: string | number) => ({
    queryKey: [{ id }],
    queryFn: () => Crimes.getReportedCrimeDetails(id),
  }),

  getReviewCategory: (category: string) => ({
    queryKey: [{ category }],
    queryFn: () => Crimes.getReviewCategory(category),
  }),
  getPostedCategory: (category: string) => ({
    queryKey: [{ category }],
    queryFn: () => Crimes.getPostedCategory(category),
  }),
  getDraftedCategory: (category: string) => ({
    queryKey: [{ category }],
    queryFn: () => Crimes.getDraftedCategory(category),
  }),
  getTotalReview: null,
  getReviewReportedCrime: null,
  getPostedReportedCrime: null,
  getDraftsReportedCrime: null,
  getCategoryAndTypes: () => ({
    queryKey: ["all"],
    queryFn: async () => {
      const response = await Crimes.fetchCrimeCategories();
      if (response?.data) {
        const fetchedCategories = response.data.map((cat) => ({
          label: cat.category,
          value: cat.category,
          disable: false,
          fixed: true,
        }));

        const totalTypes = response.data.reduce(
          (acc, cat) => acc + cat.labels.length,
          0
        );

        return {
          categories: fetchedCategories,
          totalTypes,
        };
      }
      return { categories: [], totalTypes: 0 };
    },
  }),
});

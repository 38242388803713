/* eslint-disable no-unused-vars */
import React from "react";
import { Button } from "antd";
import {
  cardStyles,
  headerStyles,
  imageStyles,
  titleStyles,
  contentStyles,
  subheaderStyles,
  subtitleStyles,
  btnStyles,
} from "./styles";
import { CustomIcons } from "../../../assets";
import Colors from "../../../config/Colors";
import TruncateTextBlock from "../../widgets/common/TruncateTextBlock";

type cardProps = {
  title: string;
  subtitle: string;
  imgSrc: string;
  editEmergencyCard: () => void;
  addNewOperator: () => void;
};

// eslint-disable-next-line import/prefer-default-export
const EmergencyCard = ({
  title,
  subtitle,
  imgSrc,
  editEmergencyCard,
  addNewOperator,
}: cardProps) => (
  <div style={cardStyles}>
    <div className="w-full flex flex-row justify-content-between align-items-center">
      <div style={imageStyles}>
        <img
          src={imgSrc}
          alt=""
          width={25}
          height={25}
          style={{ objectFit: "contain" }}
        />
      </div>

      <h2 style={titleStyles}>{title}</h2>
      <Button
        type="text"
        icon={
          <CustomIcons.PencilIcon
            width={20}
            height={20}
            viewBox={null}
            svgColor={Colors.appMainColor}
          />
        }
        style={{ color: `${Colors.appMainColor}` }}
        onClick={editEmergencyCard}
      />
    </div>
    <div style={contentStyles} className="py-2">
      <div style={subheaderStyles}>
        <TruncateTextBlock style={subtitleStyles} limit={50}>
          {subtitle}
        </TruncateTextBlock>
      </div>
    </div>
    <button style={btnStyles} type="button" onClick={addNewOperator}>
      <div>Add new {title} operator</div>
    </button>
  </div>
);

export default EmergencyCard;

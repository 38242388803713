/* eslint-disable max-len */
import React from "react";
import { useContext, useState } from "react";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import HeaderComponent from "../../components/Header/Header";
import { useGetPostApplicationDashboardDetails } from "../../hooks/usePostApplication";
import AuthContext from "../../contexts/AuthContext";
import { CustomIcons } from "../../assets";
import Colors from "../../config/Colors";
import PostApplicationTable from "../../components/Table/PostApplicationTable";
import { PostApplicationStatus } from "../../util/enums";

type CardProps = {
  title: string;
  count: number;
  bgColor: string;
  textColor: string;
};

const StatusCard: React.FC<CardProps> = ({
  title,
  count,
  bgColor,
  textColor,
}) => {
  return (
    <div
      className={`p-4 rounded-olg shadow-md w-60 transition-transform duration-500 ease-in-out transform hover:scale-105 hover:shadow-lg ${bgColor} hover:${bgColor}-dark ${textColor} cursor-pointer`}
    >
      <p className={`text-sm font-semibold mb-2 ${textColor}`}>{title}</p>
      <p className={`text-xl font-bold ${textColor}`}>{count}</p>
    </div>
  );
};

const PostApplications = () => {
  const { getCommunityId } = useContext(AuthContext);
  const communityId = getCommunityId();
  const { isLoading, data: DashboardData } =
    useGetPostApplicationDashboardDetails(communityId);

  const [activeIndex, setActiveIndex] = useState("1");

  const onTabChange = (key: string) => {
    setActiveIndex(key);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Approved`,
      children: (
        <div className="flex flex-1">
          {communityId && (
            <PostApplicationTable communityId={communityId} status="approved" />
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: `Pending`,
      children: (
        <div className="flex flex-1">
          {communityId && (
            <PostApplicationTable communityId={communityId} status="pending" />
          )}
        </div>
      ),
    },
    {
      key: "3",
      label: `Rejected`,
      children: (
        <div className="flex flex-1">
          {communityId && (
            <PostApplicationTable communityId={communityId} status="rejected" />
          )}
        </div>
      ),
    },
    {
      key: "4",
      label: `Disabled`,
      children: (
        <div className="flex flex-1">
          {communityId && (
            <PostApplicationTable communityId={communityId} status="disabled" />
          )}
        </div>
      ),
    },
    {
      key: "5",
      label: `Blocked`,
      children: (
        <div className="flex flex-1">
          {communityId && (
            <PostApplicationTable communityId={communityId} status="blocked" />
          )}
        </div>
      ),
    },
  ];

  const titleColorMapping: Record<
    string,
    { bgColor: string; textColor: string }
  > = {
    Pending: { bgColor: "bg-blue-200", textColor: "text-blue-900" },
    Approved: {
      bgColor: "bg-green-200",
      textColor: "text-green-900",
    },
    Rejected: {
      bgColor: "bg-red-200",
      textColor: "text-red-900",
    },
    Disabled: { bgColor: "bg-gray-300", textColor: "text-gray-900" },
    Blocked: { bgColor: "bg-orange-400", textColor: "text-orange-900" },
  };

  return (
    <div className="flex flex-col w-full h-full bg-backgroundColor">
      <div className="w-full max-h-screen overflow-y-auto">
        <HeaderComponent title="Community feed applications" />
        {isLoading ? (
          <div className="flex flex-col items-center justify-center flex-1">
            <CustomIcons.EOSThreeDotsLoading
              width={70}
              height={70}
              svgColor={Colors.appHeaderColor}
            />
          </div>
        ) : (
          <div>
            <div className="flex flex-wrap px-3 py-4 space-x-4">
              {DashboardData?.map((item) => {
                const colors = titleColorMapping[item.name];
                return (
                  <div className="mb-2" key={item.id}>
                    <StatusCard
                      count={item.count}
                      title={item.name}
                      {...colors}
                    />
                  </div>
                );
              })}
            </div>
            <Tabs
              defaultActiveKey={activeIndex}
              items={items}
              onChange={onTabChange}
              animated
              className="px-3"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PostApplications;

import { createQueryKeys } from "@lukemorales/query-key-factory";
import Dashboard from "../Dashboard";

export const dashboard = createQueryKeys("dashboard", {
  all: null,
  detail: (userId: string | number) => ({
    queryKey: [userId],
    queryFn: (): Promise<IOperatorDashboard> =>
      Dashboard.getDetailDashboard(userId),
  }),
});

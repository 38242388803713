/* eslint-disable no-unused-vars */
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  width: WINDOW_WIDTH;
  height: WINDOW_HEIGHT;
  background: #fbfbfb;
`;

export const BodyContainer = styled.div`
  justify-content: space-between;
  p {
    font-weight: bold;
  }
  overflow-y: auto;
  width: 90%;
  max-height: 100vh;
`;

export const SearchBar = styled.input`
  //height: 28px;
  //width: 240px;
  border-radius: 4px;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  background-color: #f5f5f5;
  ::placeholder {
    color: #c9c9c9;
  }
`;

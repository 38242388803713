import styled, { CSSProperties } from "styled-components";
import Colors from "../../../config/Colors";

// New Emergency Form

export const ImageBox = styled.div`
  width: 100px;
  height: 62px;
  border-radius: 5px;
  border: none;
  background-color: #fbf7f7;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const headerDraggerStyles: CSSProperties = {
  color: "#0B0101",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  textAlign: "center",
};

export const subtitleDraggerStyles: CSSProperties = {
  color: Colors.subtleTextColor,
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "400",
  textAlign: "center",
};

export const clickHereDraggerStyles: CSSProperties = {
  color: Colors.appMainColor,
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  textAlign: "center",
};

import React from "react";
import Colors from "../../../config/Colors";

function RenderContent({
  content,
  length = 300,
}: {
  content: string;
  length: number;
}) {
  const maxLength = length; // Adjust based on your needs
  const [isExpanded, setIsExpanded] = React.useState(false);

  if (content.length <= maxLength || isExpanded) {
    return (
      <span>
        {content}
        {content.length > maxLength && (
          <button
            type="button"
            onClick={() => setIsExpanded(false)}
            style={{
              marginLeft: "10px",
              color: Colors.appMainColor,
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Show Less
          </button>
        )}
      </span>
    );
  }

  return (
    <span>
      {content.substring(0, maxLength)}
      {"... "}
      <button
        type="button"
        onClick={() => setIsExpanded(true)}
        style={{
          color: Colors.appMainColor,
          textDecoration: "underline",
          cursor: "pointer",
        }}
      >
        Read More
      </button>
    </span>
  );
}

export default RenderContent;

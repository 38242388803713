/* eslint-disable react/no-array-index-key */
import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import { Image } from "primereact/image";
import { Carousel } from "primereact/carousel";
import { InputText } from "primereact/inputtext";
import HeaderComponent from "../../components/Header/Header";
import { SubHeading } from "../Home/styles";
import { VideoPlayerAnimationIcon } from "../../components/Icons/index";
import {
  useGetPostByPostId,
  useDeletePost,
  usePostReaction,
} from "../../hooks/useCommunities";
import { CustomIcons } from "../../assets";
import Colors from "../../config/Colors";
import { getInitials, getTimePosted } from "../../util/helpers";
import InitialsAvatar from "../../components/widgets/Avatars/InitialsAvatar";
import CommunityTag from "../../components/Tags/CommunityTag";
import RenderContent from "../../components/widgets/common/RenderContent";
import Comments from "../../components/General/Comments";
import LikeButton from "../../components/widgets/Buttons/LikeButton";
import AuthContext from "../../contexts/AuthContext";

type CommunityPostParams = {
  id: string | undefined;
};

export default function CommunityPost() {
  const { user } = useContext(AuthContext);
  const { id } = useParams<CommunityPostParams>();
  const postReaction = usePostReaction();
  const deletePost = useDeletePost();
  const { data: Post, isLoading, isError } = useGetPostByPostId(id);
  const {
    mediaPath,
    profilePicture,
    firstName,
    lastName,
    createdAt,
    tags,
    content,
    isCommentAble,
    communityId,
    reactions,
  } = Post || {};
  const mediaTemplate = (postMedia: IMedia) => {
    switch (postMedia.fileType) {
      case "image":
        return (
          <div className="flex flex-wrap items-center justify-center w-full h-full">
            <Image
              src={postMedia.url}
              className="flex justify-center object-cover w-full"
              height="100%"
              preview
            />
          </div>
        );
      case "video":
        return (
          <div className="flex justify-center w-full h-full">
            <ReactPlayer
              style={{
                flex: 1,
                justifyContent: "center",
                width: "100%",
              }}
              height="100%"
              width="100%"
              controls
              url={postMedia.url}
              pip
              light={
                <div className="pt-8 pr-5">
                  <VideoPlayerAnimationIcon />
                </div>
              }
            />
          </div>
        );
      default:
        return <SubHeading>Unsupported media found</SubHeading>;
    }
  };

  function userHasReacted(): boolean {
    const value = reactions?.some((reaction) => reaction.userId === user?.id);
    return value || false;
  }

  const handlePostReaction = async () => {
    try {
      const reaction: ICreatePostReaction = {
        userId: user?.id || 0,
        postId: Number(id),
        reactionId: 1,
        communityId: communityId || 0,
      };
      await postReaction.mutateAsync(reaction);
    } catch (error) {
      // log something here
    }
  };

  if (isError) {
    return null;
  }

  if (isLoading) {
    return (
      <div className="flex items-center self-center justify-center flex-1 h-5/6">
        <CustomIcons.EOSloading
          width={60}
          height={60}
          svgColor={Colors.appMainColor}
        />
      </div>
    );
  }
  return (
    <div className="flex w-full h-full bg-backgroundColor">
      <div className="w-full h-full max-h-screen overflow-y-auto disable-scrollbars">
        <HeaderComponent title="Community Post" />
        <div className="px-3" style={{ width: "100%", height: "90%" }}>
          <div className="flex w-full h-full">
            <div className="flex flex-col w-1/2 h-full overflow-y-auto">
              {/* 01 Content */}
              <div className="flex flex-col flex-shrink-0 h-auto">
                <div
                  className="flex items-center w-4/5 pb-2 ml-1"
                  style={{ marginTop: "1rem" }}
                >
                  <InitialsAvatar
                    url={profilePicture ?? null}
                    initials={getInitials(`${firstName} ${lastName}`)}
                  />
                  <div className="flex flex-col px-2 ml-1">
                    <p
                      className="flex justify-start text-lg font-bold text-appMainColor "
                      style={{ marginBottom: "0px" }}
                    >
                      {`${firstName} ${lastName}`}
                    </p>
                    <p
                      className="flex justify-start text-sm text-mainTextColor "
                      style={{ marginBottom: "0px" }}
                    >
                      Posted {createdAt && getTimePosted(createdAt)}
                    </p>
                  </div>
                </div>
                <p
                  className={`flex justify-start ${
                    isCommentAble ? "h-32" : "h-full"
                  }py-3 overflow-y-auto px-2 text-sm text-start w-6/6 text-mainTextColor`}
                >
                  <RenderContent
                    content={`${content}`}
                    length={isCommentAble ? 200 : 1000}
                  />
                </p>
                <div className="flex justify-start w-full gap-2 pb-2 pl-1">
                  {tags?.map((item, index) => (
                    <CommunityTag tag={item} key={`tag-${index}`} />
                  ))}
                </div>
                <div className="flex justify-between w-full px-2">
                  <LikeButton
                    active={userHasReacted()}
                    amount={reactions ? reactions.length : 0}
                    onLike={handlePostReaction}
                  />
                </div>
              </div>

              {/* 02 Comments */}
              {isCommentAble && id ? (
                <div className="flex-grow">
                  <Comments postId={id} communityId={communityId} visible />
                </div>
              ) : (
                <SubHeading>No comment is allowed on this post.</SubHeading>
              )}
            </div>
            {/* 03 Media */}
            <div className="flex items-center justify-center flex-none w-1/2 h-5/6">
              {mediaPath && mediaPath.length > 0 ? (
                <div className="flex items-center justify-center w-full h-full">
                  <Carousel
                    value={mediaPath}
                    numVisible={1}
                    numScroll={1}
                    itemTemplate={mediaTemplate}
                    className="flex items-center justify-center w-full h-full"
                    showIndicators={false}
                  />
                </div>
              ) : (
                <SubHeading>No media found</SubHeading>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { createQueryKeys } from "@lukemorales/query-key-factory";
import Communities from "../Communities";

export const communities = createQueryKeys("communities", {
  all: null,
  GetCommunityOperators: (
    communityId: number | null | undefined,
    limit: number | null | undefined = 10,
    offset: number | null | undefined = 0
  ) => ({
    queryKey: [{ communityId, limit, offset }],
    queryFn: () =>
      Communities.getCommunityOperators(communityId, limit, offset),
  }),
  GetPostByPostId: (postId: number | string | null | undefined) => ({
    queryKey: [{ postId }],
    queryFn: () => Communities.getPostByPostId(postId),
  }),
  GetSaferCityPostByPostId: (postId: number | string | null | undefined) => ({
    queryKey: [{ postId }],
    queryFn: () => Communities.getSaferCityPostByPostId(postId),
  }),
  GetReportedPost: (postId: number | null) => ({
    queryKey: [{ postId }],
    queryFn: () => Communities.getReportedPostDetails(postId),
  }),
  GetCommunityAdminCases: (
    communityAdminId: number | string | null | undefined
  ) => ({
    queryKey: [{ communityAdminId }],
    queryFn: () => Communities.getCommunityAdminCases(communityAdminId),
  }),

  getCommunitiesPaginatedCardsData: (
    pageNumber: string | number | null,
    pageSize: number | string | null,
    name: string | null
  ) => ({
    queryKey: [pageNumber ?? 1, pageSize ?? 16, name ?? ""],
    queryFn: (): Promise<IPaginatedCommunityResponse> =>
      Communities.getPaginatedCommunitiesCardsData(pageNumber, pageSize, name),
  }),
});

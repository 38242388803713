import React from "react";

const CommunityTag: React.FC<{ tag: string }> = ({ tag }) => {
  return (
    <span className="inline-block rounded-omd px-3 py-1 text-sm font-semibold text-appMainColor mr-2 mb-2 bg-secondaryColor">
      {tag}
    </span>
  );
};

export default CommunityTag;

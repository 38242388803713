/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-useless-catch */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
import { API, APILocal, APIStaging } from "../config/axios";

class PostApplication {
  getDashboardDetails = async (
    communityId: number | string | null | undefined
  ): Promise<IPostApplicationDashboard[]> => {
    if (communityId) {
      const response = await API.get(
        `Admin_PostApplication/DashboardDetails/${communityId}`
      );
      return response.data;
    }
    return Promise.reject<IPostApplicationDashboard[]>(
      "Community IO not found"
    );
  };

  getCommunityUsersByStatus = async (
    communityId: number | string | null | undefined,
    status: number | string | null | undefined
  ): Promise<IPostApplicationUser[]> => {
    if (communityId && status) {
      const response = await API.get(
        `Admin_PostApplication/CommunityUsersByStatus?communityId=${communityId}&status=${status}`
      );
      return response.data;
    }
    return Promise.reject<IPostApplicationUser[]>("Community IO not found");
  };

  getApplicationDetails = async (
    applicationId: number | string | null | undefined
  ): Promise<IPostApplicationDetails> => {
    if (applicationId) {
      const response = await API.get(`Admin_PostApplication/${applicationId}`);
      return response.data;
    }
    return Promise.reject<IPostApplicationDetails>("Application IO not found");
  };

  approveApplication = async (
    applicationId: number | string | null | undefined
  ): Promise<IPostApplicationDetails> => {
    return API.put(`Admin_PostApplication/ApproveApplication/${applicationId}`);
  };

  disableCanPost = async (
    applicationId: number | string | null | undefined
  ): Promise<IPostApplicationDetails> => {
    return API.put(`Admin_PostApplication/DisableCanPost/${applicationId}`);
  };

  enableCanPost = async (
    applicationId: number | string | null | undefined
  ): Promise<IPostApplicationDetails> => {
    return API.put(`Admin_PostApplication/EnableCanPost/${applicationId}`);
  };

  unBlockApplication = async (
    applicationId: number | string | null | undefined
  ): Promise<IPostApplicationDetails> => {
    return API.put(`Admin_PostApplication/UnblockUser/${applicationId}`);
  };

  rejectApplication = async (
    application: IRejectApplication
  ): Promise<IPostApplicationDetails> => {
    return API.put(
      `Admin_PostApplication/RejectApplication/${application.applicationId}`,
      application
    );
  };

  blockApplication = async (
    application: IRejectApplication
  ): Promise<IPostApplicationDetails> => {
    return API.put(
      `Admin_PostApplication/BlockUser/${application.applicationId}`,
      application
    );
  };
}

export default new PostApplication();

/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable max-len */
import React from "react";
import { Menu, UserPlus, CarFrontIcon, Settings } from "lucide-react";
import { Avatar } from "antd";
import { format } from "@formkit/tempo";
import { useModal } from "@/hooks/useModalStore";
import { useHistory } from "react-router-dom";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Separator } from "@/components/ui/separator";
import { Button } from "@/components/ui/button";
import { isUrl } from "@/util/helpers";
import { useQueryClient } from "@tanstack/react-query";
import { Responder, ResponderCompany } from "@/services";
import { queries } from "@/services/queries";
import { responderCompanies } from "@/services/queries/responderCompanyQueries";

const ColorList = [
  "#f56a00",
  "##87d068",
  "#fde3cf",
  "#00a2ae",
  "#7265e6",
  "#ffbf00",
];

type CompanyCardProps = {
  company: IResponderCompanyDto;
};

export function ResponderCompanyCard({ company }: CompanyCardProps) {
  const {
    id,
    companyImage,
    companyName,
    companyRegistrationNumber,
    companyDescription,
    companyCertificate,
    createdAt,
    totalResponders,
    emergencyResponderPreviews,
  } = company;
  const navigate = useHistory();
  const queryClient = useQueryClient();
  const { onOpen } = useModal();
  function getBackgroundColor(index: number) {
    return ColorList[index % ColorList.length];
  }

  const handleEdit = () => {
    const data: IEditResponderCompany = {
      id,
      companyName,
      companyDescription,
      companyImage,
      companyRegistrationNumber,
      companyCertificate,
    };
    onOpen("editResponderCompany", { responderCompany: data });
  };

  const handleAddNewResponder = () => {
    onOpen("createResponder", { activeResponderCompanyId: id });
  };
  const handleOnOpenResponder = () => {
    navigate.push(`/home/responders/${id}`);
  };

  const handleOnOpenVehicles = () => {
    navigate.push(`/home/responder-company/vehicles/${id}`);
  };

  const handlePrefetch = () => {
    queryClient.prefetchQuery({
      ...queries.responder.GetRespondersByCompanyId(1, 10, id),
    });
    queryClient.prefetchQuery(["responderCompanies", "all"], () =>
      ResponderCompany.getAllResponderCompanies()
    );
  };
  return (
    <div className="relative flex flex-col items-start gap-1 p-4 bg-white shadow-md rounded-olg sm:w-full md:w-1/3 lg:max-w-sm company-card hover:bg-gray-100">
      <div className="flex w-full mb-4 space-x-4 justify-evenly">
        {/* Company Image Here In the Middle of a Blue Rounded Background */}
        <div className="flex items-center content-center justify-center w-16 h-[4rem] rounded-omd shadow bg-[#D6E8F7] transition-transform duration-500 ease-in-out transform hover:scale-105 hover:shadow-lg  hover:bg-[#D6E8F7]-dark">
          <img
            src={companyImage}
            alt={`${companyName} logo`}
            className="content-center justify-center object-cover w-1/2 rounded h-1/2"
          />
        </div>

        <div className="flex flex-col items-start justify-between flex-1">
          {/* Logo image */}
          <h3 className="text-lg font-normal text-mainTextColor">
            {companyName}
          </h3>
          {/* Avatar Group Here */}
          {/* User icons go here, use a loop to generate them based on the number of responders */}
          <div className="flex -space-x-1 overflow-hidden">
            <Avatar.Group>
              {emergencyResponderPreviews.map((preview, index) =>
                isUrl(preview) ? (
                  <Avatar
                    key={preview}
                    src={preview}
                    style={{ backgroundColor: getBackgroundColor(index) }}
                  />
                ) : (
                  <Avatar
                    key={`${index}-${preview}`}
                    style={{ backgroundColor: getBackgroundColor(index) }}
                  >
                    {preview}
                  </Avatar>
                )
              )}
            </Avatar.Group>
          </div>
        </div>
        {/* Menu Icon here for Options */}
        <DropdownMenu>
          <DropdownMenuTrigger asChild className="flex items-start justify-end">
            <button
              type="submit"
              className="flex items-start justify-end h-6 hover:border-neutral-800 transaction"
            >
              <Menu className="flex items-start justify-end w-full h-full hover:bg-gray-700/10" />
            </button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56 text-xs font-medium text-black space-y-[2px]">
            <DropdownMenuLabel>Options</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              className="py-2 text-sm cursor-pointer"
              onClick={handleEdit}
            >
              <Settings className="h-4 mr-2" />
              Edit
            </DropdownMenuItem>
            <DropdownMenuItem
              className="py-2 text-sm cursor-pointer"
              onClick={handleAddNewResponder}
            >
              <UserPlus className="h-4 mr-2" />
              Add Responder
            </DropdownMenuItem>
            <DropdownMenuItem
              className="py-2 text-sm cursor-pointer"
              onClick={handleOnOpenVehicles}
            >
              <CarFrontIcon className="h-4 mr-2" />
              Vehicles
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      {/* Company Description */}
      <span className="text-sm text-start text-mainTextColor">
        {companyDescription}
      </span>
      {/* Divider */}
      <Separator className="my-2 bg-[#EBE8E8FA]" />
      {/* Company Details such as number of responders and company date added */}
      <div className="flex flex-row items-center justify-between w-full space-x-4">
        <div className="flex flex-col items-start justify-between">
          <span className="text-sm font-medium text-gray-900">
            {totalResponders}
          </span>
          <Button
            variant="link"
            className="h-4 px-0 py-0 my-0 text-sm font-medium underline text-appMainColor"
            onClick={handleOnOpenResponder}
            onMouseEnter={() => {
              handlePrefetch();
            }}
          >
            responders
          </Button>
        </div>
        <div className="flex flex-col items-start justify-between">
          <span className="text-sm font-medium text-gray-900">
            {format(createdAt, "YYYY-MM-DD")}
          </span>
          <span className="text-sm font-medium text-subtleTextColor">
            responders
          </span>
        </div>
      </div>
    </div>
  );
}

import { Form, Select } from "antd";
import React from "react";

const { Option } = Select;

const PhoneNumberPrefixSelector = () => {
  return (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="+27">+27</Option>
      </Select>
    </Form.Item>
  );
};

export default PhoneNumberPrefixSelector;

/* eslint-disable no-useless-catch */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
import { API, APILocal, APIStaging } from "../config/axios";

class Admins {
  getAdmins = async (): Promise<IAdmin[]> => {
    const response = await API.get("Admin_UserAuth/getAdmins");
    return response.data;
  };

  addAdmin = async (body: IUser): Promise<IUser> => {
    try {
      const response = await API.post("Admin_UserAuth/postSuperAdmin", body);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  addEmergencyAdmin = async (body: IEmergencyAdmin): Promise<IUser> => {
    try {
      const response = await API.post(
        "Admin_UserAuth/postEmergencyAdmin",
        body
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  addCommunityAdmin = async (body: ICommunityAdmin): Promise<IUser> => {
    try {
      const response = await API.post(
        "Admin_UserAuth/PostCommunityAdmin",
        body
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  addGenericAdmin = async (body: IGeneralAdmin): Promise<IUser> => {
    try {
      const response = await API.post("Admin_UserAuth/PostAdmin", body);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  removeAdmin = async (userId: string | number): Promise<IUser> => {
    const response = await API.delete(`UserAuth/removeAdmin?userId=${userId}`);
    return response.data;
  };

  getAdminList = async (
    filters: Filters | null,
    page: number | string | null | any = null
  ): Promise<IUser[]> => {
    const url = `getAdmins`;
    const params: { [key: string]: any } = {};
    if (filters) {
      params.filters = filters;
    }
    if (page) {
      params.page = page;
    }
    // Make the API call and return the response data
    const response = await API.get<IUser[]>(url, { params });
    return response.data;
  };

  getSearchAdmin = async (
    filters: Filters | null,
    page: number | string | null | any = null,
    limit: number | null = null,
    search: string | null
  ): Promise<IUser[]> => {
    const url = `getAdmins`;
    const params: { [key: string]: any } = {};

    if (filters) {
      params.filters = filters;
    }
    if (search) {
      params.search = search;
    }
    if (page) {
      params.page = page;
    }

    if (limit) {
      params.limit = limit;
    }
    // Make the API call and return the response data
    const response = await API.get<IUser[]>(url, { params });
    return response.data;
  };
}

export default new Admins();

/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Form, Input, Modal, Select, Tooltip, message } from "antd";
import moment from "moment";
import { Container, BodyContainer } from "../../styles/ContainerStyles";
import HeaderComponent from "../../components/Header/Header";
import { LinkText } from "./styles";
import {
  useUserProfile,
  useEditUser,
  useUserSubRenewal,
  useUserCompanySubRenewal,
} from "../../hooks/useUsers";
import { copyObjects } from "../../util/copyObjects";
import { SUBSCRIPTION_STATUS } from "../../constants/subscriptions";
import Spin from "../../components/widgets/Spinner/Spin";
import BackButton from "../../components/widgets/common/BackButton";
import { modelTitle } from "../../styles/ModalStyles";
import RenewCompanySubscriptions from "../../components/Forms/RenewCompanySubscriptions";
import { CustomIcons } from "../../assets";
import Colors from "../../config/Colors";
import RenewUserSub from "../../components/Forms/RenewUserSub";
import { values } from "lodash";
import RenewUserCompany from "@/components/Forms/RenewUserCompany";

type userParams = {
  id?: string | undefined;
};

const subscriptionOptions = [
  { value: 2, label: "Basic plan" },
  { value: 8, label: "Family of 2" },
  { value: 3, label: "Family of 4" },
  { value: 4, label: "Family of 6" },
  { value: 5, label: "Premium" },
  { value: 9, label: "Family of 2 Premium" },
  { value: 6, label: "Family of 4 Premium" },
  { value: 7, label: "Family of 6 Premium" },
];

const UserProfile = () => {
  const [form] = Form.useForm();
  const { id } = useParams<userParams>();
  const { isLoading, data, refetch, isFetching } = useUserProfile(id || "");
  const userSubRenewal = useUserSubRenewal();
  const userCompRenewal = useUserCompanySubRenewal();
  const [arrow, setArrow] = useState("Show");

  const [messageApi, contextHolder] = message.useMessage();
  const messageKey = "updatable";

  const { subscriptionId, subscriptionFrom, dependents, status } = data || {};

  const [openSubscriptionModal, setOpenSubscriptionModal] = useState(false);
  const [openCompanyModal, setOpenCompanyModal] = useState(false);
  const [registrationDate, setRegistrationDate] = useState(
    data?.registrationDate
  );
  const editUser = useEditUser();

  const handleClose = async () => {
    await setOpenSubscriptionModal(false);
    await setOpenCompanyModal(false);
  };

  const [billingDate, setBillingDate] = useState<string>("");
  const [dob, setDob] = useState(data?.dob);
  const [dateSubscribed, setDateSubscribed] = useState(data?.dateSubscribed);
  const [disableSelect, setDisableSelect] = useState(false);
  const [newSubscriptionId, setNewSubscriptionId] = useState<number | null>(
    null
  );

  const navigate = useHistory();

  const navigateTo = (userId: string | undefined) => {
    navigate.push(`/home/users-chat-history/${userId}`);
  };

  const navigateBack = () => {
    navigate.push(`/home/users`);
  };

  const handleEditUser = async (singleUser: IEditUser) => {
    try {
      messageApi.open({
        key: messageKey,
        type: "loading",
        content: "Updating User..",
        duration: 0,
      });

      await editUser.mutateAsync(singleUser);
      messageApi.open({
        key: messageKey,
        type: "success",
        content: "Successfully updated user",
        duration: 10,
      });
      await refetch();
    } catch (error) {
      console.error(error);
    }
  };

  const handleUserRenewal = async (userRenewal: IUserSubRenewal) => {
    try {
      userRenewal.userId = Number(data?.userId);
      await userSubRenewal.mutateAsync(userRenewal);
      message.success("Subsciption has been saved successfully.");
      refetch();
    } catch (error) {
      // console.log(error);
    }
  };

  const handleUserCompanyRenewal = async (
    userCompanyRenewal: IUserCompanyRenewal
  ) => {
    try {
      userCompanyRenewal.userId = Number(data?.userId);
      await userCompRenewal.mutateAsync(userCompanyRenewal);
      message.success("User has been saved successfully to a company.");
      refetch();
    } catch (error) {
      // console.log(error);
    }
  };

  const onFinish = async (e: any) => {
    const values: IEditUser = copyObjects(e, {
      id: data?.userId,
    });

    await handleEditUser(values);
  };

  useEffect(() => {
    if (data) {
      setRegistrationDate(moment(data.registrationDate).format("YYYY/MM/DD"));
      setDob(moment(data.dob).format("YYYY/MM/DD"));
      setDateSubscribed(moment(data.dateSubscribed).format("YYYY/MM/DD"));
      if (data.billingDate) {
        setBillingDate(moment(data.billingDate).format("YYYY/MM/DD"));
      }
      form.setFieldsValue(data);
    }

    if (subscriptionId !== null) {
      if (
        [
          SUBSCRIPTION_STATUS.ACTIVE,
          SUBSCRIPTION_STATUS.CANCELLED,
          SUBSCRIPTION_STATUS.NON_RENEWING,
        ].includes(status)
      ) {
        if (subscriptionFrom === "Portal") {
          setDisableSelect(false);
        } else {
          setDisableSelect(true);
        }
      } else {
        setDisableSelect(false);
      }
    } else {
      setDisableSelect(false);
    }
  }, [data, form]);

  function renderSubscriptionSelect() {
    return (
      <Form.Item
        name="subscriptionId"
        label="Subscription"
        className="w-1/2 mb-3"
      >
        <div className="w-full flex flex-row items-center">
          <Input
            className="w-12"
            readOnly
            value={data?.subscription}
            size="large"
          />
          {data?.subscriptionFrom?.toLocaleLowerCase() === "portal" ||
          data?.subscriptionFrom == null ? (
            <button
              type="button"
              className="pl-2"
              onClick={() => {
                setOpenSubscriptionModal(true);
              }}
            >
              <CustomIcons.PencilIcon
                height={23}
                width={23}
                svgColor={Colors.appMainColor}
              />
            </button>
          ) : (
            <Tooltip
              placement="top"
              title={
                "Subscriptions can only edited if the Subscription is from Portal or a new subscription"
              }
              color={Colors.appMainColor}
            >
              <button>
                <CustomIcons.InformationIcon
                  height={30}
                  width={30}
                  svgColor={Colors.appMainColor}
                />
              </button>
            </Tooltip>
          )}
        </div>
      </Form.Item>
    );
  }

  function renderDependents() {
    return (
      <div className="flex flex-wrap -mx-2">
        {dependents?.map((dependent, index) => (
          <div className="w-1/2 px-2" key={`Dependent-${index}`}>
            <Form.Item className="w-full mb-3">
              <Input size="large" value={dependent} disabled />
            </Form.Item>
          </div>
        ))}
      </div>
    );
  }

  function renderCancellation() {
    if (status !== SUBSCRIPTION_STATUS.CANCELLED) return null;
    return (
      <>
        {data && (
          <>
            <div className="flex flex-row w-full gap-2">
              <Form.Item label="Date user subscribed" className="w-1/2">
                <Input
                  placeholder="2023/06/20"
                  value={dateSubscribed}
                  size="large"
                  disabled
                />
              </Form.Item>
              <Form.Item label="Subscription inc. Vat" className="w-1/2">
                <Input
                  placeholder="Neli"
                  value={`${data.subscription} ${data.subscriptionCost}`}
                  size="large"
                  disabled
                />
              </Form.Item>
            </div>

            <Form.Item label="Next Billing date" className="w-1/2 mb-3">
              <Input size="large" value={billingDate} disabled />
            </Form.Item>

            <Form.Item
              name="principleMember"
              label="Principle Member"
              className="w-1/2 mb-3"
            >
              <Input size="large" disabled />
            </Form.Item>

            {renderDependents()}
          </>
        )}
      </>
    );
  }

  function renderNoSubscription() {
    if (
      [
        SUBSCRIPTION_STATUS.ACTIVE,
        SUBSCRIPTION_STATUS.CANCELLED,
        SUBSCRIPTION_STATUS.NON_RENEWING,
      ].includes(status)
    )
      return null;
    // Return JSX for no subscription condition
    return <>{renderSubscriptionSelect()}</>;
  }

  function renderSubscriptionView() {
    if (
      status !== SUBSCRIPTION_STATUS.ACTIVE &&
      status !== SUBSCRIPTION_STATUS.NON_RENEWING
    ) {
      return null;
    }

    return (
      <>
        {data && (
          <div>
            <div className="flex flex-row w-full gap-2">
              <Form.Item label="Date user subscribed" className="w-1/2">
                <Input
                  placeholder="2023/06/10"
                  value={dateSubscribed}
                  size="large"
                  disabled
                />
              </Form.Item>
              <Form.Item label="Subscription inc. Vat" className="w-1/2">
                <Input
                  value={`${data.subscription} ${data.subscriptionCost}`}
                  size="large"
                  disabled
                />
              </Form.Item>
            </div>
            <Form.Item name="" label="Next Billing date" className="mb-3">
              <Input value={billingDate} size="large" disabled />
            </Form.Item>
            <Form.Item
              name="principleMember"
              label="Principle Member"
              className="w-1/2 mb-3"
            >
              <Input placeholder="Neli" size="large" disabled />
            </Form.Item>

            {renderDependents()}
          </div>
        )}
      </>
    );
  }

  return (
    <div className="w-full h-full">
      {contextHolder}
      <Container className="w-full h-full">
        <BodyContainer className="w-full h-full">
          <div className="flex flex-row justify-start w-full align-items-center">
            <BackButton />
            <HeaderComponent title="User Profile" />
          </div>

          <div className="justify-start w-4/5 px-3">
            <Spin tip="" spinning={isLoading || isFetching} delay={500}>
              <Form
                form={form}
                onFinish={onFinish}
                name="user"
                layout="vertical"
                className="w-full mt-6 h-3/5"
                initialValues={data}
              >
                <div className="flex flex-row w-full gap-2">
                  <Form.Item
                    name="firstName"
                    label="First Name"
                    className="w-1/2"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your first name!",
                      },
                    ]}
                  >
                    <Input placeholder="Neli" size="large" />
                  </Form.Item>
                  <Form.Item
                    name="lastName"
                    label="Last Name"
                    className="w-1/2"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your last name!",
                      },
                    ]}
                  >
                    <Input placeholder="Neli" size="large" />
                  </Form.Item>
                </div>

                <div className="flex flex-row w-full gap-2">
                  <Form.Item
                    name="emailAddress"
                    label="Email"
                    className="w-1/2"
                  >
                    <Input placeholder="Neli@email.com" size="large" disabled />
                  </Form.Item>
                  <Form.Item
                    name="phoneNumber"
                    label="Phone Number"
                    className="w-1/2"
                  >
                    <Input placeholder="Neli" size="large" />
                  </Form.Item>
                </div>

                <div className="flex flex-row w-full gap-2">
                  <Form.Item
                    // name="dob"
                    label="Date of Birth"
                    className="w-1/2"
                  >
                    <Input
                      placeholder="1985/07/11"
                      value={dob}
                      size="large"
                      disabled
                    />
                  </Form.Item>
                  <Form.Item
                    name="gender"
                    label="Gender"
                    className="w-1/2 mb-3"
                  >
                    <Select placeholder="-" size="large" disabled>
                      <Select.Option>Female</Select.Option>
                      <Select.Option>Male</Select.Option>
                    </Select>
                  </Form.Item>
                </div>

                <div className="flex flex-row w-full gap-2">
                  <Form.Item
                    name="companyId"
                    label="Company"
                    className="w-1/2 mb-3"
                  >
                    <div className="w-full flex flex-row items-center">
                      <Input
                        className="w-12"
                        readOnly
                        value={data?.userCompany}
                        size="large"
                      />
                      {data?.subscriptionFrom?.toLocaleLowerCase() ===
                        "portal" || data?.subscriptionFrom == null ? (
                        <button
                          type="button"
                          className="pl-2"
                          onClick={() => {
                            setOpenCompanyModal(true);
                          }}
                        >
                          <CustomIcons.PencilIcon
                            height={23}
                            width={23}
                            svgColor={Colors.appMainColor}
                          />
                        </button>
                      ) : (
                        <Tooltip
                          placement="top"
                          title={
                            "Users can only be assigned to a company if their Subscription is from Portal or a new user"
                          }
                          color={Colors.appMainColor}
                        >
                          <button>
                            <CustomIcons.InformationIcon
                              height={30}
                              width={30}
                              svgColor={Colors.appMainColor}
                            />
                          </button>
                        </Tooltip>
                      )}
                    </div>
                  </Form.Item>
                </div>

                <div className="flex flex-row w-full gap-2">
                  <Form.Item
                    label="User Registration Date"
                    className="w-1/2"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the registration date",
                      },
                    ]}
                  >
                    <Input value={registrationDate} size="large" disabled />
                  </Form.Item>
                  <Form.Item name="" label=" " className="justify-start w-1/2">
                    <LinkText
                      className=""
                      onClick={() => navigateTo(data?.userId)}
                    >
                      View Chat History
                    </LinkText>
                  </Form.Item>
                </div>

                {/* Cancellation */}
                {renderCancellation()}
                {/* No Subscription */}
                {renderNoSubscription()}
                {/* Subscription */}
                {renderSubscriptionView()}
                {subscriptionFrom === "Portal" && (
                  <>{renderSubscriptionSelect()}</>
                )}

                <Form.Item className="w-full">
                  <div className="flex flex-row items-center justify-end w-full">
                    <Button
                      type="primary"
                      ghost
                      size="large"
                      className="w-2 bg-[#1757a2] shadow-none mr-2"
                      onClick={navigateBack}
                    >
                      Cancel
                    </Button>

                    <Button
                      loading={editUser.isLoading}
                      type="primary"
                      size="large"
                      className="w-2 bg-[#1757a2] shadow-none"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </Spin>
          </div>
        </BodyContainer>
        <Modal
          centered
          open={openSubscriptionModal}
          onOk={() => setOpenSubscriptionModal(false)}
          onCancel={() => setOpenSubscriptionModal(false)}
          width={550}
          closeIcon={
            <CustomIcons.CloseIcon
              width={30}
              height={30}
              viewBox={null}
              svgColor="#1C274C"
            />
          }
          footer={null}
        >
          <div
            className="w-12 flex justify-content-start py-3"
            style={modelTitle}
          >
            Subscription
          </div>
          <RenewUserSub
            onFormFinish={(userRenewal: IUserSubRenewal) => {
              handleUserRenewal(userRenewal);
            }}
            onModalClose={() => {
              handleClose();
            }}
            isLoading={userSubRenewal.isLoading}
            isSuccess={userSubRenewal.isSuccess}
          />
        </Modal>
        <Modal
          centered
          open={openCompanyModal}
          onOk={() => setOpenCompanyModal(false)}
          onCancel={() => setOpenCompanyModal(false)}
          width={550}
          closeIcon={
            <CustomIcons.CloseIcon
              width={30}
              height={30}
              viewBox={null}
              svgColor="#1C274C"
            />
          }
          footer={null}
        >
          <div
            className="w-12 flex justify-content-start py-3"
            style={modelTitle}
          >
            User Company Subscription
          </div>
          <RenewUserCompany
            onFormFinish={(userRenewal: IUserCompanyRenewal) => {
              handleUserCompanyRenewal(userRenewal);
            }}
            onModalClose={() => {
              handleClose();
            }}
            isLoading={userCompRenewal.isLoading}
            isSuccess={userCompRenewal.isSuccess}
          />
        </Modal>
      </Container>
    </div>
  );
};

export default UserProfile;

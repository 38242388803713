import { createQueryKeys } from "@lukemorales/query-key-factory";
import ResponderAnalytics from "../ResponderAnalytics";

export const responderAnalytics = createQueryKeys("responderAnalytics", {
  getResponderAnalyticsCallOutDetails: (id: string | number) => ({
    queryKey: ["CallOutDetails", { id }],
    queryFn: () => ResponderAnalytics.getResponderAnalyticsCallOutDetails(id),
  }),

  getResponderAnalyticsTableData: null,
  getResponderAnalyticsCardData: (cardFilter?: IResponderFilters) => ({
    queryKey: ["responderCards", { filter: cardFilter }],
    queryFn: () => ResponderAnalytics.getResponderCardData(cardFilter),
  }),
  getResponderAnalyticsMapData: (mapMarkerFilter?: IResponderFilters) => ({
    queryKey: ["mapMarkers", { filter: mapMarkerFilter }],
    queryFn: () => ResponderAnalytics.getMapMarkers(mapMarkerFilter),
  }),
  getResponderAnalyticsIncidentPieGraph: (
    incidentPieFilter?: IResponderFilters
  ) => ({
    queryKey: ["incidentPieGraph", { filter: incidentPieFilter }],
    queryFn: () => ResponderAnalytics.getIncidentChart(incidentPieFilter),
  }),
  getResponderAnalyticsTopCompanies: (
    topCompaniesFilter?: IResponderFilters
  ) => ({
    queryKey: ["topCompanies", { filter: topCompaniesFilter }],
    queryFn: () =>
      ResponderAnalytics.getTopResponderCompaniesTable(topCompaniesFilter),
  }),
  getResponderCallOutsTable: (callOutHistoryFilter?: IResponderFilters) => ({
    queryKey: ["callOutHistoryTable", { filter: callOutHistoryFilter }],
    queryFn: () => ResponderAnalytics.getCallOutTable(callOutHistoryFilter),
  }),

  getResponderDetailsCharts: (id: string | number) => ({
    queryKey: ["responderDetailsCharts", { id }],
    queryFn: () => ResponderAnalytics.getResponderDetailsCharts(id),
  }),
  getResponderDetailsTableData: (id: string | number) => ({
    queryKey: ["responderDetailsTableData", { id }],
    queryFn: () => ResponderAnalytics.getResponderDetailsTableData(id),
  }),
});

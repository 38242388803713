import React, { useState } from "react";
import { message, Tabs } from "antd";
import type { TabsProps } from "antd";
import CPFPersonalDetails from "./CPFPersonalDetails";
import CPFStatus from "./CPFStatus";
import {
  useGetCPFResponderDetails,
  useUpdateCPFResponder,
} from "@/hooks/useCPFs";
import { cpfResponder } from "@/services/queries/cpfResponderQueries";
import { toast } from "sonner";

type CPFUserIdProp = {
  cpfTableId: number;
  onModalClose: () => void;
  refetch: () => void;
};

const CPFDetailsTabs = ({
  cpfTableId,
  onModalClose,
  refetch,
}: CPFUserIdProp) => {
  const { data, isLoading, isFetching } = useGetCPFResponderDetails(cpfTableId);
  const updateCPFResponder = useUpdateCPFResponder();
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [fullPhoneNumber, setFullPhoneNumber] = useState<string>("");
  const [emailAddress, setEmailAddress] = useState<string>("");
  const [dateOfBirth, setDateOfBirth] = useState<string>("");
  const [gender, setGender] = useState<string>("");
  const [cpfid, setCPFID] = useState<string>("");
  const [area, setArea] = useState<string>("");

  const [activeKey, setActiveKey] = useState<string>("1");
  const handleNextTab = () => {
    const nextTab = (parseInt(activeKey) % 3) + 1; // Assuming 2 tabs
    setActiveKey(nextTab.toString());
  };

  const setValues = (details: ICPFResponderDetails) => {
    setFirstName(details?.firstName as string);
    setLastName(details?.lastName as string);
    setFullPhoneNumber(details?.fullPhoneNumber as string);
    setEmailAddress(details?.emailAddress as string);
    setDateOfBirth(details?.dateOfBirth as string);
    setGender(details?.gender as string);
    setCPFID(details?.cpfid as string);
    setArea(details?.area as string);
  };

  //Set the values from the DB
  const personalDetails: ICPFResponderPeronalDetails = {
    firstName: data?.firstName,
    lastName: data?.lastName,
    dateOfBirth: data?.dateOfBirth,
    gender: data?.gender,
    fullPhoneNumber: data?.fullPhoneNumber,
    emailAddress: data?.emailAddress,
    cpfid: data?.cpfid,
    area: data?.area,
  };

  const statusDetils: ICPFResponderStatus = {
    isActive: data?.isActive,
    reason: data?.reason,
  };

  const getValueOrDefault = (value: any, defaultValue: any) => {
    return value !== undefined && value !== "" ? value : defaultValue;
  };

  const handleCPFResponderDetails = async (details: ICPFResponderDetails) => {
    // Utility function to choose the first valid value (not undefined or empty string)

    const values: ICPFResponderDetails = {
      firstName: getValueOrDefault(firstName, data?.firstName),
      lastName: getValueOrDefault(
        lastName,
        details?.lastName ?? data?.lastName
      ),
      fullPhoneNumber: getValueOrDefault(
        fullPhoneNumber,
        data?.fullPhoneNumber
      ),
      emailAddress: getValueOrDefault(emailAddress, data?.emailAddress),
      dateOfBirth: getValueOrDefault(dateOfBirth, data?.dateOfBirth),
      gender: getValueOrDefault(gender, data?.gender),
      cpfid: getValueOrDefault(cpfid, data?.cpfid),
      area: getValueOrDefault(area, data?.area),
      isActive: details?.isActive ?? data?.isActive, // Assuming `undefined` is not a valid state for isActive
      reason: details?.reason,
      userId: data?.userId,
      id: data?.id, // This is the CPF Table ID
    };
    try {
      await updateCPFResponder.mutateAsync(values);
      toast.success("CPF Responder details updated successfully");
      refetch();
    } catch (error) {
      // Assuming `error` could be of any type, we first ensure it's an Error object
      const errorMessage =
        error instanceof Error ? error.message : "An unknown error occurred";

      // Log the error for debugging purposes
      console.error(error);

      // Display a user-friendly error message
      message.error(`Failed to update CPF Responder details: ${errorMessage}`);
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Personal Details",
      children: (
        <CPFPersonalDetails
          editCPFResponderValues={personalDetails}
          onFormFinish={async (details: ICPFResponderDetails) => {
            setValues(details);
            handleNextTab();
          }}
          isLoading={isLoading}
          isSuccess={updateCPFResponder.isSuccess}
          onModalClose={() => {
            onModalClose();
          }}
          isUpdating={updateCPFResponder.isLoading}
        />
      ),
    },
    {
      key: "2",
      label: "Status",
      children: (
        <CPFStatus
          editResponderValues={statusDetils}
          onFormFinish={async (details: ICPFResponderDetails) => {
            handleCPFResponderDetails(details);
          }}
          onModalClose={() => {
            onModalClose();
          }}
          isLoading={isLoading}
          isSuccess={updateCPFResponder.isSuccess}
          isUpdating={updateCPFResponder.isLoading}
        />
      ),
    },
  ];

  return (
    <div className="flex w-full h-full">
      <div className="w-full h-full px-3">
        <Tabs
          defaultActiveKey="1"
          activeKey={activeKey}
          items={items}
          onChange={(key) => setActiveKey(key)}
        />
      </div>
    </div>
  );
};

export default CPFDetailsTabs;

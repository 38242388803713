import React from "react";
import Colors from "../../config/Colors";

export function BackIcon({
  height, // number type by default
  width,
  viewBox = "0 0 20 20",
  svgColor = `${Colors.mainTextColor}`,
}: CustomSVGProp) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 320 512"
    >
      <path
        fill={svgColor}
        d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256l137.3-137.4c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
      />
    </svg>
  );
}
export default BackIcon;

import { API } from "../config/axios";

class ResponderAnalytics {
  getResponderTableData = async (): Promise<IResponderAnalyticsFilters> => {
    const response = await API.get<IResponderAnalyticsFilters>(
      "Admin_PanicEventsAnalytics"
    );
    return response.data;
  };

  buildQueryString(filters: any) {
    let buildString = "?";

    if (filters?.companyIds != null && filters.companyIds.length > 0) {
      for (let i = 0; i < filters.companyIds.length; i += 1) {
        buildString += `CompanyIds=${filters.companyIds[i]}&`;
      }
    }

    if (filters?.areas != null && filters.areas.length > 0) {
      for (let i = 0; i < filters.areas.length; i += 1) {
        buildString += `Areas=${filters.areas[i]}&`;
      }
    }

    if (filters?.callOutIds != null && filters.callOutIds.length > 0) {
      for (let i = 0; i < filters.callOutIds.length; i += 1) {
        buildString += `CallOutIds=${filters.callOutIds[i]}&`;
      }
    }

    if (filters?.dispatchSource != null && filters.dispatchSource.length > 0) {
      for (let i = 0; i < filters.dispatchSource.length; i += 1) {
        buildString += `PanicTypes=${filters.dispatchSource[i]}&`;
      }
    }

    if (filters?.operatorIds != null && filters.operatorIds.length > 0) {
      for (let i = 0; i < filters.operatorIds.length; i += 1) {
        buildString += `OperatorIds=${filters.operatorIds[i]}&`;
      }
    }

    if (filters?.startDate != null && filters.endDate != null) {
      buildString += `StartDate=${filters.startDate}&EndDate=${filters.endDate}&`;
    }

    buildString = buildString.replace(/&$/, "");

    return buildString;
  }

  getResponderCardData = async (
    filters?: IResponderFilters
  ): Promise<IResponderAnalyticsCardDTO> => {
    const builtString = this.buildQueryString(filters);
    const response = await API.get<IResponderAnalyticsCardDTO>(
      `Admin_PanicEventsAnalytics/ResponderAnalyticsCards${builtString}`
    );
    return response.data;
  };

  getTopResponderCompaniesTable = async (
    filters?: IResponderFilters
  ): Promise<ITopResponder[]> => {
    const builtString = this.buildQueryString(filters);

    const response = await API.get<ITopResponder[]>(
      `Admin_PanicEventsAnalytics/TopRespondersCompanies${builtString}`
    );

    return response.data;
  };

  getCallOutTable = async (
    filters?: IResponderFilters
  ): Promise<ICallOutsDTO[]> => {
    const builtString = this.buildQueryString(filters);
    const response = await API.get<ICallOutsDTO[]>(
      `Admin_PanicEventsAnalytics/CallOutsTable${builtString}`
    );

    return response.data;
  };

  getMapMarkers = async (
    filters?: IResponderFilters
  ): Promise<IMaplocations[]> => {
    const builtString = this.buildQueryString(filters);
    const response = await API.get<IMaplocations[]>(
      `Admin_PanicEventsAnalytics/MapLocations${builtString}`
    );

    return response.data || [];
  };

  getIncidentChart = async (
    filters?: IResponderFilters
  ): Promise<IIncidentPieGraph> => {
    const builtString = this.buildQueryString(filters);
    const response = await API.get<IIncidentPieGraph>(
      `Admin_PanicEventsAnalytics/IncidentPieGraph${builtString}`
    );

    return response.data;
  };

  getResponderAnalyticsCallOutDetails = async (
    responderId: string | number
  ): Promise<ICallOutDetails> => {
    const response = await API.get<ICallOutDetails>(
      `Admin_PanicEventsAnalytics/ResponderAnalytic/ResponderReport/${responderId}`
    );
    return response.data;
  };

  getResponderAnalyticsDetails = async (
    id: string | number
  ): Promise<IResponderDetails> => {
    const response = await API.get<IResponderDetails>(
      `Admin_PanicEventsAnalytics/ResponderDetails/${id}`
    );
    return response.data;
  };

  getResponderDetailsCharts = async (
    id: string | number
  ): Promise<IResponderAnalyticsCharts> => {
    const response = await API.get<IResponderAnalyticsCharts>(
      `Admin_PanicEventsAnalytics/ResponderDetailsCharts/${id}`
    );
    return response.data;
  };

  getResponderDetailsTableData = async (
    id: string | number
  ): Promise<IResponderAnalyticsTableData> => {
    const response = await API.get<IResponderAnalyticsTableData>(
      `Admin_PanicEventsAnalytics/ResponderDetailsTable/${id}`
    );

    return response.data;
  };
}

export default new ResponderAnalytics();

import { Button, message } from "antd";
import React from "react";
import { SubHeading } from "../../pages/Home/styles";
import { useDeletePost } from "../../hooks/useCommunities";

interface ModalProp {
  text: string;
  onConfirm: () => void;
  onClose: () => void;
}

const GenericDialog = ({ text, onClose, onConfirm }: ModalProp) => {
  return (
    <div className="flex items-center justify-center w-full">
      <div className="flex flex-col items-center justify-center w-full">
        <SubHeading>Are you sure you want to proceed ?</SubHeading>
        <p>You are about to {text}</p>
        <div className="flex flex-row items-center justify-center w-full mt-3">
          <Button
            type="primary"
            size="large"
            //loading={postDraft.isLoading}
            ghost
            className="w-3 bg-[#1757a2] shadow-none mr-2"
            onClick={onClose}
          >
            No
          </Button>
          <Button
            type="primary"
            size="large"
            // loading={postVerify.isLoading}
            className="w-3 bg-[#1757a2] shadow-none"
            onClick={onConfirm}
          >
            Yes
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GenericDialog;

/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import { DatePicker, Space } from "antd";
import "./Multiselect.css";

type DateFilterProps = {
  onFilterChange: (name: string, value: any) => void;
};

const DateFilterDropDown = ({ onFilterChange }: DateFilterProps) => {
  const { RangePicker } = DatePicker;
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();

  const handleDateChange = (dates: any, dateString: [string, string]) => {
    onFilterChange("startDate", dateString[0]);
    onFilterChange("endDate", dateString[1]);
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
  };

  return (
    <div className="responderAnalytics h-20 w-3 py-1 px-1 mr-0.5 bg-appMainColor justify-center flex-shrink-0">
      <RangePicker
        className="flex items-center justify-center w-full h-full white-placeholder my-range-picker bg-saferCity-primary text-popupColor hover:bg-appMainColor border-appMainColor placeholder-shown:placeholder:"
        onChange={handleDateChange}
      />
    </div>
  );
};

export default DateFilterDropDown;

/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-unused-vars */
import { Button, Form, Input, message, Select, Alert } from "antd";
import React, { useEffect } from "react";
import { useEmergencies } from "../../hooks/useEmergencies";

type AddExistingAdminFormProp = {
  onFormFinish: (
    user: string,
    role: number | null,
    typeId: number | null
  ) => void;
  onModelClose: () => void;
  isLoading: boolean;
  isSuccess: boolean;
};

const AddExistingAdminForm = ({
  onFormFinish,
  onModelClose,
  isLoading,
  isSuccess,
}: AddExistingAdminFormProp) => {
  const [form] = Form.useForm();
  const {
    data: emergencies,
    isLoading: loadingEmergencies,
    isFetching: refetchingEmergencies,
  } = useEmergencies();

  const onFinish = async (formValues: any) => {
    await onFormFinish(formValues.key, formValues.role, formValues.emergencyId);
  };

  const handleClose = () => {
    form.resetFields();
    onModelClose();
  };

  const onFinishFailed = (errorInfo: any) => {
    message.error("Could not create user, please try again.");
  };

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
    }
  }, [isSuccess]);

  return (
    <Form
      form={form}
      name="existingUserForm"
      onFinish={onFinish}
      layout="vertical"
      style={{ width: "100%" }}
      className="grid"
    >
      <Alert
        message="Please make sure that the user already has an account with Safer City."
        type="info"
        showIcon
      />
      <div className="pt-3 col-12">
        <Form.Item
          name="key"
          label=""
          rules={[
            { required: true, message: "Please enter value!" },
            {
              validator: async (_, value) => {
                const emailRegex =
                  /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
                const phoneRegex = /^\+27\d{9}$/;

                if (emailRegex.test(value) || phoneRegex.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    "Please enter a valid email or phone number with +27 code."
                  )
                );
              },
            },
          ]}
          className="mb-1"
          extra="The number needs to contain a +27 instead of 0"
        >
          <Input
            placeholder="Enter Email or Phone Number Here"
            size="large"
            className="w-12"
            min={1}
            max={50}
          />
        </Form.Item>
      </div>
      <div className="col-12">
        <Form.Item
          name="role"
          label="Admin Role"
          rules={[
            { required: true, message: "Please select the user's role!" },
          ]}
          className="mb-3"
        >
          <Select placeholder="Select admin role" size="large">
            <Select.Option value={21}>Super Admin</Select.Option>
            <Select.Option value={22}>Emergency Admin</Select.Option>
          </Select>
        </Form.Item>
      </div>

      <div className="col-12">
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.role !== currentValues.role
          }
        >
          {({ getFieldValue }) =>
            getFieldValue("role") === 22 ? (
              <Form.Item
                name="emergencyId"
                label="Emergency"
                rules={[
                  {
                    required: true,
                    message: "Please select the user's emergency!",
                  },
                ]}
              >
                <Select
                  placeholder="Select Emergency"
                  size="large"
                  loading={loadingEmergencies || refetchingEmergencies}
                >
                  {emergencies?.map((item: IEmergency) => (
                    <Select.Option
                      value={item.id}
                      key={`key-emergencies-${item.id}`}
                    >
                      {item.emergencyType}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            ) : null
          }
        </Form.Item>
      </div>

      <Form.Item className="w-12 mb-1">
        <div className="flex w-full gap-2 col-12 justify-content-end align-content-center">
          <Button
            type="primary"
            ghost
            onClick={handleClose}
            size="large"
            className="w-3"
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            size="large"
            className="w-3 bg-[#1757a2] shadow-none"
          >
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default AddExistingAdminForm;

import React, { useEffect, useState } from "react";
import ResponderCompanyModal from "@/components/Modals/NewResponderCompanyModal";
import NewResponderModal from "@/components/Modals/NewResponderModal";
import EditResponderModal from "@/components/Modals/EditResponderModal";
import ResponderBulkUpload from "@/components/Modals/ResponderBulkUploadModal";
import NewControlRoomOperatorModal from "@/components/Modals/NewControlRoomOperatorModal";
import ResponderVehicleModal from "@/components/Modals/ResponderVehicleModal";

export const ModalProvider = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  if (!isMounted) {
    return null;
  } // reason for this is to prevent the modal from rendering on the server, creating inconsistent and causing hydration issues
  return (
    <>
      <ResponderCompanyModal />
      <NewResponderModal />
      <EditResponderModal />
      <ResponderBulkUpload />
      <NewControlRoomOperatorModal />
      <ResponderVehicleModal />
    </>
  );
};

import MultipleSelector, { Option } from "@/components/ui/multiple-selector";
import { Button, Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import {
  useAllAffiliationCodes,
  useGetAffiliationCodeByCode,
} from "@/hooks/useCommunityCodes";

type EditReferralCodeProps = {
  onFormFinish: (code: IUpdateAffiliationCode) => void;
  onModelClose: () => void;
  isLoading: boolean;
  isSuccess: boolean;
  primaryCategories: IAffiliationPrimaryCategory[];
  secondaryCategories: IAffiliationSecondaryCategory[];
  tertiarycategories: IAffiliationTertiaryCategory[];
};

const EditReferralCode = ({
  onFormFinish,
  onModelClose,
  isLoading,
  isSuccess,
  primaryCategories,
  secondaryCategories,
  tertiarycategories,
}: EditReferralCodeProps) => {
  const [form] = Form.useForm();

  const { data: allCodes } = useAllAffiliationCodes();
  const [selectedCode, setSelectedCode] = useState<string | null>(null);
  const { data: selectedCodeData, refetch } = useGetAffiliationCodeByCode(
    selectedCode as string
  );
  const [categoryState, setCategoryState] = useState({
    primaryCategory: null as Option | null,
    secondaryCategory: null as Option | null,
    tertiaryCategory: null as Option | null,
  });

  useEffect(() => {
    if (selectedCode) {
      refetch();
    }
  }, [selectedCode, refetch]);

  useEffect(() => {
    if (selectedCodeData) {
      setCategoryState({
        primaryCategory: {
          label: selectedCodeData.primaryCategory,
          value: selectedCodeData.primaryCategory,
        },
        secondaryCategory: {
          label: selectedCodeData.secondaryCategory,
          value: selectedCodeData.secondaryCategory,
        },
        tertiaryCategory: {
          label: selectedCodeData.tertiaryCategory || "",
          value: selectedCodeData.tertiaryCategory || "",
        },
      });
    }
  }, [selectedCodeData]);

  useEffect(() => {
    if (isSuccess) {
      setSelectedCode(null);
      form.resetFields();
      onModelClose();
    }
  }, [isSuccess]);

  const codes = allCodes?.map((code) => ({
    value: code.code,
    label: code.code,
  }));

  const primaryOptions = primaryCategories.map((c) => ({
    label: c.name,
    value: c.name,
  }));

  const secondaryOptions = secondaryCategories.map((c) => ({
    label: c.name,
    value: c.name,
  }));

  const tertiaryOptions = tertiarycategories.map((c) => ({
    label: c.name,
    value: c.name,
  }));

  const onFinish = async (formValues: any) => {
    const values: IUpdateAffiliationCode = {
      code: formValues.affiliationCode,
      primaryCategory:
        (categoryState?.primaryCategory?.value as string) ||
        (selectedCodeData?.primaryCategory as string),
      secondaryCategory:
        (categoryState?.secondaryCategory?.value as string) ||
        (selectedCodeData?.secondaryCategory as string),
      tertiaryCategory:
        (categoryState?.tertiaryCategory?.value as string) ||
        (selectedCodeData?.tertiaryCategory as string),
    };
    onFormFinish(values);
  };

  const handleClose = () => {
    onModelClose();
    form.resetFields();
    setSelectedCode(null);
  };

  const handleCategoryChange = (key: string) => (value: Option | null) => {
    setCategoryState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <Form
      name="editAffiliation"
      layout="vertical"
      form={form}
      onFinish={onFinish}
      className="grid"
    >
      <div className="col-12 md:col-6">
        <Form.Item
          name="affiliationCode"
          label="Add New Code"
          rules={[{ required: true, message: "Please enter referral code!" }]}
          className="mb-1"
        >
          <Select
            options={codes}
            size="large"
            placeholder="Enter Code"
            className="mb-2"
            onChange={(value: string) => setSelectedCode(value)}
          />
        </Form.Item>
      </div>

      {selectedCode && (
        <>
          <div className="col-12 md:col-6">
            <Form.Item
              label="Primary Code"
              rules={[
                { required: true, message: "Please enter referral code!" },
              ]}
              className="mb-1"
            >
              <MultipleSelector
                hidePlaceholderWhenSelected
                defaultOptions={primaryOptions}
                placeholder="Select a primary code or enter a new primary code"
                creatable
                maxSelected={1}
                onChange={(value) =>
                  handleCategoryChange("primaryCategory")(value[0] || null)
                }
                value={
                  categoryState.primaryCategory
                    ? [categoryState.primaryCategory]
                    : []
                }
              />
            </Form.Item>
          </div>
          <div className="col-12 md:col-6">
            <Form.Item
              label="Secondary Code"
              rules={[
                { required: true, message: "Please enter referral code!" },
              ]}
              className="mb-1"
            >
              <MultipleSelector
                hidePlaceholderWhenSelected
                defaultOptions={secondaryOptions}
                placeholder="Select a secondary code or enter a new secondary code"
                creatable
                maxSelected={1}
                onChange={(value) =>
                  handleCategoryChange("secondaryCategory")(value[0] || null)
                }
                value={
                  categoryState.secondaryCategory
                    ? [categoryState.secondaryCategory]
                    : []
                }
              />
            </Form.Item>
          </div>
          <div className="col-12 md:col-6">
            <Form.Item
              label="Tertiary Code"
              rules={[
                { required: true, message: "Please enter referral code!" },
              ]}
              className="mb-1"
            >
              <MultipleSelector
                hidePlaceholderWhenSelected
                defaultOptions={tertiaryOptions}
                placeholder="Select a tertiary code or enter a new tertiary code"
                creatable
                maxSelected={1}
                onChange={(value) =>
                  handleCategoryChange("tertiaryCategory")(value[0] || null)
                }
                value={
                  categoryState.tertiaryCategory
                    ? [categoryState.tertiaryCategory]
                    : []
                }
              />
            </Form.Item>
          </div>
        </>
      )}

      <Form.Item className="w-12 mb-1">
        <div className="flex w-full gap-2 col-12 justify-content-end align-content-center">
          <Button
            type="primary"
            ghost
            onClick={handleClose}
            size="large"
            className="w-3"
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            size="large"
            className="w-3 bg-[#1757a2] shadow-none"
          >
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default EditReferralCode;

/* eslint-disable comma-dangle */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-alert */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable  implicit-arrow-linebreak */
import React, {
  useEffect,
  useInsertionEffect,
  useState,
  useContext,
} from "react";
import { observer } from "mobx-react-lite";
import { Container, InformationContainer, BodyContainer } from "./styles";
import { CustomIcons } from "../../assets/index";
import HeaderComponent from "../../components/Header/Header";
import Colors from "../../config/Colors";
import { DashboardCard } from "../../components/Cards/DashboardCard";
import { useDetailOperatorDashboard } from "../../hooks/useDashboard";
import OperatorDashboardChart from "../../components/Charts/Dashboard/OperatorDashboardChart";
import { OperatorDashboardDoughnut } from "../../components/Charts/Dashboard/OperatorDashboardDoughnut";
import AuthContext from "../../contexts/AuthContext";

const OperatorHome = observer(() => {
  const { user } = useContext(AuthContext);
  const { isLoading, data, refetch } = useDetailOperatorDashboard(
    user && user?.id.toString()
  );

  return (
    <div className="w-full h-full">
      <Container className="w-full h-full">
        <BodyContainer className="w-full disable-scrollbars">
          <HeaderComponent title="Home" />
          {isLoading ? (
            <div className="flex flex-column justify-content-center align-items-center h-full">
              <CustomIcons.EOSThreeDotsLoading
                width={80}
                height={80}
                viewBox={null}
                svgColor={Colors.appMainColor}
              />
            </div>
          ) : (
            <InformationContainer>
              <div className="w-12 h-12 py-1">
                {data !== null && data !== undefined && (
                  <>
                    <div className="py-1">
                      <div className="flex flex-row gap-3 overflow-x-scroll disable-scrollbars py-3 px-2">
                        {data.metrics.map((item: IOperatorMetric) => (
                          <div key={`key-${item.name}`}>
                            <DashboardCard
                              title={item.name}
                              number={item.total}
                              percentage={item.increase}
                              trend={item.increase >= 0}
                              key={`key-${item.name}`}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="grid px-2 animate-fadeInUp animate-faster">
                      <div className="col-12 md:col-6 animate-bounce animate-repeat-2">
                        <div className="w-12 h-full">
                          <OperatorDashboardChart messages={data.messages} />
                        </div>
                      </div>
                      <div className="col-12 md:col-6 animate-bounce animate-repeat-2">
                        <div className="w-12">
                          <OperatorDashboardDoughnut
                            packages={data.casesOverview}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </InformationContainer>
          )}
        </BodyContainer>
      </Container>
    </div>
  );
});

export default OperatorHome;

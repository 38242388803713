import { createQueryKeys } from "@lukemorales/query-key-factory";
import DirectMessage from "../DirectMessage";

export const directMessage = createQueryKeys("directMessage", {
  all: null,
  conversationByCase: (caseId: string | number) => ({
    queryKey: [caseId],
    queryFn: () => DirectMessage.getConversationByCase(caseId),
  }),
  conversationByIds: (
    userId: string | number,
    receiverId: string | number
  ) => ({
    queryKey: [userId, receiverId],
    queryFn: () => DirectMessage.getConversationByIds(userId, receiverId),
  }),
});

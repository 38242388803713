import { CSSProperties } from "react";
import styled from "styled-components";
import Colors from "../config/Colors";

export const WINDOW_WIDTH = window.innerWidth;
export const WINDOW_HEIGHT = window.innerHeight;

export const successTitle: CSSProperties = {
  color: `${Colors.mainTextColor}`,
  fontWeight: 700,
  fontSize: 22,
  fontStyle: "normal",
};
export const successSubtitle: CSSProperties = {
  color: `${Colors.subtleTextColor}`,
  fontWeight: 400,
  fontSize: 14,
  fontStyle: "normal",
};

export const modelTitle: CSSProperties = {
  color: `${Colors.mainTextColor}`,
  fontWeight: 700,
  fontSize: 24,
  fontStyle: "normal",
};

import React, { FC, ButtonHTMLAttributes } from "react";
import styled from "styled-components";
import Colors from "../../../config/Colors";

const SubmitButton = styled.button`
  border: none;
  border-radius: 8px;

  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: ${Colors.backgroundColor};
`;

const BlueButtonStyled = styled(SubmitButton)`
  background-color: ${Colors.appMainColor};
  color: white;
`;

interface BlueButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: () => void;
}

export const BlueButton: FC<BlueButtonProps> = ({
  onClick,
  children,
  ...props
}) => (
  <BlueButtonStyled onClick={onClick} {...props}>
    {children}
  </BlueButtonStyled>
);

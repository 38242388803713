/* eslint-disable max-len */
/* eslint-disable prettier/prettier */
import React from "react";
import Colors from "../../config/Colors";

export function Profile({
  height,
  width,
  viewBox = "0 0 24 24",
  svgColor = `${Colors.secondaryColor}`,
}: CustomSVGProp) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox || "0 0 24 24"}
      fill="none"
    >
      <path
        fill={svgColor || "#000000"}
        d="M12 19.2c-2.5 0-4.71-1.28-6-3.2c.03-2 4-3.1 6-3.1s5.97 1.1 6 3.1a7.232 7.232 0 0 1-6 3.2M12 5a3 3 0 0 1 3 3a3 3 0 0 1-3 3a3 3 0 0 1-3-3a3 3 0 0 1 3-3m0-3A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10c0-5.53-4.5-10-10-10Z"
      />
    </svg>
  );
}
export default Profile;

import React, { useState, useEffect } from "react";
import { DataTableFilterMeta, DataTable } from "primereact/datatable";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Column } from "primereact/column";
import { SearchOutlined } from "@ant-design/icons";
import { Tag, Button } from "antd";
import { PostApplicationStatus } from "../../util/enums";
import Spin from "../widgets/Spinner/Spin";
import { useGetCommunityUsersByStatus } from "../../hooks/usePostApplication";
import { tableBody, tableHeader } from "../../styles/TableStyles";
import CustomInputField from "../widgets/Inputs/CustomInputField";
import { customInputTheme } from "../../util/helpers";
import Colors from "../../config/Colors";
import { CustomIcons } from "../../assets";
import { useHistory } from "react-router-dom";

type PostApplicationTableProps = {
  communityId: number;
  status: string;
};

type PostApplicationColor = {
  bgColor: string;
  textColor: string;
};

const PostApplicationTable: React.FC<PostApplicationTableProps> = ({
  communityId,
  status,
}) => {
  const navigate = useHistory();
  const { isLoading, data } = useGetCommunityUsersByStatus(communityId, status);

  const getSeverity = (ApplicationStatus: number): PostApplicationColor => {
    switch (ApplicationStatus) {
      case PostApplicationStatus.Pending:
        return { bgColor: "bg-blue-200", textColor: "text-blue-900" };
      case PostApplicationStatus.Approved:
        return { bgColor: "bg-green-200", textColor: "text-green-900" };
      case PostApplicationStatus.Rejected:
        return { bgColor: "bg-red-200", textColor: "text-red-900" };
      case PostApplicationStatus.Disabled:
        return { bgColor: "bg-gray-300", textColor: "text-gray-900" };
      case PostApplicationStatus.Blocked:
        return { bgColor: "bg-orange-400", textColor: "text-orange-900" };
      default:
        return { bgColor: "bg-blue-200", textColor: "text-blue-900" };
    }
  };

  const getStatusName = (ApplicationStatus: number) => {
    switch (ApplicationStatus) {
      case PostApplicationStatus.Pending:
        return "Pending";
      case PostApplicationStatus.Approved:
        return "Approved";
      case PostApplicationStatus.Rejected:
        return "Rejected";
      case PostApplicationStatus.Disabled:
        return "Disabled";
      case PostApplicationStatus.Blocked:
        return "Blocked";
      default:
        return "Pending";
    }
  };

  // Filters
  const [filters, setFilters] = useState<DataTableFilterMeta | undefined>(
    undefined
  );
  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    setGlobalFilterValue("");
  };

  const onGlobalFilterChange = (e: string) => {
    const _filters = { ...filters };
    // @ts-ignore
    _filters.global.value = e;

    setFilters(_filters);
    setGlobalFilterValue(e);
  };

  useEffect(() => {
    initFilters();
  }, []);

  const statusBodyTemplate = (rowData: IPostApplicationUser) => {
    const { status } = rowData;
    const color = getSeverity(status);
    const applicationName = getStatusName(status);
    return (
      <Tag className={`${color.bgColor} px-2.5 py-0.5 rounded`}>
        <p
          className={`${color.textColor} flex justify-center items-center mx-0 my-0`}
        >
          {applicationName}
        </p>
      </Tag>
    );
  };

  const navigateTo = (id: number | string) => {
    navigate.push(`/home/user-profile/${id}`);
  };

  const actionBodyTemplate = (rowData: IPostApplicationUser) => {
    return (
      <div style={{ minWidth: "50px" }}>
        <Button
          type="text"
          style={{ color: `${Colors.appMainColor}` }}
          onClick={() => {
            navigate.push(
              `/home/community-application/${rowData.applicationId}`.trim()
            );
          }}
        >
          View More
        </Button>
      </div>
    );
  };

  return (
    <div className="flex flex-col w-full h-full">
      <Spin spinning={isLoading} tip="" delay={500}>
        <div className="flex flex-col flex-1 w-full">
          <div className="flex flex-col items-end justify-end px-3 mb-3">
            <CustomInputField
              value={globalFilterValue}
              onChange={(e: string) => onGlobalFilterChange(e)}
              onPressEnter={() => {}}
              inputProps={{
                size: "large",
                placeholder: "Search",
                allowClear: true,
                className: "ml-2 w-3",
                prefix: <SearchOutlined />,
              }}
              theme={customInputTheme}
              mergeThemes
            />
          </div>
          {data && (
            <>
              <DataTable
                value={data}
                paginator
                rows={10}
                rowsPerPageOptions={[10, 25, 50, 100]}
                tableStyle={{ minWidth: "50rem" }}
                sortMode="multiple"
                emptyMessage={`No ${status} applications found.`}
                dataKey="applicationId"
                scrollable
                scrollHeight="40rem"
                filters={filters}
                globalFilterFields={["firstName", "lastName", "email"]}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} applications"
                stateStorage="session"
                stateKey={`dt-state-post-application-${status}-table`}
              >
                <Column
                  field="firstName"
                  header="First Name"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                />
                <Column
                  field="lastName"
                  header="Last Name"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                />
                <Column
                  field="status"
                  header="Status of the application"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB" }}
                  bodyStyle={tableBody}
                  body={statusBodyTemplate}
                />
                <Column
                  headerStyle={tableHeader}
                  exportable={false}
                  body={actionBodyTemplate}
                  bodyStyle={tableBody}
                  style={{ background: "#FBFBFB" }}
                />
              </DataTable>
            </>
          )}
        </div>
      </Spin>
    </div>
  );
};

export default PostApplicationTable;

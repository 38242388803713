import React, { useState } from "react";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import Colors from "@/config/Colors";
import { useGetResponderTableData } from "@/hooks/useResponderAnalytics";

interface City {
  name: string;
  code: string;
}
type CallOutIdsFilterProps = {
  onFilterChange: (name: string, value: any[]) => void;
};

const CallOutDropDown = ({ onFilterChange }: CallOutIdsFilterProps) => {
  const { data, isLoading, isFetching } = useGetResponderTableData();
  const [selectedCallOutIds, setSelectedCallOutIds] = useState<number[]>([]);

  const callOutId: number[] = data?.callOutIds || [];

  const handleChange = (e: MultiSelectChangeEvent) => {
    let newCallOutIds: number[] = [];
    setSelectedCallOutIds(e.value);
    newCallOutIds = e.value;
    onFilterChange("callOutIds", newCallOutIds);
  };

  return (
    <div className="responderAnalytics flex h-20 w-3 py-1 px-1 mr-0.5 bg-appMainColor justify-center items-center flex-shrink-0">
      <MultiSelect
        value={selectedCallOutIds}
        onChange={handleChange}
        options={callOutId}
        filter
        placeholder="Call-out ID"
        maxSelectedLabels={3}
        style={{
          backgroundColor: Colors.appMainColor,
          borderColor: Colors.appMainColor,
          color: "#fff",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    </div>
  );
};

export default CallOutDropDown;

import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  ChartOptions,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Colors from "../../../config/Colors";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend
);

export const options = {
  //   scales: {
  //     y: {
  //       beginAtZero: true,
  //     },
  //   },
  plugins: {
    legend: {
      position: "bottom" as const,
      labels: {
        usePointStyle: true,
        pointStyle: "circle",
      },
    },
  },
};

interface IChatsTrendProps {
  chatMonthly: IChartMonthlyTrend[];
}

export default function OperatorChatsTrend({ chatMonthly }: IChatsTrendProps) {
  const onSortChange = (value: any) => {
    console.log(`selected ${value}`);
  };

  const labels = chatMonthly.map((item: IChartMonthlyTrend) => item.name);
  const resolvedChats = chatMonthly.map(
    (item: IChartMonthlyTrend) => item.resolvedChats
  );
  const unresolvedChats = chatMonthly.map(
    (item: IChartMonthlyTrend) => item.unresolvedChats
  );
  const transferredChats = chatMonthly.map(
    (item: IChartMonthlyTrend) => item.transferredChats
  );

  const data = {
    labels,
    datasets: [
      {
        label: "Resolved Chats",
        data: resolvedChats,
        borderColor: "#53B175",
        backgroundColor: "#53B175",
        fill: false,
      },
      {
        label: "Unresolved Chats",
        data: unresolvedChats,
        borderColor: "#CC3300",
        fill: false,
        backgroundColor: "#CC3300",
      },
      {
        label: "Transferred Chats",
        data: transferredChats,
        borderColor: "#ED7523",
        fill: false,
        backgroundColor: "#ED7523",
      },
    ],
  };

  return (
    <div
      style={{ background: "#FFFFFF" }}
      className="shadow-5 surface-card border-round px-3"
    >
      <div className="w-12 flex flex-row justify-between items-center">
        <div
          className="pt-3"
          style={{
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "18px",
            lineHeight: "27px",
            color: `${Colors.mainTextColor}`,
          }}
        >
          Chats monthly trend
        </div>
      </div>
      <div className="pb-2">
        <Line options={options} data={data} />
      </div>
    </div>
  );
}

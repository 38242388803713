/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-var */
import { AutoComplete, Button, Form, message } from "antd";
import React, { useEffect, useState } from "react";
import { useUnlinkedCommunityCodes } from "../../../hooks/useCommunityCodes";
import { useLinkCodeToCommunity } from "../../../hooks/useCommunities";

interface SearchReferalCodeProp {
  communityId: number;
}

const SearchReferralCode: React.FC<SearchReferalCodeProp> = ({
  communityId,
}) => {
  let selectedCode = "";
  const [messageApi, contextHolder] = message.useMessage();
  const messageKey = "searchReferralCodeModal";
  const unLinkedCodes = useUnlinkedCommunityCodes();
  const linkToCommunity = useLinkCodeToCommunity();
  const [form] = Form.useForm();
  const [options, setOptions] = useState<{ value: string; label: string }[]>(
    []
  );
  const handleSearch = (value: string) => {
    const codes: IAllCommunityCode[] | undefined = unLinkedCodes.data;
    let res: { value: string; label: string }[] = [];

    if (value.length > 0 && codes) {
      res = codes
        .filter((codeItem) =>
          codeItem.code.toLocaleLowerCase().includes(value.toLocaleLowerCase())
        )
        .map((codeItem) => ({
          value: codeItem.code,
          label: codeItem.code,
        }));
    }
    setOptions(res);
  };

  function handleLinkReferralCode() {
    var referralcode: ILinkCommunity = {
      communityId: communityId,
      referralCode: selectedCode,
    };
    console.log(referralcode);
    if (referralcode.referralCode && referralcode.communityId) {
      try {
        messageApi.open({
          key: messageKey,
          type: "loading",
          content: "Loading..",
          duration: 0,
        });
        linkToCommunity.mutateAsync(referralcode);
        messageApi.open({
          key: messageKey,
          type: "success",
          content: `${referralcode.referralCode} has been Successfully Linked`,
          duration: 0,
        });
        setTimeout(() => {
          messageApi.destroy(messageKey);
        }, 2000);
      } catch (error) {
        // close Message
        messageApi.destroy(messageKey);
      }
    } else {
      message.error("Please a community code is selected.");
    }
  }

  const handleClose = () => {
    form.resetFields();
  };
  return (
    <Form
      form={form}
      name="searchReferral"
      layout="vertical"
      style={{ width: "100%" }}
      className="grid"
    >
      {contextHolder}
      <div className="col-12">
        <Form.Item label="Search Referral Code" className="mb-1">
          <div className="flex justify-start w-full">
            <AutoComplete
              style={{ width: 200 }}
              onChange={(value: string) => {
                selectedCode = value;
              }}
              onSearch={handleSearch}
              placeholder="Search Referal Code"
              options={options}
              size="large"
              className="flex w-full"
            />
          </div>
        </Form.Item>
      </div>
      <Form.Item className="w-12 mb-1">
        <div className="flex w-full gap-2 col-12 justify-content-end align-content-center">
          <Button
            type="primary"
            onClick={handleLinkReferralCode}
            size="large"
            className="w-3 bg-[#1757a2] shadow-none"
          >
            Link
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default SearchReferralCode;

/* eslint-disable class-methods-use-this */
import { API, API_2, APILocal, APIStaging } from "../config/axios";

class ControlRoomOperator {
  getControlRoomOperators = async (pageNumber: number, pageSize: number) => {
    const { data } = await API_2.get<PaginatedControlRoomOperatorsResponse>(
      `Admin_ControlRoomOperator?pageNumber=${pageNumber}&pageSize=${pageSize}`
    );
    return data;
  };

  async createOrEditControlRoomOperator(
    payload: ICreateOrEditControlRoomOperator
  ) {
    try {
      const response = await API_2.post<
        APIResult<ICreateOrEditControlRoomOperator>
      >(`/Admin_ControlRoomOperator`, payload);
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  postControlRoomOperatorChats = async (body: IControlRoomOperatorChats) => {
    const response = await API.post(
      `PanicMessage/controlRoomOperatorChats`,
      body
    );
    return response.data;
  };
}

export default new ControlRoomOperator();

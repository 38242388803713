import { useInfiniteQuery } from "@tanstack/react-query";
import React, { useEffect, useRef } from "react";
import { Col, List, Row, Spin, Tag, message } from "antd";
import VirtualList from "rc-virtual-list";
import Communities from "../../../services/Communities";

const ContainerHeight = 150;

const LinkedCommunityCodes: React.FC<{ communityId: number | null }> = ({
  communityId,
}) => {
  const isMounted = useRef(true);
  const limit = 5;
  const {
    data: QueryData,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    refetch,
  } = useInfiniteQuery(
    ["getCommunityCodes", communityId], // unique key
    ({ pageParam = 0 }) =>
      Communities.getLinkedCodes(communityId, limit, pageParam), // query function
    {
      getNextPageParam: (lastPage: ILinkedCodes, allPages) =>
        lastPage.hasMore ? allPages.length * limit : undefined, // Use the length of the last page as the next page parameter
    }
  );

  const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    if (
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
      ContainerHeight
    ) {
      if (hasNextPage) {
        fetchNextPage();
      }
    }
  };

  useEffect(() => {
    // Component mounted
    // Works

    return () => {
      // Component will unmount
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (QueryData?.pages) {
      const lastPage = QueryData.pages[QueryData.pages.length - 1];
      if (lastPage?.codes && lastPage.codes.length >= 1) {
        message.success(
          `${lastPage.codes.length} referral codes loaded successfully`
        );
      }
    }
  }, [QueryData]);

  function getRandomNumber(): number {
    return Math.floor(Math.random() * 1000) + 1;
  }

  useEffect(() => {
    if (isMounted.current) {
      refetch();
    }
  }, [communityId]);

  return (
    <div className="w-full">
      <div className="w-full">
        <Spin tip="" spinning={isFetching || isLoading}>
          <List className="w-full">
            <VirtualList
              data={QueryData?.pages.flatMap((page) => page.codes) || []}
              height={ContainerHeight}
              itemHeight={10}
              itemKey="code"
              onScroll={onScroll}
              className="w-full"
            >
              {(item: ICodes, index) => (
                <List.Item key={index} className="w-full">
                  <div
                    key={getRandomNumber()}
                    className="flex justify-between w-full"
                  >
                    <div className="w-11/12 text-lg font-bold text-current">
                      {item.code}
                    </div>
                    <div className="flex flex-wrap">
                      <Tag className="flex items-center justify-center text-appMainColor bg-secondaryColor">
                        {item.totalUsed}
                      </Tag>
                    </div>
                  </div>
                </List.Item>
              )}
            </VirtualList>
          </List>
        </Spin>
      </div>
    </div>
  );
};

export default LinkedCommunityCodes;

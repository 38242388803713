/* eslint-disable lines-between-class-members */
/* eslint-disable no-var */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
import { API, APILocal, APIStaging } from "../config/axios";

class SalesIQ {
  getById = async (userId: string | number | null): Promise<ISalesIQUser> => {
    const response = await API.get<ISalesIQUser>(
      `Admin_Users/getUserSalesIQData?userId=${userId}`
    );
    return response.data;
  };

  getAll = async (): Promise<ISalesIQTable[]> => {
    const response = await API.get<ISalesIQTable[]>(
      "Admin_Users/getSalesIQData"
    );
    return response.data;
  };
}

export default new SalesIQ();

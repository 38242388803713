/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-plusplus */

import React, { useContext, useEffect, useState } from "react";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import { SearchOutlined } from "@ant-design/icons";
import { Column } from "primereact/column";
import { DateTime } from "luxon";
import { Tag } from "antd";
import moment from "moment";
import {
  BodyContainer,
  Container,
  tableBody,
  tableHeader,
  tableName,
} from "./styles";
import HeaderComponent from "../../components/Header/Header";
import Spin from "../../components/widgets/Spinner/Spin";
import CustomInputField from "../../components/widgets/Inputs/CustomInputField";
import Colors from "../../config/Colors";
import { useOperatorHistory } from "../../hooks/useOperators";
import AuthContext from "../../contexts/AuthContext";
import { FilterMatchMode, FilterOperator } from "primereact/api";

const UserHistory = () => {
  const { user, setUser } = useContext(AuthContext);
  const [statuses] = useState<string[]>([
    "Resolved",
    "Unresolved",
    "Transferred",
  ]);

  const {
    isLoading,
    isFetching,
    data: historyData,
  } = useOperatorHistory(user?.id);
  const [operatorHistoryData, setOperatorHistoryData] = useState<
    IOperatorHistory[]
  >([]);
  const customInputTheme = {
    token: {
      colorPrimaryHover: `${Colors.appMainColor}`,
    },
  };

  const getSeverity = (status: string) => {
    switch (status) {
      case "Resolved":
        return "success";
      case "Unresolved":
        return "error";

      case "Transferred":
        return "warning";
      default:
        return "processing";
    }
  };
  const [filters, setFilters] = useState<DataTableFilterMeta | undefined>(
    undefined
  );

  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");

  const onGlobalFilterChange = (e: string) => {
    const _filters = { ...filters };
    // @ts-ignore
    _filters.global.value = e;

    setFilters(_filters);
    setGlobalFilterValue(e);
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      firstName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      lastName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      userId: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    });
    setGlobalFilterValue("");
  };

  const statusBodyTemplate = (rowData: IOperatorHistory) => {
    return <Tag color={getSeverity(rowData.status)}>{rowData.status}</Tag>;
  };

  const dateBodyTemplate = (rowData: IOperatorHistory) => {
    return moment(rowData.caseDate).format("YYYY/MM/DD");
  };

  const firstNameBodyTemplate = (rowData: IOperatorHistory) => {
    return rowData.firstName;
  };

  const lastNameBodyTemplate = (rowData: IOperatorHistory) => {
    return rowData.lastName;
  };

  const userIdBodyTemplate = (rowData: IOperatorHistory) => {
    return rowData.userId;
  };

  useEffect(() => {
    initFilters();
  }, [historyData]);

  return (
    <div className="h-full w-full">
      <Container className="w-full h-full">
        <BodyContainer className="w-full">
          <HeaderComponent title="User History" />
          <Spin tip="" spinning={isLoading || isFetching} delay={500}>
            <div className="w-full h-full px-3 card Users">
              <div className="flex flex-row gap-1 justify-end align-items-center mb-2 w-full">
                <CustomInputField
                  value={globalFilterValue}
                  onChange={(e: string) => onGlobalFilterChange(e)}
                  onPressEnter={() => {}}
                  inputProps={{
                    size: "large",
                    placeholder: "Search",
                    allowClear: true,
                    className: "ml-2 w-4",
                    prefix: <SearchOutlined />,
                  }}
                  theme={customInputTheme}
                  mergeThemes
                />
              </div>
            </div>
            <DataTable
              value={historyData}
              paginator
              rows={10}
              rowsPerPageOptions={[10, 25, 50, 100]}
              dataKey="id"
              filters={filters}
              globalFilterFields={[
                "caseDate",
                "firstName",
                "lastName",
                "userId",
                "status",
              ]}
              emptyMessage="No users found."
              rowHover
              scrollable
              scrollHeight="40rem"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Users"
              stateStorage="session"
              stateKey="dt-state-operator-history-table"
              className="mx-3"
            >
              <Column
                field="caseDate"
                header="Case Date"
                headerStyle={tableHeader}
                filterField="date"
                dataType="date"
                body={dateBodyTemplate}
                bodyStyle={tableBody}
                style={{ background: "#FBFBFB" }}
              />
              <Column
                field="firstName"
                header="First name"
                headerStyle={tableHeader}
                style={{ background: "#FBFBFB" }}
                bodyStyle={tableBody}
                body={firstNameBodyTemplate}
              />
              <Column
                field="lastName"
                header="Last name"
                headerStyle={tableHeader}
                style={{ background: "#FBFBFB" }}
                body={lastNameBodyTemplate}
                bodyStyle={tableBody}
              />
              <Column
                field="userId"
                header="User Id"
                headerStyle={tableHeader}
                style={{ background: "#FBFBFB" }}
                bodyStyle={tableBody}
                body={userIdBodyTemplate}
              />
              <Column
                field="caseStatus"
                header="Case status"
                headerStyle={tableHeader}
                style={{ background: "#FBFBFB" }}
                body={statusBodyTemplate}
                filterMenuStyle={{ width: "14rem" }}
              />
            </DataTable>
          </Spin>
        </BodyContainer>
      </Container>
    </div>
  );
};

export default UserHistory;

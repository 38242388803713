import { Layout, Menu } from "antd";
import { NavLink, useHistory } from "react-router-dom";
import "./style.css";
import React, { useContext } from "react";
import Images, { CustomIcons } from "../../assets";
import { AuthContext } from "../../contexts/AuthContext";
import { Layouts } from "../../util/enums";
import Colors from "../../config/Colors";

type SiderMainProp = {
  collapsed: boolean;
  routes: any;
  layout?: Layouts;
};

const styles = {
  sider: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    height: "100vh", // Ensure the sidebar takes up full viewport height
    overflow: "hidden", // Prevent scrolling on the sidebar itself
    backgroundColor: `${Colors.appMainColor}`,
  } as const,
  menuContainer: {
    //flex: 1,
    maxHeight: "calc(100vh - 160px)", // Adjust based on your actual header and logout button height
    overflowY: "auto", // Only allow the menu area to scroll
    backgroundColor: `${Colors.appMainColor}`,
  } as const,
  logoutButton: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    // padding: "10px 0",
    backgroundColor: `${Colors.appMainColor}`, // Adjust as needed
  } as const,
  iconFlex: { display: "flex", alignItems: "center" },
  icon: { alignItems: "center" },
  spacing: { marginLeft: 26, marginBottom: 5 },
};

const Main = ({ collapsed, routes, layout }: SiderMainProp) => {
  const [activePath, setActivePath] = React.useState("");
  const { hasRoutePermission, logout } = useContext(AuthContext);
  const history = useHistory();

  const activeRoute = (routeName: any) =>
    history.location.pathname.endsWith(routeName)
      ? setActivePath(routeName)
      : "";

  const generateMenuItems = (defaultRoutes: any[]) =>
    defaultRoutes.map((prop, index) => {
      if (prop.layout !== Layouts.Home && !prop.icon && !prop.collapse) {
        return null;
      }
      if (!hasRoutePermission(prop.platformRights)) {
        return null;
      }

      // Skip pages that should be hidden from the sidebar
      if (prop.hideFromSidebar) {
        return null;
      }
      return (
        <Menu.Item
          // style={collapsed ? styles.icon : styles.iconFlex}
          key={`/home${prop.path}`}
          className="flex justify-start px-0 py-0"
          style={{
            borderColor: "#ffffff",
          }}
        >
          <NavLink to={prop.layout + prop.path}>
            <div
              className="flex flex-row items-center justify-start w-full pl-1"
              style={{
                borderColor: "#ffffff",
              }}
            >
              <prop.pageIcon
                width={30}
                height={30}
                viewBox={null}
                svgColor="#ffffff"
              />
              <span
                style={{
                  color: "#ffffff",
                  fontWeight: "700",
                  fontSize: 13,
                  fontStyle: "normal",
                  marginLeft: 8,
                }}
              >
                {prop.name}
              </span>
            </div>
          </NavLink>
        </Menu.Item>
      );
    });

  const handleLogout = () => {
    logout();
  };

  React.useEffect(() => {
    routes.map((prop: any) => {
      if (prop.layout !== Layouts.Home && !prop.collapse) return null;

      return activeRoute(prop.path);
    });
  }, [routes, activePath, history.location.pathname]);

  return (
    <Layout.Sider
      collapsible
      collapsed={collapsed}
      trigger={null}
      // className="max-h-screen overflow-y-hidden"
      className="disable-scrollbars"
      width={200}
      style={styles.sider}
    >
      <div className="flex items-center justify-center pt-2">
        <img
          src={Images.SaferCityLogoReversed}
          style={{
            height: "50px",
            width: "50px",
            alignSelf: "center",
          }}
          alt=""
        />
      </div>
      <div className="flex items-center justify-center w-full py-3 pr-2.5">
        <div style={{ color: `#ffffff` }}>SAFER</div>
        <div style={{ color: `${Colors.secondaryColor}` }}>CITY</div>
      </div>
      {/* Scrolling menu items */}

      <Menu
        // className="max-h-screen overflow-auto siderMainMenu"
        className="disable-scrollbars"
        mode="vertical"
        defaultSelectedKeys={[history.location.pathname]}
        style={styles.menuContainer}
      >
        {/* {generateMenuItems(routes)} */}
        <div className="flex-grow overflow-y-auto menu-scroll-area">
          <Menu
            className="h-full overflow-auto siderMainMenu"
            mode="vertical"
            defaultSelectedKeys={[history.location.pathname]}
            style={{
              backgroundColor: `${Colors.appMainColor}`,
            }}
          >
            {generateMenuItems(routes)}
          </Menu>
        </div>
        {/* <Menu.Item
          key="logout"
          style={collapsed ? styles.icon : styles.iconFlex}
          className="sidermain-logout"
        >
          <button type="button" onClick={handleLogout}>
            <div className="flex flex-row w-full text-white align-items-center justify-content-start">
              <CustomIcons.LogOutIcon
                width={30}
                height={30}
                viewBox={null}
                svgColor="#ffffff"
              />
              <span
                style={{
                  color: `#ffffff`,
                  fontWeight: "700",
                  fontSize: 13,
                  fontStyle: "normal",
                  marginLeft: 8,
                }}
              >
                Logout
              </span>
            </div>
          </button>
        </Menu.Item> */}
      </Menu>
      <div style={styles.logoutButton}>
        <Menu
          mode="vertical"
          style={{
            backgroundColor: `${Colors.appMainColor}`,
            borderRight: "none",
            width: "100%",
          }}
        >
          <Menu.Item key="logout" className="flex justify-start px-0 py-0">
            <button type="button" onClick={handleLogout}>
              <div className="flex flex-row w-full pl-1 text-white align-items-center justify-content-start">
                <CustomIcons.LogOutIcon
                  width={30}
                  height={30}
                  viewBox={null}
                  svgColor="#ffffff"
                />
                <span
                  style={{
                    color: `#ffffff`,
                    fontWeight: "700",
                    fontSize: 13,
                    fontStyle: "normal",
                    marginLeft: 8,
                  }}
                >
                  Logout
                </span>
              </div>
            </button>
          </Menu.Item>
        </Menu>
      </div>
    </Layout.Sider>
  );
};

export default Main;

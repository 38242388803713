/* eslint-disable no-unused-vars */
import React from "react";
import { Button } from "antd";
import {
  cardStyles,
  imageStyles,
  titleStyles,
  contentStyles,
  subheaderStyles,
  subtitleStyles,
  btnStyles,
} from "./styles";
import { CustomIcons } from "../../../assets";
import { EditAnimationIcon, SettingAnimationIcon } from "../../Icons";
import Colors from "../../../config/Colors";
import TruncateTextBlock from "../../widgets/common/TruncateTextBlock";

type cardProps = {
  title: string;
  subtitle: string;
  imgSrc: string;
  editCard: () => void;
  openSettings: () => void;
};

// eslint-disable-next-line import/prefer-default-export
const CommunityCard = ({
  title,
  subtitle,
  imgSrc,
  editCard,
  openSettings,
}: cardProps) => (
  <div style={cardStyles} className="min-w-24">
    <div className="flex flex-row items-center justify-between w-full">
      <div style={imageStyles}>
        <img
          src={imgSrc}
          alt=""
          width={25}
          height={25}
          style={{ objectFit: "contain" }}
        />
      </div>

      <h2 style={titleStyles}>{title}</h2>
      <div style={{ paddingBottom: "12px" }}>
        <Button
          type="text"
          icon={<EditAnimationIcon style={{ color: Colors.appMainColor }} />}
          style={{ color: `${Colors.appMainColor}` }}
          onClick={editCard}
        />
      </div>
    </div>
    <div style={contentStyles} className="py-2">
      <div style={subheaderStyles}>
        <TruncateTextBlock style={subtitleStyles} limit={50}>
          {subtitle}
        </TruncateTextBlock>
      </div>
    </div>
    <button style={btnStyles} type="button" onClick={openSettings}>
      <div className="flex flex-row items-center gap-2">
        <SettingAnimationIcon />
        <div>Community Settings</div>
      </div>
    </button>
  </div>
);

export default CommunityCard;

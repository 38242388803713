import React from "react";
import { Modal, Button, message } from "antd";
import {
  successTitle,
  successSubtitle,
  modelTitle,
} from "../../styles/ModalStyles";
import { CustomIcons } from "../../assets/index";
import NewCommunityForm from "../Forms/NewCommunity";
import { useAddCommunity } from "../../hooks/useCommunities";

interface NewCommunityModalProp {
  openNewModal: boolean;
  setOpenNewModal: (open: boolean) => void;
}

const NewCommunityModal = ({
  openNewModal,
  setOpenNewModal,
}: NewCommunityModalProp) => {
  const postCommunity = useAddCommunity();
  const [messageApi, contextHolder] = message.useMessage();
  const messageKey = "createNewCommunityModal";
  const handleClose = async () => {
    postCommunity.reset();
    setOpenNewModal(false);
  };
  const handleAddCommunity = async (community: IAddCommunity) => {
    try {
      messageApi.open({
        key: messageKey,
        type: "loading",
        content: "Creating Community..",
        duration: 0,
      });
      await postCommunity.mutateAsync(community);
      messageApi.open({
        key: messageKey,
        type: "success",
        content: "Created Community..",
        duration: 0,
      });

      setTimeout(() => {
        messageApi.destroy(messageKey);
      }, 2000);
    } catch (error) {
      // close Message
      messageApi.destroy(messageKey);
    }
  };

  return (
    <Modal
      centered
      open={openNewModal}
      onOk={() => setOpenNewModal(false)}
      onCancel={() => handleClose()}
      width={550}
      closeIcon={
        <CustomIcons.CloseIcon
          width={30}
          height={30}
          viewBox={null}
          svgColor="#1C274C"
        />
      }
      footer={null}
    >
      {contextHolder}
      <div className="w-full pt-2 mx-2">
        {postCommunity.isSuccess ? (
          <div className="flex w-full h-full gap-2 flex-column align-items-center justify-content-center">
            <div className="flex w-full justify-content-center">
              <CustomIcons.SuccessIcon
                width={100}
                height={100}
                viewBox={null}
                svgColor="#179717"
              />
            </div>
            <div style={successTitle}>Successful</div>
            <div style={successSubtitle} className="pb-4">
              {" "}
              You have successfully added a new community
            </div>
            <Button
              type="primary"
              ghost
              onClick={handleClose}
              size="large"
              className="w-4"
            >
              close
            </Button>
          </div>
        ) : (
          <>
            <div
              className="flex w-12 py-3 justify-content-start"
              style={modelTitle}
            >
              Create new community
            </div>

            <NewCommunityForm
              onFormFinish={(community: IAddCommunity) =>
                handleAddCommunity(community)
              }
              onModelClose={() => {
                handleClose();
              }}
              isLoading={postCommunity.isLoading}
              isSuccess={postCommunity.isSuccess}
            />
          </>
        )}
      </div>
    </Modal>
  );
};

export default NewCommunityModal;

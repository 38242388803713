import { useEffect, useState } from "react";
/* eslint-disable no-unused-vars */
import { Button, Input } from "antd";
import MultipleSelector, { Option } from "@/components/ui/multiple-selector";
import Crimes from "@/services/Crimes";
import { CustomIcons } from "@/assets";
import {
  useGetCategoryAndTypes,
} from "../../hooks/useCrimes";

const AddNewCrimeType = ({}) => {
  const [selectedCategoryOption, setSelectedCategoryOption] = useState<Option | null>(null);
  const [crimeTypeInput, setCrimeTypeInput] = useState<string>("");
  const [modalOpen, setModalOpen] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalTypes, setTotalTypes] = useState<number>(0);

  const {
    data: categoryData,
    isLoading: isLoadingTotalCrimeTypes,
    refetch,
  } = useGetCategoryAndTypes();

  const [categoryOptions, setCategoryOptions] = useState<Option[]>([]);

  useEffect(() => {
    if (categoryData && categoryData.data) {
      setCategoryOptions(
        categoryData.data.map((cat: ICrimeCategory) => ({
          label: cat.category,
          value: cat.category,
          disable: false,
          fixed: true,
        }))
      );
      setTotalTypes(
        categoryData.data.reduce((acc: number, cat: ICrimeCategory) => acc + cat.labels.length, 0)
      );
    } else {
      setCategoryOptions([]);
      setTotalTypes(0);
    }
  }, [categoryData]);

  const handleCategoryOptionChange = (options: Option[]) => {
    setSelectedCategoryOption(options[0]);
  };

  const handleCrimeTypeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCrimeTypeInput(e.target.value);
  };

  const handleCloseSuccessModal = async () => {
    setShowSuccessModal(false);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const postData: INewCateGoryData = {
      categoryName: selectedCategoryOption?.value,
      typeName: crimeTypeInput,
    };

    const submitResponse = await Crimes.submitCrimeCategoryAndType(postData);

    if (submitResponse?.data) {
      setModalOpen(false);
      setShowSuccessModal(true);
      setIsLoading(false);
      refetch();
    } else {
      alert("Failed to add new crime type. Please try again.");
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="flex justify-start text-lg font-bold text-mainTextColor">
        Crime types
      </div>
      <div className="flex justify-start text-5xl font-bold text-appMainColor">
        {totalTypes}
      </div>
      <div className="flex justify-start text-lg font-normal underline">
        <button onClick={() => setModalOpen(true)}>Add new crime type</button>
      </div>

      {modalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl w-full max-w-lg">
            <h2 className="text-xl font-bold mb-2 text-left">
              Add new crime type
            </h2>
            <p className="text-sm text-gray-600 mb-6 text-left">
              You can add new crime types that users will select when reporting
              a crime. You can select a category to add a crime type to, to add a new category type the name and hit enter.
            </p>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 text-left">
                  Crime category
                </label>
                <MultipleSelector
                  value={selectedCategoryOption ? [selectedCategoryOption] : []}
                  onChange={handleCategoryOptionChange}
                  defaultOptions={categoryOptions}
                  hidePlaceholderWhenSelected={true}
                  creatable={true}
                  maxSelected={1}
                  placeholder="Enter or select a crime category"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 text-left">
                  Crime Type
                </label>
                <Input
                  value={crimeTypeInput}
                  onChange={handleCrimeTypeInputChange}
                  placeholder="Enter crime type"
                  className="w-full"
                />
              </div>
              <div className="flex justify-start space-x-2">
                <Button
                  size="large"
                  type="primary"
                  ghost
                  onClick={() => {
                    setModalOpen(false);
                    setCrimeTypeInput("");
                    setSelectedCategoryOption(null);
                    setShowSuccessModal(false);
                  }}
                  className="mt-4 bg-saferCity-primary"
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  size="large"
                  onClick={handleSubmit}
                  className="mt-4 bg-saferCity-primary"
                  disabled={!selectedCategoryOption?.value || crimeTypeInput.length === 0}
                >
                  Add new crime type
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showSuccessModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
            <div className="flex justify-center items-center w-full pl-5">
              <CustomIcons.SuccessIcon
                width={100}
                height={100}
                viewBox="0 0 24 24"
                svgColor="#179717"
              />
            </div>
            <div className="text-lg font-semibold text-center mt-4">
              Successful
            </div>
            <div className="text-sm text-center text-gray-600 pb-4">
              New Crime type has been added.
            </div>
            <button
              onClick={handleCloseSuccessModal}
              className="w-full bg-backgroundColor hover:bg-blue-700 hover:text-white text-blue-500 font-bold py-2 px-4 rounded border border-appMainColor"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddNewCrimeType;

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useModal } from "@/hooks/useModalStore";
import {
  Form,
  Select,
  Button,
  Tag,
  Input,
  DatePicker,
  Avatar,
  Upload,
} from "antd";
import { toast } from "sonner";
import moment from "moment";
import MomentAntdDateTimePicker from "@/components/widgets/DateTimePicker";
import { UploadFile, RcFile, UploadProps } from "antd/es/upload/interface";
import DaggerCard from "@/components/Cards/DaggerCard/DaggerCard";
import { baseUploadProps } from "@/util/helpers";
import {
  useCreateResponderVehicle,
  useUpdateResponderVehicle,
} from "@/hooks/useResponderVehicles";
import SharedModalHeader from "../widgets/Headers/SharedModalHeader";
import { copyObjects } from "@/util/copyObjects";

const { Option } = Select;
const { Dragger } = Upload;

const dateFormat = "YYYY";

function ResponderVehicleModal() {
  const updateResponderVehicleMutation = useUpdateResponderVehicle();
  const createResponderVehicleMutation = useCreateResponderVehicle();
  const { isOpen, onClose, type, data } = useModal();
  const [form] = Form.useForm();
  const [image, setImage] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isUploading, setIsUploading] = useState(false);

  const isModalOpen =
    isOpen &&
    (type === "createResponderVehicle" || type === "editResponderVehicle");
  const isEdit = type === "editResponderVehicle";
  const editDetails = data?.editResponderVehicle;
  const activeCompanyId = data.activeResponderCompanyId;

  // Modal helpers

  const handleReset = () => {
    form.resetFields();
    setImage("");
    setFileList([]);
    setIsUploading(false);
  };

  useEffect(() => {
    if (isModalOpen) {
      if (isEdit && editDetails) {
        const date = moment().year(editDetails.vehicleYear).startOf("year");
        form.setFieldsValue({
          dateOfBirth: date,
        });
        form.setFieldsValue({ ...editDetails, vehicleYear: date });

        if (editDetails.vehicleImage) {
          setImage(editDetails.vehicleImage);
          const initialFileList = [
            {
              uid: "-1", // A temporary UID for the initial file
              name: "image", // Extract or set a meaningful name if possible
              status: "done",
              url: editDetails.vehicleImage, // The URL or path of the uploaded file
            },
          ] as const;
          setFileList([...initialFileList]);
          form.setFieldsValue({ vehicleImage: editDetails.vehicleImage });
        }
      } else {
        handleReset();
      }
    }
  }, [isModalOpen, isEdit, editDetails, form]);

  const handleClose = async () => {
    handleReset();
    onClose();
  };

  // UI Functions
  const beforeUpload = (file: RcFile) => {
    const isJpegOrPngOrGif = ["image/jpeg", "image/png", "image/gif"].includes(
      file.type
    );
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isJpegOrPngOrGif) {
      toast.error("You can only upload JPG/PNG/GIF file!");
      return false; // This prevents the file from being added to the list
    }
    if (!isLt10M) {
      toast.error("Image must smaller than 10MB!");
      return false; // This prevents the file from being added to the list
    }
    return isJpegOrPngOrGif && isLt10M;
  };

  const iconUploadProps: UploadProps = {
    ...baseUploadProps,
    onChange(info) {
      const lastSuccessFulUpload = fileList;
      const currentFileList = [...info.fileList];
      setFileList(currentFileList);
      const { status } = info.file;
      if (status === "uploading") {
        setIsUploading(true);
      } else if (status === "done") {
        setIsUploading(false);
        form.setFieldsValue({ companyImage: info.file.response.path });
        setImage(info.file.response.path);
        setFileList(currentFileList);
        toast.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        setIsUploading(false);
        toast.error(`${info.file.name} file upload failed.`);
        // Revert to the last successful upload if the current upload fails
        if (lastSuccessFulUpload) {
          setFileList(lastSuccessFulUpload);
        } else {
          setFileList([]); // If there was no successful upload before, clear the fileList
        }
      }
    },
    fileList,
  };

  // Async function
  const handleEditCompany = async (formValues: any) => {
    const values: IEditResponderVehicle = {
      ...formValues,
      id: editDetails?.id,
      responderCompanyId: activeCompanyId,
    };
    try {
      await updateResponderVehicleMutation.mutateAsync(values);
      toast.success("Vehicle updated successfully");
      handleClose();
    } catch (error) {}
  };

  const handleCreateCompany = async (formValues: any) => {
    const values: IEditResponderVehicle = {
      ...formValues,
      id: 0,
      responderCompanyId: activeCompanyId,
    };
    try {
      await createResponderVehicleMutation.mutateAsync(values);
      toast.success("Vehicle created successfully");
      handleClose();
    } catch (error) {}
  };
  const onFinish = async (formValues: any) => {
    const year: number = formValues.vehicleYear.year();
    const values = copyObjects(formValues, {
      vehicleYear: year,
      vehicleImage: image ? image : null,
    });
    if (isEdit) {
      await handleEditCompany(values);
    } else {
      await handleCreateCompany(values);
    }
  };
  return (
    <SharedModalHeader
      title={isEdit ? "Edit Vehicle" : "+Add New Vehicle"}
      open={isModalOpen}
      onClose={handleClose}
      width={550}
    >
      <Form
        form={form}
        name="responderVehicle"
        onFinish={onFinish}
        layout="vertical"
        className="grid w-full"
      >
        <div className="col-12 md:col-6">
          <Form.Item
            name="vehicleMake"
            label="Vehicle Make"
            rules={[
              {
                required: true,
                message: "Please input the vehicle make!",
              },
            ]}
            className="mb-1"
          >
            <Input
              size="large"
              placeholder="Mercedes"
              count={{
                show: true,
                max: 15,
                strategy: (txt) => txt.length,
                exceedFormatter: (txt, { max }) => txt.slice(0, max),
              }}
            />
          </Form.Item>
        </div>

        <div className="col-12 md:col-6">
          <Form.Item
            name="vehicleModel"
            label="Vehicle Model"
            rules={[
              { required: true, message: "Please input the vehicle model!" },
            ]}
            className="mb-1"
          >
            <Input
              size="large"
              placeholder="Corolla"
              count={{
                show: true,
                max: 25,
                strategy: (txt) => txt.length,
                exceedFormatter: (txt, { max }) => txt.slice(0, max),
              }}
            />
          </Form.Item>
        </div>
        <div className="col-12 md:col-6">
          <Form.Item
            name="vehicleColour"
            label="Vehicle Colour"
            rules={[
              {
                required: true,
                message: "Please enter the color!",
              },
            ]}
            className="mb-1"
          >
            <Input size="large" placeholder="Black" />
          </Form.Item>
        </div>

        <div className="col-12 md:col-6">
          <Form.Item
            name="registrationPlate"
            label="Registration Plate"
            rules={[
              {
                required: true,
                message: "Please input the registration plate!",
              },
            ]}
            className="mb-1"
          >
            <Input
              size="large"
              placeholder="ACC 001 GP"
              count={{
                show: true,
                max: 10,
                strategy: (txt) => txt.length,
                exceedFormatter: (txt, { max }) => txt.slice(0, max),
              }}
            />
          </Form.Item>
        </div>
        <div className="col-12">
          <Form.Item
            name="vehicleYear"
            label="Vehicle Year"
            rules={[
              {
                required: true,
                message: "Please enter the year of the vehicle!",
              },
            ]}
            className="mb-3"
          >
            <MomentAntdDateTimePicker
              size="large"
              style={{ width: "100%" }}
              picker="year"
            />
          </Form.Item>
        </div>
        {isEdit && (
          <div className="col-12">
            <Form.Item
              name="status"
              label="Status"
              rules={[
                {
                  required: true,
                  message: "Please enter the status of the vehicle!",
                },
              ]}
              className="mb-3"
            >
              <Select placeholder="Select vehicle's status" size="large">
                <Option value="Active">
                  <Tag color="green">Active</Tag>
                </Option>
                <Option value="InActive">
                  <Tag color="red">InActive</Tag>
                </Option>
              </Select>
            </Form.Item>
          </div>
        )}
        <Form.Item name="vehicleImage" label="Icon" className="mb-5 col-12">
          <div className="flex w-full gap-6 flx-row justify-content-start">
            {image.length > 0 ? (
              <Avatar shape="square" size={64} alt="vehicle" src={image} />
            ) : (
              <Avatar shape="square" size={64} alt="vehicle" />
            )}
            <Dragger
              {...iconUploadProps}
              beforeUpload={beforeUpload}
              className="w-full"
              style={{ background: "transparent" }}
              maxCount={1}
              fileList={fileList} // Use the local fileList state
              accept=".jpg,.jpeg,.png" // This allows only .jpg, .jpeg, and .png files
            >
              <DaggerCard />
            </Dragger>
          </div>
        </Form.Item>
        <Form.Item className="w-12 pt-2 mb-1">
          <div className="flex w-full gap-2 col-12 justify-content-end align-content-center">
            <Button
              type="primary"
              ghost
              onClick={handleClose}
              size="large"
              className="w-3"
            >
              Cancel
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="w-3 bg-[#1757a2] shadow-none"
              disabled={isUploading} // Disable the button while uploading
              loading={
                updateResponderVehicleMutation.isLoading ||
                createResponderVehicleMutation.isLoading
              }
            >
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </SharedModalHeader>
  );
}

export default ResponderVehicleModal;

import React, { useEffect, useState } from "react";
import {
  useAllCommunityCodes,
  useGetAllCommunityCodeByCode,
  useGetProvinces,
} from "@/hooks/useCommunityCodes";
import { Button, Form, Input, Select } from "antd";
import MultipleSelector, { Option } from "@/components/ui/multiple-selector";
import { useQueryClient } from "@tanstack/react-query";
import { queries } from "@/services/queries";
import { set } from "lodash";

type EditCommunityCodeProps = {
  countriesData: ICountry[];
  onFormFinish: (code: IUpdateCommuntiyCode) => void;
  onModelClose: () => void;
  isLoading: boolean;
  isSuccess: boolean;
};

const EditCommunityCodes = ({
  countriesData,
  onFormFinish,
  onModelClose,
  isLoading,
  isSuccess,
}: EditCommunityCodeProps) => {
  const queryClient = useQueryClient();
  const [provinceId, setProvinceId] = useState<number | null>(null);
  const [form] = Form.useForm();
  const [code, setCode] = useState<string>("");

  const { data: codeData, refetch } = useGetAllCommunityCodeByCode(code);
  const [countryId, setCountryId] = useState<number | null>(null);

  const {
    data: allCodes,
    isLoading: isLoadingAllCodes,
    isFetching: isFetchingAllCodes,
  } = useAllCommunityCodes();

  const {
    data: provinces,
    refetch: refetchProvinces,
    isRefetching: isInitialFetchingProvinces,
  } = useGetProvinces(countryId);

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      onModelClose();
      refetch();
    }
  }, [isSuccess]);

  const provinceOptions: { value: number; label: string }[] | undefined =
    provinces?.map((p) => ({
      value: p.id,
      label: p.name,
    }));

  const codes: { value: string; label: string }[] | undefined = allCodes?.map(
    (code) => ({
      value: code.code,
      label: code.code,
    })
  );

  const countryOptions: { value: number; label: string }[] | undefined =
    countriesData?.map((c) => ({
      value: c.id,
      label: c.name,
    }));

  const handleSelectedCode = (code: string) => {
    setCode(code);
    // Default values everytime on code change
    setCountryId(codeData?.countryId as number);
    setProvinceId(codeData?.provinceId as number);
  };

  useEffect(() => {
    if (isSuccess) {
      form.resetFields();
      setCode("");
    }
  }, [isSuccess]);

  const handleClose = () => {
    form.resetFields();
    setCode("");
    onModelClose();
  };

  const handleProvinces = async (countryId: number) => {
    setCountryId(countryId);
    form.setFieldsValue({ Province: "" });
    // queryClient.invalidateQueries([
    //   queries.communityCodes.getProvinces(countryId).queryKey,
    // ]);
  };

  const onFinish = async (formValues: any) => {
    const values: IUpdateCommuntiyCode = {
      code: formValues.code,
      provinceId:
        provinceId && provinceId > 0
          ? provinceId
          : (codeData?.provinceId as number),
      countryId:
        !!countryId && countryId > 0
          ? countryId
          : (codeData?.countryId as number),
    };

    onFormFinish(values);
  };

  useEffect(() => {
    if (countryId) {
      refetchProvinces();
    }
  }, [countryId]);

  useEffect(() => {
    if (codeData && countryId === null) {
      queryClient.setQueryData(
        queries.communityCodes.getProvinces(codeData.countryId).queryKey,
        codeData.country.provinces
      );
      setCountryId(codeData.countryId);
      refetchProvinces();
    }
  }, [codeData]);

  return (
    <Form
      name="newCommunityCodeForm"
      layout="vertical"
      className="grid"
      form={form}
      onFinish={onFinish}
    >
      <div className="col-12">
        <Form.Item
          name="code"
          label="Search for community code"
          rules={[{ required: true, message: "Please enter community code!" }]}
          className="mb-1"
        >
          <Select
            showSearch
            placeholder="Search for community code"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            onChange={(value) => handleSelectedCode(value)}
            options={codes}
          />
        </Form.Item>
      </div>

      {code && (
        <>
          <div className="col-12 md:col-6">
            <Form.Item
              label="Country"
              rules={[
                { required: true, message: "Please enter community code!" },
              ]}
              className="mb-1"
            >
              <Select
                showSearch
                placeholder="Select a country"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={(value) => handleProvinces(value)}
                options={countryOptions}
                value={
                  countryId && countryId > 0 ? countryId : codeData?.countryId
                }
              />
            </Form.Item>
          </div>
          <div className="col-12 md:col-6">
            <Form.Item
              label="Province"
              rules={[
                { required: true, message: "Please enter community code!" },
              ]}
              className="mb-1"
            >
              <Select
                showSearch
                placeholder="Select a province"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                onChange={(value) => setProvinceId(value)}
                options={provinceOptions}
                value={
                  provinceId && provinceId > 0
                    ? provinceId
                    : codeData?.provinceId
                }
                loading={isInitialFetchingProvinces}
                disabled={isInitialFetchingProvinces}
              />
            </Form.Item>
          </div>
          <Form.Item className="w-12 mb-1">
            <div className="flex w-full gap-2 col-12 justify-content-end align-content-center">
              <Button
                type="primary"
                ghost
                onClick={handleClose}
                size="large"
                className="w-3"
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                size="large"
                className="w-3 bg-[#1757a2] shadow-none"
              >
                Save
              </Button>
            </div>
          </Form.Item>
        </>
      )}
    </Form>
  );
};

export default EditCommunityCodes;

/* eslint-disable lines-between-class-members */
/* eslint-disable no-var */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
import { API, APILocal, APIStaging } from "../config/axios";

class Communities {
  getAll = async (): Promise<ICommunity[]> => {
    const url = `Admin_Community/GetCommunities`;
    const response = await API.get<ICommunity[]>(url);
    return response.data;
  };

  getPaginatedCommunitiesCardsData = async (
    pageNumber: string | number | null,
    pageSize: number | string | null = 6,
    name: string | null
  ): Promise<IPaginatedCommunityResponse> => {
    const url = `Admin_Community/GetPaginatedCommunities?pageNumber=${pageNumber}&pageSize=${pageSize}&name=${name}`;
    const response = await API.get<IPaginatedCommunityResponse>(url);
    return response.data;
  };
  getCommunityFeed = async (
    communityId: number | null,
    limit: number | null | undefined = 10,
    page: number | null | undefined = 0,
    sortBy: string
  ): Promise<ICommunityFeedResponse> => {
    let url = `Admin_Feed/GetCommunityPosts?communityId`;
    if (communityId) {
      url = `${url}=${communityId}`;
      const params = {
        limit,
        page,
        sortBy,
      };
      const response = await API.get<ICommunityFeedResponse>(url, {
        params,
      });
      return response.data;
    }
    return Promise.reject<ICommunityFeedResponse>("Community id is required");
  };

  getSaferCityPosts = async (
    limit: number | null | undefined = 10,
    page: number | null | undefined = 0,
    sortBy: string
  ): Promise<ICommunityFeedResponse> => {
    let url = `Admin_Feed/GetSaferCityPosts`;
    const params = {
      limit,
      page,
      sortBy,
    };
    const response = await API.get<ICommunityFeedResponse>(url, {
      params,
    });

    return response.data;
  };

  getPostByPostId = async (
    postId: number | null | string | undefined
  ): Promise<ICommunityFeed> => {
    let url = `Admin_Feed/GetPostById?postId`;
    if (postId) {
      url = `${url}=${postId}`;
      const response = await API.get<ICommunityFeed>(url);
      return response.data;
    }
    return Promise.reject<ICommunityFeed>("Post id is required");
  };

  getSaferCityPostByPostId = async (
    postId: number | null | string | undefined
  ): Promise<ICommunityFeed> => {
    let url = `Admin_Feed/GetSaferCityPostById?postId`;
    if (postId) {
      url = `${url}=${postId}`;
      const response = await API.get<ICommunityFeed>(url);
      return response.data;
    }
    return Promise.reject<ICommunityFeed>("Post id is required");
  };

  getPostComments = async (
    postId: string | number | null,
    limit: number | null | undefined = 10,
    page: number | null | undefined = 0,
    sortBy: string = "recent"
  ): Promise<ICommentsResponse> => {
    let url = `Admin_Feed/GetCommentsByPostId?postId`;
    if (postId) {
      url = `${url}=${postId}`;
      const params = {
        limit,
        page,
        sortBy,
      };
      const response = await API.get<ICommentsResponse>(url, {
        params,
      });
      return response.data;
    }
    return Promise.reject<ICommentsResponse>("Post id is required");
  };

  getSaferCityPostComments = async (
    postId: string | number | null,
    limit: number | null | undefined = 10,
    page: number | null | undefined = 0,
    sortBy: string = "recent"
  ): Promise<ICommentsResponse> => {
    let url = `Admin_Feed/GetSaferCityCommentsByPostId?postId`;
    if (postId) {
      url = `${url}=${postId}`;
      const params = {
        limit,
        page,
        sortBy,
      };
      const response = await API.get<ICommentsResponse>(url, {
        params,
      });
      return response.data;
    }
    return Promise.reject<ICommentsResponse>("Post id is required");
  };

  getCommunityOperators = async (
    communityId: number | null | undefined,
    limit: number | null | undefined = 10,
    page: number | null | undefined = 0
  ): Promise<ICommunityOperatorsResponse> => {
    let url = `Admin_Community/GetOperatorsForCommunity`;
    // let url = `Admin_Community/SimulateGetOperatorsForCommunity`;
    if (communityId) {
      url = `${url}/${communityId}`;
      const params = {
        limit,
        page,
      };
      const response = await API.get<ICommunityOperatorsResponse>(url, {
        params,
      });
      return response.data;
    }

    return Promise.reject<ICommunityOperatorsResponse>(
      "Community id is required"
    );
  };

  addCommunity = async (body: IAddCommunity) => {
    return API.post("Admin_Community/CreateCommunity", body);
  };

  updateCommunity = async (body: IEditCommunity) => {
    return API.put(`Admin_Community/UpdateCommunity/${body.id}`, body);
  };

  deletePost = async (id: number): Promise<APIResponse<ICommunityFeed>> => {
    return API.delete(`Admin_Feed/${id}`);
  };
  linkCodeToCommunity = async (body: ILinkCommunity) => {
    return API.post("Admin_Community/linkCodeToCommunity", body);
  };

  postToCommunity = async (body: IPostContent) => {
    return API.post("Admin_Feed/AdminCommunityPost", body);
  };

  postReaction = async (body: ICreatePostReaction): Promise<IPostReaction> => {
    return API.post("Admin_Feed/PostReaction", body);
  };

  postComment = async (body: IPostComment): Promise<APIResponse<IComment>> => {
    return API.post("Admin_Feed/PostComment", body);
  };

  getLinkedCodes = async (
    communityId: number | null | undefined,
    limit: number | null | undefined = 10,
    page: number | null | undefined = 0
  ): Promise<ILinkedCodes> => {
    let url = `Admin_Community/getReferralCodes`;
    if (communityId) {
      url = `${url}/${communityId}`;
      const params = {
        limit,
        page,
      };
      const response = await API.get<ILinkedCodes>(url, {
        params,
      });
      return response.data;
    }

    return Promise.reject<ILinkedCodes>("Community id is required");
  };

  getReportedPosts = async (
    communityId: number | null,
    limit: number | null | undefined = 10,
    page: number | null | undefined = 0
  ): Promise<IReportedPostResponse> => {
    let url = `Admin_Feed/GetReportedPostsByCommunityId?communityId`;
    if (communityId) {
      url = `${url}=${communityId}`;
      const params = {
        limit,
        page,
      };
      const response = await API.get<IReportedPostResponse>(url, {
        params,
      });
      return response.data;
    }
    return Promise.reject<IReportedPostResponse>("Community id is required");
  };

  getReportedPostDetails = async (
    postId: number | null
  ): Promise<ICommunityFeed> => {
    let url = `Admin_Feed/GetReportedPostDetails?postId`;
    if (postId) {
      url = `${url}=${postId}`;
      const response = await API.get<ICommunityFeed>(url);
      return response.data;
    }
    return Promise.reject<ICommunityFeed>("Post Id is required");
  };
  getCommunityAdminCases = async (
    communityAdminId: number | null | undefined | string
  ): Promise<ICommunityAdminCase[]> => {
    let url = `Admin_CommunityCase/GetCommunityAdminCases?communityAdminId=`;
    if (communityAdminId) {
      url = `${url}${communityAdminId}`;
      const response = await API.get<ICommunityAdminCase[]>(url);
      return response.data;
    }

    return Promise.reject<ICommunityAdminCase[]>("Id is required");
  };
}

export default new Communities();

/* eslint-disable camelcase */
import { CSSProperties } from "react";
import styled from "styled-components";
import Colors from "../config/Colors";

export const WINDOW_WIDTH = window.innerWidth;
export const WINDOW_HEIGHT = window.innerHeight;

export const tableHeader: CSSProperties = {
  color: `${Colors.mainTextColor}`,
  fontWeight: 600,
  fontSize: 18,
  fontStyle: "normal",
  background: "#F1F0F0",
};

export const topResponderTableHeader: CSSProperties = {
  color: `${Colors.mainTextColor}`,
  fontWeight: 600,
  fontSize: 14,
  fontStyle: "normal",
  background: "#F1F0F0",
};

export const responderTableHeader: CSSProperties = {
  color: `#fff`,
  fontWeight: 600,
  fontSize: 18,
  fontStyle: "normal",
  background: Colors.appMainColor,
};

export const TableHeader_2: CSSProperties = {
  color: `${Colors.subtleTextColor}`,
  fontWeight: 500,
  fontSize: 12,
  fontStyle: "normal",
};

export const tableName: CSSProperties = {
  color: `${Colors.mainTextColor}`,
  fontWeight: 600,
  fontSize: 24,
  fontStyle: "normal",
};

export const topResponderTableName: CSSProperties = {
  color: `${Colors.mainTextColor}`,
  fontWeight: 600,
  fontSize: 18,
  fontStyle: "normal",
};

export const tableBody: CSSProperties = {
  color: `${Colors.mainTextColor}`,
  fontWeight: 400,
  fontSize: 18,
  fontStyle: "normal",
};

export const topResponderTableBody: CSSProperties = {
  color: `${Colors.mainTextColor}`,
  fontWeight: 400,
  fontSize: 14,
  fontStyle: "normal",
};

export const tableBody_2: CSSProperties = {
  color: `${Colors.mainTextColor}`,
  fontWeight: 500,
  fontSize: 12,
  fontStyle: "normal",
};

export const tableBodyRight: CSSProperties = {
  color: `${Colors.mainTextColor}`,
  fontWeight: 400,
  fontSize: 18,
  fontStyle: "normal",
  textAlign: "right",
};

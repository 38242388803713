/* eslint-disable no-console */
import React, { useEffect, useState } from "react";
import { FileUp } from "lucide-react";
import { useModal } from "@/hooks/useModalStore";
import { Upload, Form, Avatar, Button, Input, Modal } from "antd";
import { UploadFile, RcFile, UploadProps } from "antd/es/upload/interface";
import { toast } from "sonner";
import { uploadUrl } from "@/config/urls";
import Colors from "@/config/Colors";
import DaggerCard from "@/components/Cards/DaggerCard/DaggerCard";
import { CustomIcons } from "@/assets/index";
import { modelTitle } from "@/styles/ModalStyles";
import { copyObjects } from "@/util/copyObjects";
import {
  useUpdateResponderCompany,
  useCreateResponderCompany,
} from "@/hooks/useResponderCompany";

const { Dragger } = Upload;

function ResponderCompanyModal() {
  const { isOpen, onClose, type, data } = useModal();
  const createCompanyMutation = useCreateResponderCompany();
  const updateCompanyMutation = useUpdateResponderCompany();
  const [form] = Form.useForm();
  const [imageUrl, setImageUrl] = useState("");
  const [certificateUrl, setCertificateUrl] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [certificateFileList, setCertificateFileList] = useState<UploadFile[]>(
    []
  );
  const [isUploading, setIsUploading] = useState(false);

  const isModalOpen =
    isOpen &&
    (type === "createResponderCompany" || type === "editResponderCompany");
  const isEdit = type === "editResponderCompany";
  const editDetails = data?.responderCompany;

  const handleReset = () => {
    form.resetFields();
    setImageUrl("");
    setCertificateUrl("");
    setFileList([]);
    setCertificateFileList([]);
  };

  // Use useEffect to set imageUrl and certificateUrl if editing
  useEffect(() => {
    if (isModalOpen) {
      if (isEdit && editDetails) {
        form.setFieldsValue(editDetails);
        // Assume editDetails has properties `companyImage` and `companyCertificate` for their respective URLs
        if (editDetails.companyImage) {
          setImageUrl(editDetails.companyImage);
          // Update fileList for companyImage if necessary
          const initialFileList = [
            {
              uid: "-1", // A temporary UID for the initial file
              name: "image", // Extract or set a meaningful name if possible
              status: "done",
              url: editDetails.companyImage, // The URL or path of the uploaded file
            },
          ] as const;
          setFileList([...initialFileList]);
          form.setFieldsValue({ companyImage: initialFileList }); // Make sure editDetails structure matches form fields
        }
        if (editDetails.companyCertificate) {
          setCertificateUrl(editDetails.companyCertificate);
          const initialCompanyCertificateFileList = [
            {
              uid: "-1", // A temporary UID for the initial file
              name: "certificate", // Extract or set a meaningful name if possible
              status: "done",
              url: editDetails.companyCertificate, // The URL or path of the uploaded file
            },
          ] as const;
          setCertificateFileList([...initialCompanyCertificateFileList]);
          form.setFieldsValue({
            companyCertificate: initialCompanyCertificateFileList,
          });
        }
      } else {
        // Reset for add new
        handleReset();
      }
    } else {
      // Reset whenever modal is closed
      handleReset();
    }
  }, [isModalOpen, isEdit, editDetails, form]);

  const handleClose = async () => {
    handleReset();
    onClose();
  };

  const beforeUpload = (file: RcFile) => {
    const isJpegOrPngOrGif = ["image/jpeg", "image/png", "image/gif"].includes(
      file.type
    );
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isJpegOrPngOrGif) {
      toast.error("You can only upload JPG/PNG/GIF file!");
      return false; // This prevents the file from being added to the list
    }
    if (!isLt2M) {
      toast.error("Image must smaller than 10MB!");
      return false; // This prevents the file from being added to the list
    }
    return isJpegOrPngOrGif && isLt2M;
  };

  const beforeCertificateUpload = (file: RcFile) => {
    const isPdfOrWord = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ].includes(file.type);
    const isLt15M = file.size / 1024 / 1024 < 15;
    if (!isPdfOrWord) {
      toast.error("You can only upload PDF/Word file!");
      return false; // This prevents the file from being added to the list
    }
    if (!isLt15M) {
      toast.error("file must smaller than 15MB!");
      return false; // This prevents the file from being added to the list
    }
    return isPdfOrWord && isLt15M;
  };

  // Base Props for common settings
  const baseUploadProps = {
    name: "file",
    action: uploadUrl,
    multiple: false,
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent: number | undefined) =>
        percent && `${parseFloat(percent.toFixed(2))}%`,
    },
  };

  const iconUploadProps: UploadProps = {
    ...baseUploadProps,
    onChange(info) {
      const lastSuccessFulUpload = fileList;
      const currentFileList = [...info.fileList];
      setFileList(currentFileList);
      const { status } = info.file;
      if (status === "uploading") {
        setIsUploading(true);
      } else if (status === "done") {
        setIsUploading(false);
        form.setFieldsValue({ companyImage: info.file.response.path });
        setImageUrl(info.file.response.path);
        setFileList(currentFileList);
        toast.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        setIsUploading(false);
        toast.error(`${info.file.name} file upload failed.`);
        // Revert to the last successful upload if the current upload fails
        if (lastSuccessFulUpload) {
          setFileList(lastSuccessFulUpload);
        } else {
          setFileList([]); // If there was no successful upload before, clear the fileList
        }
      }
    },
    fileList: fileList,
  };

  // Props for certificate upload
  const certificateUploadProps: UploadProps = {
    ...baseUploadProps,
    onChange(info) {
      const lastSuccessFulUpload = certificateFileList;

      const currentFileList = [...info.fileList];

      setCertificateFileList(currentFileList);
      const { status } = info.file;
      if (status === "uploading") {
        setIsUploading(true);
      } else if (status === "done") {
        setIsUploading(false);
        form.setFieldsValue({ companyCertificate: info.file.response.path });
        setCertificateFileList(currentFileList);
        setCertificateUrl(info.file.response.path); // Update certificate URL state
        toast.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        setIsUploading(false);
        toast.error(`${info.file.name} file upload failed.`);
        // Revert to the last successful upload if the current upload fails
        if (lastSuccessFulUpload) {
          setFileList(lastSuccessFulUpload);
        } else {
          setFileList([]); // If there was no successful upload before, clear the fileList
        }
      }
    },
    fileList: certificateFileList,
  };

  const handleEditCompany = async (formValues: any) => {
    const payload: IEditResponderCompany = copyObjects(formValues, {
      Id: editDetails?.id,
    });
    try {
      const response = await updateCompanyMutation.mutateAsync(payload);
      toast.success("Company updated successfully.");
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateCompany = async (formValues: any) => {
    const payload: IEditResponderCompany = copyObjects(formValues, { Id: 0 });
    try {
      const response = await createCompanyMutation.mutateAsync(payload);
      toast.success("Company created successfully.");
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  const onFinish = async (formValues: any) => {
    const values = copyObjects(formValues, {
      companyImage: imageUrl,
      companyCertificate: certificateUrl,
    });
    if (isEdit) return handleEditCompany(values);

    return handleCreateCompany(values);
  };
  return (
    <Modal
      centered
      open={isModalOpen}
      onCancel={() => handleClose()}
      width={550}
      closeIcon={
        <CustomIcons.CloseIcon
          width={30}
          height={30}
          viewBox={null}
          svgColor="#1C274C"
        />
      }
      footer={null}
    >
      <div className="flex w-12 py-3 justify-content-start" style={modelTitle}>
        {isEdit ? "Edit Responder Company" : "+Add Responder Company"}
      </div>
      <Form
        form={form}
        name="responderCompany"
        onFinish={onFinish}
        layout="vertical"
        className="grid w-full"
      >
        <div className="col-12">
          <Form.Item
            name="companyName"
            label="Name of company"
            rules={[{ required: true, message: "Please enter company name!" }]}
            className="mb-1"
          >
            <Input size="large" placeholder="Blue Hawks" />
          </Form.Item>
        </div>
        <div className="col-12">
          <Form.Item
            name="companyDescription"
            label="Description"
            rules={[
              {
                required: true,
                message: "Please enter company description!",
              },
            ]}
            className="mb-1"
          >
            <Input.TextArea
              placeholder="This is the description of the company. It should short and precise."
              rows={3}
              count={{
                show: true,
                max: 250,
                strategy: (txt) => txt.length,
                exceedFormatter: (txt, { max }) => txt.slice(0, max),
              }}
            />
          </Form.Item>
        </div>
        <div className="col-12">
          <Form.Item
            name="companyRegistrationNumber"
            label="Registration Number"
            rules={[{ required: true, message: "Please enter company name!" }]}
            className="mb-1"
          >
            <Input size="large" placeholder="CIPC" />
          </Form.Item>
        </div>
        <Form.Item
          name="companyImage"
          label="Icon"
          rules={[
            { required: true, message: "Please enter company icon!" },
            // Custom validator to check if a valid logo file has been uploaded
            () => ({
              validator(_) {
                if (!imageUrl && !isUploading) {
                  // Check if certificate URL is not set
                  return Promise.reject(
                    new Error("Please upload a company image.")
                  );
                }
                return Promise.resolve();
              },
            }),
            () => ({
              validator() {
                if (
                  fileList.length === 0 ||
                  fileList.some((file) => file.status !== "done")
                ) {
                  return Promise.reject(
                    new Error("Please upload a valid company image.")
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
          className="mb-5 col-12"
        >
          <div className="flex w-full gap-6 flx-row justify-content-start">
            {imageUrl.length > 0 ? (
              <Avatar
                shape="square"
                size={64}
                alt="responder company icon"
                src={imageUrl}
              />
            ) : (
              <Avatar shape="square" size={64} alt="responder company icon" />
            )}
            <Dragger
              {...iconUploadProps}
              beforeUpload={beforeUpload}
              className="w-full"
              style={{ background: "transparent" }}
              maxCount={1}
              fileList={fileList} // Use the local fileList state
              accept=".jpg,.jpeg,.png" // This allows only .jpg, .jpeg, and .png files
            >
              <DaggerCard />
            </Dragger>
          </div>
        </Form.Item>

        <div className="col-12">
          <Form.Item
            name="companyCertificate"
            label="Certificate"
            rules={[
              {
                required: true,
                message: "Please upload company certificate!",
              },
              // Custom validator to check if a valid certificate file has been uploaded
              () => ({
                validator(_) {
                  if (!certificateUrl && !isUploading) {
                    // Check if certificate URL is not set
                    return Promise.reject(
                      new Error(
                        "Please upload a valid PDF/Word certificate file."
                      )
                    );
                  }
                  return Promise.resolve();
                },
              }),
              () => ({
                validator() {
                  if (
                    certificateFileList.length === 0 ||
                    certificateFileList.some((file) => file.status !== "done")
                  ) {
                    return Promise.reject(
                      new Error("Please upload a valid PDF certificate file.")
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
            className="mb-1"
          >
            <Upload
              {...certificateUploadProps}
              beforeUpload={beforeCertificateUpload}
              accept=".pdf,.doc,.docx"
            >
              <Button
                className="flex items-center justify-between w-12 h-12 gap-2"
                icon={<FileUp />}
              >
                Click to Upload
              </Button>
            </Upload>
          </Form.Item>
        </div>
        <Form.Item className="w-12 pt-2 mb-1">
          <div className="flex w-full gap-2 col-12 justify-content-end align-content-center">
            <Button
              type="primary"
              ghost
              onClick={handleClose}
              size="large"
              className="w-3"
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="w-3 bg-[#1757a2] shadow-none"
              disabled={isUploading} // Disable the button while uploading
              loading={
                createCompanyMutation.isLoading ||
                updateCompanyMutation.isLoading
              }
            >
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ResponderCompanyModal;

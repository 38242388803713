/* eslint-disable no-underscore-dangle */
import {
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { queries } from "../services/queries";
import { Operators } from "../services";
import { operators } from "../services/queries/operatorsQueries";
import { users } from "../services/queries/usersQueries";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { isErrorMessage } from "../util/helpers";

export function useOperators() {
  return useQuery({
    ...queries.operators.all,
    queryFn: () => Operators.getAll(),
    staleTime: 60 * 1000, // 1 Min
    refetchOnWindowFocus: false,
  });
}

export function useOperatorHistory(
  operatorId: string | number | undefined | null
) {
  return useQuery({
    ...queries.operators.history(operatorId || 0),
    enabled: !!operatorId && Number(operatorId) > 0,
    refetchOnWindowFocus: false,
  });
}

export function useOperatorDetail(id: string) {
  return useQuery({
    ...queries.operators.detail(id),
    refetchOnWindowFocus: false,
  });
}

export function useOperatorsList(filter: Filters) {
  return useQuery({
    ...queries.operators.list(filter),
    staleTime: 60 * 1000,
  });
}

export function useSearchOperators(
  filters: Filters,
  query: string,
  limit = 15
) {
  return useQuery({
    ...queries.operators.list(filters)._ctx.search(query, limit),
    enabled: Boolean(query),
    refetchOnWindowFocus: false,
  });
}
export function useAddOperator() {
  const queryClient = useQueryClient();

  return useMutation(Operators.addOperator, {
    onSuccess: (newOperator) => {
      queryClient.setQueriesData(
        queries.operators.detail(newOperator.data.id).queryKey,
        newOperator.data
      );
      queryClient.invalidateQueries({
        queryKey: queries.operators._def,
      });
      queryClient.invalidateQueries({
        queryKey: ["emergencies"],
      });
    },
    meta: {
      conflict:
        "Found whilst creating emergency operator, Phone Number or Email Address Already Exists",
      badRequest: "Please make sure all fields are entered correctly.",
    },
  });
}

export function useEditOperator() {
  const queryClient = useQueryClient();

  return useMutation(Operators.updateOperator, {
    onSuccess: (newOperator, variables) => {
      queryClient.setQueriesData(
        queries.operators.detail(variables.userId).queryKey,
        newOperator
      );
      queryClient.invalidateQueries({
        queryKey: queries.operators._def,
      });
      queryClient.invalidateQueries({
        queryKey: ["emergencies"],
      });
    },
    meta: {
      conflict: "Found whilst making changes",
      badRequest: "Please make sure all fields are entered correctly.",
    },
  });
}

export function useOperatorResolve() {
  const queryClient = useQueryClient();

  return useMutation(Operators.resolveCase, {
    onSuccess: (resolved) => {
      queryClient.invalidateQueries({
        queryKey: queries.operators.emergencyCases(resolved.practitionerId)
          .queryKey,
      });
    },
    meta: {
      conflict: "Found whilst making changes",
      badRequest: "Please make sure all fields are entered correctly.",
    },
  });
}

export function useGetAllEmergenciesOperators() {
  return useQuery({
    ...queries.operators.getAllEmergenciesOperators,
    queryFn: () => Operators.getAllEmergencyOperators(),
  });
}

export function useOperatorsByEmergencyAll(
  emergencyId: string | number | null
  // onSuccess?: (data: IGeneralCommunity[]) => void
) {
  return useQuery({
    ...queries.operators.byEmergencyAll(emergencyId || 0),
    enabled: emergencyId !== null,
    // onSuccess,
  });
}

export function useOperatorsByEmergencyList(
  emergencyId: string | number | null,
  filter: Filters
) {
  return useQuery({
    ...queries.operators.byEmergencyList(emergencyId || 0, filter),
    enabled: !!emergencyId,
  });
}

export function useByEmergencySearchOperators(
  emergencyId: string | number | null,
  filters: Filters,
  query: string,
  limit = 15
) {
  return useQuery({
    ...queries.operators
      .byEmergencyList(emergencyId || 0, filters)
      ._ctx.search(query, limit),
    enabled: Boolean(query) && !!emergencyId,
  });
}

export function useOperatorEmergencyCases(
  id: string | number | null,
  enabled: boolean
) {
  return useQuery({
    ...queries.operators.emergencyCases(id || 0),
    enabled: !!id && Number(id) > 0 && enabled,
    refetchInterval: 30 * 1000, // refetch every 30 seconds
    refetchIntervalInBackground: true,
  });
}

export function useOperatorAnalytics(EmergencyId: number | null) {
  return useQuery({
    ...queries.operators.getOperatorsAnalytics(EmergencyId),
    refetchIntervalInBackground: true,
  });
}

export function useSelectedOperatorAnalytics(
  operatorId: string | undefined,
  startDate: string | Date | undefined | null,
  endDate: string | Date | undefined | null
) {
  return useQuery({
    ...queries.operators.getSelectedOperatorAnalytics(
      operatorId,
      startDate,
      endDate
    ),
    refetchIntervalInBackground: true,
  });
}

export function useTransferCase() {
  const queryClient = useQueryClient();
  return useMutation(Operators.transferCase, {
    onSuccess: (transferred) => {
      queryClient.invalidateQueries({
        queryKey: queries.operators.emergencyCases(transferred.practitionerID)
          .queryKey,
      });
    },
    meta: {
      conflict: "Found whilst making changes",
      badRequest: "Please make sure all fields are entered correctly.",
      notFound:
        "Either case could not be found or no available operator at this time, please try again.",
    },
  });
}

export function useEmergencyCaseOperatorNote(
  emergencyCaseId: string | number | null
) {
  return useQuery({
    ...queries.operators.getEmergencyCaseOperatorNote(emergencyCaseId),
    enabled: !!emergencyCaseId && Number(emergencyCaseId) > 0,
    refetchIntervalInBackground: true,
  });
}

export function usePostOperatorNote() {
  const queryClient = useQueryClient();

  return useMutation(Operators.postOperatorNote, {
    onSuccess: (emergencyCase) => {
      queryClient.invalidateQueries({
        queryKey: queries.operators.getEmergencyCaseOperatorNote(
          emergencyCase.id
        ).queryKey,
      });
    },
    meta: {
      conflict: "Found whilst making changes",
      badRequest: "Please make sure all fields are entered correctly.",
      notFound: "Case could not be found. Please try again",
    },
  });
}

export function usePausedCase() {
  const queryClient = useQueryClient();

  return useMutation(Operators.pauseEmergencyCase, {
    onSuccess: (emergencyCase) => {
      queryClient.invalidateQueries({
        queryKey: queries.operators.emergencyCases(emergencyCase.practitionerId)
          .queryKey,
      });
    },
    meta: {
      conflict: "Found whilst making changes",
      badRequest: "Please make sure all fields are entered correctly.",
      notFound: "Case could not be found. Please try again",
    },
  });
}

export function useResumeCase() {
  const queryClient = useQueryClient();

  return useMutation(Operators.resumeEmergencyCase, {
    onSuccess: (emergencyCase) => {
      queryClient.invalidateQueries({
        queryKey: queries.operators.emergencyCases(emergencyCase.practitionerId)
          .queryKey,
      });
    },
    meta: {
      conflict: "Found whilst making changes",
      badRequest: "Please make sure all fields are entered correctly.",
      notFound: "Case could not be found. Please try again",
    },
  });
}

export function useGetOperatorMaxConCurrentCases(
  userId: string | number | null
) {
  return useQuery({
    ...queries.operators.getOperatorMaxConCurrentCase(userId),
    enabled: !!userId && Number(userId) > 0,
  });
}

export function useUpdateOperatorMaxConcurrentCases() {
  const queryClient = useQueryClient();

  return useMutation(Operators.updateOperatorMaxConcurrentCases, {
    onSuccess: (operator) => {
      queryClient.invalidateQueries({
        queryKey: queries.operators.getOperatorMaxConCurrentCase(
          operator.practitionerId
        ).queryKey,
      });
    },
    meta: {
      conflict: "Found whilst making changes",
      badRequest: "Please make sure all fields are entered correctly.",
      notFound: "Operator could not be found. Please try again",
    },
  });
}

export function useTransferableOperators(
  emergencyId: string | number | null,
  operatorId: string | number | null
) {
  return useQuery({
    ...queries.operators.getTransferableOperators(emergencyId, operatorId),
    enabled:
      !!emergencyId &&
      Number(emergencyId) > 0 &&
      !!operatorId &&
      Number(operatorId) > 0,
  });
}

export function useTransferByOperator() {
  const queryClient = useQueryClient();
  return useMutation(Operators.transferCaseByOperator, {
    onSuccess: (transferred, data) => {
      queryClient.invalidateQueries({
        queryKey: queries.operators.getTransferableOperators(
          transferred.emergencyId,
          data.operatorId
        ).queryKey, // mistake here
      });
    },
    meta: {
      conflict: "Found whilst making changes",
      badRequest: "Please make sure all fields are entered correctly.",
      notFound:
        "Either case could not be found or no available operator at this time, please try again.",
    },
  });
}

export function useUpdateOperatorOnlineStatus() {
  const queryClient = useQueryClient();
  return useMutation(Operators.UpdateOperatorOnlineStatus, {
    onError: (error: AxiosError, dm, context) => {
      const errorMessage = error.response?.data;

      if (typeof errorMessage === "string") {
        toast.error(errorMessage);
      } else if (isErrorMessage(errorMessage)) {
        const errorTitle = errorMessage.title;
        const errorKeys = Object.keys(errorMessage.errors || {});
        if (errorKeys.length > 0 && errorMessage.errors) {
          const errorFieldMessage = `${errorKeys[0]}: ${
            errorMessage.errors[errorKeys[0]][0]
          }`;
          toast.error(errorFieldMessage);
        } else {
          toast.error(errorTitle);
        }
      } else {
        // If errorMessage is not a string and not an IErrorMessage, show a generic error message
        toast.error("Something went wrong");
      }
    },
    meta: {
      useError: true,
      conflict: "Found whilst making changes",
      badRequest: "Please make sure all fields are entered correctly.",
      notFound: "Could not find the operator.",
    },
    retry: 1,
  });
}

import { Buffer } from "buffer";
import { Dispatch, SetStateAction } from "react";
import { DateTime } from "luxon";
import { uploadUrl } from "@/config/urls";
import Colors from "../config/Colors";
import { VehicleStatus } from "./enums";

export const encodeMessage = (message: string): string => {
  const encodedMessage = Buffer.from(message).toString("base64");
  return encodedMessage;
};

export const decodeMessage = (message: string): string => {
  if (typeof message !== "string") {
    return message;
  }
  // Check if the string looks like base64. This isn't a perfect test.
  if (/^[A-Za-z0-9+/]+={0,2}$/.test(message) && message.length % 4 === 0) {
    try {
      const decodedMessage = Buffer.from(message, "base64").toString("utf8");
      // Additional validity check: For instance, assume that messages should be at least 1 character long.
      if (decodedMessage.length > 0) {
        return decodedMessage;
      }
    } catch (error) {
      // Even if it looked like base64 but failed to decode, we return the original message.
    }
  }

  // If we reach here, the message is likely plain text.
  return message;
};

export const getInitials = (name: string) => {
  const nameSplit = name.trim().split(" ");
  const initials = nameSplit.reduce(
    (accumulator, current) => accumulator + current.charAt(0),
    ""
  );
  return initials.toUpperCase();
};

export function isErrorMessage(obj: any): obj is IErrorMessage {
  return obj && typeof obj.title === "string" && typeof obj.errors === "object";
}

export type Dispatcher<S> = Dispatch<SetStateAction<S>>;

export const customInputTheme = {
  token: {
    colorPrimaryHover: `${Colors.appMainColor}`,
  },
};

export const getTimePosted = (createdAt: string | Date) => {
  let postTime;

  if (typeof createdAt === "string") {
    postTime = DateTime.fromISO(createdAt, { zone: "utc" });
  } else if (createdAt instanceof Date) {
    postTime = DateTime.fromJSDate(createdAt, { zone: "utc" });
  } else {
    return ""; // handle unexpected types
  }

  postTime = postTime.toLocal();
  const relativeTime = postTime.toRelative({ style: "short" });

  return relativeTime || "";
};

export const getTimePostedCustom = (createdAt: string | Date): string => {
  let postTime: DateTime | null = null;

  if (typeof createdAt === "string") {
    postTime = DateTime.fromISO(createdAt, { zone: "utc" });
  } else if (createdAt instanceof Date) {
    postTime = DateTime.fromJSDate(createdAt, { zone: "utc" });
  } else {
    return ""; // handle unexpected types
  }

  postTime = postTime.toLocal();
  const now = DateTime.local();

  const diff = now
    .diff(postTime, ["years", "months", "days", "hours", "minutes", "seconds"])
    .toObject();

  if (diff.years && diff.years >= 1) {
    return `${diff.years}y`;
  }
  if (diff.months && diff.months >= 1) {
    return `${diff.months}mo`;
  }
  if (diff.days && diff.days >= 1) {
    return `${diff.days}d`;
  }
  if (diff.hours && diff.hours >= 1) {
    return `${diff.hours}h`;
  }
  if (diff.minutes && diff.minutes >= 1) {
    return `${diff.minutes}m`;
  }
  return "Now";
};

export const truncateDescription = (desc: string, limit: number) => {
  return desc.length > limit ? `${desc.substring(0, limit)}...` : desc;
};

export const isFirstMessageOfDay = (
  index: number,
  messages: ICommunityMessage[]
) => {
  if (index === 0) return true;
  const prevMessageDate = DateTime.fromISO(messages[index - 1].createdAt, {
    zone: "utc",
  })
    .toLocal()
    .toFormat("yyyy-MM-dd");
  const currentMessageDate = DateTime.fromISO(messages[index].createdAt, {
    zone: "utc",
  })
    .toLocal()
    .toFormat("yyyy-MM-dd");
  return prevMessageDate !== currentMessageDate;
};

export const redirectToDashboard = (user: IUser | any, navigate: any) => {
  // Check if the user has any of the roles and redirect accordingly
  if (user.userRoles) {
    // eslint-disable-next-line no-restricted-syntax
    for (const role of user.userRoles) {
      switch (role.role.name) {
        case "SuperAdmin":
          navigate.push("/home/dashboard");
          return;
        case "EmergencyAdmin":
          navigate.push("/home/operator-dashboard");
          return;
        case "CommunityAdmin":
          navigate.push("/home/community-home");
          return;
        case "EmergencyOperator":
          navigate.push("/home/operator-dashboard");
          return;

        // Add more cases if necessary
        default:
          navigate.push("/home/");
          break;
      }
    }
  }
};

export const panicTypes = [
  {
    id: 1,
    value: 1,
    label: "Medical",
    templateUrl:
      "https://res.cloudinary.com/dlbmecwsn/raw/upload/v1709467071/Safer_City_portal_upload/template/Upload_Security_Responders_To_SaferCity.xlsx",
  },
  {
    id: 2,
    value: 2,
    label: "Security",
    templateUrl:
      "https://res.cloudinary.com/dlbmecwsn/raw/upload/v1709467021/Safer_City_portal_upload/template/Upload_Medical_Responders_To_SaferCity.xlsx",
  },
  {
    id: 3,
    value: 3,
    label: "CPF",
    templateUrl:
      "https://res.cloudinary.com/dlbmecwsn/raw/upload/v1709466975/Safer_City_portal_upload/template/Upload_CPF_Responders_To_SaferCity.xlsx",
  },
];

export const baseUploadProps = {
  name: "file",
  action: uploadUrl,
  multiple: false,
  progress: {
    strokeColor: {
      "0%": "#108ee9",
      "100%": "#87d068",
    },
    strokeWidth: 3,
    format: (percent: number | undefined) =>
      percent && `${parseFloat(percent.toFixed(2))}%`,
  },
};

export function isUrl(str: string) {
  try {
    new URL(str);
    return true;
  } catch (_) {
    return false;
  }
}

// Type guard for SuccessResponse
export function isSuccessResponse<T>(
  response: APIResult<T>
): response is SuccessResponse<T> {
  return (response as SuccessResponse<T>).status === "success";
}

// Type guard for GenericErrorResponse
export function isGenericErrorResponse(
  response: any
): response is GenericErrorResponse {
  return (response as GenericErrorResponse).status === "error";
}

// Type guard for DotNetValidationError
export function isDotNetValidationError(
  response: any
): response is DotNetValidationError {
  return (response as DotNetValidationError).errors !== undefined;
}

export const VehicleStatusLabels: Record<VehicleStatus, string> = {
  [VehicleStatus.Missing]: "Missing",
  [VehicleStatus.Found]: "Found",
};
import { createQueryKeys } from "@lukemorales/query-key-factory";
import Admins from "../Admins";

export const admins = createQueryKeys("admins", {
  all: null,
  list: (filter: Filters) => ({
    queryKey: [filter],
    queryFn: (ctx) => Admins.getAdminList(filter, ctx.pageParam),
    contextQueries: {
      search: (query: string, limit: number) => ({
        queryKey: [query, limit],
        queryFn: (ctx) =>
          Admins.getSearchAdmin(filter, ctx.pageParam, limit, query),
      }),
    },
  }),
});

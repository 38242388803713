import styled from "styled-components";
import { Drawer } from "antd";
import Colors from "../../config/Colors";

const { appMainColor } = Colors;

export const MessagingDrawer = styled(Drawer)`
  .ant-drawer .ant-drawer-content {
    width: 100%;
    height: 100%;
    overflow: auto;
    background: #fdfeff;
    pointer-events: auto;
    border-top-left-radius: 16px;
    box-shadow: 0px 0px 4px rgba(2, 17, 37, 0.08),
      -2px 0px 8px rgba(2, 17, 37, 0.08), -6px 0px 16px rgba(2, 17, 37, 0.04);
    border-left: 1px solid #cdd5de;
  }

  .ant-drawer-content-wrapper {
    padding-top: 40px;
  }

  .ant-drawer-right > .ant-drawer-content-wrapper {
    top: 0;
    right: 0;
    bottom: 0;
  }

  ant-drawer .ant-drawer-mask {
    position: absolute;
    inset: 0;
    z-index: 1000;
    background: rgba(153, 199, 235, 0.5);
    pointer-events: auto;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: WINDOW_HEIGHT;
  background-color: ${Colors.backgroundColor};
  width: 95%;
`;

export const ChatsContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  width: 90%;
  max-height: 100vh;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0px solid black;
  width: 100%;
  padding: 16px;
  background-color: ${Colors.subtleBlue};
  top: 0;
`;

export const ChatName = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
`;

export const ChatNameStatus = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${appMainColor};
  text-align: start;
`;
export const ChatEmailStatus = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-decoration-line: underline;
  color: #0e1114;
`;
export const ChatUserInformation = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #0e1114;
`;

export const ChatMessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${Colors.backgroundColor};
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
`;

export const ChatMessages = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 16px;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
`;

export const SuccessStatusText = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: -0.02em;
  color: ${Colors.mainTextColor};
`;
export const SuccessStatusDescriptionText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${Colors.subtleTextColor};
`;

export const ChatSendBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 5rem;
  align-items: center;
  padding: 16px;
  background: ${Colors.subtleBlue};
  box-shadow: 0px -1px 0px #eeeeee;
`;

export const ChatInput = styled.input`
  height: 28px;
  width: calc(100% - 50px);
  border: none;
  margin-left: 16px;
  font-size: 14px;
  background-color: transparent;
  ::placeholder {
    color: #c9c9c9;
    bottom: 20;
  }
  margin-bottom: 20px;
  bottom: 20;
`;

export const ConversationStartDate = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.45);
`;

export const SenderChatMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  background: ${appMainColor};
  min-height: 2rem;
  min-width: 40%;
  max-width: 80%;
  border-radius: 0.5rem;
`;
export const SenderText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: ${Colors.backgroundColor};
`;

export const ReceiverChatMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 1rem 1rem;
  background: ${Colors.subtleBlue};
  min-height: 5rem;
`;

export const ReceiverText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: ${Colors.mainTextColor};
  display: flex;
  align-items: start;
  justify-content: start;
`;

export const ReceiverTextTime = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  display: flex;
  align-items: start !important;
  justify-content: flex-start !important;
`;

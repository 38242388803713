import React, { useEffect, useState, useRef } from "react";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { useHistory, useParams } from "react-router-dom";
import { Column, ColumnFilterElementTemplateOptions } from "primereact/column";
import { DataTable, DataTableFilterMeta } from "primereact/datatable";
import {
  ActionBodyText,
  BodyContainer,
  Container,
} from "../../../styles/ContainerStyles";
import HeaderComponent from "../../../components/Header/Header";
import { tableBody, tableHeader, tableName } from "../../../styles/TableStyles";
import { usePostedCategory } from "../../../hooks/useCrimes";
import Colors from "../../../config/Colors";
import CustomInputField from "../../../components/widgets/Inputs/CustomInputField";
import jsPDF from "jspdf";
import { Calendar, CalendarChangeEvent } from "primereact/calendar";
import { Button, message, Modal, Spin } from "antd";
import { CustomIcons } from "../../../assets";
import BackButton from "../../../components/widgets/common/BackButton";
import autoTable, { applyPlugin } from "jspdf-autotable";
import { modelTitle } from "../../../styles/ModalStyles";

type categoryParams = {
  category: string;
};

const PostedCategory = () => {
  // @ts-ignore
  const dt = useRef<DataTable>(null);
  const { category } = useParams<categoryParams>();
  const navigate = useHistory();
  const { data, isLoading, isFetching, refetch } = usePostedCategory(category);
  const navigateTo = (id: string) => {
    navigate.push(`/home/crime-report-detail/${id}`);
  };
  const [crime, setCrime] = useState<ITabItemCategory[]>();
  const [isExporting, setIsExporting] = useState(false);
  const [openDownloadButtonsModal, setOpenDownloadButtonsModal] =
    useState(false);

  const cols = [
    { field: "lastUpdated", header: "Last Updated" },
    { field: "reportedBy", header: "Reported By" },
    { field: "category", header: "Category of Crime" },
    { field: "crimeType", header: "Crime Type" },
    { field: "location", header: "Location" },
    { field: "caseNo", header: "Case Number" },
    { field: "dateReported", header: "Date Rported" },
    { field: "time", header: "Time of Crime" },
    { field: "carInsurance", header: "Car Insurance" },
    { field: "carRegistration", header: "Car Registration" },
    { field: "vehicleMake", header: "Vehicle Make" },
    { field: "vehicleType", header: "Vehicle Type" },
  ];

  const exportColumns = cols.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));
  const customInputTheme = {
    token: {
      colorPrimaryHover: `${Colors.appMainColor}`,
    },
  };

  const exportPdf = () => {
    if (data) {
      const doc = new jsPDF();
      const rowInputData = data.map((crime) => ({
        crimeId: crime.crimeId,
        lastupdated: crime.lastUpdated,
        reportedBy: crime.reportedBy,
        category: crime.category,
        crimeType: crime.crimeType,
        location: crime.location,
        caseNo: crime.caseNo,
        dateReported: crime.dateReported,
        time: crime.time,
        carInsurance: crime.carInsurance,
        carRegistration: crime.carRegistration,
        vehicleMake: crime.vehicleMake,
        vehicleType: crime.vehicleType,
      }));

      autoTable(doc, { columns: exportColumns, body: rowInputData });
      doc.save("download.pdf");
    }
  };

  const exportCSV = (selectionOnly: boolean) => {
    setIsExporting(true);
    dt.current.exportCSV({ selectionOnly });
    setIsExporting(false);
  };

  const [filters, setFilters] = useState<DataTableFilterMeta | undefined>(
    undefined
  );

  const [globalFilterValue, setGlobalFilterValue] = useState<string>("");

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      lastUpdated: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      category: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      reportedBy: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      crimeType: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      location: {
        operator: FilterOperator.OR,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      dateReported: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      caseNo: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      carInsurance: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      carRegistration: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      vehicleMake: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      vehicleType: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });
    setGlobalFilterValue("");
  };

  const clearFilter = () => {
    initFilters();
  };

  const onGlobalFilterChangeV2 = (e: string) => {
    const _filters = { ...filters };
    // @ts-ignore
    _filters.global.value = e;

    setFilters(_filters);
    setGlobalFilterValue(e);
  };

  const getCrimes = (data: ITabItemCategory[]) => {
    return [...(data || [])].map((d) => {
      // @ts-ignore
      d.dateReported = new Date(d.dateReported);

      return d;
    });
  };

  useEffect(() => {
    if (Array.isArray(data)) {
      setCrime(getCrimes(data));
    }
    setGlobalFilterValue("");

    initFilters();
  }, [data]);

  const dateBodyTemplate = (rowData: ITabItemCategory) => {
    return moment(rowData.lastUpdated).format("YYYY/MM/DD");
  };
  const categoryBodyTemplate = (rowData: ITabItemCategory) => {
    return rowData.category;
  };
  const reportedbyBodyTemplate = (rowData: ITabItemCategory) => {
    return rowData.reportedBy;
  };
  const locationBodyTemplate = (rowData: ITabItemCategory) => {
    return rowData.location;
  };
  const timeOfCrimeBodyTemplate = (rowData: ITabItemCategory) => {
    return rowData.time;
  };
  const dateReportedBodyTemplate = (rowData: ITabItemCategory) => {
    return moment(rowData.dateReported).format("YYYY/MM/DD");
  };
  const caseNoBodyTemplate = (rowData: ITabItemCategory) => {
    return rowData.caseNo;
  };
  const crimeTypeBodyTemplate = (rowData: ITabItemCategory) => {
    return rowData.crimeType;
  };
  const carInsuranceBodyTemplate = (rowData: ITabItemCategory) => {
    return rowData.carInsurance;
  };
  const carRegistrationBodyTemplate = (rowData: ITabItemCategory) => {
    return rowData.carRegistration;
  };
  const vehicleMakeBodyTemplate = (rowData: ITabItemCategory) => {
    return rowData.vehicleMake;
  };
  const vehicleTypeBodyTemplate = (rowData: ITabItemCategory) => {
    return rowData.vehicleType;
  };

  const viewMoreBodyTemplate = (rowData: ITabItemCategory) => {
    const id = rowData.crimeId;
    return (
      <ActionBodyText
        onClick={() => {
          navigateTo(id);
        }}
      >
        View More
      </ActionBodyText>
    );
  };

  const dateFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e: CalendarChangeEvent) => {
          options.filterCallback(e.value, options.index);
        }}
        dateFormat="yy/mm/dd"
        placeholder="yyyy/mm/dd"
        mask="9999/99/99"
      />
    );
  };

  return (
    <div className="w-full h-full">
      <Container className="h-full w-full">
        <BodyContainer className="h-full w-full">
          <div className="flex flex-row w-full justify-start align-items-center">
            <BackButton />
            <HeaderComponent title="Reported Crimes" />
          </div>
          <Spin tip="" spinning={isLoading || isFetching} delay={500}>
            <div className="px-3 mt-3">
              <div className="flex items-center mb-2 w-full">
                <div
                  className="flex items-center w-1/2 justify-start"
                  style={tableName}
                >
                  {category}
                </div>
                <div className="flex items-center w-1/2 justify-end">
                  <Button
                    icon={<DownloadOutlined />}
                    onClick={() => setOpenDownloadButtonsModal(true)}
                    loading={isExporting}
                    type="primary"
                    ghost
                    size="large"
                    className="ml-1 w-2"
                  />
                  <CustomInputField
                    value={globalFilterValue}
                    onChange={(e: string) => onGlobalFilterChangeV2(e)}
                    onPressEnter={() => {}}
                    theme={customInputTheme}
                    inputProps={{
                      size: "large",
                      placeholder: "Search",
                      allowClear: true,
                      className: "ml-2 w-4",
                      prefix: <SearchOutlined />,
                    }}
                    mergeThemes
                  />
                </div>
              </div>

              <DataTable
                value={Array.isArray(crime) ? crime : []}
                ref={dt}
                paginator
                rows={10}
                filters={filters}
                globalFilterFields={[
                  "lastUpdated",
                  "category",
                  "reportedBy",
                  "crimeType",
                  "location",
                  "dateReported",
                  "caseNo",
                  "carInsurance",
                  "carRegistration",
                  "vehicleMake",
                  "vehicleType",
                ]}
                rowsPerPageOptions={[10, 25, 50, 100]}
                tableStyle={{ minWidth: "50rem" }}
                sortMode="multiple"
                emptyMessage="No reported crimes found."
                scrollable
                scrollHeight="40rem"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Operators"
                stateStorage="session"
                stateKey={`dt-state-crime-posted-category-table`}
              >
                <Column
                  field="lastUpdated"
                  header="Last Updated"
                  headerStyle={tableHeader}
                  filterField="lastupdated"
                  style={{ background: "#FBFBFB", minWidth: "15rem" }}
                  bodyStyle={tableBody}
                  body={dateBodyTemplate}
                  filter
                  filterElement={dateFilterTemplate}
                />
                <Column
                  field="reportedBy"
                  header="Reported By"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB", minWidth: "15rem" }}
                  bodyStyle={tableBody}
                  body={reportedbyBodyTemplate}
                  filter
                />

                <Column
                  field="category"
                  header="Category of Crime"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB", minWidth: "25rem" }}
                  bodyStyle={tableBody}
                  body={categoryBodyTemplate}
                  filter
                />
                <Column
                  field="crimeType"
                  header="Crime Type"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB", minWidth: "25rem" }}
                  bodyStyle={tableBody}
                  body={crimeTypeBodyTemplate}
                  filter
                />

                <Column
                  field="location"
                  header="Location"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB", minWidth: "15rem" }}
                  bodyStyle={tableBody}
                  body={locationBodyTemplate}
                  filter
                />

                <Column
                  field="caseNo"
                  header="Case Number"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB", minWidth: "15rem" }}
                  bodyStyle={tableBody}
                  body={caseNoBodyTemplate}
                  filter
                />
                <Column
                  field="dateReported"
                  header="Date Reported"
                  headerStyle={tableHeader}
                  filterField="dateReported"
                  dataType="date"
                  body={dateReportedBodyTemplate}
                  bodyStyle={tableBody}
                  filter
                  filterElement={dateFilterTemplate}
                  style={{ background: "#FBFBFB", minWidth: "15rem" }}
                />
                <Column
                  field="time"
                  header="Time of Crime"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB", minWidth: "10rem" }}
                  bodyStyle={tableBody}
                  body={timeOfCrimeBodyTemplate}
                />
                <Column
                  field="carInsurance"
                  header="Car Insurance"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB", minWidth: "15rem" }}
                  bodyStyle={tableBody}
                  body={carInsuranceBodyTemplate}
                  filter
                />
                <Column
                  field="carRegistration"
                  header="Car Registration"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB", minWidth: "15rem" }}
                  bodyStyle={tableBody}
                  body={carRegistrationBodyTemplate}
                  filter
                />
                <Column
                  field="vehicleMake"
                  header="Vehicle Make"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB", minWidth: "15rem" }}
                  bodyStyle={tableBody}
                  body={vehicleMakeBodyTemplate}
                  filter
                />
                <Column
                  field="vehicleType"
                  header="Vehicle Type"
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB", minWidth: "15rem" }}
                  bodyStyle={tableBody}
                  body={vehicleTypeBodyTemplate}
                  filter
                />
                <Column
                  header=""
                  headerStyle={tableHeader}
                  style={{ background: "#FBFBFB", minWidth: "10rem" }}
                  bodyStyle={tableBody}
                  body={viewMoreBodyTemplate}
                />
              </DataTable>
            </div>
          </Spin>
        </BodyContainer>
      </Container>
      <Modal
        centered
        open={openDownloadButtonsModal}
        onOk={() => setOpenDownloadButtonsModal(false)}
        onCancel={() => setOpenDownloadButtonsModal(false)}
        width={550}
        closeIcon={
          <CustomIcons.CloseIcon
            width={30}
            height={30}
            viewBox={null}
            svgColor="#1C274C"
          />
        }
        footer={null}
      >
        <div
          className="flex w-12 py-3 justify-content-start"
          style={modelTitle}
        >
          Download As
        </div>
        <Button
          className="flex justify-center w-full px-2 mb-1 text-white bg-successColor"
          onClick={() => {
            exportCSV(false);
          }}
        >
          CSV
        </Button>
        <Button
          className="flex justify-center w-full px-2 mb-1 text-white bg-errorColor"
          onClick={() => exportPdf()}
        >
          PDF
        </Button>
      </Modal>
    </div>
  );
};

export default PostedCategory;
